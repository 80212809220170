// Add & Remove Email Address Component
.addRemoveEmailAddress {
  position: relative;
  @include flex-center;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 5px;
  .emailInputAndDropdownCont {
    position: relative;
    .inputWrapper {
      @extend %inputWrapper;
      margin-bottom: 0;
    }
  }
  .labelName {
    color: $c-8d;
    font-size: 14px;
  }
  .chipEmail {
    position: relative;
    @include flex-center;
    justify-content: center;
    border: 1px solid $c-ea;
    border-radius: 12px;
    padding: 4px 5px;
    line-height: inherit;
    a {
      font-size: 12px;
      color: $c-3b;
      text-decoration: none;
      line-height: inherit;
      cursor: pointer;
      padding-right: 5px;
    }
    i.remove {
      font-size: 12px;
      color: $c-3b;
      cursor: pointer;
    }
    .tooltipInfoWrap {
      position: relative;
      @include flex-center;
      .infoIcon {
        position: relative;
        font-size: 12px;
        color: red;
        cursor: pointer;
        padding: 0 2px;
        line-height: inherit;
      }
      .customTooltip {
        .sharpPointer {
          padding: 6px;
          border-right: 1px solid $c-e3;
          border-top: 1px solid $c-e3;
          z-index: 4;
          top: 6px;
          right: 2px;
        }
        .tooltipBody {
          border-radius: 2px;
          border: 1px solid $c-e3;
          top: 12px;
          width: max-content;
          .tooltipText {
            padding: 6px 8px;
            font-size: 12px;
          }
        }
      }
    }
    &.invalidEmail {
      border: 1px solid red;
      a,
      i.remove {
        color: red;
      }
    }
  }
}

.emailPreviewContentPopup {
  position: fixed;
  @include flex-center;
  justify-content: center;
  inset: 0;
  @include wt-ht(100%, 100%);
  z-index: 10000;
  .card {
    position: relative;
    z-index: 20;
    background: white;
    @include wt-ht(840px, auto);
    border-radius: 5px;
    padding: 10px 15px 15px;
    .cardTitle {
      position: relative;
      display: grid;
      grid-template-columns: calc(100% - 34px) 24px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $c-dd;
      padding: 10px 0;
      &.showStatus {
        grid-template-columns: calc(100% - 164px) 130px 24px;
      }
      .previewSubject {
        position: relative;
        @include flex-center;
        .subjectAndEmailInsight {
          @include flex-center;
          justify-content: space-between;
          width: 100%;
          .subjectCont {
            position: relative;
            @include flex-center;
            .label {
              font-size: 16px;
              color: $c-3b;
              padding-right: 5px;
              font-weight: bold;
            }
            .subject {
              font-size: 16px;
              color: $blue-violet;
              font-weight: 600;
              width: unset;
              max-width: 300px;
              @include ellipsis();
            }
          }
          .emailInsight {
            justify-self: flex-end;
            ul li:last-child {
              .icon-tag {
                margin-right: 0px !important;
              }
              .customTooltip .sharpPointer {
                right: -50px !important;
              }
            }
          }
        }
        .backBtn {
          cursor: pointer;
          font-size: 24px;
          border-radius: 35px;
          padding: 0;
          margin-right: 8px;
          transition: all 0.3s ease-in-out;
          &:hover {
            background: $c-f5;
          }
        }
        .icon {
          position: relative;
          @include flex-align-center;
          background: $grad-yellow;
          border-radius: 4px;
          padding: 5px;
          svg {
            @include wt-ht(14px, 14px);
            fill: white !important;
            path {
              fill: white !important;
            }
          }
        }
        .labelAndSubject {
          position: relative;
          width: 100%;
          @include flex-center;
          &.reducedWidth {
            width: calc(100% - 210px);
          }
          .label {
            font-size: 16px;
            color: $c-8d;
            padding-right: 8px;
            text-transform: capitalize;
          }
          .subject {
            font-size: 16px;
            color: $blue-violet;
            font-weight: 600;
            @include ellipsis();
            width: calc(100% - 120px);
            padding-right: 12px;
            &.underlineBlue {
              text-decoration: underline;
            }
            &.taskTitle {
              color: $c-3b;
            }
          }
        }
      }
      .completedStatus {
        position: relative;
        @include flex-center;
        justify-content: flex-end;
        margin-right: 10px;
        color: #00a358;
        i {
          font-size: 10px;
          margin-right: 4px;
        }
        .text {
          font-size: 12px;
          position: relative;
          top: 1px;
        }
      }
      .close {
        cursor: pointer;
        font-size: 24px;
        border-radius: 35px;
        color: $c-66;
        transition: all 0.3s ease-in-out;
        &:hover {
          color: $c-33;
          background: $c-f5;
        }
      }
    }
    .cardBody {
      position: relative;
      padding: 10px 0;
      .eventDate {
        position: relative;
        @include flex-center;
        justify-content: flex-end;
        color: $c-8d;
        font-size: 12px;
        padding-right: 2px;
      }
      .phoneDetails {
        position: relative;
        @include flex-center;
        justify-content: space-between;
      }
      .linkedinDetails {
        position: relative;
        @include flex-center;
        margin: 5px 0 0;
        .label {
          color: $c-8d;
          font-size: 14px;
          padding-right: 5px;
        }
        .text {
          font-size: 14px;
          color: $c-3b;
        }
      }
      .fromEmailDetails,
      .dialerRecipient {
        position: relative;
        @include flex-center;
        justify-content: space-between;
        margin: 5px 0 8px;
        .toNumber {
          margin: 0 15px;
        }
        .fromAddress,
        .fromNumber,
        .toNumber {
          position: relative;
          @include flex-center;
          font-size: 14px;
          .labelName {
            color: $c-8d;
            font-size: 14px;
            padding-right: 5px;
          }
          a.fromEmailText,
          a.toNumberText,
          a.fromNumberText {
            font-size: 14px;
            color: $blue-violet;
            text-decoration: none;
            cursor: pointer;
          }
        }
        .eventTypeBadge {
          position: relative;
          top: -2px;
          @include flex-align-center;
          background: #dcdcdc;
          padding: 1px 6px;
          font-size: 13px;
          border-radius: 3px;
          color: $c-33;
        }
      }
      .dialerRecipient {
        margin-bottom: 0 !important;
      }
      .toAddress {
        position: relative;
        @include flex-center;
        justify-content: flex-start;
        .labelName {
          color: $c-8d;
          font-size: 14px;
          padding-right: 5px;
        }
        .toEmailText {
          position: relative;
          display: flex;
          align-items: flex-end;
          flex-flow: wrap;
          column-gap: 6px;
          row-gap: 5px;
          a.chipEmail {
            position: relative;
            font-size: 12px;
            @include flex-center;
            border: 1px solid $c-ea;
            border-radius: 12px;
            color: $c-3b;
            padding: 5px 10px;
            text-decoration: none;
            line-height: inherit;
            cursor: pointer;
            @include ellipsis();
            max-width: 700px;
            &:not(:last-child) {
              margin-right: 5px;
            }
          }
        }
      }

      .toEmailDetails {
        position: relative;
        display: grid;
        grid-template-columns: 60% 40%;
        justify-content: space-between;
        margin: 5px 0;

        .emailActionListBtn {
          @include flex-center;
          justify-content: flex-end;
          ul {
            @include list-style-zero();
            @include flex-center;
            li {
              &.viewReply,
              &.reply,
              &.replyAll,
              &.forward {
                position: relative;
                cursor: pointer;
                @include flex-center;
                transition: all 0.3s ease-in-out;
                border-radius: 12px;
                padding: 2px 8px;
                color: $blue-violet;
                font-size: 12px;
                .name {
                  position: inherit;
                  top: 1px;
                  &.icon {
                    padding-left: 5px;
                  }
                }
                svg {
                  @include wt-ht(13px, 13px);
                  color: $blue-violet;
                }
                &:hover {
                  background: $c-f5;
                }
              }
            }
          }
        }
      }
      .recipientDetails {
        position: relative;
        @include flex-center;
        flex-wrap: wrap;
        margin: 6px 0;
      }
      .prevContent {
        position: relative;
        padding: 15px;
        margin-top: 10px;
        overflow: auto;
        border: 1px solid $c-dd;
        border-radius: 5px;
        min-height: 240px;
        max-height: 300px;
        transition: all 0.3s ease;
        color: $c-3b;
        font-size: 13px;
        line-height: 1.3;
        .ql-editor {
          padding: 0;
          white-space: unset !important;
        }
      }
      .seperateNotes {
        position: relative;
        .prevNotesSection,
        .prevCallNotesSection {
          margin-top: 15px;
          .label {
            font-size: 14px;
            color: $c-8d;
            margin-bottom: 5px;
          }
          .labelTitle {
            font-size: 14px;
            color: $c-8d;
          }
          .prevNotes {
            position: relative;
            padding: 15px;
            overflow: auto;
            border: 1px solid $c-dd;
            border-radius: 5px;
            min-height: 60px;
            max-height: 140px;
            transition: all 0.3s ease;
            color: $c-3b;
            font-size: 13px;
            line-height: 1.3;
            white-space: pre-wrap;
            .ql-editor {
              padding: 0;
              white-space: unset !important;
            }
          }
          &.taskBlock {
            .label {
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              .eventDate {
                position: relative;
                @include flex-center;
                justify-content: flex-end;
                color: $c-8d;
                font-size: 12px;
                padding-right: 2px;
              }
            }
            .prevNotes {
              min-height: 240px;
              max-height: 300px;
            }
          }
        }
        &.taskPreview {
          display: grid;
          grid-template-columns: repeat(2, calc(50% - 10px));
          justify-content: space-between;
          .prevNotes,
          .prevCallNotes {
            transition: all 0.3s ease;
            min-height: 300px !important;
            max-height: unset;
            white-space: pre-wrap;
          }
        }
      }
      .emailComposeEditor {
        @extend %relBlockWidth;
        &.disabled {
          opacity: 1;
          cursor: unset;
          pointer-events: none;
          user-select: none;
        }
        .attFileList,
        .fileUploadingLoader {
          .attName {
            margin-top: 0;
            position: relative;
            top: -1px;
          }
        }
        .inputWrapper {
          @extend %inputWrapper;
          position: relative;
          margin-bottom: 0;
          width: auto;
          .inputCont input.inputBox {
            width: 180px;
            line-height: 20px;
            font-size: 14px;
            border: none !important;
            &::placeholder {
              color: $c-dd;
            }
          }
          .focusBorder {
            display: none !important;
          }
          .errMsgText {
            bottom: -12px;
          }
        }
        .fromAddress {
          position: relative;
          @include flex-center;
          font-size: 14px;
          margin-top: 5px;
          margin-bottom: 2px;
          &.disabled {
            @include disable(0.5, unset);
          }
          .labelName {
            color: $c-8d;
            font-size: 14px;
            padding-right: 5px;
          }
          a.fromEmailText {
            font-size: 14px;
            color: $blue-violet;
            text-decoration: none;
            cursor: pointer;
          }
          .selectInboxDropdownSection {
            position: relative;
            top: 1px;
            .dropdownArrow {
              position: relative;
              cursor: pointer;
              font-size: 16px;
              background: $c-f5;
              color: $c-66;
              margin-left: 10px;
              border: 1px solid $c-ec;
              border-radius: 35px;
              transition: all 0.3s ease-in-out;
              &:hover {
                color: $c-33;
              }
            }
            .drop-down-menu {
              .dropdownList,
              #selectInboxDropdown {
                margin: 4px 0;
                li {
                  color: $c-33;
                  &.alreadySelected {
                    pointer-events: none;
                    user-select: none;
                    opacity: 0.6;
                    cursor: unset;
                  }
                }
              }
            }
            .connectedInboxOverlay {
              @include bg-mask(19, transparent);
            }
          }
        }
        .addRemoveEmailAddress {
          position: relative;
          @include flex-center;
          flex-wrap: wrap;
          column-gap: 5px;
          row-gap: 5px;
          .labelName {
            color: $c-8d;
            font-size: 14px;
            line-height: initial;
          }
          &.bccSection,
          &.ccSection {
            padding: 8px 0;
            border-bottom: 1px solid $c-dd;
          }
        }
        .senderAddressInputSection {
          position: relative;
          display: grid;
          grid-template-columns: calc(100% - 50px) 50px;
          justify-content: flex-end;
          &.disabled {
            @include disable(0.5, unset);
          }
          &.fullRowWidth {
            grid-template-columns: 100%;
          }
          padding: 8px 0;
          border-bottom: 1px solid $c-dd;
          .diffAddressList {
            @include flex-center;
            justify-content: flex-end;
            column-gap: 5px;
            .addressType {
              color: $c-3b;
              font-size: 14px;
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
        .columnLayout {
          position: relative;
          display: block;
          width: 100%;
        }
        .subjectTemplateWrap {
          position: relative;
          display: grid;
          grid-template-columns: calc(65% - 14px) 35%;
          justify-content: space-between;
          &.itemListBlock {
            grid-template-columns: 100%;
          }
          .subjectSection {
            padding: 8px 0;
            border-bottom: 1px solid $c-dd;
            &.outboxHighlight {
              @include flex-center;
              .label {
                @include flex-center;
                font-size: 14px;
                color: $c-8d;
              }
              .inputWrapper {
                &.subject {
                  .inputCont {
                    width: 720px;
                  }
                }
              }
              border-bottom: 1px solid $blue-violet;
            }
            &.disableSubject {
              @include disable(0.6, unset);
            }
            input,
            .inputBox {
              width: 100% !important;
              &::placeholder {
                color: $c-8d !important;
              }
              &:not(.template):focus::placeholder {
                color: $c-dd !important;
              }
            }
          }
          .insertTemplateSection {
            padding: 8px 0;
            border-bottom: 1px solid $c-dd;
            &.outboxHighlight {
              @include flex-center;
              .label {
                @include flex-center;
                font-size: 14px;
                color: $c-8d;
              }
              .inputWrapper {
                &.subject {
                  .inputCont {
                    width: 720px;
                  }
                }
              }
              border-bottom: 1px solid $blue-violet;
            }
            input,
            .inputBox {
              width: 100% !important;
              &::placeholder {
                color: $c-8d !important;
              }
              &:not(.template):focus::placeholder {
                color: $c-dd !important;
              }
            }
            .templateCont .upDownArrow {
              position: absolute;
              right: 15px;
              font-size: 22px;
              top: 10px;
              color: $c-8d;
            }
            .templateInserted {
              .inputCont {
                display: grid;
                grid-template-columns: calc(100% - 26px) 16px;
                justify-content: space-between;
                input {
                  @include ellipsis;
                }
                i {
                  padding: 2px;
                  border-radius: 35px;
                  font-size: 16px;
                  color: $c-3b;
                  cursor: pointer;
                  align-self: center;
                  transition: all 0.3s ease-in-out;
                  &:hover {
                    background: $c-f5;
                    color: black;
                  }
                }
              }
            }
            .templatesCardList {
              top: 44px;
            }
          }
          .insertTemplateSection {
            cursor: pointer;
          }
        }
      }
      &.editorBody {
        padding-bottom: 0px;
      }
      .editor {
        position: relative;
        margin: 1rem 0;
        .quill {
          padding: 5px !important;
          .ql-container {
            border: none !important;
            .ql-editor blockquote {
              border-left: 1px solid $c-cc !important;
              padding-left: 16px !important;
              margin: 0 !important;
            }
          }
          .ql-variables {
            display: none;
          }
        }
        &.outboxHighlight#emailComposeEditor .quill {
          border: 1px solid $blue-violet;
          .ql-tooltip {
            display: none;
          }
          .ql-variables {
            display: block;
          }
        }
      }
      .actionBtnList {
        position: relative;
        @include flex-center;
        justify-content: flex-end;
        column-gap: 15px;
        .actionBtn {
          font-size: 14px;
          border-radius: 5px;
          letter-spacing: 0.3px;
          min-width: 120px;
          margin: unset;
          height: 40px;
          padding: 0 12px;
          &.cancelBtn {
            border: solid 1px $blue-violet;
            color: $blue-violet;
            background: white;
          }
          &.loading {
            @include wt-ht(40px !important, 40px !important);
            transition: all 0.3s ease-in-out;
            min-width: unset !important;
            border-radius: 35px !important;
            .load-text {
              @include wt-ht(30px, 30px);
              top: 4px;
              left: 4px;
            }
          }
        }
      }
    }
    .cardFooter {
      position: relative;
      @include flex-center;
      justify-content: flex-end;
      font-size: 12px;
      .sequenceDetails {
        @include flex-center;
        justify-content: flex-end;
        .stepNumber {
          position: relative;
          margin-right: 10px;
          color: $c-3b;
          @include flex-center;
          justify-content: center;
          border: 1px solid $c-ea;
          border-radius: 12px;
          padding: 5px 10px;
        }
        .sequenceLink {
          position: relative;
          text-decoration: none;
          cursor: pointer;
          color: $blue-violet;
          border: 1px solid $c-ea;
          border-radius: 12px;
          padding: 5px 10px;
          margin-right: 5px;
          @include ellipsis();
          width: fit-content;
          max-width: 240px;
          transition: all 0.3s ease;
          &:hover {
            background: $c-f5;
          }
        }
      }
    }
  }
  .bgOverlay {
    @include bg-mask(19, rgba(0, 0, 0, 0.57));
  }
}
