.connectInboxDropdown {
  position: relative;
  border-bottom: 1px solid $c-ec;
  .insetDropdown {
    position: relative;
    display: block;
    width: calc(100% - 8px);
    margin: 0 0 10px 8px;
    z-index: 1;
    .horizontalBorderLine {
      position: absolute;
      width: 4px;
      height: 100%;
      background: #dee3ff;
      border-radius: 5px;
      left: -8px;
      top: 0px;
      z-index: 1;
    }
    .dropdownSelectedOpt {
      position: relative;
      border-bottom: 1px solid $c-ec;
      display: grid;
      grid-template-columns: calc(100% - 40px) 18px 18px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 5px 0px;
      height: 30px;
      letter-spacing: 0.3px;
      z-index: 1001;
      background: white;
      &.noneSelected {
        grid-template-columns: calc(100% - 20px) 18px;
      }
      .selectedItem {
        position: relative;
        font-size: 13px;
        color: $c-3b;
        z-index: 1;
        &.selectPlacholder {
          color: $c-66;
        }
        .email {
          position: relative;
          font-size: 14px;
          color: $blue-violet;
          @include ellipsis;
        }
      }
      .clearItemIcon {
        position: relative;
        font-size: 14px;
        cursor: pointer;
        z-index: 2;
        color: $c-66;
        padding: 2px;
        border-radius: 35px;
        background: $white;
        &:hover {
          padding: 2px;
          background: $c-f5;
          color: $c-3b;
        }
      }
      .arrowIcon {
        position: relative;
        color: $c-66;
        font-size: 18px;
        z-index: 1;
        transition: all 0.3s ease-in;
        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
    .ddBox {
      position: absolute;
      z-index: 1000;
      inset: 29px 0 0 0;
      ul,
      .ddList {
        @extend %relBlock;
        z-index: 2;
        border-radius: 5px;
        @include list-style-zero;
        max-width: 100%;
        max-height: 200px;
        overflow-y: auto;
        box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
        border: 1px solid $c-eb;
        padding: 7px 0;
        background: white;
        li,
        .ddItem {
          @extend %relBlock;
          line-height: normal;
          padding: 8px 10px;
          background: white;
          font-size: 14px;
          cursor: pointer;
          .name {
            @extend %relBlock;
            font-size: 11px;
            padding-bottom: 2px;
            color: $c-78;
          }
          .email {
            @extend %relBlock;
            font-size: 14px;
            color: $c-44;
            @include ellipsis;
          }
          &:hover {
            background: $c-f5;
            color: $c-33;
          }
          &.active {
            background: #f6f7ff;
            &:hover {
              background: #f6f7ff;
            }
            .email {
              color: $blue-violet;
            }
          }
        }
      }
      .ddOverlay {
        @include bg-mask(1, transparent);
      }
    }
  }
  .noDataAvailable {
    position: relative;
    padding: 12px 0;
    text-align: center;
    font-size: 14px;
    background-color: $c-f5;
  }
}
