@mixin posCenter() {
  position: absolute;
  top: 50%;
  left: 50%;
}
.bounceBallContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  .circleContainer {
    width: 400px;
    height: 180px;
    @include posCenter();
    transform: translate(-50%, -50%);
    &:after {
      content: "";
      width: 20px;
      height: 5px;
      position: absolute;
      background: $c-88;
      border-radius: 50%;
      bottom: 0;
      left: 50%;
      opacity: 0.4;
      animation: bounceBallShadow 2s linear infinite;
    }
    &:nth-child(2):after {
      animation-delay: -0.3s;
    }
    &:nth-child(3):after {
      animation-delay: -0.6s;
    }
    .circle {
      width: 15px;
      height: 15px;
      background: $blue-violet;
      border-radius: 50%;
      @include posCenter();
      animation: bounceBall 2s linear infinite;
    }
    &:nth-child(2) .circle {
      animation-delay: -0.3s;
    }
    &:nth-child(3) .circle {
      animation-delay: -0.6s;
    }
  }
}

@keyframes bounceBall {
  0% {
    transform-origin: 400% 50%;
    transform: rotate(0);
  }
  50% {
    transform-origin: 400% 50%;
    transform: rotate(360deg);
  }
  50.1% {
    transform-origin: -300% 50%;
    transform: rotate(0);
  }
  100% {
    transform-origin: -300% 50%;
    transform: rotate(-360deg);
  }
}

@keyframes bounceBallShadow {
  0% {
    transform: translate(0);
  }
  12.5% {
    transform: translate(50px) scale(0.6);
  }
  25% {
    transform: translate(110px);
  }
  37.5% {
    transform: translate(50px) scale(1.4);
  }
  50% {
    transform: translate(0);
  }
  62.5% {
    transform: translate(-50px) scale(0.6);
  }
  75% {
    transform: translate(-110px);
  }
  87.5% {
    transform: translate(-50px) scale(1.4);
  }
  100% {
    transform: translate(0px);
  }
}
