.creditUsagePopupWrapper {
  width: 380px;
  background-color: $white;
  position: fixed;
  top: 56px;
  right: 130px;
  z-index: 10;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid $c-dd;
  z-index: 14;
  box-shadow: 0 0 25px 0 rgba(59, 59, 59, 0.21);
  overflow-y: auto;
  h4 {
    margin-bottom: 12px;
    font-size: 16px !important;
  }
  .creditUsagePopupContent {
    width: 100%;
    .emailCreditsIcon,
    .phoneCreditsIcon {
      margin-right: 10px;
      @include flex-center;
    }
    .creditsText {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .flexWrappers {
    @include flex-center;
  }
  .flexWrapperWithSpace {
    @include flex-center;
    justify-content: space-between;
    margin-top: 12px;
  }
  .getMoreText {
    color: $blue-violet;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    &:hover {
      font-weight: bold;
    }
  }
  .creditFooterText {
    color: $blue-violet;
    @include flex-center;
    justify-content: center;
    cursor: pointer;
    margin-top: 12px;
    font-size: 14px;
    transition: all 0.3s;
    &:hover {
      svg {
        transform: translate(20%, 0);
      }
    }
    svg {
      margin-left: 8px;
      fill: $blue-violet;
      path {
        fill: $blue-violet;
      }
    }
  }
}

.creditsGridWrapper {
  padding: 14px;
  border: 1px solid $c-dd;
  border-radius: 8px;
  margin: 12px 0;
  .flexWrapper {
    @include flex-center;
    justify-content: space-between;
  }
  .creditsLimitTitle {
    font-size: 12px;
  }
  .creditsLimitCount {
    font-size: 10px;
    &.linear-background {
      @include wt-ht(50px, 10px);
    }
  }
  .linearProgressWrapper {
    padding: 8px 0;
    .linear-background {
      @include wt-ht(100%, 10px);
    }
  }
  .remainingCount {
    font-size: 10px;
    width: 100%;
    text-align: right;
    @include flex-center;
    justify-content: flex-end;
    .linear-background {
      @include wt-ht(100px, 10px);
    }
  }
  .linearProgressBar {
    width: 100%;
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 15px;
    overflow: hidden;
    position: relative;

    .backgroundProgress {
      width: 100%;
      height: 100%;
      background-color: #ddd;
      animation: move 8s linear infinite;
      background-size: 40px 40px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .linearProgress {
      height: 100%;
      border-radius: 15px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 0; /* Start with 0 width and animate to progress */
      &.applyTransition {
        transition: width 2s ease-in-out;
      }
    }
  }
}
.creditsUsageHead {
  background-color: #e6e6e6;
  height: 50px;
  @include flex-align-center;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  &.linear-background {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    border: 0;
  }
  &.borderHeader {
    border: 1px solid $blue-violet;
  }
  &.noCursor {
    cursor: default;
  }
  .limitText {
    font-size: 14px;
  }
  .removeDailyLimit {
    color: $blue-violet;
    @include flex-align-center;
    .arrowIcon {
      margin-left: 10px;
      path {
        fill: $blue-violet;
      }
    }
  }
  .resetCreditsIcon {
    @include wt-ht(36px, 36px);
    background-color: $white;
    border-radius: 50%;
    @include flex-align-center;
    margin-right: 12px;

    svg {
      @include wt-ht(20px, 20px);
      @include flex-align-center;
    }
  }
}
.finalTimerWrapper {
  background-color: #ff6057;
  color: $white;
  margin-left: 8px;
  @include wt-ht(fit-content, 28px);
  @include flex-align-center;
  padding: 8px 10px;
  width: 80px;
  font-size: 14px;
}

.creditUsageMask {
  @include bg-mask(13, transparent);
  width: 90px;
  height: 36px;
  right: 172px !important;
  inset: unset;
  top: 30px;
}
