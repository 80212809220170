$chkbox-border-color: #eaedff;

.checkboxWrapper {
  @include flex-center();
  user-select: none;
  .commonFilledCheckBox {
    position: relative;
    @include wt-ht(15px, 15px);
    border: 1px solid $c-cc;
    -webkit-perspective: 18px;
    perspective: 18px;
    display: block;
    border-radius: 3px;
    transform: translateZ(0);
    overflow: hidden;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover {
      border-color: $blue-violet;
    }
    .checkBoxCard {
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      position: relative;
      @include wt-ht(13px, 13px);
      .checkSvgIcon,
      .emptyCheckBox {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        @include wt-ht(13px, 13px);
        border-radius: 3px;
        svg {
          margin-top: 1px;
          fill: none;
          @include wt-ht(11px, 11px);
          path {
            stroke: $blue-violet;
            stroke-width: 2.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          }
        }
      }
      .emptyCheckBox {
        background-color: $white;
        z-index: 1;
      }
      .checkSvgIcon {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
        background: $chkbox-border-color;
        text-align: center;
        color: $blue-violet;
        width: 13px;
        height: 13px;
        line-height: 15px;
        box-shadow: 0 0 0 1px $chkbox-border-color;
      }
    }
    &.checked {
      border-color: $blue-violet;
      background: $chkbox-border-color;
      .checkBoxCard {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
      }
    }
  }
  .checkBoxName {
    padding-left: 7px;
    font-size: 14px;
    color: $c-78;
    word-break: break-word;
    cursor: pointer;
    line-height: 14px;
  }
  .customTooltip {
    position: absolute;
    .tooltipBody {
      top: 15px;
      right: -100px;
      background-color: $c-4a;
      box-shadow: unset;
      .tooltipText {
        color: $white;
      }
    }
    .sharpPointer {
      top: 8px;
      right: -15px;
      background-color: $c-4a;
      border-right: 1px solid $c-4a;
      border-top: 1px solid $c-4a;
      z-index: 0;
    }
  }
  .tooltipWrap {
    @include flex-center();
    width: 14px;
    height: 15px;
    margin-left: 6px;
    position: relative;
    cursor: pointer;
    top: -2px;

    .iIcon {
      font-size: 14px;
    }
  }

  .commonFilledCheckBox.disabled {
    &:hover {
      pointer-events: none;
    }

    + .checkBoxName {
      cursor: not-allowed;
    }
  }
}
