.addDefaultInboxPopup {
  @extend %popupCard;
  color: $c-3b;
  .popupHeader {
    padding-bottom: 15px;
    @include flex-align-path-justify(center, row, space-between);

    .flexWrapper {
      @include flex-center;
      .imgType {
        @include wt-ht(20px, 20px);
        margin-right: 10px;
      }
    }

    .title {
      text-align: left;
      color: $c-3b;
      font-size: 20px;
    }

    .commonPopupCloseIcon {
      font-size: 20px;
      padding: 3px;
      border-radius: 50%;
      color: $c-53;
      cursor: pointer;

      &:hover {
        color: #000;
        background-color: $c-dd;
      }
    }
  }
  hr {
    border: 0;
    border-bottom: 1px solid $bg-default;
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
  }
  label {
    font-size: 12px;
  }
  .card {
    width: 400px;
    max-height: 700px;
    overflow: auto;
    text-align: center;
    padding: 20px;
    .inputWrapper {
      @extend %inputWrapper;
      &.phoneNumber {
        margin-bottom: 20px;
      }
      .activeBorder {
        border-color: $blue-violet !important;
      }
    }
    .createTeamButton {
      @extend %primaryBlueBtn;
      width: 50%;
      border-radius: 10px;
    }
  }
  .popupBody {
    .selectListWrapper {
      border: 1px solid #eceff1;
      border-radius: 10px;
      margin-bottom: 12px;
      .selectFromLists {
        text-align: left;
        padding: 12px;
        border-bottom: 1px solid #eceff1;
      }
    }
    .radioBtnCont {
      border-bottom: 1px solid #eceff1;
      padding: 8px 0;
      @include flex-center;
      &:last-child {
        border-bottom: 0;
      }
      .circle {
        &.linear-background {
          @include wt-ht(16px, 16px);
          border-radius: 50%;
          margin-right: 12px;
        }
      }
      .radioButtonCont {
        &.linear-background {
          width: 60% !important;
          height: 12px;
        }
      }
    }
    .selectListCont {
      padding: 12px;
    }
    .scrollUserList {
      position: relative;
      display: block;
      .inboxListWrap {
        max-height: 154px;
        overflow-y: auto;
      }
    }
    .inboxDetails {
      .inboxEmail {
        font-size: 16px;
        color: $c-3b;
        margin-bottom: 4px;
        margin-left: 10px;
      }
    }
    .connectBtn {
      border: solid 1px $blue-violet;
      background: $blue-violet;
      color: $white;
      margin-top: 16px;
      @include wt-ht(150px, 32px);
      padding: 0 12px;
      &.loading {
        @include wt-ht(32px!important, 32px!important);
        .load-text {
          top: 4px;
          left: 4px;
          @include wt-ht(22px!important, 22px!important);
        }
      }
    }

    .multiSelectSection {
      .checkBoxSection {
        border-bottom: 1px solid $c-dd;
        padding: 12px 10px;
        align-items: flex-start;
        .commonFilledCheckBox {
          top: 2px;
          @include wt-ht(16px, 16px);
          &.checked {
            border-color: $blue-violet !important;
            background: $blue-violet !important;
          }
          &:hover {
            border-color: $blue-violet !important;
          }
          .checkSvgIcon {
            background: $blue-violet !important;
            color: $white !important;
            box-shadow: 0 0 0 1px $blue-violet !important;
            svg path {
              stroke: $white !important;
            }
          }
        }
        .checkBoxName {
          width: 100%;
          padding-top: 0;
          padding-left: 10px;
        }
      }
    }
    .searchInputBtn {
      border: 1px solid $b-c-1;
      border-radius: 5px;
      margin-bottom: 12px;
      .searchIcon {
        color: #787878;
        font-size: 18px;
        padding-left: 11px;
        width: unset;
        height: unset;
      }
      .searchTextBox {
        width: 100%;
        .searchInputBoxWrapper {
          width: 100%;
        }
        input {
          width: 100%;
        }
      }
    }
  }
}

.emptyInbox {
  .emptyInboxText {
    font-size: 14px;
    color: $c-3b;
    margin-bottom: 16px;
  }
}
