.pullContactsWrapper {
  @extend %popupCard;
  z-index: 21;
  overflow: unset;

  .closeIcon {
    color: #333;
    font-size: 20px;
    position: absolute;
    top: 16px !important;
    right: 16px !important;
    cursor: pointer;
    &:hover {
      border-radius: 50%;
      background-color: #ddd;
    }
  }
  .card {
    width: 700px;
    max-height: 70%;
    text-align: center;
    padding: 20px;
    border-radius: 6px;
    position: relative;
    @include flex-align-center;
    flex-direction: column;
    .pullContactsText {
      color: #787878;
      font-size: 20px;
      margin-bottom: 24px;
    }
    .pasteInputWrapper {
      height: 54px;
      width: 90%;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      padding: 0 16px;
      &.disableInput {
        background-color: #f7f7f7;
        cursor: default;
        pointer-events: none;
        user-select: none;
        opacity: 0.7;
      }
      &::placeholder {
        color: #d9d9d9;
      }
    }
    .searchWrapper {
      background-color: $blue-violet;
      width: 100px;
      color: white;
      font-size: 16px;
      @include flex-center;
      margin-top: 24px;
      cursor: pointer;
    }
    .extractBtnWrapper {
      display: flex;
      flex-direction: column;
      padding: 0 24px;
      .listSequenceContainer {
        margin-bottom: 24px;
        .checkBoxName {
          text-align: left;
        }
      }
    }
    .extractBtn {
      background-color: $blue-violet;
      width: 100px;
      color: white;
      font-size: 16px;
      @include flex-center;
      cursor: pointer;
      margin-top: 16px;
      &.disableExtractBtn {
        background-color: #868496;
        cursor: default;
        pointer-events: none;
      }
    }
    .extractContactsWrapper {
      width: 90%;
      margin-top: 16px;
      border: 1px dashed $blue-violet;
      border-radius: 8px;
      background-color: #f7f7f7;
      &.showListSeq {
        .extractTextWithNotes svg {
          margin-right: 6px;
          @include wt-ht(30px, 34px);
        }
      }
      .extractTextWithNotes {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        align-items: center;
        padding: 16px 24px;
        margin: 0 auto;
        svg {
          margin-right: 16px;
          @include wt-ht(90px, 80px);
        }
        .flexWrapWithIcon {
          @include flex-center;
        }
        .flexWrapper {
          display: flex;
          align-items: flex-start;
          margin-top: 10px;
          padding: 4px 0px;
          .infoOutlinedIcon {
            font-size: 12px;
            color: #787878;
            position: relative;
            left: 8px;
          }
        }
        .smallNotesText {
          font-size: 12px;
          color: #787878;
          text-align: left;
        }
      }
      .extractContactsText {
        font-size: 18px;
        display: flex;
        align-items: center;
        strong {
          margin-right: 4px;
        }
      }

      .listFlexColumn {
        position: relative;
      }
      .listSequenceWrapper {
        .listFlex {
          @include flex-center;
          justify-content: space-between;
        }
        .checkBoxSection {
          padding: 0;
          margin-bottom: 18px;
        }
      }
    }
  }
}
.pullContactsMask {
  @include bg-mask(20, rgba(0, 0, 0, 0.65));
}

.customAutoCompleteDropdownWrapper {
  position: relative;
  z-index: 12;
  .dropdownHeader {
    background-color: $white;
  }
  .dropdownHeader,
  .dropdownPanel .dropdownList {
    width: 250px !important;
  }
  .clearIpIcon {
    position: relative;
    font-size: 14px;
    cursor: pointer;
    z-index: 2;
    color: $c-66;
    padding: 2px;
    border-radius: 35px;
    background: $white;
    &:hover {
      padding: 2px;
      background: $c-f5;
      color: $c-3b;
    }
  }
  .arrowIcon {
    position: relative;
    color: $c-66;
    font-size: 18px;
    z-index: 1;
    transition: all 0.3s ease-in;
    &.rotate {
      transform: rotate(180deg);
    }
  }
  .dropdownInputHeader {
    padding: 0 12px;
    border: 1px solid $blue-violet;
    border-radius: 6px;
    @include flex-center;
    justify-content: space-between;
    height: 42px;
    .title {
      font-size: 16px;
      color: $blue-violet;
    }
    .arrow i {
      color: $blue-violet;
    }
  }
  &.disableDropdown,
  &.restrictAllowSequence {
    pointer-events: none;
    .dropdownInputHeader {
      border: 1px solid #afafaf;
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
      .dropdownTitle {
        border: 0;
        width: 90%;
        &::placeholder {
          color: #d9d9d9;
        }
      }
      .arrow i {
        color: #d9d9d9;
      }
    }
  }
  &.restrictAllowSequence {
    .dropdownInputHeader,
    .dropdownTitle {
      background-color: #f7f7f7;
    }
  }

  .dropdownInputHeader {
    .dropdownTitle {
      border: 0;
      width: 90%;
      &::placeholder {
        color: #afafaf;
      }
    }
    .arrow {
      @include flex-center;
      @include wt-ht(20px, 20px);
      i {
        color: #afafaf;
      }
    }
  }
  .dropdownPanel {
    background: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
    position: absolute;
    z-index: 12;
    opacity: 1;
    top: 42px;
    border: 1px solid $c-eb;
    overflow: auto;
    .dropdownList {
      @include list-style-zero;
      padding: 5px 0;
      transition: opacity 0.3s ease;
      overflow: auto;
      width: 250px;
      max-height: calc(212px - 24px);
      .noResult {
        font-size: 14px;
        color: $c-75;
        padding: 10px;
        &.displayCenter {
          display: flex;
          justify-content: center;
        }
      }
      .dropdownOptions {
        position: relative;
        @include flex-center;
        padding: 6px 10px;
        height: 36px;
        font-size: 14px;
        cursor: pointer;
        color: $c-66;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover:not(.noResultsFound) {
          background: $c-f5;
          color: $c-33;
        }
        &.noResultsFound {
          color: $c-66;
          text-align: center;
          background: $c-f9;
          padding: 10px;
          cursor: default;
          pointer-events: none;
        }
        &.hasCreateList {
          width: 100%;
          background-color: #f7f5fb;
          .updatedName {
            width: 100%;
          }
        }
        .updatedName {
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 210px;
          &.linear-background {
            width: 100%;
          }
        }
        .defaultValue {
          letter-spacing: 0.4px;
          padding: 5px;
          font-size: 10px;
          border-radius: 3px;
          margin-left: 10px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          background-color: #e3e3e3;
          color: #757575;
        }
        &.active {
          color: $blue-violet;
          background: #f3f9fa;
          font-weight: 500;
        }
        &:hover:not(.activeLimit) {
          background: $c-f5;
          color: $c-44;
        }
      }
    }
  }
  .createNewListBtn {
    height: 36px;
    display: flex;
    align-items: center;
    .btnText {
      color: $blue-violet;
      font-size: 14px;
    }
  }
}
.autoCompleteDropdownOverlay {
  @include bg-mask(11, transparent);
}

.createListErrMsg {
  color: red;
  font-size: 10px;
  position: absolute;
  margin-top: 8px;
  left: 27px;
}
