.tracking {
  position: relative;
  display: block;
  @include wt-ht(100%, 100%);
  background: $c-f5f8;
  border-color: $bg-default;

  .headerSection {
    padding: 0px;
  }

  .bodySection {
    position: relative;
    @include wt-ht(100%, calc(100% - 60px));
    padding: 12px;
    .visitorsInfoSection {
      position: relative;
      height: 100%;
      .viewSection {
        display: flex;
        justify-content: space-between;
        background: white;
        padding: 10px;
        border-radius: 10px;
        height: 60px;
        .filterByDateTab.disabled {
          opacity: 0.1;
        }
        .viewTitle {
          @include flex-center;
          color: $c-3b;
          font-size: 16px;
          font-weight: 700;
        }
        .dateFilter {
          @extend %dateFilter;
        }
        .overlayCustomDateFilter {
          @include bg-mask(4, transparent);
        }
      }
      .visitAnalyticList {
        position: relative;
        margin-top: 12px;
        display: grid;
        grid-template-columns: repeat(3, auto);
        column-gap: 10px;
        &.disabled {
          @include disable(0.2, unset);
        }
        .visitCont {
          @include flex-center;
          justify-content: space-between;
          font-size: 14px;
          padding: 15px;
          height: 78px;
          border-radius: 10px;
          background: white;
          border: 1px solid;
          &.visits {
            border-color: $c-28;
          }
          &.uniqueVisits {
            border-color: $blue-violet;
          }
          &.avgVisits {
            border-color: $c-1eb9d5;
          }
          .visitTitleAndTime {
            @include flex-align-path-justify(start, column, space-between);
            align-self: stretch;
            .visitTitle {
              &.linear-background {
                width: 90px;
              }
            }
            .visitTime {
              &.linear-background {
                width: 130px;
              }
            }
          }
          .visitTimeInMin {
            font-weight: 700;
            &.visits {
              color: $c-28;
            }
            &.uniqueVisits {
              color: $blue-violet;
            }
            &.avgVisits {
              color: $c-1eb9d5;
            }
            &.linear-background {
              width: 60px;
            }
          }
        }
      }
      .visitorsDataSection {
        position: relative;
        margin-top: 12px;
        @include wt-ht(100%, calc(100% - 160px));
        border: 1px solid $c-dd;
        border-radius: 10px;
        background: white;
        .tabletitle {
          font-size: 16px;
          color: $c-3b;
          @include flex-center;
          padding: 16px;
          font-weight: 700;
          &.disabled {
            @include disable(0.2, unset);
          }
        }
        .visitorsDataTable {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @include wt-ht(100%, calc(100% - 50px));
          table {
            @include wt-ht(100%, 100%);
            .vistorsTableHeaders {
              display: grid;
              grid-template-columns: 22% 16% 12% 12% 12% 12% auto;
              background: #f9f9ff;
              height: 40px;
              align-content: center;
              align-items: center;
              column-gap: 10px;
              font-size: 14px;
              color: $c-3b;
              font-size: 14px;
              padding: 12px 16px;
              &.disabled {
                @include disable(0.2, unset);
                border-bottom: 2px solid $c-dd;
                border-top: 1px solid $c-dd;
              }
              .sessionDetailHeader {
                @include flex-center;
                justify-content: center;
              }
            }
            .emptyDataMsg {
              height: unset;
              min-height: 450px;
              @include flex-align-center;
            }
            .navigateToSettings {
              position: relative;
              height: unset;
              min-height: 360px;
              display: block;
              text-align: center;
              padding: 60px 0;
              .illustrationImg {
                margin-bottom: 30px;
                img {
                  @include wt-ht(100px, 80px);
                }
              }
              .infoText {
                font-size: 14px;
                color: $c-343a40;
                margin-bottom: 20px;
              }
              .navigateToSettingsBtn {
                border: 1px solid $blue-violet;
                color: $blue-violet;
                padding: 10px;
                border-radius: 5px;
                cursor: pointer;
                width: fit-content;
                height: 34px;
                font-size: 14px;
                margin: 0 auto;
                @include flex-center;
              }
            }
            .vistorsTableBody {
              display: block;
              height: 100%;
              overflow: auto;
            }
            .vistorsTableBodyRow {
              display: grid;
              grid-template-columns: 22% 16% 12% 12% 12% 12% auto;
              column-gap: 10px;
              font-size: 14px;
              border-bottom: 1px solid $c-dd;
              align-content: center;
              align-items: center;
              font-size: 14px;
              padding: 12px 16px;
              cursor: pointer;
              .name {
                @include flex-center;
                .circleOwner {
                  @include flex-center;
                  justify-content: center;
                  @include wt-ht(26px, 26px);
                  border-radius: 35px;
                  padding-top: 2px;
                  border: 1px solid $blue-violet;
                  color: $blue-violet;
                  text-transform: uppercase;
                  font-size: 12px;
                }
                .userName {
                  position: relative;
                  margin-left: 8px;
                }
              }
              .domain {
                @include flex-center;
                svg {
                  @include wt-ht(22px, auto);
                }
                .domainName {
                  position: relative;
                  margin-left: 8px;
                }
              }
              .country {
                @include flex-center;
                .countryCode {
                  margin-right: 10px;
                }
              }
              .sessionStartTime,
              .sessionDate {
                @include flex-center;
                svg {
                  margin-right: 6px;
                }
              }
              .nextIcon {
                @include flex-center;
                justify-content: center;
                cursor: pointer;
              }
              &.bodyloader {
                display: grid;
                width: 100%;
                column-gap: 10px;
                .name {
                  &.linear-background {
                    width: 70%;
                  }
                }
                .domain {
                  &.linear-background {
                    width: 50%;
                  }
                }
                .country {
                  &.linear-background {
                    width: 50%;
                  }
                }
                .startDate {
                  &.linear-background {
                    width: 40%;
                  }
                }
                .startTime {
                  &.linear-background {
                    width: 40%;
                  }
                }
                .time {
                  &.linear-background {
                    width: 40%;
                  }
                }
                .nextIcon {
                  &.linear-background {
                    width: 30%;
                    position: relative;
                    left: 50px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .visitorsDetailedInfoSection {
      position: relative;
      height: 100%;
      width: 100%;
      .detailedInfoHeaderSection {
        @include wt-ht(100%, 60px);
        background: white;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 60px auto;
        column-gap: 15px;
        background: none;
        margin-bottom: 10px;
        .backButton {
          position: relative;
          width: 60px;
          background: $white;
          border-radius: 10px;
          @include flex-align-center;
          cursor: pointer;
          &:hover {
            i {
              color: $blue-violet;
            }
          }
          i {
            position: relative;
            font-size: 22px;
            color: $c-9e;
          }
        }
        .domainInfoAndNavigationCont {
          position: relative;
          background: $white;
          border-radius: 10px;
          font-size: 16px;
          display: grid;
          grid-template-columns: repeat(2, auto);
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          .domainInfo {
            .pageHeading {
              color: $c-3b;
            }
            .domainName {
              color: $blue-violet;
              font-size: 16px;
              margin-left: 20px;
            }
          }
          .countAndNavigation {
            position: relative;
            @include flex-center;
            justify-content: space-between;
            width: 220px;
            .pageCount {
              font-size: 16px;
            }
            .navigationCont {
              display: grid;
              grid-template-columns: 54px calc(100% - 64px);
              grid-column-gap: 10px;
              -webkit-column-gap: 10px;
              column-gap: 10px;
              height: 34px;
              align-items: center;
              .nextButton,
              .prevButton {
                position: relative;
                border: 1px solid $c-dd;
                padding: 3px;
                border-radius: 5px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 54px;
                i {
                  color: $blue-violet;
                }
                &.disabled {
                  @include disable(0.5, unset);
                }
              }
            }
            .navTaskBtnCont {
              display: grid;
              grid-template-columns: 54px calc(100% - 64px);
              column-gap: 10px;
              height: 34px;
              align-items: center;
              .navToPrev,
              .navToNext {
                @extend %navigation;
              }
            }
          }
        }
      }
      .visitorsDetailedInfoBody {
        position: relative;
        @include wt-ht(100%, calc(100% - 75px));
        background: $white;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 360px auto;
        column-gap: 15px;
        background: none;
        .visitorsDetails {
          background: $white;
          border-radius: 10px;
          border: 1px solid $c-dd;
          padding: 10px;
          overflow-y: scroll;
          overflow-x: hidden;
          .emptyVisitorMsg {
            @include wt-ht(100%, 100%);
            @include flex-align-center;
          }
          .vistorDetailCard {
            width: 340px;
            margin-bottom: 10px;
            border: 1px solid $c-dd;
            border-radius: 10px;
            cursor: pointer;
            &.highlight {
              border: 1px solid $blue-violet;
            }
            .visitorName {
              font-size: 16px;
              color: $c-3b;
              border-bottom: 1px solid $c-dd;
              padding: 12px 16px;
              .name {
                &.linear-background {
                  width: 170px;
                }
              }
            }
            .vistorCardBody {
              display: grid;
              grid-template-columns: repeat(2, 50%);
              padding: 16px;
              .companyAndTotalDuration {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                font-size: 14px;
                .companyDetails {
                  @include flex-center;
                  margin-bottom: 12px;
                  .name {
                    margin-left: 5px;
                  }
                }
                .totalDuration {
                  color: $c-3b;
                  @include flex-center;
                  .duration {
                    margin-left: 5px;
                    line-height: 1.1;
                    font-size: 14px;
                  }
                }
              }
              .countryDetails {
                display: flex;
                justify-content: right;
                font-size: 14px;
                .countryCodeCont {
                  margin-right: 5px;
                }
                .countryName {
                  font-size: 14px;
                  &.linear-background {
                    width: 80px;
                  }
                }
              }
            }
          }
        }
        .sessionDetails {
          position: relative;
          background: $white;
          border-radius: 10px;
          border: 1px solid $c-dd;
          overflow: scroll;
          padding: 20px;
          .emptySessionDetail {
            @include wt-ht(100%, 100%);
            @include flex-align-center;
          }
          .headerCont {
            border-bottom: 1px solid $c-dd;
            padding-bottom: 20px;
            font-size: 16px;
            color: $c-3b;
          }
          .sessionDetailCard {
            display: grid;
            grid-template-columns: 4% 13% 13% auto 15%;
            align-items: center;
            border-bottom: 1px solid $c-dd;
            font-size: 14px;
            column-gap: 10px;
            padding: 20px;
            .serialNo {
              &.linear-background {
                width: 40px;
              }
            }
            .sessionLink {
              @include ellipsis;
              &.linear-background {
                width: 550px;
              }
            }
            .sessionDuration {
              @include flex-align-center;
              &.linear-background {
                width: 80px;
              }
            }
            .sessionStartTime {
              @include flex-center;
              line-height: 1;
              &.linear-background {
                width: 80px;
              }
              .startTime {
                margin-left: 5px;
              }
            }
            .sessionDate {
              @include flex-center;
              line-height: 1;
              &.linear-background {
                width: 80px;
              }
              .startDate {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}
