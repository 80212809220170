.comingSoonFeaturePopup {
  @extend %popupCard;
  -webkit-user-select: none;
  user-select: none;
  z-index: 99999999;
  .card {
    width: 500px;
    .cardBody {
      padding: 11px 0 10px;
      .comingSoonImg {
        position: relative;
        padding-bottom: 20px;
        text-align: center;
        height: 120px;
        img {
          margin: 0 auto;
          @include wt-ht(100px, 100px);
        }
      }
      h4 {
        text-align: center;
        margin-bottom: 15px;
        font-size: 18px;
        color: $c-33;
      }
      p {
        text-align: center;
        font-size: 14px;
        line-height: 1.5;
        color: $c-66;
        margin-bottom: 20px;
        b {
          color: $c-44;
        }
      }
      .comingSoonBtn {
        @extend %primaryBlueBtn;
        min-width: 100px;
      }
    }
  }
}
.overlayComingSoonBg{
  @include bg-mask(99999998, rgba(0, 0, 0, 0.57));
}
