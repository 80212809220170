%freeSlotCont {
  .freeSlotCont {
    @extend %relBlock;
    @include wt-ht(100%, 100%);
    padding: 15px;
    .slotDate {
      @extend %relBlock;
      letter-spacing: 0px;
      color: #343a40;
      font-size: 16px;
      margin: 20px 0 30px;
      &.linear-background {
        height: 20px;
      }
    }
    .slotsAvailablelist {
      @extend %relBlock;
      height: 100%;
      padding-top: 70px;
      .collapsible {
        text-align: center;
        font-weight: 600;
        padding: 5px 0;
        height: 28px;
        font-size: 16px;
        color: #343a40;
        &::after {
          content: "";
        }
      }

      .collapsiveContentBody {
        width: 100%;
        border-bottom: none !important;
        overflow: auto !important;
        max-height: 350px;
        transition: max-height 0.2s ease-out;
        .flexWrapper {
          @include flex-center();
          justify-content: space-between;
        }
        .noSlotAvailable {
          @extend %relBlock;
          background: $c-f5;
          padding: 20px 0;
          font-size: 13px;
          color: #343a40;
          text-align: center;
        }
      }
      .slotsAvailable {
        @include list-style-zero;
        padding: 15px 5px 15px 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        row-gap: 12px;
        column-gap: 12px;
        li,
        .timeSlot {
          position: relative;
          @include flex-align-center;
          height: 36px;
          background: white;
          border: 2px solid $c-dd;
          border-radius: 5px;
          font-size: 13px;
          letter-spacing: 0px;
          color: #343a40;
          line-height: initial;
          transition: all 0.3 ease;
          cursor: pointer;
          width: 170px;
          max-width: 190px;
          span.linear-background {
            @extend %relBlock;
            @include flex-align-center;
            @include wt-ht(30%, 13px);
            border-radius: 5px;
            margin: 0 8px;
          }
          &.active {
            border-color: $blue-violet;
            color: $blue-violet;
            cursor: unset;
            user-select: none;
            pointer-events: none;
          }
          &:hover:not(.active) {
            color: $blue-violet;
            border-color: $blue-violet;
          }
          &.linear-background:hover {
            border-color: $c-dd;
          }
        }
      }
    }
  }
}
