.connectedAccountsView {
  height: 100%;

  .connectLinkedInBody {
    height: calc(100% - 56px);
    @include flex-center;
    overflow: auto;
    justify-content: center;

    &.linkedInAccountConnected {
      align-items: flex-start;
    }
  }

  .connectedLinkedInAccountsBody {
    padding: 15px 10px;
    width: 100%;
  }

  .connectedAccountsHeader {
    @include flex-center();
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid $c-ec;
  }

  .tabNavigationBody {
    width: 100%;

    .tabNavigation {
      width: 100%;
      background-color: $white;
      border: 0;
    }

    .tabItem {
      top: 0px;
    }
  }

  .dropdownBtn {
    @extend %relBlock;

    button {
      font-size: 14px;
      padding: 5px 0;
      border-radius: 5px;
      letter-spacing: 0.3px;
      min-width: 150px;
      margin: unset;
      font-weight: 500;

      &.connectInboxDropdownBtn,
      &.ConnectedLinkedInButton {
        border: solid 1px $blue-violet;
        background: $blue-violet;

        &:hover {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
        }
      }
    }

    .connectLinkedIn.inboxTypeOptions {
      width: 265px !important;
    }

    .inboxTypeOptions {
      position: absolute;
      @include list-style-zero;
      background: white;
      z-index: 20;
      right: 0;
      border-radius: 7px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px $c-ec;
      padding: 15px;
      width: 225px;

      .option {
        position: relative;
        @include flex-center;
        cursor: pointer;

        &.smtpImapInbox,
        &.connectLinkedIn,
        &.connectSalesNavigator {
          margin: 0 auto;
          width: calc(100% - 4px);
          height: 36px;
          border: 1px solid $c-66;
          padding: 0 10px;

          .smtpImapIcon {
            font-size: 18px;
            color: $c-66;
            margin-right: 8px;
          }

          svg {
            margin-right: 8px;
          }

          .btnCta {
            color: $c-66;
            position: relative;
            font-weight: 600;
            font-size: 13px;
            line-height: 1px;
          }
        }

        &.microsoftInbox {
          padding: 0 2px;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        .imgType {
          position: relative;
          @include flex-center;
        }

        .text {
          font-size: 14px;
          color: $c-78;
          font-weight: 600;
        }

        img {
          max-width: 100%;
        }
      }
    }

    .inboxTypeOptionsOverlay {
      @include bg-mask(19, transparent);
    }
  }
}

.linkedInNotConnectedWrapper {
  @extend %popupCard;

  .card {
    width: 450px;
    max-width: 500px;
    max-height: 350px;
    overflow: auto;
    text-align: center;
    padding: 20px;
    border-radius: 6px;
  }

  .linkedInHeader {
    @include flex-center;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid $c-dd;
    position: relative;

    .title {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .btnContainer {
    @include flex-center;
    justify-content: center;

    .connectLinkedInBtn {
      background-color: $blue-violet;
      color: $white;
      margin: 0;
      width: 110px;
    }

    .cancelLinkedInBtn {
      background-color: transparent;
      color: $blue-violet;
      margin: 0;
      border: 1px solid $blue-violet;
      margin-right: 8px;
      width: 110px;
    }
  }

  .commonPopupCloseIcon {
    font-size: 20px;
    position: absolute;
    right: 0px;
    padding: 3px;
    border-radius: 50%;
    color: $c-53;
    cursor: pointer;

    &:hover {
      background: $c-dd;
      color: $black;
    }
  }

  .noLinkedInText {
    font-size: 16px;
    font-weight: 700;
  }

  .notifySmallText {
    font-size: 13px;
    margin: 16px 0;
  }

  .linkedInSigninBtn {
    border: 1px solid $blue-violet;
    background-color: $blue-violet;
    color: $white;
    font-size: 14px;

    svg {
      width: 14px;
      height: 14px;
      margin-right: 6px;

      path {
        fill: $white;
      }
    }
  }
}

.progressContainer {
  border: 0;
  background-color: transparent;
}

.highlightLink {
  color: $blue-violet;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
}

.diffAccountText {
  font-size: 13px;
  color: $c-8d;
}

.installExtensionWrapper {
  .headingTitle {
    font-size: 14px;
    color: $c-3b;
  }

  .notification {
    background-color: #3953fb1a;
    font-size: 14px;
    color: $c-3b;
    margin-top: 10px;
    padding: 10px;
  }
}

.linkedInDetailsList {
  margin-top: 26px;
  margin-bottom: 20px;
}

.linkedInDetailsCard {
  @include flex-center;
  padding: 16px;
  border: 1px solid $c-dd;
  border-radius: 5px;
  position: relative;
  cursor: pointer;

  &.active {
    border: 1px solid $blue-violet;

    .absoluteIcon {
      border: 1px solid $blue-violet;
    }
  }

  .absoluteIcon {
    position: absolute;
    top: -10px;
    right: 10px;
    border: 1px solid $c-dd;
    padding: 4px 10px;
    border-radius: 5px;
    z-index: 2;
    background-color: $white;
    @include flex-center;
  }

  .salesNavigatorIcon {
    @include wt-ht(100px, auto);
  }

  .leftLayout {
    margin-right: 8px;
    position: relative;

    .absoluteMiniIcon {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      background-color: $white;
      @include wt-ht(16px, 16px);
    }

    .linkedInImg {
      @include wt-ht(52px, 52px);
      border-radius: 50%;
    }
  }

  .rightLayout {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;

    .fullName {
      text-transform: capitalize;
      font-size: 16px;
      margin-bottom: 4px;
      color: $c-343a40;
    }

    .jobDetails {
      font-size: 13px;
      margin-bottom: 6px;
      color: $c-343a40;
    }

    .followers {
      font-size: 13px;
      color: $c-8d;
    }
  }
}

.connectedLinkedInList {
  height: calc(100% - 38px);

  .connectedLinkedInCard {
    display: grid;
    grid-template-columns: 40% auto 30%;
    align-items: center;
    border: 1px solid $c-dd;
    padding: 14px 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px #00000012;
    cursor: pointer;
    margin-bottom: 16px;
    &.linkedInAccountDeleted {
      opacity: 0.4;
      pointer-events: none;
    }
    &:has(.statusBtn) {
      grid-template-columns: 35% auto 20% 20%;
    }

    .statusBtn {
      display: flex;
      justify-content: flex-end;
    }

    &:hover {
      background: #f3f6ff;
    }

    .btnInboxActions {
      @include flex-center;
      justify-content: flex-end;

      .configureBtn {
        margin-right: 8px;
      }

      .deleteInboxIcon,
      .configureBtn {
        cursor: pointer;
        padding: 6px;
        @include flex-center;
        justify-content: center;

        &:hover {
          background: $white;
          height: 28px;
          border-radius: 36px;
        }

        svg {
          @include wt-ht(14px, 14px);
          fill: $c-8d;
        }
      }
    }
  }
}

.linkedInMetricsWrapper {
  @include flex-center;
  justify-content: center;
  .visibilityIcon {
    fill: $c-8d;
    font-size: 16px;
  }
  .replyRateIcon {
    @include flex-center;
    svg {
      @include wt-ht(15px, 15px);
      path {
        fill: $c-8d;
      }
    }
  }
  .metrics {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
  .customTooltip {
    background: #393a3d;

    .sharpPointer {
      background: #393a3d;
      border: 1px solid transparent;
      padding: 5px;
      border-radius: unset;
      top: 8px;
      right: 15px;
    }

    .tooltipBody {
      width: fit-content;
      white-space: nowrap;
      background: #393a3d;
      top: 12px;
      right: -32px;
    }

    .tooltipText {
      color: $white;
      padding: 8px;
    }
  }

  .metrics {
    @include flex-center;
  }

  .metricsIcon {
    @include wt-ht(24px, 24px);
    margin-right: 4px;
    @include flex-center;
  }
}

.profileInformation {
  @include flex-center;

  .flexRow {
    @include flex-center;
    margin-bottom: 6px;

    svg {
      margin-left: 10px;
    }
  }

  .leftLayout {
    margin-right: 10px;
    position: relative;

    .absoluteIcon {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      background-color: $white;
      @include wt-ht(18px, 18px);
    }

    .imgUrl {
      @include wt-ht(52px, 52px);
      border-radius: 50%;
      z-index: 1;
    }
  }

  .rightLayout {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;

    .salesNavigatorIcon {
      width: 130px;
      height: auto;
      margin-left: 10px;
    }

    .fullName {
      text-transform: capitalize;
      font-size: 16px;
      color: $c-343a40;
    }

    .jobDetails {
      font-size: 14px;
      color: $c-343a40;
    }

    .publicUrl {
      font-size: 13px;
      margin-bottom: 6px;
      color: $c-8d;
    }

    .followersCount {
      font-size: 13px;
      color: $c-343a40;
    }
  }
}

.connectedLinkedInConfigure {
  width: 100%;

  .settingsWrapper {
    border: 1px solid $c-dd;
    border-radius: 5px;
    margin: 16px;

    .profileInformation {
      padding: 20px;
    }
  }

  .linkedInSettings {
    display: block;
    position: relative;
    min-height: 200px;
    border-top: 1px solid $c-dd;
    overflow: auto;

    .warmingSettingsCont {
      position: relative;

      .warmingSettingsHeader {
        height: 60px;
        padding: 20px;
        font-weight: bold;
        border-bottom: 1px solid $c-dd;
        color: black;
        font-size: 14px;
      }

      .warmingSettingsBody {
        height: calc(100% - 60px);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        padding: 15px;
        column-gap: 20px;
        row-gap: 20px;

        label {
          margin-bottom: 16px !important;
        }

        .warmingTagWrapper {
          .emailWarmingTagWrapper {
            @include flex-center;

            .copyIcon {
              cursor: pointer;
              font-size: 14px;
              margin-left: 20px;

              &:hover {
                background: $c-f5;
                color: black;
                transition: all 0.3s ease;
              }
            }
          }

          .label {
            margin-bottom: 15px;
            font-size: 14px;
            @include flex-center;

            .infoIcon {
              position: relative;
              margin-left: 8px;
              top: 3px;
              cursor: pointer;

              .iIcon {
                font-size: 14px;
              }

              .customTooltip {
                .sharpPointer {
                  top: -3px;
                  right: 0px;
                  border: none;
                  background: #393a3d;
                }

                .tooltipBody {
                  background: #393a3d;
                  border-radius: 5px;
                  top: 4px;
                  left: -60px;
                  width: 300px;
                  text-align: center;

                  .tooltipText {
                    color: $white;
                    padding: 8px;
                  }
                }
              }
            }
          }

          .emailWarmingTag {
            background-color: #eff1ff;
            color: $blue-violet;
            border-radius: 6px;
            font-size: 11px;
            min-width: 70px;
            max-width: 100px;
            padding: 6px;
            @include flex-center;
            justify-content: center;
          }
        }

        &.settingsLoader {
          row-gap: 45px;
        }

        .warmingSettingsLoader {
          position: relative;
          height: 50px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .label {
            &.linear-background {
              width: 80%;
              height: 25%;
            }
          }

          .inputType {
            &.linear-background {
              width: 40%;
              height: 25%;
            }
          }
        }

        .checkBoxInput {
          align-items: center;
          display: grid;
          grid-template-rows: repeat(3, auto);
          grid-gap: 15px;
          height: auto;

          .checkboxlabel {
            font-size: 15px;
          }

          .checkboxSubLabel {
            display: block;
            font-size: 12px;
            color: $c-8c8c8c;
          }

          .toggleBtnSection {
            .toggleText {
              &.rightPad {
                padding-right: 0px;
              }
            }

            .toogleButton {
              .toggleCheckbox {
                &:checked ~ .layer {
                  background-color: $blue-violet;
                }
              }
            }
          }
        }

        .numberInputBox {
          .flexTooltip {
            @include flex-center;
          }
          .wrapInfoIconTp {
            position: relative;
            @include flex-center;
            margin-left: 10px;
            .infoIcon {
              font-size: 18px;
              color: $c-44;
              cursor: pointer;
              border-radius: 35px;
            }
            .customTooltip {
              display: none;
            }
            &:hover .customTooltip {
              position: absolute;
              display: block;
              font-family: $font-regular;
              .sharpPointer {
                border: unset;
                background: #393a3d;
                border-top-right-radius: 18px;
                top: unset;
                bottom: 10px;
                left: 3px;
                right: unset;
                padding: 6px;
              }
              .tooltipBody {
                background: #393a3d;
                border-radius: 5px;
                width: 220px;
                top: unset;
                bottom: 16px;
                right: unset;
                left: -100px;
                .tooltipText {
                  text-align: center;
                  font-size: 12px;
                  color: $white;
                  @include flex-center;
                  justify-content: center;
                  padding: 10px;
                }
              }
            }
          }
          &:focus-within {
            label,
            .numberInputBoxSubLabel {
              color: $c-8c8c8c;
            }
          }
        }
      }

      .buttonActionList {
        position: relative;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 20px;
        border-top: 1px solid $c-dd;
        background: $white;
        margin-top: 5px;

        .cancelButton {
          @extend %primaryBlueBtn;
          min-width: 130px;
          border-radius: 5px;
          color: $blue-violet;
          background: $white;
          border: 1px solid $blue-violet;
          margin: unset;

          &:hover {
            background: $white;
          }
        }

        .saveButton {
          @extend %primaryBlueBtn;
          min-width: 130px;
          border-radius: 5px;
          background: $blue-violet;
          margin-left: 15px;

          &.loading {
            transition: all 0.3s ease-in-out;
            min-width: auto;
            border-radius: 35px;

            .load-text {
              @include wt-ht(30px, 30px);
              top: 4px;
              left: 4px;
            }
          }
        }
      }
    }
  }

  .sectionHeader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    padding: 0 16px;
    border-bottom: 1px solid $c-ec;

    .backBtnAndHeader {
      @include flex-center;

      .navigateToInbox {
        @include flex-align-center;
        margin-right: 10px;
        @include wt-ht(30px, 30px);
        border-radius: 6px;
        cursor: pointer;

        &:hover {
          background: $c-ec;
        }

        .backBtn {
          font-size: 20px;
        }
      }

      h6 {
        position: relative;
        display: block;
        font-size: 16px;
        font-family: $font-regular;
        color: $c-66;
        font-weight: 400;
      }
    }
  }
}

.connectedLinkedInCard {
  &.inboxRowloader {
    height: 80px;

    .imgType {
      @include wt-ht(52px, 52px);
      border-radius: 50%;
    }

    .wrapConnectedEmail {
      .connectedEmail {
        @include wt-ht(160px, 12px);
      }

      .userName {
        margin-bottom: 4px;
        @include wt-ht(100px, 10px);
      }
    }

    .warmerMetricsWrapper {
      @include wt-ht(75%, 12px);
    }

    .warmingEnabledOrDisabledBtn {
      @include wt-ht(150px, 24px);
    }

    .deleteInboxIcon,
    .configureBtn {
      @include wt-ht(24px, 24px);
    }
  }
}

.dropdownWithLabelWrapper {
  max-width: 500px;

  .dropdownWithNumberInput {
    border: 0;
    outline: 0;
    border-bottom: 1px solid $c-dd;
    width: 50px;
    text-align: center;
    margin: 0 8px;
  }

  .adjustLabel,
  label {
    font-size: 15px;
    color: $c-66;
    top: -16px;
  }

  .activeDropdownValue {
    border: 1px solid $c-dd;
    width: 100%;
    padding: 0 8px;
    border-radius: 3px;
    height: 38.5px;
    margin-top: 0;

    .activeValue {
      color: $blue-violet;
    }

    i {
      right: 8px;
      color: $c-66;
    }
  }

  &.dropdownWithNumberInputWrapper {
    .numberInputBox {
      @include flex-center;
    }

    .numberInputBoxWrapper {
      position: relative;
      top: -16px;
    }
  }
}
.liConnectWrapper {
  text-align: center;
  img {
    width: 120px;
    height: 100px;
    object-fit: contain;
  }

  h6 {
    font-weight: 500;
    font-size: 16px;
    margin: 15px 0;
    text-align: center;
  }
  .liButtonWrapper {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .connectLinkedInBtn,
    .connectSNLinkedInBtn {
      border: 1px solid $blue-violet;
      color: $blue-violet;
      background-color: transparent;
      font-size: 14px;
      cursor: pointer;
      max-width: 300px;
      min-width: 250px;

      svg {
        margin-right: 8px;
      }
    }

    .connectSNLinkedInBtn {
      margin-top: 16px;
    }

    .wrapLockIcon,
    .freemiumIcon {
      @include wt-ht(20px, 20px);

      svg {
        margin-right: 0;
        margin-left: 8px;
      }

      .topRight {
        top: 18px;
        right: -25px;
        width: 370px;
      }
    }
  }
}

.customTaskCrm {
  opacity: 0.5;
}
.linkedinStatus {
  display: inline-flex;
  align-items: center;
  height: 22px;
  max-width: fit-content;
  border-radius: 4px;
  padding: 0 6px;
  font-weight: bold;
  white-space: nowrap;

  .linkedinAccountStatus {
    font-size: 13px;
    color: $c-343a40;
  }
}

.reconnectComponent {
  margin-right: 4px;
}

.alertLinkedinWarningSvg {
  margin-right: 5px;
}
