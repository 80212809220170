%previewTemplate {
  position: relative;
  height: calc(100%);
  h6,
  .preview-title {
    @include flex-center();
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 25px;
    color: $c-33;
    @extend %sharedBadge;
  }
  .template-form {
    position: relative;
    height: 100%;
    width: 100%;
    .input-group {
      position: relative;
      display: grid;
      grid-template-columns: 40% 56%;
      grid-column-gap: 2%;
      justify-content: center;
      .input-form {
        sup {
          color: red;
        }
      }
    }
    #templateQuilEditor {
      position: relative;
      margin: 10px 1% 20px;
      box-shadow: 0 0 13px 0 rgba(68, 68, 68, 0.08);
      border: solid 1px #e4e5e8;
      border-radius: 10px;
      height: calc(100% - 128px);
      .quill {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-direction: column-reverse;
        color: #000;

        .ql-container {
          position: relative;
          display: block;
          overflow: hidden;
          @include bor-rad-top(10px, 10px);
          border: none;
          height: calc(100% - 45px);
          padding: 10px;
          ul,
          ol {
            margin: 1em 0;
          }
          &.ql-snow {
            background: $white;
            @include bor-rad-top(10px, 10px);
            .ql-editing {
              left: 0 !important;
            }
          }
        }
        .ql-toolbar.ql-snow .ql-picker-options {
          position: absolute;
          border-radius: 6px;
        }
        .ql-toolbar.ql-snow .ql-font .ql-picker-options {
          transform: translateY(-100%);
          margin-top: -25px;
          text-align: left;
        }
        .ql-toolbar.ql-snow .ql-align .ql-picker-options {
          top: -105px;
        }
        .ql-toolbar.ql-snow .ql-size .ql-picker-options {
          position: absolute;
          top: -144px;
        }
        .ql-tooltip {
          left: 0 !important;
          transform: translateY(-10px);
        }
        .ql-toolbar {
          background: #fcfcfc;
          border: none;
          border-top: 1px solid #ddd;
          padding: 8px 10px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          .ql-formats .ql-variables::after {
            font-size: 14px;
            line-height: 1;
            border-radius: 3px;
            vertical-align: top;
          }

          .ql-formats {
            &:last-child {
              position: absolute;
              right: 0;
            }
          }

          button {
            height: 20px;
            width: 20px;
            padding: 3px;
            color: $c-66;
            &:hover {
              background: #eee;
              border-radius: 3px;
            }
          }
          .ql-formats {
            margin-right: 5px;

            .ql-font.ql-picker {
              width: 95px;

              .ql-picker-label {
                padding-left: 0;
              }
            }

            .ql-size.ql-picker {
              width: 75px;

              .ql-picker-label {
                padding-left: 0px;
              }

              .ql-picker-options {
                width: 80px;
                .ql-picker-item[data-value="9.75px"]:before {
                  content: "small";
                  font-size: 10px !important;
                }
                .ql-picker-item[data-value="13px"]:before {
                  content: "Normal";
                  font-size: 13px !important;
                }
                .ql-picker-item[data-value="19.5px"]:before {
                  content: "Large";
                  font-size: 18px !important;
                }
                .ql-picker-item[data-value="32.5px"]:before {
                  content: "Huge";
                  font-size: 25px !important;
                }
              }
            }

            .ql-color-picker {
              .ql-picker-options {
                transform: translateY(-100%);
                margin-top: -25px;
              }
            }
          }
        }
      }
    }
    .subjectLineGenerator {
      right: 0;
    }
  }
  .preview-body {
    position: relative;
    display: block;
    background: #fcfcfc;
    border: 1px solid #f5f5f5;
    padding: 15px;
    border-radius: 4px;
    overflow-y: auto;
    height: calc(100% - 52px);
    color: black;
    font-family: Arial, Helvetica, sans-serif;

    .preview-text {
      font-size: 14px;
      text-align: center;
      padding-top: 15px;
    }
    .preview-name,
    .preview-subject {
      position: relative;
      display: grid;
      grid-template-columns: 36px auto;
      align-items: flex-start;
      margin-bottom: 10px;
      label {
        font-size: 14px;
        color: $c-22;
      }
      .text {
        color: $blue-violet;
        font-size: 14px;
        word-break: break-all;
      }
    }
    .preview-content-body {
      font-size: 14px;
      .ql-editor {
        padding: 0;
        ol,
        ul {
          margin: 1em 0;
          padding-left: 40px;
        }
      }
    }
  }
}
.preview-templete {
  overflow: auto;
  @extend %previewTemplate;
}
