.phoneNotFoundPopupWrapper {
  @extend %dataStatusPopup;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  z-index: 1111;
  font-size: 17px;
  .closeButtonWrapper {
    display: flex;
    justify-content: flex-end;
    .closeButton {
      cursor: pointer;
      width: fit-content;
      .closeIcon {
        position: absolute;
        top: 10px !important;
        right: 10px !important;
        cursor: pointer;
        padding: 4px;
        scale: 0.9;
        i {
          font-size: 10px;
        }
        &:hover {
          background-color: #ddd;
          border-radius: 50%;
        }
      }
    }
  }
  .phoneNotFoundContent {
    display: flex;
    flex-direction: column;
    width: 450px;
    border-radius: 16px;
    overflow: hidden;
    will-change: transform;
    transition: all 0.4s ease;
    background: #fff;
    z-index: 99999999;
    padding: 30px;
    > *:not(:first-child) {
      gap: 20px !important;
    }
    .headerData {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 18px;
      color: #333333;
      font-weight: 700;
      padding: 0 10px 0 10px;
    }
    .fetchData {
      font-size: 18px;

      font-weight: 700;
      color: #333333;
      padding: 10px 0;
    }
    .fetchProgress {
      display: flex;
      flex-direction: row;
      font-size: 16px;
      padding: 20px 10px;
      gap: 30px;
      .contactNotFound {
        scale: 0.8;
      }
      .contactDataContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        .mailDataContainer {
          display: flex;
          gap: 20px;
          flex-direction: row;
          color: #333333;
          font-size: 16px;
          .copyIcon {
            cursor: pointer;
          }
        }
        .phoneDataContainer {
          display: flex !important;
          gap: 20px !important;
          flex-direction: row !important;
          .viewPhoneNumberContainer {
            cursor: pointer;
            .viewPhoneNumberButton {
              border: 2px solid #707070;
              border-radius: 10px;
              text-align: center;
            }
            .viewPhoneNumber {
              padding: 4px;
              color: #3953fb;
              width: 100px;
              height: 20px;
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  .confirmation-popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
    background: rgba(0, 0, 0, 0.53);
    opacity: 0.75;
    &.bulkMask {
      z-index: 1;
    }
  }
  .continueToSearchContainer {
    display: flex;
    justify-content: center;
    .continueToSearchButtonWrapper {
      border-radius: 5px;
      background-color: #3953fb;
      color: #ffffff;
      font-size: 16px;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
      padding: 0 10px;
      .gotItButton {
        padding: 8px 13px;
      }
    }
  }
  .confirmation-popup-mask {
    z-index: 115;
  }
}
