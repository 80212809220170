.singleSelectDropdown {
  @extend %relBlock;
  label.selectOption {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: calc(100% - 30px) 30px;
    justify-content: space-between;
    height: 39px;
    border-bottom: 1px solid #f3f4f8;
    transition: all 0.3s ease;
    &.readOnly {
      border: none;
    }
    .focusBorder {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      will-change: height;
      z-index: 99;
    }
    & ~ .focusBorder:before,
    & ~ .focusBorder:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: $blue-violet;
      transition: 0.4s;
      will-change: background-color;
    }
    & ~ .focusBorder:after {
      left: auto;
      right: 0;
    }
    .selectLabel {
      position: relative;
      @include flex-center;
      height: 39px;
      .labelName {
        position: absolute;
        color: $c-66;
        font-size: 13px;
        bottom: 12px;
        left: 0;
        will-change: font-size;
        letter-spacing: 0.3px;
        transition: all 0.2s linear;
        .required {
          color: red;
          padding-left: 5px;
        }
        .infoIcon {
          position: relative;
          top: 2px;
          left: 2px;
          i {
            font-size: 13px;
            cursor: pointer;
          }
          .customTooltip {
            .sharpPointer {
              border-top-right-radius: 2px;
              padding: 5px;
              border-right: 1px solid black;
              border-top: 1px solid black;
              right: 1px;
              background: black;
            }
            .tooltipBody {
              background: black;
              right: -15px;
              top: 3px;
              width: 200px;
              border-radius: 4px;
              .tooltipText {
                color: white;
                white-space: initial;
                padding: 6px;
                font-size: 11px;
                text-align: center;
              }
            }
          }
        }
      }
      .activeValue {
        position: relative;
        font-size: 14px;
        will-change: font-size;
        top: 3px;
        color: $blue-violet;
        @include ellipsis();
        width: 100%;
      }
    }
    .arrowUpDown {
      position: relative;
      @include flex-align-center;
      color: $c-66;
      font-size: 20px;
    }
    &.activeDropdown {
      border-bottom: 1px solid $blue-violet;
      & ~ .focusBorder:before,
      & ~ .focusBorder:after {
        width: 50%;
        transition: 0.4s;
      }
    }
    &.selectedValue .labelName {
      white-space: nowrap;
      bottom: 36px;
      font-size: 11px;
      transition: all 0.2s linear;
      .infoIcon {
        i {
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
  .dropdownOptions {
    position: absolute;
    left: 0;
    height: auto;
    width: 100%;
    background: $white;
    z-index: 999;
    box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
    border: solid 1px $c-eb;
    transition: all 0.4s ease-in-out;
    .searchInputBtn {
      position: relative;
      border-bottom: 1px solid $c-ec;
      margin-bottom: 5px;
      padding: 2px 10px;
      .searchIcon {
        color: $c-cc;
      }
      .searchTextBox {
        width: 100%;
        .searchInputBoxWrapper {
          position: relative;
          top: 2px;
          width: 100%;
          input {
            width: 100%;
          }
        }
      }
    }
    .selectOptionDropdown {
      @include list-style-zero();
      overflow: auto;
      min-width: 130px;
      max-height: 240px;
      li.option {
        @include flex-center;
        position: relative;
        padding: 8px 10px;
        width: 100%;
        transition: all 0.3s ease;
        cursor: pointer;
        text-align: left;
        font-size: 14px;
        color: $c-66;
        word-break: break-word;
        line-height: normal;
        &.selected {
          background: $c-f5;
          color: $blue-violet;
          cursor: unset;
          pointer-events: none;
          user-select: none;
        }
        &:hover {
          background: $c-f5;
          color: $c-33;
        }
      }
    }

    .emptyOption {
      position: relative;
      padding: 5px 0;
      font-size: 14px;
      color: $c-78;
      @include flex-align-center;
    }
  }
  .selectOptionOverlay {
    @include bg-mask(1, transparent);
  }
  .errMsgText {
    display: flex;
    font-size: 11px;
    color: red;
    position: absolute;
    bottom: -15px;
    left: 0;
  }
}
