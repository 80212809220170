@mixin leftSection {
  .titleCont {
    margin-bottom: 10px;
    .title {
      font-size: 27px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #3b3b3b;
      font-family: $font-regular;
      padding: 0px;
      line-height: normal;
      @include flex-center();

      span {
        @include flex-center;
        margin-left: 7px;
        svg {
          @include wt-ht(22px, 22px);
          @media (max-width: 1024px) {
            @include wt-ht(20px, 20px);
          }
        }
      }
    }

    .desc {
      letter-spacing: normal;
      text-align: left;
      color: $c-66;
      padding: 5px 0px;
      font-size: 16px;
    }
  }

  .featureGrid {
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-gap: 20px;
    text-align: left;
    padding: 15px 0px;

    .featureCard {
      display: flex;

      .cardIconCont {
        padding-top: 5px;
        img {
          width: 20px;
        }
      }

      .textCont {
        color: $c-66;
        padding-left: 20px;

        .cardTitle {
          font-family: "Graphik-Semibold";
          font-size: 16px;
        }
        .cardMessage {
          font-size: 14px;
        }
      }
    }
  }
}

@mixin rightSection {
  width: 100%;
  height: auto;
  @include flex-center();
  justify-content: flex-end;

  img {
    width: 350px;
    object-fit: contain;
  }
}

.welcomePopup {
  height: 100%;
  top: 0;
  border-radius: 10px;

  .common-popup {
    min-width: 900px;
    padding: 0px;

    .welocmeInnerPopup {
      border-radius: 10px;
      min-width: 850px !important;

      .popup-content-wrap {
        height: 510px;
        padding: 40px 45px 0px 45px;

        .leftContainer {
          width: 50%;
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          float: left;
          height: calc(100% - 76px);

          .leftSection {
            @include leftSection();
          }
        }

        .rightContainer {
          float: right;
          width: 50%;
          height: calc(100% - 76px);
          @include flex-center();
          justify-content: flex-start;
          flex-direction: row;

          .rightSection {
            @include rightSection();
          }
        }

        .buttonCont {
          background-color: #f97220;
          border-color: #f97220;
          height: 36px;
          width: fit-content;
          padding: 15px;
          color: #fff;
          font-size: 14px;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          cursor: pointer;
          border: 0;
          font-family: $font-regular, sans-serif;
          margin-bottom: 40px;
          float: left;
          transition: all 0.3s;

          &:hover {
            background-color: $blue-violet;
          }
        }
      }
    }
  }
}

.onboardOverlayMask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background: #000;
  opacity: 0.75;
}
