.stagesCont {
  @extend %relBlock;
  width: 100%;
  height: 100%;
  .overviewDetails {
    position: relative;
    display: grid;
    grid-template-columns: 50px calc(100% - 50px);
    width: 100%;
    margin-bottom: 20px;
    animation: fadeIn 0.5s linear;
    user-select: none;
    .stageIconWrap {
      position: relative;
      @include flex-center;
      justify-content: center;
      border-radius: 35px;
      @include wt-ht(28px, 28px);
      margin-top: 11px;
      svg {
        @include wt-ht(16px, 16px);
        fill: white;
      }
      img {
        @include wt-ht(auto, 14px);
      }
      &.email {
        background: $c-38;
      }
      &.manualEmail {
        background: $c-ff9;
      }
      &.linkedinAuto,
      &.linkedin {
        background: $c-53;
        svg {
          @include wt-ht(12px, 12px);
        }
      }
      &.phone {
        background: $c-1eb9d5;
      }
      &.customTask {
        background-color: #3fdd43;
        svg {
          margin-left: 2px;
        }
      }
    }
    .verticalLine,
    .verticalLineLoader {
      position: absolute;
    }
    &:not(:last-child) .verticalLine,
    .verticalLineLoader {
      width: 2px;
      height: calc(100% - 30px);
      z-index: 1;
      left: 14px;
      top: 51px;
      background: $c-d8;
    }

    &:last-child .verticalLine {
      display: none;
    }
    .stageCard,
    .stageCardLoader {
      position: relative;
      background: white;
      font-family: $font-regular;
      border-radius: 10px;
      box-shadow: 0px 5px 12.4px 0.7px rgba(59, 59, 59, 0.07);
      border: solid 1px $c-eb;
      %text-style {
        position: relative;
        color: $c-53;
        font-size: 14px;
        letter-spacing: 0.3px;
      }
      .stageHeader {
        position: relative;
        display: grid;
        grid-template-columns:
          80px 185px 85px auto
          45px;
        align-items: center;
        padding: 0 15px;
        border-bottom: 1px solid $b-c-1;
        height: 48px;
        .stepNo {
          padding-right: 15px;
          .linear-background {
            @include wt-ht(80px, 14px);
          }
        }
        .stageType {
          padding: 0 15px;
          .linear-background {
            @include wt-ht(120px, 14px);
            margin-left: 15px;
          }
        }
        .stepNo,
        .stageType,
        .stageDate {
          @extend %text-style;
          border-right: 1px solid $b-c-1;
          @include flex-center;
          height: 100%;
          font-weight: bold;
          padding-top: 3px;
        }
        .stageStatusBadgeWrap {
          position: relative;
          padding-left: 16px;
          cursor: pointer;
          span {
            padding: 5px 5px 4px;
            font-size: 13px;
            text-transform: capitalize;
            border-radius: 2px;
            font-weight: 600;
            &:not(:last-child) {
              margin-right: 5px;
            }
          }
          .stageCompleted {
            background: #cefebe;
            color: #58b937;
          }
          .stageScheduled {
            background: #ffe8cf;
            color: #c78d4f;
          }
          .stagePaused {
            background: #e46833;
            color: #fedee0;
          }
          .stageFailed {
            background: #fcc8c5;
            color: #ff5349;
          }
          .stageBounced {
            background: $c-d8;
            color: $c-4c;
          }
          .stageReplied {
            background: #cefebe;
            color: $green;
          }
          .linear-background {
            display: inline-block;
            @include wt-ht(60px, 22px);
          }
        }
        .stageDate {
          position: relative;
          font-size: 13px;
          color: $c-53;
          padding-left: 15px;
          cursor: pointer;
          &.stageEditDisabled {
            pointer-events: none;
            &:hover:not(.linear-background) {
              background-color: unset;
              .sharpPointer {
                background-color: #f4f6ff;
              }
            }
          }
          &:hover:not(.linear-background) {
            background-color: #f4f6ff;
            .sharpPointer {
              background-color: #f4f6ff;
            }
          }
          .linear-background {
            @include wt-ht(60px, 14px);
          }
        }
      }
      .stageVariants {
        padding: 10px 15px;
        position: relative;
        .hoverCard {
          width: 100%;
          opacity: 1;
          left: 0;
          height: 100%;
          @include flex-center;
          justify-content: center;
          background-color: #f4f6ff;
          position: absolute;
          transition: all 0.3s;
          border-radius: 5px;
          cursor: pointer;
          .cardData {
            padding: 7px 15px;
            background: #fff;
            border-radius: 20px;
            opacity: 1;
            z-index: 8;
            font-size: 12px;
            @include flex-center();
            justify-content: center;
            box-shadow: 0px 5px 12.4px 0.7px rgba(59, 59, 59, 0.07);
            .eyeIcon {
              @include wt-ht(15px, 15px);
              margin-right: 7px;
              svg {
                @include wt-ht(inherit, inherit);
                fill: $blue-violet;
              }
            }

            span.viewText {
              color: $blue-violet;
            }
          }
        }
        &.emailStage {
          margin-bottom: 8px;
        }
        .variantCard {
          position: relative;
          display: grid;
          grid-template-columns: 24px 54px 240px auto 240px 30px;
          align-items: center;
          border: 1px solid transparent;
          border-radius: 5px;
          padding: 0 5px;
          transition: all 0.3s;
          height: 44px;
          cursor: pointer;

          .contentDisplay {
            display: contents;
            .toggleBtnSection {
              z-index: 0;
            }
          }
          &.disabledAction {
            user-select: none;
            pointer-events: none;
            cursor: unset;
          }
          &.disabledVariant {
            opacity: 0.7;
            .index {
              color: $c-a7;
            }
            .content,
            .subject,
            .stats {
              color: $c-a7;
            }
          }
          &:not(:last-child) {
            margin-bottom: 5px;
          }
          &.taskStage {
            grid-template-columns: 240px auto 30px;
          }
          &.phoneStage {
            grid-template-columns: 240px auto 240px 30px;
          }
          &.linkedinAutoStage {
            grid-template-columns: 240px auto 240px;
          }
          .index {
            position: relative;
            @include flex-center;
            justify-content: center;
            border: solid 1px #ebeaf4;
            border-radius: 35px;
            background-color: white;
            font-size: 12px;
            font-weight: bold;
            text-align: left;
            color: $blue-violet;
            @include wt-ht(20px, 20px);
            line-height: 12px;
            padding-top: 1px;
            &.linear-background {
              @include wt-ht(20px, 20px);
            }
          }
          .toggleBtnSection {
            .toogleSequence .toogleButton {
              &.loading {
                .knobs::before {
                  animation: spin 1s linear infinite;
                  border: 1.5px solid #3953fb;
                  border-bottom-color: #3953fb;
                  border-left-color: #3953fb;
                  border-left: 1px;
                  background-color: transparent;
                }

                .toggleCheckbox:checked + .knobs:before {
                  background-color: transparent;
                }
              }
              @include wt-ht(28px, 14px);
              .knobs::before {
                @include wt-ht(10px, 10px);
                top: 2px;
                padding: unset;
              }
              .toggleCheckbox:checked {
                & ~ .layer {
                  background: #fff;
                  border: 1px solid #3953fb;
                }
                & + .knobs:before {
                  left: 15px;
                  background: #3953fb;
                }
              }
              .layer {
                background: #fff;
                border: 1px solid $c-d3;
              }
              .knobs:before {
                background: $c-a7;
              }
            }
            &.linear-background {
              @include wt-ht(34px, 16px);
            }
          }
          .content {
            padding: 0 15px;
            color: $c-88;
            &.linear-background {
              @include wt-ht(200px, 14px);
            }
          }
          .subject {
            &.linear-background {
              @include wt-ht(200px, 14px);
            }
          }
          .subject,
          .content {
            position: relative;
            @extend %text-style;
            @include ellipsis();
          }
          .stats {
            position: relative;
            @include flex-center;
            .statsCountTitleWrap {
              @extend %relBlock;
              font-size: 12px;
              padding: 0 10px;
              width: fit-content;
              letter-spacing: 0.3px;
              &:first-child {
                padding-left: 0px;
              }
              &:last-child {
                padding-right: 0px;
              }
              &:not(:last-child) {
                border-right: 1px solid $c-ee;
              }
              .statsCount {
                @extend %relBlock;
                color: $c-48;
                font-weight: 600;
                margin-bottom: 2px;
                font-size: 15px;
                &.linear-background {
                  display: block;
                  @include wt-ht(20px, 20px);
                  border-radius: 35px;
                }
              }
              .statsTitle {
                @extend %relBlock;
                color: #979a9d;
                &.linear-background {
                  display: block;
                  @include wt-ht(40px, 14px);
                }
              }
            }
          }
          .moreOptionSlide {
            .dropdownOptions {
              top: -5px;
            }
            &.linear-background {
              @include wt-ht(20px, 20px);
            }
          }
        }
      }
      .addVariant {
        position: absolute;
        bottom: -20px;
        left: 18px;
        background: white;
        padding: 3px;
        border-radius: 35px;
        border: 1px solid $c-f4;
        @include flex-center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s;
        .freemiumIcon {
          top: -14px;
          left: -25px;
        }
        i {
          font-size: 24px;
          color: $c-1a;
        }
        .tpText {
          display: none;
          transition: all 0.3s;
          padding: 0 5px;
          font-size: 12px;
          font-weight: 500;
          text-align: left;
          color: $c-1a;
        }
        &:hover .tpText {
          display: inline-flex;
        }
      }
    }
  }
}
