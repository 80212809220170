$c-343a40: #343a40;
$c-border: #eceff1;
$c-bg: #f9f9ff;

.websiteTrackingWrapper {
  @extend %relBlock;
  height: 100%;

  .websiteTrackingCont {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    @include wt-ht(100%, 100%);
    .websiteTrackingHeader {
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      border-bottom: 1px solid $c-ec;
      padding: 0 30px;
      padding-bottom: 16px;
    }
    h6 {
      position: relative;
      display: block;
      font-size: 16px;
      font-family: $font-regular;
      color: $c-66;
      font-weight: 400;
    }
    .flexEnd {
      @include flex-center;
      justify-content: flex-end;
      .refreshCreditsUsage {
        min-width: 100px;
        cursor: pointer;
        margin: auto 0;
        padding: 0 12px;
        color: $c-66;
        background: $c-f5;
        border: 1px solid $c-f5;
        font-size: 14px;
        height: 28px;
        border-radius: 35px;
        .iconRight i {
          font-size: 14px;
          padding-left: 5px;
        }
        &.loading {
          @include wt-ht(30px !important, 30px !important);
          min-width: unset !important;
          .load-text {
            @include wt-ht(18px, 18px);
            border-color: $blue-violet;
          }
        }
        &:hover {
          color: $c-22;
          border-color: $c-ee;
          transition: all 0.3s ease;
          .iconRight i {
            color: $c-22;
          }
        }
      }
    }
  }
  .optionalScriptWrapper {
    margin-top: 24px;
    color: $c-3b;
    font-size: 13px;
    .longText {
      width: 70%;
      line-height: 1.3;
    }
    .headText {
      font-size: 13px;
      margin: 0 4px;
      color: $c-F59064;
    }
    .websiteTrackingSubText {
      margin-bottom: 16px;
      font-size: 11px;
      color: $c-8d;
    }
  }
  .websiteTrackingBody {
    padding: 0 24px;
    overflow-y: auto;
    margin-bottom: 24px;
    .bodyHeadingText {
      padding: 24px 0;
      .websiteTrackingBoldText {
        color: $c-343a40;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 12px;
      }
      .websiteTrackingSubText {
        color: $c-3b;
        font-size: 13px;
      }
      .headText {
        font-size: 13px;
        margin: 0 4px;
        color: $c-F59064;
      }
    }

    .copyOptionalScriptWrapper {
      margin-bottom: 24px;
      .copyScriptBody {
        padding: 0 !important;
      }
      .copyScriptCont {
        @include flex-center;
        padding: 12px 16px !important;
        justify-content: space-between;
      }
    }

    .copyScriptWrapper {
      width: 600px;
      .copyScriptCont {
        border-radius: 10px;
        border: 1px solid $c-border;
        background-color: $c-bg;
      }
      .copyScriptHeader {
        @include flex-center;
        justify-content: space-between;
        border-bottom: 1px solid $c-border;
        padding: 12px 16px;
        background-color: $white;
        @include bor-rad-top(10px, 10px);
        p {
          font-size: 14px;
          font-weight: 700;
        }
      }
      .copyScriptBody {
        color: $c-8d;
        font-size: 12px;
        padding: 18px;
        line-height: 2;
      }
      .copyButtonWrap {
        @include flex-center;
        border: 1px solid $c-dd;
        padding: 0px 8px;
        height: 26px;
        background-color: $white;
        color: $blue-violet;
        border-radius: 6px;
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
        width: 80px;
        justify-content: center;
        transition: 0.3s all ease;
        .copyButton {
          margin-right: 6px;
          font-size: 14px;
          color: $blue-violet;
        }
      }
      .copiedButtonWrap {
        @include flex-center;
        background-color: $blue-violet;
        color: $white;
        padding: 0px 8px;
        height: 26px;
        border-radius: 6px;
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
        width: 80px;
        justify-content: center;
        transition: 0.3s all ease;
        .copyButton {
          margin-right: 6px;
          font-size: 14px;
          color: $white;
        }
      }
    }
    .copyScriptFooter {
      background-color: $white;
      color: $c-3b;
      font-size: 13px;
      padding-top: 12px;
      @include flex-center;
      .knowMoreBtnWrapper {
        margin: 0;
        cursor: pointer;
        width: 120px;
      }
    }
  }
}
.scriptInstalledWrapper {
  background-color: #28ad5629;
  border-radius: 6px;
  padding: 14px 16px;
  margin-top: 20px;
  .successTextWrapper {
    @include flex-center;
    margin-bottom: 8px;
    svg {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      fill: $c-28;
    }
  }
  .successText {
    font-size: 14px;
    color: $c-28;
  }
  .successSubText {
    font-size: 13px;
    color: $c-3b;
    margin-left: 24px;
  }
}
