@mixin cardLayout {
  text-decoration: none;
  color: $c-66;
  @include flex-center();
  font-size: 13px;
  padding: 6px 10px;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  cursor: pointer;
  .folderIcon {
    font-size: 16px;
  }
  .name {
    padding-left: 10px;
    line-height: 18px;
    width: calc(100% - 30px);
    @include ellipsis();
  }
  &.selected {
    .name {
      color: $blue-violet;
    }
    i {
      color: $blue-violet;
    }
  }
  .owner {
    background-color: $c-e3;
    color: $c-75;
    margin: 0;
    padding: 5px;
    border-radius: 3px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    line-height: 8px;
    font-size: 9px;
    margin-left: 5px;
    font-weight: 500;
  }
}

@mixin leftSection {
  border-right: 1px solid #eff2f6;
  overflow-y: auto;
  height: 100%;
  max-height: inherit;
  overflow-x: hidden;
  .folderCont {
    width: calc(100% - 5px);
    .folderListCont {
      @include flex-align-path-justify(flex-start, column, flex-start);
      .templateFolder {
        @include cardLayout();
        &:not(.selected):hover {
          background: $c-f5;
          color: $c-33;
          border-radius: 3px;
          svg {
            fill: $c-33;
          }
        }
        .material-icons-outlined {
          font-size: 18px;
        }
        .rightArrow {
          width: 10px;
          height: 10px;
          position: absolute;
          right: 5px;
          font-size: 10px;
        }
      }
    }
  }
  .folderLoadingWrapper {
    @include flex-align-path-justify(center, column, flex-start);
    width: 100%;
    height: inherit;
  }
}

.newTemplateFolderLoader {
  @include flex-center();
  padding: 9px 10px;
  background-color: #fff;
  width: 100%;
  .linear-background {
    border-radius: 6px;
  }
  .folderIcon {
    width: 18px;
    margin-right: 7px;
  }
  .folderName {
    width: calc(100% - 36px);
  }
  .arrowIcon {
    width: 18px;
    margin-left: 7px;
  }
}

@mixin PrimaryButton {
  border: none;
  padding: 8px 15px;
  background-color: #00ce76;
  border-radius: 5px;
  color: white;
  width: fit-content;
  font-size: 13px;
  cursor: pointer;
}

@mixin noTemplates {
  height: 100%;
  @include flex-center();
  flex-direction: column;
  justify-content: center;

  .emptySequence {
    @include flex-center();
    flex-direction: column;
    justify-content: center;
    img {
      @include wt-ht(65px, auto);
      object-fit: contain;
    }
    .createTemplateBtn {
      margin-top: 15px;
      @include PrimaryButton();
      &:focus {
        outline: none;
      }
      span {
        padding: 0px;
        color: white;
      }
    }

    span {
      text-align: center;
      font-size: 11px;
      padding-top: 10px;
    }
  }
}

@mixin rightSection {
  overflow-y: auto;
  height: 100%;
  max-height: inherit;
  overflow-x: hidden;

  .templatesList {
    position: relative;
    display: block;
    @include wt-ht(calc(100% - 5px), 100%);
    padding-left: 5px;
    .templatesDetails {
      @include cardLayout();
      &:hover {
        background: $c-f5;
        color: $c-33;
        border-radius: 3px;
        svg {
          fill: $c-33;
        }
      }
    }
    .noTemplates {
      @include noTemplates();
    }
  }
}

.templatesCardList {
  position: absolute;
  background: white;
  text-align: left;
  top: 68px;
  z-index: 1000;
  right: 0px;
  border-radius: 10px;
  border: solid 1px $c-eb;
  box-shadow: 0 0 22.3px 1.7px rgba(59, 59, 59, 0.14);
  line-height: normal;
  height: 210px;
  width: 370px;
  max-width: 400px;
  padding: 10px;
  overflow: unset;
  display: grid;
  grid-template-columns: repeat(2, 50%);

  .tooltipPointer {
    position: absolute;
    border-top: 1px solid white;
    border-right: 1px solid white;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    border-radius: 4px;
    background: white;
    padding: 7px;
    z-index: 0;
    right: 24px;
    top: -5px;
  }

  .leftSection {
    @include leftSection();
  }

  .rightSection {
    @include rightSection();
  }
}

.fullScreen {
  .templatesListCard {
    max-width: 440px;
    width: 410px;
  }
}

.overlayTemplateCardBg {
  @include bg-mask(999, transparent);
}
