//input - styled component css
.input-form {
  position: relative;
  padding-top: 15px;
  padding-bottom: 20px;
  input:focus ~ label,
  &.filled label {
    color: $blue-violet;
    font-weight: 500;
    transition: 300ms ease all;
  }
  input:focus ~ label,
  input:valid ~ label,
  &.filled label {
    top: -4px;
    font-size: 12px;
  }
  input:focus ~ .bar:before {
    width: 100%;
  }
  input {
    background: none;
    color: $blue-violet;
    font-size: 14px;
    display: block;
    border: none;
    width: 100%;
    border-radius: 0;
    border-bottom: 1px solid $c-dd;
    padding: 10px 0 6px;
    font-family: $font-regular, Arial, sans-serif;
    &:-internal-autofill-selected {
      color: $blue-violet !important;
      font-size: 14px !important;
    }
    &:focus {
      outline: none;
      color: $c-66;
    }
    &[title] {
      display: none;
    }
    &:-webkit-autofill {
      color: $blue-violet;
    }
  }

  label {
    color: $c-66;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    top: 15px;
    transition: 300ms ease all;
    font-family: $font-regular, Arial, sans-serif;
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;
    &:before {
      content: "";
      height: 1px;
      width: 0px;
      bottom: 0px;
      right: 0;
      position: absolute;
      background: $blue-violet;
      transition: 300ms ease all;
      left: 0%;
    }
  }
}
