@keyframes animateWidth {
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: 46px;
    opacity: 1;
  }
}

.callSettingContainer {
  @extend %relBlock;
  height: 100%;
  .callSettingContent {
    padding: 0 24px;
    @extend %relBlock;
    height: calc(100% - 56px);
    overflow: hidden;
    .dialerWrapper {
      padding: 24px 0 !important;
      overflow: hidden !important;
      .dialerInnercont {
        padding: 0 !important;
        .dialerImageCont {
          position: relative;
          display: inline;
          height: 100%;
        }
        .dialerUpgradeCont {
          .upgradeTooltipSection {
            top: -50px;
          }
        }
      }
    }
    .callDispostionSection {
      @extend %relBlockWidth;
      height: 100%;
      overflow-y: auto;
      padding: 24px 0;
      .callStageCardList {
        position: relative;
        display: grid;
        grid-template-columns: repeat(1, 100%);
        grid-gap: 15px;
        width: 100%;
        overflow-y: auto;
        justify-content: space-between;
        line-height: normal;
        align-items: stretch;
        padding-bottom: 40px;
        @media (min-width: 992px) {
          grid-template-columns: repeat(2, calc(50% - 10px));
        }
        @media (min-width: 1400px) {
          grid-template-columns: repeat(3, calc(33.33% - 10px));
        }
        .callStageCardLoader {
          border-color: $c-dd;
          background-color: transparent;
          .stageName {
            width: 150px;
            color: $c-3b;
          }
        }
        .callStageCard {
          border: 1px solid $c-dd;
          border-radius: 5px;
          display: grid;
          align-items: center;
          grid-template-columns: auto minmax(112px, 154px);
          justify-content: space-between;
          height: 36px;
          padding: 0 12px;
          font-size: 14px;
          width: 100%;
          gap: 10px;
          cursor: pointer;
          color: $c-3b;
          .wrapStage {
            position: relative;
            @include flex-center();
            gap: 6px;
          }
          .colorCode {
            position: relative;
            display: block;
            padding: 5px;
            cursor: pointer;
            height: 14px;
            width: 14px;
            border-radius: 2px;
          }
          .customTooltip {
            .sharpPointer {
              border-top-right-radius: 10px;
              border-bottom-left-radius: 35px;
              padding: 6px;
              top: 8px;
              right: 0;
              background: #393a3d;
              border: 1px solid #393a3d;
            }
            .tooltipBody {
              background: #393a3d;
              border-radius: 3px;
              top: 14px;
              left: unset;
              right: -15px;
              width: max-content;
              text-align: center;
            }
            .tooltipText {
              color: $white;
              padding: 5px 8px;
              font-size: 13px;
            }
          }
          &.createNewStageCard {
            border: 1px dashed $c-dd;
            color: $c-8d;
            top: 1px;
            .stageName {
              top: 0px;
              color: $c-8d;
            }
            .iconBg i {
              font-size: 20px;
            }
          }
          &:hover:not(.createNewStageCard) .hoverWrapIcon {
            @include flex-align-center;
            width: 46px;
            opacity: 1;
            animation: animateWidth 0.3s;
          }
          &.nonActive {
            border: 1px solid $c-dd;
          }
          &.nonEditable {
            user-select: none;
            pointer-events: none;
            cursor: unset;
            background-color: #f4f4f6;
            color: $c-3b;
            border: 1px solid $c-dd;
          }
          .stageName {
            @extend %relBlock;
            top: 1px;
            @include text-ellipsis();
            max-width: 200px;
          }
          .inputWithCloseIcon {
            @include flex-center;
            position: relative;
            width: 100%;
            justify-content: space-between;
            .editStageName {
              height: 30px;
              border: 0;
              width: 100%;
              padding: 0;
              font-size: 14px;
              color: $c-3b;
            }
            .closeIcon {
              font-size: 14px;
              color: $c-8d;
              cursor: pointer;
              transform: translateX(0, 100%);
              transition: all 0.3s;
            }
          }
          .actionIcons {
            position: relative;
            @include flex-center;
            justify-content: flex-end;
            min-width: 112px;
            max-width: 154px;
            .hoverWrapIcon {
              position: relative;
              display: none;
              padding-right: 5px;
              width: 0;
              opacity: 0.5;
            }
          }

          .visibilityIcon {
            &.visible {
              color: $blue-violet;
            }
          }
          .iconBg,
          .iconBackground {
            @include wt-ht(18px, 18px);
            border-radius: 3px;
            background-color: #f4f4f6;
            margin-right: 5px;
            @include flex-center;
            justify-content: center;
            cursor: pointer;
            i {
              line-height: revert;
            }
            &:last-child {
              margin-right: 0;
            }
            .cursorNotAllowed {
              cursor: not-allowed;
              opacity: 0.5;
            }

            &.badge {
              width: fit-content;
              padding-left: 8px;
              padding-right: 8px;
              top: 1px;
              font-size: 11px;
              line-height: normal;
              height: 23px;
              border-radius: 12px;
              @include flex-align-center;
              white-space: nowrap;
              &.answered {
                color: #28ad56;
                background: #28ad5629;
                width: 68px;
              }
              &.notAnswered {
                color: $c-8d;
                background: #dddddd80;
                width: 89px;
              }
            }
          }

          .deleteIcon {
            cursor: pointer;
            width: 14px;
            position: relative;
          }

          .renameIcon,
          .visibilityIcon {
            color: $c-8d;
            font-size: 14px;
            position: relative;
          }
        }
      }
    }
  }
}

.createCallStagePopup {
  hr {
    margin-bottom: 25px !important;
  }
  .card {
    position: relative;
    line-height: normal;
    .colorCodes {
      position: relative;
      width: 100%;
      margin-bottom: 2rem;
      top: -1px;
      label {
        @extend %label;
        color: #3b3b3b;
        display: flex;
        font-size: 13px;
      }
      .codeList {
        position: relative;
        width: 100%;
        @include flex-center;
        padding: 8px 0 10px;
        gap: 10px;
        .boxSquare {
          padding: 6px;
          border-radius: 2px;
          border: 1px solid $c-dd;
          outline: transparent solid 4px;
          cursor: pointer;
          &.active {
            border: 1px solid #a3afff;
            outline: #a3afff solid 4px;
          }
        }
      }
      .errMsgText {
        @extend %errMsgText;
        bottom: -10px;
      }
    }
    .inputWrapper .inputCont {
      .inputLabel {
        color: $c-8d;
      }
      .inputBox {
        border-bottom: 1px solid $c-dd;
        &.filled ~ label,
        &:focus ~ label {
          color: $c-8d;
        }
      }
    }
    .callMapToDropdown {
      position: relative;
      width: 100%;
      margin-bottom: 2rem;
      margin-top: 2rem;
      label {
        @extend %label;
        font-size: 11px;
        color: $c-8d;
        display: flex;
        cursor: pointer;
        position: absolute;
        top: -12px;
      }
      .activeDropdownValue {
        position: relative;
        @include flex-center;
        border-bottom: 1px solid $c-dd;
        height: 40px;
        padding: 9px 0px;
        margin-top: 2px;
        border-radius: 0;
        cursor: pointer;
        .activeValue {
          color: $blue-violet;
          font-size: 15px;
          font-weight: 500;
          padding-top: 2px;
        }
        i {
          position: absolute;
          right: 0px;
          font-size: 20px;
          color: $c-66;
        }
      }
      @extend %dropdownFullWidth;
    }
  }
}
