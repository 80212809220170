.dropdownContainer {
  position: relative;
  .dropdown {
    position: relative;
    font-size: 14px;
    color: #333;

    &.disabled {
      @include disable(0.5, unset);
    }

    .dropdownList {
      padding: 12px;
      background: #fff;
      position: absolute;
      top: 44px;
      left: 1px;
      right: 1px;
      transform-origin: 50% 0;
      transform: scale(1, 0);
      transition: transform 0.15s ease-in-out 0.15s;
      overflow-y: scroll;
      z-index: 999;

      .errorMessage {
        font-size: 12px;
        color: red;
      }

      .dropdownFooter {
        padding: 6px;
        width: 100%;
        @include flex-center;
        justify-content: end;
        gap: 8px;
        border-top: 1px solid $c-dd;
        button {
          font-size: 14px;
          padding: 10px 0;
          border-radius: 5px;
          letter-spacing: 0.3px;
          min-width: 120px;
          margin: unset;
          &.saveBtn {
            border: solid 1px $blue-violet;
            background: $blue-violet;
            &.disabledBtn {
              @include disable(0.5, unset);
            }
          }
          &.cancelBtn {
            border: solid 1px $blue-violet;
            color: $blue-violet;
            background: white;
          }
          &.loading {
            @include wt-ht(34px !important, 34px !important);
            transition: all 0.3s ease-in-out;
            min-width: auto;
            border-radius: 35px;
            .load-text {
              @include wt-ht(24px, 24px);
              top: 4px;
              left: 4px;
            }
          }
        }
      }
    }

    .dropdownOption {
      display: block;
      padding: 8px 12px;
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
      .dropValue {
        cursor: pointer;
        .checkBoxSection {
          @include flex-center();
        }
      }
    }

    .dropdownLabel {
      @include flex-center();
      justify-content: space-between;
      background: #fff;
      border: 1px solid #ccc;
      padding: 12px;
      cursor: pointer;
      font-size: 14px;
      color: $c-3b;
      border-radius: 4px;

      .arrow {
        transition: all 0.3s ease;
        @include flex-center();
        justify-content: inherit;
        position: relative;
        svg {
          fill: $c-53;
          @include wt-ht(16px, 12px);
        }

        &.activeArrow {
          transform: rotate(180deg);
        }
      }
    }

    &.on {
      .dropdownList {
        transform: scale(1, 1);
        transition-delay: 0s;
        border-radius: 4px;
        border: 1px solid $c-dd;

        .dropdownOption {
          opacity: 1;
          transition-delay: 0.2s;
          z-index: 999;
        }
      }
    }

    [type="checkbox"] {
      position: relative;
      top: -1px;
      margin-right: 4px;
    }
  }

  .emailOverlayMask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
}
