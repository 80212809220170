.maintenancePage {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .innerCont {
    width: 80%;
    text-align: center;

    .logo {
      margin-bottom: 25px;
    }

    .maintenanceMessage {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 23px;
      line-height: 1.4;
    }

    .moreQuestions {
      margin-top: 25px;
    }
  }
}
