.addInboxButtonWrapper {
  @include flex-center();
  padding: 8px 2px;
  cursor: pointer;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  user-select: none;
  &:hover {
    background: $c-f5;
  }
  &.enabled {
    cursor: default;
  }
  .addInboxCont {
    position: relative;
    transform: translateZ(0);
    overflow: hidden;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    display: flex;
    justify-content: flex-end;
    margin-left: 10px;
    min-width: 35px;
    .addInboxButton {
      position: relative;
      display: flex;
    }
    .addIcon {
      font-size: 16px;
    }
    &.enabled {
      cursor: default;
    }
  }
  .addInboxLabel {
    font-size: 14px;
    color: $c-78;
    word-break: break-word;
    cursor: pointer;
    line-height: 16px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .addedInboxLabel {
    font-size: 10px;
    color: $c-78;
    line-height: 16px;
    &.loaderThreeDotWrapper {
      color: $blue-violet;
    }
  }
}
