@keyframes cardSlideUp {
  0% {
    opacity: 0;
    transform: translateY(-75%);
    transform-origin: 0;
  }
  50% {
    opacity: 0.5;
    transform: translateY(35px) offset(0);
    transform-origin: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0) offset(1);
    transform-origin: 1;
  }
}

@keyframes blinkingText {
  0% {
    color: red;
  }
  49% {
    color: red;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: red;
  }
}

@keyframes around {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}