.accountSection {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  &.showAccountFilter {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    .accountsList {
      flex: 0 0 calc(100% - 310px);
      max-width: calc(100% - 310px);
      transition: all 0.3s ease-in-out;
    }
    .contactFilterByOption {
      height: 100%;
      flex: 0 0 300px;
      max-width: 300px;
      transition: all 0.3s ease-in-out;
    }
  }
  .toggleFilterBtn {
    position: relative;
    @include flex-center();
    height: 36px;
    margin-left: 10px;
    width: 100px;
    &.disabled {
      @include disable(0.5, pointer, unset, unset);
    }
    &:hover {
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
    }
    background: $blue-violet;
    border: 1px solid $blue-violet;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    svg {
      @include wt-ht(14px, 16px);
      fill: white;
      margin: 0 10px;
    }
    .text {
      font-size: 14px;
      padding-right: 6px;
      padding-top: 4px;
    }
    .count {
      @include flex-center;
      justify-content: center;
      background: white;
      color: $blue-violet;
      font-size: 14px;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      @include wt-ht(24px, 32px);
      padding-top: 4px;
    }
    &.filterUsed {
      transition: all 0.3s ease-in-out;
      background: white;
      border: 1px solid $b-c-1;
      svg {
        fill: $blue-violet;
      }
      .text {
        color: $blue-violet;
      }
    }
  }
}

.accountFilterByOption {
  @extend %filterOprionCard;
  width: 300px;
  .contactDateFilter,
  .conditionField {
    position: relative;
    border-bottom: 1px solid $c-ec;
    .dropdownSearchBox {
      @extend %relBlock;
      width: calc(100% - 8px);
      margin: 0 0 10px 8px;
      .horizontalBorderLine {
        position: absolute;
        width: 4px;
        height: 100%;
        background: #dee3ff;
        border-radius: 5px;
        left: -8px;
        top: 0px;
        z-index: 1;
      }
    }
  }
}
