.searchContactTag .searchInputBox {
  position: relative;
  width: 286px;
  border: 1px solid $c-eb;
  background: white;
  @include flex-center();
  border-radius: 6px;
  flex-direction: row;
}
.searchInputBtn {
  @extend %searchInput;
  &.disabled {
    pointer-events: none;
    user-select: none;
  }
}
