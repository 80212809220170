.freeTrialFormPopup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  @include flex-center();
  justify-content: center;
  letter-spacing: 0.3px;
  width: 100%;
  z-index: 100;
  overflow: auto;
  .ftCard {
    position: relative;
    display: grid;
    padding: 50px 60px 0;
    width: 900px;
    align-items: flex-end;
    border-radius: 10px;
    grid-template-columns: 45% 55%;
    background: linear-gradient(to right, $blue-violet, #465ffc);
    z-index: 101;
    user-select: none;
    .overlayLogo {
      position: absolute;
      width: calc(100% - 80px);
      margin: 0 auto;
      top: 0;
      left: 0;
      right: 0;
      opacity: 0.2;
      svg {
        fill: rgba(255, 255, 255, 0.44);
        @include wt-ht(100%, 100%);
        path {
          fill: rgba(255, 255, 255, 0.44);
        }
      }
    }
    .contentCard {
      color: white;
      position: relative;
      padding-right: 30px;
      .welcomeText {
        position: relative;
        font-size: 18px;
        @include flex-center;
        font-weight: 16px;
        margin-bottom: 30px;
        .wrapTitleLogo {
          padding-left: 10px;
          padding-right: 2px;
          position: relative;
          @include flex-center;
          svg {
            fill: white;
            height: 32px;
            &:not(:last-child) {
              margin-left: 5px;
            }
            path {
              fill: white;
            }
          }
        }
      }
      h5 {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.3px;
        margin-bottom: 20px;
      }
      .descListItem {
        position: relative;
        display: grid;
        grid-template-columns: 30px auto;
        align-items: flex-start;
        margin-bottom: 10px;
        img {
          @include wt-ht(16px, 16px);
        }
        .text {
          font-size: 14px;

          line-height: 1.3;
        }
      }
      .wrapEmailImg {
        background: transparent url("./../../../assets/png/ellipseHalf.png")
          no-repeat center;
        background-size: 100% 100%;
        margin-top: 50px;
        text-align: center;
        height: 120px;
        img {
          height: 90px;
          margin-top: -10px;
        }
      }
    }
    .formContent {
      position: relative;
      padding-left: 30px;
      display: block;
      height: 100%;
      .formBody {
        padding: 30px 50px;
        position: relative;
        background: white;
        @include bor-rad-top(10px, 10px);
        display: block;
        height: 100%;
        .formTitle {
          font-size: 20px;
          padding-left: 20px;
          font-weight: 600;
          text-align: center;
          margin: 10px 0 40px;
          color: $blue-violet;
          text-transform: capitalize;
          color: $c-53;
          @include flex-center;
          justify-content: center;
        }
        .formCard {
          display: block;
          .staticLabel {
            top: -4px;
            font-size: 12px;
          }
          .input-form {
            padding-bottom: 25px;
          }
          .err-msg-text {
            bottom: 10px;
            &.global {
              left: 0;
              right: 0;
            }
          }
          .telephonicInputCont {
            margin-top: 5px;
            .react-tel-input {
              width: 100%;
              position: relative;
              border-bottom: 1px solid $c-dd;
              .flag-dropdown,
              .selected-flag {
                z-index: 1;
                border: none;
                background: white;
                .country-list {
                  @include wt-ht(300px !important, 200px !important);
                  .country {
                    height: 30px !important;
                    font-size: 14px !important;
                  }
                  .country-name,
                  .dial-code {
                    font-family: $font-regular, sans-serif;
                    color: $c-66;
                    font-size: 14px !important;
                  }
                }
              }
              input[type="tel"] {
                height: 30px;
                border: none;
                outline: none;
                box-shadow: none;
              }
            }
          }
          .ftContinueBtn {
            min-width: 70%;
            margin-top: 20px;
            background: #3953fb;
            letter-spacing: 0.3px;
            font-weight: 500;
            font-size: 16px;
            height: 40px;
            &.loading {
              min-width: auto;
            }
          }
        }
      }
    }
  }
  .overlayFreeTrialFormPopup {
    @include bg-mask(99, rgba(0, 0, 0, 0.57));
  }
}
