.email-insight-stage {
  position: relative;
  @include list-style-zero();
  display: flex;
  align-items: center;
  width: 100%;
  li {
    position: relative;
    .icon-tag-last {
      margin-right: 0 !important;
    }
    :not(:last-child) {
      margin-right: 30px;
    }
    .customTooltip {
      background-color: #393a3d;
      .sharpPointer {
        border-right: 1px solid $c-33;
        border-top: 1px solid $c-33;
        background-color: #393a3d;
        border-top-right-radius: 10px;
        right: -20px;
        top: -44px;
        padding: 5px;
      }
      .tooltipBody {
        width: fit-content;
        right: -10px;
        white-space: nowrap;
        background-color: #393a3d;
        right: -33px;
        top: -64px;
        border-radius: 4px;
        .tooltipText {
          color: $white;
          font-size: 12px;
          padding: 5px;
        }
      }
    }
    .number-count {
      width: fit-content;
      position: absolute;
      font-size: 11px;
      border: 1px solid transparent;
      color: $c-8a;
      border-radius: 35px;
      top: -5px;
      left: 15px;
      min-width: 16px;
      @include flex-align-center;
      white-space: nowrap;
      padding: 0px 5px;
      z-index: 0;
      background: $white;
      cursor: pointer;
    }
    i,
    .icon-tag {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: $blue-violet;
      color: $white;
      font-size: 14px;
      border: 1px solid $blue-violet;
      cursor: pointer;
      &:not(.icon-tag-empty) {
        background: white;
        border: 1px solid $c-blue;
        color: $blue-violet;
        svg {
          color: $blue-violet;
          fill: $blue-violet;
          * {
            color: $blue-violet;
            fill: $blue-violet;
          }
        }
      }
      svg {
        cursor: pointer;
        @include wt-ht(12px, 12px);
        fill: $white;
      }
      &.sendIcon svg {
        @include wt-ht(10px, 10px);
      }
      &.icon-tag-empty {
        color: $c-53 !important;
        background: $white;
        border: 1px solid #e3e4e4;
        svg {
          fill: $c-53 !important;
          color: $c-53 !important;
        }
      }
    }

    &:not(:last-child) .line-dot {
      z-index: 0;
      position: absolute;
      right: 0;
      display: block;
      height: 1px;
      width: calc(100% - 30px);
      background: transparent;
      letter-spacing: 1px;
      border-bottom: 1px dashed #d2d2d2;
      top: 50%;
    }
  }
}
