.contactRangeSelection {
  position: absolute;
  .rangeSelector {
    position: relative;
    width: 220px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px $b-c-1;
    background-color: white;
    z-index: 200;
    .itemList {
      position: relative;
      font-size: 13px;
      padding: 10px;
      line-height: 1.3;
      text-align: left;
      color: $c-87;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        background: $c-f5;
        color: $c-66;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $b-c-1;
      }
      &.active {
        background: $c-f5;
        color: $c-66;
      }
    }
    .customRangeSelector {
      position: relative;
      .cntHeader {
        position: relative;
        @include flex-center;
        border-bottom: 1px solid $b-c-1;
        padding: 0 10px;
        height: 38px;
        i {
          margin-right: 6px;
          font-size: 20px;
          color: $c-ae;
          padding: 3px;
          cursor: pointer;
          border-radius: 35px;
          &:hover {
            background: $c-f5;
            color: $c-66;
          }
        }
        .text {
          padding-top: 4px;
          position: relative;
          opacity: 0.8;
          font-size: 14px;
          text-align: left;
          color: $c-78;
        }
      }
      .cntBody {
        position: relative;
        padding: 10px;
        .inputWrapper {
          @extend %inputWrapper;
          margin-bottom: 15px;
          input {
            line-height: 30px !important;
            font-size: 13px;
            &::placeholder {
              opacity: 0.4;
              font-size: 13px;
              color: $c-78;
            }
          }
        }
        .applyCustomRange {
          @include wt-ht(100%, 25px);
          border-radius: 5px;
          margin-top: 10px;
          border: solid 1px $blue-violet;
          background-color: #fff;
          color: $blue-violet;
          transition: all 0.3s ease;
          &:hover {
            background: $blue-violet;
            color: white;
          }
        }
      }
      .cntFooter {
        position: relative;
        @include flex-center;
        border-top: 1px solid $b-c-1;
        padding: 0 10px;
        height: 36px;
        i {
          margin-right: 4px;
          opacity: 0.6;
          font-size: 12px;
          color: $c-78;
        }
        .text {
          padding-top: 2px;
          position: relative;
          opacity: 0.8;
          font-size: 10px;
          text-align: left;
          color: $c-78;
        }
      }
    }
  }
  .bgOverlay {
    @include bg-mask(100, transparent);
  }
}
