@import "./configuration";
@import "./events";
@import "./createEvent";
@import "./addDefaultInbox";

%toggleBtn {
  .toogleButton {
    position: relative;
    @include wt-ht(32px !important, 16px !important);
    &.loading {
      .knobs::before {
        animation: spin 1s linear infinite;
        border: 1.5px solid $blue-violet;
        border-bottom-color: $blue-violet;
        border-left-color: $blue-violet;
        border-left: 1px;
        background-color: transparent;
      }
      .toggleCheckbox:checked + .knobs:before {
        background-color: transparent;
      }
    }
    .knobs::before {
      @include wt-ht(10px, 10px);
      top: 3px;
      left: 3px;
      padding: unset;
    }
    .toggleCheckbox:checked {
      & ~ .layer {
        background: white;
        border: 1px solid $blue-violet;
      }
      & + .knobs:before {
        left: 18px;
        background: $blue-violet;
      }
    }
    .layer {
      background: white;
      border: 1px solid $c-d3;
    }
    .knobs:before {
      background: $c-a7;
    }
  }
}

.calendarSetting {
  @extend %relBlock;
  @include wt-ht(100%, 100%);
  background: white;

  // Component
  .actionIconTooltip {
    @extend %relBlock;
    cursor: pointer;
    .customTooltip {
      .sharpPointer {
        padding: 4px;
        right: 2px;
        top: -1px;
        border-right: 1px solid black;
        border-top: 1px solid black;
        background: black;
        border-top-right-radius: 2px;
      }
      .tooltipBody {
        width: unset;
        min-width: 30px;
        white-space: nowrap;
        right: -10px;
        top: 3px;
        background-color: black;
        border-radius: 4px;
        .tooltipText {
          line-height: initial;
          padding: 5px 8px;
          color: white;
          text-align: center;
        }
      }
    }
  }

  .actionIcon,
  %icon {
    position: relative;
    cursor: pointer;
    padding: 2px;
    font-size: 12px;
    color: $c-8d;
    transition: all 0.3s ease;
    border-radius: 35px;
    &:hover {
      color: $c-66;
      background: $c-f5;
    }
  }

  .headerLayout {
    position: relative;
    width: 100%;
    height: 55px;
    padding: 0 30px;
    display: grid;
    border-bottom: 1px solid $c-ec;
    grid-template-columns: 160px calc(100% - 160px);
    .titleSection {
      position: relative;
      @include flex-center;
    }
    .actionSection {
      position: relative;
      @include flex-center;
      justify-content: flex-end;
      .calendarPrimaryUrl {
        position: relative;
        @include flex-center;
        justify-content: flex-end;
        .staticDomain,
        .pathValue {
          position: relative;
          white-space: nowrap;
          font-size: 13px;
          transition: all 0.3s ease;
        }
        .pathValue {
          position: relative;
          @include flex-center;
          padding: 0 3px;
          .clearIcon {
            @extend %icon;
            margin-left: 2px;
            @include flex-center;
            border-radius: 2px;
            @include wt-ht(16px, 16px);
            background: white;
          }
          .saveIcon {
            @extend %icon;
            margin-left: 2px;
            @include flex-center;
            color: $c-10c;
            font-weight: 600;
            &:hover {
              color: $c-10c;
            }
            background: white;
            border-radius: 2px;
            @include wt-ht(16px, 16px);
          }
          .errMsgText {
            right: 0;
            left: unset;
          }
          &.editPathValue {
            background: aliceblue;
            .inputWrapper input {
              background: aliceblue;
            }
          }
        }
        .inputWrapper {
          @extend %inputWrapper;
          margin-bottom: 0;
          input.inputBox {
            line-height: inherit !important;
            font-size: 13px !important;
          }
        }
        .actionIcon {
          @include flex-center;
          font-size: 12px;
        }
        .baseUrlActionIcon {
          position: relative;
          @include flex-center;
        }
        .baseUrlLoader {
          @extend %relBlock;
          @include wt-ht(360px, 11px);
        }
      }
    }
  }

  .createNewEventBtn {
    margin: 0 10px 10px auto !important;
    padding: 8px 12px;
    background: $blue-violet;
    height: 32px;
  }

  // Calendar Page
  .calendarCont {
    @extend %relBlock;
    @include wt-ht(100%, 100%);
    // Header
    .navTabCont {
      position: relative;
      @include flex-center;
      justify-content: space-between;
      border-bottom: 1px solid $c-ec;
      height: 50px;
      .tabNavigation {
        background: white !important;
        border-bottom: none !important;
        .tabItem {
          height: 46px;
          color: #343a40;
          &.active {
            color: $blue-violet;
          }
        }
      }
    }
    // Main Content
    .calendarMainContent {
      @extend %relBlock;
      @include wt-ht(100%, 100%);
      background: white;
      .eventsContainer {
        @extend %eventsContainer;
      }
      .configurationCont {
        @extend %configurationCont;
      }
    }
  }

  .createEventFormSection {
    @extend %createEventFormSection;
  }
}
