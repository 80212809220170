.reportsSection {
  position: relative;
  @include wt-ht(100%, 100%);
  background: $c-f5f8;

  .wrapFlex {
    @include flex-center;

    .reportInfoIconTp {
      position: relative;
      @include flex-center;
      margin-left: 5px;

      i {
        font-size: 18px;
        color: $blue-violet;
        padding: 2px;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          background: $c-f5;
        }
      }

      .wrapInfoText {
        position: absolute;
        left: 24px;
        width: 420px;
        font-size: 12px;
        padding: 7px;
        background: black;
        border-radius: 10px;
        color: white;

        .sharpPointer {
          position: absolute;
          padding: 5px;
          z-index: 4;
          top: 18px;
          left: -5px;
          transform: rotate(45deg);
          background: black;
        }
      }
    }
  }

  .reportsContainer {
    position: relative;
    @include wt-ht(100%, 100%);
    display: flex;
    padding: 12px;

    .reportsBody {
      @extend %body-layout;
      padding: 0;
      &.isNewReportsEnabled {
        height: calc(100% - 124px);
      }
      .reportsCard {
        position: relative;
        @include wt-ht(100%, 100%);
        border-radius: 15px;

        .filterNaviagtionCard {
          position: sticky;
          top: 0;
          z-index: 9;
          width: 100%;
          @include flex-center();
          justify-content: space-between;
          border-bottom: 1px solid $b-c-1;

          // margin-bottom: 10px;
          .reportsFilter {
            position: relative;
            width: 100%;
            @include flex-center();
            justify-content: flex-end;
            padding-bottom: 10px;

            .lockIcon {
              background: orange;
              color: white;
              font-size: 12px;
              width: 20px;
              height: 20px;
              border-radius: 35px;
              justify-content: center;
              display: flex;
              align-items: center;
              position: relative;
              margin-left: 10px;
            }

            .userFilterDropDown {
              position: relative;
              margin-right: 10px;

              .userFilterBtn {
                position: relative;
                @include flex-center();
                justify-content: center;
                outline: none;
                background: white;
                border-radius: 3px;
                border: 1px solid $b-c-1;
                padding: 0 10px;
                @include wt-ht(200px, 36px);
                font-size: 15px;
                transition: all 0.3s ease;
                cursor: pointer;
                &:hover {
                  background: $c-fb;
                  transition: all 0.3s ease;
                }

                .icon {
                  margin-right: 10px;
                  @include wt-ht(14px, 14px);

                  svg {
                    fill: $blue-violet;
                    @include wt-ht(14px, 14px);
                  }
                }

                .UpgradeTooltip {
                  .sharpPointer {
                    right: 29px;
                  }
                }

                .name {
                  position: relative;
                  color: $blue-violet;
                  min-width: 100px;
                  max-width: 110px;
                  text-align: left;
                  font-size: 15px;
                  top: 2px;
                  line-height: initial;

                  .shortName {
                    text-transform: uppercase;

                    &:not(:last-child)::after {
                      content: ", ";
                    }

                    &.teamNameSelected {
                      text-transform: unset;
                      @include ellipsis;
                    }
                  }
                }

                .hiddenCount {
                  font-size: 13px;
                  color: $c-78;
                  min-width: 30px;
                  line-height: initial;
                  position: relative;
                  top: 1px;
                }

                .arrow {
                  margin-left: 5px;
                  margin-top: 3px;
                  @include flex-center();
                  transition: all 0.3s ease;

                  svg {
                    fill: $c-53;
                    @include wt-ht(11px, 11px);
                  }

                  &.activeArrow {
                    transform: rotate(-180deg);
                    transition: all 0.3s ease;
                  }
                }
              }
              @extend %userListDropdown;
              .overlayUserFilterMask {
                @include bg-mask(4, transparent);
              }
            }

            .dateFilter {
              @extend %dateFilter;
            }

            .rdrCalendarWrapper {
              position: absolute;
              z-index: 5;
              right: 0;
              top: 36px;
              box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);

              .rdrMonthAndYearWrapper {
                padding-top: 0;
                height: 46px;
              }

              .rdrMonth {
                padding-bottom: 10px;
              }

              .rdrWeekDay {
                line-height: 1.8;
              }

              &.dateRangePicker .dateBtnSection {
                position: relative;
                padding: 0 0.833em 1.666em 0.833em;
                text-align: right;
                @include flex-center;
                justify-content: flex-end;

                input[type^="button"] {
                  height: 30px;
                  border-radius: 35px;
                  outline: none;
                  letter-spacing: 0.3px;
                  font-size: 14px;
                  width: 80px;
                  border: none;
                  font-weight: 400;
                  cursor: pointer;

                  &:not(:first-child) {
                    margin-left: 10px;
                  }

                  &.cancelCustomDateFilter {
                    background: white;
                    color: $c-66;
                    transition: all 0.3s ease;

                    &:hover {
                      transition: all 0.3s ease;
                      background: $c-f5;
                      color: black;
                    }
                  }

                  &.applyCustomDateFilter {
                    color: white;
                    background: #3d91ff;
                    transition: all 0.3s ease;

                    &:not(:disabled):hover {
                      transition: all 0.3s ease;
                      box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.12);
                    }

                    &:disabled {
                      background: $c-78;
                      cursor: default;
                      opacity: 0.5;
                    }
                  }
                }

                .wrapUpgradeIconTooltip {
                  padding-left: 30px;

                  .freemiumIcon {
                    right: 10px;
                  }
                }

                #customDateFilterErrMsg {
                  display: block;
                  position: absolute;
                  font-size: 12px;
                  bottom: 3px;
                  color: $c-78;
                  transition: all 0.3s ease;

                  &.highlightErr {
                    color: red;
                  }
                }
              }
            }

            .overlayCustomDateFilter {
              @include bg-mask(4, transparent);
            }
          }
        }

        .reportsTableCard {
          @extend %relBlock;
          padding-top: 12px;
          @include wt-ht(100%, calc(100% - 48px));
        }

        .reportsSeqInsightsTableSection {
          position: relative;
          width: 100%;
          .tableHeader,
          .tableRow,
          .tableRowLoader {
            position: relative;
            display: grid !important;
            width: 100%;
            grid-template-columns: 17% 13% 13% 13% 10% 10% 10% 14%;
          }
        }

        .reportsSummaryTableSection {
          .teamMember {
            width: 16%;
          }

          .emailsSent,
          .meetingBooked {
            width: 14%;
          }

          .replyRate,
          .callsMade {
            width: 12%;
          }

          .callsConnected,
          .contactsAdded,
          .tasksCompleted {
            width: 16%;
          }

          .tasksCompleted {
            display: flex !important;
          }
        }

        .reportsSummaryTableSection,
        .reportsSeqInsightsTableSection {
          position: relative;
          background: $white;
          @include wt-ht(100%, 100%);
          border-radius: 10px;
          border: 1px solid $b-c-1;
          box-shadow: 0 0 12px 2.7px rgba(59, 59, 59, 0.04);

          .teamMember {
            position: relative;
            padding: 0 10px;
            font-size: 15px;

            h6.emailAddress {
              font-size: 14px;
              color: $c-78;
              font-weight: 400;
              margin-top: 2px;
              margin-bottom: 5px;
              @include ellipsis();

              &.linear-background {
                @include wt-ht(60%, 12px);
                display: block;
                border-radius: 10px;
                margin-top: 7px;
                margin-bottom: 7px;
              }
            }

            p.fullName {
              font-size: 16px;
              color: $c-33;
              margin-top: 5px;
              font-weight: 500;
              margin-bottom: 2px;
              @include ellipsis();

              &.linear-background {
                @include wt-ht(80%, 16px);
                display: block;
                border-radius: 10px;
                margin-top: 7px;
                margin-bottom: 7px;
              }
            }
          }

          .sequenceName {
            position: relative;
            padding: 0 10px;
            font-size: 15px;
          }

          .tasksCompleted {
            display: flex;
            flex-direction: column;
            align-items: center;

            .tasksCompletedSubSection {
              font-size: 10px;
              display: flex;
            }
          }

          .emailsSent,
          .openRate,
          .clickRate,
          .replyRate,
          .contactsAdded,
          .tasksCompleted,
          .bouncesCount,
          .meetingBooked,
          .callsMade,
          .callsConnected {
            padding: 0 10px;
            position: relative;
            @include ellipsis();
            font-size: 15px;
            text-align: center;
          }

          .tableHeader {
            @extend %tableHeader;
            position: sticky;
            top: -16px;
            z-index: 8;
            padding: 5px 15px;
            @include flex-direction(row);
            height: 49px;
            border-top: none;
            @include bor-rad-top(10px, 10px);
          }

          .tableBody {
            position: relative;
            @include wt-ht(100%, calc(100% - 62px));
            @include flex-direction(column);
            overflow: auto;
            background: white;

            .openRate,
            .clickRate,
            .replyRate {
              @include flex-center();
              justify-content: center;

              i {
                font-size: 6px;
                margin-right: 8px;
              }
            }

            .openRate,
            .openRate .dot {
              color: #658bed;
            }

            .clickRate,
            .clickRate .dot {
              color: #ff8d55;
            }

            .replyRate,
            .replyRate .dot {
              color: #3ecb6f;
            }

            .tableRow {
              &:hover {
                background: $c-f8;
              }
            }

            .tableRow,
            .tableRowLoader {
              font-size: 16px;
              @include flex-direction(row);
              @extend %relPadTableWidth;
              padding: 5px 15px;
              border-bottom: 1px solid $tb-bor-btm;

              .linear-background {
                @include wt-ht(60%, 14px);
                display: block;
                border-radius: 10px;
                margin: 2px 0;
              }

              .emailsSent,
              .openRate,
              .clickRate,
              .replyRate,
              .contactsAdded,
              .tasksCompleted,
              .bouncesCount,
              .optOutsCount,
              .callsMade,
              .callsConnected,
              .meetingBooked {
                .linear-background {
                  margin: 2px auto;
                }
              }
            }
          }
        }

        .reportInsights {
          @extend %relBlock;
          height: calc(100% - 30px);

          .wrapInsightRow {
            padding: 15px 1% 0px;
            height: 100%;
            overflow: auto;

            .chartCard {
              @extend %relBlock;
              background: white;
              border-radius: 15px;
              box-shadow: 0 0 12px 2.7px rgba(32, 31, 31, 0.04);
              padding: 20px;

              h4 {
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 10px;
                color: $c-55;
              }

              .chartContent {
                @extend %relBlock;
                height: 350px;
              }

              .chartLoader {
                @include flex-center;
                justify-content: center;
                @include wt-ht(100%, 100%);
              }
            }

            .sequenceInsights {
              background: white;
              border-radius: 15px;
              box-shadow: 0 0 12px 2.7px rgba(59, 59, 59, 0.04);
              padding-top: 20px;
              margin: 20px 0;

              h4 {
                padding: 0 20px;
                font-weight: 500;
                font-size: 18px;
                color: $c-55;
                margin-bottom: 15px;
              }

              .reportsSeqInsightsTableSection {
                border-radius: 0;
                @include bor-rad-btm(15px, 15px);
                border-bottom: none;
                box-shadow: none;

                .tableHeader {
                  @include flex-center;
                  border-radius: 0;

                  .headerName {
                    @include flex-center;

                    &:not(:first-child) {
                      justify-content: center;
                    }

                    .name {
                      @extend %relBlock;
                    }

                    .sortIcon {
                      font-size: 18px;
                      padding: 2px;
                      width: 24px;
                      margin-left: 5px;
                      @extend %relBlock;
                      border-radius: 50%;
                      transition: all 0.3s ease;
                      cursor: pointer;
                      color: $c-78;
                      transform: rotate(180deg);

                      &:hover {
                        background: $c-eb;
                        transition: all 0.3s ease;
                      }

                      &.activeSort {
                        color: black;
                      }

                      &.ascending {
                        transform: rotate(0deg);
                      }
                    }
                  }
                }

                .tableBody {
                  border-radius: 0;
                  @include bor-rad-btm(15px, 15px);
                  .tableRow {
                    min-height: 54px;

                    &:last-child {
                      border-bottom: 0;
                    }

                    .sequenceName {
                      @include flex-center;

                      .name {
                        font-size: 15px;
                        max-width: calc(100% - 30px);
                        @include ellipsis();
                        cursor: pointer;
                        color: $blue-violet;
                        &:hover {
                          text-decoration: underline;
                        }
                        &.sharedText {
                          max-width: calc(100% - 82px);
                        }
                      }

                      .trigger {
                        @include flex-center;
                        margin-left: 5px;

                        .owner {
                          background: $c-e3;
                          color: $c-75;
                          padding: 5px;
                          line-height: 9px;
                          font-size: 9px;
                          text-transform: uppercase;
                          letter-spacing: 0.4px;
                          cursor: pointer;
                        }
                      }

                      .shared {
                        letter-spacing: 0.4px;
                        background: #ffdfd1;
                        padding: 5px;
                        line-height: 9px;
                        font-size: 9px;
                        color: #e46833;
                        border-radius: 3px;
                        margin-left: 5px;
                        text-transform: uppercase;
                      }
                    }
                  }

                  .tableRowLoader {
                    min-height: 54px;
                  }
                }

                .pagination-section {
                  @include bor-rad-btm(15px, 15px);
                }
              }
            }
          }
        }
      }
    }
  }
}

.noReportsFound {
  position: relative;
  letter-spacing: 0.3px;
  padding-top: 40px;
  @include flex-direction(column);
  justify-content: center;

  .textDesc {
    color: #abaeb5;
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: 0.3px;
  }
}

.reportTableInitialScreen {
  position: relative;
  @include wt-ht(100%, 100%);
  padding: 25px;
  display: grid;
  align-items: center;
  grid-template-columns: 60% 35%;

  img {
    max-width: 100%;
    margin: 0 0 0 auto;
  }

  .textDesc {
    color: #abaeb5;
    font-size: 18px;
    max-width: 340px;
    line-height: 1.3;
    letter-spacing: 0.3px;
    text-align: left;
    padding-left: 20px;
    font-style: italic;
  }

  &.emptyEmailInsight {
    img {
      height: unset;
    }
  }

  &.emptySequenceInsight {
    height: 300px;

    img {
      height: unset;
    }

    .textDesc {
      max-width: 380px;
    }
  }
}
