.site-content {
  position: relative;
  top: 50px;
  float: left;
  width: calc(100% - 50px);
  margin-left: 50px;
  color: $c-78;
  height: calc(100% - 10px);
  transition: all 0.3s ease;
  &.installBannerHidden {
    .settingsPage .crmIntegration,
    .settingsPage .schedulesWrapper,
    .pricingSection .pricingContainer.rightSideBar,
    .settingsPage .rightSideBarWrapper,
    .settingsPage .customFields,
    .settingsPage .createStages {
      height: calc(100vh - 82px) !important;
    }
  }

  &.combinedBanners {
    .settingsPage .crmIntegration,
    .settingsPage .schedulesWrapper,
    .pricingSection .pricingContainer.rightSideBar,
    .settingsPage .customFields,
    .settingsPage .createStages {
      height: calc(100vh - 82px) !important;
    }
  }
  &.singleBanner {
    .settingsPage .crmIntegration,
    .settingsPage .schedulesWrapper,
    .pricingSection .pricingContainer.rightSideBar,
    .settingsPage .customFields,
    .settingsPage .createStages {
      height: calc(100vh - 82px) !important;
    }
    .fieldList {
      height: calc(100% - 190px) !important;
    }
  }

  &.combinedBanners {
    transition: all 0.3s ease;
    height: calc(100% - 82px);
  }
  &.singleBanner {
    transition: all 0.3s ease;
    height: calc(100% - 50px);
  }
  &.newFeatureUpdateBanner {
    transition: all 0.3s ease;
    height: calc(100% - 82px);
  }

  .commonPopup {
    position: relative;
    @include wt-ht(65%, auto);
    padding: 25px;
    font-family: $font-regular;
    position: fixed;
    z-index: 112;
    top: 50%;
    display: block;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 440px;
    border-radius: 5px;
    animation: modalSlide 0.75s;
    .commonPopupInnerCont {
      border-radius: 5px;
      overflow: hidden;
      .popupHeader {
        font-size: 21px;
        font-weight: 500;
        color: white;
        background: $lb-blue;
        padding: 20px;
      }

      .popupBodyContainer {
        padding: 20px;
        background: white;

        .input-cont {
          padding: 10px 0;
          width: 100%;
          display: inline-block;

          input {
            color: #212420;
            font-size: 14px;
            padding: 5px;
            border-width: 0 0 1px;
            background: white;
            border-color: $tb-bor-btm;
            line-height: 25px;
            width: 80%;

            &:focus {
              border-color: $blue;
            }
          }
        }
      }
    }

    .popup-close {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;

      svg {
        fill: white;
      }
    }
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
