.helpPopupWrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  .common-popup {
    padding: 0px;
    min-width: 500px;

    .helpThankyouPopup {
      border-radius: 10px;
      min-width: 450px;
      height: 375px;

      .popup-content-wrap {
        padding: 35px 0px 0px 0px;
        height: 100%;

        .popup-close {
          top: 10px;
        }

        .popupTitle {
          font-family: "Graphik-Semibold";
          font-size: 16px;
          letter-spacing: 0.4px;
          text-align: center;
          color: $c-3b;
          padding: 15px 15px 10px 15px;
        }

        .popupMessage {
          font-size: 14px;
          text-align: center;
          color: $c-66;
          padding: 5px 45px;
        }

        .popupImage {
          margin-top: 20px;
          @include flex-center();
          justify-content: center;
          background-image: url("../../../assets/png/help_background.png");
          background-size: contain;
          background-position: bottom;
          background-repeat: no-repeat;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;

          img {
            width: 275px;
            height: auto;
            object-fit: contain;
          }
        }
      }
    }
  }
}
