.syncLogsHistoryPopupWrapper {
  @extend %popupCard;
  z-index: 12;
  .card {
    width: 65%;
    max-height: auto;
    text-align: left;
    position: relative;
    padding: 20px 16px;
  }
  .popupHeading {
    font-size: 20px;
    color: $c-3b;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .popupTableHeader,
  .popupTableContent {
    border: 1px solid $c-dd;
    border-collapse: collapse;
    font-size: 12px;
    display: grid;
    grid-template-columns: 20% 10% 20% 15% 15% auto;
    span {
      text-align: center;
    }
  }
  .tableWrapper {
    display: contents;
  }
  .popupTableHeader {
    border-bottom: 0;
    font-weight: bold;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    .flexCenterWithBorder {
      @include flex-align-center;
      border-right: 1px solid $c-dd;
      padding: 8px;
      &:last-child {
        border-right: 0;
      }
    }
    .filterWrapper {
      position: relative;
      .filterIcon {
        @include flex-center;
        margin-left: 4px;
        cursor: pointer;
        svg {
          @include wt-ht(12px, 12px);
          fill: $c-66;
        }
      }
      .drop-down-menu {
        left: 0;
        font-weight: 400;
        ul {
          width: 60px;
        }
      }
      .filterDropdownOverlay {
        @include bg-mask(110, transparent);
      }
    }
  }
  .popupTableContent {
    border-bottom-left-radius: 6px;
    overflow: auto;
    max-height: 350px;
    border-bottom-right-radius: 6px;
    span {
      border-bottom: 1px solid $c-dd;
      border-right: 1px solid $c-dd;
      padding: 8px;
      text-align: center;
    }
    .syncSource {
      text-transform: capitalize;
    }
  }
  .noLogsFound {
    font-size: 16px;
    text-align: center;
    @include wt-ht(100%, 100px);
    @include flex-align-center;
    &.borderDone {
      border: 1px solid $c-dd;
    }
  }
  .closeIcon {
    position: absolute;
    right: 24px !important;
    top: 24px !important;
    cursor: pointer;
    border-radius: 50%;
    font-size: 18px;
    padding: 4px;
    &:hover {
      background-color: $c-dd;
    }
  }
  .criteriaWrapper {
    @include flex-center;
    font-size: 14px;
    color: $c-66;
    margin: 12px 0;
    .popupCriteria {
      color: $blue-violet;
      font-weight: 500;
    }
  }
  .actionBtn {
    @include flex-center;
    justify-content: right;
    .startSyncCrm {
      width: 120px;
      background: $blue-violet;
      cursor: pointer;
      margin: 0;
    }
    .cancelBtn {
      color: $blue-violet;
      width: 100px;
      cursor: pointer;
      background-color: $white;
      margin: unset;
      margin-right: 16px;
      border: 1px solid $blue-violet;
    }
  }
  .paginationContent {
    @include flex-align-center;
    margin: 0 auto;
    margin-top: 12px;
    .pageLimit {
      width: fit-content;
      .text,
      .limitValue {
        font-size: 10px;
      }
      .wrapIconValue .value {
        font-size: 10px;
      }
      .wrapIconValue .arrow svg {
        @include wt-ht(10px, 10px);
      }
    }
    .pagination-section {
      padding: 0;
      border-top: 0;
      width: auto;
      margin: 0;
      .pagination,
      .pagination li {
        font-size: 10px;
      }
    }
  }
}
.popupTableContentLoader {
  .syncDate {
    @include flex-align-center;
    .linear-background {
      width: 70%;
    }
  }
  .syncSource {
    @include flex-align-center;
    .linear-background {
      width: 60%;
    }
  }
  .syncStatus {
    @include flex-align-center;
    .linear-background {
      width: 60%;
    }
  }
  .addedCount,
  .updatedCount,
  .failedCount {
    @include flex-align-center;
    .linear-background {
      width: 50px;
    }
  }
}

.syncConfirmationPopupOverlay {
  @include bg-mask(11, rgba(0, 0, 0, 0.65));
}
