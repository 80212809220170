.accountsOverview {
  position: relative;
  background-color: $white;
  height: calc(100% - 192px);
  .ctTlSection {
    width: 100%;
    grid-template-columns: 100%;
    padding: 0px;
    .ctTlBody {
      width: 100%;
      height: calc(100vh - 48px);
      .ctTlContentBody {
        height: calc(100% - 146px);
        width: 100%;
        box-shadow: none;
        .title {
          display: none;
        }
        .ctTlFilter {
          display: none;
        }
      }
    }
  }
}

.singleBanner .accountsOverview .ctTlBody {
  background: transparent;
  height: calc(100vh - 88px) !important;
}
