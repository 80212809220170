.customUrlsuccess {
  height: 100%;

  .customUrlSuccessPopupWrapper {
    min-width: 400px !important;
    border-radius: 10px;
    background: white;
    padding: 25px 25px 30px;
    .textTitle {
      @extend %relBlock;
      text-align: center;
      font-family: $font-medium;
      padding: 20px 0 15px;
      font-weight: 400;
      font-size: 20px;
    }
    p.text {
      color: $c-78;
      font-size: 14px;
      text-align: center;
      padding: 0 10px;
      line-height: 1.4;
      margin-bottom: 20px;
    }
    .successCustomUrlBtn {
      @extend %primaryBlueBtn;
      cursor: pointer;
      width: 120px;
    }
  }
}
