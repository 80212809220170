%contentSpace {
  padding: 14px 30px;
  position: relative;
}
%label {
  font-size: 16px;
  font-weight: 500;
  color: $c-52;
  margin-bottom: 4px;
  position: relative;
}
.addStagesPopup,
.stepEmailCreateEditorPopup,
.testEmailPopupWrapper,
.initialSchedulePopupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  @include flex-center();
  justify-content: center;
  letter-spacing: 0.3px;
  @include wt-ht(100%, 100%);
  z-index: 100;
  overflow: auto;
  .disabledUserAction {
    user-select: none;
    pointer-events: none;
    cursor: unset;
  }
  .addStagesPopupCard {
    position: relative;
    display: block;
    z-index: 101;
    border-radius: 10px;
    background: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.53);
    width: 600px;
    .stepIconAction {
      @extend %contentSpace;
      width: 100%;
      display: grid;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $c-ea;
      .stepNo {
        position: relative;
        font-family: $font-regular;
        font-size: 20px;
        font-weight: 600;
        color: $c-1b;
        padding-top: 2px;
        line-height: 1;
      }
    }
    .stageContentBody {
      @extend %contentSpace;
      min-height: 340px;
      .expandLabelInput {
        margin-bottom: 24px;
        padding: 16px 0;
        label.li_send_inMail {
          top: -2px !important;
        }
      }
      .stagesType {
        position: relative;
        width: 100%;
        cursor: pointer;
        .dropdownHeader {
          @include flex-align-center(center, space-between);
          color: $blue-violet;
          border-bottom: 1px solid $c-dd;
          margin-bottom: 14px;
          padding-bottom: 6px;
        }
        .drop-down-menu {
          @include stageDropdownMenu(30px, unset);
        }
      }
      .stageTitle {
        font-size: 18px;
        font-weight: bold;
        font-family: $font-regular;
        color: $c-1a;
        margin-bottom: 1rem;
        .infoText {
          font-size: 14px;
          font-weight: 300;
          text-align: left;
        }
      }
      .stepSummary {
        position: relative;
        padding: 8px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 2px #ffebbf;
        background-color: #fff;
        h6 {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.41;
          color: $c-52;
          margin-bottom: 2px;
        }
        p {
          font-size: 14px;
          line-height: 1.4;
          text-align: left;
          color: $c-57;
        }
      }
      .notesInput {
        @extend %notesInput;
        position: relative;
        .errMsgText {
          left: 0;
          bottom: 0 !important;
          right: unset;
        }
      }
      .wrapInputBox {
        position: relative;
        display: flex;
        column-gap: 16px;
        &.wrapLinkedIn {
          margin-top: 16px;
        }
        .daysLater,
        .linkedinTaskType {
          font-size: 15px !important;
        }
        .inputBoxSection {
          margin-bottom: 1rem;
          label {
            @extend %label;
          }
          .inputBox {
            margin-top: 2px;
            input[type="number"]::-webkit-inner-spin-button {
              opacity: 1;
            }
            input {
              width: 74px;
              text-align: center;
              padding: 10px;
              border: none;
              border-bottom: solid 1px $c-dd;
              background-color: #fff;
              border-radius: 0;
              font-size: 15px;
              font-weight: 500;
              color: $c-1a;
            }
            .descLabelText {
              padding-left: 12px;
              color: $c-53;
              font-size: 18px;
            }
          }
        }
      }
    }
    .footerAction {
      @extend %contentSpace;
      @include flex-center;
      justify-content: flex-end;
      border-top: 1px solid $c-ea;
      column-gap: 15px;
      margin-bottom: 16px;
      button {
        font-size: 14px;
        padding: 10px 0;
        border-radius: 8px;
        letter-spacing: 0.3px;
        min-width: 120px;
        margin: unset;
        &.createStageBtn {
          border: solid 1px $blue-violet;
          background: $blue-violet;
        }
        &.disableBtn {
          pointer-events: none;
          opacity: 0.5;
          cursor: not-allowed;
        }
        &.closeAddStageBtn {
          border: solid 1px $c-71;
          color: $c-52;
          background: white;
        }
        &.loading {
          @include wt-ht(34px !important, 34px !important);
          transition: all 0.3s ease-in-out;
          min-width: auto;
          border-radius: 35px;
          .load-text {
            @include wt-ht(24px, 24px);
            top: 4px;
            left: 4px;
          }
        }
      }
    }
  }
  .emailCreateEditorCard {
    position: relative;
    display: block;
    z-index: 101;
    border-radius: 10px;
    padding: 30px 30px 0;
    background: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.53);
    @include wt-ht(75%, calc(85vh));
    @media (max-width: 1200px) {
      @include wt-ht(90%, calc(85vh));
    }
    .closePopup {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
      font-size: 20px;
      color: $c-66;
      border-radius: 35px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      background: white;
      &:hover {
        background: $c-f5;
        color: black;
      }
    }
    .editorPreviewSection {
      position: relative;
      display: grid;
      grid-template-columns: calc(60% - 25px) 40%;
      column-gap: 25px;
      height: calc(100% - 68px);
      #reactEditor {
        height: 100%;
        .stageTitle {
          position: relative;
          display: block;
          font-weight: 500;
          color: $c-33;
          padding-bottom: 15px;
          font-size: 20px;
        }
        .wrapInputFieldSection {
          display: grid;
          grid-template-columns: repeat(3, 32%);
          column-gap: 10px;
          justify-content: space-between;
          margin-bottom: 14px;
          &.manualTask {
            grid-template-columns: 100%;
          }

          .input-box {
            line-height: 36px;
            -webkit-appearance: none;
            border: 0;
            font-size: 15px;
            width: 100%;
            border-bottom: 1px solid #dddddd;
            color: $blue-violet;
            &.disabledInput {
              cursor: unset;
              border-bottom: none;
              pointer-events: none;
              user-select: none;
            }
          }

          .templateCont {
            background-color: $white;
            color: $black;
            position: relative;
            padding-top: 10px;
            @include flex-center();

            .inputLabel {
              color: #525252;
              font-size: 14px;
              font-family: $font-regular;
              position: absolute;
              top: 6px;
              left: 0;
            }

            #selectTemplateInput {
              width: 100%;
              cursor: pointer;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              padding: 13px 0 2px;
            }

            &.templateInserted {
              #selectTemplateInput {
                width: 80%;
                cursor: default;
              }
            }

            .templateWrapper {
              position: absolute;
              display: none;
              float: left;
              width: 100%;
              height: 100%;
              max-height: 270px;

              &.showDropDown {
                display: block;
              }

              .templateListCont {
                position: absolute;
                width: 100%;
                right: -5px;
                min-width: 250px;
                font-family: inherit;
                z-index: 10;
                font-family: $font-regular;
                top: 60px;
                .selectTitle {
                  background: $blue-violet;
                  padding: 10px 0px;
                  color: #fff;
                  text-align: center;
                  font-size: 14px;
                }

                .innerCont {
                  overflow: auto;
                  max-height: 250px;
                  height: 250px;
                  overflow-y: auto;

                  .loadingText {
                    align-items: center;
                    justify-content: center;
                    z-index: 9;
                    border-radius: 10px;
                    box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
                    border: solid 1px #ebebeb;
                    width: calc(100% - 15px);
                    display: flex;
                    background: $white;
                    padding: 10px;
                    margin-left: 15px;
                    color: $c-66;
                  }

                  .drop-down-menu {
                    width: calc(100% - 15px);

                    .drop-down-menu {
                      overflow-y: auto;
                      left: initial;

                      #notemplates {
                        &:hover {
                          background: none;
                        }
                        span {
                          color: red;
                          font-size: 12px;
                        }
                      }
                    }
                  }
                }

                .noTemplate {
                  text-align: center;
                  padding: 10px 0px;
                }
              }
            }

            .sgCloseIcon {
              font-size: 19px;
              cursor: pointer;
              padding: 0 0 3px;
              margin-top: 18px;
              border-bottom: 1px solid $c-dd;
              line-height: 30px;
              color: $c-53;
            }

            .templateTitle {
              text-align: right;
              cursor: pointer;
            }
          }
        }
        #sequenceAutomaticQuilEditor {
          height: calc(85vh - 214px);
          .quill {
            padding: 0;
            padding-bottom: 0px;
            border-radius: 5px;
            height: 100%;
            .ql-container {
              border: none;
              margin-bottom: 0;
              height: calc(100% - 40px);
            }
            .ql-editor {
              min-height: unset;
            }
            .ql-formats {
              margin-right: 5px !important;
              &:last-child {
                right: 0;
                position: absolute;
              }
              button {
                height: 20px;
                width: 20px;
                padding: 3px;
                color: #666;
              }
            }
            .ql-snow .ql-picker.ql-size {
              width: 78px !important;
            }
            .ql-snow .ql-picker.ql-font {
              width: 102px;
            }
            .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
              top: 60%;
            }
            .ql-align.ql-picker.ql-icon-picker {
              display: none !important;
            }
          }
        }
      }
      .previewContentWrap {
        .preview-templete {
          h6 {
            padding-bottom: 15px;
            font-size: 20px;
          }
          .preview-body {
            height: calc(85vh - 140px);
            overflow: auto;
            background: $c-f5;
            &.previewModeHtAdjust {
              height: calc(85vh - 140px) !important;
            }
          }
        }
      }
    }
    .footerAction {
      position: relative;
      @include flex-center;
      justify-content: flex-end;
      column-gap: 15px;
      padding: 14px 0;
      button {
        font-size: 15px;
        padding: 10px 0;
        border-radius: 5px;
        letter-spacing: 0.3px;
        font-weight: 500;
        margin: unset;
        &.createStageBtn {
          border: solid 1px $blue-violet;
          background: $blue-violet;
          min-width: 120px;
        }
        &.sendTestEmailBtn {
          border: solid 1px $blue-violet;
          color: $blue-violet;
          background: white;
          min-width: 150px;
          &:hover {
            box-shadow: 0 2px 2px 1px rgba(139, 101, 241, 0.15);
          }
          &.disableSentEmailBtn {
            background: $c-f5;
            border: 1px solid $c-87;
            color: $c-87;
            user-select: none;
            pointer-events: none;
          }
        }
        &.loading {
          @include wt-ht(34px !important, 34px !important);
          transition: all 0.3s ease-in-out;
          min-width: auto;
          border-radius: 35px;
          background: $blue-violet !important;
          .load-text {
            @include wt-ht(24px, 24px);
            top: 4px;
            left: 4px;
          }
        }
      }
    }
  }
}

.testEmailPopupOverlay {
  user-select: none;
  @include bg-mask(14, rgba(0, 0, 0, 0.57));
}

.initialSchedulePopupOverlay {
  transition: all 300ms ease;
  @include bg-mask(16, rgba(0, 0, 0, 0.53));
}

.overlayStagesPopup {
  @include bg-mask(100, rgba(0, 0, 0, 0.53));
}

.expandLabelInput {
  background: $white;
  color: $black;
  display: block;
  width: 100%;
  position: relative;
  padding: 25px 0 0;
  .inputLabel {
    color: $c-66;
    font-size: 14px;
    font-family: $font-regular;
    position: absolute;
    top: 30%;
    left: 0px;
    transition: all 0.2s linear;
    &.disabledInputLabel {
      top: 3px !important;
      user-select: none;
      pointer-events: none;
      cursor: unset;
    }
  }
  .inputBox {
    line-height: 34px;
    -webkit-appearance: none;
    border: 0;
    font-size: 15px;
    width: 100%;
    border-bottom: 1px solid $c-dd;
    color: $blue-violet;
    &.disabledInput {
      border-bottom: 1px solid transparent;
      user-select: none;
      pointer-events: none;
    }
    & ~ .focus-border {
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 100%;
      height: 1px;
      z-index: 99;
      &::after,
      ::before {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 0;
        height: 100%;
        background-color: $blue-violet;
        transition: 0.4s;
      }
    }
    &:focus ~ .focus-border {
      &::before,
      &::after {
        width: 100%;
        transition: 0.4s;
      }
    }
  }
  .inputBox:focus ~ label,
  &.filled label {
    top: 4px;
    transition: all 0.2s linear;
  }
  .errMsgText {
    bottom: -13px;
    left: 0px;
  }
}

%dropdownFullWidth {
  .drop-down-menu {
    width: 100% !important;
    min-width: unset !important;
    max-width: unset !important;
    .listItemWrap {
      width: 100% !important;
    }
  }
}
.replyThreadDropdown {
  &.isManualTask {
    pointer-events: none;
    opacity: 0.5;
  }
}

.replyThreadDropdown,
.filterDropdown {
  position: relative;
  width: 100%;
  label {
    @extend %label;
    cursor: pointer;
    position: relative;
    font-size: 14px;
    top: 4px;
  }
  .activeDropdownValue {
    position: relative;
    @include flex-center;
    border-bottom: 1px solid $c-dd;
    height: 40px;
    padding: 9px 0;
    margin-top: 2px;
    cursor: pointer;
    .activeValue {
      color: $c-1a;
      font-size: 15px;
      font-weight: 500;
      padding-top: 2px;
    }
    i {
      position: absolute;
      right: 0px;
      font-size: 20px;
      color: $c-1a;
    }
  }
  @extend %dropdownFullWidth;
}

.taskTypeDropdown {
  position: relative;
  width: 310px;
  margin-bottom: 1rem;
  label {
    @extend %label;
    cursor: pointer;
  }
  .activeDropdownValue {
    position: relative;
    @include flex-center;
    border-bottom: 1px solid $c-dd;
    height: 40px;
    padding: 9px 0px;
    margin-top: 2px;
    border-radius: 0;
    cursor: pointer;
    .activeValue {
      color: $c-1a;
      font-size: 15px;
      font-weight: 500;
      padding-top: 2px;
    }
    i {
      position: absolute;
      right: 0px;
      font-size: 20px;
      color: $c-1a;
    }
  }
  @extend %dropdownFullWidth;
}

.wrapInputTaskBox {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  justify-content: space-between;
  label {
    font-size: 16px;
    font-weight: 500;
    color: $c-52;
    margin-bottom: 4px;
    position: relative;
  }
  .inputBoxAndAutoCompleteDropDown {
    position: relative;
    .inputBoxSection {
      @extend %relBlock;
      margin-bottom: 16px;
      border-bottom: 1px solid $c-dd;
      .inputBox {
        height: 32px;
        input {
          border: none;
          height: 32px;
          width: 100%;
        }
      }
    }
    &.disableEdit .inputBoxSection {
      border: none;
      input {
        pointer-events: none;
        user-select: none;
        cursor: none;
        color: $blue-violet;
      }
    }
    .drop-down-menu {
      left: 0;
      top: 54px;
      .dropdownList {
        .listItem .listItemWrap {
          @include ellipsis();
        }
        .option {
          padding: 10px 10px;
          .itemName {
            .addTxt {
              color: $blue-violet;
            }
          }
        }
      }
    }
  }
  .taskDatePicker {
    @extend %taskDatePicker;
  }
}

.contactsCountText {
  color: $blue-violet;
  font-weight: 500;
  margin-top: 10px;
}

#manualEmailBackBtn {
  min-width: 80px;
  border: 1px solid transparent;
  color: $c-44;
  background: white;
  cursor: pointer;
  height: 36px;
  transition: all ease 0.3s;
  &:hover {
    color: $c-22;
    background: $c-f5;
  }
}
.testEmailCard {
  position: relative;
  display: block;
  z-index: 101;
  border-radius: 10px;
  padding: 20px;
  background: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.53);
  @include wt-ht(60%, auto);
  max-width: 600px;
  .testEmailHeader {
    @include flex-center;
    margin-bottom: 16px;
  }
  .fromAddressWrapper {
    margin-bottom: 10px;
  }
  .fromFieldHeader,
  .toFieldHeading {
    font-size: 14px;
    margin-bottom: 10px;
    color: $c-3b;
  }
  .activeDropdownValue {
    height: 30px !important;
    .activeValue {
      color: $blue-violet !important;
    }
    i {
      color: $c-8d;
    }
  }
  .closePopup {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    font-size: 20px;
    color: $c-66;
    border-radius: 35px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background: white;
    &:hover {
      background: $c-f5;
      color: black;
    }
  }
  .previewContentWrap {
    margin: 16px 0;
    .preview-body {
      background-color: #f9f9ff;
      border: 1px solid $c-dd;
      max-height: 300px;
    }
  }
  .footerAction {
    padding: 14px 0;
    @include flex-center;
    justify-content: flex-end;
    column-gap: 15px;
    button {
      font-size: 14px;
      padding: 10px 0;
      border-radius: 8px;
      letter-spacing: 0.3px;
      min-width: 120px;
      margin: unset;
      &.loading {
        @include wt-ht(34px !important, 34px !important);
        transition: all 0.3s ease-in-out;
        min-width: auto;
        border-radius: 35px;
        .load-text {
          @include wt-ht(24px, 24px);
          top: 4px;
          left: 4px;
        }
      }
    }
    .manualEmailBackBtn {
      min-width: 80px;
      border: 1px solid $blue-violet !important;
      color: $blue-violet !important;
      background: white;
      cursor: pointer;
      height: 40px;
      transition: all ease 0.3s;
      &:hover {
        color: $c-22;
        background: $c-f5;
      }
    }
    .sendTestEmailBtn {
      background-color: $blue-violet;
      color: $white;
      min-width: 150px;
      height: 36px;
      &:hover {
        box-shadow: 0 2px 2px 1px rgba(139, 101, 241, 0.15);
      }
      &.disableSentEmailBtn {
        background: $c-f5;
        border: 1px solid $c-87;
        color: $c-87;
        user-select: none;
        pointer-events: none;
      }
    }
  }
  .inputWrapper {
    @extend %inputWrapper;
    &.toField input {
      height: 30px !important;
      border-bottom: 1px solid $c-dd !important;
    }
  }
}

.initialSchedulePopupWrapper .card {
  position: relative;
  display: block;
  z-index: 101;
  border-radius: 10px;
  padding: 20px;
  background: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.53);
  @include wt-ht(60%, auto);
  max-width: 600px;
  .seqNameWrapper {
    color: $c-3b !important;
    .seqName {
      margin-bottom: 4px;
    }
    .desc {
      font-size: 11px;
    }
  }
  .initialSchedulePopupInbox {
    padding-bottom: 16px !important;
    margin-bottom: 0 !important;
    h6 {
      color: $c-3b !important;
      font-weight: 400;
    }
    .addInboxBtn {
      border: 1px solid $c-dd;
      height: 28px;
      border-radius: 6px;
      font-size: 12px;
      i {
        font-size: 14px;
      }
    }
    .createNewScheduleBtn {
      @include flex-center();
      font-weight: bold;
      cursor: pointer;
      height: 36px;
      padding: 0 10px;
      font-size: 13px;
      &:hover {
        color: $blue-violet;
      }
      i {
        font-size: 16px;
        margin-right: 4px;
      }
    }
    .selectedInboxWrapper {
      display: grid;
      grid-template-columns: 84% auto;
      grid-gap: 4px;
      max-height: auto !important;
      margin-top: 10px;
      transition: all ease 0.3s;
      color: $c-8d;
      &.showMore {
        border-top: 1px solid $c-dd;
        border-bottom: 1px solid $c-dd;
        padding: 8px 12px;
      }
      &.showLess {
        border: 1px solid $c-dd;
        border-radius: 3px;
        padding: 8px 12px;
      }
      .selectedInboxEmailList {
        max-height: 55px !important;
        row-gap: 6px;
        column-gap: 6px;
        margin: 0;
        .removeIcon {
          display: block;
          background: white;
          display: block;
          border: 1px solid $c-eb;
        }
        li {
          border: 1px solid #959cf2;
          border-radius: 12px;
          height: 24px;
        }

        .email {
          font-size: 11px;
        }
      }
    }
    .addInboxButtonWrapper {
      color: $c-78;
    }
    .viewMoreBtn {
      border: 1px solid #959cf2;
      border-radius: 12px;
      height: 24px;
      color: $blue-violet;
      font-size: 11px;
      @include flex-center;
      justify-content: center;
      text-align: center;
      cursor: pointer;
      .arrowDownIcon {
        font-size: 12px;
      }
    }
  }
  .fromFieldHeader {
    font-size: 14px;
    color: $c-3b;
    margin-bottom: 20px;
  }
  .fromAddressWrapper {
    margin-bottom: 12px;
  }
  .schedulesWrapper {
    .scheduleBody {
      padding-bottom: 16px;
      .hereText {
        text-decoration: none;
        color: $blue-violet;
        cursor: pointer;
        border: 0;
        font-size: 14px;
        background-color: transparent;
        padding: 0;
      }
    }
    .sendingWindow {
      border-bottom: 1px solid $c-dd;
      margin-bottom: 12px;
    }
    .timeZone {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 12px;
      color: $c-3b;
    }
    .headerTitle {
      font-size: 14px;
      color: $c-3b;
      margin-bottom: 8px;
    }
    .scheduleBody p {
      font-size: 14px;
      color: $c-8d;
    }
    .autoCompleteDropdown .dropdownHeader .title {
      font-size: 14px;
    }
  }
  .flexCenter {
    width: 100%;
  }
  .scheduleWindowPanel {
    width: 100%;
    .daysWithTimeWrapper {
      @include flex-center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 12px;
      color: $c-8d;
      font-size: 14px;
    }
  }
  .cardHeader {
    @include flex-center;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px solid $c-dd;
    padding-bottom: 6px;
  }
  .actionBtn {
    width: 100%;
    @include flex-center;
    justify-content: flex-end;
    .activateSequenceBtn {
      border: solid 1px $blue-violet;
      background: $blue-violet;
      color: $white;
      @include wt-ht(150px, 32px);
      padding: 0 12px;
      margin: 0;
      &.loading {
        @include wt-ht(32px!important, 32px!important);
        .load-text {
          top: 4px;
          left: 4px;
          @include wt-ht(22px!important, 22px!important);
        }
      }
    }
  }

  .activeDropdownValue {
    height: 30px !important;
    .activeValue {
      color: $blue-violet !important;
      font-size: 14px;
    }
    i {
      color: $c-8d;
    }
  }
  .closePopup {
    position: absolute;
    top: 12px;
    right: 10px;
    padding: 5px;
    font-size: 20px;
    color: $c-66;
    border-radius: 35px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background: white;
    &:hover {
      background: $c-f5;
      color: black;
    }
  }
}
