.csvImportsSection {
  @extend %relBlock;
  @include wt-ht(100%, 100%);
  border-radius: 10px;
  .inboxPaginationAndLimit {
    position: relative;
    @include flex-center;
    justify-content: flex-end;
    padding: 10px 30px;
    @include wt-ht(100%, 60px);

    .pagination-section {
      border-top: 0;
    }

    .pageLimit {
      .limitDropdown {
        top: auto;
        bottom: 40px;
      }
    }
  }
  .titleDescSection {
    @extend %relBlock;
    padding: 20px 15px 0px;
    h3 {
      font-size: 16px;
      color: $c-66;
      font-family: $font-regular;
      font-weight: 400;
      padding-bottom: 15px;
    }
  }
  .tableList {
    @extend %relBlock;
    height: calc(100% - 120px);
    width: 100%;
    border-top: 1px solid $b-c-1;
    background-color: #f9f9f9;
    .tableRecordHeightAlign {
      height: calc(100% - 56px);
      width: 100%;
      position: absolute;
      overflow: auto;
      background-color: $white;
    }
    .tableHeader {
      margin: 10px 6px;
    }
    .tableHeader,
    .tableBody,
    .tableBodyLoader {
      @include flex-center;
      position: relative;
      font-size: 14px;
      height: 48px;
      padding: 0 5px;
      color: $c-66;
      border-bottom: 1px solid $b-c-1;
      .titleRow {
        @extend %relBlock;
        @include ellipsis();
        padding: 0 10px;
      }
    }
    .tableBodyLinkedIn,
    .tableHeaderLinkedIn,
    .tableBodyLoaderLinkedIn {
      .createdDate {
        width: 12%;
      }
      .searchUrl {
        width: 25%;
      }
      .status {
        @include flex-center;
      }
      .actions {
        width: 7%;
        cursor: pointer;
        text-align: center;
        position: relative;
        margin: 0 auto;
        .pauseStop {
          @include flex-align-center;
          span:first-child {
            margin-right: 8px;
          }
          .stopButton {
            svg path {
              color: red;
            }
            .customTooltip {
              .sharpPointer {
                right: -13px;
              }
              .tooltipBody {
                left: 37px;
                right: unset;
              }
            }
          }
        }
        .customTooltip {
          background: #393a3d;
          .sharpPointer {
            border-right: 1px solid $c-33;
            border-top: 1px solid $c-33;
            background: #393a3d;
            left: unset;
            right: 44px;
            top: -20px;
            border-radius: unset;
            padding: 5px;
          }
          .tooltipBody {
            width: fit-content;
            right: -10px;
            white-space: nowrap;
            background: #393a3d;
            top: -33px;
            right: 48px;
            left: unset;
            .tooltipText {
              color: $white;
              padding: 8px;
            }
          }
        }
      }
      .status {
        width: 15%;
        text-align: center;
        justify-content: center;
        .statusDot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 6px;
        }
      }
      .totalContacts,
      .contactsExtracted,
      .contactsPerDay {
        width: 15%;
        text-align: center;
      }
    }
    .tableBodyLinkedIn {
      .searchUrl {
        color: $blue-violet;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .tableHeader {
      height: 48px;
      position: sticky;
      background: $white;
      top: 0;
      z-index: 1;
      width: calc(100% - 16px);
    }
    .colLoader {
      @extend %relBlock;
      width: 70%;
      margin: 0 10px;
    }
    .fileName {
      width: 19%;
    }
    .sequenceName {
      width: 26%;
    }
    .tableBody .sequenceName {
      @include flex-center;
      .name {
        font-size: 14px;
        max-width: 70%;
        @include ellipsis();
        cursor: pointer;
        color: $blue-violet;
        &:hover {
          text-decoration: underline;
        }
      }
      .trigger {
        @include flex-center;
        margin-left: 5px;
        .owner {
          background: $c-e3;
          color: $c-75;
          padding: 5px;
          line-height: 9px;
          font-size: 9px;
          text-transform: uppercase;
          letter-spacing: 0.4px;
          cursor: pointer;
        }
      }
      .shared {
        letter-spacing: 0.4px;
        background: #ffdfd1;
        padding: 5px;
        line-height: 9px;
        font-size: 9px;
        color: #e46833;
        border-radius: 3px;
        margin-left: 5px;
        text-transform: uppercase;
      }
    }
    .status {
      width: 11%;
    }
    .totalCount {
      width: 8%;
      text-align: center;
    }
    .successCount {
      width: 8%;
      text-align: center;
    }
    .failedCount {
      width: 10%;
      text-align: center;
      position: relative;
      @include flex-center;
    }
    .createdDate {
      width: 10%;
    }
    .ownerName,
    .failedCount,
    .successCount,
    .totalCount {
      .colLoader {
        margin: 0 auto;
      }
    }
    .ownerName {
      width: 8%;
      text-align: center;
      .contactOwner {
        @extend %contactOwner;
        margin: 0 auto;
        .customTooltip {
          .sharpPointer {
            left: unset;
            right: 22px;
            top: -7px;
            border-radius: unset;
            padding: 5px;
          }
          .tooltipBody {
            top: -18px;
            right: 27px;
            left: unset;
          }
        }
      }
      .colLoader {
        margin: 0 auto;
        @include wt-ht(30px, 30px);
        border-radius: 35px;
      }
    }
    .noDataFound {
      position: relative;
      @include flex-center;
      justify-content: center;
      text-align: center;
      padding: 40px 0 20px;
      .text {
        padding-left: 7px;
      }
    }
  }
  .pagination-section {
    position: absolute;
  }
}

.failedCountDetail {
  @extend %failedOperationStyle;
}

.importsNavigation {
  .tabNavigation {
    background-color: $white !important;
  }
  .tabItem {
    top: 0 !important;
  }
}
