#toolbarTracking {
  position: absolute;
  right: 20px;
  height: 40px;
  bottom: 0;
  display: flex;
  align-items: center;
  .toggleText.leftPad {
    position: relative;
    top: 1px;
  }
  .toogleSequence {
    .toogleButton {
      &.loading {
        .knobs::before {
          animation: spin 1s linear infinite;
          border: 1.5px solid #3953fb;
          border-bottom-color: #3953fb;
          border-left-color: #3953fb;
          border-left: 1px;
          background-color: transparent;
        }

        .toggleCheckbox:checked + .knobs:before {
          background-color: transparent;
        }
      }
      @include wt-ht(28px, 14px);
      .knobs::before {
        @include wt-ht(10px, 10px);
        top: 2px;
        padding: unset;
      }
      .toggleCheckbox:checked {
        & ~ .layer {
          background: #fff;
          border: 1px solid #3953fb;
        }
        & + .knobs:before {
          left: 15px;
          background: #3953fb;
        }
      }
      .layer {
        background: #fff;
        border: 1px solid $c-d3;
      }
      .knobs:before {
        background: $c-a7;
      }
    }
  }
  .trackIcon {
    display: flex;
    .toggleText {
      cursor: pointer;
    }
  }
  .trackingOptionCard {
    position: absolute;
    padding: 10px;
    right: 16px;
    bottom: 24px;
    .pointerTriangle {
      position: absolute;
      border: unset;
      background: #fff;
      border-top-right-radius: 18px;
      top: unset;
      bottom: 5px;
      left: unset;
      right: 30px;
      padding: 6px;
      z-index: 2;
      transform: rotate(-45deg);
      box-shadow: -2px 0px 4px 0px rgba(0, 0, 0, 0.16);
    }
    .trackingOption {
      position: relative;
      border-radius: 10px;
      padding: 8px;
      width: 210px;
      border: 1px solid $c-dd;
      z-index: 1;
      background: $white;
      box-shadow: 0 0 12.1px 0.9px rgb(139 139 139 / 26%);
      .descText {
        font-size: 13px;
        color: $c-9e;
        margin-bottom: 8px;
      }
      .toggleBtnSection:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
  .overlayTracking {
    @include bg-mask(0, transparent);
  }
}
