.outboxFilterByOption {
  @extend %filterOprionCard;
  height: calc(100% - 34px);
  overflow-y: auto;
  .contactDateFilter {
    position: relative;
    border-bottom: 1px solid $c-ec;
    .dropdownSearchBox {
      @extend %relBlock;
      width: calc(100% - 8px);
      margin: 0 0 10px 8px;
      .horizontalBorderLine {
        position: absolute;
        width: 4px;
        height: 100%;
        background: #dee3ff;
        border-radius: 5px;
        left: -8px;
        top: 0px;
        z-index: 1;
      }
    }
  }
}
