.commonPopupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  @include flex-center();
  justify-content: center;
  letter-spacing: 0.3px;
  width: 100%;
  z-index: 100;
  overflow: auto;
  .commonPopupContainer {
    position: relative;
    display: block;
    z-index: 101;
    border-radius: 10px;
    padding: 20px;
    background: white;
    width: 35%;
    .title {
      position: relative;
      top: 13px;
      color: $c-8d;
      font-size: 13px;
      padding-bottom: 10px;
    }
  }
  .commonPopupHeader {
    @include flex-center();
    position: relative;
    margin-bottom: 20px;
    .commonPopupHeading {
      font-size: 20px;
      color: $c-66;
    }
    .commonPopupCloseIcon {
      font-size: 20px;
      position: absolute;
      right: 0px;
      padding: 3px;
      border-radius: 50%;
      color: $c-53;
      cursor: pointer;
      &:hover {
        background: $c-dd;
        color: $black;
      }
    }
  }
}
.commonPopupOverlay {
  @include bg-mask(12, rgba(0, 0, 0, 0.57));
}
