.collapsible {
  background: white;
  color: $c-66;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  &:after {
    content: "\002B";
    color: $c-66;
    font-weight: bold;
    float: right;
    margin-left: 5px;
  }
  &:hover {
    background: $c-fb;
  }
  &.active:hover {
    background: unset;
  }
  &.active:after {
    content: "\2715";
    color: $c-66;
    font-weight: bold;
    font-size: 12px;
  }
}
.collapsiveContentBody {
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  border-bottom: 1px solid $c-d8;
  p {
    position: relative;
    display: inline-block;
    line-height: 1.5;
    a {
      color: $blue-violet;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
