%contactSliderCard {
  .contactCard,
  .contactCard .contactCardHeader {
    display: block;
    background: $white;
    transition: 0.3s;
    padding: 5px;
    position: inherit;
    width: 360px;
    .notFoundText {
      height: 100%;
      @include flex-center();
      opacity: 0.5;
      font-size: 12px;
      color: $c-78;
      opacity: 0.3;
    }
    .maskData {
      @include wt-ht(100%, 100%);
      @include flex-align-path-justify(flex-start, column, center);

      .maskText {
        font-size: 12px;
        color: $c-66;
        opacity: 0.6;
        cursor: pointer;
        border-bottom: 2px dotted $blue-violet;
        padding-bottom: 2px;
        width: fit-content;
      }
    }
    .closeContactSlider {
      position: absolute;
      right: 360px;
      top: -1px;
      font-size: 20px;
      z-index: 101;
      width: fit-content;
      margin: 0 0 0 auto;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 3px;
      background: white;
      cursor: pointer;
      transition: all 0.3s ease;
      border-left: 1px solid $blue-violet;
      border-top: 1px solid $blue-violet;
      border-bottom: 1px solid $blue-violet;
      &:hover {
        background: $c-f5;
      }
    }
    .contactInfoCard {
      @extend %relBlock;
      @include wt-ht(100%, calc(100vh - 106px));
      // overflow-y: auto;
      padding: 5px;
      svg {
        fill: $c-a0;
      }
      .contactDetails {
        @extend %relBlock;
        width: 100%;
        border-radius: 10px;
        padding: 10px 20px 16px;
        margin-bottom: 16px;
        border: solid 1px $c-dd;
        background-color: $white;
        .contactIcon {
          position: relative;
          @include flex-center;
          justify-content: center;
          color: $c-8d;
          font-size: 20px;
          font-weight: 600;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
          border: solid 2px white;
          background-color: #eff3f4;
          border-radius: 35px;
          margin: 0 auto;
          @include wt-ht(58px, 56px);
          padding-top: 4px;
          margin-bottom: 12px;
          text-transform: uppercase;
        }
        .justifyCenter {
          justify-content: space-between;
        }
        .contactName {
          @extend %relBlock;
          font-size: 18px;
          font-weight: 600;
          color: $c-3b;
          margin-bottom: 2px;
          @include ellipsis();
        }
        .contactJobCompany {
          @extend %relBlock;
          width: 100%;
          margin: 0 auto;
          font-size: 12px;
          text-align: left;
          color: $c-3b;
          padding-bottom: 10px;
          margin-bottom: 20px;
          border-bottom: 1px solid $c-dd;
          @include ellipsis();
        }
        .contactContactedInfo {
          position: relative;
          margin-bottom: 16px;
          .contactDate {
            padding: 5px;
            background-color: rgba(255, 255, 255, 0.63);
            @include flex-center();
            justify-content: space-between;
            p {
              font-size: 12px;
              text-align: center;
              color: $c-3b;
            }
            h6 {
              font-size: 12px;
              font-weight: 500;
              text-align: center;
              color: $c-3b;
            }
          }
        }
        .actionIcon {
          position: relative;
          display: grid;
          width: 100%;
          margin: 0 auto;
          grid-template-columns: repeat(6, 1fr);
          grid-gap: 6px;
          justify-content: center;
          justify-items: center;
          .actionBtnIcon {
            cursor: pointer;
            position: relative;
            @include flex-center;
            justify-content: center;
            border-radius: 5px;
            @include wt-ht(44px, 40px);
            background: white;
            transition: all 0.3s ease;
            border: solid 1px $c-dd;
            &:not(.moreActions) {
              .drop-down-menu {
                width: 190px !important;
                right: 0px;
                top: 16px;
                padding-top: 30px;
                border-radius: 5px;
                box-shadow: none;
                border: none !important;
                border-top-left-radius: 250px;
                background: transparent !important;
                .sharpPointer {
                  position: absolute;
                  border-top-right-radius: 0px;
                  padding: 6px;
                  border-right: 1px solid #dddddd;
                  border-top: 1px solid #dddddd;
                  z-index: 4;
                  top: 26px;
                  right: 14px;
                  transform: rotate(-45deg);
                  background: white;
                  border-bottom-left-radius: 100%;
                }
                .dropdownList {
                  width: 190px !important;
                  margin: 2px 0;
                  background: $white;
                  border: 1px solid #dddddd;
                  border-radius: 6px;
                  .option {
                    position: relative;
                    border-bottom: 1px solid $c-cc;
                    padding: 10px !important;
                    &:last-child {
                      border-bottom: 0;
                    }
                    .listItem {
                      gap: 15px;
                      .callIconType {
                        @include flex-align-center;
                        svg {
                          @include wt-ht(20px, 20px);
                          fill: $c-3b !important;
                          path {
                            fill: $c-3b !important;
                          }
                        }
                      }
                      .listItemWrap {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        .numberType {
                          font-size: 12px;
                          color: $c-8d;
                        }
                        .itemName {
                          font-size: 15px;
                          color: $c-3b;
                          margin-top: 4px;
                        }
                      }
                    }
                  }
                }
              }
            }

            svg {
              margin-right: 0;
              @include wt-ht(16px, 16px);
            }
            &:hover {
              border-color: white;
              transition: all 0.3s ease-in-out;
            }
            .customTooltip {
              top: 18px;
              .sharpPointer {
                background: black;
                padding: 8px;
                border: black;
              }
              .tooltipBody {
                background: black;
                top: 5px;
                border-radius: 4px;
                .tooltipText {
                  color: white;
                  font-size: 14px;
                  padding: 8px;
                }
              }
            }
            &.addToSequence {
              svg {
                fill: #3a50ff !important;
                path {
                  fill: #3a50ff !important;
                }
              }
              .tooltipBody {
                width: 132px;
                right: -50px;
              }
              &:hover {
                background: #3a50ff;
                svg {
                  fill: white !important;
                  path {
                    fill: white !important;
                  }
                }
              }
            }
            &.addToTask {
              svg {
                fill: #ff9e10 !important;
                path {
                  fill: #ff9e10 !important;
                }
              }
              .tooltipBody {
                width: 106px;
                right: -40px;
              }
              &:hover {
                background: #ff9e10;
                svg {
                  fill: white !important;
                  path {
                    fill: white !important;
                  }
                }
              }
            }
            &.makeACall {
              svg {
                fill: #1eb9d5 !important;
                path {
                  fill: #1eb9d5 !important;
                }
              }
              .tooltipBody {
                width: 92px;
              }
              &:hover {
                border-color: #1eb9d5 !important;
                background: #1eb9d5 !important;
                svg {
                  fill: white !important;
                  path {
                    fill: white !important;
                  }
                }
              }
            }
            &.sendOneOffEmail {
              svg {
                @include wt-ht(19px, 19px);
                fill: #00a358 !important;
              }
              .tooltipBody {
                width: 110px;
                right: -45px;
              }
              &:hover {
                background: #00a358 !important;
                svg {
                  fill: white !important;
                }
              }
            }

            &.calendarBookMeeting {
              svg {
                @include wt-ht(19px, 19px);
                fill: #272056 !important;
              }
              .tooltipBody {
                width: 110px;
                right: -45px;
              }
              &:hover {
                background: #272056 !important;
                svg {
                  fill: white !important;
                }
              }
            }
            &.linear-background {
              border: solid 2px $c-ec;
            }
            &.moreActions {
              cursor: pointer;
              svg {
                path {
                  fill: $c-3b;
                }
                @include wt-ht(12px !important, 12px !important);
              }
              .drop-down-menu {
                top: 40px;
                ul li {
                  padding: 10px;
                  .icon-holder {
                    margin-right: 8px;
                    top: -1px;
                  }
                  .listItemWrap {
                    position: relative;
                    flex-direction: unset;
                    align-items: center;
                    flex-direction: row;
                    width: 160px;
                    .itemName {
                      margin-top: 0;
                    }
                  }
                }
              }
              &:hover {
                color: $c-33;
                background: #f4f4f6;
                border-color: $c-dd;
              }
            }
          }
        }
      }
      .contactActivityInfo {
        @extend %relBlock;
        @include wt-ht(100%, 97%);
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 10px;
        .contactHeading {
          position: sticky;
          top: 0;
          padding: 0;
          margin-bottom: 8px;
          display: flex;
          justify-content: flex-end;
          .title {
            position: relative;
            font-size: 14px;
            font-weight: 500;
            color: #969a9d;
          }
          .viewActivityWrapper {
            position: relative;
            font-size: 12px;
            font-weight: 500;
            color: #969a9d;
            text-align: left;
            cursor: pointer;
            padding-right: 10px;
            border-right: 1px solid $c-e8;
          }
          .editWraper {
            @include flex-center();
            padding-left: 10px;
            padding-right: 5px;
            justify-content: flex-end;
            cursor: pointer;
            color: #969a9d;
            .title {
              font-size: 12px !important;
            }
          }
          .editWraper .title,
          .viewActivityWrapper .viewActivity {
            &:hover {
              color: $blue-violet;
            }
          }
        }
        .wrapContactActivity {
          @extend %relBlock;
          @include wt-ht(100%, calc(100% - 174px));
          overflow-y: auto;
          overflow-x: hidden;
          border-radius: 10px;
          border: solid 1px $c-dd;
          background-color: #fff;
          padding: 0 12px 10px;
          .collapsible {
            font-weight: 500;
            padding: 14px 0;
            font-size: 14px;
            border-bottom: 1px solid $c-d8;
            &::after {
              font-family: "Material Icons";
              content: "\e5cf";
              font-weight: 100;
              font-size: 18px;
              width: 18px;
              transition: all 0.3s ease;
            }
            &.active::after {
              content: "\e5ce";
              transition: all 0.3s ease;
            }
          }

          .collapsiveContentBody {
            border-bottom: none !important;
          }
          .collapsible.active + .collapsiveContentBody {
            border-bottom: 1px solid $c-d8 !important;
          }
          .gridCol {
            position: relative;
            display: grid;
            grid-template-columns: 112px calc(100% - 112px);
            padding: 12px 0;
            line-height: normal;
            &.alignStart {
              align-items: start !important;
              padding-top: 15px;
            }
            .icon {
              @include flex-center;
              align-items: center;
              margin-right: 8px;
              text-transform: capitalize;
              &.iconStart {
                align-items: flex-start;
              }
              svg {
                margin-right: 8px;
                @include wt-ht(11px, 11px);
              }
              font-size: 12px;
              color: $c-8d;
              word-break: break-word;
            }
            .showMore {
              position: relative;
              @include flex-center;
              padding: 0 8px;
              border-radius: 10px;
              background-color: rgba(0, 0, 0, 0.08) !important;
              font-size: 11px;
              font-weight: 600;
              color: $c-3b;
              height: 18px;
              width: fit-content;
              transition: all 0.3 ease;
              cursor: pointer;
              .flexCenterPointer {
                @include flex-center;
                cursor: pointer;
              }
              .showLessBtn {
                @include flex-center;
                cursor: pointer;
                .text {
                  position: relative;
                  top: 1px;
                }
                svg {
                  transform: rotate(180deg);
                }
              }
              .count {
                position: relative;
                top: 2px;
              }
              svg {
                fill: $c-33;
                @include wt-ht(9px, 9px);
                margin-left: 10px;
                path {
                  fill: $c-33;
                  @include wt-ht(9px, 9px);
                }
              }
            }
            a.linkValue {
              color: $blue-violet;
              cursor: pointer;
              text-decoration: none;
              transition: all 0.3s ease;
              &:hover {
                text-decoration: underline;
              }
            }
            .valueColumn {
              @extend %relBlock;
              font-size: 13px;
              font-weight: 500;
              text-align: left;
              color: $c-3b;
              word-break: break-all;
              position: relative;
              word-break: break-word;
            }
            .updateContactStage {
              font-size: 13px;
              position: relative;
            }
            .contactStatus {
              text-transform: capitalize;
              line-height: initial;
              border-radius: 3px;
              background: #f4f4f6;
              padding: 4px;
              color: $c-3b;
              width: fit-content;
            }
            .contactEmail {
              width: 100%;
            }
            .contactEmail,
            .contactPhone,
            .contactOwner,
            .contactStatus,
            .contactTask {
              @extend %relBlock;
              font-size: 13px;
              font-weight: 500;
              text-align: left;
              color: $c-3b;
              word-break: break-all;
              .smallText {
                font-size: 10px;
                color: $c-78;
                margin-right: 15px;
              }
            }

            .insightData {
              @include flex-center;
              .email-insight-stage {
                li {
                  .icon-tag,
                  i {
                    @include wt-ht(24px, 24px);
                  }
                  :not(:last-child) {
                    width: 24px;
                  }
                  .sharpPointer {
                    right: -24px;
                    top: -38px;
                    width: unset !important;
                  }
                  .tooltipBody {
                    right: -12px;
                    top: -58px;
                  }
                }
              }
            }

            .contactTagList,
            .contactPhone {
              position: relative;
              .chip {
                position: relative;
                font-size: 13px;
                color: #3a50ff;
                display: inline;
                word-break: break-word;
                cursor: pointer;
                &:not(:last-child)::after {
                  content: ",";
                  padding-right: 3px;
                  color: $c-87;
                }
              }
              .showBtn {
                color: $blue-violet;
                font-size: 12px;
                margin-left: 16px;
                cursor: pointer;
              }
            }
            .contactSequenceList {
              position: relative;
              line-height: normal;
              @include flex-center;
              flex-wrap: wrap;
              gap: 6px;
              .chip {
                position: relative;
                @include flex-center;
                border: 1px solid $c-dd;
                color: #343a40;
                background-color: #f4f4f6;
                justify-content: space-between;
                column-gap: 6px;
                border-radius: 6px;
                line-height: initial;
                padding: 4px 6px;
                width: 100%;
                max-width: fit-content;
                cursor: pointer;
                .phoneText {
                  padding-right: 4px;
                }
                .seqName {
                  position: relative;
                  font-size: 11px;
                  line-height: initial;
                  @include ellipsis;
                  transition: all 0.3s ease-in-out;
                  &:hover {
                    color: $blue-violet;
                    text-decoration: underline;
                  }
                }
                .expanBtnText {
                  position: relative;
                  font-size: 11px;
                  color: $blue-violet;
                  line-height: initial;
                }
                .arrow {
                  position: relative;
                  border-radius: 35px;
                  transition: all 0.3s ease;
                  @include flex-center();
                  justify-content: inherit;
                  svg {
                    @include wt-ht(9px, 9px);
                    fill: $c-53;
                  }

                  &.activeArrow {
                    transform: rotate(180deg);
                  }
                  &:hover {
                    background: $c-f5;
                    svg {
                      fill: $c-33;
                    }
                  }
                }
                .drop-down-menu {
                  left: 0;
                  top: 20px;
                  right: unset;
                  ul li .listItem .listItemWrap {
                    width: 164px;
                  }
                }
              }
            }
            .contactTagList,
            .contactPhone {
              .chip {
                position: relative;
                color: black;
                cursor: unset;
                @include flex-center;
                &:hover {
                  background: white;
                }
              }
            }
            .contactPhone .singleRecord .chip {
              padding: 0;
              border-radius: 0;
              border: unset;
            }
          }
        }
      }
    }
    .dialerServiceCardSection {
      right: 360px;
      transition: all 0.3 ease;
    }
  }
}
.accountWrapper {
  display: grid;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
  grid-template-columns: calc(100% - 68px) 62px;
  .accountRedirect {
    position: relative;
    text-align: left;
    cursor: pointer;
    color: $blue-violet;
    display: grid;
    align-items: center;
    grid-template-columns: 14px auto;
    &:hover .accountText {
      text-decoration: underline;
    }
    svg {
      @include wt-ht(14px, 14px);
    }
    .accountText {
      position: relative;
      top: 1px;
      @include ellipsis;
      padding-left: 2px;
    }
  }
}
.contactSocialIconLink {
  position: relative;
  @include flex-center();
  justify-content: center;
  margin-bottom: 8px;
  .websiteIcon {
    font-size: 18px;
    color: $c-8d;
    position: relative;
    @include flex-center;
    justify-content: center;
    transform: rotate(-45deg);
    color: #0a66c2;
    @include wt-ht(21px !important, 21px !important);
    cursor: pointer;
    &.disabled {
      @include disable(0.5, unset);
      color: #a0a0a0;
    }
    &:hover:not(.disabled) {
      transition: all 0.3s ease;
      background: $c-ec;
      border-radius: 35px;
    }
  }
  .linkedinIcon {
    cursor: pointer;
    position: relative;
    @include flex-center;
    justify-content: center;
    border-radius: 35px;
    transition: all 0.3s ease;
    @include wt-ht(21px !important, 21px !important);
    margin: 0 6px;
    padding: 2px;
    svg {
      margin: 0;
      fill: #0a66c2 !important;
      path {
        fill: #0a66c2 !important;
      }
      @include wt-ht(13px !important, 13px !important);
    }
    &.disabled {
      @include disable(0.5, unset);
      svg {
        fill: $c-a0 !important;
        path {
          fill: $c-a0 !important;
        }
      }
    }
    &:hover:not(.disabled) {
      transition: all 0.3s ease;
      background: $c-ec;
    }
  }
}

.contactCardLoader {
  .contactInfoCard {
    .contactDetails {
      margin-bottom: 6px !important;
      .contactName {
        width: 50% !important;
        margin-bottom: 6px !important;
      }
      .contactJobCompany {
        width: 100% !important;
        margin-bottom: 10px !important;
        margin: unset !important;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
      .contactSocialIconLink {
        margin-bottom: 10px !important;
        .linkedinIcon {
          margin-right: 5px;
          @include wt-ht(13px, 13px);
        }
        .websiteIcon {
          margin-left: 5px;
          @include wt-ht(13px, 13px);
        }
      }
      .contactContactedInfo {
        margin-top: 20px;
        .contactDate {
          p {
            margin: 0 auto;
            margin-right: 8px;
            @include wt-ht(65%, 10px);
          }
          h6 {
            margin: 0 auto;
            @include wt-ht(55%, 10px);
          }
        }
      }
    }
    .contactActivityInfo {
      .contactHeading {
        width: 100%;
        margin: 6px 0;
        justify-content: flex-end;
        span {
          display: block;
          @include wt-ht(60px, 10px);
        }
      }
      .wrapContactActivity {
        margin-top: 10px;
        .collapsibleLoader {
          position: relative;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title {
            display: block;
            @include wt-ht(100px, 12px);
          }
          .icon {
            display: block;
            @include wt-ht(12px, 12px);
            border-radius: 35px;
          }
        }
      }
      .gridCol {
        height: 37px;
        padding: 0 !important;
        border-bottom: 1px;
        .icon {
          .svgIcon {
            @include wt-ht(11px, 11px);
            margin-right: 8px;
          }
          .name {
            @include wt-ht(50px, 10px);
          }
        }
      }
    }
  }
}

#contactCard {
  position: fixed;
  @include wt-ht(360px, calc(100% - 60px));
  right: 0;
  bottom: 0;
  z-index: 9;
  overflow: hidden;
  background: white;
  border-top: 1px solid $blue-violet;
  border-left: 1px solid $blue-violet;
  @extend %contactSliderCard;
  .popup-content-wrap {
    .highlightText {
      color: $blue-violet;
    }
  }
}

@keyframes cardSlideInRight {
  0% {
    right: 0px;
    opacity: 0;
  }
  25% {
    right: 55px;
    opacity: 0;
  }
  50% {
    right: 110px;
    opacity: 0.5;
  }
  75% {
    right: 220px;
    opacity: 0.7;
  }
  100% {
    right: 340px;
    opacity: 1;
  }
}

@keyframes cardSlideInBtmRight {
  0% {
    right: -20px;
    opacity: 0;
  }
  25% {
    right: -10px;
    opacity: 0;
  }
  50% {
    right: 0px;
    opacity: 0.5;
  }
  75% {
    right: 10px;
    opacity: 0.7;
  }
  100% {
    right: 20px;
    opacity: 1;
  }
}

@keyframes cardSlideInFromBottom {
  0% {
    right: -100%;
    opacity: 0;
  }
  25% {
    right: -75%;
    opacity: 0.25;
  }
  50% {
    right: -50%;
    opacity: 0.5;
  }
  75% {
    right: -25%;
    opacity: 0.75;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes cardSlideOutToBottom {
  0% {
    right: 0;
    opacity: 1;
  }
  25% {
    right: -25%;
    opacity: 0.75;
  }
  50% {
    right: -50%;
    opacity: 0.5;
  }
  75% {
    right: -75%;
    opacity: 0.25;
  }
  100% {
    right: -100%;
    opacity: 0;
  }
}

.slideInFromBottom {
  animation: cardSlideInFromBottom 0.3s linear;
}

.slideOutToBottom {
  animation: cardSlideOutToBottom 0.3s linear;
}

.installBannerHidden {
  .wrapContactActivity {
    @include wt-ht(360px, calc(100% - 174px) !important);
  }
  .contactCardLoader .wrapContactActivity {
    @include wt-ht(360px, calc(100% - 164px) !important);
  }
  .installPluginExtBannerShown#contactCard {
    @include wt-ht(360px, calc(100% - 60px));
  }
  .ctTlSidePanelCard .contactCardLoader .wrapContactActivity {
    @include wt-ht(360px, calc(100% - 206px) !important);
  }
  .performTaskPanel .contactCardLoader .wrapContactActivity {
    @include wt-ht(360px, calc(100% - 150px) !important);
  }
  .ctTlSidePanelCard .wrapContactActivity {
    @include wt-ht(360px, calc(100% - 216px) !important);
  }
  .performTaskPanel.contactCardLoader .wrapContactActivity {
    @include wt-ht(360px, calc(100% - 138px) !important);
  }
}

.singleBanner {
  .contactCardLoader .wrapContactActivity {
    @include wt-ht(360px, calc(100% - 164px) !important);
  }
  .ctTlSidePanelCard .contactCard {
    &:not(.contactCardLoader) {
      .wrapContactActivity {
        @include wt-ht(360px, calc(100% - 254px) !important);
      }
    }
    &.contactCardLoader .wrapContactActivity {
      @include wt-ht(360px, calc(100% - 244px) !important);
    }
  }
}

.combinedBanners {
  .contactCardLoader .wrapContactActivity {
    @include wt-ht(360px, calc(100% - 166px) !important);
  }
  .ctTlSidePanelCard .contactCard {
    &:not(.contactCardLoader) {
      .wrapContactActivity {
        @include wt-ht(360px, calc(100% - 294px) !important);
      }
    }
    &.contactCardLoader .wrapContactActivity {
      @include wt-ht(360px, calc(100% - 284px) !important);
    }
  }
}

// Event registration Banner 42px
.showEventBanner .singleBanner,
.showEventBanner .combinedBanners {
  .contactCardLoader .wrapContactActivity {
    @include wt-ht(360px, calc(100% - 164px) !important);
  }
}

.emailCopyWrapper {
  position: relative;
  line-height: normal;
  display: grid;
  grid-template-columns: calc(100% - 18px) 16px;
  justify-content: space-between;
  align-items: center;
  a {
    @include ellipsis;
  }
}
