.overlayMaskInbox {
  @include bg-mask(110, rgba(0, 0, 0, 0.57));
}

.connectInboxFailureSection {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 111;
  .pop-card {
    position: relative;
    background: $white;
    display: grid;
    width: 700px;
    grid-template-columns: 400px 240px;
    padding: 60px 30px;
    border-radius: 10px;
    box-shadow: 0px 1px 7px 0px rgba(60, 59, 59, 0.25);
    i {
      position: absolute;
      top: 10px;
      right: 8px;
      padding: 5px;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.2s ease-in;
      color: $c-78;
      &:hover {
        background: $c-e8;
        color: $c-22;
        transition: all 0.2s ease-in;
      }
    }
    .contentSpaceAlign {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      position: relative;
      will-change: auto;
      h6 {
        color: $c-3b;
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: 0.3px;
      }
      p {
        margin-top: 10px;
        color: $c-3b;
        font-size: 16px;
      }
      .btnSection {
        margin-top: 20px;
        position: relative;
        display: inline-flex;
        button {
          border-radius: 4px;
          padding: 10px 15px;
          font-size: 14px;
          letter-spacing: 0.3px;
          outline: none;
          font-weight: 500;
          &:hover {
            box-shadow: 0px 0px 3px 0 rgba(0, 0, 0, 0.18);
          }
        }
        .proceedBtn {
          background: $blue-violet;
          border: 1px solid $blue-violet;
          will-change: border;
          color: $white;
        }
        .no-thanks {
          background: $white;
          border: 1px solid $c-dd;
          will-change: border;
          color: $c-66;
          margin-right: 12px;
        }
      }
    }
    .imgLapping {
      @extend %relBlockWidth;
      .failureImg {
        max-width: 100%;
        height: 185px;
        will-change: height;
      }
      .bgDotImg {
        position: absolute;
        bottom: -60px;
        right: -30px;
        max-width: 100%;
        z-index: -1;
        border-radius: 10px;
      }
    }
  }
}
