.accountsPage {
  height: 100%;
  width: 100%;
  .accountsBody {
    @include wt-ht(100%, calc(100% - 60px));
    background: $c-f5f8;
    padding: 10px;
    .accountsList {
      height: 100%;
      width: 100%;
      .filterNavBar {
        &.seq-contact-nav-section {
          .seq-contact-list {
            grid-template-columns: repeat(8, calc(12.5% - 10px));
          }
        }
      }
      .headerContents {
        @include flex-center;
        justify-content: space-between;
        height: 40px;
        margin-bottom: 12px;
        .leftHeaderContents {
          @include flex-center;
          @include searchInputByKeyword();
          .accountCount {
            margin-left: 10px;
          }
        }
        .rightHeaderContents {
          @include flex-center;
          gap: 10px;
          .pageLimit {
            margin-right: unset !important;
          }
          .toggleFilterBtn,
          .moreOptionBtn {
            margin-left: unset !important;
          }
          .moreOptionBtn {
            position: relative;
            user-select: none;
            .drop-down-menu {
              min-width: 130px;
              .listItemWrap {
                width: 120px;
              }
            }
            i {
              @include flex-center;
              justify-content: center;
              font-size: 20px;
              border-radius: 5px;
              cursor: not-allowed;
              border: 1px solid white;
              color: $c-9e;
              @include wt-ht(35px, 35px);
              background: white;
              transition: all 0.3s;
              &.enabled {
                cursor: pointer;
                border: 1px solid $blue-violet;
                color: $blue-violet;
                &:hover {
                  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
                }
              }
            }
            .moreOptionOverlay {
              @include bg-mask(2, transparent);
            }
          }
        }
      }
      .accountsTable {
        position: relative;
        height: calc(100% - 138px);
        border: 1px solid #eceff1;
        border-radius: 5px;
        .accountListHeader {
          position: relative;
          border-bottom: 1px solid $b-c-1;
          @include bor-rad-top(5px, 5px);
          background: $c-fa;
          ul {
            display: grid;
            grid-template-columns: 3% repeat(7, 1fr);
            padding: 10px 14px;
            line-height: normal;
            margin: 0;
            list-style-type: none;
            align-items: center;
            .headerCheckbox {
              height: 24px;
              .checkBoxSection {
                grid-template-columns: 100%;
              }
            }
            li {
              color: $c-3b;
              font-size: 14px;
              &.contacts,
              &.actions,
              &.owner {
                text-align: center;
              }
            }
          }
        }
        .accountsListBody {
          position: relative;
          background: $white;
          height: calc(100% - 46px);
          @include bor-rad-btm(5px, 5px);
          &.paginationSection {
            height: calc(100% - 94px);
            @include bor-rad-btm(0, 0);
            .pagination-section {
              @include bor-rad-btm(5px, 5px);
            }
          }

          .actionDp {
            position: relative;
            @include flex-center;
            justify-content: center;
            .linear-background {
              @include wt-ht(30px, 30px);
              border-radius: 35px;
              display: block;
              display: block;
              margin: 0 auto;
            }
            .icon-hover-btn {
              font-size: 14px !important;
            }
          }
          .moreOption {
            text-align: center;
            cursor: pointer;
            .linear-background {
              @include wt-ht(30px, 30px);
              border-radius: 50%;
            }
          }

          .accountsListLoader {
            background: $white;
            border-bottom: 1px solid $c-dd;
            ul {
              height: 48px;
              display: grid;
              grid-template-columns: 3% repeat(7, 1fr);
              align-items: center;
              padding: 10px;
              margin: 0;
              list-style-type: none;
              .checkbox {
                width: 14px;
                height: 14px;
                border-radius: 4px;
                margin: 0 5px;
                padding: 8px;
              }
              .name {
                &.linear-background {
                  width: 50%;
                }
              }
              .website {
                &.linear-background {
                  width: 65%;
                }
              }
              .contacts {
                margin: 0 auto;
              }
              .stage,
              .contacts {
                &.linear-background {
                  width: 30%;
                }
              }
              .lastContacted {
                &.linear-background {
                  width: 70%;
                }
              }
              .owner {
                margin: 0 auto;
                &.linear-background {
                  @include wt-ht(30px, 30px);
                  border-radius: 35px;
                  display: block;
                }
              }
              .actions {
                margin: 0 auto;
                &.linear-background {
                  @include wt-ht(30px, 30px);
                  border-radius: 35px;
                  display: block;
                }
              }
            }
          }
          .accountsListTableBody,
          .accountLoaderBody {
            position: relative;
            width: 100%;
            height: calc(100% - 52px);
            overflow: auto;
            .accountDp {
              position: relative;
            }
            .accountRow {
              display: grid;
              grid-template-columns: 3% repeat(7, 1fr);
              align-items: center;
              padding: 6px 14px;
              font-size: 14px;
              cursor: pointer;
              background: $white;
              line-height: normal;
              border-bottom: 1px solid $b-c-1;
              .contacts {
                position: relative;
                text-align: center;
              }
              .noAction {
                text-align: center;
              }
              .website {
                @include ellipsis();
                .linkIcon {
                  margin-right: 4px;
                }
                .websiteLink {
                  @include linkHover;
                }
              }
              &:first-child .owner .customTooltip {
                .sharpPointer {
                  top: 14px;
                  right: 0px;
                  border-bottom-left-radius: 35px;
                }
                .tooltipBody {
                  top: 20px;
                }
              }
              .name {
                display: flex !important;
                align-items: center;
                @include ellipsis();
                margin-right: 2px;
                .logoHeaderIcon {
                  margin-right: 4px;
                  margin-bottom: 4px;
                }
                .accountLogo {
                  img {
                    margin-right: 4px;
                    object-fit: contain;
                    @include wt-ht(24px, 24px);
                  }
                }
                .accountName {
                  @include ellipsis();
                }
              }
              .stage {
                position: relative;
                margin-left: 4px;
                @include updateAccountStageDropdown();
              }
              .owner {
                @extend %contactOwner;
              }
              .actions {
                @include flex-center;
              }
              .lastContacted {
                margin-left: 8px;
              }
            }
            .pagination-section {
              padding-bottom: 15px;
            }
          }
          .noAccountFoundCont {
            position: relative;
            @include flex-align-center;
            @include wt-ht(100%, 100%);
          }
        }
      }
    }
    .accountContactsLoader {
      .headerCheckbox .linear-background {
        @include wt-ht(15px, 15px);
        border-radius: 4px;
      }
      .headerCheckbox {
        padding-left: 25px;
      }
      .linear-background {
        display: block;
        @include wt-ht(70%, 12px);
        border-radius: 10px;
        margin: unset;
      }
    }
  }
}

.accountDetailedView {
  position: relative;
  @include wt-ht(100%, calc(100vh - 130px));
  display: grid;
  grid-template-columns: calc(100% - 370px) 360px;
  gap: 10px;
  .accountDetailedViewWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    .tabNavigationBody {
      @include flex-center;
      justify-content: space-between;
      .tabNavigation {
        height: 52px;
        border-bottom: 1px solid #ececec;
      }
    }

    .addNotesButton {
      button {
        font-size: 14px;
        padding: 10px 0;
        border-radius: 5px;
        letter-spacing: 0.3px;
        min-width: 120px;
        margin: unset;
        &.addAccountNotesBtn {
          border: solid 1px $blue-violet;
          background: $blue-violet;
        }
      }
    }
  }
}

.singleBanner .accountDetailedView {
  height: calc(100vh - 170px);
}
