.radioButtonCont {
  position: relative;
  transition: all 0.5s;
  @include flex-center();
  height: auto;
  cursor: pointer;

  label {
    cursor: pointer;
  }

  .radioBtn {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 23px;
    height: 24px;
    display: block;
    margin: 0;
    cursor: pointer;

    &:checked ~ label {
      color: $blue-violet !important;
    }
  }

  .contentClass {
    margin-right: 5px;
    cursor: pointer;
    position: relative;
    transition: all 0.5s;
    width: 23px;
    height: 24px;
    display: block;

    &::before,
    &::after {
      content: "";
      border-radius: 9999px;
      width: 16px;
      height: 16px;
      margin: 3px 0;
      position: absolute;
      z-index: 1;
    }

    &:before {
      transition: all 0.5s;
      background-color: #ffffff;
      border: 2px solid #dcdcdc;
    }

    &:after {
      background-color: transparent;
      transition: all 0.2s;
      transition-timing-function: ease-out;
    }

    &:hover::after {
      transform: scale(2.5);
      background-color: rgba(51, 170, 221, 0.08);
    }
  }

  .radioBtn:checked ~ .contentClass::before {
    background-color: #ffffff;
    border: 2px solid #3953f6;
  }

  .radioBtn:checked ~ .contentClass::after {
    background-color: #3953f6;
    border: 0px solid transparent;
    transform: scale(0.5);
  }
}
