@import "styles/layout/onBoarding/onBoardingMixins";

.dashboardPage {
  width: 100%;
  height: 100%;
  background: $c-f5f8;
  border-color: $bg-default;

  .dashboardWrapper {
    position: relative;
    @include wt-ht(100%, calc(100% - 40px));
    padding: 0 12px;
    display: block;
    overflow: hidden;
    .filterSectionWrap {
      position: relative;
      height: 52px;
      padding-left: 12px;
      @include flex-center();
      justify-content: space-between;
      .userFilterDropDown {
        position: relative;
        .userFilterBtn {
          position: relative;
          @include flex-center();
          justify-content: center;
          outline: none;
          background: white;
          border-radius: 3px;
          border: 1px solid $b-c-1;
          padding: 0 10px;
          @include wt-ht(200px, 36px);
          font-size: 15px;
          transition: all 0.3s ease;
          cursor: pointer;
          &:hover {
            background: $c-fb;
            transition: all 0.3s ease;
          }
          .icon {
            margin-right: 10px;
            @include wt-ht(14px, 14px);
            svg {
              fill: $blue-violet;
              @include wt-ht(14px, 14px);
            }
          }
          .UpgradeTooltip {
            .sharpPointer {
              right: 29px;
            }
          }
          .name {
            position: relative;
            color: $blue-violet;
            min-width: 100px;
            max-width: 110px;
            text-align: left;
            font-size: 15px;
            top: 2px;
            line-height: initial;
            .shortName {
              text-transform: uppercase;
              &:not(:last-child)::after {
                content: ", ";
              }
              &.teamNameSelected {
                text-transform: unset;
                @include ellipsis;
              }
            }
          }
          .hiddenCount {
            font-size: 13px;
            color: $c-78;
            min-width: 30px;
            line-height: initial;
            position: relative;
            top: 1px;
          }
          .arrow {
            margin-left: 5px;
            margin-top: 3px;
            @include flex-center();
            transition: all 0.3s ease;
            svg {
              fill: $c-53;
              @include wt-ht(11px, 11px);
            }
            &.activeArrow {
              transform: rotate(-180deg);
              transition: all 0.3s ease;
            }
          }
        }
        @extend %userListDropdown;
        .overlayUserFilterMask {
          @include bg-mask(4, transparent);
        }
      }
    }
    .dashboardContainer {
      position: relative;
      @include wt-ht(100%, calc(100% - 82px));
      border-radius: 10px;
      display: grid;
      grid-template-columns: calc(72% - 16px) 28%;
      grid-gap: 10px;
      overflow: auto;
      color: $c-66;
    }
    .leftSection {
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      height: 100%;
    }
    .rightSection {
      border-radius: 10px;
      background: $white;
      overflow: hidden;
    }
  }
}

.no-task {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .noTaskText {
    font-size: 13px;
  }

  .taskIcon {
    width: 15px;
    height: 15px;
    margin-right: 5px;

    svg {
      width: inherit;
      height: inherit;
      fill: #666;
    }
  }
}

.taskSectionWrapper {
  background: $white;
  border-radius: 10px;
  flex: 0 0 40%;
  .taskFilterWrapper {
    @include flex-center();
    justify-content: space-between;
    width: 100%;
    height: 56px;
    padding: 0 12px;
    box-shadow: 0 4px 6px 0.7px rgba(59, 59, 59, 0.07);
    .filterDetails {
      margin-right: 10px;
      cursor: pointer;
      &.active {
        .filterOption {
          border: 1px solid $c-d8;
        }
      }
    }
    .taskFilterHeading {
      flex: 0 0 15%;
      font-weight: bold;
      font-size: 16px;
    }
    .taskFilterHeader {
      font-size: 16px;
    }
    .taskFilterSection {
      @include flex-center();
      flex-direction: row-reverse;
      flex: 1;
      overflow: auto;
    }
    .filterDetails {
      &.linear-background {
        @include wt-ht(150px, 32px);
        list-style-type: none;
        border-radius: 10px;
      }
      &:last-child .filterOption {
        width: 125px;
      }
    }
    .filterOption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      width: 160px;
      border: 1px solid $b-c-1;
      height: 32px;
      border-radius: 6px;
      padding: 0 12px;
      .material-icons-outlined {
        width: 20px;
        font-size: 16px;
        color: $c-7b;
      }
      .filterValue {
        font-size: 15px;
        font-family: "Graphik-Semibold";
        font-weight: bold;
        color: $c-66;
      }
      .filterLabel {
        @include ellipsis();
        font-size: 13px;
      }
      .svgIcon {
        width: 20px;
        display: block;
        svg {
          @include wt-ht(14px, 14px);
          fill: $c-7b;
        }
        &.linkedin svg {
          width: 12px;
        }
        &.phone,
        &.customTask,
        &.person_add {
          height: 16px;
        }
      }

      .filterValueCont {
        @include flex-center();
        text-align: left;
        overflow: hidden;
        width: 100%;
        font-size: 14px;
        i {
          font-size: 15px;
        }
      }
    }
  }

  .taskSectionDetailWrapper {
    padding: 12px;
    height: 270px;
    overflow: auto;
    transition: all ease 0.3s;
    .taskSectionDetail,
    .taskTableBodyLoader {
      display: grid;
      grid-template-columns: 24% 20% 43% 13%;
      padding: 12px;
      border: 1px solid $b-c-1;
      border-collapse: collapse;
      align-items: center;
      .taskEmail {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.linear-background {
          @include wt-ht(85%, 14px);
          margin-bottom: 4px;
        }
      }
      .contactOwner {
        border: 1px solid $b-c-1;
        border-radius: 6px;
        background-color: #e2dede;
        padding: 0 8px;
        padding-top: 1px;
        font-size: 14px;
        @include wt-ht(30px, auto);
        font-weight: bold;
        @include flex-center();
        justify-content: center;
        &.linear-background {
          display: block;
          @include wt-ht(30px, 20px);
        }
      }
      .taskTypeLabel,
      .taskDueDate span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .taskTypeWrapper {
        @include flex-center();
        &.manualEmail .taskTypeIcon,
        &.email .taskTypeIcon {
          display: flex;
          i.manualEmail {
            font-size: 17px;
            color: $c-7b;
          }
        }
        &.linkedin,
        &.customTask {
          svg {
            fill: $c-7b;
            @include wt-ht(16px, auto);
            path {
              fill: $c-7b;
            }
          }
        }
        &.phone {
          svg {
            fill: $c-7b;
            @include wt-ht(16px, auto);
          }
        }
      }
      .taskTypeIcon {
        margin-right: 8px;
        .taskTypeWrapper {
          @include flex-center();
          font-size: 14px;
        }
      }
      .taskDueDate {
        @include flex-center;
        i {
          padding-right: 3px;
          font-size: 15px;
        }
        .linear-background {
          display: block;
          @include wt-ht(80%, 14px);
        }
      }
      .taskNotes {
        .linear-background {
          display: block;
          @include wt-ht(80%, 14px);
        }
      }
      .taskNotes,
      .taskDueDate {
        font-size: 14px;
      }
      .notes {
        @include lineClamp(1);
      }
    }
  }
}

.liveFeedSettingBarWeb {
  .linear-background {
    @extend %linear-background;
  }

  .tabWrapper {
    position: fixed;
    z-index: 10;
    top: 0;
    background: $white;
    user-select: none;
    width: 410px;
    @include flex-align-path-justify(center, row, space-evenly);
    .tabItem {
      position: relative;
      height: 46px;
      cursor: pointer;
      background: $white;
      border: 0;
      outline: 0;
      font-size: 18px;
      text-transform: capitalize;
      color: $c-77787c;
      border-bottom: 2px solid transparent;
      &.activeTab {
        color: $blue-violet;
        font-weight: 500;
        border-bottom: 2px solid $blue-violet;
        pointer-events: none;
        transition: border-bottom 0.2s ease;
      }
    }
  }

  .liveFeedSection {
    padding: 0 12px;
    padding-top: 8px;
    position: relative;
    height: 100%;

    .tagSectionWrapper {
      @include flex-center();
      flex: 1;
      flex-wrap: wrap;
      justify-content: flex-end;
      max-height: 34px;
      overflow: auto;
      .tagWrapper {
        @include flex-center();
        border-radius: 15px;
        background-color: $white;
        width: max-content;
        padding: 6px 10px;
        margin: 0 4px;
        margin-bottom: 4px;
        border: 1px solid $b-c-1;
        .tagText {
          font-size: 12px;
          color: $c-8f;
          margin-right: 10px;
          text-transform: capitalize;
        }
        .closeIcon {
          font-size: 10px;
          cursor: pointer;
          padding: 3px;
          &:hover {
            @include flex-center();
            background-color: $c-dd;
            border-radius: 50%;
          }
        }
      }
    }
    .liveFeedHeaderWrapper {
      border-bottom: 1px solid $border-grey;
    }

    .liveFeedHeader {
      @include flex-center();
      justify-content: space-between;
      padding-bottom: 8px;
      .liveFeedTitle {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .filterWrapper {
      @include flex-center();
      justify-content: flex-end;
      position: relative;
      .refreshIcon {
        font-size: 18px;
        cursor: pointer;
      }
      .filterOverlay {
        @include bg-mask(2, transparent);
      }
      .filterByEventWrapper {
        flex: 0 0 100px;
        height: 36px;
        @include flex-center();
        justify-content: space-between;
        border: 1px solid $mild-blue;
        border-radius: 8px;
        padding: 10px;
        background-color: $white;
        margin-left: 10px;
        cursor: pointer;
        svg {
          width: 12px;
          height: 12px;
          fill: $blue-violet;
        }
        .filterTextWrapper {
          @include flex-center;
          color: $c-66;
          font-size: 12px;
          span {
            padding-top: 2px;
          }
          .dropdownFilterIcon {
            font-size: 16px;
            color: $c-9e;
          }
        }
      }
    }
    .filterDropdown {
      position: absolute;
      top: 40px;
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
      border: 1px solid $c-eb;
      width: 175px;
      z-index: 3;
      user-select: none;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      animation: dropdownScaleIn 0.3s linear;

      .sharpPointer {
        position: absolute;
        border-top-right-radius: 5px;
        padding: 8px;
        border-right: 1px solid $c-f5;
        border-top: 1px solid $c-f5;
        z-index: 4;
        top: -8px;
        right: 10px;
        transform: rotate(-45deg);
        background: $white;
      }

      .dropDownItem {
        padding: 8px 10px;
        font-size: 14px;
        text-transform: capitalize;
        cursor: pointer;
        color: #444;
        @include flex-center();
      }

      .commonFilledCheckBox {
        position: relative;
        @include wt-ht(15px, 15px);
        border: 1px solid $c-cc;
        -webkit-perspective: 18px;
        perspective: 18px;
        display: block;
        border-radius: 3px;
        transform: translateZ(0);
        overflow: hidden;
        margin-right: 8px;
        cursor: pointer;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        &:hover {
          border-color: $blue-violet;
        }
        .checkBoxCard {
          -webkit-transition: all 0.4s ease;
          transition: all 0.4s ease;
          -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
          position: relative;
          @include wt-ht(13px, 13px);
          .checkSvgIcon,
          .emptyCheckBox {
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            @include wt-ht(13px, 13px);
            border-radius: 3px;
            svg {
              margin-top: 1px;
              fill: none;
              @include wt-ht(11px, 11px);
              path {
                stroke: $white;
                stroke-width: 2.5;
                stroke-linecap: round;
                stroke-linejoin: round;
              }
            }
          }
          .emptyCheckBox {
            background-color: $white;
            z-index: 1;
          }
          .checkSvgIcon {
            -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
            background: $blue-violet;
            text-align: center;
            color: $white;
            width: 13px;
            height: 13px;
            box-shadow: 0 0 0 1px $blue-violet;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        &.checked {
          border-color: $blue-violet;
          background: $blue-violet;
          .checkBoxCard {
            -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
          }
        }
      }
    }

    .liveFeedWrapper,
    .liveFeedLoadingWrapper {
      margin-top: 12px;
      height: calc(100% - 72px);
      overflow: auto;
      padding-right: 6px;
      overflow-x: unset;
      .singleFeed {
        margin-bottom: 16px;
      }
      .liveFeed {
        padding-bottom: 10px;
        &:last-child {
          padding-bottom: 0;
          .verticalLine {
            display: none;
          }
        }
      }
      .accordionSection {
        display: flex;
        position: relative;
        .leftPanel {
          @include wt-ht(27px, 27px);
          @include flex-center();
          border-radius: 50%;
          justify-content: center;
          flex: 0 0 27px;
          z-index: 1;
          margin-top: 12px;

          .verticalLine {
            width: 2px;
            height: calc(100% - 36px);
            left: 13px;
            top: 51px;
            background: $c-d8;
            position: absolute;
          }

          svg {
            fill: white;
            @include wt-ht(15px, 15px);
          }
        }
      }

      .rightPanel {
        background: $white;
        border-radius: 10px;
        box-shadow: 0.6px 1.9px 3.9px 3px rgba(59, 59, 59, 0.06);
        margin-left: 12px;
        font-size: 14px;
        color: $c-66;
        display: flex;
        flex-direction: column;
        flex: 1;
        .wrapRightPanel {
          padding: 12px;
        }
        .autoReplyBadge {
          margin-bottom: 8px;
        }
        .accordionWithArrow {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .accorWrap {
            display: flex;
            flex-direction: column;
            flex: 0 0 72%;
          }

          .accordionInfo {
            flex: 1;
            margin-right: 4px;
          }
          .accordionRightPanel {
            @include flex-center();
            justify-content: flex-end;
            width: 100%;
          }

          .accordionArrow {
            transition: 0.6s ease;
            cursor: pointer;
            font-size: 18px;
          }
          .timeNoted {
            color: $c-bdc0c2;
            font-size: 12px;
            text-align: right;
            padding-right: 6px;
            &.noAccordion {
              padding-top: 6px;
            }
          }
        }

        .roundedIcon {
          border-radius: 50%;
          width: 20px;
          height: 20px;
          @include flex-center();
          justify-content: center;
          margin-right: 4px;
          svg {
            fill: white;
            color: white;
            @include wt-ht(15px, 15px);
            path {
              fill: white;
              @include wt-ht(15px, 15px);
            }
          }
        }
        .recipientName {
          font-weight: 400;
          color: $c-2080ed;
          padding-right: 2px;
          word-break: break-word;
          cursor: pointer;
          &.noCursor {
            cursor: unset;
          }
        }
        .fromText {
          margin: 0 4px;
        }
        .sequenceSubject,
        .replyContent {
          padding-left: 3px;
          color: $c-2080ed;
          font-weight: 400;
          font-size: 14px;
          word-break: break-word;
        }
        .subject {
          cursor: pointer;
          word-break: break-word;
          padding-left: 3px;
          color: $c-2080ed;
          font-weight: 400;
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }
        .noSubject {
          pointer-events: none;
        }
      }
      .date {
        color: $c-77787c;
        font-size: 14px;
        margin-bottom: 16px;
        font-weight: 500;
      }
      .accordionHeader {
        @include flex-align-path-justify(center, row, space-between);
      }
      .accordionContent {
        background: $white;
        overflow: hidden;
        transition: max-height 0.4s ease;
      }
      .sequenceDetailedInfo {
        position: relative;
        @include flex-center;
        flex: 1;
        width: 100%;
        padding: 10px;
        border-top: 1px solid $c-f5;
        .chip {
          position: relative;
          @include flex-center;
          justify-content: center;
          font-size: 11px;
          border-radius: 35px;
          padding: 5px;
          border: 1px solid #dcdfe4;
          color: #8e9193;
          &.sequenceStageType {
            max-width: 90px;
            @include ellipsis();
          }
          &.sequenceStageNo {
            max-width: 60px;
            margin-right: 7px;
            @include ellipsis();
          }
          &.sequenceName {
            margin-right: 7px;
            max-width: 150px;
            @include ellipsis;
            width: fit-content;
          }
        }
      }
      .contentWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $border-grey;
        &:first-child {
          padding-top: 16px;
        }
        &:last-child {
          border-bottom: 0;
        }
        padding: 12px 8px;
        .status {
          @include flex-center();
          flex: 1;
        }
        .statusText {
          text-transform: capitalize;
          color: $c-66;
          font-size: 13px;
          &.addHyphen {
            &:after {
              content: "-";
              margin: 0 4px;
            }
          }
        }
        .clickedLink {
          color: $c-0177ff;
          text-overflow: ellipsis;
          width: 75px;
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          font-size: 12px;
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }
        .rightArrowWrapper {
          width: 12px;
          height: 12px;
          transform: rotate(-45deg);
          cursor: pointer;
          margin-right: 8px;
          svg {
            fill: $c-0177ff;
          }
        }
      }
      .dateTime {
        color: $c-bdc0c2;
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .additionalInfoWrapper {
        padding-top: 16px;
        @include flex-center();
        justify-content: space-between;
        .additionalInfoSection {
          display: flex;
        }
        .openClickInfo {
          border: 1px solid $mild-grey;
          @include flex-center;
          min-width: 92px;
          padding: 6px;
          margin-right: 8px;
          border-radius: 5px;
          box-shadow: 0.6px 1.9px 3.9px 0.1px rgba(59, 59, 59, 0.06);
          background: $white;
          cursor: pointer;
          .openClickInfoText {
            font-size: 11px;
            color: $c-666870;
          }
        }
      }
    }
  }

  .noActivityFound {
    @include flex-align-path-justify(center, column, center);
    height: 100%;
    position: relative;
    justify-content: start;

    .noActivityFoundText {
      color: $c-8a8787;
      font-size: 17px;
      font-style: italic;
      left: 124px;
      text-align: center;
      padding: 5px;
    }
  }
}

.reportsModuleWrapper {
  margin-bottom: 10px;
  background: $white;
  border-radius: 10px;
  padding: 8px 12px;
  flex: 1;
  transition: all ease 0.3s;
  .noActivityFound {
    @include flex-center();
    justify-content: center;
    min-height: 200px;
    .textDesc {
      font-style: unset !important;
      color: $c-66;
      font-size: 13px;
    }
  }
  .reportsHeader {
    @include flex-center();
    justify-content: space-between;
    margin-bottom: 20px;
    .flexCenter {
      @include flex-center();
      .reportsDate {
        margin-left: 4px;
      }
      .reportsDate {
        color: $blue-violet;
        font-size: 14px;
      }
      .reportsTitle {
        font-size: 16px;
        font-weight: bold;
        color: $c-66;
      }
    }
  }
  .dateFilter {
    @include flex-center();
    justify-content: flex-end;
    flex: 1;
    overflow-x: auto;
    .tabItem {
      border: 1px solid $b-c-1;
      height: 32px;
      padding: 0 8px;
      @include flex-center();
      font-size: 14px;
      cursor: pointer;
      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &.active {
        background-color: $blue-violet;
        color: $white;
      }
    }
  }
  .activityInsightsAnalyticsWrapper {
    @include flex-center();
    overflow: auto;
    flex: 1;
    .activityAnalyticsWrap {
      border: 1px solid $b-c-1;
      border-collapse: collapse;
      padding: 8px 12px;
      @include flex-center();
      justify-content: space-between;
      &.activityAnalyticsLoader {
        @include flex-center();
        flex-direction: column;
      }
      .activityTitle {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 110px;
        white-space: nowrap;
        margin-top: 4px;
        &.linear-background {
          @include wt-ht(80px, 14px);
        }
      }
      i {
        font-size: 20px;
      }
      .activityCount {
        font-size: 18px;
        font-weight: bold;
        &.linear-background {
          @include wt-ht(30%, 14px);
        }
      }
    }
  }
  .activityInsightsAvgWrapper {
    padding: 8px 12px;
    overflow: auto;
    border: 1px solid $b-c-1;
    border-radius: 6px;
    .activityInsightsAvgSection {
      @include flex-center();
      width: 100%;
    }
    .activityInsightsAvgTitle {
      font-size: 14px;
      font-weight: bold;
    }
    .borderLoader {
      background-color: $white;
      @include flex-center;
      justify-content: center;
      width: 100%;
    }
    .activityInsightsAvg {
      padding: 0px 12px;
      background-color: $white;
      font-size: 14px;
      @include flex-center();
      .activityInsightsText {
        &.linear-background {
          @include wt-ht(45px, 14px);
          margin-right: 6px;
        }
      }
      .activityInsightsAvgTile {
        @include wt-ht(16px, 16px);
        margin-right: 6px;
        &.linear-background {
          @include wt-ht(16px, 14px);
          border-radius: 0;
        }
      }
    }
  }
  .activityInsightsHeader {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    .activityInsightsAvgHeader {
      @include flex-center();
      justify-content: space-between;
      margin-bottom: 6px;
      .activityInsightsAvgTitle {
        @include flex-center();
      }
    }
  }
}

.onBoardingWrapper {
  overflow: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.onBoardingCardWrapper {
  width: 80%;
  max-width: 300px;
  cursor: pointer;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
.onBoardingHoverCardWrapper {
  min-height: 110px;
  width: 260px;
  box-shadow: 0.1px 6px 10px 2px rgba(129, 129, 129, 0.31);
  border: solid 1px rgba(147, 147, 147, 0.11);
  background-color: $white;
  border-radius: 10px;
  position: absolute;
  top: -4px;
  bottom: 0;
  margin: auto 0;
  right: -273px;
  text-align: center;
  padding: 18px;
  font-size: 14px;
  pointer-events: none;
  z-index: 6;
  @include flex-center();
  p {
    div {
      margin-top: 16px;
    }
  }
  &.leftHoverCard {
    left: -283px;
    .sharpPointer {
      right: -11px;
      left: unset;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 10px solid white;
      border-right: 0;
    }
  }
  .breakSentence {
    margin-top: 16px;
  }
  .sharpPointer {
    position: absolute;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 10px solid white;
    top: 50%;
    bottom: 50%;
    left: -10px;
    display: flex;
    align-items: center;
  }
}
.onBoardingCardContainer {
  position: relative;
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    user-select: none;
    .stepContainer .roundedStepNumber {
      background: $white !important;
      color: $c-66 !important;
    }
    .doneOutlineIcon {
      color: $c-66 !important;
    }
    .stepContent {
      box-shadow: 0.1px 4px 10px 0 rgba(147, 147, 147, 0.11) !important;
      p {
        color: $c-66 !important;
        font-weight: unset !important;
      }
    }
  }
  &.activeHoverCard {
    .stepContent {
      box-shadow: 0.1px 6px 10px 0 rgba(129, 129, 129, 0.31);
    }
  }
  &.activeOnboarding {
    .stepContainer .roundedStepNumber {
      background-color: $blue-violet;
      color: $white;
    }
    .stepContent {
      box-shadow: 0.1px 6px 10px 0 rgba(129, 129, 129, 0.31);
      p {
        color: $blue-violet;
        font-weight: 700;
      }
    }
  }
  &.linear-background {
    height: 110px;
    width: 260px;
    border-radius: 10px;
  }
  &.completedOnBoardingCardContainer {
    cursor: not-allowed;
    .stepIcon {
      path {
        stroke: #c4c4c4;
      }
    }
    .stepContent p {
      color: $c-66;
      font-weight: 700;
    }
    .emailIcon,
    .contactsIcon {
      path:last-child {
        fill: #c4c4c4;
        stroke: unset;
      }
    }
  }
  .stepsContentWithAnimation {
    display: flex;
    flex-direction: column;
  }
  .stepContent {
    display: flex;
    flex-direction: column;
    box-shadow: 0.1px 4px 10px 0 rgba(147, 147, 147, 0.11);
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12px;
    border-radius: 10px;
    p {
      font-size: 14px;
      color: $c-66;
    }
    .stepIcon {
      margin-bottom: 12px;
      svg {
        @include wt-ht(32px, 32px);
      }
    }
  }
  .stepContainer {
    position: absolute;
    left: -13px;
    bottom: 50%;
    top: 50%;
    display: flex;
    align-items: center;

    .roundedStepNumber {
      border-radius: 50%;
      @include wt-ht(30px, 30px);
      @include flex-center();
      justify-content: center;
      padding: 8px;
      background: $white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
      &.completedCircle {
        background-color: $green;
      }
      .doneOutlineIcon {
        color: $white;
        font-size: 20px;
      }
    }
  }
}
.onboarding-popup-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #333;
  opacity: 0.5;
}

.onBoardingCompletedPopupWrapper {
  @include onBoardingCompletedPopup();
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
