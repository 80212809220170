.gmailMessagePopup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  overflow: auto;
  @include flex-center();

  .gmailMessagePopupCont {
    padding: 20px;
    @include wt-ht(650px, 650px);
    margin: auto;
    background: $white;
    border-radius: 6px;
    display: grid;
    grid-template-rows: 5% 85% 10%;
    grid-template-columns: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;

    &.grid-row-4 {
      grid-template-rows: 15% 75% 10%;
    }

    &.grid-row-3 {
      grid-template-rows: 5% 85% 10%;
    }

    .popupHeader {
      position: relative;
      width: 100%;
      .title {
        color: $c-66;
        text-align: center;
        font-size: 16px;
        width: 100%;
        position: relative;
        @include flex-align-path-justify(center, row, center);

        .errorIcon {
          color: $red;
          font-size: 21px;
          padding-right: 10px;
        }
      }

      .closeIcon {
        color: $c-78;
        border-radius: 50%;
        position: absolute;
        right: -10px;
        top: -15px;
        padding: 3px;
        font-size: 19px;
        cursor: pointer;

        &:hover {
          background: $c-dd;
          color: $black;
          transition: all 0.2s ease-in;
        }
      }

      .subTitle,
      .additionalLink {
        font-size: 13px;
        color: $c-8c8c8c;
        padding-top: 10px;
        text-align: center;
        line-height: 1.4;
      }
      .additionalLink {
        .errorLink {
          color: $blue-violet;
          text-decoration: unset;
          &:hover {
            text-decoration: underline;
          }
        }
        .openInNewTabIcon {
          color: $blue-violet;
          margin-left: 8px;
          cursor: pointer;
        }
      }
    }

    .popupBody {
      @include wt-ht(100%, 80%);
    }

    .popupFooter {
      @include flex-align-path-justify(center, row, center);
      cursor: pointer;
      .googleAuthBtn {
        width: 215px;
        cursor: pointer;
      }
    }
  }

  + .popupMask {
    @include bg-mask(99, rgba(0, 0, 0, 0.57));
  }
}
