.pricingDiscountBanner {
  @extend %relBlock;
  background: $blue-violet;
  width: calc(100% - 2%);
  margin: 0 auto;
  height: 54px;
  padding: 0 1%;
  @include bor-rad-btm(10px, 10px);
  transition: all 0.3s;
  .bgOverlay {
    position: absolute;
    top: 0;
    @include wt-ht(100%, 54px);
    z-index: 0;
    @include bor-rad-btm(10px, 10px);
  }
  .wrapContentImgBanner {
    position: relative;
    @include flex-center;
    justify-content: center;
    z-index: 1;
    .celCapLeft {
      width: 24px;
      margin-right: 30px;
      margin-top: -5px;
    }
    .phBadge {
      width: 140px;
      margin-right: 30px;
    }
    .wrapTextAndDiscountpercent {
      position: relative;
      @include flex-center;
      color: white;
      height: 54px;
      .mediumText {
        position: relative;
        font-size: 20px;
        font-weight: bold;
        padding: 0 10px;
      }
      .discountWrap {
        position: relative;
        @include flex-center;
        flex-direction: row;
        color: #f5ba3d;
        .number {
          position: relative;
          font-size: 42px;
          font-family: $font-semi-bold;
        }
        .percentOffWrap {
          position: relative;
          @include flex-center;
          flex-direction: column;
          justify-content: center;
          padding: 0 10px 0 5px;
          .symbol {
            position: relative;
            font-size: 14px;
            font-weight: bold;
          }
          .offText {
            font-weight: bold;
            font-size: 14px;
            text-transform: uppercase;
          }
        }
      }
      .smallText {
        position: relative;
        font-size: 16px;
        font-weight: bolder;
      }
    }
    .discountBtnWrap {
      position: relative;
      @include flex-center;
      justify-content: space-between;
      @include wt-ht(170px, 32px);
      padding: 0 10px;
      background: white;
      color: $blue-violet;
      margin-left: 20px;
      border-radius: 6px;
      box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
      animation: shake-content linear 1.2s 1;
      cursor: pointer;
      .btnText {
        padding-top: 3px;
        position: relative;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 600;
      }
      .forwardArrow {
        font-size: 16px;
        width: 16px;
      }
    }
    .celCapRight {
      width: 24px;
      margin-left: 30px;
      margin-top: -5px;
    }
  }
}
