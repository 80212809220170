.syncConfirmationPopupWrapper {
  @extend %popupCard;
  padding: 20px;
  z-index: 12;
  .card {
    width: 400px;
    max-height: auto;
    overflow: auto;
    text-align: left;
    position: relative;
  }
  .popupHeading {
    font-size: 20px;
    color: $c-3b;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .popupContent {
    margin-bottom: 16px;
    color: $c-66;
    font-size: 14px;
  }
  .closeIcon {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 18px;
    padding: 4px;
    &:hover {
      background-color: $c-dd;
    }
  }
  .criteriaWrapper {
    @include flex-center;
    font-size: 14px;
    color: $c-66;
    margin: 12px 0;
    .popupCriteria {
      color: $blue-violet;
      font-weight: 500;
      margin-left: 4px;
    }
  }
  .actionBtn {
    @include flex-center;
    justify-content: right;
    .startSyncCrm {
      width: 120px;
      background: $blue-violet;
      cursor: pointer;
      margin: 0;
    }
    .cancelBtn {
      color: $blue-violet;
      width: 100px;
      cursor: pointer;
      background-color: $white;
      margin: unset;
      margin-right: 16px;
      border: 1px solid $blue-violet;
    }
  }
}
.syncConfirmationPopupOverlay {
  @include bg-mask(11, rgba(0, 0, 0, 0.65));
}
