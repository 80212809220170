$customThemeColor: #333 !default;

.tooltip-container {
  box-sizing: border-box;
  z-index: 1000;
  background-color: #333 !important;
  color: white;
  padding: 3px 5px !important;
  border-color: #333 !important;
  font-family: $font-regular, sans-serif;
  font-size: 11px;
  will-change: transform !important;

  .tooltip-arrow {
    top: 3px !important;

    &::after,
    &::before {
      border-width: 0.4rem 0.3rem 0.4rem 0 !important;
    }
  }

  .tooltip-arrow[data-placement*="top"] {
    &::after {
      border-color: $customThemeColor transparent transparent transparent !important;
    }
    &::before {
      border-color: $customThemeColor transparent transparent transparent !important;
    }
  }

  .tooltip-arrow[data-placement*="right"] {
    &::after {
      border-color: transparent $customThemeColor transparent transparent !important;
    }
    &::before {
      border-color: transparent $customThemeColor transparent transparent !important;
    }
  }

  .tooltip-arrow[data-placement*="left"] {
    &::after {
      border-color: transparent transparent transparent $customThemeColor !important;
    }
    &::before {
      border-color: transparent transparent transparent $customThemeColor !important;
    }
  }

  .tooltip-arrow[data-placement*="bottom"] {
    &::after {
      border-color: transparent transparent $customThemeColor transparent !important;
    }
    &::before {
      border-color: transparent transparent $customThemeColor transparent !important;
    }
  }
}
