.template-section {
  background-color: $bg-default;
  position: relative;

  .create-new-templates-section {
    @include wt-ht(100%, 100%);
    position: absolute;
    .overlay-popup {
      @include bg-mask(19, rgba(0, 0, 0, 0.57));
    }
    .create-new-templates {
      font-family: $font-regular, Arial, sans-serif;
      @include wt-ht(80%, calc(100% - 10%));
      overflow: hidden;
      @include pos-center(fixed, 20, $white, 25px, 10px);

      .popup-close {
        position: absolute;
        padding: 3px;
        top: 10px;
        right: 15px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1;

        .material-icons {
          font-size: 19px;
        }

        &:hover {
          background-color: #ddd;
          border-radius: 50%;
        }
      }

      .create-new-template-body {
        @include wt-ht(100%, 100%);
        position: relative;
        .create-new-template-content {
          display: grid;
          grid-template-columns: calc(60% - 2%) calc(40%);
          column-gap: 2%;
          justify-content: center;
          overflow: hidden;
          height: calc(100% - 58px);
          .preview-templete {
            overflow: auto;
          }
          .templete-edit {
            overflow: hidden;
          }
          .templete-edit,
          .preview-templete {
            @extend %previewTemplate;
          }
          .attFileList .attFileCard {
            flex: 0 0 212px !important;
            max-width: 212px !important;
          }
          .varibaleContainer {
            width: fit-content;
            bottom: 40px;
          }
        }
        .template-btn {
          position: relative;
          display: flex;
          padding: 1% 0 0;
          margin-top: 10px;
          text-align: right;
          justify-content: flex-end;
          .btnWidth {
            width: 140px;
            text-align: center;
          }
          button {
            @extend %btn-blue;
            display: unset;
            letter-spacing: 0.5px;
            font-size: 14px;
            width: auto !important;
            &.loading {
              @include wt-ht(36px !important, 36px !important);
              border: none !important;
              outline: none !important;
              background: $blue-violet !important;
              border-radius: 35px !important;
              min-width: unset !important;
              .load-text {
                @include wt-ht(26px !important, 26px !important);
              }
            }
            &.disableSentEmailBtn {
              background: $c-f5 !important;
              border: 1px solid $c-87 !important;
              color: $c-87 !important;
              user-select: none !important;
              pointer-events: none !important;
            }
          }
          .sendTestEmailTemplateBtn {
            @extend %primaryOutlineBtn;
            margin-right: 10px;
          }
        }
      }
    }
  }

  @include wt-ht(100%, 100%);
  .templateBodyWrapper {
    display: flex;
    transition: 0.5s;
    @include wt-ht(100%, 100%);
    padding: 12px;
  }
  .template-body {
    position: relative;
    @include wt-ht(100%, calc(100% - 58px));
    display: grid;
    grid-template-columns: 280px calc(100% - 280px);
    &.productDiscount {
      @include wt-ht(100%, calc(100% - 112px));
    }
    aside,
    #templateFolderSection {
      @include wt-ht(calc(100% - 10px), 100%);
      position: relative;
      display: block;
      overflow: hidden;
      border-radius: 10px;
      background-color: $white;
      .active {
        color: $blue-violet;
        background: $white;
        i {
          color: $c-66;
        }
        &:hover:after {
          transform: translate(20%, 0);
          will-change: transform;
        }
      }

      .active {
        background: $white;
        .template-folder-text {
          color: $blue-violet;
        }
        &:after {
          content: url("./../../assets/svg/rightArrowBlue.svg");
          width: 15px;
          position: absolute;
          right: 20px;
          padding-top: 2px;
          transition: all 200ms ease;
          height: 18px;
        }
      }
      %shared-folder-structure {
        position: relative;
        cursor: pointer;
        transition: all 0.2s ease;
        &:hover {
          background: #fafafa;
          transition: all 0.2s ease;
        }
        .template-folder-name {
          padding: 18px 10px;
          text-decoration: none;
          font-size: 14px;
          color: $c-66;
          width: 100%;
          border-bottom: 1px solid $b-c-1;
          @include flex-center();
          i {
            font-size: 18px;
            vertical-align: middle;
            margin-right: 10px;
          }
          .template-folder-text {
            width: calc(100% - 60px);
            @include ellipsis();
          }
          .wrapActionIcon {
            @include flex-center;
          }
        }
        &:hover {
          color: $c-33;
        }
      }
      %folder-loader {
        @include flex-center();
        background: $white;
        border-bottom: 1px solid $c-f5;
        padding: 18px 20px;
        transition: all 0.2s ease;
        .linear-background {
          display: block;
          height: 18px;
          border-radius: 6px;
          &.folder-icon {
            width: 18px;
            margin-right: 7px;
          }
          &.folder-name {
            width: calc(100% - 25px);
          }
        }
      }
      .default-template-folder-loader {
        @include bor-rad-top(10px, 10px);
        transition: all 0.2s ease;
        @include wt-ht(100%, 58px);
        // animation: cardSlideUp 1s ease-in;
        @extend %folder-loader;
      }
      %bor-bg-sticky-folder-header {
        @include bor-rad-top(10px, 10px);
        background: $white;
      }
      .default-template-folder {
        @extend %bor-bg-sticky-folder-header;
        @include wt-ht(100%, 55px);
        @include flex-center();
        @extend %shared-folder-structure;
        &.dropOver {
          opacity: 0.6;
          border-bottom: 1px solid $blue-violet;
          .template-folder-name {
            color: $blue-violet !important;
          }
        }
        .template-folder-name {
          padding: 18px 10px;
          @include bor-rad-top(10px, 10px);
        }
        &:hover {
          a {
            @include bor-rad-top(10px, 10px);
            background-color: #fafafa;
            &.active {
              background: $white;
            }
          }
        }
      }
      .template-folder {
        @include list-style-zero();
        background: $white;
        @include wt-ht(100%, calc(100% - 70px));
        overflow: auto;

        @extend %EditFolder;

        .create-new-template-folder {
          position: relative;
          z-index: 2;
          background-color: #fff8de;
          width: 100%;
          padding: 16px;
          border-bottom: 1px solid #fdf3d0;
          transition: all 0.2s ease-in-out;
          .create-folder {
            position: relative;
            @include flex-center();
            z-index: inherit;
            input {
              font-size: 16px;
              letter-spacing: 0.3px;
              background: transparent;
              width: calc(100% - 43px);
              outline: none;
              border: none;
              color: $c-78;
            }
            span {
              font-size: 13px;
              color: $blue-violet;
              cursor: pointer;
              margin-left: 5px;
            }
          }
          &:after {
            content: "";
            position: absolute;
            top: 0;
            z-index: 1;
            width: inherit;
            left: 0;
            height: 53px;
            box-shadow: 0px 0px 0px 0px;
            color: rgba(199, 152, 157, 0.6);
            animation: anim-shadow 0.3s forwards;
          }
        }
        .overlay-mask {
          @include bg-mask(1, transparent);
        }
        .template-folder-box {
          @extend %shared-folder-structure;
          &.dropOver {
            opacity: 0.6;
            border-bottom: 1px solid $blue-violet;
            .template-folder-name {
              color: $blue-violet !important;
            }
          }
          .template-folder-name {
            padding: 18px 10px;
            height: 55px;
          }
          &.hovered {
            .template-folder-text {
              width: calc(100% - 120px);
            }
          }
        }
        .template-folder-box-loader {
          transition: all 0.2s ease;
          @include flex-center();
          @extend %folder-loader;
          padding: 16px 20px;
        }
      }
    }
    main {
      @include wt-ht(100%, 100%);
      background: $white;
      overflow: hidden;
      border-radius: 10px;
      position: relative;
      .template-overview-section {
        @include wt-ht(100%, 100%);
        position: relative;

        .empty-templates {
          @extend %empty-section;
          .createBtn {
            position: relative;
            @include flex-center;
            border: 1px solid $blue-violet;
            border-radius: 5px;
            padding: 2px 20px;
            #newTemplate {
              background: white;
              color: $blue-violet;
              padding-left: 0;
              padding-right: 0;
              &:hover {
                background: white;
                color: $blue-violet;
              }
              svg {
                fill: $blue-violet;
              }
            }
            .freemiumIcon {
              margin-left: 10px;
              width: 26px;
              position: relative;
              .upgradeTooltipSection.btmRight {
                right: -46px;
              }
            }
          }
        }
        .template-folder-name {
          position: relative;
          @include flex-center();
          padding: 18px 10px;
          height: 58px;
          width: 100%;
          font-size: 16px;
          border-bottom: 1px solid $b-c-1;
          .linear-background {
            border-radius: 20px;
            padding: 10px;
            width: 150px;
          }
        }
        .template-table-section {
          position: relative;
          @include wt-ht(100%, 100%);
          @include flex-center();

          &.tempPageWithHt {
            height: calc(100% - 60px);
          }

          flex-direction: column;
          %table-alignment {
            position: relative;
            font-size: 14px;
            width: 100%;
            padding: 5px 20px;
            @include flex-center();
            flex-wrap: nowrap;
            border-bottom: 1px solid $tb-bor-btm;
            %pad-rel {
              padding: 10px;
              position: relative;
            }
            %linear-bg {
              .linear-background {
                border-radius: 6px;
                width: 90%;
              }
            }
            .template-name {
              @extend %pad-rel;
              width: 30%;
              display: inline-block;
              @extend %linear-bg;
              @extend %sharedBadge;
              .sharedBadge {
                margin-left: 5px;
              }
              .trigger {
                display: inline-block;
                .ownerCont {
                  .ownerBadge {
                    padding: 4px 5px;
                  }
                  .sequenceOwnerReadOnlyIcon {
                    padding: 4px 5px 4px 0px;
                  }
                }
              }
            }
            .template-subject {
              width: 35%;
              @extend %pad-rel;
              @extend %linear-bg;
              @include ellipsis();
            }
            .template-created {
              width: 20%;
              font-size: 14px;
              @extend %pad-rel;
              @extend %linear-bg;
            }
            .template-updated {
              width: 15%;
              font-size: 14px;
              @extend %pad-rel;
              @extend %linear-bg;
            }
            .template-options {
              position: relative;
              display: block;
              @extend %pad-rel;
              padding: 5px 10px;
              width: 8%;
              justify-content: flex-end;
              .dropdown-overlay {
                @include bg-mask(1, transparent);
              }
              .linear-background {
                @include wt-ht(30px, 30px);
                border-radius: 50%;
              }
            }
          }

          .template-table-header {
            background: $c-fa;
            font-weight: 500;
            height: 55px;
            padding: 9px 20px;
            color: $c-66;
            @extend %table-alignment;
            .linear-background {
              height: 14px;
            }
          }

          .template-table-body {
            position: relative;
            overflow-y: auto;
            @include wt-ht(100%, 100%);
            .nameInputcont {
              padding: 10px 5% 10px 5%;
              overflow: hidden;
              overflow-x: auto;

              &.linear-background {
                border-radius: 0px;
              }
            }

            .createTemplate {
              position: relative;
              z-index: 2;
              background-color: #fff8de;
              width: 100%;
              padding: 16px 30px;
              border-bottom: 1px solid #fdf3d0;
              transition: all 0.2s ease-in-out;
              .templateInputBox {
                position: relative;
                @include flex-center();
                z-index: inherit;
                input {
                  font-size: 16px;
                  letter-spacing: 0.3px;
                  background: transparent;
                  width: calc(100% - 43px);
                  outline: none;
                  border: none;
                  color: $c-78;
                }
                span {
                  font-size: 13px;
                  color: $blue-violet;
                  cursor: pointer;
                  margin-left: 5px;
                }
              }
              &:after {
                content: "";
                position: absolute;
                top: 0;
                z-index: 1;
                width: inherit;
                left: 0;
                height: 53px;
                box-shadow: 0px 0px 0px 0px;
                color: rgba(199, 152, 157, 0.6);
                animation: anim-shadow 0.3s forwards;
              }
            }
            .overlayMask {
              @include bg-mask(1, transparent);
            }
            .template-table-row-loader {
              height: 55px;
              padding: 7px 20px;
            }
            .template-table-row,
            .template-table-row-loader {
              font-size: 14px;
              width: 100%;
              @include flex-center();
              @extend %table-alignment;
              padding: 5px 20px;
            }
            .template-table-row:hover {
              background: $c-f8;
              .dragHolder {
                display: block !important;
              }
            }
            .template-table-row-loader {
              overflow: hidden;
            }
          }
        }
        .pagination-section {
          padding: 15px 0;
        }
      }
    }
  }
}
