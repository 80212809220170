// Create Event

%infoIcon {
  position: relative;
  top: 2px;
  width: fit-content;
  margin-left: 5px;
  i {
    color: $c-66;
    font-size: 13px;
    cursor: pointer;
  }
  .customTooltip {
    .sharpPointer {
      padding: 5px;
      right: 2px;
      top: -2px;
      border-right: 1px solid black;
      border-top: 1px solid black;
      background: black;
      border-top-right-radius: 2px;
    }
    .tooltipBody {
      width: 230px;
      min-width: unset;
      right: -100px;
      top: 3px;
      background-color: black;
      border-radius: 4px;
      .tooltipText {
        line-height: 1.3;
        font-size: 11px;
        padding: 6px;
        color: white;
        text-align: center;
      }
    }
  }
}

%labelTitle {
  .labelTitle {
    position: relative;
    @include flex-center;
    font-size: 14px;
    color: #343a40;
    margin-bottom: 20px;
    .workingHoursInfoIcon {
      @extend %infoIcon;
    }
  }
}

%createEventFormSection {
  @extend %relBlock;
  @include wt-ht(100%, calc(100% - 62px));
  background: white;
  .createEventInputCont {
    position: relative;
    @include wt-ht(100%, calc(100% - 54px));
    padding: 40px 30px 30px;
    overflow: auto;
    background: white;
    .fromInputSection {
      position: relative;
      .formInputBox {
        position: relative;
        margin-bottom: 30px;
        .inputWrapper {
          position: relative;
          @extend %inputWrapper;
          align-items: unset;
          margin-bottom: 0px;
        }
      }
    }

    .selectScheduleHours {
      display: grid;
      margin: 10px 0;
      grid-template-columns: calc(60% - 15px) 40%;
      column-gap: 15px;
      @media (max-width: 1200px) {
        grid-template-columns: 100%;
        column-gap: unset;
        row-gap: 40px;
      }

      .scheduleWorkingHour {
        @extend %relBlock;
        @extend %labelTitle;
        @extend %sendingWindowWrapper;
        .errMsgText {
          bottom: -25px;
          right: unset;
          left: 0;
        }
      }
      .quickSelectScheduleHour {
        @extend %relBlock;
        .labelTitle {
          position: relative;
          font-size: 15px;
          color: $c-3b;
          margin-bottom: 20px;
        }
        ul,
        .quickSelectScheduleHourList {
          position: relative;
          @include list-style-zero;
          margin-top: 20px;
          li,
          .scheduleHourOption {
            position: relative;
            @include wt-ht(fit-content, 36px);
            display: grid;
            grid-template-columns: 12px calc(100% - 20px);
            column-gap: 8px;
            border: 1px solid $c-dd;
            align-items: center;
            justify-content: space-between;
            background: white;
            padding: 0 10px;
            border-radius: 6px;
            transition: all 0.3s ease;
            .scheduleIcon,
            .scheduleIntervals {
              position: relative;
              color: $c-8d;
              font-size: 13px;
            }
            &:not(:last-child) {
              margin-bottom: 10px;
            }
            &.active {
              pointer-events: none;
              user-select: none;
              cursor: unset;
              background: transparent;
              border-color: $blue-violet;
              .scheduleIcon,
              .scheduleIntervals {
                color: $blue-violet;
              }
            }
            &:hover:not(.active) {
              cursor: pointer;
              background: $c-f5;
              border-color: $blue-violet;
              .scheduleIcon,
              .scheduleIntervals {
                color: $blue-violet;
              }
            }
          }
        }
      }
    }

    .bufferCont {
      @extend %labelTitle;
      .meetingBufferInfoIcon {
        @extend %infoIcon;
      }
      .startBeforeWrapper {
        .inputWrapper {
          position: relative;
          @extend %inputWrapper;
          align-items: unset;
          margin-bottom: 0px;
        }
        .durationDetails {
          position: relative;
          padding: 5px 0px;
          cursor: default;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          align-items: center;

          .startBuffDuration,
          .singleSelectDropdown {
            padding: 5px 5px 5px 0px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .createEventActionBtnGroup {
    position: relative;
    @include flex-center;
    justify-content: flex-end;
    border-top: 1px solid $c-ea;
    column-gap: 15px;
    height: 54px;
    padding: 0 30px;
    background: white;
    button {
      font-size: 14px;
      border-radius: 5px;
      letter-spacing: 0.3px;
      min-width: 120px;
      margin: unset;
      @include wt-ht(34px !important, 34px !important);
      &.createEventActionBtn {
        border: solid 1px $blue-violet;
        background: $blue-violet;
        &.disabledBtn {
          pointer-events: none;
          user-select: none;
          cursor: unset;
        }
      }
      &.cancelCreateEventBtn {
        border: solid 1px $blue-violet;
        color: $blue-violet;
        background: white;
      }
      &.loading {
        transition: all 0.3s ease-in-out;
        min-width: auto;
        border-radius: 35px;
        .load-text {
          @include wt-ht(24px, 24px);
          top: 4px;
          left: 4px;
        }
      }
    }
  }
}
