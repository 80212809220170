%autoImportTime {
  .timeCont {
    @include flex-center();
    position: relative;
    border-bottom: 1px solid $c-eb;
    width: 50px;
    border-collapse: collapse;
    .timeRange {
      border: 0;
      @include flex-center();
      color: $blue-violet;
      width: 35px;
      padding: 0;
      text-align: center;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  .drop-down-menu {
    min-width: max-content !important;
    max-width: 250px !important;
  }
  .quantity {
    @include flex-center();
    flex-direction: column;
    .lessButton,
    .moreButton {
      border: 0;
      background: transparent;
      margin: 0;
      padding: 0;
      @include wt-ht(20px, 100%);
      @include flex-center();
      justify-content: center;
      cursor: pointer;
      &:hover {
        background-color: $c-f5;
      }
    }
  }
  .expandLessIcon,
  .expandMoreIcon {
    font-size: 12px;
    color: $c-66;
  }
  .filterDropdownWrap {
    width: 200px;
    margin-left: 20px;
    .activeDropdownValue {
      height: 25px;
      .activeValue {
        color: $c-66;
      }
    }
  }
}

%salesForce {
  .activityTabWrapper {
    display: flex;
    flex-direction: column;
    .errMsg {
      position: absolute;
      bottom: -12px;
      color: red;
      font-size: 11px;
      left: 0px;
    }
  }
}

@mixin syncLog() {
  .contactStatWrapper {
    font-size: 14px;
    color: $c-3b;
    height: 100%;

    .contactStatContainer {
      display: grid;
      grid-template-columns: 27% 73%;
      border: 1px solid $c-dd;
      padding: 8px 7px;
      border-radius: 10px;
      min-height: 60px;

      .dateContainer {
        @include flex-center();
        margin-right: 30px;
      }

      .countWrapper {
        @include flex-center();

        .countCont {
          margin: 0px 20px 0px 0px;
          .count {
            min-width: 36px;
            min-height: 36px;
            padding: 10px 8px;
            border: 1px solid $c-00A358;
            color: $c-00A358;
            border-radius: 6px;
            display: inline-block;
            text-align: center;
            font-family: $font-medium;
          }

          .statName {
            padding-left: 6px;
          }
        }

        .updatedCont {
          .count {
            border-color: $c-F59064;
            color: $c-F59064;
          }
        }

        .failedCont {
          .count {
            border-color: $c-FF0000;
            color: $c-FF0000;
          }
        }
      }
    }

    .errorLogWrapper {
      height: calc(100% - 60px);
      overflow: auto;
      margin: 16px 0px;

      .log {
        padding: 6px 0px;
        font-size: 13px;
        color: $c-8d;
        width: 90%;
        display: grid;
        grid-template-columns: 15% 15% 25% 45%;

        * {
          width: 95%;
        }
      }
    }

    @media (min-width: 0) and (max-width: 1370px) {
      .contactStatContainer {
        .countCont {
          @include flex-align-path-justify(center, column, center);

          .count {
            margin-bottom: 3px;
          }

          .statName {
            padding: 0px !important;
            text-align: center;
          }
        }
      }

      .errorLogWrapper {
        .log {
          grid-template-columns: 15% 20% 25% 40%;
        }
      }
    }

    .loaderContainer {
      display: grid;
      grid-template-columns: 25% 75%;
      border: 1px solid $c-dd;
      padding: 8px 7px;
      border-radius: 10px;
      min-height: 60px;

      .dateContainer,
      .countWrapper {
        @include flex-center();
        margin-right: 0px;
        .loaderAnimation {
          width: 90%;
        }
      }
    }

    .errorLogLoader {
      .erroLogLoader {
        display: flex;

        .dateColumn {
          width: 23%;
          display: block;
          margin: 15px 0px;
        }

        .loaderAnimation {
          width: 21%;
          display: block;
          margin: 15px 0px 15px 25px;
        }
      }
    }
  }
}

%zoho,
%hubspot,
%pipedrive,
%salesForce {
  .configFooter {
    padding: 24px 0;
    position: fixed;
    bottom: 0;
  }
  .card {
    grid-gap: 16px;
    width: 100%;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.11);
  }
  .crmWrapper {
    width: 100%;
    height: 100%;
    border: 1px solid $c-ee;
    border-radius: 8px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.11);
    .tabFieldsWrapper {
      background-color: $c-fa;
      border-bottom: 1px solid $c-ee;
    }
    .transparentOverlay {
      z-index: 9 !important;
    }
    .autoImportSettings {
      width: 100%;
      position: relative;
      border-bottom: 1px dashed $c-de;
    }
    .ownersWrapper .tableView {
      border-radius: 8px;
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.11);
      border: solid 1px $c-f0;
    }
    .leadsWrapper,
    .contactsWrapper,
    .activityWrapper,
    .activityTabWrapper,
    .ownersWrapper,
    .syncLogWrapper {
      padding: 20px;
      height: calc(100% - 60px);
      overflow: auto;
      p.descText {
        font-size: 13px;
      }
      .variantToggleWrapper {
        @include flex-center();
        align-items: center;
        line-height: 26px;
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        .toggleText {
          font-size: 13px;
          margin-left: 10px;
        }
      }
      .activityCheckListWrapper {
        position: relative;
        width: 300px;
        .comingSoonBadge {
          left: 75px;
        }
      }
      .checkListBox {
        margin-top: 12px;
        font-size: 13px;
        .checkBoxName {
          font-size: 13px;
        }
      }
      .invalidMap {
        @include flex-center();
        i {
          color: $c-fb656;
          font-size: 18px;
          padding-left: 5px;
          cursor: pointer;
        }

        .customTooltip {
          .sharpPointer {
            border-color: $c-4543 !important;
            background-color: $c-4543;
            padding: 7px;
            top: 10px;
          }

          .tooltipBody {
            background-color: $c-4543;
            top: 17px;
            border-radius: 5px;
            left: -30px;

            .tooltipText {
              padding: 7px;
              color: $white;
              font-size: 11px;
            }
          }
        }
      }
      .filterHeader label {
        position: absolute;
        display: none;
        transition: unset;
      }
      .filterHeader .adjustLabel {
        display: block;
        top: 10px;
      }
      .sectionMarginBottom {
        margin-bottom: 20px;
      }
      .flexColumn {
        display: flex;
        flex-direction: column;
      }
      .autoImportLabel,
      .updateContactsCont {
        @include flex-center();
        margin-bottom: 10px;
        .headingText {
          margin-bottom: 0;
        }
        .toggleBtnSection {
          margin-left: 12px;
        }
      }
      .headingText {
        font-weight: bold;
        font-size: 13px;
        margin-bottom: 10px;
        color: $c-33;
      }
      .headingSubText {
        color: $c-66;
        font-size: 12px;
      }
      @extend %toggleSection;
      .autoImportTime {
        display: flex;
        align-items: flex-end;
        @extend %autoImportTime;
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
      .importNowWrapper {
        @include flex-center();
        margin-bottom: 10px;
        .importNowBtn.loading {
          border: none;
        }
        .resumeDateText {
          margin-left: 12px;
          color: red;
          font-size: 12px;
        }
        .load-text {
          border: 1.5px solid $blue-violet;
          border-bottom-color: $blue-violet;
          border-left-color: $blue-violet;
          border-left: 1px;
        }
        .importNowBtn {
          @extend %secondaryButton;
          font-family: "Graphik-Regular";
          margin: 0;
          &.disabled {
            border-color: $c-66;
            color: $c-66;
            opacity: 0.4;
            cursor: not-allowed;
          }
        }
        .viewHistoryBtn {
          color: $blue-violet;
          font-size: 12px;
          margin-left: 8px;
          cursor: pointer;
        }
        .lastSynced {
          margin-left: 12px;
          color: $c-66;
          font-size: 12px;
        }
      }
      .importNowCont {
        .customTooltip {
          .tooltipBody {
            background-color: $white;
            color: $c-66;
            right: -83px;
            width: fit-content;
          }
          .sharpPointer {
            top: 1px;
            right: 39px;
          }
          .tooltipText {
            padding: 10px;
          }
        }
      }
      .syncInProgress {
        color: $c-66;
        font-size: 12px;
        margin-bottom: 24px;
      }
    }
    .APILimitWrapper {
      margin-bottom: 20px;
      font-size: 13px;
      .globalAPIUsage .headingText {
        margin-bottom: 8px;
      }
      .headingSubText {
        margin-bottom: 8px;
      }
      .globalAPIUsageWrapper {
        margin-bottom: 10px;
      }
      .apiLimitInputBox {
        border: 0;
        border-bottom: 1px solid $c-dd;
        margin-right: 50px;
        color: $c-66;
      }
      .apiLimitInputBoxWrap {
        @include flex-center();
        margin: 24px 0;
        .inputBoxSection {
          position: relative;
          .errMsgText {
            left: 0;
          }
        }
      }
    }

    .syncLogWrapper {
      @include syncLog();
    }
  }

  .tabItem {
    margin: 0 24px;
    color: $c-33;
    font-size: 14px;
    width: 80px;
    border-bottom: 2px solid transparent;
    &.activeTab {
      border-bottom: 2px solid $blue-violet;
      font-weight: bold;
      color: $blue-violet;
    }
  }
  .autoImport {
    .activeDropdownValue,
    .listItem {
      text-transform: capitalize;
    }
  }
  .crmListView,
  .conditionDropdownCont {
    .dropdownHeader .title {
      white-space: nowrap;
    }
    .autoCompleteDropdown {
      width: unset;
      max-width: 200px;
      font-size: 12px;
      .searchInputBoxWrapper {
        input {
          font-size: 12px;
        }
      }
      .searchTextBox {
        margin: 4px 0;
      }
      .searchIcon {
        font-size: 16px;
      }
      .dropdownList {
        max-width: 250px;
        width: unset;
        .noResult {
          font-size: 12px;
        }
        .dropdownOptions {
          font-size: 12px;
          height: 32px;
          .updatedName {
            color: $c-3b;
            &.loading {
              @include flex-center;
              color: $c-8d;
            }
          }
        }
      }
    }
  }
  .crmListView,
  .autoImport,
  .updateContactsWrapper,
  .addTagsWrapper {
    display: grid;
    grid-template-columns: 37% calc(53% - 64px);
    grid-gap: 64px;
    width: 60%;
    &.disabled {
      pointer-events: none;
    }
    &.addTagsWrapper {
      width: 100%;
      grid-template-columns: 22% auto;
      .sectionTitle {
        display: none;
      }
    }
    .parentWrapper {
      display: grid;
      grid-template-columns: 35% auto;
      align-items: center;
      .sectionInputTagsCont {
        .multiSelectDropDown {
          z-index: 4;
          .multiSelectInput {
            margin: 0px;
            width: 100%;
            .downArrowBlack {
              right: 8px;
            }
            .inputCont {
              padding: 0;
              input {
                max-width: 300px;
              }
            }
          }
          .innerCont {
            .listCont {
              top: 54px;
              z-index: auto;
            }
          }
        }
      }
      .previewTagWrapper {
        @include previewTags();
        border-radius: 6px;
        max-height: 60px;
        overflow: hidden;
        transition: all 0.3s;
        .previewTagsCont {
          padding: 0px 10px 0px;
          margin: 0px;
          .selectedTag {
            padding: 2px 8px;
            .selecteTagValue {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  .crmListView,
  .autoImportTime {
    .filterDropdownWrap {
      flex: 1;
    }
    .filterDropdown {
      .adjustLabel,
      .listItem {
        font-size: 13px;
      }
      .activeDropdownValue .activeValue {
        font-size: 12px;
        color: $blue-violet !important;
        text-overflow: ellipsis;
        min-width: 100px;
        overflow: hidden;
      }
      .activeDropdownValue i {
        font-size: 18px;
        color: $c-66;
      }
    }
  }
  .pipedriveFieldMapping .crmField,
  .pipedriveFieldMapping .sgField {
    .dropdownPanel .dropdownList {
      max-height: 130px !important;
    }
    .positionTop .dropdownPanel {
      top: unset !important;
      bottom: 36px !important;
    }
  }
  .fieldMappingWrapper {
    padding-top: 20px;
    width: 100%;
    .fieldMappingTable {
      border-radius: 8px;
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.11);
      border: solid 1px $c-f0;

      table {
        border-collapse: collapse;
        font-size: 14px;
        table-layout: fixed;
      }
      .headingText {
        font-weight: bold;
        font-size: 13px;
        margin-bottom: 10px;
        color: $c-33;
      }
    }
  }
  .tableView {
    &.disabled {
      opacity: 0.75;
      pointer-events: none;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      height: 100%;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
      thead {
        @extend %textDark;
        border-bottom: solid 1px $c-f0;
        th.textDark {
          padding: 16px 30px;
          background-color: $c-fa;
          font-size: 13px;
          color: $c-33;
          font-weight: bold;
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid $c-f0;
          td {
            position: relative;
          }
          td.crmField,
          td.crmDetails {
            padding: 12px 30px;
            font-size: 12px;
            color: $c-33;
          }
          .deleteIcon {
            font-size: 16px;
            cursor: pointer;
            position: absolute;
            right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 10px;
            padding: 4px;
            &:hover {
              border-radius: 35px;
              background-color: $c-dd;
              @include wt-ht(24px, 24px);
              transition: all 0.2s ease-in;
            }
          }
          td.sgField,
          td.crmField,
          td.sgDetails,
          td.syncDirectionField {
            padding: 6px 30px;
            .dropdownHeader .title {
              white-space: nowrap;
            }
            .autoCompleteDropdown {
              width: unset;
              max-width: 200px;
              font-size: 12px;
              .searchInputBoxWrapper {
                input {
                  font-size: 12px;
                }
              }
              .searchTextBox {
                margin: 4px 0;
              }
              .searchIcon {
                font-size: 16px;
              }
              .dropdownList {
                max-width: 250px;
                width: unset;
                .noResult {
                  font-size: 12px;
                }
                .dropdownOptions {
                  font-size: 12px;
                  height: 32px;
                }
              }
            }
            .adjustLabel,
            .listItem {
              font-size: 13px;
            }
            .activeDropdownValue {
              height: 30px;
            }
            .activeDropdownValue .activeValue {
              font-size: 12px;
              color: $blue-violet;
              text-overflow: ellipsis;
              min-width: 100px;
              overflow: hidden;
              .selectedSyncDirectionIcon {
                margin-right: 12px;
                svg {
                  width: 12px;
                }
              }
            }
            .activeDropdownValue i {
              font-size: 18px;
              color: $c-66;
            }
          }
          td.crmDetailsLoader,
          td.sgDetailsLoader {
            @include wt-ht(200px, 40px);
            padding: 0 30px;
            .linear-background {
              display: block;
              width: 200px;
            }
          }
          td.sgDetailsLoader .linear-background {
            width: 300px;
          }
        }
      }
    }
  }
  .highlightContactExportTable {
    .drop-down-menu.animatedFast {
      z-index: 3 !important;
    }
    tbody tr,
    thead {
      border: 1px solid #ddd !important;
    }
    thead {
      .highlightContactExport {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
    }
    tbody {
      .highlightContactExport:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
  .highlightContactExport {
    background-color: white !important;
    z-index: 14 !important;
    position: relative;
  }

  .highlightContactExportOverlay {
    @include bg-mask(12, rgba(0, 0, 0, 0.5));
  }
  .contactConditionWrapper {
    margin-top: 10px;
    width: 60%;

    .verticalDotLine {
      position: absolute;
      border-left: 2px dotted #dee3ff;
      height: calc(100% - 90px);
      left: 25px;
      top: 26px;
    }
    .horizontalDotLine {
      position: absolute;
      border-bottom: 2px dotted #dee3ff;
      width: 15px;
      left: -25px;
      bottom: 30px;
    }
    .horizontalBorderLine {
      position: absolute;
      width: 4px;
      height: 75%;
      background: #dee3ff;
      border-radius: 5px;
      left: 0px;
      top: 8px;
    }
    .activeDropdownValue {
      margin-top: 0px;
      padding: 0;
      height: 36px;
    }
    .conditionWrapper {
      margin-bottom: 12px;
      margin-left: 12px;
      .inputBoxSection {
        width: 100%;
      }
      .contactInputBox {
        width: 100%;
        height: 36px;
        border: 0;
        border-bottom: 1px solid #ddd;
        flex: 1;
        background-color: transparent;
        color: $c-66 !important;
        &::placeholder {
          font-size: 12px;
        }
      }
    }
    .conditionDropdownWrapper {
      @include flex-center();
      position: relative;
      .conditionDropdownCont {
        align-items: center;
        width: calc(100% - 50px);
        margin-left: 12px;
        display: grid;
        grid-template-columns: 40% 10% 40% auto;
        grid-column-gap: 15px;
        column-gap: 15px;
        .operatorListWrapper {
          .conditionSymbol {
            font-size: 18px;
            border-bottom: 1px solid $c-dd;
            line-height: 36px;
            @include flex-center();
            justify-content: center;
          }
        }
        .drop-down-menu {
          min-width: fit-content !important;
        }
      }
      .deleteCondition {
        font-size: 16px;
        cursor: pointer;
      }
      .activeValue {
        font-size: 13px;
        color: $c-66;
      }
      .dropdownList .nameText {
        font-size: 12px;
      }
    }
    .conditionsFunc {
      display: flex;
      flex-direction: column;
      .conditionNotesSection {
        color: #adaaaa;
        font-size: 10px;
        margin: 10px 0;
        margin-left: 2px;
      }
      .addNewItem {
        background-color: transparent;
        padding: 0;
        i,
        .btnText {
          color: $blue-violet;
          padding: 0;
        }
        .btnText {
          font-size: 10px;
        }
      }
    }
  }
}
