$c-border: #eceff1;
$c-bg: #f9f9ff;

.googleOAuthPopupWrapper {
  @extend %popupCard;
  .card {
    flex-direction: column !important;
    padding: 20px;
    color: #343a40;
    .OAuthPopupHeader {
      @include flex-center;
      width: 100%;
      border-bottom: 1px solid $c-dd;
      padding-bottom: 20px;
      .imgType {
        margin-right: 10px;
        @include flex-center;
      }
      .connectTxt {
        height: 20px;
      }
      .OAuthPopupClose {
        position: absolute;
        right: 16px;
        cursor: pointer;
        top: 16px;
        color: $c-8d;
        font-size: 14px;
        padding: 3px;
        @include wt-ht(24px, 24px);
        @include flex-center;
        justify-content: center;
        &:hover {
          border-radius: 50%;
          background: $c-dd;
          color: $black;
          transition: all 0.2s ease-in;
        }
      }
    }
    .OAuthPopupBody {
      padding-top: 16px;
      font-size: 14px;
      .topWrapper {
        text-align: center;
        padding-bottom: 24px;
        border-bottom: 1px solid $c-dd;
        .salesgearLogoIcon {
          width: 140px;
          height: 32px;
          margin: auto;
          margin-bottom: 12px;
        }
        .salesgearAllowAccessContent {
          font-size: 14px;
          color: $c-3b;
          margin-bottom: 14px;
          a {
            color: $blue-violet;
          }
        }
        .emailContent {
          font-size: 11px;
          color: $c-3b;
          @include flex-center;
          justify-content: center;
          .emailInitials {
            @include flex-center;
            justify-content: center;
            @include wt-ht(26px, 26px);
            border-radius: 35px;
            border: 1px solid #34a853;
            background-color: #34a853;
            color: $white;
            text-transform: uppercase;
            font-size: 12px;
            margin-right: 12px;
          }
        }
      }
      .finalWrapper {
        padding: 20px 0;
        strong {
          font-size: 14px;
          a {
            color: $blue-violet;
          }
        }
        .content {
          line-height: 1.5;
          color: $c-3b;
          padding: 10px 0;
          font-size: 14px;
        }
        .supportMailLink {
          font-size: 14px;
        }
      }
      .initialScreenHeading {
        text-align: center;
        margin-bottom: 20px;
      }
      .chooseToConnectHeading {
        text-align: center;
        padding-bottom: 16px;
      }
      .initialScreenBody {
        padding-bottom: 16px;
        .copyContentWrapper {
          margin-top: 12px;
        }
        .inputBoxSection {
          position: relative;
          .errMsgText {
            right: unset;
            bottom: -16px !important;
          }
          label {
            color: #3b3b3b;
            font-size: 14px;
          }
          sup {
            color: #ff0000;
          }
          .wrapInputIcon {
            position: relative;
            @include flex-center;
            width: 100%;
            border: 1px solid $c-dd;
            margin-top: 6px;
            border-radius: 5px;
            padding-left: 10px;
            padding-right: 4px;
            margin-bottom: 16px;
            .material-icons-outlined {
              cursor: pointer;
              color: #cbd2ff;
              margin-left: 5px;
              font-size: 16px;
            }
            .inputBoxForAppPwd {
              border: 0;
              margin-top: 0;
              margin-bottom: 0;
              padding: 0;
            }
          }
        }
        .inputBoxForAppPwd {
          width: 100%;
          border: 1px solid $c-dd;
          border-radius: 5px;
          height: 32px;
          margin-top: 6px;
          margin-bottom: 16px;
          padding-left: 10px;
          &::placeholder {
            color: $c-8d;
            font-size: 11px;
          }
          &.emailInput {
            color: $c-8d;
          }
        }
        .chooseConnectWrapper {
          border: 1px solid $c-dd;
          border-radius: 6px;
          padding: 16px;
          margin-bottom: 8px;
          position: relative;
          cursor: pointer;
          .subLabelContent {
            color: $c-8d;
            font-size: 12px;
          }
          &.selected {
            border: 1px solid $blue-violet;
          }
          &.recommended {
            .recommendedText {
              position: absolute;
              top: -6px;
              right: 4px;
              background-color: $blue-violet;
              color: $white;
              font-size: 10px;
              border-radius: 10px;
              padding: 4px 8px;
            }
          }
          .radioButtonCont {
            margin-bottom: 6px;
            color: $c-3b;
          }
          .radioButtonCont .radioBtn:checked ~ label {
            color: inherit !important;
          }
        }
        .radioWrapper {
          @include flex-center;
          font-weight: bold;
          font-size: 14px;
        }
        .subLabelContent {
          font-size: 12px;
        }
      }
      .tutorialLink {
        @include flex-center;
        justify-content: center;
        .knowMoreBtnWrapper {
          background-color: #3953fb14;
          width: fit-content;
          padding: 0 12px;
          border-radius: 6px;
        }
      }
      ol {
        margin: 0;
        padding: 0 20px;
        margin-top: 12px;
        li {
          margin-bottom: 16px;
          .subLabelContent {
            @include flex-center;
            margin-top: 6px;
          }
          .blueSettings {
            color: $blue-violet;
            font-size: 18px;
            margin: 0 6px;
          }
          .hasLink {
            color: $blue-violet;
            text-decoration: underline;
          }
          .highlightBlue {
            color: $blue-violet;
          }
        }
      }
    }
    .oAuthFooter {
      border-top: 1px solid $c-dd;
      .actionBtns {
        @include flex-center;
        justify-content: center;
        padding-top: 16px;
      }
      .backBtn {
        border: 0;
        background-color: transparent;
        color: $c-8d;
        cursor: pointer;
        width: 100px;
        margin: 0;
      }
      .proceedBtn,
      .loginBtn {
        background-color: $blue-violet;
        border: 1px solid $blue-violet;
        cursor: pointer;
        color: $white;
        width: 100px;
        margin: 0;
      }
    }
  }
}
.googleOAuthPopupOverlay {
  @include bg-mask(99, rgba(0, 0, 0, 0.57));
}

.copyContentWrapper {
  .copyContentHeader {
    @include flex-center;
    justify-content: flex-end;
    padding-top: 8px;
    background-color: $white;
    @include bor-rad-top(10px, 10px);
    p {
      font-size: 14px;
      font-weight: 700;
    }
  }
  .copyContentBody {
    color: $c-8d;
    font-size: 12px;
    padding: 18px;
    line-height: 2;
    border-radius: 10px;
    border: 1px solid #eceff1;
    background-color: $c-bg;
    word-break: break-word;
    display: block;
    white-space: normal;
  }
  .copyButtonWrap {
    @include flex-center;
    border: 1px solid $c-dd;
    padding: 0px 8px;
    height: 26px;
    background-color: $white;
    color: $blue-violet;
    border-radius: 6px;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    width: 80px;
    justify-content: center;
    transition: 0.3s all ease;
    .copyButton {
      margin-right: 6px;
      font-size: 14px;
      color: $blue-violet;
    }
  }
  .copiedButtonWrap {
    @include flex-center;
    background-color: $blue-violet;
    color: $white;
    padding: 0px 8px;
    height: 26px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    width: 80px;
    justify-content: center;
    transition: 0.3s all ease;
    .copyButton {
      margin-right: 6px;
      font-size: 14px;
      color: $white;
    }
  }
}
