$data-font-size: 13px;

.dataSearchPage {
  .headerSection {
    padding: 12px !important;
  }
  .selectAll {
    .checkBoxSection {
      margin-left: 15px;
    }
  }
  .flexCenterWithJustifyContent {
    @include flex-center();
    justify-content: space-between;
    width: 100%;
    .clearAllIcon {
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: $c-55;
      }
    }
  }
  .commonFilledCheckBox {
    &.checked {
      border-color: $blue-violet !important;
      background: $blue-violet !important;
    }
    &:hover {
      border-color: $blue-violet !important;
    }
    .checkSvgIcon {
      background: $blue-violet !important;
      color: $white !important;
      box-shadow: 0 0 0 1px $blue-violet !important;
      svg path {
        stroke: $white !important;
      }
    }
  }
  .checkBoxName {
    flex: 1;
    .dot {
      position: relative;
      padding: 4px;
      display: inline-block;
      border-radius: 35px;
      left: 5px;
    }
  }

  .bodySection {
    @extend %body-layout;
    display: flex;
  }
  .dataSearchWrapper {
    display: grid;
    grid-template-columns: 300px calc(100% - 312px);
    grid-gap: 12px;
    justify-content: space-between;
    @include wt-ht(100%, 100%);
    .iconBackground {
      @include wt-ht(18px, 18px);
      border-radius: 3px;
      background-color: #f4f4f6;
      margin-right: 10px;
      @include flex-center();
      justify-content: center;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.cursorNotAllowed {
        cursor: not-allowed;
        opacity: 0.5;
      }
      .customTooltip {
        .sharpPointer {
          top: 14px;
          right: -1px;
          border: none;
          background: #393a3d;
        }
        .tooltipBody {
          background: #393a3d;
          border-radius: 5px;
          top: 22px;
          left: -60px;
          width: 120px;
          text-align: center;
          &.visibilityFix {
            width: 190px;
            left: -140px;
          }
        }
        .tooltipText {
          color: $white;
          padding: 8px;
        }
      }
    }

    .deleteIcon {
      width: 14px;
      position: relative;
    }
    .createSavedSearchPopupWrapper {
      @extend %popupCard;
      .popupHeader {
        padding-bottom: 15px;
        @include flex-align-path-justify(center, row, space-between);

        .title {
          text-align: left;
          color: $c-3b;
          font-size: 20px;
        }

        .commonPopupCloseIcon {
          font-size: 20px;
          padding: 3px;
          border-radius: 50%;
          color: $c-53;
          cursor: pointer;

          &:hover {
            color: #000;
            background-color: $c-dd;
          }
        }
      }
      hr {
        border: 0;
        border-bottom: 1px solid $bg-default;
        margin: 0;
        padding: 0;
        margin-bottom: 36px;
      }
      label {
        font-size: 12px;
      }
      .card {
        width: 400px;
        max-height: 700px;
        overflow: auto;
        text-align: center;
        padding: 20px;
        border-radius: 0px;
        .inputWrapper {
          @extend %inputWrapper;
          &.phoneNumber {
            margin-bottom: 20px;
          }
          .activeBorder {
            border-color: $blue-violet !important;
          }
        }
        .saveButton {
          @extend %primaryBlueBtn;
          width: 40%;
          border-radius: 5px;
        }
      }
    }
  }
  .dataSearchFilter {
    background: white;
    border: 1px solid $c-ec;
    border-radius: 6px;
    @include wt-ht(100%, 100%);
    min-height: 100%;
    .filterComponentWrapper {
      height: 100%;

      .filterComponentBody {
        height: calc(100% - 50px);
      }
    }
  }
  .dataSearchTable {
    @extend %dataSearchTable;
  }
}
.filterComponentInputHeader,
.filterComponentBodyHeader {
  .filterComponentTitle {
    font-size: 14px;
    color: $c-66;
    border: 0;
    width: 100%;
    &::placeholder {
      color: $c-66;
    }
  }
  .toggleActionSection {
    position: relative;
    margin-right: 5px;
    z-index: 1;
  }
}
.addRemoveValue {
  position: relative;
  @include flex-center();
  font-size: 10px;
  .horizontalBorderLine {
    position: absolute;
    width: 4px;
    height: 100%;
    background: #dee3ff;
    border-radius: 5px;
    left: 0px;
  }
}
.addRemoveValueWrapper {
  display: inline;
  margin-top: 8px;
  position: relative;
  margin-left: 4px;
  max-height: 60px;
  overflow: auto;
  .tagItem {
    border: 1px solid $c-c7;
    color: $blue-violet;
    background-color: $white;
    margin-bottom: 8px;
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    margin: 0 4px;
    margin-bottom: 6px;
    .selectedTag {
      font-size: 12px;
      margin-right: 8px;
    }
    .selectedTagCloseIcon {
      font-size: 14px;
      cursor: pointer;
      color: $c-9e;
      border-radius: 35px;
      &:hover {
        background: $c-eb;
      }
    }
  }
}

.filterComponentHeader {
  position: relative;
  @include flex-center;
  justify-content: space-between;
  padding: 16px 12px;
  border-bottom: 1px solid $c-ec;
  width: 100%;
  .filterComponentTitle {
    font-size: 14px;
    color: $c-66;
    border: 0;
  }

  .savedSearchSection {
    font-size: 14px;
    width: 75%;
    position: relative;
    display: flex;
    justify-content: space-between;
    color: $blue-violet;
    .seperator {
      color: grey;
      font-weight: bold;
    }
    .saveText {
      cursor: pointer;
    }
    .clearAllBtn {
      position: relative;
      top: 0px;
      @extend %relBlock;
      font-size: 14px;
      cursor: pointer;
      color: $blue-violet;
      &.disabled {
        @include disable(0.8, unset);
        color: rgba(57, 83, 251, 0.5);
      }
      .customTooltip {
        .sharpPointer {
          right: -1px;
          top: 14px;
          border-radius: 1px;
        }
        .tooltipBody {
          top: 20px;
          width: 70px;
          left: -42px;
          .tooltipBodyText {
            font-size: 12px/14px;
            padding: 0px;
          }
        }
      }
    }
  }
}
.filterCompContainer {
  position: relative;
  .filterCount {
    border-radius: 50%;
    @include wt-ht(20px, 20px);
    background-color: $blue-violet;
    color: $white;
    font-size: 10px;
    @include flex-center();
    justify-content: center;
    margin-right: 8px;
    line-height: 1;
  }
  &:last-child .filterComponentBodyHeader {
    border-bottom: 0;
  }
  .multiSelectDropdown {
    .dropdownSearchBox {
      padding: 0;
      .searchBox .searchInputList .inputBox {
        background-color: $white;
        border: 0;
      }
    }
  }
  .inputBoxSection input:focus::placeholder {
    opacity: 0.3;
  }
}
.dropdownListWrapper.industryMultiDropdown {
  max-height: 320px;
  .dropdownList {
    max-height: 280px;
  }
}
.dropdownListWrapper.contactTeamDropDown {
  max-height: 320px;
  .dropdownList {
    max-height: 125px;
  }
}

.overrideColor {
  background-color: blue;
}

.dropdownListWrapper.dashboard {
  position: relative;
  max-height: 320px;
  width: 200px;
  .dropdownList {
    max-height: 100px;
  }
}

.loaderAndErrorPositioning,
.emptyDropdown {
  position: relative;
  padding: 12px 0;
  text-align: center;
  font-size: 14px;
  background-color: $c-f5;
}

.filterComponentBody {
  padding: 8px;
  overflow: auto;
  .filterComponentBodyHeader {
    border-bottom: 1px solid $c-ec;
    cursor: pointer;
    @include flex-center();
    justify-content: space-between;
    position: relative;
    .filterComponentTitleWithWrapper {
      @include flex-center();
      justify-content: space-between;
      width: 100%;
      padding: 12px 0;
      .arrowIcon {
        font-size: 18px;
        color: $c-66;
      }
    }
    .maxValueReached {
      color: red;
      font-size: 10px;
      margin-left: 8px;
    }
    .flexColumn {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .filterComponentInputHeader {
      @include flex-center();
      justify-content: space-between;
      width: 100%;
      span {
        font-size: 18px;
      }
      .inputBoxSection {
        width: 100%;
        padding: 12px 0;
      }
    }
    .filterComponentBodyTitle {
      font-size: 14px;
      color: $c-66;
      border: 0;
    }
  }
  @extend %toggleSection;
  .toggleText {
    display: none;
  }
}

.customAccordion {
  width: 100%;
  .accordionContent {
    background: $white;
    width: 100%;
    overflow: hidden;
    position: relative;
    .horizontalBorderLine {
      position: absolute;
      width: 4px;
      height: 100%;
      background: #dee3ff;
      border-radius: 5px;
      left: 0px;
    }
  }
  .customAccordionItem {
    @include flex-center();
    flex-direction: column;
    .customAccordionTitle {
      cursor: pointer;
      @include flex-center();
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 14px;
      color: $c-66;
    }
    .arrowIcon {
      font-size: 18px;
      color: $c-66;
    }
    .drop-down-menu {
      width: 100%;
      max-width: 100%;
      top: 15px;
    }
  }
}

.exactMatch {
  position: relative;
  @include flex-center();
  margin-top: 6px;
  font-size: 10px;
  margin-bottom: 12px;
  .horizontalBorderLine {
    position: absolute;
    width: 4px;
    height: 100%;
    background: #dee3ff;
    border-radius: 5px;
    left: 0px;
  }
  .checkboxWrapper {
    margin-left: 10px;
  }
  .checkBoxName {
    font-size: 12px;
  }
}
.customAutoCompleteDropdownWrapper {
  .drop-down-menu {
    top: -3px !important;
    left: 6px;
  }
}

.autoCompleteDropdownListWrapper {
  position: relative;
  width: calc(100% - 6px);
  background: white;
  height: 100%;
  max-height: 240px;
  margin-top: 8px;
  margin-left: 12px;
  .companyDomainWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .companyName {
      display: flex;
      font-size: 14px;
    }
    .primaryDomain {
      display: flex;
      font-size: 12px;
    }
  }
  .dropdownList {
    max-height: 200px;
    overflow: auto;
  }
  .searchTextBox {
    width: 100%;
    input {
      width: 100%;
      padding: 0;
      border-radius: 0;
      z-index: 3;
    }
    .searchIcon {
      font-size: 18px;
      right: 0;
      @include wt-ht(auto, auto);
      opacity: 0.2;
      cursor: unset;
      &:hover {
        background: unset;
      }
    }
  }
  .searchInputBtn {
    border-bottom: 1px solid $c-dd;
    height: 32px;
    z-index: 5;
  }
}

.dropdownListWrapper {
  border: 1px solid $c-dd;
  border-radius: 5px;
  position: absolute;
  width: 280px;
  z-index: 5;
  background: white;
  border-radius: 5px;
  border: 1px solid $c-ec;
  max-height: 240px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  left: -6px;
  &.keywords {
    bottom: 70px;
  }
  &.customBtn {
    bottom: 40px;
  }
  .addCustomItem {
    font-size: 14px;
    padding: 10px;
    background: $c-f9;
    .customName {
      color: $blue-violet;
    }
  }
  .searchInputBtn {
    width: 100%;
    border-bottom: 1px solid $c-eb;
    .searchIcon {
      flex: 0 0 24px;
      font-size: 20px;
      margin-left: 4px;
    }
    .searchTextBox {
      flex: 1;
      margin: 4px;
    }
    .searchInputBoxWrapper {
      width: 100%;
      @include flex-center();
      input {
        width: 100%;
        border-radius: 4px;
        padding: 4px 8px;
        line-height: unset;
      }
    }
  }
  .dropdownList {
    max-height: 200px;
    overflow: auto;
    .rightArrowIcon {
      font-size: 16px;
      cursor: pointer;
    }
    &.subMenu {
      margin-left: 30px;
      max-height: 100% !important;
      overflow: unset;
    }
  }
  .applySelectionBtn {
    @extend %primaryBlueBtn;
    cursor: pointer;
    width: 100%;
    position: absolute;
    left: 0;
    height: 32px;
    border-collapse: collapse;
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    &.disabled {
      opacity: 1;
      background-color: #929ff1;
    }
  }
}
.dropdownOverlay {
  @include bg-mask(2, transparent);
}

// Contact table UI

%dataSearchTable {
  @include wt-ht(100%, 100%);
  min-height: 100%;
  .customTooltip {
    .sharpPointer {
      background: #393a3d !important;
      border-right: 1px solid #393a3d !important;
      border-top: 1px solid #393a3d !important;
    }
    .tooltipBody {
      background: #393a3d !important;
    }
  }
  .contactTableWrapper {
    @include wt-ht(100%, 100%);
    display: flex;
    flex-direction: column;
    .searchTableHeader {
      @extend %searchTableHeader;
    }
    .searchTableBody {
      @extend %searchTableBody;
      &.isFromSeoPage {
        height: fit-content;
        .contactBody {
          overflow: unset;
        }
      }
    }
  }
}

%searchTableHeader {
  position: relative;
  height: 35px;
  margin: 0px 0px 10px 0px;
  @include flex-align-path-justify(center, row, space-between);
  &.noTableSearchData {
    display: none;
  }
  .contactSelectedCount,
  .contactsFound {
    position: relative;
    font-size: $data-font-size;
    align-self: flex-end;
    bottom: 3px;
  }
  .actions {
    @include flex-center();
    justify-content: flex-end;
    .pageLimit {
      margin: 0px;
      .text {
        font-size: $data-font-size;
      }
      .wrapIconValue {
        @include wt-ht(fit-content, 36px);
        padding: 10px;
        line-height: 1;

        .value {
          font-size: $data-font-size;
          margin-right: 10px;
          width: unset;
        }

        .arrow {
          width: auto;
          svg {
            width: 9px;
            height: 9px;
          }
        }
      }

      .limitDropdown {
        width: 50px;

        .limitValue {
          font-size: $data-font-size;
        }
      }
    }

    .customListWrapper {
      margin-left: 10px;
      .autoCompleteDropdown {
        width: unset;

        .dropdownHeader {
          width: 100%;
          height: unset;
          padding: 0;
          background: transparent;
          border: 0;
        }

        .dropdownPanel {
          right: 0;
        }
      }
    }

    .addToListBtn {
      position: relative;
      border-radius: 5px;
      border: 1px solid $b-c-1;
      background: $white;
      @include wt-ht(36px, 36px);
      @include flex-center();
      justify-content: center;
      cursor: not-allowed;
      &:hover {
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
      }
      i {
        font-size: 22px;
        color: $c-9e;
      }
      .customTooltip {
        .sharpPointer {
          top: 18px;
          right: 2px;
          border-top-right-radius: 0px;
        }
        .tooltipBody {
          border-radius: 5px;
          width: 84px;
          top: 26px;
          right: -20px;
        }
        .tooltipText {
          color: $white;
          padding: 8px;
        }
      }
      &.enabled {
        cursor: pointer;
        i {
          color: $blue-violet;
        }

        &:hover {
          box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
        }
      }
    }
    @include addToSequenceButton();
    .addToSequenceFeatureBtn {
      margin-left: 10px;
    }
    .exportBtnWrapper,
    .addToSequenceFeatureBtn {
      .customTooltip {
        .sharpPointer {
          top: 18px;
          right: 2px;
          border-top-right-radius: 0px;
        }
        .tooltipBody {
          border-radius: 5px;
          width: 120px;
          top: 26px;
          right: -20px;
        }
        .tooltipText {
          color: $white;
          padding: 8px;
        }
      }
    }

    .bulkPurchaseBtn {
      position: relative;
      margin-left: 10px;
      border-radius: 5px;
      border: 1px solid $b-c-1;
      background: $white;
      @include wt-ht(36px, 36px);
      @include flex-center;
      justify-content: center;
      color: $blue-violet;
      cursor: not-allowed;

      .icon {
        @include wt-ht(18px, 18px);
        fill: $c-9e;
      }

      &.enabled {
        cursor: pointer;
        @extend %enabledBtn;
        &:hover {
          box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
        }
      }

      .drop-down-menu {
        right: 0 !important;
        top: 36px !important;
        .freemiumIcon {
          svg path {
            fill: white !important;
          }
        }
      }
      .customTooltip {
        .sharpPointer {
          top: 18px;
          right: -2px;
          border-top-right-radius: 0px;
        }
        .tooltipBody {
          background: #393a3d;
          border-radius: 5px;
          width: 130px;
          top: 26px;
          right: -46px;
        }
        .tooltipText {
          color: $white;
          padding: 8px;
        }
      }
    }
  }
}

.iconDescriptionWrapper {
  position: relative;
  @include flex-center();
  @include wt-ht(100%, 100%);
  justify-content: center;
  .exportIconWithDesc,
  .addToListIconWithDesc,
  .addToSeqWithDesc {
    @include flex-center();
    margin: 0 4px;
    .descText {
      font-size: 12px;
      color: #261d56;
    }
    .arrow {
      position: relative;
      @include flex-center();
    }
    .upLine {
      height: 30px;
      background: $blue-violet;
      opacity: 0.8;
      width: 1.5px;
    }
    .upPoint {
      position: absolute;
      content: "";
      display: inline-block;
      top: 0px;
      width: 8px;
      height: 8px;
      border-left: 2px solid $blue-violet;
      border-bottom: 2px solid $blue-violet;
      opacity: 0.8;
      transform: rotate(135deg);
    }
    .line {
      width: 30px;
      background: $blue-violet;
      opacity: 0.8;
      height: 1.5px;
    }
    .rightPoint {
      position: absolute;
      content: "";
      display: inline-block;
      right: 0px;
      width: 8px;
      height: 8px;
      border-left: 2px solid $blue-violet;
      border-top: 2px solid $blue-violet;
      opacity: 0.8;
      transform: rotate(135deg);
    }
    .leftPoint {
      position: absolute;
      content: "";
      display: inline-block;
      left: 0px;
      width: 8px;
      height: 8px;
      border-right: 2px solid $blue-violet;
      border-bottom: 2px solid $blue-violet;
      opacity: 0.8;
      transform: rotate(135deg);
    }
  }
  .addToSeqWithDesc {
    position: relative;
    flex-direction: column;
    .descText {
      position: absolute;
      top: -64px;
      width: 500px;
      text-align: center;
    }
    .arrow {
      position: absolute;
      top: -30px;
      flex-direction: column;
    }
  }
  .exportIconWithDesc {
    margin-left: 0;
  }
  .addToListIconWithDesc {
    margin-right: 0;
  }
  .exportIconWrapper,
  .addToListWrapper,
  .addToSequenceWrapper {
    @include wt-ht(24px, 24px);
    background-color: $blue-violet;
    opacity: 0.8;
    border-radius: 6px;
    @include flex-center();
    justify-content: center;
    color: white;
  }
  .addToListIcon {
    font-size: 18px;
    color: $white;
  }
  .addToSequenceWrapper {
    svg {
      @include wt-ht(16px, 16px);
      path {
        fill: $white;
        stroke: $white;
      }
    }
  }
  .exportIcon {
    @include wt-ht(16px, 16px);
    path {
      fill: $white;
    }
  }
}

%searchTableBody {
  @include wt-ht(100%, calc(100% - 45px));
  border: 1px solid $c-ec;
  border-radius: 6px;
  background-color: $white;
  &.noTableSearchData {
    height: 100%;
  }
  .contactBody {
    transition: all 0.3s;
    @include wt-ht(100%, 100%);
    padding: 0 15px;
    overflow-y: auto;

    .loadMoreContactsWrapper {
      @include flex-center();
      justify-content: center;
      padding: 12px 0;
      &.applyOpacity {
        opacity: 0.1;
        pointer-events: none;
      }
      .loadMoreContacts {
        font-size: 14px;
        color: $blue-violet;
        cursor: pointer;
        opacity: 0.7;
      }
    }

    .tableHeader {
      position: sticky;
      top: 0;
      z-index: 1;
      width: 100%;
      display: grid;
      grid-template-columns: 30px 15% repeat(3, calc(23% - 10px)) 16%;
      align-items: center;
      font-size: $data-font-size;
      color: #666;
      padding: 15px 0;
      border-bottom: 1px solid $b-c-1;
      background: white;
      .ctSelectAll {
        .checkBoxSection {
          padding: 0;
        }
      }
    }

    .tableContent {
      @include wt-ht(100%, calc(100% - 41px));

      .showBtn {
        color: $blue-violet;
        font-size: 12px;
        cursor: pointer;
      }

      .contactTableLoader {
        width: 100%;
        display: grid;
        grid-template-columns: 30px 15% repeat(3, calc(23% - 10px)) 16%;
        .dataContainer {
          position: relative;
          padding: 0px;
        }
      }

      .contact {
        border-bottom: 1px solid $b-c-1;
        padding: 13px 0px;
        width: 100%;
        display: grid;
        grid-template-columns: 30px 15% repeat(3, calc(23% - 10px)) 16%;

        .ctSelectAll {
          @include flex-center();
          width: 30px;

          .checkBoxSection {
            padding: 0;
          }

          .linear-background {
            @include wt-ht(15px, 15px);
            border-radius: 5px;
          }
        }

        .dataContainer {
          width: calc(100% - 15px);
          gap: 6px;
          @include flex-align-path-justify(flex-start, column, center);

          .socialCont {
            @include flex-center();
            span {
              line-height: 1;
              cursor: pointer;
              height: 14px;
              svg {
                @include wt-ht(14px, 14px);
                fill: $c-66;
              }

              &.disabled {
                color: $c-78;
                opacity: 0.3;
                cursor: default !important;
                svg {
                  fill: $c-78;
                }
              }

              .websiteIcon {
                width: 13px;
              }

              .phoneIcon {
                width: 12px;
              }
            }

            .customTooltip {
              .sharpPointer {
                top: -40px;
                right: 0px;
              }
              .tooltipBody {
                width: fit-content;
                right: -10px;
                white-space: nowrap;
                background: #393a3d;
                border-radius: 5px;
                right: -33px;
                top: -58px;
                .tooltipText {
                  color: $white;
                  padding: 8px;
                }
              }
            }
          }
          &.emailCont {
            position: relative;
            text-align: center;
            .clickHereToViewWrapper {
              .arrow {
                position: relative;
                @include flex-center();
                position: absolute;
                top: 56px;
                color: $blue-violet;
                width: 118px;
                justify-content: center;
              }
              .upLine {
                height: 20px;
                background: $blue-violet;
                opacity: 0.8;
                width: 1.5px;
              }
              .upPoint {
                position: absolute;
                content: "";
                display: inline-block;
                top: 0px;
                width: 8px;
                height: 8px;
                border-left: 2px solid $blue-violet;
                border-bottom: 2px solid $blue-violet;
                opacity: 0.8;
                transform: rotate(135deg);
              }
              .clickHereToView {
                position: absolute;
                top: 82px;
                color: #261d56;
                font-size: 12px;
                width: 134px;
              }
            }
          }

          &.mobileCont,
          &.emailCont {
            position: relative;
            @include flex-align-path-justify(flex-start, column, center);

            .emailWrapper {
              position: relative;
              width: calc(100% - 10px) !important;
              @include flex-center();
              .darkText {
                width: auto;
              }
              .trigger {
                cursor: pointer;
              }
              .dot {
                position: relative;
                display: inline-block;
                border-radius: 35px;
                @include wt-ht(7px, 7px);
                margin-right: 15px;
                left: 10px;
                @include flex-center();
                cursor: pointer;
              }
              .customTooltip {
                .sharpPointer {
                  top: -24px;
                  border-top-right-radius: 35px;
                }
                .tooltipBody {
                  border-radius: 5px;
                  top: -44px;
                  min-width: 180px;
                  max-width: 200px;
                  .tooltipText {
                    text-align: center;
                    margin: 0 auto;
                    padding: 0 5px;
                  }
                }
              }
              &.emailTooltip {
                .customTooltip {
                  position: absolute;
                  .sharpPointer {
                    left: 42px;
                    right: unset;
                  }
                  .tooltipBody {
                    width: 100px;
                    min-width: unset;
                    left: 0;
                    right: unset;
                  }
                }
              }
            }
          }
          .customTooltip {
            .tooltipBody {
              box-shadow: unset;
              right: -83px;
              top: 12px;
              width: 180px;
              height: 30px;
              @include flex-center();
              .tooltipText {
                color: $white;
              }
              .sharpPointer {
                z-index: 0;
                top: 7px;
                right: 0;
              }
            }
          }
          .darkText {
            cursor: pointer;
          }
          .darkText,
          .lightText {
            color: $c-78;
            font-size: $data-font-size;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: $font-medium;
            font-weight: 500;
            width: 100%;
          }

          .lightText {
            font-weight: 400;
            width: 100%;
            font-family: $font-regular;
          }

          .maskData {
            @include wt-ht(100%, 100%);
            @include flex-align-path-justify(flex-start, column, center);

            .maskText {
              font-size: $data-font-size;
              color: $c-66;
              opacity: 0.6;
              cursor: pointer;
              border-bottom: 2px dotted $blue-violet;
              padding-bottom: 3px;
              width: fit-content;
            }
          }
        }

        .notFoundText {
          height: 100%;
          @include flex-center();
          opacity: 0.5;
          font-size: $data-font-size;
          color: $c-78;
          opacity: 0.3;
        }

        &:last-child {
          border-bottom: 0px;
        }
      }

      .noContactFound {
        height: 100%;
        @include flex-align-path-justify(center, column, center);
      }
    }
  }

  .noFilters {
    position: relative;
    @include wt-ht(100%, 100%);
    position: relative;
    @include flex-align-path-justify(center, column, center);

    .wrapLottieText {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 30px;
      .discoverImageContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        .recordTableLoader {
          @include wt-ht(15%, auto);
        }
      }

      .lottieText {
        position: relative;
        width: 100%;
        align-self: center;
        padding-bottom: 15px;
        display: grid;
        grid-template-rows: auto;
        align-items: center;
        row-gap: 10px;

        .mainTextContainer {
          display: flex;
          justify-content: center;
          text-align: center;

          .text {
            font-size: 24px;
            position: relative;
            max-width: 1000px;
            text-align: center;
            word-break: break-word;
            font-weight: 800;
            color: #333333;
          }
        }
        .subTextContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          padding: 0 20%;
          .subText {
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 15px;
            color: #333333;
          }
        }
        .startSearching {
          display: flex;
          justify-content: center;
          padding: 15px 20% 0;
          .searchNowParentContainer {
            background-color: #3953fb;
            color: #ffffff;
            border-radius: 14px;
            &:hover {
              cursor: pointer;
            }
            .searchNow {
              background-color: #3953fb;
              color: #ffffff;
              padding: 20px;
              width: 256px;
              height: 52px;
              font-size: 18px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: none;
              border-radius: 14px;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
        .quickDemo {
          display: flex;
          justify-content: center;
          padding: 15px 20% 0;
          .watchVideoParentContainer {
            background-color: #ffffff;
            color: #3953fb;
            border-radius: 14px;
            display: flex;
            flex-direction: row;
            width: 328px;
            height: 38px;
            border: 3px solid #e0e3e6;
            padding: 15px;
            gap: 10px;

            &:hover {
              cursor: pointer;
            }

            .playButtonParentContainer {
              flex: 0 0 10%;
              display: flex;

              justify-content: center;
              align-items: center;
              background-color: #fff;
              color: #3953fb;
            }

            .watchVideo {
              flex: 0 0 90%;
              color: #3953fb;
              font-size: 16px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              border: none;
              border-radius: 14px;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  &.adjustPaginationSpace {
    .contactBody {
      height: calc(100% - 51px);
      transition: all 0.3s;
    }
  }

  .mobileCont {
    &.showCont {
      display: flex !important;
      flex-direction: row !important;
      grid-gap: 20px;
      align-items: center !important;
      justify-content: space-between !important;
      .darkText {
        width: fit-content !important;
      }
    }
    .maskText {
      white-space: nowrap;
    }
    .customTooltip {
      position: absolute;
      .sharpPointer {
        bottom: 20px;
        left: 40px;
        border-top-right-radius: 35px;
        top: unset;
      }
      .tooltipBody {
        border-radius: 5px;
        width: 100px !important;
        min-width: unset;
        bottom: 26px !important;
        left: 0px !important;
        right: unset !important;
        top: unset !important;
        justify-content: center;
        font-weight: inherit;
        .tooltipText {
          position: relative;
          color: $white;
          font-weight: inherit;
          font-family: $font-regular;
        }
      }
    }
  }
}
