.buyNewNumberPopup {
  height: 100%;

  .newNumberPopupWrapper {
    height: 400px;
    border-radius: 10px;
    background: #fff;
    min-width: 350px !important;
    padding: 25px;

    .newNumberCont {
      width: 100%;
      height: 100%;

      .popupHeader {
        @include flex-center();

        .popupTitle {
          color: $c-53;
          font-size: 16px;
          width: 80%;
          display: inline-block;
          font-weight: 600;
        }
      }

      .popupBody {
        padding-top: 25px;
        height: 100%;

        .avaiableNumbers {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          height: calc(100% - 145px);
          position: relative;

          .noNumbersResult {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            color: $c-66;
            font-size: 12px;

            .noNumbers {
              width: 85%;
              text-align: center;
            }
          }

          .radioCont {
            padding: 5px 0px;
            font-size: 13px;

            label {
              color: $c-33;
            }
          }
        }

        .actionBtn {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          width: calc(100% - 50px);
          position: absolute;
          bottom: 25px;
          flex-direction: column;

          .btnCont {
            @include flex-direction(row);
            justify-content: space-evenly;
            width: 100%;

            .btn {
              height: 33px;

              &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
            }

            .backCont {
              background-color: $white;
              border: solid 1px $tb-bor-btm;
              color: $blue-violet;
            }
          }

          .chargeText {
            color: $c-66;
            font-size: 11px;
            padding-top: 10px;
          }
        }
      }
    }

    .inputWrapper {
      margin-bottom: 15px;
      @extend %inputWrapper;

      .inputLabel {
        font-size: 11px !important;
      }
    }
  }
}
