.onboardingRightSelectionMainContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .getStartedWrapper {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 2;

    .remainingCount {
      background-color: red;
      color: white;
      border-radius: 50%;
      padding: 5px 10px;
      font-size: 12px;
      font-weight: bold;
    }
  }
}
