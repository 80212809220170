.deletecustomUrl {
  height: 100%;

  .deleteCustomUrlPopupWrapper {
    height: 350px;
    min-width: 350px !important;
    border-radius: 10px;
    background: #fff;
    padding: 25px;

    .deleteImgcont {
      @include flex-center();
      justify-content: center;
      padding-top: 30px;
    }

    .deleteHeader {
      @include flex-center();
      justify-content: center;
      padding-top: 25px;
      font-size: 20px;
      font-family: $font-medium;
      line-height: 0.71;
      color: $c-53;
    }

    .deleteDesc {
      font-size: 14px;
      color: $c-78;
      padding: 20px 0 30px;
      text-align: center;
      line-height: 1.42;
      letter-spacing: normal;
    }

    @extend %actionBtnWrapper;
  }
}
