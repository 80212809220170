.toggleBtnSection {
  font-family: $font-regular;
  color: $c-78;
  font-size: 13px;
  @include flex-direction(row);
  .toggleText {
    &.rightPad {
      padding-right: 8px;
    }
    &.leftPad {
      padding-left: 8px;
    }
    &.activeToogle {
      font-weight: 600;
      color: $blue-violet;
      -webkit-font-smoothing: antialiased;
      + .offerBadge {
        color: $green;
        font-weight: bold;
        background-color: $fade-light-green;
      }
    }
  }
  .offerBadge {
    background: $c-f5;
    color: $c-66;
    font-size: 11px;
    height: 18px;
    @include flex-center();
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
    margin-left: 10px;
  }
  .tooltipWrap {
    position: relative;
    i {
      cursor: pointer;
      font-size: 14px;
      position: relative;
      top: 2px;
      left: 5px;
    }
    .customTooltip {
      position: relative;
      .sharpPointer {
        position: absolute;
        border-top-right-radius: 2px;
        padding: 4px;
        border-right: 1px solid $c-fb;
        border-top: 1px solid $c-fb;
        z-index: 4;
        top: -25px;
        right: -2px;
        transform: rotate(135deg);
        background: white;
      }
      .tooltipBody {
        right: -80px;
        border: 1px solid $c-fb;
        top: -88px;
        z-index: 3;
        width: 180px;
        height: auto;
        box-shadow: 0 0 12.1px 0.9px rgb(139 139 139 / 26%);
        .tooltipText {
          padding: 8px;
          font-size: 12px;
        }
      }
    }
  }
}
