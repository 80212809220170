.domainHealthCenterWrapper {
  @extend %relBlock;
  height: 100%;
  width: 100%;
  .domainHealthCenterCont {
    position: relative;
    display: flex;
    flex-direction: column;
    @include wt-ht(100%, 100%);
    .headerCont {
      @include flex-center;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid $c-ec;
      padding: 16px 24px;
      .titleHeader {
        position: relative;
        display: block;
        font-size: 16px;
        font-family: $font-regular;
        color: $c-66;
        font-weight: 400;
      }
      .emailCont {
        @include flex-center;
        .microsoftIcon,
        .smtpIcon,
        .googleIcon {
          margin-right: 5px;
          width: 22px;
          height: 22px;
        }
        .emailTxt {
          color: $c-343a40;
        }
      }
    }
  }
  .searchAndFilterWrapper {
    @include flex-center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .layoutSection {
    display: grid;
    grid-template-columns: auto minmax(310px, 346px);
    @include wt-ht(100%, calc(100% - 54px));
    padding: 16px 24px;
    grid-gap: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    @media (max-width: 1200px) {
      grid-template-columns: 100%;
    }
    .leftLayout {
      position: relative;
      height: 100%;
      overflow: hidden;
      @media (max-width: 1200px) {
        overflow: unset;
      }
      @include searchInputByKeyword();
      .inboxListSection {
        position: relative;
        height: calc(100% - 54px);
        border: 1px solid $c-dd;
        border-radius: 6px;
        @media (max-width: 1200px) {
          height: unset;
        }
      }
      .searchWrapper .searchInput:focus-within {
        input,
        svg {
          color: $c-66 !important;
          fill: $c-66 !important;
        }
      }
      .inboxListHeader,
      .inboxListBody .inboxItem {
        display: grid;
        grid-template-columns: auto 20% 20% 20% 40px;
        font-size: 14px;
        align-items: center;
        line-height: initial;
      }
      .inboxListHeader {
        position: sticky;
        top: -16px;
        background-color: $c-f9f9;
        border-bottom: 1px solid $c-dd;
        @include bor-rad-top(6px, 6px);
        color: $c-3b;
        padding: 12px;
      }
      .inboxListBody {
        &:not(:last-child) {
          border-bottom: 1px solid $c-dd;
        }
        @include bor-rad-btm(6px, 6px);
        height: calc(100% - 44px);
        overflow-y: auto;
        @media (max-width: 1200px) {
          height: unset !important;
        }
        .inboxItem {
          padding: 12px;
          border-bottom: 1px solid $c-dd;
          cursor: pointer;
          &:last-child {
            border-bottom: 0;
          }
        }
        .noInboxFound {
          padding: 12px;
          text-align: center;
          font-size: 14px;
        }
        .inboxName {
          color: $blue-violet;
          @include ellipsis;
          &.linear-background {
            width: 80%;
          }
        }
        .authentication {
          width: 75px;
          height: 24px;
          font-size: 12px;
          border-radius: 32px;
          cursor: pointer;
          @include flex-center;
          justify-content: center;
          &.pending {
            color: $c-f59064;
            background-color: $c-f5906429;
            border: 1px solid $c-f59064;
          }
          &.completed {
            color: $c-00a358;
            background-color: $c-00a35829;
            border: 1px solid $c-00a358;
          }
        }
        .profilePicture,
        .profileName {
          align-self: end;
        }
        .profileName,
        .profilePicture,
        .refreshIcon {
          &.linear-background,
          .linear-background {
            border-radius: 35px;
            @include wt-ht(20px, 20px);
          }
        }
        .errorColor {
          color: #ff9e10;
          font-size: 16px;
        }
        .doneColor {
          color: #28ad56;
          font-size: 16px;
        }
        .refreshIcon {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .hasPagination {
        .pagination-section {
          border-top: 1px solid unset;
          @include bor-rad-btm(6px, 6px);
        }
        .inboxListPageAndLimit {
          position: relative;
          @include flex-center;
          justify-content: flex-end;
          padding: 0px 30px;
          width: 100%;
          .pagination-section {
            border-top: 0;
          }
          .pageLimit {
            .limitDropdown {
              top: auto;
              bottom: 40px;
            }
          }
        }
        .inboxListBody {
          height: calc(100% - 95px);
          @include bor-rad-btm(0, 0);
          @media (max-width: 1200px) {
            height: unset !important;
          }
        }
      }
      .profilePicture,
      .profileName,
      .authentication {
        text-align: center;
        margin: 0 auto;
      }
    }

    .rightLayout {
      position: relative;
      display: flex;
      flex-direction: column;
      .domainHealthScoreWrapper,
      .customTrackingCont,
      .emailTestCont {
        margin-bottom: 10px;
        @media (max-width: 1200px) {
          margin-bottom: 16px;
        }
        h4 {
          color: $c-343a40;
          font-size: 16px;
          font-weight: 700;
        }
      }
      .customTrackingCont {
        position: relative;
        border: 1px solid $c-dd;
        border-radius: 10px;
        padding: 20px;
        .customTrakingHeader {
          position: relative;
          @include flex-center;
          justify-content: space-between;
        }
        .configureCustomLink {
          @include flex-center;
          margin-top: 15px;
          .customLink {
            position: relative;
            margin-left: 10px;
            text-decoration: underline;
            color: $blue-violet;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
      .emailTestCont {
        position: relative;
        border: 1px solid $c-dd;
        border-radius: 10px;
        padding: 20px;
        .emailTestHeader {
          @include flex-center;
          justify-content: space-between;
          .cmgSnText {
            font-size: 14px;
            color: $blue-violet;
            opacity: 0.8;
          }
        }
      }
    }
  }

  .detailedDomainHealthInfo {
    @include wt-ht(100%, calc(100% - 40px));
    position: relative;
    display: grid;
    grid-template-columns: 60% auto;
    padding: 16px 24px;
    .leftSection {
      width: 100%;
      height: 100%;
      .statusUpdateAndDeliverability {
        position: relative;
        overflow-y: auto;
        height: calc(100% - 80px);

        .dnsInfoBody {
          position: relative;
          &:first-child .headerContent {
            padding: 0;
            padding-bottom: 20px;
          }
          .headerContent {
            @include flex-center;
            flex-direction: row;
            justify-content: normal;
            color: $c-343a40;
            padding: 20px 0;
            border-bottom: 0;
            .headerTitle {
              font-size: 16px;
            }
          }
        }
      }
      .backButtonCont {
        position: absolute;
        bottom: 0;
        @include wt-ht(100%, 80px);
        @include flex-center;
        justify-content: flex-start;
        padding: 10px;
        background-color: $white;
        .backButton {
          position: relative;
          margin: unset;
          background-color: $white;
          width: 120px;
          color: $blue-violet;
          border: 1px solid $blue-violet;
        }
      }
    }
    .rightSection {
      position: relative;
      display: block;
      height: 100%;
      overflow-y: auto;
      .keyInfoCont {
        position: relative;
        display: block;
        .keyInfoHeader {
          position: relative;
          @include flex-center;
          justify-content: space-between;
          padding-bottom: 16px;
          border-bottom: 1px solid $c-ec;
          font-size: 16px;
          color: $c-343a40;
        }
        .keyInfoBody {
          position: relative;
          list-style: none;
          padding: 10px;
          .keyInfoPoint {
            display: grid;
            grid-template-columns: 7% auto;
            margin-bottom: 20px;
            font-size: 13px;
            line-height: 1.8;
            svg {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
.searchAndFilterWrapper {
  .userFilterDropDown {
    position: relative;
    margin-left: 10px;

    .userFilterBtn {
      position: relative;
      @include flex-align-center();
      outline: none;
      background: white;
      border-radius: 3px;
      border: 1px solid $b-c-1;
      padding: 0 10px;
      @include wt-ht(200px, 36px);
      font-size: 15px;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        background: $c-fb;
        transition: all 0.3s ease;
      }

      .icon {
        margin-right: 10px;
        @include wt-ht(14px, 14px);

        svg {
          fill: $blue-violet;
          @include wt-ht(14px, 14px);
        }
      }

      .UpgradeTooltip {
        .sharpPointer {
          right: 29px;
        }
      }

      .name {
        position: relative;
        color: $blue-violet;
        min-width: 100px;
        max-width: 110px;
        text-align: left;
        font-size: 15px;
        top: 2px;
        line-height: initial;

        .shortName {
          text-transform: uppercase;

          &:not(:last-child)::after {
            content: ", ";
          }

          &.teamNameSelected {
            text-transform: unset;
            @include ellipsis;
          }
        }
      }

      .hiddenCount {
        font-size: 13px;
        color: $c-78;
        min-width: 30px;
        line-height: initial;
        position: relative;
        top: 1px;
      }

      .arrow {
        margin-left: 5px;
        margin-top: 3px;
        @include flex-center();
        transition: all 0.3s ease;

        svg {
          fill: $c-53;
          @include wt-ht(11px, 11px);
        }

        &.activeArrow {
          transform: rotate(-180deg);
          transition: all 0.3s ease;
        }
      }
    }

    @extend %userListDropdown;

    .overlayUserFilterMask {
      @include bg-mask(4, transparent);
    }
  }
}
.domainHealthScoreWrapper {
  border: 1px solid $c-dd;
  padding: 16px;
  border-radius: 10px;
  .noInboxFound {
    font-size: 13px;
  }
  .domainHealthList {
    display: grid;
    grid-template-columns: calc(60% - 16px) 40%;
    grid-gap: 16px;
    &.loading {
      justify-content: flex-start !important;
    }
    &.noGrid {
      display: block;
    }
  }
  .domainHealthHeading {
    color: $c-343a40;
    font-size: 16px;
    padding-bottom: 20px;
    font-weight: 700;
  }
  .domainHealth {
    @include flex-center;
    justify-content: space-between;
    padding-bottom: 16px;
    font-size: 13px;
  }
  .domainHealthLabel {
    padding-right: 12px;
    &.linear-background {
      width: 140px;
      margin-right: 12px;
    }
  }
  .domainHealthValue {
    font-weight: 700;
    &.linear-background {
      width: 40px;
    }
    &.green {
      color: #28ad56;
    }
    &.blue {
      color: $blue-violet;
    }
    &.yellow {
      color: #ff9e10;
    }
    &.red {
      color: #ff0000;
    }
  }
  .scoreLoader {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    @include flex-align-center;
    font-size: 22px;
    color: $c-3b;
    margin: 0 auto;
    &.linear-background {
      border-radius: 50%;
    }
  }
}

.statusInfo {
  width: 80px;
  height: 24px;
  font-size: 12px;
  border-radius: 32px;
  @include flex-center;
  justify-content: center;
  margin-left: 10px;
  .count {
    margin-right: 5px;
  }
  &.pending {
    color: $c-f59064;
    background-color: $c-f5906429;
    border: 1px solid $c-f59064;
  }
  &.completed {
    color: $c-00a358;
    background-color: $c-00a35829;
    border: 1px solid $c-00a358;
  }
}

.noInboxConnectedWrapper {
  padding: 24px 12px;
  font-size: 13px;
  color: $c-3b;
  line-height: 1.5;
  .connectEmailAccounts {
    color: $blue-violet;
    cursor: pointer;
  }
}

.noInboxConnectedWrapperDS {
  font-size: 13px;
  color: $c-3b;
  line-height: 1.5;
  .connectEmailAccounts {
    color: $blue-violet;
    cursor: pointer;
  }
}
