.accountSocialLinkCont {
  position: relative;
  @include flex-align-center;
  gap: 4px;
  @include list-style-zero;
  .socialLinkItem {
    position: relative;
    &:hover:not(.disabled) {
      transition: all 0.3s ease;
      background: $c-ec;
      border-radius: 35px;
    }
    .websiteIcon,
    .twitterIcon {
      position: relative;
      @include flex-align-center;
      cursor: pointer;
      border-radius: 35px;
      padding: 3px;
    }
    .websiteIcon {
      font-size: 16px;
      transform: rotate(-45deg);
      color: #0a66c2;
    }
    .twitterIcon {
      @include wt-ht(21px, 21px);
      path {
        fill: #0a66c2 !important;
      }
    }
    .linkedin {
      position: relative;
      @include flex-align-center;
      cursor: pointer;
      border-radius: 35px;
      padding: 3px;
      @include wt-ht(22px !important, 22px !important);
      .linkedinIcon {
        @include wt-ht(13px !important, 13px !important);
        path {
          fill: #0a66c2 !important;
        }
      }
    }
    &.disabled {
      @include disable(0.5, unset);
      i {
        color: #a0a0a0 !important;
      }
      svg path,
      .linkedin path {
        fill: #a0a0a0 !important;
      }
    }
  }
  &.small .socialLinkItem {
    .websiteIcon,
    .twitterIcon {
      padding: 1px;
    }
    .websiteIcon {
      font-size: 15px;
    }
    .twitterIcon {
      @include wt-ht(15px, 15px);
    }
    .linkedin {
      padding: 2px;
      @include wt-ht(16px !important, 16px !important);
      .linkedinIcon {
        @include wt-ht(11px !important, 11px !important);
      }
    }
  }
}
