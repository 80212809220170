%contentSpace {
  padding: 14px 30px;
  position: relative;
}
%hideLabel {
  .filterDropdown {
    label {
      position: absolute;
      display: none;
      transition: unset;
      &.adjustLabel {
        top: 10px;
        display: block;
        transition: unset;
      }
    }
  }
}
%disableInput {
  cursor: not-allowed;
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
}
.triggersSection {
  @extend %relBlock;
  @include wt-ht(100%, 100%);
  background: white;
  .overviewTab {
    @extend %relBlock;
    @include wt-ht(100%, 100%);
    .overviewHeader {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 55px;
      padding: 0 30px;
      border-bottom: 1px solid $c-ec;
      h6 {
        position: relative;
        display: block;
        font-size: 16px;
        font-family: $font-regular;
        color: $c-66;
        font-weight: 400;
      }
      .flexEnd {
        @include flex-center;
        justify-content: flex-end;
      }
      button {
        font-size: 14px;
        padding: 10px 0;
        border-radius: 5px;
        letter-spacing: 0.3px;
        min-width: 120px;
        margin: unset;
        font-weight: 500;
        &.addTrigger {
          border: solid 1px $blue-violet;
          background: $blue-violet;
          &:hover {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
          }
        }
      }
    }
    .overviewTableSection {
      @extend %relBlock;
      height: 100%;
      padding: 15px 30px 0;
      background: white;
      height: calc(100% - 65px);
      .featureDemoContentCard {
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      .overviewCardList {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding-bottom: 8px;
        &.showPagination {
          height: calc(100% - 41px);
        }
        .overviewCard,
        .overviewCardLoader {
          position: relative;
          cursor: pointer;
          display: grid;
          align-items: center;
          grid-template-columns: 60px auto 140px 60px 60px;
          border-radius: 5px;
          border: 1px solid $c-ec;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
          background: white;
          padding: 20px;
          &:hover {
            border: 1px solid #f5f8fd;
            background: #f5f8fd;
          }
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          .toggleBtnSection {
            position: relative;
            .toogleSequence .toogleButton {
              &.loading {
                .knobs::before {
                  animation: spin 1s linear infinite;
                  border: 1.5px solid #3953fb;
                  border-bottom-color: #3953fb;
                  border-left-color: #3953fb;
                  border-left: 1px;
                  background-color: transparent;
                }

                .toggleCheckbox:checked + .knobs:before {
                  background-color: transparent;
                }
              }
              @include wt-ht(28px, 14px);
              .knobs::before {
                @include wt-ht(10px, 10px);
                top: 2px;
                padding: unset;
              }
              .toggleCheckbox:checked {
                & ~ .layer {
                  background: #fff;
                  border: 1px solid #3953fb;
                }
                & + .knobs:before {
                  left: 15px;
                  background: #3953fb;
                }
              }
              .layer {
                background: #fff;
                border: 1px solid $c-d3;
              }
              .knobs:before {
                background: $c-a7;
              }
            }
            &.linear-background {
              @include wt-ht(34px, 16px);
            }
          }
          .name {
            font-size: 14px;
            color: #3b3b3b;
            font-family: "Graphik-Medium";
            @include ellipsis;
            &.linear-background {
              @include wt-ht(240px, 14px);
            }
          }
          .subText {
            font-size: 12px;
            width: 475px;
            line-height: 15px;
            font-family: $font-regular;
            color: #8d8d8d;
            &.linear-background {
              @include wt-ht(300px, 14px);
            }
          }
          .date {
            font-size: 14px;
            &.linear-background {
              @include wt-ht(100px, 14px);
            }
          }
          .contactOwner {
            position: relative;
            .circleOwner {
              @include flex-center;
              justify-content: center;
              @include wt-ht(36px, 36px);
              border-radius: 35px;
              padding-top: 2px;
              border: 1px solid $blue-violet;
              color: $blue-violet;
              text-transform: uppercase;
              font-size: 12px;
            }
            &.linear-background {
              @include wt-ht(30px, 30px);
            }
          }
          &:first-child .moreOptionSlide {
            .sharpPointer {
              top: 12px;
              border-bottom-left-radius: 5px;
              border-top-right-radius: unset;
            }
            .tooltipBody {
              top: 16px;
            }
          }
          .moreOptionSlide {
            &.linear-background {
              margin-left: auto;
              @include wt-ht(24px, 24px);
            }
          }
        }
        .toggleTrigger,
        .triggerTitle {
          height: 100%;
        }
      }
      .emptyTrigger {
        @extend %empty-section;
        height: calc(100% - 70px);
        .createBtn {
          position: relative;
          @include flex-center;
          border: 1px solid $blue-violet;
          border-radius: 5px;
          padding: 2px 10px;
          #newTriggerBtn {
            background: white;
            color: $blue-violet;
            padding-left: 0;
            padding-right: 0;
            &:hover {
              background: white;
              color: $blue-violet;
            }
          }
          .freemiumIcon {
            margin-left: 10px;
            width: 26px;
            position: relative;
            .upgradeTooltipSection.btmRight {
              right: -46px;
            }
          }
        }
      }
    }
  }
  .createEditTrigger {
    @extend %relBlock;
    height: 100%;
    background: white;
    .textTitle {
      position: relative;
      @include flex-center;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      color: $c-44;
      padding: 0 30px;
      border-bottom: 1px solid $c-ec;
      height: 55px;
    }
    .formInputFields {
      @extend %relBlock;
      padding: 30px;
      height: calc(100% - 131px);
      overflow: auto;
      .triggerName {
        position: relative;
        margin-bottom: 20px;
        .inputWrapper {
          @extend %inputWrapper;
          height: 40px;
          margin-bottom: 0px;
          .inputCont {
            width: 300px;
            .inputBox {
              width: 300px;
            }
            .focusBorder {
              width: 300px;
              border-bottom: 1px solid $c-dd;
            }
          }
        }
      }
      .filterDropdownWrap,
      .triggerConditions,
      .triggerActions {
        position: relative;
        .errMsgText {
          @extend %errMsgText;
        }
      }
      .filterDropdown {
        margin-bottom: 15px;
        width: 300px;
        label {
          position: relative;
          font-size: 11px;
          top: 0;
          display: block;
          margin-bottom: 2px;
          &.adjustLabel {
            display: block;
            margin-bottom: unset;
            top: 22px;
            font-size: 13px;
            transition: all 0.3s;
          }
        }
        .activeValue {
          padding-top: 0;
          position: relative;
          color: $blue-violet;
          @include ellipsis;
          width: calc(100% - 16px);
        }
        .activeDropdownValue {
          margin-top: 0px;
          padding: 0;
          height: 36px;
        }
      }
      .TriggerProspect {
        position: relative;
        @include flex-center;
        padding: 1.25rem 0 0.5rem;
        .checkboxWrapper {
          height: 16px;
        }
        .wrapInfoIconTp {
          position: relative;
          @include flex-center;
          margin-left: 10px;
          .infoIcon {
            font-size: 18px;
            color: $c-44;
            cursor: pointer;
            border-radius: 35px;
          }
          .customTooltip {
            display: none;
          }
          &:hover .customTooltip {
            position: absolute;
            display: block;
            font-family: $font-regular;
            .sharpPointer {
              border: unset;
              background: #393a3d;
              border-top-right-radius: 18px;
              top: unset;
              bottom: 10px;
              left: 3px;
              right: unset;
              padding: 6px;
            }
            .tooltipBody {
              background: #393a3d;
              border-radius: 5px;
              width: 220px;
              top: unset;
              bottom: 16px;
              right: unset;
              left: -100px;
              .tooltipText {
                text-align: center;
                font-size: 12px;
                color: $white;
                @include flex-center;
                justify-content: center;
                padding: 10px;
              }
            }
          }
        }
      }
      .websiteTrackingNotEnabled {
        @include flex-center();
        gap: 10px;

        .svgIcon {
          @include wt-ht(24px, 24px);
          margin-right: 10px;
          cursor: pointer;

          svg {
            @include wt-ht(24px, 24px);
          }

          &.crossIcon {
            svg {
              fill: transparent;
              stroke: $c-f59064;
            }
          }
        }
        &:hover .customTooltip {
          position: absolute;
          display: block;
          font-family: $font-regular;
          .sharpPointer {
            border: unset;
            background: #393a3d;
            border-top-right-radius: 18px;
            top: unset;
            bottom: 30px;
            left: 6px;
            right: unset;
            padding: 6px;
          }
          .tooltipBody {
            background: #393a3d;
            border-radius: 5px;
            width: 220px;
            top: unset;
            bottom: 36px;
            right: unset;
            left: -100px;
            .tooltipText {
              text-align: center;
              font-size: 12px;
              color: $white;
              display: block;
              padding: 10px;
              span {
                text-decoration: underline;
                color: #f59064;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .formInputBtnAction {
      @extend %contentSpace;
      @include flex-center;
      justify-content: flex-start;
      border-top: 1px solid $c-ea;
      column-gap: 15px;
      button {
        font-size: 14px;
        padding: 10px 0;
        border-radius: 8px;
        letter-spacing: 0.3px;
        min-width: 120px;
        margin: unset;
        &.saveTrigger,
        &.updateTrigger {
          border: solid 1px $blue-violet;
          background: $blue-violet;
          &.disabledBtn {
            pointer-events: none;
            user-select: none;
            cursor: unset;
          }
        }
        &.triggerBackBtn {
          border: solid 1px $blue-violet;
          color: $blue-violet;
          background: white;
        }
        &.loading {
          @include wt-ht(34px !important, 34px !important);
          transition: all 0.3s ease-in-out;
          min-width: auto;
          border-radius: 35px;
          .load-text {
            @include wt-ht(24px, 24px);
            top: 4px;
            left: 4px;
          }
        }
      }
    }
  }
}

.labelText {
  position: relative;
  @include flex-center;
  margin-bottom: 8px;
  svg {
    @include wt-ht(24px, 24px);
    margin-right: 5px;
  }
  .titleText {
    font-weight: 500;
    font-size: 13px;
    color: $c-66;
    padding-top: 2px;
  }
  .labelErr {
    position: relative;
    display: flex;
    font-size: 11px;
    color: red;
    align-self: end;
    margin-left: 15px;
  }
}

.addNewItem {
  position: relative;
  @include flex-center;
  transition: all 0.3s;
  background: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 8px;
  font-size: 12px;
  width: fit-content;
  font-weight: 600;
  &.addItemUpdateContact {
    margin-left: 30px;
    margin-bottom: 10px;
  }
  i {
    font-size: 14px;
    margin-right: 5px;
    color: $c-44;
  }
  .btnText {
    padding-top: 2px;
    color: $c-44;
  }
  &:hover {
    background: $c-f5;
  }
}

.triggerActions {
  position: relative;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid $c-ec;
  .triggerActionCard {
    position: relative;
    border-radius: 5px;
    border: solid 1px $c-ec;
    padding: 20px;
    max-width: 790px;
    overflow-x: auto;
    .triggerActionList {
      position: relative;
      margin-left: 10px;
      .newActionItem {
        position: relative;
        display: grid;
        grid-template-columns: 260px 300px auto;
        align-items: center;
        column-gap: 15px;
        justify-content: flex-start;
        &.twoGridItem {
          grid-template-columns: 240px 0 auto;
          margin-bottom: 0;
          grid-column-gap: 8px;
          .filterDropdown {
            margin: 0 !important;
          }
          .horizontalBorderLine {
            height: 100%;
          }
        }
        &.disableInput,
        #disableInput {
          @extend %disableInput;
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        .horizontalBorderLine {
          position: absolute;
          width: 4px;
          height: 75%;
          background: #dee3ff;
          border-radius: 5px;
          left: -10px;
          top: 0px;
        }
        @extend %hideLabel;
        .actionType {
          .filterDropdown {
            width: 240px;
          }
        }
        .taskType {
          position: relative;
          margin-bottom: 14px;
          .taskTypeValue {
            color: $blue-violet;
            border-bottom: 1px solid $c-dd;
            cursor: pointer;
            height: 36px;
            display: flex;
            align-items: center;
            font-size: 15px;
            .labelValue {
              position: relative;
              font-size: 13px;
              color: $c-66;
              padding-top: 2px;
            }
          }
        }
        .slackNotConnected {
          width: 100%;
          @include flex-center();
          gap: 10px;

          .slackImage {
            height: 18px;
            width: 18px;
            object-fit: contain;
          }

          &:hover .customTooltip {
            position: absolute;
            display: block;
            font-family: $font-regular;
            .sharpPointer {
              border: unset;
              background: #393a3d;
              left: 4px;
            }
            .tooltipBody {
              background: #393a3d;
              border-radius: 5px;
              width: 220px;
              .tooltipText {
                text-align: center;
                font-size: 12px;
                color: $white;
                display: block;
                padding: 10px;
                span {
                  text-decoration: underline;
                  color: $c-F59064;
                  cursor: pointer;
                }
              }
            }
          }

          .slackValue {
            border: 1px solid $c-8d;
            border-radius: 5px;
            background-color: $white;
            cursor: pointer;
            padding: 8px 16px;
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            .labelValue {
              position: relative;
              font-size: 14px;
              color: $c-3b;
              padding-top: 2px;
              font-weight: 600;
            }
          }
        }

        .sendSlackNotificationPopup {
          position: relative;
          margin-bottom: 14px;
          .slackValue {
            color: $blue-violet;
            border-bottom: 1px solid $c-dd;
            cursor: pointer;
            height: 36px;
            @include flex-center();
            font-size: 15px;
            .labelValue {
              position: relative;
              font-size: 13px;
              color: $c-66;
              padding-top: 2px;
              color: $blue-violet;
            }
          }
        }
      }
      .updateContactsActionWrapper {
        position: relative;
        .verticalDotLine {
          top: -11px;
          height: calc(100% - 55px);
        }
        @extend %hideLabel;
        .filterDropdown {
          width: 220px !important;
        }
      }
    }
  }
  .labelErr {
    top: -2px;
  }
}

.deleteTrigger {
  @extend %deleteIcon;
  top: -5px;
}

.logicalOperator {
  position: relative;
  @include flex-center;
  justify-content: center;
  border-radius: 3px;
  border: solid 1px $c-ec;
  background: white;
  width: fit-content;
  padding: 4px 5px;
  margin-bottom: 10px;
  width: 50px;
  .operator {
    @extend %relBlock;
    padding: 0 5px;
    cursor: pointer;
    &.active {
      border-radius: 5px;
      border-bottom: #dee3ff;
      color: $blue-violet;
      cursor: unset;
      pointer-events: none;
      user-select: none;
      .text {
        color: $blue-violet;
      }
    }
    .text {
      color: $c-be;
      font-size: 11px;
    }
    .activeLine {
      height: 3px;
      border-radius: 8px;
      background: #dee3ff;
    }
  }
}

.triggerConditions {
  position: relative;
  margin-top: 20px;
  .labelText .titleText {
    padding-top: 8px;
  }
  .condtionsBox {
    position: relative;
    border-radius: 5px;
    border: solid 1px $c-ec;
    padding: 15px 20px;
    max-width: 790px;
    .conditionsList {
      position: relative;
      width: 100%;
      .conditionsInputBox {
        position: relative;
        width: 100%;

        @extend %hideLabel;
        .conditionsFields,
        .triggerInputFieldValue {
          .filterDropdown {
            width: 220px;
          }
        }
        .operatorList {
          position: relative;
          &.disableInput {
            @extend %disableInput;
          }
          .filterDropdown {
            width: 64px;
            .activeValue {
              left: 20px;
            }
            .drop-down-menu {
              width: unset !important;
              ul li {
                padding: 4px 10px;
                .conditionSymbol {
                  font-size: 20px;
                  top: 2px;
                  position: relative;
                }
              }
            }
            .conditionSymbol {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
.verticalDotLine {
  position: absolute;
  border-left: 2px dotted #dee3ff;
  height: calc(100% - 90px);
  left: 25px;
  top: 26px;
}
.conditionsInputRow {
  position: relative;
  display: grid;
  grid-template-columns: 220px 80px 220px auto;
  column-gap: 15px;
  width: calc(100% - 50px);
  align-items: center;
  justify-content: flex-start;
  margin-left: 50px;
  .horizontalDotLine {
    position: absolute;
    border-bottom: 2px dotted #dee3ff;
    width: 15px;
    left: -25px;
    bottom: 30px;
  }
  .horizontalBorderLine {
    position: absolute;
    width: 4px;
    height: 75%;
    background: #dee3ff;
    border-radius: 5px;
    left: -10px;
    top: 0px;
  }
}

.selectedSeqWrapper,
.selectedTagWrapper {
  .itemName {
    @extend %sharedBadge;
    display: flex !important;
    align-items: center;
    .nameText {
      @include ellipsis();
    }
    .trigger {
      width: fit-content;
      .ownerCont {
        width: fit-content;
      }
    }
  }
}

.triggerInputFieldValue {
  position: relative;
  .emailAutoCompleteDropdown .drop-down-menu {
    top: 36px;
  }
  &.disableInput {
    @extend %disableInput;
  }
  .filterDropdown {
    width: 100%;
  }
  .inputWrapper {
    @extend %inputWrapper;
    margin-bottom: 18px;
    .inputCont {
      width: 100%;
      .inputBox {
        width: 100%;
        height: 36px;
        &.filled ~ label,
        &:focus ~ label {
          display: none;
          transition: unset;
        }
      }
      .focusBorder {
        width: 100%;
        border-bottom: 1px solid $c-dd;
      }
    }
  }
}
.updateContactsActionItem {
  .conditionsInputRow {
    grid-template-columns: 220px 250px auto;
  }
  .inputWrapper {
    @extend %inputWrapper;
    margin-bottom: 15px;
    .inputCont {
      width: 250px;
      .inputBox {
        width: 250px;
        height: 36px;
      }
      .focusBorder {
        width: 250px;
        border-bottom: 1px solid $c-dd;
      }
    }
  }
}
