.accordion {
  border: 1px solid $c-cc;
  margin-bottom: 10px;
  border-radius: 10px;
  width: 90%;

  .accordionHeader {
    @include flex-center;
    justify-content: space-between;
    padding: 10px;
    background-color: none;
    cursor: pointer;
    &.disableCursor {
      cursor: default;
    }
    .accordionTitle {
      @include flex-center;
      font-size: 15px;
      .errorColor {
        color: #ff9e10;
        font-size: 16px;
        @include wt-ht(20px, 20px);
        background: $c-f5906429;
        @include flex-align-center;
        margin-right: 5px;
      }
      .doneColor {
        color: #28ad56;
        font-size: 16px;
        @include wt-ht(20px, 20px);
        background-color: $c-00a35829;
        @include flex-align-center;
        margin-right: 5px;
      }
      .abbreviatedForm {
        font-size: 12px;
        color: $c-8d;
        margin-left: 8px;
      }
      .smileyIcon {
        font-size: 16px;
        @include wt-ht(18px, 18px);
        background-color: $c-00a35829;
        @include flex-align-center;
        margin-right: 5px;
        border-radius: 3px;
      }
      .fullForm {
        font-size: 12px;
        color: $c-8d;
        margin-left: 5px;
      }
      .tickIcon {
        font-size: 14px;
        margin-right: 5px;
      }
    }
    .arrowIcon {
      transform: rotate(0deg);
      transition: transform 0.3s ease;
      &.rotate {
        transform: rotate(180deg);
      }
    }
    .comingSoonTxt {
      position: relative;
      font-size: 13px;
      color: $blue-violet;
      opacity: 0.8;
    }
  }

  .accordionContent {
    position: relative;
    font-size: 14px;
    list-style: none;
    margin: 0;
    padding: 0 10px 10px 10px;
    max-height: 0px;
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
    .stepNoAndInfo {
      position: relative;
      margin-bottom: 6px;
      font-size: 12px;
      color: $c-343a40;
      &.gridWrapper {
        display: grid;
        grid-template-columns: 50px calc(100% - 60px);
        column-gap: 10px;
      }
      &.gridMinMaxWrapper {
        display: grid;
        grid-template-columns: minmax(44px, auto) auto 1fr;
        grid-column-gap: 10px;
        align-items: baseline;
      }
      .nestedListed {
        position: relative;
        top: 3px;
        margin-bottom: 6px;
        list-style: none;
        padding-left: 0;
        li.stepNoAndInfo {
          margin-bottom: 2px;
          .stepNo {
            font-weight: 600;
          }
        }
      }
      &.blockDisplay {
        display: block;
      }
      .stepNo {
        margin-right: 5px;
        font-weight: 700;
      }
      .stepItem {
        line-height: 1.8;
        &.registered {
          color: #28ad56;
        }
        &.dkimContent {
          @include text-ellipsis(2);
        }
      }
      .mxContent,
      .mxContentHeader {
        display: grid;
        grid-template-columns: 30% 10% auto;
      }
      .mxContentHeader {
        color: $c-3b;
        font-weight: 700;
      }
    }
    .updateAndVerifyBtn {
      position: relative;
      @include flex-center;
      justify-content: flex-end;
      font-size: 12px;
      .updateBtn {
        margin-right: 6px;
        color: $c-8d;
      }
      .verifyNowBtn {
        color: $blue-violet;
        text-decoration: underline;
        cursor: pointer;
      }
      .loaderCont {
        width: 55px;
        .verifyNowLoader {
          @include InputSpinLoader(-2px, 20px, $c-ab);
          height: 15px;
          width: 15px;
        }
      }
    }
    .dkimUpdateAndVerify {
      display: grid;
      grid-template-columns: repeat(2, auto);
      justify-content: space-between;
      align-items: center;
      font-size: 11px;

      .inputAndVerifyBtn {
        @include flex-center;
        justify-content: flex-end;
        min-width: 320px;
        position: relative;
        .verifyBtn {
          @include flex-center;
          color: $blue-violet;
          text-decoration: underline;
          cursor: pointer;
          margin-left: 16px;
        }
        .dkimInputCont {
          @include flex-center;
          position: relative;
          @include wt-ht(58%, 40px);
          .dkimInputBox {
            border: 1px solid $c-ec;
            padding: 5px;
            border-radius: 6px;
            box-shadow: none;
            outline: 0;
            color: $blue-violet;
            width: 100%;
            transition: all 0.5s;
            &::placeholder {
              font-size: 12px;
            }
            &:focus {
              border-color: $blue-violet;
            }
          }
        }
        .loaderCont {
          width: 72px;
          position: relative;
          .verifyLoader {
            @include InputSpinLoader(-8px, 18px, $c-ab);
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  }

  &.expanded {
    .accordionContent {
      max-height: 500px; // Adjust the desired height
      transition: max-height 0.5s ease-in-out;
    }
  }
}
