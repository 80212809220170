ul.filterByCard {
  position: relative;
  background: transparent;
  z-index: 0;
  width: 100%;
  display: flex;
  column-gap: 10px;
  padding: 0 5px 10px 0;
  margin: 10px 0 0;
  overflow-x: auto;
  list-style: none;
  li.filterCard {
    flex: 1 0 180px;
    max-width: 180px;
    position: relative;
    background: white;
    @include wt-ht(100%, 68px);
    border: 1px solid $c-eb;
    border-radius: 5px;
    @include flex-center;
    padding: 10px 8px;
    z-index: 1;
    box-shadow: 0 4px 6px 0.7px rgba(59, 59, 59, 0.07);
    cursor: pointer;
    &:hover {
      border-color: $c-c4;
    }
    &.plusContactCard {
      border: 1px dashed $contact-stage-border;
      font-size: 44px;
      color: $contact-stage-border;
      cursor: pointer;
      justify-content: center;
      &:hover {
        border-color: $contact-stage-border;
      }
    }
    .wrapFilterValue {
      @extend %relBlock;
      .filterCount {
        @extend %relBlock;
        margin-bottom: 5px;
        font-size: 16px;
        color: $c-78;
        font-weight: 600;
      }
      .filterName {
        @extend %relBlock;
        font-size: 14px;
        color: $c-78;
        @include ellipsis;
        max-width: 154px;
      }
    }
    &.active {
      @include pointerSelect;
      transition: all 0.2s ease;
      @include pointerSelect;
      border-top: 4px solid #ff9f09 !important;
      .filterCount {
        color: $c-ff9;
      }
    }
    .filterIcon {
      @extend %relBlock;
    }
  }
  li.filterCardLoader {
    position: relative;
    border-radius: 5px;
    flex: 1 0 180px;
    max-width: 180px;
    @include wt-ht(100%, 68px);
  }
}
