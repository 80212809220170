.pagination-section {
  padding: 10px 0;
  background: $white;
  text-align: center;
  @include flex-direction(row);
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  border-top: 1px solid $b-c-1;
  &.loading {
    li {
      a {
        pointer-events: none;
      }
    }
  }
  .pageNoLoader {
    position: relative;
    display: block;
    @include wt-ht(28px, 28px);
    border-radius: 35px;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  .pagination {
    @include list-style-zero();
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    .previous-page {
      margin-right: 10px;
    }
    .next-page {
      margin-left: 10px;
    }
    .previous-page,
    .next-page {
      font-size: 14px;
      cursor: pointer;
      padding: 5px 10px;
      &:hover {
        background: $c-f5;
        border-radius: 3px;
      }
    }
    li {
      min-width: 30px;
      min-height: 30px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      cursor: pointer;
      border-radius: 50%;
      margin: 0px 5px;

      a {
        width: 100%;
        line-height: 30px;
      }

      &:hover:not(.linear-background) {
        color: $c-66 !important;
        color: $black !important;
        background: #eee !important;
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
      }

      &.active,
      &.active:hover {
        background: $blue-violet !important;
        color: $white !important;
        box-shadow: 0px 1px 4px 2px rgba(57, 83, 251, 0.26) !important;
        transition: all 0.2s ease-in-out;
        pointer-events: none;
      }

      &.next,
      &.previous {
        padding: 0px 15px;
        border-radius: 2px;
        &:hover {
          background: $c-f5 !important;
        }
      }
    }
    .limit-count {
      position: relative;
      .limit-btn {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        font-size: 14px;
        cursor: pointer;
        i {
          margin-left: 5px;
          color: $c-53;
          font-size: 14px;
        }
        &:hover {
          background: #eee;
          border-radius: 3px;
        }
      }
      .limit-dropdown {
        position: absolute;
        @include list-style-zero();
        padding: 10px;
        top: 24px;
        right: 0px;
        z-index: 2;
        border-radius: 8px;
        box-shadow: 0 0 25.1px 1.9px rgba(59, 59, 59, 0.07);
        border: solid 1px #dfdfdf;
        background-color: $white;
      }
    }
  }
}
