.unsubscribePage {
  position: relative;
  @include flex-center();
  justify-content: center;
  flex-direction: column;
  @include wt-ht(100%, 80%);
  .bigTitleFont {
    @extend %relBlock;
    font-weight: 600;
    text-align: center;
    font-size: 36px;
    line-height: 1.8;
    margin-bottom: 30px;
    letter-spacing: 1px;
    color: $c-3b;
  }
  .unsubscribeBtn {
    @extend %primaryBlueBtn;
    @include wt-ht(180px, 50px);
    font-size: 18px;
    letter-spacing: 1px;
    margin-bottom: 40px;
    &.loading {
      @include wt-ht(50px !important, 50px !important);
      .load-text {
        @include wt-ht(40px, 40px);
      }
    }
  }
}

.unsubscribeSuccess {
  position: relative;
  @include flex-center();
  justify-content: center;
  flex-direction: column;
  @include wt-ht(100%, 100%);
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 36px;
  color: $c-3b;
}
