.upgradeBadge {
  position: relative;
  display: flex !important;
  align-items: center !important;
  border-radius: 18px;
  color: white;
  width: fit-content;
  font-size: 10px;
  padding: 1px 5px;
  user-select: none;
  margin-right: 10px;
  &.sm {
    padding: 1px 5px;
    font-size: 10px;
  }
  &.md {
    padding: 2px 8px;
    font-size: 11px;
  }
  &.lg {
    padding: 5px 10px;
    font-size: 14px;
  }
  .text {
    color: white;
    margin-left: 5px;
    padding-top: 1px;
    font-weight: bold;
    &.sm {
      font-size: 10px;
      display: block;
      line-height: 1.2;
      padding-top: 2px;
    }
    &.md {
      font-size: 12px;
    }
    &.lg {
      font-size: 14px;
    }
  }
  .flashIcon {
    fill: white !important;
    display: unset !important;
    &.sm {
      @include wt-ht(10px !important, 10px !important);
    }
    &.md {
      @include wt-ht(11px !important, 11px !important);
    }
    &.lg {
      @include wt-ht(12px !important, 12px !important);
    }
  }
}
