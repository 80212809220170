.copyTextSection {
  position: relative;
  display: block;
  .copyIcon {
    position: relative;
    font-size: 11px;
    cursor: pointer;
    border-radius: 35px;
    padding: 3px;
    color: $c-78;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: $c-3b;
      background: $c-f5;
    }
  }
  .copyToast {
    position: absolute;
    white-space: nowrap;
    display: block;
    top: 16px;
    background: #cdf2cb;
    color: #177207;
    font-size: 12px;
    border-radius: 3px;
    padding: 4px 10px;
    right: 2px;
    line-height: normal;
  }
}
