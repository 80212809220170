%wtElpPad {
  position: relative;
  width: 20%;
  @include ellipsis();
  font-size: 14px;
}
%relWidth {
  width: 100%;
  position: relative;
}

.rightSection {
  height: 100% !important;
  &.isTeamPage {
    background-color: transparent !important;
  }
}

.teamFilterOption {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  @include searchInputByKeyword;
  .searchWrapper .searchInput .searchTextBox input {
    padding: 6px 20px 6px 2px;
  }
  .wrapLimitStatusFilter {
    position: relative;
    @include flex-center;
    justify-content: flex-end;
    padding-left: 10px;
    .filterDropdownByTeamMemberStatus {
      position: relative;
      .filterDropdownBtn {
        position: relative;
        padding: 0 10px;
        height: 36px;
        cursor: pointer;
        @include flex-center;
        background: white;
        border-radius: 4px;
        border: 1px solid $b-c-1;
        svg {
          @include wt-ht(14px, 16px);
          fill: $blue-violet;
        }
        .text {
          position: relative;
          font-size: 14px;
          color: $blue-violet;
          padding: 3px 10px 0 10px;
        }
        .filterIcon {
          font-size: 20px;
          color: $c-78;
        }
      }
      .drop-down-menu {
        border-radius: 2px;
        ul {
          min-width: 92px;
          margin: 0;
          li {
            &:not(:last-child) {
              border-bottom: 1px solid $b-c-1;
            }
            .listItem .listItemWrap {
              width: 106px;
            }
          }
        }
      }
      .filterDropdownOverlay {
        @include bg-mask(110, transparent);
      }
    }
  }
}

.teamSectionCont {
  @include wt-ht(100%, 100%);
  .teamHeaderSection {
    .teamHeading {
      padding-right: 16px;
      height: fit-content;
    }
  }

  .teamHeading {
    @include flex-direction(row);
    font-size: 16px;
    height: 59px;
    padding: 5px 20px;
    .myTeam {
      position: relative;
    }
    .seatCount {
      position: relative;
      font-size: 14px;
      .highlightSeat {
        color: $blue-violet;
        font-weight: 500;
      }
    }
  }
  .teamContainer {
    height: 100%;
    &.loadingTeam {
      margin-top: 10px;
    }

    .tableSection {
      position: relative;
      background: white;
      border-radius: 5px;
      @include wt-ht(100%, 100%);
      .headerPadding {
        padding: 0 16px;
      }
      .fullName {
        @extend %wtElpPad;
        @include flex-center();
        @include ellipsis();
        width: 18%;
        padding: 0;
        .sortIcon {
          margin-left: 5px;
          position: relative;
          svg {
            position: relative;
            color: $c-53;
          }
        }
      }
      .emailAddress {
        @extend %wtElpPad;
        width: 24%;
      }
      .teamName {
        .groupNameDetails {
          @include flex-center;
          .groupName {
            border: 1px solid $blue-violet;
            border-radius: 18px;
            color: $c-3b;
            font-size: 13px;
            padding: 2px 8px;
            @include flex-center();
            margin-right: 4px;
            max-width: 150px;
            @include ellipsis();
          }
          .remainingGroupCount {
            position: relative;
            color: $blue-violet;
            font-size: 13px;
            top: 1px;
          }
        }
      }
      .jobTitle,
      .teamName {
        @extend %wtElpPad;
      }
      .jobTitle {
        width: 24%;
      }
      .teamName {
        cursor: pointer;
      }
      .role {
        width: 16%;
        @include ellipsis();
        font-size: 14px;
      }
      .status {
        width: 16%;
        position: relative;
        @include flex-center();
        font-size: 14px;
        svg {
          @include wt-ht(16px, 16px);
        }
        .statusName {
          padding-left: 8px;
          text-transform: capitalize;
        }
        &.invited {
          color: $blue-violet;
          svg {
            fill: $blue-violet;
          }
        }
        &.active {
          color: $green;
          svg {
            fill: $green;
          }
        }
        &.inactive {
          color: $orange;
          svg {
            fill: $orange;
          }
        }
        &.rejected {
          color: $red;
          svg {
            fill: $red;
          }
        }
      }
      .moreOptions {
        width: 3%;
        position: relative;
        text-align: right;
        .dropdown-overlay {
          @include bg-mask(1, transparent);
        }
        .icon-hover-btn {
          padding: 2px;
        }
      }
      .tableHeader {
        @extend %tableHeader;
        @include flex-direction(row);
        background-color: transparent;
        border-top: 0;
        padding: 14px 0;
        color: $c-8d;
        &.loadingData {
          height: 46px;
        }
      }
      .tableBody {
        @include flex-direction(column);
        position: relative;
        @include wt-ht(100%, calc(100% - 170px));
        overflow: auto;
        color: $c-3b;
        padding: 0 16px;
        .noTeamMember {
          @include flex-direction(row);
          color: $c-66;
          letter-spacing: 0.3px;
          justify-content: center;
          padding: 25px;
          i {
            margin-right: 7px;
            font-size: 20px;
          }
        }
        .tableRow,
        .tableRowLoader {
          font-size: 16px;
          @include flex-direction(row);
          @extend %relPadTableWidth;
          padding: 12px 0;
          border-bottom: 1px solid $tb-bor-btm;
          .linear-background {
            @include wt-ht(80%, 12px);
            display: block;
            border-radius: 10px;
            margin: 2px 0;
          }
        }
        .tableRowLoader {
          .status {
            .icon {
              width: 16px;
              margin-right: 8px;
            }
          }
          .moreOptions {
            text-align: right;
            .linear-background {
              margin: 0 0 0 auto;
              display: block;
              text-align: right;
              @include wt-ht(24px, 24px);
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}

.popupSection {
  @extend %relWidth;
  .popupContainer {
    position: fixed;
    left: 0;
    top: 0;
    @include wt-ht(100%, 100%);
    @include flex-center();
    justify-content: center;
    z-index: 10;
    .popupWrap {
      background: $white;
      position: relative;
      padding: 24px;
      @include flex-direction(column);
      width: 430px;
      border-radius: 10px;
      .popupHeader {
        @extend %relWidth;
        @include flex-center();
        justify-content: space-between;
        margin-bottom: 15px;
        h6 {
          font-weight: 500;
          color: $c-53;
          font-size: 20px;
          font-family: $font-regular;
        }
        i {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 19px;
          border-radius: 50%;
          color: $c-53;
          cursor: pointer;
          &:hover {
            background: $c-dd;
            color: $black;
          }
        }
      }
      hr {
        margin: 0;
        margin-bottom: 24px;
        width: 100%;
        border: 0;
        border-bottom: 1px solid $c-dd;
      }
      .popupBody {
        display: block;
        @extend %relWidth;
        margin-bottom: 10px;
        .formInputGroup {
          display: grid;
          grid-template-columns: repeat(2, calc(50% - 10px));
          justify-content: space-between;
          row-gap: 10px;
          position: relative;
        }
        .inputWrapper {
          @extend %inputWrapper;
          margin-bottom: 30px;
          label {
            font-size: 12px;
          }
          .focusBorder {
            display: none;
          }
          input:focus {
            border: 0;
            border-bottom: 1px solid $blue-violet !important;
          }
          .errMsgText {
            bottom: -20px;
          }
          &.jobTitle {
            margin-bottom: 15px !important;
          }
        }
        .selectDropdown {
          position: relative;
          margin-bottom: 25px;
          .errMsgText {
            @extend %errMsgText;
            bottom: -20px !important;
          }
          .labelName {
            position: relative;
            font-size: 11px;
            .required {
              @include fieldRequired;
            }
          }
          .selectBox {
            cursor: pointer;
            position: relative;
            color: $blue-violet;
            line-height: 36px;
            font-size: 15px;
            border-bottom: 1px solid $bg-default;
            @include flex-center();
            &.activeBorder {
              border-bottom: 1px solid $blue-violet;
            }
            i {
              font-size: 20px;
              position: absolute;
              right: 10px;
              padding: 3px;
              cursor: pointer;
              border-radius: 50%;
              color: $c-53;
              &:hover {
                background: $c-fa;
                color: $black;
              }
            }
          }
        }
      }
      .popupFooter {
        @include flex-center();
        justify-content: center;
        @extend %relWidth;
        button {
          @extend %btn-blue;
          height: 40px;
          &.loading {
            border-radius: 50%;
            width: auto;
            min-width: unset;
          }
        }
      }
    }
  }
}

.teamHeaderSection {
  position: absolute;
  right: 0px;
  top: 10px;
  @include flex-center();
  .addNewTeamBtn {
    background-color: $blue-violet;
    width: 100px;
    height: 34px;
    padding: 0 12px;
  }
}
.settingSection {
  .tabNavigation {
    border-bottom: 1px solid $c-ec;
    background: $c-f5f8;
    .tabItem {
      height: 55px;
      font-size: 14px;
      &.activeTab {
        border-bottom: 2px solid $blue-violet;
        font-weight: 600;
        color: $blue-violet;
      }
    }
  }
}

.groupWrapper {
  @include wt-ht(100%, 100%);
  margin-top: 10px;
  .inputWithCloseIcon {
    @include flex-center();
    position: relative;
    width: fit-content;
    .editTeamName {
      height: 30px;
      background-color: #ecedfd;
      border: 0;
      padding: 0 16px;
    }
    .closeIcon {
      position: absolute;
      right: 16px;
      font-size: 12px;
      color: $c-8d;
    }
  }
  .circleOwner {
    @include flex-center;
    justify-content: center;
    @include wt-ht(30px, 30px);
    border-radius: 35px;
    padding-top: 2px;
    border: 1px solid $blue-violet;
    color: $blue-violet;
    text-transform: uppercase;
    font-size: 12px;
  }
  .emptyTeamWrapper {
    height: calc(100% - 146px);
    @include flex-center();
    flex-direction: column;
    justify-content: center;
    .createTeamBtn {
      margin-top: 30px;
    }
  }
  .createTeamBtn {
    border: solid 1px $blue-violet;
    background: $blue-violet;
    color: $white;
    width: 150px;
    height: 32px;
    padding: 0 12px;
  }
  .tableSection {
    position: relative;
    @include wt-ht(100%, 100%);
    background: white;
    border-radius: 5px;
    .headerPadding {
      padding: 0 16px;
    }
    .fullName {
      @extend %wtElpPad;
      @include flex-center();
      @include ellipsis();
      padding: 0;
      .sortIcon {
        margin-left: 5px;
        position: relative;
        svg {
          position: relative;
          color: $c-53;
        }
      }
    }
    .members,
    .createdOn,
    .updatedOn,
    .teamName {
      @extend %wtElpPad;
    }
    .owner {
      position: relative;
      width: 20%;
      font-size: 14px;
      display: flex;
      justify-content: center;
    }

    .members {
      width: calc(20% - 60px);
      text-align: left;
    }
    .editDeleteAction {
      position: relative;
      text-align: center;
      width: 60px;
      span:first-child {
        margin-right: 10px;
      }
    }
    .createdOn,
    .updatedOn,
    .members,
    .owner {
      text-align: center;
    }
    .moreOptions {
      width: 6%;
      position: relative;
      text-align: right;
      .dropdown-overlay {
        @include bg-mask(1, transparent);
      }
      .icon-hover-btn {
        padding: 8px;
      }
    }
    .tableHeader {
      @extend %tableHeader;
      @include flex-direction(row);
      background-color: transparent;
      border-top: 0;
      padding: 14px 0;
      color: $c-8d;
      &.loadingData {
        height: 46px;
      }
    }
    .tableBody {
      @include flex-direction(column);
      position: relative;
      @include wt-ht(100%, calc(100% - 126px));
      overflow: auto;
      color: $c-3b;
      padding: 0 16px;
      .teamName {
        cursor: pointer;
      }
      .noTeamMember {
        @include flex-direction(row);
        color: $c-66;
        letter-spacing: 0.3px;
        justify-content: center;
        padding: 25px;
        i {
          margin-right: 7px;
          font-size: 20px;
        }
      }
      .tableRow:hover {
        background: $c-f8;
      }
      .createdOn,
      .updatedOn,
      .members,
      .owner {
        text-align: center;
      }
    }
  }
  .tableRow,
  .tableRowLoader {
    font-size: 16px;
    @include flex-direction(row);
    @extend %relPadTableWidth;
    padding: 10px 0;
    width: 100%;
    border-bottom: 1px solid $tb-bor-btm;
    .linear-background {
      @include wt-ht(80%, 12px);
      display: block;
      border-radius: 10px;
      margin: 2px 0;
    }
    .editIcon,
    .deleteIcon {
      width: 20px;
      cursor: pointer;
      i {
        font-size: 16px;
      }
    }
  }
  .tableRowLoader {
    .status {
      .icon {
        width: 16px;
        margin-right: 8px;
      }
    }
    .owner {
      width: 20%;
      text-align: center;
      .linear-background {
        display: block;
        text-align: center;
        @include wt-ht(30px, 30px);
        border-radius: 50%;
      }
    }
    .moreIcons {
      width: 60px;
      text-align: right;
      @include flex-center;
      justify-content: flex-end;
      .editIcon {
        margin-right: 10px;
      }
      .linear-background {
        margin: 0 0 0 auto;
        display: block;
        text-align: right;
        @include wt-ht(24px, 24px);
        border-radius: 50%;
      }
    }
  }
}

.createTeamPopupWrapper {
  @extend %popupCard;
  .popupHeader {
    padding-bottom: 15px;
    @include flex-align-path-justify(center, row, space-between);

    .title {
      text-align: left;
      color: $c-3b;
      font-size: 20px;
    }

    .commonPopupCloseIcon {
      font-size: 20px;
      padding: 3px;
      border-radius: 50%;
      color: $c-53;
      cursor: pointer;

      &:hover {
        color: #000;
        background-color: $c-dd;
      }
    }
  }
  hr {
    border: 0;
    border-bottom: 1px solid $bg-default;
    margin: 0;
    padding: 0;
    margin-bottom: 36px;
  }
  label {
    font-size: 12px;
  }
  .card {
    width: 400px;
    max-height: 700px;
    overflow: auto;
    text-align: center;
    padding: 20px;
    .inputWrapper {
      @extend %inputWrapper;
      &.phoneNumber {
        margin-bottom: 20px;
      }
      .activeBorder {
        border-color: $blue-violet !important;
      }
    }
    .createTeamButton {
      @extend %primaryBlueBtn;
      width: 50%;
      border-radius: 10px;
    }
  }
}

.teamMemberWrapper {
  @include wt-ht(100%, 100%);
  margin-top: 10px;
  .addTeamMemberWrapper {
    height: calc(100% - 146px);
    @include flex-center();
    flex-direction: column;
    justify-content: center;
    .addTeamMemberBtn {
      margin-top: 30px;
    }
  }
  .addTeamMemberBtn {
    border: solid 1px $blue-violet;
    background: $blue-violet;
    color: $white;
    width: 150px;
    height: 32px;
    padding: 0 12px;
  }

  .teamHeaderSection {
    .backBtn {
      position: relative;
      top: 1px;
      .forwardArrow {
        position: relative;
        @include flex-align-center;
        transform: rotate(180deg);
        @include wt-ht(32px, 32px);
        border: 1px solid $c-dd;
        background: #f4f4f6 0% 0% no-repeat padding-box;
        border-radius: 5px;
        color: $blue-violet;
        cursor: pointer;
      }
    }
    .addTeamMemberBtn {
      margin-left: 12px;
    }
  }

  .tableRow,
  .tableRowLoader {
    font-size: 16px;
    @include flex-direction(row);
    @extend %relPadTableWidth;
    padding: 0;
    height: 50px;
    border-bottom: 1px solid $tb-bor-btm;
    .linear-background {
      @include wt-ht(80%, 12px);
      display: block;
      border-radius: 10px;
      margin: 2px 0;
    }
    .makeOwnerIcon {
      position: relative;
      cursor: default;
      &.notAOwner svg {
        opacity: 0.5;
      }
      .customTooltip {
        .sharpPointer {
          border-right: 1px solid $c-33;
          border-top: 1px solid $c-33;
          background-color: #393a3d;
          top: -3px;
          right: 5px;
        }
        .tooltipBody {
          background-color: #393a3d;
          border-radius: 5px;
          width: 130px;
          top: 3px;
          right: -50px;
        }
        .tooltipText {
          color: $white;
          padding: 8px;
          display: block;
          text-align: center;
        }
      }
    }
    .makeOwnerIcon,
    .removeMemberIcon {
      width: 30px;
      margin: 0 10px;
      cursor: pointer;
      text-align: center;
    }
    .moreIcons {
      width: 20%;
      text-align: right;
      @include flex-center;
      justify-content: flex-end;
      .makeOwnerIcon {
        margin-right: 10px;
      }
      .linear-background {
        margin: 0 0 0 auto;
        display: block;
        text-align: right;
        @include wt-ht(30px, 30px);
        border-radius: 50%;
      }
    }
  }
  .fullName,
  .emailAddress,
  .jobTitle,
  .role {
    @extend %wtElpPad;
    width: calc(24% - 16px);
  }
}

.addMembersPopup {
  @extend %popupCard;
  .popupHeader {
    padding-bottom: 15px;
    @include flex-align-path-justify(center, row, space-between);

    .title {
      text-align: left;
      color: $c-3b;
      font-size: 20px;
    }

    .commonPopupCloseIcon {
      font-size: 20px;
      padding: 3px;
      border-radius: 50%;
      color: $c-53;
      cursor: pointer;

      &:hover {
        color: #000;
        background-color: $c-dd;
      }
    }
  }
  hr {
    border: 0;
    border-bottom: 1px solid $bg-default;
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
  }
  label {
    font-size: 12px;
  }
  .card {
    width: 400px;
    max-height: 700px;
    overflow: auto;
    text-align: center;
    padding: 20px;
    .inputWrapper {
      @extend %inputWrapper;
      &.phoneNumber {
        margin-bottom: 20px;
      }
      .activeBorder {
        border-color: $blue-violet !important;
      }
    }
    .createTeamButton {
      @extend %primaryBlueBtn;
      width: 50%;
      border-radius: 10px;
    }
  }
  .popupBody {
    .scrollUserList {
      position: relative;
      display: block;
      max-height: 260px;
      overflow-y: auto;
    }
    .teamDetails {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .flexColumn {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .teamMemberName {
        font-size: 16px;
        color: $c-3b;
        margin-bottom: 6px;
      }
      .teamMemberJobTitle {
        color: #787878;
        font-size: 12px;
      }
      .teamMemberRole {
        color: #787878;
        font-size: 12px;
        text-transform: capitalize;
      }
    }
    .addTeamMemberBtn {
      border: solid 1px $blue-violet;
      background: $blue-violet;
      color: $white;
      @include wt-ht(150px, 32px);
      padding: 0 12px;
      &.loading {
        @include wt-ht(32px!important, 32px!important);
        .load-text {
          top: 4px;
          left: 4px;
          @include wt-ht(22px!important, 22px!important);
        }
      }
    }

    .multiSelectSection {
      margin-bottom: 30px;
      .checkBoxSection {
        border-bottom: 1px solid $c-dd;
        padding: 12px 10px;
        align-items: flex-start;
        .commonFilledCheckBox {
          top: 2px;
          @include wt-ht(16px, 16px);
          &.checked {
            border-color: $blue-violet !important;
            background: $blue-violet !important;
          }
          &:hover {
            border-color: $blue-violet !important;
          }
          .checkSvgIcon {
            background: $blue-violet !important;
            color: $white !important;
            box-shadow: 0 0 0 1px $blue-violet !important;
            svg path {
              stroke: $white !important;
            }
          }
        }
        .checkBoxName {
          width: 100%;
          padding-top: 0;
          padding-left: 10px;
        }
      }
    }
    .searchInputBtn {
      border: 1px solid $b-c-1;
      border-radius: 5px;
      margin-bottom: 12px;
      .searchIcon {
        color: #787878;
        font-size: 18px;
        padding-left: 11px;
        width: unset;
        height: unset;
      }
      .searchTextBox {
        width: 100%;
        .searchInputBoxWrapper {
          width: 100%;
        }
        input {
          width: 100%;
        }
      }
    }
  }
}
.teamMemberSection {
  position: relative;
  @include wt-ht(100%, 100%);
  background: $white;
  border-radius: 5px;
  .headerPadding {
    position: relative;
    padding: 0 16px;
    .tableHeader {
      @extend %tableHeader;
      @include flex-direction(row);
      background-color: transparent;
      border-top: 0;
      padding: 14px 0;
      color: $c-8d;
      &.loadingData {
        height: 46px;
      }
    }
  }
  .tableBody {
    @include flex-direction(column);
    position: relative;
    @include wt-ht(100%, calc(100% - 126px));
    overflow: auto;
    color: $c-3b;
    padding: 0 16px;
  }
}
