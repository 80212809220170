#customFieldsIntroBanner,
#trackingDelayBanner,
#connectInboxRevokeAccess,
#lowCreditsBanner {
  position: relative;
  @include flex-center;
  @include wt-ht(100%, 40px);
  justify-content: center;
  background: $hb-sandal-yellow;
  font-size: 13px;
  color: $c-44;
  z-index: 1;

  .text {
    margin-right: 10px;
  }
  img {
    margin-right: 10px;
    height: 24px;
  }
  .badgeBtn {
    @include flex-center;
    justify-content: center;
    background: #ec7720;
    font-family: Graphik-Medium;
    color: white;
    padding: 0 10px;
    height: 24px;
    margin-right: 10px;
    border-radius: 35px;
    min-width: fit-content;
    font-size: 13px;
  }
  .tryNowCustomFields {
    position: relative;
    background: transparent;
    border: 1px solid transparent;
    color: $hb-text;
    cursor: pointer;
    font-weight: bold;
    margin: unset;
    cursor: pointer;
    padding: 0;
    transition: all 0.3s;
    color: $blue-violet;
    &:hover {
      transition: all 0.3s;
      text-decoration: underline;
    }
  }

  .closeIcon {
    @include flex-center();
    position: absolute;
    right: 10px;
    i {
      color: $c-66;
      font-size: 22px;
      padding: 2px;
      cursor: pointer;

      &:hover {
        background-color: $c-dd;
        border-radius: 50%;
      }
    }
  }

  // tracking Dealy banner css

  &.trackingDelayBanner {
    .clock {
      padding-right: 10px;
      color: $blue-violet;
    }
    .text {
      line-height: 1.4;
    }
  }

  .connectInboxRow {
    position: relative;
    display: grid;
    width: 100%;
    align-items: center;
    grid-template-columns: 25px auto 100px;
    justify-content: center;
    i {
      margin-right: 10px;
      font-size: 24px;
      color: darkorange;
    }
    .text {
      padding: 0 5px;
      font-size: 14px;
      text-align: center;
      line-height: 1;
    }
    button.animatedButton {
      padding: 8px 20px;
      &:hover {
        box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.08);
      }
    }

    .closeIcon {
      i {
        font-size: 18px;
        color: $c-66;
      }
    }
  }

  &.twoColLayout {
    .connectInboxRow {
      grid-template-columns: 25px auto;
    }
  }

  &.lowCreditsBanner {
    .content {
      position: relative;
      display: grid;
      width: 100%;
      align-items: center;
      grid-template-columns: 25px auto 25px;
      justify-content: center;

      .warningIcon {
        margin-right: 10px;
        font-size: 24px;
        color: darkorange;
      }

      .text {
        padding: 0 5px;
        font-size: 14px;
        text-align: center;
        line-height: 1;
      }
    }
  }
}
