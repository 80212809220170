button.animatedButton {
  @include flex-center;
  position: relative;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 12px;
  font-weight: 500;
  color: $white;
  background: $light-green;
  font-size: 14px;
  border-radius: 5px;
  transition: all 0.3s ease;
  margin: 0 auto;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  span.iconStart {
    margin-right: 7px;
    @include flex-center;
    svg {
      @include wt-ht(15px, 15px);
      fill: $white;
    }
  }
  span.iconRight {
    @include flex-center;
  }
  &.blueConfirmBtn {
    padding: 10px;
    height: 36px;
    min-width: 80px;
    font-weight: bold;
    background: $blue-violet;
  }
  &.loading {
    position: relative;
    cursor: not-allowed;
    transition: all 0.3s ease;
    border-radius: 45px;
    width: 40px !important;
    height: 40px !important;

    .load-text {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 5px;
      left: 5px;
      color: #fff;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      line-height: 1;
      padding: 5px;
      z-index: 2;
      border-radius: 50%;
      transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
      animation: spin 1s linear infinite;
      border: 1.5px solid #ffffff;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-left: 1px;
      background-color: transparent;
    }
  }
  &.fill-transistion {
    transition: transform 0.3s 0.1s ease-out, opacity 0.1s 0.3s,
      background-color 0.1s 0.3s;
    transform: scale(100);
    opacity: 1;
    .load-text {
      border: none;
    }
  }
  &.rotateForwardIcon {
    font-size: 16px !important;
    i {
      background: white;
      padding: 2px;
      color: $light-green;
      border-radius: 35px;
      font-size: 16px;
      font-weight: 400;
      transform: rotate(180deg) !important;
    }
  }
}
