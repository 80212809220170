.editSequencePopupWrapper,
.emailVerificationLoggedInWrapper {
  @extend %popupCard;
  .card {
    width: 500px;
    .cardBody {
      .fileUploadImg {
        position: relative;
        text-align: center;
        img {
          margin: 0 auto;
          @include wt-ht(116px, 113px);
        }
      }
      h4 {
        text-align: center;
        margin-bottom: 15px;
        font-size: 18px;
        color: $c-33;
      }
      .textWrapper {
        text-align: center;
        width: 100%;
        .message {
          font-size: 14px;
          line-height: 1.5;
          color: $c-66;
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 20px;
          }
          b {
            color: $c-44;
          }
        }
      }
      .editSeqOkBtn {
        @extend %primaryBlueBtn;
      }
    }
  }
}
.overlayFileExceedMask {
  @include bg-mask(14, rgba(0, 0, 0, 0.57));
}
.emailVerificationLoggedInWrapper {
  .textWrapper {
    .message {
      font-size: 13px !important;
    }
  }
  .emailLink {
    text-decoration: none;
  }
  .logoutButton {
    @include flex-center();
    justify-content: center;
    margin-top: 16px;
    color: $blue-violet;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
  }
}
