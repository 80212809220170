.exportToSalesforceSection {
  @extend %relBlock;
  .sfBtn {
    background: white;
    position: relative;
    border: 1px solid $c-eb;
    @include wt-ht(36px, 36px);
    border-radius: 5px;
    @include flex-center;
    justify-content: center;
    margin-left: 10px;
    cursor: not-allowed;
    svg {
      @include wt-ht(24px, 24px);
      fill: $c-9e;
      path:first-child {
        fill: $c-9e;
      }
    }
    &.enabled {
      cursor: pointer;
      svg {
        @include wt-ht(24px, 24px);
        fill: $blue-violet;
        path:first-child {
          fill: $blue-violet;
        }
      }
      &:hover {
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
      }
    }
    &.activeBtn {
      border: 1px solid $c-cc;
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
    }
  }
  &.sfExpDisabled #export_selected {
    opacity: 0.7;
    pointer-events: none;
  }
  .sfExportSuccessSection {
    @extend %popupCard;
    .card {
      width: 400px;
      h6 {
        @extend %relBlock;
        font-size: 20px;
        font-weight: 500;
        color: $c-53;
        letter-spacing: 0.3px;
      }
      .imgAlign {
        @extend %relBlock;
        text-align: center;
        margin: 30px 0 20px;
      }
      .expContactInfoBoxLoader {
        height: 100px;
        margin-top: 60px;
        @extend %relBlock;
      }
      .expContactInfoBox {
        @extend %relBlockWidth;
        margin: 10px 0 30px;
        .expDetailInfo {
          position: relative;
          display: grid;
          align-items: center;
          grid-template-columns: 30px calc(100% - 90px) 60px;
          border-bottom: 1px solid $c-eb;
          padding: 12px;
          font-size: 14px;
          img {
            @include wt-ht(24px, auto);
          }
          .text {
            color: $c-66;
            margin-left: 10px;
            align-self: end;
          }
          .count {
            position: relative;
            text-align: right;
            align-self: end;
            font-weight: 600;
            &.success {
              color: #65b242;
            }
            &.failed {
              color: #eb4e3f;
            }
          }
        }
      }
      .doneBtn {
        padding: 10px;
        @include wt-ht(100px !important, 36px !important);
        letter-spacing: 0.3px;
        font-size: 14px;
        background: $blue-violet;
      }
    }
  }
  .pleaseWaitCard {
    position: absolute;
    border: 1px solid $c-eb;
    z-index: 10;
    background: $white;
    right: 10px;
    padding: 15px;
    width: 160px;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(115, 115, 115, 0.12);
    border: solid 1px $tb-bor-btm;
    background-color: white;
  }
  .exportSalesforceCard {
    position: absolute;
    border: 1px solid $c-eb;
    z-index: 10;
    background: $white;
    right: 0px;
    width: 320px;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(115, 115, 115, 0.12);
    border: solid 1px $tb-bor-btm;
    background-color: white;
    &.zdx8 {
      z-index: 8;
    }
    .cardTitle {
      position: relative;
      display: block;
      width: 100%;
      @include bor-rad-top(10px, 10px);
      padding: 12px 15px;
      background: $tb-bor-btm;
      h6 {
        font-size: 16px;
        font-weight: 500;
        color: $c-53;
        letter-spacing: 0.6px;
        margin: 0px;
      }
    }
    .cardBody {
      position: relative;
      padding: 15px 15px 5px;
      .radioBtnCont {
        &:not(:last-child) {
          margin: 5px 0;
        }
        .radioButtonCont {
          line-height: 1.25;
          .radioBtn,
          .contentClass {
            @include wt-ht(14px, 14px);
          }
          label {
            font-size: 14px;
            margin-top: 3px;
            color: $c-53;
          }
          .contentClass {
            margin-right: 8px;
            &::before,
            &::after {
              margin: 0;
              @include wt-ht(14px, 14px);
            }
          }
        }
      }
      .exportTypeList,
      .exportUpdateList {
        margin-bottom: 12px;
      }
      .exportUpcomingList {
        margin-bottom: 0px;
        .commingSoonBadge {
          background: #fff2ce;
          padding: 5px;
          font-size: 12px;
          width: fit-content;
          border-radius: 3px;
          font-style: italic;
        }
        .radioBtnCont {
          user-select: none;
          label {
            color: $c-d3;
          }
        }
      }
      .radioTitle {
        color: $c-87;
        letter-spacing: 0.3px;
        text-align: left;
        font-size: 14px;
        margin-bottom: 7px;
      }
    }
    .cardFooter {
      position: relative;
      padding: 0 15px 15px;
      width: fit-content;
      margin: 0 0 0 auto;
      button.animatedButton {
        padding: 10px;
        @include wt-ht(100px !important, 36px !important);
        letter-spacing: 0.3px;
        background: $blue-violet;
        &.loading {
          width: 36px !important;
          .load-text {
            position: absolute;
            @include wt-ht(26px, 26px);
          }
        }
      }
    }
  }
  .sfLoginCard {
    position: absolute;
    border: 1px solid $c-eb;
    z-index: 10;
    background: $white;
    right: 10px;
    width: 220px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(115, 115, 115, 0.12);
    border: solid 1px $tb-bor-btm;
    background: white;
    transition: all 0.3s;
    p {
      font-size: 14px;
      color: $c-66;
      text-align: center;
      margin-bottom: 10px;
      line-height: 1.5;
    }
    button.animatedButton {
      padding: 10px;
      @include wt-ht(100px !important, 36px !important);
      letter-spacing: 0.3px;
      background: $blue-violet;
    }
  }
  .overlaySFBg {
    @include bg-mask(9, transparent);
  }
  .overlayExpMask {
    @include bg-mask(99, rgba(0, 0, 0, 0.57));
  }
}
