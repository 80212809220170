@import "./eventsList";
@import "./calendarFormInput";
@import "./freeSlot";

.bookMeetingPopup {
  position: fixed;
  @include flex-center;
  justify-content: center;
  inset: 0;
  @include wt-ht(100%, 100%);
  z-index: 100;
  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 21;
    font-size: 18px;
    color: $c-66;
    cursor: pointer;
    padding: 2px;
    &:hover {
      background-color: $c-dd;
      border-radius: 50%;
    }
  }
  .card {
    position: relative;
    z-index: 20;
    background: white;
    @include wt-ht(70%, calc(100vh - 20%));
    border-radius: 5px;
    @media screen and (min-width: 768px) and (max-width: 1300px) {
      width: 80%;
    }

    @media screen and (min-width: 1024px) and (max-width: 1300px) {
      .nextBtn button {
        min-width: 70px !important;
      }
    }

    .bookMeetingCont {
      position: relative;
      display: grid;
      grid-template-columns: 30% 70%;
      height: 100%;
      &.disableAction {
        pointer-events: none;
        user-select: none;
        cursor: unset;
      }
      .sideMenuCont {
        @extend %relBlock;
        @include bor-rad-left(5px, 5px);
        border-right: 1px solid $c-dd;
        @include wt-ht(100%, 100%);
        background: #f9f9ff;
        margin: auto;
        padding: 20px 10px 20px 20px;
        overflow: auto;
        .eventsListSection {
          @extend %relBlock;
          height: 100%;
          padding-right: 10px;
          overflow: auto;
          @extend %eventsListPopup;
        }
      }
      .calendarContentBody {
        @extend %relBlock;
        @include bor-rad-right(5px, 5px);
        height: calc(100% - 52px);
        align-items: flex-end;
        .calendarPopupCont {
          position: relative;
          display: grid;
          grid-template-columns: 55% 45%;
          @include wt-ht(100%, 100%);
          @extend %calendarFormInputCont;
          @extend %freeSlotCont;
          &.oneColGrid {
            grid-template-columns: 100%;
          }
        }
        #calendarDescContent {
          border: 1px solid $c-eb;
          border-radius: 5px;
        }
        .addGuestDescCont {
          @extend %relBlock;
          @include wt-ht(99%, 100%);
          padding: 30px 20px 20px;
          .addGuestCont {
            position: relative;
            display: grid;
            grid-template-columns: 100%;
            padding: 5px 0;
            border: 1px solid $c-eb;
            margin-bottom: 30px;
            border-radius: 5px;
            .addRemoveEmailAddress {
              .emailAndInputBoxCont {
                position: relative;
                .drop-down-menu {
                  left: 0;
                  .dropdownList {
                    .listItem .listItemWrap {
                      @include ellipsis();
                    }
                    .option {
                      padding: 10px 10px;
                      .itemName {
                        .addTxt {
                          color: $blue-violet;
                        }
                      }
                    }
                  }
                }
                .inputWrapper {
                  width: auto;
                  .inputCont .inputBox {
                    border-bottom: unset;
                    line-height: 25px;
                    width: 100%;
                    border: none;
                  }
                }
              }
              .labelName {
                line-height: initial;
                height: 26px;
                @include flex-center;
                position: relative;
                top: 2px;
                font-size: 12px;
                white-space: nowrap;
              }

              .focusBorder {
                display: none !important;
              }
            }
          }
        }
        .calendarActionBtn {
          position: relative;
          @include flex-center;
          justify-content: flex-end;
          border-top: 1px solid $c-ea;
          column-gap: 15px;
          height: 52px;
          padding: 0 20px;
          background: white;
          border-bottom-right-radius: 10px;

          button {
            font-size: 14px;
            border-radius: 5px;
            letter-spacing: 0.3px;
            min-width: 100px;
            margin: unset;
            @include wt-ht(34px !important, 34px !important);
            &.calendarScheduleEventBtn,
            &.calendarNextBtn {
              border: solid 1px $blue-violet;
              background: $blue-violet;
              &.disabledBtn {
                pointer-events: none;
                user-select: none;
                cursor: unset;
              }
            }
            &.calendarScheduleEventBtn {
              min-width: 130px;
            }
            &.calendarBackBtn {
              border: solid 1px white;
              color: $c-66;
              background: white;
              &:hover {
                background: $c-f5;
                color: black;
                border: solid 1px $c-f5;
              }
            }
            &.calendarCancelBookingBtn {
              border: solid 1px $blue-violet;
              color: $blue-violet;
              background: white;
            }
            &.loading {
              transition: all 0.3s ease-in-out;
              min-width: auto;
              border-radius: 35px;
              .load-text {
                @include wt-ht(24px, 24px);
                top: 4px;
                left: 4px;
              }
            }
          }
        }
        .calendarActionBtn.nextBtn {
          background-color: unset;
          height: auto;
          padding: 0 0 0 6px;
          border: 0;
          button {
            min-width: 70px;
          }
        }
        .disabled {
          @include disable;
        }
      }
    }
  }
  .bgOverlay {
    @include bg-mask(19, rgba(0, 0, 0, 0.57));
  }
}

@media screen and (max-width: 1024px) {
  .bookMeetingCont {
    grid-template-columns: repeat(1, 1fr) !important;
    overflow: auto !important;
    &.autoHeight {
      display: flex !important;
      flex-direction: column !important;
      height: auto !important;
      .sideMenuCont {
        flex: 0 0 20% !important;
      }
      .calendarContentBody {
        flex: 0 0 auto !important;
      }
    }
  }
  .calendarPopupCont {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .slotsAvailablelist {
    padding-top: 12px !important;
    .timeSlot {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  .flexWrapper {
    width: 100% !important;
  }
  .sideMenuCont {
    overflow: unset !important;
  }
  .eventsListSection {
    padding-right: 14px !important;
  }
  .eventsList {
    max-height: 220px !important;
    overflow: auto !important;
  }
  .closeIcon {
    top: 5px !important;
    right: 5px !important;
  }
  .addGuestCont {
    margin-bottom: 16px !important;
  }
  .collapsible {
    text-align: left;
  }
  .bookMeetingPopup
    .card
    .bookMeetingCont
    .calendarContentBody
    .calendarActionBtn {
    border: 0;
    button {
      width: 100% !important;
      &.loading {
        width: 34px !important;
      }
    }
  }
}
