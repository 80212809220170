@keyframes rotate-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
.chatGptEditorSection {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  @include wt-ht(100%, 1px);
  line-height: normal !important;
  .chatGptWrapper {
    position: absolute;
    display: block;
    @include wt-ht(100%, 1px);
    right: 0;
    .iconBtnWrapper {
      position: relative;
      width: 100%;
      @include flex-center;
      justify-content: flex-end;
      gap: 8px;
      bottom: 30px;
      right: 10px;
      width: fit-content;
      margin: 0 0 0 auto;
      .aiMagicWandBtn {
        position: relative;
        @include flex-align-center;
        @include wt-ht(24px, 24px);
        background: $blue-violet;
        border-radius: 35px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
        cursor: pointer;
        svg {
          @include wt-ht(14px, 14px);
        }
      }
      .scoreSection {
        position: relative;
        @include flex-align-center;
        .scoreBtn {
          @extend %relBlock;
          @include flex-align-center;
          border-radius: 35px;
          @include wt-ht(24px, 24px);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
          cursor: pointer;
          border: 1px solid #959cf2;
          background: white;
          .scoreLoader {
            border: 3px solid #cdcdf9;
            @include wt-ht(16px, 16px);
            border-radius: 50%;
            border-right-color: transparent;
            animation: rotate-circle 1.5s linear infinite;
          }
          .circularChart {
            display: block;
            @include wt-ht(16px, 16px);
          }
          .circleBg {
            fill: none !important;
            stroke: $c-ee;
            stroke-width: 3.8;
          }
          .circle {
            fill: none !important;
            stroke-width: 3.8;
            stroke-linecap: round;
            animation: progress 1s ease-out forwards;
          }

          .singleChart {
            position: relative;
          }
          .percentage {
            fill: $c-66;
            font-size: 18px;
            text-align: center;
            text-anchor: middle;
          }
        }
        .scoreWrapper {
          position: absolute;
          bottom: 26px;
          background: $white;
          z-index: 20;
          right: 0;
          border-radius: 6px;
          box-shadow: 0px 2px 3px #00000029;
          border: 1px solid #d6d5d5;
          width: 250px;
          .titleWrapper {
            padding: 10px;
            border-bottom: 1px solid $c-e5;
            @include flex-center;
            justify-content: space-between;
            .scoreTitle {
              font-weight: bold;
              font-size: 14px;
            }
            .percent {
              color: #eaa126;
              font-size: 12px;
            }
          }
          .contentWrapper {
            border-bottom: 1px solid $c-e5;
            max-height: 78px;
            overflow: auto;
            padding: 5px;
            .individualScore {
              @include flex-center;
              justify-content: space-between;
              padding: 5px;
              font-size: 12px;
            }
          }
          .rewriteWithAI {
            @include flex-align-center;
            height: 36px;
            color: #5946ff;
            font-size: 14px;
            cursor: pointer;
          }
        }
        .bgOverlay {
          @include bg-mask(19, transparent);
        }
      }
      .generateScore {
        position: relative;
        @include flex-align-center;
        @include wt-ht(24px, 24px);
        border-radius: 35px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
        cursor: pointer;
      }
    }
    .rewriteAiInputCard {
      position: relative;
      background: $white;
      padding: 15px 10px;
      width: 100%;
      bottom: 107px;
      border-top: 1px solid $c-dd;
      transition: bottom 0.4s ease-in-out;
      &.generateAiLoader {
        bottom: 67px;
        padding: 0 10px 12px 10px;
        transition: bottom 0.4s ease-in-out;
      }
      .closeAiIcon {
        position: absolute;
        @include flex-align-center;
        font-size: 12px;
        top: -4px;
        right: 15px;
        padding: 5px 4px 5px 2px;
        border-radius: 10px 35px 35px 10px;
        transform: rotate(90deg);
        color: $c-8d;
        background: $c-dd;
        overflow: hidden;
        cursor: pointer;
      }
      .aiContentTags {
        position: relative;
        display: flex;
        align-items: flex-start;
        .contentLabel {
          position: relative;
          @include flex-center;
          font-size: 12px;
          color: $c-3b;
          margin-right: 5px;
          top: 2px;
          white-space: nowrap;
          height: 22px;
        }
        .contentTagsList {
          position: relative;
          @include flex-center;
          flex-flow: row nowrap;
          @include list-style-zero;
          column-gap: 8px;
          row-gap: 5px;
          overflow-x: auto;
          .tagItem {
            position: relative;
            background: $c-f4;
            padding: 3px 6px;
            border-radius: 2px;
            gap: 3px;
            cursor: pointer;
            @include flex-align-center;
            .tagSvg {
              @include flex-center;
              position: relative;
              svg {
                @include wt-ht(14px, 14px);
              }
            }
            .tagName {
              position: relative;
              @include flex-center;
              color: $c-8d;
              font-size: 12px;
              top: 1px;
            }
          }
        }
      }
      .inputSearchBotWrapper {
        position: relative;
        @include flex-center;
        gap: 10px;
        border: 1px solid $blue-violet;
        border-radius: 6px;
        padding: 6px;
        margin-top: 12px;
        height: 42px;
        .defaultPrompt {
          position: relative;
          .wrapperFoldIcon {
            position: relative;
            @include flex-align-center;
            padding: 5px;
            @include wt-ht(24px, 24px);
            cursor: pointer;
            border-radius: 6px;
            background: #eaebfc;
            svg {
              position: relative;
              @include wt-ht(10px, 10px);
            }
          }
          .promptDropdown {
            position: absolute;
            padding: 10px;
            width: 290px;
            background: $white;
            border: 1px solid $c-dd;
            box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
            border-radius: 6px;
            opacity: 1;
            bottom: 36px;
            left: -6px;
            z-index: 20;
            transition: opacity 0.4s ease-in-out;
            @include searchInputByKeyword;
            .searchWrapper .searchInput {
              width: 270px;
              .searchTextBox input {
                padding: 4px 20px 4px 2px;
                width: 236px;
                line-height: 1.2;
                font-size: 13px;
                &::placeholder {
                  font-size: 13px;
                }
              }
            }
            .dropdownWrapper {
              position: relative;
              display: block;
              min-height: 34px;
              max-height: 188px;
              overflow-y: auto;
              transition: max-height 0.3s ease-in-out,
                min-height 0.3s ease-in-out;
              margin-top: 5px;
              .spinnerLoader {
                @extend %spinnerIcon;
                opacity: 0.8;
                background: $c-f7;
                padding: 10px 0;
                i {
                  font-size: 14px;
                }
                .text {
                  font-size: 14px;
                }
              }
              .tempDropdown {
                position: relative;
                @include list-style-zero;
                border-radius: 6px;
                li {
                  position: relative;
                  border-radius: 6px;
                  .tempDropdownItem {
                    position: relative;
                    display: block;
                    padding: 8px 6px;
                    cursor: pointer;
                    .text {
                      position: relative;
                      font-size: 14px;
                      color: $c-343a40;
                      @include ellipsis;
                      margin-bottom: 4px;
                    }
                    .desc {
                      position: relative;
                      color: $c-8d;
                      font-size: 11px;
                      @include ellipsis;
                    }
                  }
                  .arrowForward {
                    display: none;
                    position: absolute;
                    right: 6px;
                    bottom: 8px;
                    font-size: 16px;
                    color: $c-66;
                  }
                  &:hover {
                    background: #eff1ff;
                  }
                  &.active {
                    background: $white;
                    .text {
                      color: $blue-violet;
                    }
                    .tempDropdownItem {
                      width: calc(100% - 24px);
                      transition: width 0.3s ease-in-out;
                    }
                    .arrowForward {
                      display: block;
                      color: $blue-violet;
                      transition: display 0.3s ease-in;
                    }
                    &:hover {
                      background: #eff1ff;
                    }
                  }
                }
              }
              .emptyResult {
                position: relative;
                text-align: center;
                padding: 10px;
                font-size: 14px;
                color: $c-8d;
              }
            }
          }
          .bgOverlay {
            @include bg-mask(19, transparent);
          }
        }
        .inputWrapper {
          @extend %inputWrapper;
          width: 100%;
          margin-bottom: 0;
          .inputCont {
            #rewriteAiInput {
              line-height: 26px !important;
              border-bottom: none !important;
              width: 100% !important;
            }
            .focusBorder {
              display: none;
            }
          }
        }
        .helpMeWriteBtn {
          position: relative;
          min-width: 132px;
          height: 28px !important;
          border-radius: 6px;
          color: $blue-violet;
          background: #eaebfc;
          margin: unset;
          svg path {
            fill: $blue-violet;
          }
          .load-text {
            @include wt-ht(18px, 18px);
            border-color: $blue-violet;
            left: 57px;
          }
        }
        .loadingBot {
          position: relative;
          top: 1px;
          text-align: left;
          letter-spacing: 0px;
          font-size: 14px;
          color: $blue-violet;
        }
      }
    }
  }
}
