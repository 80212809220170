@mixin connectingDots {
  content: "...";
  position: absolute;
  width: 10px;
  height: 2px;
  top: 1px;
  left: 35px;
}

.addStageOverlay {
  position: relative;
  border: solid 1px #c8cfff;
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0px 5px 12.4px 0.7px rgba(59, 59, 59, 0.07);
  margin: 15px auto;
  max-width: 365px;
  @include flex-center();

  .addStageBtn {
    position: relative;
    @include flex-center();
    padding: 8px 12px;
    width: 100%;
    flex-direction: row;

    .btnText {
      color: $blue-violet;
      font-size: 14px;
      font-family: $font-medium;
      line-height: 24px;
    }

    .stageListCont {
      margin-left: 15px;
      @include flex-center();
      .autoEmail,
      .manualEmail,
      .linkedin,
      .phoneCall,
      .customTask {
        position: relative;
        cursor: pointer;
        @include wt-ht(30px, 30px);
        @include flex-center();
        justify-content: center;
        position: relative;
        &:hover {
          background-color: #ddd;
          border-radius: 50%;
        }
      }
      .autoEmail,
      .manualEmail,
      .linkedin,
      .phoneCall {
        &::after {
          @include connectingDots();
          cursor: unset;
          pointer-events: none;
          user-select: none;
        }
      }
      .autoEmail {
        &:hover {
          svg {
            background-color: #ddd;
          }
        }
        svg {
          fill: $c-38;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: $white;
        }
      }
      .manualEmail {
        margin-left: 25px;
      }
      .linkedin {
        margin-left: 25px;
        svg {
          fill: $c-53;
          width: inherit;
          height: 12px;
          margin-left: 0;
        }

        .linkedInSubMenu {
          height: fit-content;
          border-radius: 10px;
          box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
          border: solid 1px #ebebeb;
          opacity: 0;
          bottom: 50px;
          transition: opacity 0.4s ease-in-out;
          right: -300px;
          left: unset;
          bottom: -10px;
          width: 300px;
          padding: 10px 0px;
          position: absolute;
          z-index: -1;
          background-color: $white;

          &.showOptions {
            opacity: 1;
            z-index: 2;
          }
          .stepCont {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: row;
            height: 36px;
            padding: 0 15px;
            cursor: pointer;
            font-size: 14px;
            &:hover {
              background-color: $c-fa;
            }
          }
        }
      }
      .phoneCall,
      .customTask {
        margin-left: 25px;
      }
      .phoneCall svg {
        fill: $c-1eb9d5;
        width: 15px;
        height: 15px;
      }
      .customTask svg {
        fill: $fade-green;
        width: 16px;
        height: 16px;
        margin-left: 0;
      }

      .lockIcon {
        position: absolute;
        background: orange;
        color: white;
        top: -5px;
        right: -5px;
        font-size: 12px;
        @include wt-ht(20px, 20px);
        border-radius: 35px;
        justify-content: center;
        @include flex-center;
      }
    }
  }
}
