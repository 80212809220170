.notificationBanner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  top: 11px;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  .notificationCard {
    position: relative;
    line-height: initial;
    @include flex-center;
    background: rgb(255 248 245 / 96%);
    border: 1px solid #f59064;
    border-radius: 5px;
    padding: 6px 16px;
    width: fit-content;
    margin: 0 auto;
    .infoSvgIcon {
      position: relative;
      @include flex-center;
      svg {
        width: 16px;
        height: 16px;
        fill: #f59064;
      }
    }
    .text {
      position: relative;
      font-size: 12px;
      padding-left: 10px;
      padding-right: 8px;
      color: $c-3b;
      width: max-content;
    }
    .btnText {
      position: relative;
      line-height: initial;
      @include flex-center;
      white-space: nowrap;
      font-size: 12px;
      padding: 0 10px;
      background: #f59064;
      color: white;
      border-radius: 11px;
      height: 24px;
      cursor: pointer;
    }
    .closeSvgIcon {
      position: relative;
      margin-left: 10px;
      border-left: 1px solid #f3dbd0;
      padding: 3px 0 3px 10px;
      @include flex-center;
      svg {
        width: 12px;
        height: 12px;
        cursor: pointer;
        fill: #f59064;
      }
    }
  }
}
