.addBulkContactsTaskBtn {
  position: relative;
  border: 1px solid $c-eb;
  user-select: none;
  background: white;
  @include flex-center;
  justify-content: center;
  @include wt-ht(36px, 36px);
  margin-left: 10px;
  border-radius: 5px;
  cursor: not-allowed;
  svg {
    @include wt-ht(16px, 16px);
    fill: $c-9e;
  }
  .customTooltip {
    .sharpPointer {
      border-right: 1px solid $c-33;
      border-top: 1px solid $c-33;
      background-color: #393a3d;
      top: -38px;
      right: -2px;
    }
    .tooltipBody {
      background-color: #393a3d;
      border-radius: 5px;
      width: 100px;
      top: -62px;
      right: -46px;
      &.bulkAddToTask {
        width: 291px;
        right: -136px;
      }
    }
    .tooltipText {
      color: $white;
      padding: 8px;
      display: block;
      text-align: center;
    }
  }
  .wrapLockIcon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  &.enabled {
    cursor: pointer;
    @extend %enabledBtn;
    &:hover {
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
    }
  }
}

.moreOptionBtn {
  position: relative;
  margin-left: 8px;
  user-select: none;
  .drop-down-menu {
    min-width: 200px;
    .freemiumIcon {
      right: 0 !important;
    }
  }
  i {
    @include flex-center;
    justify-content: center;
    font-size: 20px;
    border-radius: 35px;
    cursor: not-allowed;
    border: 1px solid white;
    color: $c-9e;
    @include wt-ht(24px, 36px);
    background: white;
    transition: all 0.3s;
    &.enabled {
      cursor: pointer;
      border: 1px solid $c-eb;
      color: $blue-violet;
      &:hover {
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
      }
    }
  }
  .drop-down-menu {
    #addToSalesforce {
      .svg-wrapper {
        svg {
          @include wt-ht(20px, 16px);
        }
      }
    }
    .listItemWrap {
      background: transparent;
      .icon-holder i {
        background: transparent;
        border-color: transparent;
      }
    }
  }
  .moreOptionOverlay {
    @include bg-mask(2, transparent);
  }
}
