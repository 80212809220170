.upgradePopup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  @include flex-center();
  justify-content: center;
  letter-spacing: 0.3px;
  width: 100%;
  z-index: 10;
  overflow: auto;
  user-select: none;
  &.showAddStage {
    z-index: 101 !important;
  }
  .upgradePopupCloseIcon {
    .closeIcon {
      position: absolute;
      top: 14px;
      right: 14px;
      font-size: 18px;
      padding: 4px;
      border-radius: 50%;
      color: $c-8d;
      cursor: pointer;
      &:hover {
        background-color: $c-dd;
      }
    }
  }
  .upgradeCard {
    position: relative;
    display: block;
    z-index: 102;
    border-radius: 10px;
    padding: 30px 40px;
    background: white;
    max-width: 500px;
    .title {
      position: relative;
      @include flex-center;
      margin-bottom: 25px;
      h4 {
        margin-left: 10px;
        font-size: 22px;
        font-weight: $font-medium;
        color: $c-33;
        font-weight: bold;
        line-height: 1;
      }
    }
    .subTitle {
      font-size: 18px;
      font-weight: 600;
      color: $c-17;
      margin-bottom: 15px;
      line-height: 1.4;
    }
    .descText {
      color: #6f6f6f;
      font-size: 16px;
      margin-bottom: 15px;
      line-height: 1.4;
      .linkText,
      a {
        font-weight: 600;
        color: $blue-violet;
        text-decoration: none;
        cursor: pointer;
      }
    }
    .upgradeBtn {
      margin-top: 25px;
      position: relative;
      margin-left: 0;
      font-size: 18px;
      font-weight: bold;
      color: white;
      border-radius: 35px;
      padding: 10px 0;
      min-width: 130px;
      background: #ff6057;
      border: 1px solid #ff6057;
      outline: none;
    }
    button#my_custom_link {
      min-width: 160px;
    }
  }
}

.upgradeOverlay {
  @include bg-mask(100, rgba(0, 0, 0, 0.57));
}

.freemiumIcon {
  position: relative;
  @include flex-center;
  justify-content: center;
  border-radius: 35px;
  .thunderSvg {
    position: absolute !important;
    border-radius: 35px !important;
    @include wt-ht(20px !important, 20px !important);
    justify-content: center;
    @include flex-center;
    fill: $white;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.24);
  }
}

.upgradeSequenceCard {
  position: absolute;
  @include flex-center;
  justify-content: center;
  width: 100%;
  inset: 0;
  z-index: 10;
  .card {
    position: relative;
    cursor: pointer;
    @include flex-center;
    z-index: 10;
    border-radius: 35px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #a8b2ff;
    background-color: #fff;
    padding: 8px 20px;
    .upgradeBadge {
      margin-right: 10px;
      .text {
        color: white;
      }
    }
    .descText {
      font-size: 14px;
      color: $c-50;
    }
  }
  .upgradeBgOverlay {
    position: absolute;
    z-index: 9;
    inset: 0;
    opacity: 0.5;
    @include wt-ht(100%, 100%);
    background: $c-f0;
  }
}
