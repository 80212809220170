.snoozeTaskPopup {
  .popup-wrap {
    overflow: unset !important;
    background: $white;

    .content-container {
      border-radius: 10px;
    }

    .title {
      padding: 30px 40px 15px 40px;
    }

    .reschedulingCont {
      height: fit-content;
      background-color: #fff;
      color: $c-53;
      padding: 10px 40px 10px 40px;
      background-color: #fff;
      font-family: "Graphik-Regular ", Arial, sans-serif;

      .dropDown {
        .p-rel {
          border-bottom: 1px solid $c-ee;
          .scheduledLabel {
            font-size: 15px;
            padding-bottom: 10px;
            display: block;
          }

          .drop-down-menu {
            ul {
              max-height: 250px;
              overflow: auto;
            }
          }

          .value {
            font-size: 16px;
            color: #3953fe;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            cursor: pointer;
          }
        }
      }

      .alertMeContainer {
        display: flex;
        align-items: center;
        padding-top: 20px;

        .animatedCheckbox {
          height: fit-content;
        }

        .autosignText {
          font-size: 15px;
        }
      }
    }

    .popup-action-wrap {
      padding: 15px 40px 30px !important;
    }
  }
}
