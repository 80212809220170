.ball-container {
  position: absolute;
  height: 50px;
  width: 129px;
  top: 0;
  right: 0;
  bottom: 0;

  left: 0;
  margin: auto;
  text-align: center;

  .ball {
    width: 16px;
    position: relative;
    display: inline-block;
    margin: 5px;
    height: 16px;
    border-radius: 50%;
    z-index: 999;
    &.ball-one {
      background-color: $blue-violet;
      animation: jump 0.5s ease 0s infinite alternate;
    }

    &.ball-two {
      background-color: $blue-violet;
      animation: jump 0.5s ease 0.15s infinite alternate;
    }

    &.ball-three {
      background-color: $blue-violet;
      animation: jump 0.5s ease 0.25s infinite alternate;
    }

    &.ball-four {
      background-color: $blue-violet;
      animation: jump 0.5s ease 0.35s infinite alternate;
    }
  }

  .shadow {
    position: relative;
    opacity: 0.1;
    bottom: 16px;
    width: 16px;
    height: 5px;
    border-radius: 50%;
    background-color: $black;
    display: inline-block;
    margin: 5px;
    &.shadow-one {
      animation: shrink 0.5s ease 0s infinite alternate;
    }
    &.shadow-two {
      animation: shrink 0.5s ease 0.15s infinite alternate;
    }

    &.shadow-three {
      animation: shrink 0.5s ease 0.25s infinite alternate;
    }

    &.shadow-four {
      animation: shrink 0.5s ease 0.35s infinite alternate;
    }
  }
}

@keyframes jump {
  0% {
    transform: scaleY(0.8);
  }
  100% {
    transform: translateY(-30px);
  }
}

@keyframes shrink {
  100% {
    transform: scaleX(0.5);
    opacity: 0.01;
  }
}
