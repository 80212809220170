#reactEditor,
#emailComposeEditor {
  position: relative;

  .quill {
    height: 100%;
    position: relative;
    border: 1px solid #dddddd;
    padding: 10px 15px;
    padding-bottom: 0px;
    border-radius: 10px;
    color: $black;

    .ql-editor {
      min-height: 200px;
      color: $black;
      p {
        line-height: normal;
        white-space: pre-wrap;
      }
    }

    .ql-toolbar {
      width: 100%;
      left: 0px;
      bottom: 0px;
      text-align: left;
      position: absolute;
      z-index: 8;
      background-color: #fafafb;
      border: 0;
      border-top: 1px solid $c-cc;
      z-index: 2;
      @include bor-rad-btm(10px, 10px);
      .ql-picker-options {
        transform: translateY(-100%);
        margin-top: -25px;
        text-align: left;
        .ql-picker-item[data-value="9.75px"]:before {
          content: "small";
          font-size: 10px !important;
        }

        .ql-picker-item[data-value="13px"]:before {
          content: "Normal";
          font-size: 13px !important;
        }

        .ql-picker-item[data-value="19.5px"]:before {
          content: "Large";
          font-size: 18px !important;
        }

        .ql-picker-item[data-value="32.5px"]:before {
          content: "Huge";
          font-size: 32px !important;
        }
      }
    }

    .ql-container {
      border-top: 1px solid #ccc;
      margin-bottom: 45px;

      .ql-tooltip {
        left: 0px !important;
        transform: translateY(-10px);
      }
    }
  }
}

#emailComposeEditor {
  height: 270px;
  .quill {
    .ql-container {
      height: calc(100% - 40px);
      .ql-editor {
        min-height: unset;
      }
    }
  }
  &.outboxHighlight .quill .ql-formats {
    margin-right: 5px !important;
    &:last-child {
      right: 0;
      position: absolute;
    }
  }
}

// Token Variable Container
.varibaleContainer {
  width: auto;
  position: absolute;
  bottom: 40px;
  right: 6px;
  z-index: 10;
  display: block;
  .variableCont-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: transparent;
    opacity: 0.75;
  }
  .tokenVariables {
    box-sizing: border-box;
    border-radius: 10px;
    width: fit-content;
    border: 1px solid $c-dd;
    z-index: 999;
    background: $white;
    box-shadow: 3px 3px 5px #bfbdbd;
    position: relative;
    .variableTabWrapper {
      @include flex-center();
      background: $c-f9;
      border-bottom: 1px solid $c-dd;
      @include bor-rad-top(10px, 10px);
    }
    .tokenTitle {
      font-size: 14px;
      font-weight: 400;
      padding: 10px;
      color: $c-66;
      text-align: left;
      cursor: pointer;
      text-transform: capitalize;
      height: 40px;
      &.activeTokenTitle {
        color: $blue-violet;
        border-bottom: 2px solid $blue-violet;
      }
    }
    .optionWrapper {
      width: 294px;
      max-height: 220px;
      .freemiumIcon .thunderSvg {
        left: 10px;
      }
      .searchTextBox {
        width: 100%;
        input::placeholder {
          color: $c-dd;
        }
      }
      .searchInputBtn {
        border-bottom: 1px solid $c-dd;
        position: sticky;
        z-index: 2;
        .searchIcon {
          font-size: 20px;
          color: $c-dd;
          &:hover {
            @include wt-ht(24px, 24px);
          }
        }
      }
      .optionCont {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 180px;
        min-height: 180px;
        z-index: 1;
      }
      .option {
        padding: 8px 10px;
        font-size: 14px;
        font-family: $font-regular, sans-serif;
        text-align: left;
        display: flex;
        align-items: center;
        padding-right: 0;
        position: relative;
        &.optionDisabled {
          opacity: 0.5;
          pointer-events: none;
          .freemiumIcon {
            left: 5px;
          }
        }
        &.freeUser {
          .freemiumIcon {
            width: 27px;
          }
          &:hover {
            cursor: default;
            background-color: $white;
            color: unset;
          }
          .tokenText,
          .highlightYellow {
            opacity: 0.5;
            background-color: $white;
          }
        }

        .lockIcon {
          position: absolute;
          right: 4px;
        }
        .tokenText {
          color: $c-66;
          &:after {
            margin: 0 0.25rem;
            content: "-";
          }
        }
        .highlightYellow {
          background: #fff5d9;
        }
        &:hover {
          border-radius: 2px;
          cursor: pointer;
          color: $blue-violet;
          background: #f5f5f5;
        }
      }
    }
    .sharpPointer {
      position: absolute;
      border: unset;
      background: #fff;
      border-top-right-radius: 18px;
      top: unset;
      bottom: -6px;
      left: unset;
      right: 5px;
      padding: 6px;
      transform: rotate(-45deg);
      box-shadow: -2px 4px 4px 0px rgba(0, 0, 0, 0.16);
    }
  }
}
// End Token Variable container

.ql-formats {
  .ql-picker.ql-font {
    .ql-picker-item {
      font-size: 0;
      &:before {
        content: attr(data-value) !important;
        font-size: 14px;
        text-transform: capitalize;
      }
    }
  }

  .ql-picker.ql-font {
    .ql-active {
      &:before {
        content: attr(data-value) !important;
        font-size: 14px;
        text-transform: capitalize;
      }
    }
  }

  /* Set content font-families */
  .ql-font-SansSerif {
    font-family: sans-serif;
  }
  .ql-font-Serif {
    font-family: Serif;
  }
  .ql-font-Georgia {
    font-family: Georgia;
  }
  .ql-font-GillSans {
    font-family: "Gill Sans";
  }
  .ql-font-Palatino {
    font-family: Palatino;
  }
  .ql-font-Monospace {
    font-family: Monospace;
  }
  .ql-font-Verdana {
    font-family: verdana;
  }
  .ql-font-Helvetica {
    font-family: Helvetica;
  }
  .ql-font-Tahoma {
    font-family: Tahoma;
  }
  .ql-font-Garamond {
    font-family: Garamond;
  }

  .ql-variables {
    font-size: 14px !important;
    width: fit-content !important;
    &::after {
      content: "{  }";
      font-size: 16px;
      color: $blue-violet;
      padding: 2px 0;
      cursor: pointer;
      border: 1px solid transparent;
      &:hover {
        border: 1px solid $c-ed;
      }
    }
  }
}

.quill .image-uploading {
  // width: 100%;
  margin-left: 10px;
  &::before {
    border-top-color: $blue-violet;
  }
}

.ql-font .ql-picker-label {
  &::before {
    width: 82px;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
  }
  svg {
    right: -4px !important;
  }
}
.ql-toolbar.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="sans-serif"]::before {
  font-family: sans-serif;
  content: "Sans Serif" !important;
}
.ql-toolbar.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Courier"]::before {
  font-family: Courier;
  content: "Courier" !important;
}
.ql-toolbar.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Georgia"]::before {
  font-family: Georgia;
  content: "Georgia" !important;
}
.ql-toolbar.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Gill Sans"]::before {
  font-family: "Gill Sans";
  content: "Gill Sans" !important;
}
.ql-toolbar.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Garamond"]::before {
  font-family: Garamond;
  content: "Garamond" !important;
}
.ql-toolbar.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Helvetica"]::before {
  font-family: Helvetica;
  content: "Helvetica" !important;
}
.ql-toolbar.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Palatino"]::before {
  font-family: Palatino;
  content: "Palatino" !important;
}
.ql-toolbar.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Tahoma"]::before {
  font-family: Tahoma;
  content: "Tahoma" !important;
}
.ql-toolbar.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Verdana"]::before {
  font-family: Verdana;
  content: "Verdana" !important;
}

#sequenceAutomaticQuilEditor,
#templateQuilEditor {
  position: relative;
  .quill > div.ql-toolbar.ql-snow > span:last-child {
    height: 42px;
    bottom: 0px;
    @include flex-center;
    flex-direction: row-reverse;
  }
}

.insertHTMLWrap {
  width: 100%;
  height: 100%;
  position: relative;
  border-collapse: collapse;

  .insertHTMLArea {
    resize: none;
    width: 100%;
    height: 100%;
    border: 1px solid $c-dd;
    border-radius: 5px;
    padding: 12px 15px;
  }
}

.insertHTMLIcon {
  @include wt-ht(unset !important, 28px !important);
  padding: 2px !important;
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 4px;
  border: 1px solid $c-dd;
  border-radius: 5px;
  @include flex-center();
  cursor: pointer;
  z-index: 1;
  font-weight: 500;
  &.active {
    color: $blue-violet;
    font-weight: 500;
    width: 100%;
    height: 100%;
  }
}

// Writing Assistant
#writingAssistantDom {
  @include wt-ht(unset !important, 28px !important);
  padding: 2px !important;
  margin-right: 6px;
  position: relative;
  .icon {
    position: relative;
    svg {
      @include wt-ht(24px, 24px);
    }
  }
  .assistantFeatureList {
    position: absolute;
    background: white;
    border-radius: 10px;
    bottom: 34px;
    z-index: 200;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.8px $c-ed;
    background-color: #fff;
    padding: 5px;
    left: -230px;
    width: 270px;
    .sharpPointer {
      position: absolute;
      border: unset;
      background: #fff;
      border-top-right-radius: 18px;
      top: unset;
      bottom: -6px;
      left: unset;
      right: 20px;
      padding: 6px;
      transform: rotate(-45deg);
      box-shadow: -2px 4px 4px 0px rgba(0, 0, 0, 0.16);
    }
    .subTitle {
      font-size: 14px;
      padding: 8px 0;
      position: relative;
      display: block;
      font-weight: bolder;
      white-space: nowrap;
    }
    .dropdownList {
      position: relative;
      width: 100%;
      @include flex-center;
      flex-direction: column;
      justify-content: flex-start;
      border-radius: 7px;
      box-shadow: inset 0 1px 6px 0 $c-c4;
      border: solid 0.8px #fff;
      padding: 8px;
      transition: all 0.3s ease;
      .dropdownItem {
        position: relative;
        @include flex-center;
        text-align: left;
        color: $c-78;
        width: 100%;
        font-size: 13px;
        padding: 8px;
        border: solid 0.8px transparent;
        border-radius: 35px;
        svg {
          @include wt-ht(13px, 13px);
          margin-right: 10px;
        }
        .text {
          position: relative;
          color: $c-52;
        }
        &.upcoming .text {
          color: $c-87;
        }
        .freemiumIcon {
          padding-left: 10px;
          justify-content: unset;
        }
        &:not(.upcoming):hover {
          border: solid 0.8px #d9deff;
          background-color: rgba(235, 234, 244, 0.39);
          svg {
            @include wt-ht(13px, 13px);
            fill: $c-52;
          }
          .text {
            color: $c-52;
          }
        }
        &:hover.upcoming {
          opacity: 0.4;
          border: solid 0.8px #d9deff;
          background-color: rgba(235, 234, 244, 0.39);
          .comingSoonBadge {
            display: block;
          }
        }
      }
    }
  }
  .editorAssistantOverlay {
    @include bg-mask(199, transparent);
  }
}

/* Implement for one of email schedule later date time banner */
#emailComposeEditor {
  position: relative;
  border: 1px solid $c-dd;
  border-radius: 10px;
  .quill {
    border: unset;
  }
}
#emailComposeEditor.scheduleLaterDateTimeBanner .quill {
  height: calc(100% - 40px);
}
/* END */

/* --------- Content Editor Body Height Adjustment (Don't remove this) ---------- */
.tempSingleRowHt,
.seqSingleRowHt {
  height: calc(100% - 38px) !important;
}
.tempMultiRowHt,
.seqMultiRowHt {
  height: calc(100% - 76px) !important;
}

.fullHt {
  height: 100% !important;
}
.schSeqHt50 {
  padding-bottom: 50px !important;
}
.schSeqHt90 {
  padding-bottom: 90px !important;
}
.schSeqHt12 {
  padding-bottom: 12px !important;
}
/* --------- End Editor Body Height Adjustment (Don't remove this) ---------- */
/* ---- CHAT GPT ---- */
.chatGptEnabledNormal {
  height: calc(100% - 108px) !important;
}
.chatGptEnabledSmall {
  height: calc(100% - 148px) !important;
}
.chatGptEnabledLarge {
  height: calc(100% - 190px) !important;
}
/* ---- CHAT GPT ---- */

/* ----  ACCOUNT NOTES ----*/

#accountNotesEditor {
  position: relative;
  height: 100%;

  .quill {
    height: 100%;
    position: relative;
    border: 1px solid $blue-violet;
    padding: 10px 15px 0;
    border-radius: 10px;
    color: $black;

    .ql-editor {
      position: relative;
      top: 60px;
      min-height: 200px;
      color: $black;
      p {
        line-height: normal;
        white-space: pre-wrap;
      }
    }

    .ql-toolbar {
      width: 100%;
      left: 0px;
      bottom: 0px;
      text-align: left;
      position: absolute;
      z-index: 8;
      background-color: #fafafb;
      border: 0;
      border-top: 1px solid $c-cc;
      z-index: 2;
      border-radius: 0px 0px 11px 11px;
    }
  }
  .ql-container.ql-snow {
    border: none;
  }
}

/* ----  ACCOUNT NOTES ----*/
