.addNumberPopupWrapper {
  height: 100%;

  .addNumberPopup {
    height: 255px;
    border-radius: 10px;
    background: #fff;
    min-width: 420px !important;
    position: relative;
    padding: 25px;

    .popupHeader {
      @include flex-center();

      .popupTitle {
        color: $c-53;
        font-size: 16px;
        width: 80%;
        display: inline-block;
        font-weight: 600;
      }
    }

    .popupBody {
      margin-top: 25px;

      .partition {
        display: grid;
        grid-template-columns: 90% 10%;
        box-shadow: 3.4px 5px 4px 0 rgba(124, 130, 165, 0.1);
        border: solid 1px $c-d3;
        border-radius: 10px;
        margin: 15px 0px;
        transition: all 0.3s ease;

        .option {
          @include flex-direction(column);
          align-items: flex-start;
          padding: 15px;
          color: $c-66;
          cursor: pointer;

          .optionTitle {
            font-size: 15px;
          }

          .optionDesc {
            font-size: 11px;
            padding: 3px 0px;
          }
        }

        .rightArrow {
          width: 15px;
          height: auto;
          @include flex-center();
          transition: transform 0.3s ease;

          svg {
            height: inherit;
            width: inherit;
            path {
              fill: $c-66;
            }
          }
        }

        &:hover {
          box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
          .optionTitle {
            color: $blue-violet;
          }

          .rightArrow {
            transform: translate(20%, 0);

            svg path {
              fill: $blue-violet;
            }
          }
        }
      }
    }
  }
}
