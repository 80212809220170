.header {
  background: #fff;
  border-bottom: 1px solid #f1f2f6;
  // box-shadow: 0.6px 1.9px 27px 0 rgba(59, 59, 59, 0.07);
  position: fixed;
  top: 0;
  width: 100%;
  padding-left: 70px;

  nav {
    padding: 12px 25px;
    height: 69px;
    display: flex;
    align-items: center;

    .welcome-msg {
      color: #737685;
    }
  }
}
