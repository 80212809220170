.signupSuccessPage {
  position: relative;
  user-select: none;
  background-image: url("./../../assets/png/spiderLineBg.png");
  @include wt-ht(100%, 100%);
  overflow: auto;
  .pageHeader {
    background: #261d56;
    position: sticky;
    top: 0;
    padding: 18px 0;
    z-index: 10;
    box-shadow: 0 0 2px 0 $c-a7;
    .container {
      margin: 0 auto;
      @include flex-center;
      svg {
        height: 32px;
        fill: white;
        path {
          fill: white;
        }
      }
    }
  }
  .pageBody {
    @extend %relBlock;
    margin: 50px auto;
    .cardBox {
      padding: 50px 30px;
      text-align: center;
      position: relative;
      border-radius: 10px;
      box-shadow: 0 0 33.3px 1.8px rgba(59, 59, 59, 0.06);
      background: white;
      width: 500px;
      margin: 50px auto;
      img {
        margin-bottom: 15px;
      }
      h4 {
        margin-top: 15px;
        font-size: 20px;
        color: $c-33;
        letter-spacing: 0.3px;
        margin-bottom: 10px;
      }
      i.spinnerFillIcon {
        font-size: 18px;
        color: $blue-violet;
        margin-top: 10px;
      }
      p {
        margin-top: 10px;
        color: $c-66;
      }
    }
    .supportContactSignup {
      @extend %relBlock;
      .supportText {
        text-align: center;
        font-size: 16px;
        color: $c-44;
        margin-bottom: 20px;
      }
      .supportList {
        position: relative;
        display: grid;
        align-items: center;
        grid-template-columns: 270px;
        justify-content: center;
        .card {
          background: white;
          @extend %relBlock;
          border: 1px solid $c-ee;
          border-radius: 10px;
          padding: 15px;
          transition: all 0.3s;
          cursor: pointer;
          @include flex-center;
          gap: 15px;
          &:not(:last-child) {
            margin-right: 20px;
          }
          img {
            @include wt-ht(auto, 20px);
            margin-bottom: 10px;
            align-self: flex-start;
          }
          .name {
            @extend %relBlock;
            font-size: 14px;
            font-weight: 300;
            color: rgba(102, 102, 102, 0.5);
            margin-bottom: 2px;
          }
          .value {
            @extend %relBlock;
            font-size: 16px;
            font-weight: 400;
            color: $c-66;
          }
          .hoverArrowForward {
            position: absolute;
            display: none;
            right: 20px;
            font-size: 24px;
            bottom: 10px;
          }
          &:hover {
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.06);
            border-color: #3c5afa;
            transition: all 0.3s;
            .hoverArrowForward {
              display: block;
            }
          }
        }
      }
    }
  }
}
