.exportBtnWrapper {
  position: relative;
  background: $white;
  color: $blue-violet;
  border: solid 1px $tb-bor-btm;
  font-size: 14px;
  border-radius: 5px;
  width: fit-content;
  @include wt-ht(36px, 36px);
  @include flex-center();
  justify-content: center;
  cursor: not-allowed;
  margin-left: 10px;
  .customTooltip {
    .sharpPointer {
      border-right: 1px solid $c-33;
      border-top: 1px solid $c-33;
      background-color: #393a3d;
      top: -38px;
      right: -2px;
    }
    .tooltipBody {
      background-color: #393a3d;
      border-radius: 5px;
      width: 120px;
      top: -62px;
      right: -46px;
      &.bulkAddtoSequence {
        width: 290px;
        right: -144px;
      }
      &.enrichContactsTooltip {
        width: 160px;
        right: -77px;
      }
    }
    .tooltipText {
      color: $white;
      padding: 8px;
    }
  }
  &.exportSelectedDisabled.enrichBtnwrapper {
    .enrichText {
      svg,
      path,
      circle {
        stroke: $c-9e !important;
      }
    }
  }
  &.enrichBtnwrapper {
    margin: 0;
    margin-right: 10px;
    svg,
    path {
      fill: transparent !important;
    }
    .enrichText {
      @include wt-ht(16px, 16px);
      svg {
        @include wt-ht(16px, 16px);
      }
      svg,
      path,
      circle {
        stroke: $blue-violet;
        fill: transparent !important;
      }
    }
    .customTooltip {
      .sharpPointer {
        border-right: 1px solid $c-33;
        border-top: 1px solid $c-33;
        background-color: #393a3d;
        top: -38px;
        right: -2px;
      }
      .tooltipBody {
        background-color: #393a3d;
        border-radius: 5px;
        width: 120px;
        top: -62px;
        right: -46px;
        &.bulkAddtoSequence {
          width: 290px;
          right: -144px;
        }
        &.enrichContactsTooltip {
          width: 160px;
          right: -77px;
        }
      }
      .tooltipText {
        color: $white;
        padding: 8px;
      }
    }
    .enrichText {
      color: $blue-violet;
    }
    .drop-down-menu {
      .dropdownList {
        width: 240px !important;
        .option .listItem .listItemWrap {
          width: 240px !important;
        }
      }
    }
  }
  .drop-down-menu {
    width: 170px !important;
    right: 0px;
    top: 4px !important;
    padding-top: 30px;
    border-radius: 5px;
    @include bor-rad-top(250px, 0px);
    background: transparent !important;
    border: none !important;
    box-shadow: none;
    .dropdownList {
      width: 190px !important;
      margin: 2px 0;
      background: $white;
      border: 1px solid #dddddd;
      border-radius: 6px;
      #exportToHubspot,
      #exportToSalesforce {
        .freemiumIcon {
          svg,
          path {
            fill: white !important;
          }
        }
      }
      .option {
        position: relative;
        border-bottom: 1px solid $c-cc;
        padding: 8px !important;
        &:last-child {
          border-bottom: 0;
        }
        &:not(:last-child) {
          border-bottom: 1px solid $c-cc;
        }
        .listItem {
          gap: 8px;
          justify-content: left;
          .listItemWrap {
            width: 170px !important;
          }
          .icon-holder {
            @include flex-align-center;
            .csvIcon {
              @include wt-ht(18px !important, 18px !important);
              fill: $blue-violet !important;
              path {
                fill: $blue-violet !important;
              }
            }
            .hubspotIcon {
              @include wt-ht(18px !important, 18px !important);
              fill: $c-F59064 !important;
              path {
                fill: $c-F59064 !important;
              }
            }
            .salesforceIcon {
              @include wt-ht(18px !important, 18px !important);
              path {
                &:first-child {
                  fill: #00a1e0 !important;
                }
                fill: #fffffe !important;
              }
            }
          }
          .listItemWrap {
            @include flex-center;
            .itemName {
              font-size: 12px;
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
  .exportSvg {
    @include wt-ht(16px, 16px);
    fill: $c-9e;
    path {
      fill: $c-9e;
    }
  }
  .customTooltip {
    .sharpPointer {
      border-right: 1px solid $c-33;
      border-top: 1px solid $c-33;
      background-color: #393a3d;
      top: -38px;
      right: -2px;
    }
    .tooltipBody {
      background-color: #393a3d;
      border-radius: 5px;
      width: 120px;
      top: -62px;
      right: -46px;
      &.bulkAddtoExport {
        width: 255px;
        right: -122px;
      }
    }
    .tooltipText {
      color: $white;
      padding: 8px;
      display: block;
      text-align: center;
    }
  }
  .wrapLockIcon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  &.enabled {
    @extend %enabledBtn;
    cursor: pointer;
    &:hover {
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
    }
  }

  &.exportSelectedDisabled {
    #export_selected {
      opacity: 0.7;
      pointer-events: none;
    }
    .enrichText {
      color: #9e9e9e;
    }
  }
}
.overlay-mask {
  @include bg-mask(1, transparent);
}
