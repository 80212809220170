.ratingSmileyCont {
  @include flex-center();
  justify-content: center;
  flex-direction: row;

  .smileyIcon {
    fill: #c8ced3;
    border: 0;
    color: #b57700;
    padding: 5px;
    margin: 10px;
    opacity: 0.6;
    cursor: pointer;
    display: grid;
    text-align: center;
    align-items: center;
    justify-content: center;

    .ratingType {
      font-size: 13px;
      color: $c-66;
      z-index: 1;
      margin-top: 10px;
    }

    &.active {
      transition: all 4s ease-in-out;
      fill: #ffcd00;
      opacity: 1;
    }

    &:hover {
      opacity: 1;
      transition: none;
    }

    svg {
      width: 35px;
      height: 35px;
    }
  }

  .smileyInput {
    display: none;
  }

  .smiley {
    background: linear-gradient(
      135deg,
      rgb(255, 233, 25) 0%,
      rgb(251, 192, 0) 100%
    );
    border-radius: 100%;
    padding: 5px;
    position: relative;
    width: 50px;
    height: 50px;
    box-shadow: rgba(211, 165, 110, 0.498039) 0px 7px 7px 1px,
      rgb(245, 245, 245) 0px 20px 10px 1px;
  }

  .mouth {
    width: 60%;
    height: 30%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    box-sizing: border-box;
    position: absolute;
    bottom: 18%;
    left: 50%;
    margin-left: -30%;
    background: #b57700;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .eyes {
    width: 100%;
    margin-top: 15%;
    box-sizing: border-box;
    padding: 0 5px;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .eyes .eye {
    width: 8px;
    height: 8px;
    background: #b57700;
    float: left;
    border-radius: 100%;
    position: relative;
  }

  .eyes .eye:nth-of-type(2) {
    float: right;
  }

  .eyes .eye::after {
    content: "";
    display: block;
    position: absolute;
    width: 0%;
    height: 0%;
    background: #fed800;
    transform: rotate(0deg);
    top: -15px;
    left: 5px;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .eyes .eye:first-of-type::after {
    transform: rotate(0deg);
    left: auto;
    right: 5px;
  }

  /* Normal animation */
  .smiley.normal .mouth,
  #normal .smiley .mouth {
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    height: 10%;
    width: 40%;
    bottom: 25%;
    margin-left: -20%;
  }

  .smiley.normal .eyes,
  #normal .smiley .eyes {
    margin-top: 30%;
  }

  /* angry animation */
  .smiley.angry .mouth,
  #angry .smiley .mouth {
    width: 40%;
    height: 20%;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    bottom: 18%;
    left: 50%;
    margin-left: -20%;
    border-bottom: 0;
  }

  .smiley.angry .eyes,
  #angry .smiley .eyes {
    margin-top: 35%;
  }

  .smiley.angry .eye::after,
  #angry .smiley .eye::after {
    width: 120%;
    height: 50%;
    transform: rotate(-35deg);
    top: -3px;
    left: -5px;
    border-radius: 0;
  }

  .smiley.angry .eye:first-of-type::after,
  #angry .smiley .eye:first-of-type::after {
    transform: rotate(35deg);
    left: auto;
    right: -5px;
  }

  #happy.active .smiley .mouth,
  #happy:hover .smiley .mouth {
    animation: move-mouth-down 0.8s;
    animation-delay: 0.3s;
    animation-iteration-count: 2;
  }

  #happy.active .smiley .eyes,
  #happy:hover .smiley .eyes {
    animation: move-eyes-down 0.8s;
    animation-delay: 0.3s;
    animation-iteration-count: 2;
  }

  #happy.active .smiley .eye:nth-of-type(2),
  #happy:hover .smiley .eye:nth-of-type(2) {
    height: 8px;
    margin-top: 0;
    animation: wink 0.8s;
    animation-delay: 0.3s;
    animation-iteration-count: 2;
  }

  #normal.active .smiley .eye,
  #normal:hover .smiley .eye {
    height: 8px;
    margin-top: 0;
    animation: blink 0.6s;
    animation-delay: 0.3s;
    animation-iteration-count: 2;
  }

  #angry.active .smiley .eyes,
  #angry.active .smiley .mouth,
  #angry:hover .smiley .eyes,
  #angry:hover .smiley .mouth {
    animation: move-angry-head 0.6s;
    animation-delay: 0.3s;
    animation-iteration-count: 2;
  }

  @keyframes move-angry-head {
    0% {
      transform: translateX(0%);
    }

    20% {
      transform: translateX(-20%);
    }

    40% {
      transform: translateX(15%);
    }

    60% {
      transform: translateX(-10%);
    }

    80% {
      transform: translateX(5%);
    }

    100% {
      transform: translateX(0%);
    }
  }

  @keyframes move-mouth-down {
    0% {
      bottom: 18%;
    }

    35% {
      bottom: 16%;
    }

    65% {
      bottom: 16%;
    }

    100% {
      bottom: 18%;
    }
  }

  @keyframes blink {
    0% {
      height: 8px;
      margin-top: 0;
    }

    25% {
      height: 2px;
      margin-top: 8px;
    }

    50% {
      height: 8px;
      margin-top: 0;
    }

    75% {
      height: 2px;
      margin-top: 8px;
    }

    100% {
      height: 8px;
      margin-top: 0;
    }
  }

  @keyframes wink {
    0% {
      height: 8px;
      margin-top: 0;
    }

    30% {
      height: 3px;
      margin-top: 8px;
    }

    70% {
      height: 3px;
      margin-top: 8px;
    }

    100% {
      height: 8px;
      margin-top: 0;
    }
  }

  @keyframes move-eyes-down {
    0% {
      margin-top: 15%;
    }

    35% {
      margin-top: 19%;
    }

    65% {
      margin-top: 19%;
    }

    100% {
      margin-top: 15%;
    }
  }
}
