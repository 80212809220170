@mixin NavigationCard {
  .cardTooltipWrapper {
    width: 215px;
    height: 100px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 0 22.3px 1.7px rgba(59, 59, 59, 0.14);
    border: solid 1px #ebebeb;
    background-color: #ffffff;
    position: absolute;
    left: 65px;
    top: 0;
    will-change: transform;

    .cardTooltipInnerCont {
      height: 100%;
      padding: 15px;

      .cardTooltipTextCont {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        font-size: 12px;
        width: calc(100% - 55px);
        padding-right: 5px;
        color: $c-66;
        float: left;

        .cardTitle {
          text-transform: capitalize;
          font-family: $font-medium;
          color: $c-33;
          font-size: 14px;
        }

        .cardDesc {
          text-align: left;
          padding-top: 5px;
          font-size: 11px;
          word-break: break-word;
          color: $c-66;
          line-height: 15px;
        }
      }

      .cardImgCont {
        float: right;
        .betaBadge {
          width: 40px;
          height: 20px;
          top: -2px;
          right: -7px;
        }

        img,
        svg {
          width: 80px;
          height: auto;
          object-fit: contain;
          padding: 0px;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}
aside {
  ::-webkit-scrollbar {
    width: 0.15em !important;
  }
}
.sideBar {
  position: fixed;
  padding: 0;
  margin: 0;
  width: 50px;
  background: #f4f4f6;
  height: calc(100vh - 50px);
  text-align: center;
  overflow: unset;
  z-index: 11;
  user-select: none;
  transition: height 0.2s ease-in-out;
  top: 50px;
  &.adjustZindex {
    z-index: 111;
  }
  a {
    display: inline-block;
    img {
      padding: 15px 0;
    }
  }

  .menuList {
    @include list-style-zero();
    @include flex-center();
    flex-direction: column;
    @include wt-ht(100%, 40px);

    .inboxIconWrapper {
      @include flex-center;
      position: relative;
      .count {
        @include flex-center;
        justify-content: center;
        background-color: red;
        color: $white;
        @include wt-ht(12px, 12px);
        padding: 2px;
        position: absolute;
        top: -4px;
        right: -6px;
        z-index: 2;
        border-radius: 50%;
        font-size: 10px;
        &.moreDigits {
          border-radius: unset;
          top: -8px;
          right: -14px;
          @include wt-ht(fit-content, auto);
          padding: 1px;
        }
        &.littleMoreDigits {
          @include wt-ht(14px, 14px);
        }
      }
    }

    a {
      padding: 10px;
      margin: 2px 0px;
      @include wt-ht(100%, 38px);
      text-decoration: none;
      @include flex-center();
      justify-content: center;
      cursor: pointer;
      position: relative;

      #templates,
      #contacts,
      #team,
      #settings,
      #sequences,
      #tasks,
      #logout,
      #pricing,
      #reports,
      #dashboard,
      #calls,
      #tracking,
      #contactTimeline {
        height: 20px;
        width: 20px;
        background-size: 20px;
      }
      .svgIcon {
        @include flex-center();
        svg {
          fill: #8d8d8d;
          @include wt-ht(22px, 22px);
        }
      }

      #dashboardNew,
      #discover,
      #sequences {
        svg {
          @include wt-ht(20px, 20px);
        }
      }
      #calls,
      #reports,
      #tracking {
        svg {
          @include wt-ht(18px, 18px);
        }
      }
      .inboxIcon {
        font-size: 18px;
        color: $c-8d;
      }

      &.selected-path {
        .svgIcon svg path {
          fill: $blue-violet;
        }
      }

      &.selected-path .inboxIcon {
        color: $blue-violet !important;
      }

      @include NavigationCard();
    }
  }

  @mixin bottomMenu {
    padding: 8px;
    margin: 0px;

    .menuIcon {
      height: 20px;
      width: 20px;
      background-size: 20px;
      cursor: pointer;
      display: block;
      .material-icons {
        color: #e6e5eb;
      }
    }
  }

  @mixin helpWrapper {
    position: absolute;
    background: #fff;
    text-align: left;
    bottom: 0px;
    z-index: 8;
    left: 40px;
    border-radius: 10px;
    border: solid 1px #ebebeb;
    box-shadow: 0 0 22.3px 1.7px rgba(59, 59, 59, 0.14);
    line-height: normal;
  }

  .tooltipPoiner {
    position: absolute;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    border-radius: 4px;
    background: #fff;
    padding: 7px;
    z-index: 0;
    left: -7px;
    bottom: 15px;
  }

  .bottomNav {
    justify-content: center;
    position: absolute;
    bottom: 0;
    @include flex-center();
    width: 100%;
    margin-bottom: 10px;
    color: #a29cc4;
    flex-direction: column;

    .helpMenuContainer {
      @include bottomMenu;

      .helpIcon {
        background-image: url("../../assets/png/helpMenu.png");
      }

      .helpMenuList {
        @include helpWrapper();

        .dropDownLists {
          padding: 10px 0px;
          display: flex;
          flex-direction: column;
          min-width: 225px;
          position: relative;
          overflow: hidden;

          .helpTypeCont {
            padding: 5px 15px;
            cursor: pointer;
            @include flex-center();

            &:hover {
              background-color: #e3f0fd;
            }

            .helpTypeIcon {
              height: 16px;
              width: 16px;
              object-fit: contain;
            }

            .helpType {
              @include flex-center();
              justify-content: flex-start;
              padding: 5px 30px 5px 10px;
              font-size: 14px;
              color: $c-66;
              line-height: 14px;
            }
          }
        }
      }
    }

    .textWrapper {
      @include helpWrapper();
      padding-bottom: 10px;

      textarea {
        border-radius: 5px;
        border: solid 1px #ebebeb;
        background-color: #ffffff;
        resize: vertical;
        height: 150px;
        min-height: 150px;
        max-height: 300px;
        width: 300px;
        padding: 10px;
        margin: 5px;
        color: $c-66;
        font-size: 13px;

        &::placeholder {
          font-size: 12px;
          color: #60636780;
          font-family: $font-regular, sans-serif;
        }
      }

      .buttonCont {
        @include flex-center();
        flex-direction: row;
        justify-content: flex-end;
        padding: 0px 5px;

        .cancelBtn {
          border: solid 1px $b-c-1;
          background-color: #fff;
          margin-right: 15px;
          color: $c-66;
        }

        .button {
          font-size: 13px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          cursor: pointer;
          height: 30px;
          width: fit-content;
        }
      }
    }

    .tutorialContainer {
      @include bottomMenu;

      &:hover {
        transition: all 0.1s ease-in-out;
        background: $c-373772;
        border-radius: 35px;
      }

      #tutorial {
        // background-image: url("../../assets/png/playIcon.png");
        // background-repeat: no-repeat;
        height: 23px;
        width: 22px;
        // background-size: 23px;
        cursor: pointer;
        display: block;
      }
      .ringContainer {
        position: relative;
        .circle {
          width: 10px;
          height: 10px;
          background-color: #ec9a20;
          border-radius: 50%;
          position: absolute;
          top: -7px;
          left: 20px;
        }

        .ringring {
          border: 3px solid #e46636;
          -webkit-border-radius: 30px;
          height: 25px;
          width: 25px;
          position: absolute;
          left: 13px;
          top: -15px;
          -webkit-animation: pulsate 1s ease-out;
          -webkit-animation-iteration-count: infinite;
          opacity: 0;
        }
      }
    }
  }
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
