@mixin Section {
  padding: 5px 0px;

  .sectionTitle {
    padding: 0px 0px 15px 0px;
    text-align: left;
    color: $c-66;
    font-size: 18px;
    font-weight: 500;
    line-height: 0.92;
    letter-spacing: normal;
    font-family: $font-medium;
    .iconPosition {
      position: relative;
      top: 8px;
      left: 5px;
    }
  }

  &.companyInfo {
    width: 50%;
    display: inline-block;
    float: left;
    max-width: 540px;
  }

  &.contactNotes {
    width: 50%;
    display: inline-block;
    max-width: 540px;
    float: left;
    height: 120px;

    .notes-section {
      width: 100%;
      .txt-cont {
        width: 100%;
        height: 80px;
        color: $c-66;
      }
    }
  }

  &.contactTags {
    z-index: 999;
    .sectionTitle {
      padding-bottom: 0px;
    }

    .parentWrapper {
      display: grid;
      grid-template-columns: 25% 75%;
      height: 100px;
      align-items: center;

      .sectionInputTagsCont {
        .multiSelectDropDown {
          max-width: 250px;
          z-index: 99 !important;
          .multiSelectInput {
            margin: 0px;

            .inputCont {
              padding: 0;
            }
          }

          .innerCont {
            .listCont {
              top: 54px;
            }
          }
        }
      }

      .previewTagWrapper {
        @include previewTags();
        border: 1px solid $c-dd;
        padding: 7px;
        border-radius: 6px;
        max-height: 90px;
        overflow: hidden;
        transition: all 0.3s;

        .previewTagsCont {
          padding: 0px 10px 0px;
          max-height: 75px;
          margin: 0px;

          .selectedTag {
            padding: 2px 8px;
            .selecteTagValue {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

.customFieldsWrapper .sectionInputTagsCont {
  display: grid;
  .upgradeTooltipSection {
    .tooltipCard {
      .title {
        background: #262626 !important;
      }
    }
  }
  grid-template-columns: repeat(4, 1fr);
  .addCustomFields {
    width: 200px;
    border: 1px dashed #959cf2;
    color: #8d8d8d;
    cursor: pointer;
    display: flex;
    padding: 5px;
    justify-content: space-between;
    border-radius: 10px;
    font-size: 13px;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    .upgradeTooltipSection {
      &.unlimitedPlan,
      &.enterprisePlan {
        width: 385px;
      }
      .tooltipCard {
        .title {
          padding: 5px;
        }
      }
    }
  }
}

.popupWrapper {
  &.editContactPopup {
    .confirmation-popup {
      width: 80%;
      height: 90%;
      min-width: 1230px;

      .popup-wrap {
        max-width: 100%;
        height: 100%;

        .title {
          padding: 25px 40px 25px 40px;
        }

        .popup-content-wrap {
          overflow-y: auto;
          margin-right: 20px;
          padding: 0px 15px 0px 40px !important;
          will-change: unset;

          .inputWrapper {
            width: 100%;
            max-width: 250px;
            margin-right: 20px;
            position: relative;
            .upgradeTooltipSection {
              title {
                background: rgb(38, 38, 38) !important;
                h4 {
                  color: white !important;
                }
              }
            }
            .inputCont {
              padding-top: 15px !important;
              &.readOnlyInput {
                .focus-border {
                  display: none;
                }

                .inputLabel {
                  bottom: 36px;
                  will-change: font-size;
                  font-size: 11px;
                  transition: all 0.2s linear;
                }

                .input-box {
                  border: 0px;
                }
              }
            }
            .errMsg {
              position: absolute;
              bottom: -8px;
              font-size: 11px;
              color: red;
            }
          }

          .rowBreaker {
            @include flex-center();
            flex-direction: row;
            .errMsg {
              bottom: -16px;
            }
          }

          .info {
            @include Section();
          }

          .companyAndNotes {
            margin-bottom: 20px;
            @include flex-center();
          }
        }

        .popup-action-wrap {
          padding-bottom: 25px !important;
          justify-content: flex-end;

          .positive-btn {
            margin: 0;
          }
        }
      }
    }
  }
}
