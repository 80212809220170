.prospectsBody,
.seqContactWrapper {
  height: 100%;
  .seq-contact-card {
    box-shadow: none;
  }
}

.seqContactWrapper {
  &.contactFilterShown {
    display: grid;
    grid-template-columns: auto 300px;
    grid-gap: 12px;
    .seq-contacts-section {
      overflow: hidden;
    }
  }
}

.seq-contacts-section {
  height: 100%;
}

.seq-contacts-table-section {
  &.seqContactPage {
    background: white;
    border: 1px solid $b-c-1;
    border-bottom: none;
    border-radius: 6px;
    .contactContent {
      position: relative;
      background: white;
    }
  }
  .contactContent {
    overflow: auto;
    height: 100%;
    .seqContactsTableList:nth-child(1) .email-insight-stage li {
      .customTooltip {
        .sharpPointer {
          border-top-right-radius: unset;
          border-bottom-left-radius: 10px;
          top: 0;
        }
        .tooltipBody {
          top: 5px;
        }
      }
    }
  }
}

.seq-contact-nav-section {
  position: relative;
  background: transparent;
  .seq-contact-list {
    @include list-style-zero();
    display: grid;
    width: 100%;
    grid-template-columns: repeat(16, calc(100% / 10 - 10px));
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    transition: all 200ms ease;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px 0px 5px;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0);
    text-shadow: 0 0 $c-78;
    &.filterWrapper {
      padding: 10px 20px;
    }
    .seq-contact-card {
      position: relative;
      padding: 10px 8px;
      border-radius: 5px;
      background: $white;
      cursor: pointer;
      transition: background 200ms ease;
      box-shadow: 0 0 15px 0 rgba(59, 59, 59, 0.05);
      border: solid 1px $c-ed;
      background-color: $white;

      &.coming-soon-card {
        background: $c-f1;
        h6 {
          font-style: italic;
        }
        h6,
        p {
          font-family: $font-regular !important;
          font-size: 12px !important;
          color: $c-ab;
          font-weight: 100 !important;
        }

        i {
          color: $c-ab;
        }

        &:hover {
          border-color: #ebebeb !important;
          cursor: unset;
          background: #f1f1f1;
        }
      }

      &:hover {
        background: #fcfcfc;
        border-color: #c4c4c4;
        transition: background 200ms ease;
      }

      &.active {
        z-index: 1 !important;
        pointer-events: none;
        border-top: 4px solid $c-ff9 !important;

        &:hover {
          border-color: #c4c4c4;
        }

        .sharp-pointer {
          position: absolute;
          width: 0;
          height: 0;
          border-bottom: 1px solid $c-ed;
          border-right: 1px solid $c-ed;
          background: $white;
          font-size: 0;
          line-height: 0;
          padding: 5px;
          bottom: -7px;
          left: 50%;
          transform: rotate(45deg);
          transition: all 200ms ease;
        }
      }
      .seq-contact-body {
        position: relative;
        .seq-contact-content {
          position: relative;
          .flexValue {
            @include flex-center;
            justify-content: space-between;
            .liIcon svg {
              @include wt-ht(14px, 14px);
              fill: #0a66c2;
              position: relative;
            }
          }
          h6 {
            font-size: 16px;
            font-family: $font-semi-bold;
            margin-bottom: 5px;
            line-height: 1.3;
            height: 20px;
            &.activeNo {
              color: $c-ff9;
            }
          }
          p {
            font-size: 14px;
            @include ellipsis;
          }
        }
        i {
          font-size: 20px;
        }
        .cardSvgIcon {
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .linear-background {
      transition: all 200ms ease;
      height: 67px;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 0.4em;
      border-radius: 4px;
      background-clip: padding-box;
      border: 4px solid transparent;
      box-shadow: inset 0 0 0 10px;
      scrollbar-width: thin !important;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        border-color: #ddd;
      }
    }
  }
}

.seqContactWrapper.stepsListShown {
  display: grid;
  height: 100%;
  grid-template-columns: calc(100% - 135px) 125px;
  grid-gap: 10px;

  .seq-contacts-section {
    overflow: hidden;
  }
}
