%eventsContainer {
  @extend %relBlock;
  @include wt-ht(100%, calc(100% - 115px));
  padding: 10px 20px 5px 30px;
  &.emptyLoader {
    overflow: auto;
  }
  .eventCard {
    cursor: pointer;
    z-index: 3;
  }
  // Component
  .eventCard,
  .eventCardLoader {
    position: relative;
    display: grid;
    width: calc(100% - 10px);
    grid-template-columns: 300px calc(100% - 440px) 115px; // includes padding 20+20 = 40
    padding: 20px;
    margin-right: 10px;
    column-gap: 10px;
    height: 64px;
    align-items: center;
    border: 1px solid $c-dd;
    border-radius: 5px;
    min-width: 875px;
    max-width: 100%;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .eventToggleBtn {
      position: relative;
      @include flex-center;
      .toggleBox.linear-background {
        @include wt-ht(32px, 14px);
        margin-right: 8px;
        top: 0;
      }
    }
    .toogleSequence {
      @extend %toggleBtn;
      z-index: 10 !important;
    }
    .toggleText {
      position: relative;
      top: 2px;
      color: $c-3b;
      font-size: 14px;
      @include ellipsis;
      &.linear-background {
        top: unset;
        @include wt-ht(200px, 12px);
      }
    }
    .durationIntervalPeriod {
      position: relative;
      font-size: 14px;
      &.linear-background {
        @include wt-ht(160px, 12px);
      }
    }
    .eventActionIcon {
      position: relative;
      @include flex-center;
      justify-content: flex-end;
      .actionIconTooltip {
        .customTooltip {
          .sharpPointer {
            right: 5px;
            top: -2px;
          }
          .tooltipBody {
            top: 2px;
          }
        }
        &:not(:last-child) {
          margin-right: 5px;
        }
        .actionIcon {
          @extend %relBlock;
          font-size: 16px !important;
          &.linear-background {
            @include wt-ht(24px, 24px);
          }
        }
        #cloneEvent {
          font-size: 14px !important;
        }
        #copyEventLink {
          font-size: 18px !important;
        }
      }
    }
  }

  // List Event
  .eventsListTable {
    @extend %relBlock;
    @include wt-ht(100%, calc(100% - 42px));
    .eventsCardList {
      @extend %relBlock;
      height: 100%;
      overflow: scroll;
      &.paginationEnabled {
        @include wt-ht(100%, calc(100% - 36px));
      }
    }
  }
  .emptyEventSection {
    @extend %relBlock;
    @include wt-ht(100%, calc(100% - 42px));
    @include flex-align-center;
    flex-direction: column;
    img {
      @extend %relBlock;
      margin: 0 auto 30px;
      width: 100px;
    }
    .text {
      font-size: 16px;
      color: $c-66;
      text-align: center;
    }
  }
}
