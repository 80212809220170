.userProfileSection {
  @extend %relBlockWidth;
  height: calc(100% - 56px);
  overflow: auto;
  padding: 20px 35px;

  .popupBody {
    position: relative;
    display: block;
    top: 15px;
    overflow: auto;
    padding: 10px 0px;
    width: 70%;
    @media (max-width: 1080px) {
      width: 100%;
    }
    .formInputGroup {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 10px));
      @media (max-width: 767px) {
        grid-template-columns: 100%;
      }
      justify-content: space-between;
      row-gap: 10px;
      position: relative;
      .changeWrap {
        position: relative;
        display: block;
        .changeBtn {
          position: absolute;
          right: 0;
          top: 10px;
          font-size: 12px;
          color: $blue-violet;
          font-weight: 500;
          cursor: pointer;
          background: #3953fb29;
          padding: 4px 13px;
          border-radius: 3px;
        }
      }
    }
    .inputWrapper {
      @extend %inputWrapper;
      &.phoneNumber {
        margin-bottom: 20px;
      }
    }
    .userProfileApiKeyWrapper {
      width: 100%;
    }
    .emailPreference {
      margin: 20px 0 20px;
      h6 {
        font-size: 16px;
        margin-bottom: 15px;
      }

      .chkBoxSection {
        position: relative;
        display: flex;
        i {
          margin-left: 10px;
          color: $blue-violet;
        }
        .checkBoxSection {
          padding: 0;
        }
      }
    }
    .btnSection {
      position: relative;
      margin-top: 10px;

      button {
        font-size: 14px;
        padding: 10px 0;
        border-radius: 5px;
        letter-spacing: 0.3px;
        min-width: 120px;
        margin: unset;

        &.updateProfileBtn {
          border: solid 1px $blue-violet;
          background: $blue-violet;
          &.disabledBtn {
            @include disable(0.5, unset);
          }
        }

        &.loading {
          @include wt-ht(34px !important, 34px !important);
          transition: all 0.3s ease-in-out;
          min-width: auto;
          border-radius: 35px;
          .load-text {
            @include wt-ht(24px, 24px);
            top: 4px;
            left: 4px;
          }
        }
      }

      .deactiveBtn {
        @extend %relBlock;
        margin: unset;
        margin-top: 60px;
        padding-top: 25px;
        border: 1px solid $c-dd;
        background: $c-fa;
        cursor: pointer;
        color: $c-8d;
        padding: 10px 32px;
        letter-spacing: 0.3px;
        font-size: 16px;
        justify-content: start;
      }
    }
  }

  .userProfileLoader {
    @extend %relBlock;
    .inputLoader {
      height: 16px;
      width: 240px;
      border-radius: 35px;
      margin-top: 20px;
    }
    .checkBoxHeader {
      margin-top: 35px;
      width: 200px;
      height: 14px;
      border-radius: 35px;
      margin-bottom: 10px;
    }
    .checkboxLoader {
      margin: 15px 0 35px;
      @include flex-direction(row);
      .checkBox {
        height: 16px;
        width: 16px;
        margin-right: 10px;
        border-radius: 5px;
      }
      .checkBoxContent {
        height: 16px;
        width: 340px;
        border-radius: 35px;
      }
    }
    .saveBtnLoader {
      margin-top: 35px;
      width: 140px;
      height: 30px;
      margin-bottom: 25px;
      border-radius: 5px;
    }
    .deactivateBtnloader {
      margin-bottom: 35px;
      width: 180px;
      height: 18px;
      border-radius: 35px;
    }
  }

  .profileSaveSection {
    position: absolute;
    bottom: 16px;
    right: 63px;
    button {
      font-size: 14px;
      padding: 10px 0;
      border-radius: 5px;
      letter-spacing: 0.3px;
      min-width: 120px;
      margin: unset;

      &.updateProfileBtn {
        border: solid 1px $blue-violet;
        background: $blue-violet;
        &.disabledBtn {
          @include disable(0.5, unset);
        }
      }

      &.loading {
        @include wt-ht(34px !important, 34px !important);
        transition: all 0.3s ease-in-out;
        min-width: auto;
        border-radius: 35px;
        .load-text {
          @include wt-ht(24px, 24px);
          top: 4px;
          left: 4px;
        }
      }
    }
  }

  .overlayExpMask {
    @include bg-mask(99, rgba(0, 0, 0, 0.57));
  }
}

.changePasswordSection {
  @extend %popupCard;
  .cardBody {
    padding: 11px 0 10px;
    .popupBody {
      width: 100%;
    }
    .updateBtn {
      @extend %relBlock;
      text-align: center;
      .changePassword {
        background: $blue-violet;
        padding: 0 30px;
        letter-spacing: 0.5px;
        font-size: 14px;
        will-change: font-size;
        font-weight: bold;
        height: 36px;
        &.hover {
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
        }
        &.loading {
          width: unset;
          margin: 0 auto;
          padding: 0;
        }
      }
    }
  }
}

.overlayMaskProfile {
  @include bg-mask(1, rgba(0, 0, 0, 0.57));
}

.apiKeyWrapper {
  .apiKeyContainer {
    @include flex-center();
    width: 220px;
    @extend %inputWrapper;
    .apiKey {
      margin-bottom: 0;
      width: 100%;
      .readOnlyInput {
        .inputLabel {
          bottom: 36px;
          will-change: font-size;
          font-size: 11px;
          transition: all 0.2s linear;
        }

        .inputBox {
          border: 0px;
        }
      }
    }
    .copyIcon,
    .visibilityIcon {
      cursor: pointer;
      font-size: 14px;
      &:hover {
        background: $c-f5;
        color: black;
        transition: all 0.3s ease;
      }
    }
    .visibilityIcon {
      margin-right: 8px;
      font-size: 16px;
    }
  }
}
