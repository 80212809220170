.popup {
  position: fixed;
  @include flex-center();
  justify-content: center;
  letter-spacing: 0.3px;
  @include wt-ht(100%, 100%);
  z-index: 100;
  .popupOverlay {
    @include bg-mask(100, rgba(0, 0, 0, 0.65));
  }
  .popupCard {
    position: relative;
    display: block;
    z-index: 101;
    border-radius: 10px;
    padding: 30px 30px 0;
    background: #fff;
    &.expProcessWt {
      width: 400px;
      min-height: 300px;
    }
    .popupTitle {
      position: relative;
      display: block;
      .titleSm {
        position: relative;
        font-size: 18px;
        color: $black;
        font-weight: 500;
        text-align: center;
      }
    }
    .popupBody {
      padding-top: 20px;
      position: relative;
      display: block;
      .progressBar {
        padding-bottom: 40px;
      }
      .popupText {
        position: relative;
        text-align: center;
        color: $c-66;
        .blueText {
          color: $blue-violet;
        }
      }
      .popupBtn {
        text-align: center;
        padding: 15px 0 30px;
      }
    }
    .imgWrap {
      position: relative;
      display: block;
      text-align: center;
      .contactImg {
        width: 200px;
        margin-bottom: -6px;
      }
      .dotWaveBg {
        position: absolute;
        bottom: 0px;
        left: -30px;
        right: -30px;
        width: 400px;
        z-index: -1;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
  }
}
