.emailNotifyPopup {
  position: fixed;
  @include flex-center();
  justify-content: center;
  letter-spacing: 0.3px;
  @include wt-ht(100%, 100%);
  z-index: 100;
  .popupOverlay {
    @include bg-mask(100, rgba(0, 0, 0, 0.65));
  }
  .emailNotifyPopupCard {
    position: relative;
    display: block;
    z-index: 101;
    border-radius: 10px;
    background: #fff;
    padding-top: 0px;
    width: 435px;
    min-height: 300px;
    .emailNotifyPopupTitle {
      position: relative;
      display: block;
      padding: 16px;
      border-bottom: 1px solid $c-dd;
      .emailNotifyTitleSm {
        position: relative;
        font-size: 18px;
        color: $black;
        font-weight: 500;
        text-align: center;
      }
    }
    .emailNotifyPopupBody {
      position: relative;
      display: block;
      padding: 15px;
      .progressBar {
        padding-bottom: 40px;
      }
      .popupText {
        position: relative;
        text-align: center;
        color: $c-66;
        line-height: 30px;
        .blueText {
          color: $blue-violet;
        }
      }
      .popupBtn {
        text-align: center;
        padding: 15px 0 15px;
      }
    }
  }
}
