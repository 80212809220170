.emailAutoCompleteDropdown {
  .drop-down-menu {
    left: 0;
    border-radius: 5px;
    .dropdownList {
      margin: 0;
      max-height: 246px;
      .option {
        padding: 8px 8px !important;
        &:not(:last-child) {
          border-bottom: 1px solid $c-ee;
        }
        .listItem .listItemWrap {
          width: 220px !important;
          @include ellipsis();
          .itemName {
            font-size: 15px;
            color: $c-343a40;
            margin-bottom: 2px;
            .addTxt {
              color: $c-3b !important;
            }
          }
          .itemEmail {
            font-size: 12px;
            color: $c-78;
          }
        }
      }
    }
  }
}
