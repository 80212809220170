.multiSelectDropDown {
  position: relative;
  z-index: 999;
  overflow: unset;

  .multiSelectInputCont {
    @extend %inputWrapper;
    margin-bottom: 0px;
    position: relative;

    div[aria-label="animation"] {
      position: absolute;
      top: -12px;
      z-index: 0;
      right: -45px;
    }

    .downArrowBlack {
      position: absolute;
      right: 0;
      transition: all 0.3s;
      padding-left: 0px;
      width: fit-content;
      cursor: pointer;

      &.top {
        transform: rotate(180deg);
      }
    }
  }

  .innerCont {
    .listCont {
      position: absolute;
      width: 100%;
      font-family: inherit;
      background: $white;
      z-index: 999999;
      color: $c-66;
      min-width: 165px;
      height: fit-content;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      border-radius: 10px;
      box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
      border: 1px solid $c-eb;
      opacity: 1;
      bottom: 50px;
      -webkit-transition: opacity 0.4s ease-in-out;
      transition: opacity 0.4s ease-in-out;
      top: 40px;
      height: fit-content;
      max-height: 145px;
      overflow-y: auto;
      padding: 5px;

      .noTags {
        position: relative;
        padding: 5px;
        display: flex;
        align-items: center;
        flex-direction: row;
        color: $c-66;
        font-size: 14px;
      }

      .tagOption {
        .checkBoxName {
          padding-left: 10px;
          color: $c-66;
          cursor: pointer;
          max-width: 155px;
          @include ellipsis();
          white-space: pre-wrap;
          line-height: 14px;
          text-align: left;
        }

        &:hover {
          background: $c-f5;
          transition: all 0.3s;
          border-radius: 6px;
        }

        .newOption {
          position: relative;
          padding: 5px;
          display: flex;
          align-items: center;
          flex-direction: row;
          cursor: pointer;
          font-size: 14px;
          word-break: normal;

          .newOptionValue {
            padding-left: 5px;
            color: $blue-violet;
          }
        }
      }
    }
  }

  .transparentOverlay {
    transition: all 300ms ease;
    @include bg-mask(9, transparent);
  }
}
