.contactTagList {
  position: relative;
  @include flex-center;
  flex-direction: row;
  z-index: 4;
  border-radius: 10px;
  transition: all 0.2s;

  .tagLabel {
    font-size: 12px;
    font-style: italic;
    color: $c-7b;
    display: flex;
    flex: 0 0 32px;
    max-width: 32px;
  }
  .selectedTags {
    @include list-style-zero;
    @include flex-center;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 4px;
    @include wt-ht(100%, auto);
    overflow: hidden;
    padding-top: 2px;
    transition: all 0.2s;
    will-change: transform;
    li.tagItem {
      position: relative;
      will-change: transform;
      @include flex-center;
      justify-content: space-between;
      background: #fffdf5;
      color: #494b4d;
      border-radius: 35px;
      border: 1px solid #ffeaa9;
      height: 28px;
      padding: 2px 4px 2px 12px;
      margin-right: 8px;
      margin: 4px;
      .tagName {
        font-size: 14px;
        padding-right: 5px;
      }
      i {
        font-size: 12px;
        padding: 3px;
        cursor: pointer;
        border-radius: 35px;
        &:hover {
          background: $c-f5;
        }
      }
    }
    &.addShowMore {
      max-width: calc(100% - 110px);
      height: 36px;
      overflow: hidden;
      padding: 0;
    }
  }
  &.showMoreTags {
    box-shadow: 0 0 25.1px 1.9px rgba(59, 59, 59, 0.07);
    border: solid 1px rgba(223, 223, 223, 0.38);
    background: white;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 10px;
    transition: all 0.2s;
    align-items: flex-start;
    justify-content: flex-end;
    .tagLabel {
      align-content: flex-start;
      padding-top: 10px;
    }
    .moreOptions {
      margin-top: 5px;
      &:hover {
        background: $c-f5;
        color: $c-33;
      }
    }
  }
  .moreOptions {
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    width: 10%;
    color: $blue-violet;
    border-radius: 6px; 
    height: 28px;
    width: 100px;
    @include flex-center;
    justify-content: center;
    &:hover {
      background: white;
      box-shadow: 0 3px 6px 0 rgba(59, 59, 59, 0.07);
    }
  }
}
