.installPluginBanner {
  position: relative;
  @include flex-center;
  @include wt-ht(100%, 40px);
  padding: 0 20px;
  justify-content: center;
  background-color: $hb-sandal-yellow;
  font-size: 14px;
  color: $c-44;
  z-index: 1;
  .text {
    margin-right: 5px;
  }
  .installSalesgearPluginBtn {
    position: relative;
    background: transparent;
    border: 1px solid transparent;
    color: $hb-text;
    cursor: pointer;
    font-weight: 500;
    margin: unset;
    cursor: pointer;
    padding: 5px 10px;
    transition: all 0.3s;
    width: 130px;
    margin-right: 5px;
    i {
      position: absolute;
      right: 0;
      transform: rotate(180deg);
      font-size: 18px;
      position: relative;
      margin-left: 5px;
      transition: all 0.3s;
    }
    &:hover {
      transition: all 0.3s;
      background: bisque;
      font-weight: 500;
      i {
        transition: all 0.3s;
        right: -5px;
      }
    }
  }

  .closeIcon {
    @include flex-center();
    position: absolute;
    right: 10px;
    i {
      color: $c-66;
      font-size: 22px;
      padding: 2px;
      cursor: pointer;

      &:hover {
        background-color: $c-dd;
        border-radius: 50%;
      }
    }
  }
}
