.connectInboxSection {
  @extend %relBlock;
  @include wt-ht(100%, 100%);
  background: white;

  .sectionHeader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    padding: 0 16px;
    border-bottom: 1px solid $c-ec;

    .backBtnAndHeader {
      @include flex-center;

      .navigateToInbox {
        @include flex-align-center;
        margin-right: 10px;
        @include wt-ht(30px, 30px);
        border-radius: 6px;
        cursor: pointer;

        &:hover {
          background: $c-ec;
        }

        .backBtn {
          font-size: 20px;
        }
      }

      h6 {
        position: relative;
        display: block;
        font-size: 16px;
        font-family: $font-regular;
        color: $c-66;
        font-weight: 400;
      }
    }
  }

  .connectedInboxBody {
    @extend %relBlock;
    padding: 15px 10px;
    overflow-y: auto;
    background: white;
    height: calc(100% - 70px);

    &.adjustHeight {
      height: calc(100% - 116px);
    }

    &.enableSmtp {
      height: calc(100% - 34px);
    }

    .connectedInboxCard {
      position: relative;
      padding: 12px 20px;
      display: grid;
      grid-template-columns: 3% 20% 10% auto 15%;
      align-items: center;
      border: 1px solid $c-ec;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
      border-radius: 5px;

      cursor: pointer;

      &:hover {
        background: #f3f6ff;
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      .imgType {
        position: relative;
        display: flex;

        svg {
          @include wt-ht(20px, 20px);
        }
      }

      .customTooltip {
        background: #393a3d;

        .sharpPointer {
          background: #393a3d;
          border: 1px solid transparent;
          padding: 5px;
          border-radius: unset;
          top: 2px;
          right: 147px;
        }

        .tooltipBody {
          width: fit-content;
          right: -10px;
          white-space: nowrap;
          background: #393a3d;
          right: -23px;
          top: 7px;
        }

        .tooltipText {
          color: $white;
          padding: 8px;
        }
      }

      .warmerMetricsWrapper {
        padding-left: 10px;
        @include flex-center();

        .customTooltip {
          background: #393a3d;

          .sharpPointer {
            background: #393a3d;
            border: 1px solid transparent;
            padding: 5px;
            border-radius: unset;
            top: 8px;
            right: 25px;
          }

          .tooltipBody {
            width: fit-content;
            right: -10px;
            white-space: nowrap;
            background: #393a3d;
            right: -23px;
            top: 13px;
          }

          .tooltipText {
            color: $white;
            padding: 8px;
          }
        }

        .warmerMetrics {
          @include flex-center;
          font-size: 14px;
          width: 83px;
        }

        .warmerMetricsIcon {
          @include flex-center;
          margin-right: 8px;

          svg path {
            fill: $c-8d;
          }

          &.low {
            svg path {
              fill: #ff0000;
            }
          }

          &.medium {
            svg path {
              fill: #ff9e10;
            }
          }

          &.high {
            svg path {
              fill: #28ad56;
            }
          }

          &.middle {
            svg path {
              fill: #3953fb;
            }
          }
        }

        .warmerMetricsValue {
          position: relative;
          top: 1px;
          color: $c-3b;

          &.low {
            color: #ff0000;
          }

          &.medium {
            color: #ff9e10;
          }

          &.high {
            color: #28ad56;
          }

          &.middle {
            color: #3953fb;
          }
        }
      }

      .btnInboxActions {
        display: grid;
        grid-template-columns: auto 30px;
        justify-content: flex-end;
        padding-left: 12px;

        .deleteInboxIcon,
        .configureBtn {
          cursor: pointer;
          padding: 6px;
          @include flex-center;
          justify-content: center;
          @include wt-ht(100%, 100%);

          &:hover {
            background: white;
            height: 28px;
            border-radius: 36px;
          }

          svg {
            @include wt-ht(14px, 14px);
            fill: $c-8d;
          }
        }
      }

      .warmingEnabledOrDisabledBtn {
        position: relative;

        #warmingEnableBtn {
          background-color: transparent;
          width: 180px;
          border-radius: 20px;
          height: 28px;
          cursor: pointer;

          &.enableWarming {
            border: 1px solid $c-8d;
            color: $c-3b;

            svg,
            path {
              fill: $c-8d;
            }
          }

          &.warmingError {
            border: none;
            color: $c-3b;

            svg {
              @include wt-ht(22px, 22px);

              path {
                fill: #ff0000;
              }
            }
          }

          &.warmingEnabled {
            border: 1px solid $c-8d;
            color: $c-3b;

            svg,
            path {
              fill: $c-28;
            }
          }

          &.loading {
            transition: all 0.3s ease-in-out;
            min-width: auto;
            border-radius: 35px;
            @include wt-ht(30px !important, 30px !important);
            background: $c-8d;

            .load-text {
              @include wt-ht(20px, 20px);
              top: 4px;
              left: 4px;
            }
          }
        }

        .customTooltip {
          background: #393a3d;

          .sharpPointer {
            background: #393a3d;
            border: 1px solid transparent;
            padding: 5px;
            border-radius: unset;
            top: 2px;
            right: calc(50% - 2px);
          }

          .tooltipBody {
            width: fit-content;
            white-space: nowrap;
            background: #393a3d;
            top: 7px;
            right: -15%;
          }

          .tooltipText {
            color: $white;
            padding: 8px;
          }
        }

        &.errorBtn {
          .customTooltip {
            .tooltipBody {
              min-width: 60px;
              @include flex-align-center;
              right: 42%;
            }
          }
        }
      }

      .reconnectInbox {
        position: relative;
        width: fit-content;
        @include flex-align-center;
        padding: 6px 8px;
        font-size: 14px;
        border: 1px solid $blue-violet;
        border-radius: 15px;
        margin-left: 6px;
        height: 28px;
      }

      .wrapConnectedEmail {
        position: relative;

        .connectedEmail {
          @extend %relBlock;
          font-weight: 500;
          font-size: 14px;
          color: $c-27;
          @include ellipsis;
        }

        .userName {
          @extend %relBlock;
          font-weight: 400;
          margin-bottom: 2px;
          font-size: 14px;
          color: $c-44;
        }
      }

      &:first-child .defaultType {
        visibility: unset;
      }

      &.inboxRowloader {
        height: 60px;
        grid-template-columns: 36px 25% auto 23% 30px 30px;

        .imgType {
          @include wt-ht(24px, 24px);
        }

        .wrapConnectedEmail {
          .connectedEmail {
            @include wt-ht(160px, 12px);
          }

          .userName {
            margin-bottom: 4px;
            @include wt-ht(100px, 10px);
          }
        }

        .warmerMetricsWrapper {
          @include wt-ht(75%, 12px);
        }

        .warmingEnabledOrDisabledBtn {
          @include wt-ht(150px, 24px);
        }

        .deleteInboxIcon,
        .configureBtn {
          @include wt-ht(24px, 24px);
        }
      }

      .groupWarmingFields {
        @include flex-align-center();
        justify-content: space-between;
        border: 1px solid #dddd;
        border-radius: 15px;
        background-color: #fafafa;
        justify-self: end;
      }

      .notGroupWarmingFields {
        display: flex;
        justify-self: end;
      }
    }

    #addAccount {
      height: 40px;
      border: 1px solid #eee;
      color: $c-78;
      font-weight: bold;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
      font-size: 14px;
      background: $white;
      border-radius: 2px;
      letter-spacing: 0.3px;
    }

    .emptyInbox {
      @extend %emptyInbox;
    }
  }

  .inboxPaginationAndLimit {
    position: relative;
    @include flex-center;
    justify-content: flex-end;
    padding: 10px 30px;
    @include wt-ht(100%, 60px);

    .pagination-section {
      border-top: 0;
    }

    .pageLimit {
      .limitDropdown {
        top: auto;
        bottom: 40px;
      }
    }
  }

  .configurationSection {
    position: relative;
    display: block;
    @include wt-ht(100%, 100%);

    .navigationAndDateFilter {
      position: relative;
      @include flex-center;
      justify-content: space-between;
      border-bottom: 1px solid $c-dd;

      .filterByDateTab {
        .tabItem {
          .dateRange {
            font-size: 18px;
            margin-right: 6px;
          }
        }
      }

      .tabNavigation {
        background: white;

        .tabItem {
          height: 60px;
        }
      }

      .filterByDateTab {
        position: relative;
        margin-right: 20px;

        .tabItem {
          font-size: 14px;
          height: 34px;
        }
      }
    }

    .configureInboxSetting {
      position: relative;
      overflow-y: scroll;
      @include wt-ht(100%, calc(100% - 100px));
      padding: 20px;

      .wrapConfigureBox {
        position: relative;
        border-radius: 7px;
        border: solid 1px $c-ec;
        background-color: white;
        padding: 20px;
        margin-bottom: 20px;

        .configHeader {
          position: relative;
          @include flex-center;
          padding: 10px 0 35px;

          .emailAddress {
            font-size: 24px;
            font-weight: 600;
            text-align: left;
            color: $c-27;
            padding-left: 10px;
          }

          .imgType {
            position: relative;
            @include flex-center();

            svg {
              @include wt-ht(40px, 40px);

              path {
                @include wt-ht(40px, 40px);
              }
            }
          }
        }

        .configBody {
          position: relative;
          display: grid;
          grid-template-columns: 40% calc(60% - 25px);
          column-gap: 25px;

          @media (max-width: 1080px) {
            grid-template-columns: 100%;
            column-gap: 0px;

            .signatureBox .quill {
              min-height: 300px;

              .ql-snow .ql-picker-label {
                padding-left: 5px;
              }
            }
          }

          .numberInputBox {
            width: 100%;

            .numberInputBoxWrapper {
              width: 100%;
            }
          }

          .maximumEmailsPerDay {
            margin-bottom: 40px;

            .errMsgText {
              bottom: -30px;
            }
          }

          .emailDailyLimit,
          .signatureBox {
            position: relative;

            .title {
              font-size: 14px;
              font-weight: bold;
              margin-bottom: 10px;
              text-align: left;
              color: #444;
            }
          }

          .signatureBox {
            position: relative;
            @extend %editorConfig;

            .ql-toolbar {
              height: 40px;
            }

            .ql-toolbar.ql-snow .ql-formats {
              margin-right: 3px;
            }

            .ql-snow .ql-picker.ql-font,
            .ql-snow .ql-picker.ql-size {
              width: 80px;
            }

            .ql-snow .ql-icon-picker,
            .ql-snow .ql-color-picker,
            .ql-snow.ql-toolbar button {
              @include wt-ht(20px, 20px);
            }

            .ql-snow.ql-toolbar button {
              padding: 3px;
            }

            .ql-snow .ql-color-picker {
              font-size: 12px;

              .ql-picker-label {
                padding-top: 4px;
              }
            }

            .ql-snow .ql-picker-label {
              padding-left: 2px;
            }
          }
        }
      }
    }

    .emailWarmingSection {
      @include wt-ht(100%, calc(100% - 130px));
      position: relative;
      overflow-y: scroll;
      padding: 15px;

      .deliverablityAnalyticsSection {
        position: relative;
        display: block;
        border: 1px solid $c-dd;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 10px;
        min-height: 260px;

        .deliverablityHeaderAndEnableWarming {
          @include flex-center;
          justify-content: space-between;
          padding: 20px;

          .deliverablityHeader {
            @include flex-center;

            .deliverablityTitle {
              color: $c-3b;
            }

            .deliverablityType {
              position: relative;
              margin-left: 4px;
              font-size: 12px;
              color: $c-8d;
            }
          }

          .warmingToogleCont {
            position: relative;

            .toggleBtnSection {
              .toggleText {
                font-size: 14px;

                &.activeToogle {
                  color: #787878 !important;
                  font-weight: 400 !important;
                }
              }

              .toogleButton {
                height: 16px !important;

                .toggleCheckbox {
                  &:checked ~ .layer {
                    background-color: $blue-violet;
                  }
                }
              }
            }

            .customTooltip {
              .sharpPointer {
                top: 8px;
                right: 12px;
                border: none;
                background: #393a3d;
              }

              .tooltipBody {
                background: #393a3d;
                border-radius: 5px;
                top: 15px;
                left: -60px;
                width: 230px;
                text-align: center;

                .tooltipText {
                  color: $white;
                  padding: 8px;
                }
              }
            }
          }
        }

        .deliverablityAnalyticsBody {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          border-radius: 10px;
          min-height: 170px;

          .donutLoader {
            position: relative;
            @include flex-align-center;

            .donutGraphLoader {
              &.linear-background {
                @include wt-ht(150px, 150px);
                border-radius: 50%;
              }
            }

            .donutLabel {
              display: flex;
              align-items: flex-end;
              flex-direction: column;
              height: 100%;
              width: 70px;
              margin-left: 20px;
              padding-top: 20px;

              .inboxLabel,
              .spamLabel {
                &.linear-background {
                  width: 50px;
                  margin-bottom: 15px;
                }
              }
            }
          }

          .deliverablityStats {
            display: grid;
            grid-template-rows: repeat(4, auto);

            &.loader {
              @include wt-ht(100%, 100%);
              margin: 0;
              grid-template-rows: repeat(4, 28%);
            }

            .deliverablityStatsCont {
              position: relative;
              padding-inline: 40px;
              color: $c-3b;
              font-size: 13px;
              @include flex-center;
              justify-content: space-between;
              text-align: center;
              border-right: 1px solid $c-dd;
              border-left: 1px solid $c-dd;

              .rightCont {
                &.linear-background {
                  display: block;
                  width: 20%;
                }

                &.deliverability {
                  color: $c-28;
                }

                &.total {
                  color: $c-3b;
                }

                &.inbox {
                  color: $blue-violet;
                }

                &.spam {
                  color: $c-F59064;
                }
              }

              .leftCont {
                &.linear-background {
                  display: block;
                  width: 50%;
                }
              }
            }
          }

          .noActivityInDonutGraph {
            position: relative;
            @include flex-align-center;
            color: #abaeb5;
            font-size: 16px;
            line-height: 1.3;
            letter-spacing: 0.3px;
            font-style: italic;
          }

          .healthScore {
            position: relative;
            padding: 30px;
            display: grid;
            grid-template-rows: repeat(3, auto);
            grid-gap: 8px;

            .headerCont {
              cursor: pointer;

              .headerName {
                font-weight: bold;
                text-decoration: underline;
                text-decoration-color: #ddd;

                &:hover {
                  color: $blue-violet;
                  text-decoration-color: $blue-violet;
                }
              }

              &.linear-background {
                width: 50%;
              }
            }

            .scoreCont {
              font-size: 16px;
              color: $c-28;

              &.linear-background {
                width: 20%;
              }
            }

            .lastCheckedCont {
              font-size: 12px;
              color: $c-8d;

              &.linear-background {
                width: 70%;
              }
            }
          }
        }
      }

      .emailSentCountCont {
        position: relative;
        display: block;
        padding: 15px;
        border: 1px solid $c-dd;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        min-height: 400px;

        .noReportsFound {
          position: relative;
          @include flex-align-center;
          min-height: 400px;
          padding: 0 !important;
        }

        .chartLoader {
          @include flex-center;
          justify-content: center;
          @include wt-ht(100%, 100%);
        }
      }

      .emailWarmingSettings {
        display: block;
        position: relative;
        padding: 10px 0px;
        border: 1px solid $c-dd;
        border-radius: 10px;
        margin-top: 10px;
        min-height: 445px;

        .warmingSettingsCont {
          position: relative;

          .warmingSettingsHeader {
            height: 60px;
            padding: 20px;
            font-weight: bold;
            border-bottom: 1px solid $c-dd;
            color: black;
          }

          .warmingSettingsBody {
            height: calc(100% - 60px);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
            padding: 15px;
            column-gap: 20px;
            row-gap: 20px;

            .warmingTagWrapper {
              .emailWarmingTagWrapper {
                @include flex-center;

                .copyIcon {
                  cursor: pointer;
                  font-size: 14px;
                  margin-left: 20px;

                  &:hover {
                    background: $c-f5;
                    color: black;
                    transition: all 0.3s ease;
                  }
                }
              }

              .label {
                margin-bottom: 15px;
                font-size: 15px;
                @include flex-center;

                .infoIcon {
                  position: relative;
                  margin-left: 8px;
                  top: 3px;
                  cursor: pointer;

                  .iIcon {
                    font-size: 14px;
                  }

                  .customTooltip {
                    .sharpPointer {
                      top: -3px;
                      right: 0px;
                      border: none;
                      background: #393a3d;
                    }

                    .tooltipBody {
                      background: #393a3d;
                      border-radius: 5px;
                      top: 4px;
                      left: -60px;
                      width: 300px;
                      text-align: center;

                      .tooltipText {
                        color: $white;
                        padding: 8px;
                      }
                    }
                  }
                }
              }

              .emailWarmingTag {
                background-color: #eff1ff;
                color: $blue-violet;
                border-radius: 6px;
                font-size: 11px;
                min-width: 70px;
                max-width: 100px;
                padding: 6px;
                @include flex-center;
                justify-content: center;
              }
            }

            &.settingsLoader {
              row-gap: 45px;
            }

            .warmingSettingsLoader {
              position: relative;
              height: 50px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .label {
                &.linear-background {
                  width: 80%;
                  height: 25%;
                }
              }

              .inputType {
                &.linear-background {
                  width: 40%;
                  height: 25%;
                }
              }
            }

            .checkBoxInput {
              align-items: center;
              display: grid;
              grid-template-rows: repeat(3, auto);
              grid-gap: 15px;
              height: auto;

              .checkboxlabel {
                font-size: 15px;
              }

              .checkboxSubLabel {
                display: block;
                font-size: 12px;
                color: $c-8c8c8c;
              }

              .toggleBtnSection {
                .toggleText {
                  &.rightPad {
                    padding-right: 0px;
                  }
                }

                .toogleButton {
                  .toggleCheckbox {
                    &:checked ~ .layer {
                      background-color: $blue-violet;
                    }
                  }
                }
              }
            }

            .numberInputBox {
              &:focus-within {
                label,
                .numberInputBoxSubLabel {
                  color: $c-8c8c8c;
                }
              }
            }
          }

          .buttonActionList {
            position: relative;
            display: flex;
            justify-content: flex-start;
            width: 100%;
            padding: 20px;
            border-top: 1px solid $c-dd;
            background: $white;
            margin-top: 5px;

            .cancelButton {
              @extend %primaryBlueBtn;
              min-width: 130px;
              border-radius: 5px;
              color: $blue-violet;
              background: $white;
              border: 1px solid $blue-violet;
              margin: unset;

              &:hover {
                background: $white;
              }
            }

            .saveButton {
              @extend %primaryBlueBtn;
              min-width: 130px;
              border-radius: 5px;
              background: $blue-violet;
              margin-left: 15px;

              &.loading {
                transition: all 0.3s ease-in-out;
                min-width: auto;
                border-radius: 35px;

                .load-text {
                  @include wt-ht(30px, 30px);
                  top: 4px;
                  left: 4px;
                }
              }
            }
          }
        }
      }
    }
  }

  .addAccountCont {
    position: relative;
    display: grid;
    grid-template-columns: 65% 35%;

    @media (max-width: 767px) {
      grid-template-columns: 100%;
    }

    .accountFormBody {
      position: relative;

      form {
        position: relative;
        display: block;
        width: 100%;

        .customSelectCont {
          width: calc(50% - 20px);
          margin: 0 10px;

          @media (max-width: 767px) {
            width: calc(100% - 10px);
            margin: 0 5px;
          }

          .filled {
            @include flex-center();
            justify-content: space-between;
          }
        }

        .inputLabel {
          color: $c-66;
          font-size: 13px;
          font-family: $font-regular;
          position: absolute;
          top: 40%;
          left: 0px;
          transition: all 0.2s linear;
        }

        .inputCont {
          background-color: $white;
          color: $black;
          display: inline-block;
          width: calc(50% - 20px);
          margin: 10px;

          @media (max-width: 767px) {
            width: calc(100% - 10px);
            margin: 5px;
          }

          position: relative;
          padding: 25px 0 0;
        }

        .input-box {
          line-height: 36px;
          -webkit-appearance: none;
          border: 0;
          font-size: 15px;
          width: 100%;
          border-bottom: 1px solid $bg-default;
          color: $blue-violet;
        }

        .input-box:focus ~ label,
        .inputCont.filled label,
        .select-box:focus ~ label,
        .selectValues.filled label {
          top: 8px;
          transition: all 0.2s linear;
        }

        .buttonCont {
          width: 100%;
          padding: 15px;
          @include flex-center();
          justify-content: left;

          .btn {
            margin-right: 10px;
            width: fit-content;
            padding: 10px;
          }
        }

        .cancelBtn {
          background: $c-f5;
          border: 1px solid $c-f5;
          color: $c-66;
        }

        .additionalDetailsCont {
          padding: 15px;
          @include flex-center();

          .additionalText {
            margin-right: 10px;
            color: #707070;
            font-size: 20px;
          }

          .dottedLine {
            flex: 1;
            border-bottom: 1px dotted #ddd;
          }
        }

        .privacyCont {
          font-size: 14px;
          margin: 15px 15px 0px;
          line-height: 1.3;
          letter-spacing: 0.3px;

          a {
            color: $blue-violet;
            text-decoration: none;

            &:active,
            &:hover,
            &:focus {
              color: $blue-violet;
            }
          }
        }
      }
    }

    .instructionStep {
      position: relative;
      padding: 0 10px;

      .instructionCont {
        position: relative;
        background-color: #edefff;
        border-radius: 10px;
        padding: 20px;
        margin: 0 10px;

        &.highLightErr {
          background-color: #e4e7fa;
          box-shadow: 0px 0 5px 2px #8f98e7;
          height: 100%;
          margin: 10px 0;
        }

        .connectErrorMsg {
          margin-bottom: 10px;
          padding: 5px;
          color: red;
          font-size: 14px;
          font-weight: 500;
          @include flex-direction(row);
          border-radius: 5px;

          i {
            font-size: 20px;
          }

          .msgContent {
            padding-left: 10px;
          }
        }

        .instructionHeader {
          @include flex-direction(row);
          color: $blue-violet;
          font-size: 16px;
          letter-spacing: 0.3px;
          line-height: 1.4;
          font-weight: 500;
          font-family: $font-regular;

          .fa-envelope-open-o {
            color: $blue-violet;
            padding-right: 10px;
            font-size: 16px;
          }
        }

        .instructionWrapper {
          display: flex;
          flex-direction: column;

          .instruction1,
          .instruction2 {
            position: relative;
            word-break: break-word;
            width: 100%;

            .instructionTitle {
              color: $c-66;
              margin-top: 20px;
              margin-bottom: 5px;
              font-size: 14px;
              width: 100%;
              position: relative;
              @include flex-center();
              justify-content: space-between;
              padding-bottom: 5px;
              border-bottom: 1px dashed #ddd;

              a {
                color: $blue-violet;
                text-decoration: none;
                font-weight: 500;
                font-size: 11px;
              }
            }

            .olCont {
              color: $c-66;
              font-size: 14px;
              padding-left: 20px;
              margin: 0;
              letter-spacing: 0.3px;

              .fa-cog {
                cursor: default;
              }

              li {
                padding: 5px 0px;

                a {
                  color: $blue-violet;
                  text-decoration: none;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }

  .accountDetailsCont {
    width: calc(100% - 20px);
    padding: 0px 10px;
    display: inline-block;

    .accountBody {
      .accountCard {
        display: flex;
        align-items: center;
        height: auto;
        padding: 5px;
        font-family: $font-regular;

        &.googleAuth,
        &.outlookAuth {
          .mailId {
            padding-bottom: 0px !important;
          }
        }

        .mailCont {
          width: 40%;
          white-space: nowrap;
          text-overflow: ellipsis;
          @include flex-center();
          margin-right: 10px;

          .gmail {
            background-image: url("../../../assets/Settings/gmail-3.png");
            height: 30px;
            width: 30px;
            display: inline-block;
            background-size: 100%;
            background-repeat: no-repeat;
          }

          .outlook {
            background-image: url("../../../assets/Settings/Outlook-Logo.png");
            height: 30px;
            width: 30px;
            display: inline-block;
            background-size: 100%;
            background-repeat: no-repeat;
          }

          .userDetails {
            display: flex;
            flex-direction: column;

            .mailId {
              display: inline-block;
              padding-left: 10px;
              font-size: 15px;
              color: #666666;
              font-weight: 500;
              padding-bottom: 5px;
            }

            .userName {
              display: inline-block;
              padding-left: 10px;
              font-size: 13px;
            }
          }
        }

        .serverTypeCont,
        .buttonCont {
          width: 30%;
        }

        .buttonCont {
          text-align: right;

          .btn {
            width: 100px;
            height: 36px;
            text-align: right;
            display: inline-flex;
          }
        }

        .serverTypeCont {
          display: flex;
          align-items: left;
          flex-direction: column;

          .servertype {
            padding-bottom: 5px;
          }

          .serverTypeTxt {
            font-size: 13px;
          }
        }
      }
    }

    .accountHeader {
      padding: 10px;
    }

    .accountDetail {
      border: 1px solid #ececec;
      display: flex;
      align-items: center;
      width: 100%;
      background: #fafafb;

      .accLabel,
      .accText {
        width: 50%;
        display: inline-block;
        padding: 10px;
      }
    }
  }
}

.configFooter,
.smtpImapfooter {
  position: relative;
  padding: 10px 0;
  @include flex-center;
  justify-content: flex-start;
  column-gap: 15px;

  button {
    font-size: 14px;
    padding: 10px 0;
    border-radius: 5px;
    letter-spacing: 0.3px;
    min-width: 120px;
    margin: unset;

    &.updateConfigure,
    &.saveSmtpImapConnection {
      border: solid 1px $blue-violet;
      background: $blue-violet;
      color: white;

      &.disabledBtn {
        pointer-events: none;
        user-select: none;
        cursor: unset;
      }
    }

    &.cancelConfigure,
    &.backToConnectInbox {
      border: solid 1px $blue-violet;
      color: $blue-violet;
      background: white;
    }

    &.loading {
      @include wt-ht(34px !important, 34px !important);
      transition: all 0.3s ease-in-out;
      min-width: auto;
      border-radius: 35px;
      background: $blue-violet;

      .load-text {
        @include wt-ht(24px, 24px);
        top: 4px;
        left: 4px;
      }
    }
  }
}

.smtpImapConnectPage {
  @extend %relBlock;
  @include wt-ht(100%, 100%);
  background: white;

  .smtpImapContentBody {
    position: relative;
    @include wt-ht(100%, calc(100% - 60px));
    overflow: auto;

    .stmpImapTitle {
      position: sticky;
      padding: 16px 20px 12px;
      background: white;
      top: 0;
      z-index: 8;
      @include bor-rad-top(10px, 10px);

      h4 {
        @extend %relBlock;
        font-size: 16px;
        color: $c-66;
        margin-bottom: 5px;
        font-weight: 400;
      }

      p {
        @extend %relBlock;
        font-size: 13px;
        color: $c-78;
      }
    }

    .twoColLayout {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 20px));
      justify-content: space-between;
      padding: 0 20px;
      margin: 15px 0 20px;

      .toggleText {
        position: relative;
        font-size: 14px;
        top: 2px;
        font-weight: 400;
        padding-left: 8px;

        &.activeToogle {
          color: $c-66;
        }
      }

      .toggleActionSection {
        @include flex-center;
        position: relative;

        .rightPad {
          display: none;
        }
      }

      .colTiteAction {
        @extend %relBlock;

        .toggleActionSection {
          height: 46px;
        }

        .titeAction {
          position: relative;
          @include flex-center;
          justify-content: space-between;
          margin-bottom: 25px;
          margin-top: 5px;
          @include wt-ht(300px, 34px);

          h6 {
            color: $c-22;
            font-size: 16px;
            font-weight: 500;
          }

          button {
            font-size: 14px;
            border-radius: 3px;
            letter-spacing: 0.3px;
            min-width: 70px;
            margin: unset;
            border: solid 1px $blue-violet;
            color: $blue-violet;
            background: white;
            @include wt-ht(34px !important, 34px !important);

            &:hover:not(.loading) {
              background: $blue-violet;
              color: white;
            }

            &.loading {
              @include wt-ht(34px !important, 34px !important);
              transition: all 0.3s ease-in-out;
              min-width: auto;
              border-radius: 35px;
              background: $blue-violet;

              .load-text {
                @include wt-ht(24px, 24px);
                top: 4px;
                left: 4px;
              }
            }

            &.disabled {
              border: 1px solid $c-cc;
              color: $c-66;
              opacity: 0.6;
              pointer-events: none;
              user-select: none;
              cursor: unset;
            }
          }

          .verfiedMsg {
            position: relative;
            background: white;
            @include flex-center;
            border-radius: 20px;
            padding: 3px 5px;

            .tickIcon {
              color: #0ccd0c;
              font-size: 12px;
            }

            .text {
              position: relative;
              top: 1px;
              font-weight: 500;
              letter-spacing: 0.3;
              padding: 0 5px;
              letter-spacing: 0.3px;
              color: #0ccd0c;
              font-size: 12px;
            }
          }
        }
      }

      .inputWrapper {
        @extend %inputWrapper;
        height: 40px;
        margin-bottom: 25px;
        width: 300px;

        .inputCont {
          position: relative;
          width: 300px !important;

          .inputBox {
            width: 300px;
            color: $blue-violet;

            &:not(:focus)::placeholder {
              color: transparent;
            }
          }

          .focusBorder {
            width: 300px;
            border-bottom: 1px solid $c-dd;
            z-index: 8 !important;
          }

          &.wrapInputIcon {
            width: calc(100% - 20px);

            .eyeIcon {
              cursor: pointer;
              position: absolute;
              right: 0;
              font-size: 18px;
              color: $c-66;
              top: 12px;
            }
          }

          .inputBox.password {
            width: calc(100% - 20px);
          }
        }
      }

      .smtpEmailsForm,
      .imapEmailsForm {
        @extend %relBlock;

        .dropdownButtonOption {
          @extend %relBlock;
          width: 300px;
          border-bottom: 1px solid $b-c-1;
          margin-bottom: 25px;

          label {
            position: absolute;
            font-size: 11px;
            display: block;
            top: -10px;
            transition: all 0.5s;

            &.adjustLabel {
              display: block;
              margin-bottom: unset;
              top: 14px;
              font-size: 13px;
              transition: all 0.5s;
            }
          }

          .inputValueIcon {
            position: relative;
            @include flex-center;
            justify-content: space-between;
            height: 40px;
            cursor: pointer;

            .activeValue {
              position: relative;
              top: 4px;
              font-size: 14px;
              color: $blue-violet;
            }

            .icon {
              cursor: pointer;
              color: $c-66;
              font-size: 20px;
            }
          }

          .drop-down-menu {
            right: unset;
            left: 0;
            bottom: 40px;
            top: unset;
          }
        }

        &.disableImap {
          opacity: 0.5;
          pointer-events: none;
        }

        .inputWrapper {
          .inputBox {
            &.disableInput {
              color: $c-8d;
            }
          }
        }
      }
    }

    @extend %toggleSection;
  }

  .smtpImapfooter {
    border-top: 1px solid $b-c-1;
    height: 60px;
    padding: 0 20px;

    &.disabled {
      @include disable;
    }
  }
}

.connectedInboxWrapper {
  @include flex-center;
  justify-content: space-between;
}

.connectedInboxWrapperIsAdmin {
  @include flex-center;
  justify-content: flex-end;
  width: 100%;
}

.connectedInboxHeader {
  margin-bottom: 16px;
  @include flex-center;
  justify-content: space-between;
  @include searchInputByKeyword();

  .flexRow {
    @include flex-center;
  }

  .searchWrapper .searchInput:focus-within {
    input,
    svg {
      color: $c-66 !important;
      fill: $c-66 !important;
    }
  }

  .dateFilter {
    @extend %dateFilter;
  }

  .filterHeaderWrapper {
    position: relative;
    width: 200px;

    .dropdownList li {
      height: 32px !important;
      padding: 8px 10px !important;
    }
  }

  .filterBtn {
    position: relative;
    @include flex-center;
    border: 1px solid #eceff1;
    padding: 10px;
    border-radius: 5px;
    color: $c-59;
    background: white;
    cursor: pointer;
    margin-left: 12px;
    height: 36px;

    svg {
      @include wt-ht(14px, 16px);
      fill: $blue-violet;
      margin-right: 8px;
    }

    .filterTextWrapper {
      @include flex-center;
      width: 146px;
      justify-content: space-between;
      color: $blue-violet;
      font-size: 14px;

      .filterTxt {
        width: 130px;
        padding-top: 2px;
        @include ellipsis();
      }

      .dropdownFilterIcon {
        font-size: 20px;
        color: $c-9e;
      }
    }
  }

  .filterDropdownOverlay {
    @include bg-mask(1, transparent);
  }

  .userFilterDropDown {
    position: relative;
    margin-left: 10px;

    .userFilterBtn {
      position: relative;
      @include flex-align-center();
      outline: none;
      background: white;
      border-radius: 3px;
      border: 1px solid $b-c-1;
      padding: 0 10px;
      @include wt-ht(200px, 36px);
      font-size: 15px;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        background: $c-fb;
        transition: all 0.3s ease;
      }

      .icon {
        margin-right: 10px;
        @include wt-ht(14px, 14px);

        svg {
          fill: $blue-violet;
          @include wt-ht(14px, 14px);
        }
      }

      .UpgradeTooltip {
        .sharpPointer {
          right: 29px;
        }
      }

      .name {
        position: relative;
        color: $blue-violet;
        min-width: 100px;
        max-width: 110px;
        text-align: left;
        font-size: 15px;
        top: 2px;
        line-height: initial;

        .shortName {
          text-transform: uppercase;

          &:not(:last-child)::after {
            content: ", ";
          }

          &.teamNameSelected {
            text-transform: unset;
            @include ellipsis;
          }
        }
      }

      .hiddenCount {
        font-size: 13px;
        color: $c-78;
        min-width: 30px;
        line-height: initial;
        position: relative;
        top: 1px;
      }

      .arrow {
        margin-left: 5px;
        margin-top: 3px;
        @include flex-center();
        transition: all 0.3s ease;

        svg {
          fill: $c-53;
          @include wt-ht(11px, 11px);
        }

        &.activeArrow {
          transform: rotate(-180deg);
          transition: all 0.3s ease;
        }
      }
    }

    @extend %userListDropdown;

    .overlayUserFilterMask {
      @include bg-mask(4, transparent);
    }
  }

  .filterInbox {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
  }
}

.noInboxFound {
  @include wt-ht(100%, 100%);
  @include flex-center;
  justify-content: center;
}

.linkedInwarmingEnabledOrDisabledBtn {
  position: relative;

  #warmingEnableBtn {
    background-color: transparent;
    @include wt-ht(180px, 28px);
    border-radius: 20px;
    cursor: pointer;

    &.warmingError {
      border: none;
      color: $c-3b;

      svg {
        @include wt-ht(22px, 22px);

        path {
          fill: #ff0000;
        }
      }
    }
  }

  &.errorBtn {
    .customTooltip {
      .tooltipBody {
        min-width: 60px;
        @include flex-align-center;
        right: 42%;
      }
    }
  }
}

.btnInboxActions {
  display: grid;
  grid-template-columns: auto 30px;
  justify-content: flex-end;
  padding-left: 12px;
  transform: translateX(-10px);
}
.reconnectBtn {
  position: relative;
  width: fit-content;
  @include flex-align-center;
  padding: 6px 8px;
  font-size: 14px;
  border: 1px solid $blue-violet;
  border-radius: 15px;
  margin-left: 6px;
  height: 28px;
}
