.emptyCustomUrlWrapper {
  position: relative;
  height: 100%;
  @include flex-center();
  .emptyCustomUrlCont {
    @include flex-direction(column);
    justify-content: center;
    width: 100%;
    .imageCont {
      padding-bottom: 25px;
      img {
        width: 75px;
        height: auto;
        object-fit: contain;
      }
    }
    .addCustomUrlBtn {
      @extend %secondaryButton;
      width: 200px;
      height: 46px;
      font-weight: 500;
      cursor: pointer;
      margin-bottom: 15px;
      font-size: 14px;
      transition: all 0.3s;
      letter-spacing: 0.3px;
      &:hover {
        transition: all 0.3s;
        color: white;
        background: $blue-violet;
      }
    }
    p.cutomParaText {
      @extend %relBlock;
      min-width: 240px;
      max-width: 450px;
      font-size: 14px;
      color: $c-87;
      text-align: center;
      line-height: 1.46;
      letter-spacing: -0.16px;
      opacity: 0.65;
    }
  }
}
