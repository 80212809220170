.filterSection {
  position: relative;
  @include bor-rad-top(10px, 10px);
  display: grid;
  grid-template-columns: repeat(2, 50%);

  padding: 12px 25px;
  .searchFilter {
    @include flex-direction(row);
    position: relative;
    .searchBtn {
      margin-right: 5px;
      cursor: pointer;
      color: $c-66;
      font-size: 20px;
    }
    .searchInputBox {
      @include flex-center();
      position: relative;
      input {
        border: 0;
        color: $blue-violet;
        width: 260px;
        font-size: 16px;
        padding: 5px 28px 5px 10px;
        border-radius: 35px;
        transition: all 300ms ease-in-out;
        &:required:valid {
          background: $c-f5;
        }
        &:invalid {
          background: $white;
        }
        &:required:valid + .clearText {
          display: block;
        }
        line-height: 1.5;
      }
      .clearText {
        transition: all 300ms ease-in-out;
        display: none;
        position: absolute;
        right: 5px;
        color: $c-66;
        font-size: 14px;
        cursor: pointer;
        padding: 2px;
        border-radius: 35px;
        &:hover {
          color: $black;
        }
      }
    }
    .errMsg {
      position: absolute;
      bottom: -12px;
      color: red;
      font-size: 11px;
      left: 35px;
    }
    &:focus-within {
      transition: all 300ms ease-in-out;
      .searchBtn {
        color: $blue-violet;
        transition: all 300ms ease-in-out;
      }
      .searchTextBox input {
        color: $c-3b;
        border-radius: 35px;
        background: $c-f5;
        transition: all 300ms ease-in-out;
      }
    }
  }
}
