.upgradePlanPopupWrapper {
  @extend %upgradePopupCard;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1111;
  .upgradePlanParentWrapper {
    display: flex;
    flex-direction: column;
    width: 523px;
    min-width: 523px;
    border-radius: 15px;
    overflow: hidden;
    will-change: transform;
    transition: all 0.4s ease;
    background: #f7f7f7;
    z-index: 99999999;
    .upgradePlanSubParentWrapper {
      background-color: #f7f7f7;
    }
    .remainingCreditsContentContainer {
      display: flex;
      justify-items: center;
      align-items: center;
      padding: 18px 30px;
      line-height: 20px;
      &.isLoading {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .remainingCreditsContent {
        color: #333333 !important;
        font-size: 14px;
        span {
          font-weight: 700;
        }
      }
    }
    .toggleWrapper {
      background-color: #ffffff;
      .navigateButtonContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 15px 20px 28px 20px;
        gap: 20px;
        .continueButtonWrapper {
          border-radius: 5px;
          background-color: #3953fb;
          color: #ffffff;
          align-items: center;
          display: flex;
          justify-content: center;
          cursor: pointer;
          padding: 13px 25px;
        }
        .chatWithUsButtonWrapper {
          border-radius: 5px;
          background-color: #ffffff;
          color: #3953fb;
          align-items: center;
          display: flex;
          justify-content: center;
          cursor: pointer;
          padding: 13px 25px;
          border: 2px solid #3953fb;
        }
      }
    }
    .toggle-container {
      width: 400px;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      background: white;
      text-align: center;
    }
    .toggle-container h3 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .toggle-container p {
      font-size: 14px;
      color: #555;
      margin-bottom: 20px;
    }
    .toggleOptions {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0px 30px;
      .toggleHeading {
        font-size: 16px;
        color: #333333;
        font-weight: 600 !important;
      }
    }
    .toggleOption {
      display: flex;
      align-items: center;
      padding: 15px;
      border: 2px solid #d9d9d9;
      border-radius: 8px;
      cursor: pointer;
      transition: 0.3s;
      &.non-selectable {
        cursor: default;
      }
      .option {
        color: #333333 !important;
        font-size: 14px;
      }
    }
    .radioButton {
      width: 24px;
      height: 24px;
      border: 2px solid #ccc;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    .toggleOption.selected .radioButton {
      border-color: #3953fb;
      background: #3953fb;
    }
    .checkmark {
      color: white;
      font-size: 14px;
      font-weight: bold;
    }
    .optionDetails {
      text-align: left;
      line-height: 25px;
    }
    .price {
      font-size: 16px;
      font-weight: bold;
      color: #333;
    }
    .buttons {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
    }
    .chat-btn {
      background: white;
      border: 2px solid #3953fb;
      color: #3953fb;
      padding: 10px 15px;
      border-radius: 6px;
      font-weight: bold;
      cursor: pointer;
    }
    .chat-btn:hover {
      cursor: pointer;
    }
    .continue-btn {
      background: #3953fb;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 6px;
      font-weight: bold;
      cursor: pointer;
    }
    .continue-btn:hover {
      background: #0056b3;
    }
    .contactDataContent {
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      overflow: hidden;
      will-change: transform;
      transition: all 0.4s ease;
      background: #f7f7f7;
      z-index: 99999999;
      padding: 20px 20px 5px;
      gap: 5px;
      .closeButton {
        cursor: pointer;
        display: flex;
        justify-content: end;
        .closeIcon {
          position: absolute;
          top: 10px !important;
          right: 10px !important;
          cursor: pointer;
          padding: 4px;
          scale: 0.9;
          i {
            font-size: 10px;
          }
          &:hover {
            background-color: #ddd;
            border-radius: 50%;
          }
        }
      }
      .headerDataParent {
        display: flex;
        padding: 6px 10px;
        flex-direction: column;
        gap: 10px;
        .headerData {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          color: #333333;
          font-size: 18px;
          font-weight: 600;
        }
        .fetchData {
          font-weight: 700;
          color: #333333;
          padding: 10px 0;
        }
        .fetchedMaskedData {
          display: flex;
          flex-direction: column;
          padding: 10px 0;
          gap: 30px;
          .maskedContactDataContainer {
            display: flex;
            flex-direction: row;
            gap: 5px;
            .maskedContactFoundIcon {
              width: 20%;
            }
            .maskedContactData {
              width: 80%;
              display: flex;
              flex-direction: column;
              gap: 10px;
              .mailDataContainer {
                display: flex;
                gap: 20px;
                flex-direction: row;
                color: #333333;
                font-size: 15px !important;
                .mailId {
                  font-size: 20px;
                }
                .copyIcon {
                  cursor: pointer;
                }
              }
              .phoneDataContainer {
                display: flex !important;
                gap: 20px !important;
                flex-direction: row !important;
                color: #1d2026;
                .phoneIcon {
                }
                .phoneData {
                  font-size: 15px !important;
                }
              }
            }
          }
        }
        .fetchProgress {
          display: flex;
          flex-direction: row;
          padding: 10px 0;
          gap: 30px;
          .contactDataContainer {
            display: flex;
            flex-direction: column;
            gap: 5px;
            .contactDataHeader {
              font-size: 15px;
              color: #333333;
              line-height: 20px;
              span {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
  .confirmation-popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
    background: rgba(0, 0, 0, 0.53);
    opacity: 0.75;
    &.bulkMask {
      z-index: 1;
    }
  }
  .gotItButtonContainer {
    display: flex;
    justify-content: center;
    .gotItButtonWrapper {
      border-radius: 20px;
      background-color: #3953fb;
      color: #ffffff;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
      padding: 13px 25px;
    }
  }
  .confirmation-popup-mask {
    z-index: 115;
  }
}
