.addEmailPreferencesPopup {
  position: fixed;
  @include flex-align-center;
  @include wt-ht(100%, 100vh);
  inset: 0;
  background: transparent;
  z-index: 11;
  .card {
    position: relative;
    z-index: 2;
    background: white;
    @include wt-ht(440px, auto);
    border-radius: 5px;
    padding: 20px;
    color: $c-3b;
    &.disableClick {
      @include disable(0.7, unset);
    }
    .cardHeader {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid $b-c-1;
      .textWrapper {
        position: relative;
        display: block;
        .title {
          position: relative;
          display: block;
          font-size: 20px;
          text-align: left;
          color: $c-3b;
        }
        .desc {
          position: relative;
          display: block;
          margin-top: 6px;
          color: $c-8d;
          font-size: 12px;
        }
      }
      i.close {
        font-size: 20px;
        color: $c-66;
        border-radius: 35px;
        padding: 3px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: $c-f5;
          color: $c-33;
        }
      }
    }
    .cardBody {
      position: relative;
      display: block;
      padding: 15px 0 0 2px;
      &.loading {
        @include disable(0.5, unset);
      }
      @include searchInputByKeyword;
      .searchWrapper {
        margin-bottom: 15px;
        .searchInput {
          width: 100%;
          padding: 3px;
          .searchTextBox {
            width: calc(100% - 30px);
            input {
              width: 100%;
              padding: 6px 20px 6px 2px;
            }
            i {
              z-index: 999;
            }
          }
        }
      }

      .allContactOpList {
        position: relative;
        height: 248px;
        overflow: auto;
        top: -14px;

        .addedContactList {
          padding: 12px 10px;
          border: 1px solid $c-dd;
          border-radius: 3px;
          @include flex-center();
          justify-content: space-between;
          margin-bottom: 10px;
          margin-top: 10px;

          .contactOpName {
            font-size: 14px;
            color: $blue-violet;
            line-height: 18px;
            &.linear-background {
              position: relative;
              @include wt-ht(calc(100% - 20px), 16px);
            }
          }
          i.close {
            font-size: 20px;
            color: $c-dd;
            border-radius: 35px;
            padding: 3px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
              background: $c-f5;
              color: $c-33;
            }

            &.linear-background {
              position: relative;
              opacity: 1;
              @include wt-ht(18px, 18px);
            }
          }
        }

        .contactCompleteDropdown {
          .drop-down-menu {
            left: 0;
            border-radius: 5px;
            max-width: 100% !important;
            padding: 12px;
            .dropdownList {
              margin: 0;
              max-height: 154px;
              .option {
                padding: 8px 8px !important;
                &:not(:last-child) {
                  border-bottom: 1px solid $c-ee;
                }
                .listItem .listItemWrap {
                  width: 220px !important;
                  @include ellipsis();
                  .itemName {
                    font-size: 15px;
                    color: $c-343a40;
                    margin-bottom: 2px;
                    .addTxt {
                      color: $c-3b !important;
                    }
                  }
                  .itemEmail {
                    font-size: 12px;
                    color: $c-78;
                  }
                }
              }

              .invalidEmail {
                font-size: 14px;
                color: red;
              }
            }

            ul li {
              border-bottom: 1px solid $c-dd;
              padding: 10px;
              color: $c-78;
              font-size: 14px;
              font-family: "Graphik-Regular";
            }
          }
        }
      }

      .btnFooter {
        width: 100%;
        @include flex-center;
        justify-content: center;
        gap: 8px;
        button {
          font-size: 14px;
          padding: 10px 0;
          border-radius: 5px;
          letter-spacing: 0.3px;
          min-width: 120px;
          margin: unset;
          &.saveContactBtn {
            border: solid 1px $blue-violet;
            background: $blue-violet;
            &.disabledBtn {
              @include disable(0.5, unset);
            }
          }
          &.contactCancelBtn {
            border: solid 1px $blue-violet;
            color: $blue-violet;
            background: white;
          }
          &.loading {
            @include wt-ht(34px !important, 34px !important);
            transition: all 0.3s ease-in-out;
            min-width: auto;
            border-radius: 35px;
            .load-text {
              @include wt-ht(24px, 24px);
              top: 4px;
              left: 4px;
            }
          }
        }
      }
    }
  }
  .overlay {
    @include bg-mask(1, rgba(0, 0, 0, 0.65));
  }
}
