.accountContacts {
  position: relative;
  width: 100%;
  height: calc(100vh - 184px);
  .actionFilterCont {
    position: relative;
    @include flex-center;
    padding-bottom: 10px;
    justify-content: space-between;

    // Search Component STYLE
    @include searchInputByKeyword();

    .actionLeftCont {
      @include flex-center;
      .accountCount {
        margin-left: 10px;
      }
    }

    .actionRightCont {
      @include flex-center;
      @include addToSequenceButton();
      .addToSequenceFeatureBtn {
        margin-left: 10px;
      }
    }

    .pageLimit {
      margin-right: unset;
    }
  }

  .searchTextBox input {
    height: 36px;
  }

  .dataTableCont {
    position: relative;
    display: block;
    width: 100%;
    height: calc(100% - 136px);
    border-radius: 6px;
    background: white;
    z-index: 0;
    @include tableFixedCont();
    .headerCheckbox {
      min-width: 30px !important;
      left: 0 !important;
      right: 0 !important;
    }
    th[scope="row"],
    thead tr {
      position: sticky;
      top: 0;
      left: 55px;
      z-index: 999;
    }
    .tableContainer {
      height: 100%;
      color: $c-66;
      .headerCheckbox {
        min-width: 30px;
      }
      thead tr {
        top: -1px;
      }
      thead th {
        border-top: 1px solid #fafafa;
      }
      %hightNameEmail {
        @include ellipsis();
        text-align: left;
        cursor: pointer;
        &:hover {
          color: $blue-violet !important;
          text-decoration: underline;
        }
      }
      tbody tr:first-child .ctOwner .customTooltip {
        .sharpPointer {
          top: 14px;
          right: 0px;
          border-bottom-left-radius: 35px;
        }
        .tooltipBody {
          top: 20px;
        }
      }
      .name {
        color: $c-66;
        font-weight: 600;
        margin-bottom: 5px;
        @extend %hightNameEmail;
      }
      .email {
        color: $c-78;
        @extend %hightNameEmail;
      }
      .companyJobTile {
        @extend %companyJobTile;
      }
      .tag {
        @extend %contactTag;
      }
      .ctOwner {
        @extend %contactOwner;
      }
    }
    &.paginationSection {
      height: calc(100% - 140px);
      .tableContainer {
        height: calc(100% - 52px);
      }
    }
    @include updateAccountStageDropdown();
    .dtHeader,
    .dtBody,
    .dtLoader {
      position: relative;
      display: grid;
      grid-template-columns: 20% 18% 18% 16% 18% 10%;
      align-items: center;
      font-size: 14px;
      height: 48px;
      padding: 0 5px;
      color: $c-66;
      border-bottom: 1px solid $b-c-1;
      .titleRow {
        @extend %relBlock;
        @include ellipsis();
        padding: 0 10px;
      }
    }
    .dtHeader {
      width: calc(100% - 4px);
    }
  }
}

.singleBanner .accountContacts {
  height: calc(100vh - 224px);
}

.emptyContactsSection {
  position: relative;
  height: calc(100% - 48px);
  width: 100%;
  color: $c-66;
  flex-direction: column;
  @include flex-align-center;
  i {
    font-size: 24px;
  }
  .text {
    position: relative;
    font-size: 16px;
    text-align: center;
  }
}
