.verifyNumberPopup {
  height: 100%;

  .verifyNumberPopupWrapper {
    min-height: 410px;
    height: fit-content;
    min-width: 400px !important;
    border-radius: 10px;
    background: #fff;
    padding: 25px;

    .newNumberCont {
      height: 100%;

      .popupHeader {
        @include flex-center();

        .popupTitle {
          color: $c-53;
          font-size: 16px;
          width: 80%;
          display: inline-block;
          font-weight: 600;
        }
      }

      .popupBody {
        padding-top: 25px;
        height: 100%;

        .callingTextCont {
          @include flex-direction(column);
          padding-top: 15px;
          .salesgearCallingText {
            font-size: 14px;
            color: $blue-violet;
          }

          .infoCont {
            font-size: 12px;
            color: $c-66;
            cursor: default;
            @include flex-direction(column);
            gap: 5px;

            .infoText {
              text-align: center;
              padding: 0px 55px;
            }
          }

          .codeCont {
            @include flex-direction(column);
            padding: 15px 0px 30px 0px;

            .codeTxt {
              font-size: 14px;
            }
            .code {
              margin-top: 5px;
              font-size: 24px;
              letter-spacing: 5px;
              color: $c-66;
              font-weight: 600;

              &.linear-background {
                height: 15px;
                width: 100px;
                border-radius: 10px;
              }
            }
          }

          .countDownCont {
            padding-top: 10px;
            color: $blue-violet;
            font-family: $font-semi-bold;
          }

          .plsWaitText {
            color: $blue-violet;
            font-size: 14px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  &.verified {
    .verifyNumberPopupWrapper {
      min-height: 220px;

      .verifiedMessageCont {
        padding-top: 20px;
        @include flex-direction(column);
        justify-content: center;

        .verifiedNumber {
          color: $blue-violet;
        }

        .verifiedText {
          color: $c-66;
          font-size: 15px;
          width: 80%;
          text-align: center;
        }
      }
    }
  }
}
