.deselectCheckedPopup {
  @extend %popupCard;
  user-select: none;
  .card {
    width: 540px;
    .cardBody {
      .textDesc {
        margin-bottom: 30px;
        color: $c-66;
        line-height: 1.5;
      }

      .actionBtn {
        @include flex-center;
        justify-content: flex-end;
        .deselectClearSelectionBtn {
          width: fit-content;
          background: $blue-violet;
          margin: 0 0 0 15px;
          font-weight: 500;
          letter-spacing: 0.3px;
          cursor: pointer;
        }
        .deselectCancelBtn {
          margin: unset;
          font-weight: 500;
          letter-spacing: 0.3px;
          color: $c-66;
          width: fit-content;
          background: white;
          cursor: pointer;
          &:hover {
            color: black;
            background: $c-f5;
          }
        }
      }
    }
  }
  .deselectPopupOverlay {
    @include bg-mask(99, rgba(0, 0, 0, 0.57));
  }
}
