.addMultiInputEmail {
  position: relative;
  border-bottom: 1px solid $c-ec;
  .emailInputBoxWrapper {
    position: relative;
    display: block;
    width: calc(100% - 8px);
    margin: 0 0 10px 8px;
    .horizontalBorderLine {
      position: absolute;
      width: 4px;
      height: 100%;
      background: #dee3ff;
      border-radius: 5px;
      left: -8px;
      top: 0px;
      z-index: 1;
    }
    .inputEmailBox {
      position: relative;
      border-bottom: 1px solid $c-ec;
      display: grid;
      grid-template-columns: calc(100% - 24px) 22px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 5px 0px;
      height: 30px;
      letter-spacing: 0.3px;
      background: white;
      &.emailText {
        grid-template-columns: calc(100% - 42px) 18px 20px;
      }
      .emailInput {
        position: relative;
        color: $c-66;
        border: none;
        padding-right: 5px;
        background: transparent;
        font-size: 14px;
        &:focus-within {
          color: $blue-violet;
        }
      }
      &.invalidEmail {
        border-bottom: 1px solid $red;
        .emailInput {
          color: $red;
        }
      }
      .clearIpIcon {
        position: relative;
        font-size: 14px;
        color: $c-66;
        cursor: pointer;
        padding: 2px;
        border-radius: 35px;
        background: $white;
        &:hover {
          padding: 2px;
          background: $c-f5;
          color: $c-3b;
        }
      }
      .addIpIcon {
        position: relative;
        color: $c-66;
        cursor: pointer;
        font-size: 20px;
        padding: 1px;
        border-radius: 35px;
        &:hover:not(.disabled) {
          background: $c-f5;
          color: $c-3b;
        }
        &.disabled {
          @include disable(0.5, unset);
        }
      }
    }
    @extend %selectedItemsCont;
  }
}
