@import "./variables";

@mixin center-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: tranform;
  text-align: center;
  color: #6c6f73;
}
@mixin zero-mp {
  padding: 0;
  margin: 0;
}
@mixin list-style-zero {
  padding: 0;
  margin: 0;
  list-style: none;
}
@mixin flex-center {
  display: flex;
  align-items: center;
}
@mixin flex-align-center($align: center, $justify: center) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}
@mixin wt-ht($width, $height) {
  width: $width;
  height: $height;
}
@mixin ellipsis() {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin bg-mask($idx, $bg-color) {
  position: fixed;
  z-index: $idx;
  inset: 0;
  width: 100%;
  height: 100%;
  background: $bg-color;
}

@mixin pos-center($pos, $idx, $color, $pad, $bor) {
  position: $pos;
  z-index: $idx;
  padding: $pad;
  background: $color;
  border-radius: $bor;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  will-change: transform;
}

@mixin flex-direction($direction) {
  @include flex-center();
  flex-direction: $direction;
}

@mixin bor-rad-top($left, $right) {
  border-top-left-radius: $left;
  border-top-right-radius: $left;
}

@mixin bor-rad-left($top, $btm) {
  border-top-left-radius: $top;
  border-bottom-left-radius: $btm;
}

@mixin bor-rad-right($top, $btm) {
  border-top-right-radius: $top;
  border-bottom-right-radius: $btm;
}

@mixin bor-rad-btm($top, $btm) {
  border-bottom-right-radius: $top;
  border-bottom-left-radius: $btm;
}

@mixin siblingBtn {
  font-size: 14px;
  padding: 10px;
  min-height: 35px;
  background-color: #1633ea;
  color: #fff;
  height: 36px;
  border-left: 1px solid #1633ea;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

@mixin contactSelectCheckbox {
  width: 30px;
  @include flex-center();
  justify-content: center;
}

@mixin smoothly-font($property) {
  will-change: $property;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

@mixin linkedInSubMenu {
  right: 100%;
  left: unset;
  top: 0px;
  width: 325px;
  padding: 10px 0px;
  display: none;
}

@mixin flex-align-path-justify($align, $path, $justify) {
  display: flex;
  align-items: $align;
  flex-direction: $path;
  justify-content: $justify;
}

@mixin flex-width($wt) {
  flex: 0 0 $wt;
  max-width: $wt;
}

@mixin btn-jiggle($delayInSec, $iterationCount) {
  animation: tidleShake 5s linear $delayInSec $iterationCount running;
  -webkit-animation: tidleShake 5s linear $delayInSec $iterationCount running;
}

@mixin InputSpinLoader($top, $right, $spinnerColor) {
  position: absolute;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
  right: $right;
  top: $top;

  &::after,
  &::before {
    content: "";
    background: white;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: $spinnerColor $spinnerColor transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
  }

  &::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
  }
}

@mixin lineClamp($value: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $value;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin crmConfigurePageBase {
  position: relative;
  padding: 10px 25px 25px;
  .backBtn {
    position: relative;
    border-radius: 6px;
    @include flex-center;
    margin-bottom: 25px;
    color: $c-78;
    width: fit-content;
    padding: 5px 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    border: 1px solid $c-ee;
    i {
      font-size: 20px;
      margin-right: 5px;
    }
    &:hover {
      background: $c-f5;
      transition: all 0.3s ease;
      color: $c-33;
    }
  }

  .card {
    width: 80%;
    position: relative;
    display: grid;
    grid-template-columns: 64px auto 160px;
    align-items: center;
    border: 1px solid $c-ee;
    border-radius: 8px;
    padding: 15px 20px;
    background: white;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 25px;
    img {
      max-width: 100%;
    }
    .headingBlock {
      @extend %relBlock;
      h6.subHeading {
        font-size: 16px;
        font-weight: 400;
        color: $c-33;
        margin-bottom: 4px;
      }
      .wrapIconText {
        position: relative;
        @include flex-center;
        .highlightConnectedAccount {
          color: $blue-violet;
        }
        i {
          color: greenyellow;
          font-size: 16px;
          margin-right: 5px;
        }
        .text {
          font-size: 14px;
          color: $c-87;
        }
      }
    }
    button {
      border-radius: 6px;
      outline: none;
      background: white;
      font-size: 14px;
      letter-spacing: 0.3px;
      font-weight: 500;
      cursor: pointer;
      min-width: 140px;
      padding: 10px 20px;
      transition: all 0.3s ease;
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08);
      &.connectBtn {
        border: 1px solid $blue-violet;
        color: $blue-violet;
        &:hover {
          color: white;
          background: $blue-violet;
          transition: all 0.3s ease;
        }
      }
      &.disconnectBtn {
        border: 1px solid #ff9800;
        color: #ff9800;
        &:hover {
          color: white;
          background: #ff9800;
          transition: all 0.3s ease;
        }
        &.loading {
          min-width: auto;
          background: #ff9800;
          border-radius: 35px;
        }
      }
    }
  }
}

@mixin pointerSelect($pe: none, $us: none) {
  user-select: $us;
  pointer-events: $pe;
}

@mixin disable($opacity: 0.5, $cursor: pointer, $pe: none, $us: none) {
  user-select: $us;
  pointer-events: $pe;
  opacity: $opacity;
  cursor: $cursor;
}

@mixin text-ellipsis($lines: 1, $condtion: 1) {
  text-overflow: ellipsis;
  overflow: hidden;
  @if ($lines > $condtion) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  } @else {
    white-space: nowrap;
  }
}

@mixin addToSequenceButton {
  .addToSequenceFeatureBtn {
    position: relative;
    border-radius: 5px;
    border: 1px solid $b-c-1;
    background: white;
    @include wt-ht(36px, 36px);
    padding: 0 10px;
    @include flex-center;
    color: $blue-violet;
    cursor: not-allowed;
    svg {
      @include wt-ht(14px, 14px);
      fill: $c-9e;
    }
    .customTooltip {
      .sharpPointer {
        border-right: 1px solid $c-33;
        border-top: 1px solid $c-33;
        background-color: #393a3d;
        top: -38px;
        right: -2px;
      }
      .tooltipBody {
        background-color: #393a3d;
        border-radius: 5px;
        width: 120px;
        top: -62px;
        right: -46px;
        &.bulkAddtoSequence {
          width: 315px;
          right: -144px;
        }
      }
      .tooltipText {
        color: $white;
        padding: 8px;
      }
    }
    .wrapLockIcon {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      .lockIcon {
        background: orange;
        color: white;
        font-size: 12px;
        width: 20px;
        height: 20px;
        border-radius: 35px;
        justify-content: center;
        @include flex-center;
        position: absolute;
        top: -5px;
        right: -8px;
        margin-left: 10px;
        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.04);
      }
    }
    &.enabled {
      cursor: pointer;
      @extend %enabledBtn;
      &:hover {
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
      }
    }
  }
}

%taskDatePicker {
  position: relative;
  min-width: 210px;
  border-bottom: 1px solid $c-dd;
  margin-bottom: 20px;
  background: transparent;
  .wrapDatePickerInput {
    position: relative;
    @include flex-center;
    justify-content: space-between;
    svg {
      position: absolute;
      right: 0;
      @include flex-center;
      @include wt-ht(10px, 10px);
      fill: $c-1a;
      cursor: pointer;
      z-index: -1;
    }
    .react-datepicker {
      display: flex;
      .react-datepicker__time-box {
        width: 80px;
      }
      .react-datepicker__day,
      .react-datepicker__day-name {
        margin: 0 !important;
        width: 1.5rem !important;
        line-height: 1.5rem !important;
      }
      .react-datepicker__time-list-item {
        height: 24px !important;
        padding: 5px 10px 2px !important;
      }
    }
    .react-datepicker-wrapper {
      position: relative;
      width: 100%;
      input {
        border: none;
        color: $c-1a;
        font-size: 15px;
        font-weight: 500;
        padding: 3px 0 5px;
        height: 32px;
        width: 210px;
        background: transparent;
      }
    }
  }
}

@mixin linkHover {
  color: $c-78;
  text-decoration: none;
  transition: color 0.3s, text-decoration 0.3s;
  &:hover {
    color: $blue-violet;
    text-decoration: underline;
    cursor: pointer;
  }
}

@mixin tableFixedCont {
  .tableContainer {
    overflow: auto;
    height: calc(100% - 70px);
  }
  .alignCenter {
    text-align: center;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border-top: 1px solid #eceff1;
    border-bottom: 1px solid #eceff1;
  }
  thead tr td:first-child,
  th {
    background: #fafafa;
    color: #666;
    font-weight: 500;
  }
  td,
  th {
    border-top: 1px solid #eceff1;
    text-align: left;
    font-size: 14px;
    min-width: 160px;
    letter-spacing: 0.3px;
    padding: 10px;
    height: 46px;
    vertical-align: middle;
  }

  tr td:first-child,
  tr th:first-child {
    padding-left: 20px;
  }
  tr th:last-child,
  tr td:last-child {
    padding-right: 20px;
  }
  th[scope="row"],
  thead tr {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
  }
  tbody th[scope="row"] {
    background: white;
    z-index: 998;
  }
}

@mixin updateAccountStageDropdown {
  .updateContactStageCont {
    position: relative;
    .selectedStage {
      border-radius: 3px;
      background: #f4f4f6;
      padding: 4px;
      color: #3b3b3b;
      font-size: 14px;
      width: fit-content;
      .arrow {
        left: 0px;
      }
      .stageName {
        margin-right: 6px;
      }
    }
    .drop-down-menu {
      max-width: 160px;
      ul li:hover {
        background-color: #eff2ff !important;
        .itemName {
          color: $blue-violet;
        }
      }

      .dropdownList {
        max-width: 160px;
        max-height: 170px;
        .option {
          .listItem {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@mixin fieldRequired($left: 5px, $pos: relative, $color: red) {
  position: $pos;
  padding-left: $left;
  color: $color;
}

@mixin stageDropdownMenu($top, $right) {
  right: $right;
  left: 0;
  margin: 0 auto;
  top: $top;
  border-radius: 5px;
  width: 244px;
  .dropdownList {
    margin: 0;
    .option {
      height: 38px;
      padding: 10px;
      cursor: pointer;

      &:last-child {
        border-bottom: 0;
      }

      .listItem {
        .icon-holder {
          @include flex-align-center;

          .autoEmail {
            fill: #28ad56 !important;
          }

          .phone {
            fill: #1eb9d5 !important;
            path {
              fill: #1eb9d5 !important;
            }
          }

          .linkedin {
            fill: #0a66c2 !important;
            path {
              fill: #0a66c2 !important;
            }
          }
        }
      }
    }
  }
}
