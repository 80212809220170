.contactStageRules {
  @extend %relBlock;
  padding: 25px 16px 25px;
  font-size: 14px;
  @include wt-ht(100%, calc(100% - 56px));
  .headerContent {
    @extend %relBlock;
    border-bottom: 1px solid rgb(224, 224, 224);
    min-height: 40px;
  }
  .stageRulesContentBody {
    position: relative;
    display: block;
    height: calc(100% - 100px);
    width: 100%;
    overflow: auto;
    padding: 15px 0;
    .ruleSetRow {
      position: relative;
      min-width: 620px;
      display: grid;
      grid-template-columns:
        minmax(200px, 450px) 18px 140px
        184px;
      align-items: center;
      justify-content: space-between;
      line-height: normal;
      gap: 15px;
      padding: 10px 15px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 4px #00000012;
      border: 1px solid #f5f8fd;
      border-radius: 5px;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      .dropDownWithText {
        position: relative;
        padding: 8px 6px;
        border-radius: 5px;
        &:hover {
          cursor: pointer;
          background: $c-f5;
        }
        .wrapIconValue {
          position: relative;
          @include flex-center();
          justify-content: space-between;
          cursor: pointer;
          .value {
            position: relative;
            width: 156px;
            text-align: left;
            font-weight: 500;
            color: $c-3b;
            @include ellipsis;
            &.active {
              color: $blue-violet;
            }
          }
          .arrow {
            position: relative;
            top: 3px;
            color: $c-66;
            transition: all 0.3s ease;
            svg {
              fill: $c-53;
              @include wt-ht(11px, 11px);
            }
            &.activeArrow {
              top: 0px;
              text-align: right;
              transform: rotate(-180deg);
              transition: all 0.3s ease;
            }
          }
        }
        .srDropdown {
          @include list-style-zero;
          position: absolute;
          right: 0;
          background: $white;
          border-radius: 10px;
          box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
          padding: 5px 0;
          z-index: 12;
          width: 175px;
          top: 32px;
          opacity: 1;
          border: 1px solid $c-eb;
          transition: opacity 0.3s ease;
          height: 200px;
          overflow-y: auto;
          .srOption {
            position: relative;
            @include flex-center;
            color: $c-66;
            padding: 0px 10px;
            height: 32px;
            font-size: 15px;
            cursor: pointer;
            &.active {
              color: $c-8d;
              @include disable(0.5, unset);
              font-weight: 500;
            }
            &:hover:not(.activeLimit) {
              background: $c-f5;
              color: $c-44;
            }
          }
        }
      }
    }
  }
  .buttonActionList {
    position: relative;
    display: inline-flex;
    width: 100%;
    padding-top: 16px;
    background: white;
    border-top: 1px solid $c-f5;
    .cancelButton {
      @extend %primaryBlueBtn;
      min-width: 130px;
      margin-right: 10px;
      border-radius: 5px;
      color: $blue-violet;
      background: white;
      border: 1px solid $blue-violet;
      margin: 0 12px 0 0;
      &:hover {
        background: white;
      }
    }
    .saveButton {
      @extend %primaryBlueBtn;
      min-width: 130px;
      border-radius: 5px;
      background: $blue-violet;
      margin: unset;
      &.loading {
        transition: all 0.3s ease-in-out;
        min-width: auto;
        border-radius: 35px;
        .load-text {
          @include wt-ht(30px, 30px);
          top: 4px;
          left: 4px;
        }
      }
    }
  }
}
