.checkbox-tick {
  font-size: 11px;
  text-align: left;
  padding: 0;
  color: $c-78;
  margin: 0;
  list-style: none;
  position: relative;
  .list-item {
    padding: 0;
    line-height: 1.3;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    position: relative;

    label {
      position: relative;
      line-height: 1.3;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        font-size: 12px;
        display: block;
        a {
          color: $blue-violet;
          text-decoration: none;
          font-weight: bold;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      input[type="checkbox"] {
        position: relative;
        top: 0px;
        margin: 0 1rem 0 0;
        cursor: pointer;
        &:before {
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          content: "";
          position: absolute;
          left: 0;
          z-index: 1;
          width: 1rem;
          height: 1rem;
          border: 1.4px solid $c-cc;
        }
        &:checked:before {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          height: 0.5rem;
          border-color: #009688;
          border-top-style: none;
          border-right-style: none;
        }
        &:after {
          content: "";
          position: absolute;
          top: -0.125rem;
          left: 0;
          width: 1.1rem;
          height: 1.1rem;
          background: $white;
          cursor: pointer;
        }
      }
    }
  }
}

// Filled Check box
.checkBoxSection {
  position: relative;
  padding: 5px;
  cursor: default;
  display: grid;
  grid-template-columns: 15px auto;
  align-items: center;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .commonFilledCheckBox {
    position: relative;
    @include wt-ht(15px, 15px);
    border: 1px solid $c-cc;
    -webkit-perspective: 18px;
    perspective: 18px;
    display: block;
    border-radius: 3px;
    transform: translateZ(0);
    overflow: hidden;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover {
      border-color: $blue-violet;
    }
    .checkBoxCard {
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      position: relative;
      @include wt-ht(13px, 13px);
      .checkSvgIcon,
      .emptyCheckBox {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        @include wt-ht(13px, 13px);
        border-radius: 3px;
        svg {
          margin-top: 1px;
          fill: none;
          @include wt-ht(11px, 11px);
          path {
            stroke: $white;
            stroke-width: 2.5;
            stroke-linecap: round;
            stroke-linejoin: round;
          }
        }
      }
      .emptyCheckBox {
        background-color: $white;
        z-index: 1;
      }
      .checkSvgIcon {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
        background: $blue-violet;
        text-align: center;
        color: $white;
        width: 13px;
        height: 13px;
        line-height: 15px;
        box-shadow: 0 0 0 1px $blue-violet;
      }
    }
    &.checked {
      border-color: $blue-violet;
      background: $blue-violet;
      .checkBoxCard {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
      }
    }
  }
  .checkBoxName {
    padding-left: 7px;
    padding-top: 2px;
    font-size: 14px;
    color: $c-78;
    word-break: break-word;
    cursor: pointer;
  }
}
