.underConstructionCont {
  display: flex;
  align-items: center;
  justify-content: center;

  .place_balk_workers {
    width: 437px;
    height: 213px;
    position: relative;
    margin: auto;

    .balk_workers {
      width: 437px;
      height: 213px;
      position: relative;
      background-image: url(../../../assets/svg/workers.svg);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-color: transparent;
      background-size: 100%;
      z-index: 3;
    }

    .saw {
      width: 325px;
      height: 63px;
      background-image: url(../../../assets/svg/saw.svg);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-color: transparent;
      background-size: 100%;
      position: absolute;
      left: 53px;
      top: 68px;
      z-index: 2;
      -moz-animation: 1s ease 0s normal none infinite saw;
      -moz-transform-origin: 85% 65%;
      -webkit-animation: saw 1s infinite ease-in-out;
      -webkit-transform-origin: 85% 65%;
      -o-animation: 1s ease 0s normal none infinite saw;
      -o-animation: saw 1s infinite ease-in-out;
      -o-transform-origin: 85% 65%;
      -ms-animation: 1s ease 0s normal none infinite saw;
      -ms-animation: saw 1s infinite ease-in-out;
      -ms-transform-origin: 85% 65%;
      animation: 1s ease 0s normal none infinite saw;
      animation: saw 1s infinite ease-in-out;
      transform-origin: 85% 65%;
    }

    .balk {
      width: 60px;
      height: 23px;
      background-image: url(../../../assets/svg/balk.svg);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-color: transparent;
      background-size: 100%;
      position: absolute;
      top: 92px;
      left: 194px;
    }
  }

  .constructionTextCont {
    text-align: center;
    font-size: 14px;
    font-family: $font-regular, Arial, sans-serif;
  }
}

@-moz-keyframes saw {
  0% {
    left: 53px;
  }
  50% {
    left: 72px;
  }
  100% {
    left: 53px;
  }
}

@-webkit-keyframes saw {
  0% {
    left: 53px;
  }
  50% {
    left: 72px;
  }
  100% {
    left: 53px;
  }
}

@-o-keyframes saw {
  0% {
    left: 53px;
  }
  50% {
    left: 72px;
  }
  100% {
    left: 53px;
  }
}

@-ms-keyframes saw {
  0% {
    left: 53px;
  }
  50% {
    left: 72px;
  }
  100% {
    left: 53px;
  }
}

@keyframes saw {
  0% {
    left: 53px;
  }
  50% {
    left: 72px;
  }
  100% {
    left: 53px;
  }
}
