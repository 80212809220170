.emailLimitBanner {
  position: relative;
  @include flex-center;
  @include wt-ht(100%, 40px);
  padding: 20px;
  justify-content: center;
  background-color: #ffe0e0;
  font-size: 14px;
  color: $c-44;
  z-index: 1;
  .text {
    margin-right: 5px;
  }
  .upgradeBtn {
    position: relative;
    background: transparent;
    border: 1px solid transparent;
    color: $hb-text;
    cursor: pointer;
    font-weight: 500;
    padding: 5px 10px;
    transition: all 0.3s;
    width: 130px;
    color: #ff0000;
    margin: 0 5px 0 0;
    i {
      position: absolute;
      right: 0;
      transform: rotate(180deg);
      font-size: 18px;
      margin-left: 5px;
      transition: all 0.3s;
    }
  }
}
