.pluginContactCard,
.pluginContactCardLoader {
  background: white;
  @extend %relBlock;
  .upcomingToase {
    position: absolute;
    display: block;
    z-index: 9;
    font-size: 14px;
    padding: 10px;
    background: rgba(255, 241, 220, 0.47058823529411764);
    border-radius: 5px;
    border: 1px solid orange;
    color: orange;
    top: 46px;
    left: 50%;
    margin: 0 auto;
    width: fit-content;
    transform: translateX(-50%);
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .pccSectionList {
    @extend %relBlock;
    padding: 15px 20px;
    @extend %contactInfoCard;
    @extend %contactDetailInfoList;
    .contactInfoList {
      padding: 0 0 15px;
      .at {
        color: $c-5c;
      }
    }
    .contactDetailInfoList {
      padding: 15px 0;
      border-bottom: 1px solid #f3f4f8;
      .infoIconCard {
        .icon {
          padding-top: 1px;
          svg {
            @include wt-ht(14px, 14px);
          }
          &.linear-background {
            width: 14px;
            border-radius: 50%;
            margin-right: 10px;
            margin-bottom: 5px;
          }
        }
        .text.linear-background {
          width: 180px;
        }
      }
    }
    .insightLoader {
      border-bottom: 1px solid $bg-default;
      padding: 15px 0;
      .infoCard {
        display: grid;
        grid-template-columns: 20px 140px;
        .icon {
          border-radius: 50%;
          margin-right: 5px;
        }
      }
    }
    .insightsItemList {
      border-bottom: 1px solid $bg-default;
      padding: 15px 0;
      .email-insight-stage {
        padding-left: 24px;
        margin: 15px 0 0;
      }
    }
    .sequenceItemList {
      padding: 15px 0;
      .headingLoader {
        display: grid;
        grid-template-columns: 20px 180px;
        .iconSize.linear-background {
          width: 14px;
        }
        .textNormal.linear-background {
          width: 160px;
        }
      }
      .sequenceLinkUrl {
        position: relative;
        cursor: pointer;
        cursor: pointer;
        font-size: 14px;
        text-decoration: none;
        color: $blue-violet;
        @include ellipsis();
        padding-bottom: 2px;
        width: fit-content;
        &:hover {
          text-decoration: underline;
        }
        &.linear-background {
          width: 160px;
          margin-bottom: 5px;
        }
      }

      .remainCountLoader {
        display: grid;
        margin-top: 15px;
        .linear-background {
          @include wt-ht(50px, 22px);
        }
      }

      .remainCount {
        cursor: pointer;
        &:hover {
          box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.08);
        }
      }
    }
  }
}
