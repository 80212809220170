.reportsPage {
  @include wt-ht(100%, 100%);
  .reportsBody {
    @include wt-ht(100%, calc(100% - 60px));
    .reportsHeaderCont {
      @include flex-align-center(center, space-between);
      padding: 12px;
      .tabNavigationBody {
        border-bottom: 1px solid $c-dd;
        height: 60px;
        .tabNavigation {
          .tabItem {
            height: 58px;
          }
        }
      }
      .rightCont {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        border-bottom: 1px solid $c-dd;
        height: 60px !important;
        .filterDropdown {
          position: relative;
          width: unset;
          margin-right: 2%;
          .userFilterDropDown {
            position: relative;
            margin-left: 10px;
            .userFilterBtn {
              position: relative;
              @include flex-align-center();
              outline: none;
              background: white;
              border-radius: 3px;
              border: 1px solid $c-dd;
              padding: 0 10px;
              @include wt-ht(200px, 36px);
              font-size: 15px;
              transition: all 0.3s ease;
              cursor: pointer;

              &:hover {
                background: $c-fb;
                transition: all 0.3s ease;
              }

              .icon {
                margin-right: 10px;
                @include wt-ht(14px, 14px);

                svg {
                  fill: $blue-violet;
                  @include wt-ht(14px, 14px);
                }
              }

              .UpgradeTooltip {
                .sharpPointer {
                  right: 29px;
                }
              }

              .name {
                position: relative;
                color: $blue-violet;
                min-width: 100px;
                max-width: 110px;
                text-align: left;
                font-size: 15px;
                top: 2px;
                line-height: initial;

                .shortName {
                  text-transform: uppercase;

                  &:not(:last-child)::after {
                    content: ", ";
                  }

                  &.teamNameSelected {
                    text-transform: unset;
                    @include ellipsis;
                  }
                }
              }

              .hiddenCount {
                font-size: 13px;
                color: $c-78;
                min-width: 30px;
                line-height: initial;
                position: relative;
                top: 1px;
              }

              .arrow {
                margin-left: 5px;
                margin-top: 3px;
                @include flex-center();
                transition: all 0.3s ease;

                svg {
                  fill: $c-53;
                  @include wt-ht(11px, 11px);
                }

                &.activeArrow {
                  transform: rotate(-180deg);
                  transition: all 0.3s ease;
                }
              }
            }
            @extend %userListDropdown;

            .overlayUserFilterMask {
              @include bg-mask(4, transparent);
            }
          }
        }
        .filterByDateTab {
          .dateFilter {
            .tabItem {
              &.active {
                z-index: 0;
              }
            }
          }
          .rdrCalendarWrapper {
            z-index: 1000;
          }
        }
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
    .reportsActivity {
      @include wt-ht(100%, calc(100% - 88px));
      padding: 6px 10px;
      &.switchVersionDisplay {
        @include wt-ht(100%, calc(100% - 154px));
      }
    }
    .reportsSequence {
      @include wt-ht(100%, calc(100% - 92px));
      padding: 14px;
      padding-bottom: 6px;
      padding-top: 0px;
      overflow-y: scroll;
      &.switchVersionDisplay {
        @include wt-ht(100%, calc(100% - 154px));
      }
      .analyticsCardCont {
        @include flex-center;
        gap: 10px;
        .analyticsCard {
          width: 100%;
          height: fit-content;
          border: 1px solid $c-dd;
          border-radius: 5px;
          background: $white;
          .cardHeader {
            @include flex-align-center(center, space-between);
            padding: 12px;
            border-bottom: 1px solid $c-dd;
            font-size: 14px;
            .cardName {
              color: $c-343a40;
              font-size: 16px;
            }
            .cardNameLoader {
              width: 30%;
            }
            .totalNo {
              color: $blue-violet;
              background: #3953fb1a;
              padding: 4px;
              font-size: 16px;
            }
            .totalNoLoader {
              width: 15%;
            }
          }

          .detailedAnalyticsSection {
            .detailedAnalyticsCont {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              justify-items: center;
              .nameAndCount {
                @include wt-ht(100%, 100%);
                @include flex-center;
                flex-direction: column;
                padding: 10px;
                &:not(:last-child) {
                  border-right: 1px solid $c-dd;
                }
                &:last-child {
                  border-right: none;
                }
                .name {
                  color: $c-343a40;
                  font-size: 14px;
                  margin-bottom: 4px;
                }
                .count {
                  font-size: 24px;
                }
                .nameLoader {
                  width: 50%;
                  margin-bottom: 16px;
                }
                .countLoader {
                  width: 30%;
                }
              }
            }
          }
        }
      }
      .sequenceGraphCont {
        @include wt-ht(100%, fit-content);
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        margin-top: 10px;
        .sequenceAnalyticsCard {
          border: 1px solid $c-dd;
          border-radius: 5px;
          height: fit-content;
          background: $white;
          .headerCont {
            @include flex-align-center(center, space-between);
            padding: 12px;
            border-bottom: 1px solid $c-dd;
            font-size: 14px;
            .headerName {
              color: $c-343a40;
              font-size: 16px;
            }
            .totalCount {
              color: $blue-violet;
              background: #3953fb1a;
              padding: 4px;
              font-size: 16px;
            }
            .headerNameLoader {
              width: 30%;
            }
            .totalCountLoader {
              width: 10%;
            }
          }
          .donutGraphSection {
            @include flex-align-center(center, space-between);
            position: relative;
            .labelList {
              @include wt-ht(100%, 100%);
              padding: 0 12px;
              .legendCont {
                @include flex-align-center(center, space-between);
                margin-bottom: 10px;
                .legendNameAndIcon {
                  @include flex-center;
                  .legendIcon {
                    @include wt-ht(18px, 18px);
                    display: inline-block;
                    border-radius: 5px;
                  }
                  .labelIcon {
                    color: $c-3b;
                    font-size: 14px;
                    margin-left: 4px;
                  }
                }
                .legendNameAndIconLoader {
                  @include flex-center;
                  width: 100%;
                  margin-bottom: 8px;
                  .legendIconLoader {
                    width: 10%;
                    margin-right: 10px;
                  }
                  .labelIconLoader {
                    width: 40%;
                  }
                }
                .value {
                  color: $c-3b;
                  font-size: 14px;
                }
                .valueLoader {
                  width: 10%;
                }
              }
            }
            .donutChartLoader {
              width: 60%;
              height: 160px;
              border-radius: 100px;
              margin-top: 14px;
              margin-bottom: 14px;
            }
          }
        }
      }
      .reportsActivity {
        padding: 0;
        padding-top: 10px;
        height: 100%;
        .metricsTableSection {
          .metricsHeaderCont {
            .rightSideCont {
              .exportBtnWrapper {
                .customTooltip {
                  .tooltipBody {
                    width: 270px;
                  }
                }
              }
            }
          }
          .metricsTableBody {
            height: calc(100% - 104px);
            &.noPagination {
              height: calc(100% - 50px);
            }
            .tableContainer {
              thead {
                tr {
                  th {
                    min-width: 196px;
                    .tabType {
                      min-width: 196px;
                    }
                  }
                }
              }
              tbody {
                tr {
                  th {
                    .userNameAndEmail {
                      min-width: 196px;
                    }
                  }
                  td {
                    .circleOwner {
                      @include flex-align-center;
                      @include wt-ht(36px, 36px);
                      border-radius: 50%;
                      padding-top: 2px;
                      border: 1px solid $blue-violet;
                      color: $blue-violet;
                      text-transform: uppercase;
                      font-size: 12px;
                      margin-left: 36%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
