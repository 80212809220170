.contactsSchedulePopup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  @include flex-center();
  justify-content: center;
  letter-spacing: 0.3px;
  width: 100%;
  z-index: 101;
  overflow: auto;
  user-select: none;
  .upgradeCard {
    position: relative;
    display: block;
    z-index: 102;
    border-radius: 10px;
    padding: 30px 40px;
    background: white;
    max-width: 75%;
    .contactScheduleWrap {
      display: flex;
    }
    .scheduleWindowPanel {
      border: 1px solid $c-dd;
      border-radius: 6px;
      padding: 16px;
      flex: 0 0 35%;
      color: #383434;
      font-size: 13px;
      h5 {
        text-align: center;
        margin-bottom: 16px;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .scheduleWindowText {
      flex: 1;
      margin-right: 30px;
    }

    .daysWithTimeWrapper {
      @include flex-center();
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
      .weekDays {
        font-family: $font-regular;
        flex: 1;
        &.linear-background {
          @include wt-ht(60px, 16px);
          border-radius: 10px;
          margin-right: 80px;
          flex: 0 0 75px;
        }
      }
      .TimeRange {
        max-width: 130px;
        width: 100%;
        &.linear-background {
          @include wt-ht(90px, 16px);
          border-radius: 10px;
          flex: 0 0 90px;
        }
        &.emptyResult {
          text-align: center;
        }
      }
    }
    .headingText {
      font-size: 16px;
      margin-bottom: 30px;
      text-align: center;
    }
    .smallText {
      font-size: 13px;
      margin-bottom: 20px;
      &:first-child {
        margin-top: 8px;
      }
    }
    .timezoneSection {
      font-size: 14px;
      font-weight: bold;
      margin: 20px 0;
    }
    .hereText {
      text-decoration: underline;
      color: $blue-violet;
      cursor: pointer;
      border: 0;
      font-size: 14px;
      background-color: transparent;
      padding: 0;
    }
    .closeIcon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      i {
        font-size: 19px;
      }
      &:hover {
        background-color: #ddd;
        border-radius: 50%;
        @include wt-ht(20px, 20px);
      }
    }
    .buttonWrapper {
      @include flex-center();
      button {
        width: 150px;
        margin: unset;
        padding: 8px;
        &:first-child {
          margin-right: 24px;
          background-color: $white;
          color: $black;
          border: 1px solid #c3c4c4;
        }
        &:last-child {
          background-color: $blue-violet;
        }
      }
    }
  }
}

.upgradeOverlay {
  @include bg-mask(100, rgba(0, 0, 0, 0.57));
}
