.accordianSection {
  @extend %relBlock;
  .accordian {
    width: 100%;
    position: relative;
    display: grid;
    padding: 15px;
    border-top: 1px solid $c-eb;
    grid-template-columns: 40% 60%;
    &.showTitle {
      grid-template-columns: calc(100% - 60px) 60px;
    }
    &.linear-background {
      padding: 17px 15px;
    }
    .subjectTitle {
      font-size: 15px;
      width: 100%;
      @extend %relBlock;
      text-align: left;
      color: $c-66;
      @include ellipsis;
      &.boldBlueText {
        font-weight: 600;
        color: $blue-violet;
      }
    }
    .descBtnWrap {
      position: relative;
      @include flex-center;
      justify-content: flex-end;
      width: 100%;
      grid-template-columns: 100%;
      font-size: 14px;
      .desc {
        @extend %relBlock;
        color: $c-87;
        @include ellipsis;
        width: calc(100% - 44px);
        &.linear-background {
          width: calc(100% - 54px);
          margin-right: 5px;
        }
      }
      .collapseBtn {
        color: $blue-violet;
        cursor: pointer;
        &.linear-background {
          width: 40px;
        }
      }
    }
  }
  .accordianContent {
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease;
    margin: 0 15px 0;
    width: calc(100% - 30px);
    &.prevMode {
      margin: 0 15px 15px;
    }
    .accordionText {
      font-weight: 400;
      font-size: 14px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid $c-eb;
      max-height: 200px;
      user-select: none;
      overflow: auto;
      .ql-editor{
        padding: 0;
      }
    }
  }
}
