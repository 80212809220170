.upgradeTooltipSection {
  position: absolute;
  z-index: 101;
  width: 360px;
  user-select: none;
  .pointer {
    position: absolute;
    padding: 8px;
    z-index: 101;
    transform: rotate(-45deg);
    background: $c-26;
  }
  .tooltipCard {
    position: relative;
    display: block;
    z-index: 101;
    padding: 25px 30px;
    color: white;
    background: $c-26;
    border-radius: 16px;
    .title {
      position: relative;
      @include flex-center;
      margin-bottom: 15px;
      h4 {
        font-size: 16px;
        color: $c-33;
        font-weight: $font-medium;
        font-weight: bold;
        text-align: left;
        line-height: 1.3;
      }
    }
    .subTitle {
      text-align: left;
      font-size: 14px !important;
      font-weight: 600;
      color: white;
      margin-bottom: 8px;
      line-height: 1.4;
    }
    .descText {
      text-align: left;
      color: $c-a2;
      font-size: 13px !important;
      margin-bottom: 10px;
      line-height: 1.3;
      a {
        text-decoration: none;
        color: $blue-violet;
        font-weight: bold;
        &:hover {
          color: $blue-violet;
        }
      }
    }
    .upgradeTooltipBtn {
      margin-top: 15px;
      position: relative;
      margin-left: 0;
      font-weight: bold;
      color: white;
      border-radius: 35px !important;
      padding: 6px 0 !important;
      min-width: 100px;
      background: #ff6057 !important;
      border: 1px solid #ff6057 !important;
      outline: none;
      .btnText {
        color: white;
        font-size: 14px;
        line-height: 1;
      }
    }
  }
  &.btmRight {
    bottom: 12px;
    right: -60px;
    padding-bottom: 10px;
    .pointer {
      bottom: 2px;
      right: 52px;
    }
  }
  &.btmRightCustom {
    bottom: 30px;
    right: -60px;
    padding-bottom: 10px;
    .pointer {
      bottom: 2px;
      right: 52px;
    }
  }
  &.contactCustom {
    bottom: 40px;
    padding-bottom: 10px;
    .pointer {
      bottom: 2px;
      left: 165px;
    }
  }
  &.rightBtm {
    bottom: -26px;
    right: 12px;
    padding-right: 10px;
    .pointer {
      bottom: 17px;
      right: 2px;
    }
  }
  &.topRight {
    top: 10px;
    right: -30px;
    padding-top: 10px;
    .pointer {
      top: 2px;
      right: 22px;
    }
  }
  &.topRightCustom {
    top: 42px;
    right: -30px;
    padding-top: 10px;
    .pointer {
      top: 2px;
      right: 22px;
    }
  }
  &.topLeft {
    top: 10px;
    left: -30px;
    padding-top: 10px;
    .pointer {
      top: 2px;
      left: 22px;
    }
  }
  &.leftTop {
    top: -20px;
    left: 8px;
    padding-left: 10px;
    .pointer {
      top: 12px;
      left: 4px;
    }
  }
}
