*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: $font-regular, sans-serif;
  line-height: 1.25;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
}

.scrollHidden {
  overflow: hidden;
}

body {
  margin: 0;
  font-weight: 400;
  text-align: left;
  background-color: #fff;
  height: 100%;
  overflow: hidden;
  position: relative;

  &.settingsPage {
    .grecaptcha-badge {
      visibility: hidden !important;
    }
  }

  #root,
  main {
    width: 100%;
    height: calc(100% - 40px);
    position: relative;
  }
}

.linear-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  height: 14px;
  border: none;
  position: relative;
  overflow: hidden;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

:focus {
  outline: none;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  letter-spacing: 0.1px;
}

p {
  margin: 0;
  letter-spacing: 0.3px;
}

::placeholder {
  color: #7878788a;
  font-size: 14px;
}

.hide {
  display: none !important;
}

.visibilityHidden {
  visibility: hidden;
}

.fa {
  color: #aaa;
  cursor: default;
}

.show-elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ov-hide {
  overflow: hidden !important;
}

.confirmation-popup-mask,
.common-popup-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  background: rgba(0, 0, 0, 0.53);
  opacity: 0.75;

  &.bulkMask {
    z-index: 1;
  }
}

.confirmation-popup-mask {
  z-index: 115;
}

.common-tooltip-error {
  position: absolute;
  top: 50px;
  left: 15px;
  background: #333;
  color: #fff;
  font-size: 11px;
  letter-spacing: 0.1px;
  padding: 5px 20px;
  border-radius: 4px;
  text-align: center;
  transition: all 0.1s ease-in-out;
  z-index: 1;
  line-height: normal;

  .top-arrow {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #333;
    position: absolute;
    top: -7px;
    left: 5px;
  }
}

#root input:-internal-autofill-selected {
  background: transparent !important;
  color: $blue-violet !important;
  font-size: 14px !important;
  appearance: none;
}
.popupWrapper {
  z-index: 9999999;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  top: 0;
  &.confirmationPopupWrapper {
    z-index: 99999999;
    .logoutCont {
      .redirectMsg {
        font-size: 12px;
        margin-top: 4px;
      }
    }
  }
  &.quillPopupWrapper {
    z-index: 12;
    height: 100%;

    .common-popup {
      padding: 25px;
    }
  }
  .matchSenderTokens {
    color: $c-4c;
    font-size: 14px;
  }

  .common-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    //position: relative;
    width: 450px;
    padding: 25px;
    padding-top: 0px;
    z-index: 1111;
    will-change: transform;
    font-size: 17px;
    font-family: $font-regular;
    .emailLink {
      color: $blue-violet;
      text-decoration: none;
    }

    .confirmationPopup {
      background: white;
      .loaderWrapper {
        padding: 25px !important;
        display: flex;
        align-items: center;
        flex-direction: column;
        .loadMessage {
          padding: 15px;
        }
      }
    }

    &.quillPopup {
      width: 950px;

      ::placeholder {
        color: #787878;
        font-size: 15px;
        letter-spacing: 1px;
      }

      .ql-toolbar {
        @include bor-rad-btm(10px, 10px);
      }

      .ql-container {
        max-height: 200px;
        overflow: auto;
        border: 0 !important;

        .ql-editor {
          padding: 0;
        }

        .ql-tooltip {
          left: 0 !important;
          transform: translateY(-10px);
        }
      }

      .popup-wrap {
        width: 100%;
        max-width: 940px;
        will-change: transform;
        border-radius: 10px;
        background: $white;
        .title {
          padding: 25px 40px 15px 25px;
        }

        .inputWrapper {
          @include flex-center();
          margin-bottom: 10px;
          font-family: $font-regular;

          .inputCont {
            width: 80%;
            display: inline-block;
            background-color: $white;
            margin: 0px 15px 15px 0px;
            color: $black;
            position: relative;
            padding-top: 25px;

            .inputLabel {
              color: $c-66;
              font-size: 13px;
              font-family: $font-regular;
              position: absolute;
              top: 40%;
              left: 0px;
              transition: all 0.2s linear;
            }
          }

          .input-box:focus ~ label,
          .inputCont.filled label,
          .select-box:focus ~ label,
          .selectValues.filled label {
            top: 3px;
            transition: all 0.2s linear;
          }

          &.showDuration {
            .inputCont {
              width: 40%;
            }
          }
        }
      }

      .popup-action-wrap {
        justify-content: flex-end !important;

        .addNextBtn {
          background: $white;
          color: $white;
          border-color: $white;

          svg {
            fill: $c-73;
          }
        }
      }
    }

    .popup-wrap {
      display: flex;
      flex-direction: column;
      min-width: 420px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-top: 0px;
      overflow: hidden;
      will-change: transform;
      transition: all 0.4s ease;
      background: $white;

      .title {
        color: $c-53;
        padding: 25px 40px 15px 40px;
        background-color: $white;
        font-size: 22px;
        font-family: "Graphik-Regular ", Arial, sans-serif;
      }

      .popup-content-wrap {
        background-color: $white;
        padding: 15px 25px 10px 25px;
        text-align: center;
        justify-content: center;
        will-change: inherit;
        .retryResponse,
        .bulkDeleteSeqContactResponse {
          text-align: left;
          .linkHighLight {
            color: $blue-violet;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
          .leftBorder {
            position: absolute;
            margin-right: 4px;
          }
          .failureResponseMsg {
            transition: all ease 0.4ms;
            background-color: #ff000029;
            margin-left: 6px;
            padding: 8px;
            font-size: 14px;
            line-height: 16px;
            border-radius: 5px;
            border-left: 4px solid #ff00004d;
            &.textLeft {
              text-align: left;
            }
          }
        }
        .retryResponse {
          text-align: center;
        }
        .input-box {
          line-height: 36px;
          appearance: none;
          border: 0;
          font-size: 15px;
          width: 100%;
          border-bottom: 1px solid $c-dd;
          color: $blue-violet;
          &.disabledInput {
            cursor: unset;
            border-bottom: none;
            pointer-events: none;
            user-select: none;
          }
        }
        .noteSection {
          font-size: 12px;
        }
        .disabledInputLabel {
          top: 3px !important;
        }

        .input-box::placeholder {
          color: #afafaf;
          letter-spacing: 1px;
          color: rgba(96, 99, 103, 0.65);
          font-family: $font-regular;
          font-size: 13px;
          letter-spacing: 1px;
        }

        .profileErrorMsg {
          display: none;
          top: 70px;

          &.errorShown {
            display: block;
          }
        }

        select {
          width: 100%;
          border: 0;
          padding: 10px 10px 10px 0px;
          background: transparent;
          border-bottom: 1px solid $lb-blue;
          font-family: $font-regular, sans-serif;
          font-size: 13px;
          will-change: transform;
          -webkit-appearance: none;
          background: url("../assets/Sequence/dropdow_arrow.png") no-repeat
            right 7px center;
          background-size: 10px;
          margin-right: 10px;
        }
      }

      .popup-action-wrap {
        background-color: #fff;
        padding: 15px 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 16px;
        .toggleBtnSection {
          margin-right: 10px;
          .customTooltip {
            position: absolute;
            top: -82px;
          }
          .tooltipBody {
            background-color: $c-4a;
            box-shadow: unset;
          }
          .tooltipText {
            color: $white;
          }
          .sharpPointer {
            bottom: -89px;
            right: 9px;
            background-color: $c-4a;
            border-right: 1px solid $c-4a;
            border-top: 1px solid $c-4a;
            z-index: 0;
          }
          .tooltipWrap {
            width: 14px;
            height: 14px;
            margin-left: 6px;
            position: relative;
            cursor: pointer;

            .iIcon {
              font-size: 14px;
            }
          }
        }
        .popup-action-btn {
          min-width: 115px;
          align-items: center;
          justify-content: center;
          display: flex;
          height: 36px;
          padding: 0 10px;
          font-size: 14px;
          border-radius: 5px;
          border: solid 1px #c4c4c4;
          color: #242323;
          cursor: pointer;
          font-family: $font-regular, Arial, sans-serif;
          line-height: 36px;
          margin: 0px 5px;
          &.startSequenceBtn {
            &.loading {
              min-width: auto;
              border-radius: 35px;
            }
          }
        }

        .positive-btn {
          background-color: $blue-violet;
          color: $white;
        }

        .cancelBtn {
          margin-right: 10px;
        }
      }
    }

    .popup-close {
      position: absolute;
      padding: 3px;
      top: 10px;
      z-index: 1;
      right: 11px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .material-icons {
        font-size: 19px;
      }

      &:hover {
        background-color: #ddd;
        border-radius: 50%;
      }

      svg {
        fill: $c-53;
        cursor: pointer;
      }
    }
  }

  &.addPeopleWrapper {
    z-index: 12;
    height: 100%;

    .common-popup {
      width: 950px;
      z-index: 9;
      padding: 25px;

      .popup-wrap {
        min-width: 900px;
        border-radius: 10px;
        background: $white;

        .title {
          padding: 25px 40px;
        }

        .popup-content-wrap {
          padding: 0px 37px 20px;

          .detailsContainer {
            border: 1px solid $c-dd;
            border-radius: 10px;
            overflow: hidden;

            .tableContainer {
              .tableHader {
                background: $c-f8;
                height: 54px;
                display: flex;
                align-items: center;
                padding: 0px 20px;
                border-bottom: 1px solid $c-dd;

                .deleteIcon {
                  width: 5%;
                  padding: 0px 20px;
                  visibility: hidden;
                }

                .column {
                  color: $c-66;
                  width: 23%;
                  padding: 0px 10px;
                  text-align: left;
                  .required {
                    padding-left: 2px;
                    color: red;
                  }
                }
              }

              .tableBody {
                .tableBodyWrapper {
                  height: 350px;
                  overflow-y: auto;

                  .addRecipientCont {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid $c-dd;
                    padding: 0px 30px;

                    .addRecipientText {
                      color: $blue-violet;
                      cursor: pointer;
                    }

                    .addRecipientIcon {
                      width: 20px;
                      height: 20px;
                      margin-right: 10px;

                      svg {
                        fill: $blue-violet;
                        width: 20px;
                        height: 18px;
                      }
                    }
                  }

                  .dataPreviewWrapper {
                    overflow-y: auto;

                    .addRecipientContainer {
                      padding: 10px 20px;
                      border-bottom: 1px solid $c-dd;
                      @include flex-center;
                      flex-direction: row;
                      height: 55px;
                      .delete {
                        color: $c-66;
                        width: 5%;
                        //margin: 0px 15px;
                        font-size: 16px;
                        line-height: 21px;
                        cursor: pointer;
                      }

                      .inputCont {
                        width: 23%;
                        display: inline-block;
                        padding: 0px 10px;

                        input {
                          border: 0;
                          font-size: 16px;
                          color: $c-66;
                          width: 100%;
                          text-overflow: ellipsis;
                          // border-bottom: 1px solid #ddd;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .nextBtnCont {
            float: right;
            padding-top: 20px;

            .btn {
              width: fit-content;
              width: -webkit-fit-content;
              width: -moz-fit-content;
              height: 36px;
              padding: 0px 10px;
            }
          }
        }
      }

      .popup-close {
        //padding: 25px 0px;
        padding: 3px;
        top: 20px;
        right: 15px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .material-icons {
          font-size: 19px;
        }
      }
    }
  }

  &.addSeqinContactsPage {
    height: 100%;
    z-index: 102 !important;

    #nextBtnInAddContact {
      background-color: $blue-violet;
    }

    .common-popup {
      width: 850px;
      z-index: 102 !important;
      padding: 25px;

      .popup-wrap {
        min-width: 800px;
        height: 500px;
        border-radius: 10px;

        .title {
          padding: 25px 25px 0px 25px;
          font-size: 20px;
          .descText {
            @extend %relBlockWidth;
            font-size: 14px;
            color: $c-66;
            margin-top: 5px;
          }
        }

        .popup-content-wrap {
          padding: 25px;
          max-height: calc(100% - 113px);
          height: 100%;
          &.discover {
            max-height: calc(100% - 148px);
          }
          .leftSection {
            display: inline-block;
            width: calc(40% - 20px);
            text-align: left;
            float: left;
            border-radius: 10px;
            border: solid 1px #dddddd;
            background-color: #ffffff;
            color: #666666;
            margin-right: 20px;
            height: 100%;

            .folderCont {
              height: 100%;

              .folderListCont {
                height: 100%;

                .dynamicFoldersWrapper {
                  height: calc(100% - 90px);
                  overflow: auto;
                }

                .folder {
                  display: flex;
                  align-items: center;
                  padding: 12px 15px;
                  height: fit-content;
                  cursor: pointer;
                  font-size: 14px;
                  border-bottom: 1px solid $bg-default;
                  &.dropOver {
                    opacity: 0.6;
                    color: $blue-violet;
                    border-bottom: 1px solid $blue-violet;
                  }
                  .folderIcon {
                    margin-right: 10px;
                    width: 16px;
                    height: 16px;

                    svg {
                      fill: #666666;
                      width: 16px;
                      height: 16px;
                    }
                  }

                  .folderName {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    white-space: nowrap;
                  }

                  .rightArrowIcon {
                    width: 18px;
                    height: 18px;
                    display: none;

                    svg {
                      height: 18px;
                      width: 18px;

                      path {
                        fill: $blue-violet;
                      }
                    }
                  }

                  &.selected {
                    color: $blue-violet;

                    .rightArrowIcon {
                      display: block;
                    }
                  }
                }
              }
            }
          }

          .rightSection {
            display: inline-block;
            text-align: left;
            border: solid 1px $c-dd;
            background: white;
            color: $c-66;
            height: 100%;
            width: 60%;

            .sequenceCont {
              height: 100%;

              .sequenceInnerCont {
                height: 100%;
                overflow-y: auto;

                .noSequence {
                  font-size: 16px;
                  text-align: center;
                  color: $c-66;
                  padding: 15px;
                  height: 100%;
                  @include flex-center;
                  flex-direction: column;
                  justify-content: center;
                  img {
                    max-width: 60%;
                  }
                  p {
                    margin: 5px 0 15px;
                  }
                  .redirectToCreateSequence {
                    background: $blue-violet;
                  }
                }
              }

              .sequenceDetails {
                display: flex;
                align-items: center;
                padding: 12px 15px;
                height: fit-content;
                font-size: 14px;
                border-bottom: 1px solid $bg-default;
                .freemiumIcon {
                  padding-left: 12px;
                }
                .contentClass {
                  height: 20px;
                }
              }
            }
          }
        }

        .popup-action-wrap {
          justify-content: flex-end;
          padding: 0px 25px 25px 25px;

          .btn {
            width: 90px;
            height: 36px;
            margin: 0;
          }
        }
      }
    }
    .common-popup-mask {
      z-index: 101 !important;
    }
  }

  &.snoozeTaskPopup {
    height: 100%;
    top: 0px;

    .common-popup {
      min-width: 450px;

      .popup-wrap {
        border-radius: 10px;
        min-width: 420px;
        background: $white;
      }
    }
  }

  &.editContactPopup {
    z-index: 91;
    height: 100%;

    .confirmation-popup {
      width: 600px;
      padding: 25px;

      .popup-wrap {
        width: 100%;
        max-width: 550px;
        will-change: transform;
        border-radius: 10px;
        background: $white;
        .popup-content-wrap {
          padding: 0px 40px 15px 40px;
          .highLi .inputOptionsHolder.d-flex {
            @include flex-center();
          }

          .inputWrapper {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            font-family: $font-regular;

            .inputCont {
              width: 100%;
              display: inline-block;
              background: #fff;
              color: #000;
              position: relative;
              padding: 25px 15px 0 0;

              input {
                line-height: 36px;
                -webkit-appearance: none;
                border: 0;
                font-size: 14px;
                width: 100%;
                border-bottom: 1px solid #dddddd;
                color: #3953fe;
              }

              .inputLabel {
                color: #666666;
                font-size: 13px;
                font-family: $font-regular;
                position: absolute;
                top: 40%;
                left: 0px;
                transition: all 0.2s linear;

                sup {
                  color: #ff0000;
                }
              }
            }

            .input-box:focus ~ label,
            .inputCont.filled label,
            .select-box:focus ~ label,
            .selectValues.filled label {
              top: 3px;
              transition: all 0.2s linear;
            }
          }

          .react-datepicker-popper {
            transform: translate3d(-10%, 49px, 0) !important;
            .react-datepicker {
              font-size: 11px;
              width: max-content;
              .react-datepicker__month {
                margin: 0.2rem !important;
              }
              .react-datepicker__time-container {
                width: fit-content;
              }
              .react-datepicker__day-name,
              .react-datepicker__day,
              .react-datepicker__time-name {
                width: 1.3rem !important;
                line-height: 1.3rem !important;
                margin: 0.146rem !important;
                border-radius: 11px;
              }

              .react-datepicker__time-container
                .react-datepicker__time
                .react-datepicker__time-box
                ul.react-datepicker__time-list
                li.react-datepicker__time-list-item {
                height: auto !important;
                @include flex-center();
              }
              .react-datepicker__triangle {
                left: 50% !important;
              }
            }
          }

          .react-datepicker__input-container input {
            background: none !important;
            border: none !important;
            font-size: 14px !important;
            color: #3953fe !important;
            // border-bottom: 1px solid !important;
            width: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }

  &.updateTaskPopup {
    .ql-toolbar {
      .ql-variables {
        display: none !important;
      }
    }

    &.manualemail {
      #reactEditor .quill {
        overflow: unset !important;
      }
      &.notesOptionShown {
        .quill {
          .ql-container {
            max-height: 95px !important;
            .ql-editor {
              min-height: 95px !important;
            }
          }
        }
      }

      .confirmation-popup {
        width: 700px;

        .popup-wrap {
          max-width: 650px;
          background: $white;

          .addNotesBtn {
            color: $blue-violet;
            font-size: 14px;
            cursor: pointer;
            padding: 5px;
            margin-left: 10px;
            &:hover {
              transition: all 0.3s;
              border-radius: 5%;
              background: $c-dd;
            }
          }
          @extend %NotesSection;
        }

        .popup-action-wrap {
          height: 55px;
        }
      }
    }

    &.linkedin {
      .confirmation-popup {
        width: 650px;
        .popup-wrap {
          background: $white;
          max-width: 600px;
          .popup-content-wrap {
            .inputOptionsHolder.d-flex {
              margin-bottom: 15px;
            }

            .inputOptionsHolder.notes-section {
              height: 250px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .confirmation-popup {
      .popup-wrap {
        background: $white;
        .title {
          padding: 20px 40px 20px 40px;
          .contactsAdded {
            color: #3953fe;
            font-size: 16px;
            padding-top: 5px;
          }
        }

        .popup-content-wrap {
          .inputWrapper {
            margin-bottom: 15px;
            .inputCont {
              padding-top: 31px;
              &.filled input[readOnly] {
                border: 0px !important;
              }
            }
          }

          .inputOptionsHolder.d-flex {
            width: 100%;
            height: 60px;
            justify-content: space-between;
            font-family: $font-regular;
            .p-rel.inputOptions,
            .inputWrapper {
              width: 48%;
              .name {
                margin: 0px;
                position: relative;
                display: block;
                text-align: left;
              }
              input {
                line-height: 36px;
                border-bottom: 1px solid $c-dd !important;
              }
            }
          }

          .input-box:focus ~ label,
          .inputCont.filled label,
          .select-box:focus ~ label,
          .selectValues.filled label {
            top: 10px !important;
          }

          #reactEditor {
            .quill {
              .ql-container {
                max-height: 200px;
                overflow: auto;
                border: 0 !important;

                .ql-editor {
                  padding: 0;
                }
              }

              .ql-toolbar {
                button {
                  height: 20px;
                  width: 20px;
                  padding: 3px;
                  color: #666;
                }

                .ql-formats {
                  margin-right: 5px;

                  .ql-font.ql-picker {
                    width: 95px;
                    .ql-picker-label {
                      padding-left: 0;
                    }
                  }

                  .ql-size.ql-picker {
                    width: 75px;

                    .ql-picker-options {
                      width: 80px;

                      .ql-picker-item[data-value="9.75px"]:before {
                        content: "small";
                        font-size: 10px !important;
                      }

                      .ql-picker-item[data-value="13px"]:before {
                        content: "Normal";
                        font-size: 13px !important;
                      }

                      .ql-picker-item[data-value="19.5px"]:before {
                        content: "Large";
                        font-size: 18px !important;
                      }

                      .ql-picker-item[data-value="32.5px"]:before {
                        content: "Huge";
                        font-size: 25px !important;
                      }
                    }

                    .ql-picker-label {
                      padding-left: 0px;
                    }
                  }
                }
              }
            }
          }
        }
        .popup-action-wrap {
          padding-top: 0px;
          @include flex-center();
          justify-content: space-between;

          button {
            padding: 10px 20px;
            margin: 0;
          }
        }
      }
    }
  }

  &.tagPopup {
    z-index: 12;
    height: 100%;

    .common-popup {
      width: 750px;
      z-index: 9;
      padding: 25px;

      .popup-wrap {
        min-width: 600px;
        border-radius: 10px;
        background: $white;
        max-height: 510px;
        min-height: 275px;
        box-sizing: border-box;

        .popup-close {
          top: 21px;
        }

        .title {
          padding: 25px 40px;
        }

        .addTagSelectBox {
          text-align: left;
          @include flex-center();
          flex-direction: column;

          .innerText {
            color: $c-66;
            text-align: center;
            font-size: 16px;
            font-family: $font-regular;
            width: 435px;
            line-height: 1.6;
            margin-bottom: 20px;
          }
        }

        @include previewTags();

        .btnCont {
          padding: 0px 37px 25px 37px;
          @include flex-center();
          justify-content: center;
          width: 100%;
          background-color: $white;

          .continueBtn {
            background-color: $white;
            color: $blue-violet;
            margin: 0px 10px;
            font-size: 16px;
            padding: 12px 25px;
            cursor: pointer;
            transition: all 0.5s;
            border: 1px solid $c-f3;
            border-radius: 5px;

            &:hover {
              background: #e7e9f3;
              border-radius: 10px;
            }
          }

          .nextBtn {
            font-size: 16px;
            margin: 0px 10px;
            width: 120px;
            background-color: $blue-violet;

            &.disabled {
              pointer-events: none;
              background-color: $c-cb;
            }
          }
        }
      }
    }
  }
}

.toggleThreadSection {
  @include flex-direction(row);
  .threadReplyToogle {
    @include flex-direction(row);
    padding: 8px 20px;
    margin-left: 20px;
    border-radius: 35px;
    background: $c-f9;
    .descText {
      color: $c-87;
      font-size: 14px;
      padding-right: 10px;
    }
    .activeToogle {
      color: $c-22;
    }
  }
}

.seqBulkPopupWrapper {
  display: none;
  z-index: 9999999;
  position: fixed;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;

  .seqBulkCont {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    box-shadow: 0 0 22.3px 1.7px rgba(59, 59, 59, 0.21);
    border-radius: 10px;
    overflow: hidden;
    will-change: transform;
    z-index: 9;
    background: #fff;
    font-family: $font-regular;

    .bulkContTitle {
      color: #fff;
      padding: 15px;
      background-color: $lb-blue;
      font-size: 17px;
      will-change: transform;
    }

    .totalProspect {
      padding: 13px 13px 0px;
      font-size: 14px;
    }

    form {
      .data-preview-sec {
        max-height: 435px;
        min-height: auto;
        overflow: hidden;
        width: 98%;
        padding: 0;
        margin: 1% auto;

        table {
          margin: 5px;
          border-collapse: collapse;
          table-layout: fixed;
          font-size: 14px;
          float: left;
          display: inline-table;
          width: calc(100% - 10px);
          border: 1px solid rgb(197, 197, 197);
          border-bottom: 0px;

          thead {
            display: table;
            width: 100%;
            table-layout: fixed;

            td {
              border-bottom: 1px solid rgb(197, 197, 197);
              background: none;
              width: 85%;
              padding: 10px;
              outline: 0;
              font-weight: 500;
              text-align: left;
              font-size: 16px;
              text-transform: capitalize;
            }
          }

          tbody {
            display: block;
            height: 384px;
            overflow: auto;
            width: 100%;

            tr {
              display: table;
              width: 100%;
              table-layout: fixed;

              td {
                border-bottom: 1px solid rgb(197, 197, 197);

                input[type="text"] {
                  background: none;
                  width: 85%;
                  padding: 10px;
                  border: none;
                  outline: 0;
                  word-break: break-word;
                  text-overflow: ellipsis;
                  width: -webkit-fill-available;
                }

                .ae-td-close {
                  width: 100%;
                  text-align: center;

                  i {
                    color: $lb-blue;
                  }
                }
              }
            }
          }
        }
      }

      .buttonCont {
        display: inline-block;
        width: 100%;
        padding-top: 10px;
        box-shadow: inset 2px 2px 15px #fafafb;
        background: #fafafb;

        .button {
          background: $lb-blue;
          color: #fff;
          float: right;
          padding: 10px 40px;
          margin-right: 2%;
          border: 1px solid $lb-blue;
          border-radius: 3px;
          cursor: pointer;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.confirmation-popup {
  .popup-wrap {
    min-width: 320px;
    max-width: 360px;
    background: $white;

    .popup-content-wrap {
      padding-bottom: 19px;
      font-size: 14px;
      line-height: 20px;
      position: relative;
      .highlightText {
        color: $blue-violet;
      }
      .linkHighLight {
        color: $blue-violet;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .leftBorder {
        border-left: 4px solid red;
        position: absolute;
        margin-right: 4px;
        left: 0;
        top: 0;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      .failureResponseMsg {
        transition: all ease 0.4ms;
        margin-top: 8px;
        background-color: #ffd6d6;
        margin-left: 6px;
        padding: 8px;
        font-size: 14px;
        line-height: 16px;
        border-radius: 4px;
        position: relative;
      }
    }
  }
}

.common-loader {
  .common-loading-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #9d9d9d;
    opacity: 0.3;
    z-index: 9999999;
    // background-color: #eee;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $lb-blue;
    font-size: 40px;
    z-index: 11;

    .margin-10 {
      margin-right: 15px;
    }
  }
}

.common-toast-wrapper {
  //visibility: hidden;
  width: fit-content;
  will-change: transform;
  color: $white !important;
  text-align: center;
  border-radius: 5px;
  padding: 10px 16px;
  position: absolute;
  z-index: 99999999;
  font-size: 14px;
  left: 0;
  right: 0;
  margin: auto;
  top: 0px;
  box-shadow: 0px 6px 11.5px 0.5px rgba(59, 59, 59, 0.17);
  opacity: 0;
  transform: translate(0%, -100%);
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  align-items: center;

  .messageCont {
    display: flex;
    will-change: transform;

    .svgIcon {
      width: 16px;
      height: 16px;
      margin-right: 10px;

      svg {
        width: 16px;
        height: 16px;
      }

      &.crossIcon {
        svg {
          fill: #fff;
        }
      }

      &.tickIcon {
        svg {
          fill: #fff;
        }
      }

      &.infoIcon {
        i {
          font-size: 16px;
        }
      }
    }
    .message {
      align-self: center;
      will-change: transform;
    }
    .undoButton {
      position: relative;
      font-size: 12px;
      background: #265930;
      color: #c9dacc;
      padding: 2px 8px;
      line-height: 1.5;
      border-radius: 4px;
      margin-left: 0.4rem;
      letter-spacing: 0.3px;
      will-change: transform;
      height: 20px;
      cursor: pointer;
    }
    .closeToaster {
      position: relative;
      cursor: pointer;
      font-size: 18px;
      margin-left: 10px;
      border-radius: 35px;
    }
    .toolTip {
      position: relative;
      cursor: pointer;
      font-size: 18px;
      margin-left: 20px;
      margin-top: 20px;
      transform: translateY(5px);
      border-radius: 35px;
    }
  }
}

.show {
  top: 15px;
  opacity: 1;
  transform: translate(0%, 0%);
}

.upcomingToase {
  background: rgba(255, 241, 220, 0.47058823529411764);
  border: 1px solid orange;
  color: orange !important;
  top: 46px;
}

.headerStyle {
  @include flex-center;
  padding-left: 20px;
  background: transparent;
  font-size: 14px;
  border-bottom: 1px solid $b-c-1;
  font-family: $font-regular;
  .txt-loading {
    width: 150px;
    height: 20px;
    border-radius: 20px;
  }
}

.pageClass {
  width: 100%;
  height: 100%;
  background: $c-f5f8;
  border-color: $bg-default;

  .pageCont {
    height: 100%;
    overflow: hidden;

    .headerSection {
      height: 60px;
      display: flex;
      align-items: center;
      border-bottom: solid 1px $bg-default;
      background-color: #fff;

      .leftSectionWrapper {
        @include flex-center();
      }

      .headerLeftSection {
        width: 50%;
        padding: 10px;
        display: inline-block;
      }

      .headerRightSection {
        width: 50%;
        padding: 10px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        .addNewButton.btn {
          height: 36px;
          margin: 0;
        }

        .downWrapper {
          width: 180px;
        }
      }
    }

    .leftSection {
      width: 260px;
      height: 100%;
      position: relative;
      border-radius: 10px;
      padding-right: 10px;
      overflow: hidden;
    }

    .rightSection {
      width: 100%;
      overflow: hidden;
      background: $white;
      display: block;
      height: 100%;
      position: relative;
      .nameInputcont {
        padding: 10px 5% 10px 5%;
        overflow: hidden;
        overflow-x: auto;
        &.linear-background {
          z-index: 5;
          border-radius: 0px;
        }
      }
    }
  }
}
.seperator {
  padding: 0px 5px;
}

.breadCrumbInnerCont {
  display: inline;
  &.betaBadgeWrap {
    display: inline-flex;
    align-items: center;
    .betaBadge {
      @include wt-ht(50px, 20px);
    }
  }

  .breadValue {
    display: inline-block;
    cursor: pointer;
    color: $blue-violet;
    text-decoration: none;
    &:hover {
      color: $blue-violet;
    }
    &.linear-background {
      width: 100px;
    }
    &.text-path {
      cursor: default;
      color: $c-53;
      pointer-events: none;
    }
  }
}
.btn {
  background-color: $blue-violet;
  border-color: $blue-violet;
  height: 36px;
  width: 130px;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  border: 0;
  font-family: $font-regular, sans-serif;
  user-select: none;
}

.btn:hover {
  background-color: #1a37f7;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  color: $c-78 !important;
}

.dropdownMask,
.overlayMask {
  @include bg-mask(1, transparent);
}

.animatedCheckbox {
  position: relative;
  padding-right: 5px;
  height: 23px;
  width: 24px;

  .cbx {
    -webkit-perspective: 20px;
    perspective: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12px;
    border: 2px solid #e8e8eb;
    background: #e8e8eb;
    border-radius: 4px;
    transform: translate3d(0, 0, 0);
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .cbx:hover {
    border-color: #2dca73;
  }

  .flip {
    display: block;
    transition: all 0.4s ease;
    transform-style: preserve-3d;
    position: relative;
    width: 15px;
    height: 15px;
  }
  .cbxCheck {
    display: none;
  }
  .cbxCheck:checked + .cbx {
    border-color: #2dca73;
  }
  .cbxCheck:checked + .cbx .flip {
    transform: rotateY(180deg);
  }
  .front,
  .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 2px;
  }

  .front {
    background: #fff;
    z-index: 1;
  }
  .back {
    transform: rotateY(180deg);
    background: #2dca73;
    text-align: center;
    color: #fff;
    line-height: 15px;
    box-shadow: 0 0 0 1px #2dca73;
  }
  .back svg {
    margin-top: 1px;
    fill: none;
    width: 12px;
    height: 13px;
  }
  .back svg path {
    stroke: #fff;
    stroke-width: 2.5;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

.downArrowWhite,
.downArrowBlue,
.downArrowBlack {
  padding-left: 15px;
  width: 15%;
  display: inline-block;

  svg {
    width: 10px !important; // overide all higher level classes
    height: 10px !important; // overide all higher level classes
    fill: #fff;
  }
}

.downArrowBlack {
  svg {
    fill: #666666;
  }
}

.downArrowBlue {
  svg {
    fill: $blue-violet;
  }
}

.animated-border-cont {
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0px;

  .animated-border {
    transform: translate(0px, 0px) scaleX(0);
    left: 0;
    background-color: #5951ff;
    -webkit-transition: width 0.25s ease-in-out, -webkit-transform 0.25s ease;
    transition: width 0.25s ease-in-out, -webkit-transform 0.25s ease;
    -o-transition: width 0.25s ease-in-out, transform 0.25s ease;
    transition: width 0.25s ease-in-out, transform 0.25s ease;
    transition: width 0.25s ease-in-out, transform 0.25s ease,
      -webkit-transform 0.25s ease;
    left: 0;
    background-color: #5951ff;
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
  }
}

.moreOptionCont {
  position: absolute;
  width: 100%;
  right: 10px;
  min-width: 130px;
  font-family: inherit;
  background: #fff;
  z-index: 999;
  display: none;
  top: calc(-100% - 50px);
  border-radius: 10px;
  box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
  border: solid 1px #ebebeb;
  padding: 0px;
  transition: opacity 0.4s ease-in-out;

  &.first {
    transform: none;
    top: 36px;
  }

  .option {
    padding: 10px 22px;
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;

    .deleteIcon,
    .cloneIcon,
    .editIcon {
      width: 18px;
      height: 18px;
      margin-right: 10px;

      svg {
        width: 18px;
        fill: #666666;
        height: 18px;
      }
    }

    &:hover {
      background: #f2f2f2;
    }
  }
}

.optionEnabled {
  display: block;
}

.moreOption {
  &.optionEnabled {
    .moreOptionCont {
      display: block;
    }
    .moreOptionMask {
      display: block;
    }
  }
}

.moreOptionMask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: transparent;
  opacity: 0.75;
  display: none;
}

.downWrapper {
  position: relative;
  width: auto;
  height: auto;
  z-index: 5;

  .downCont {
    position: absolute;
    width: 100%;
    right: 0px;
    min-width: 190px;
    border-radius: 10px;
    border: 1px solid #ebebeb;
    font-family: inherit;
    background: #fff;
    z-index: 999999;
    top: 3px;
    color: #666666;
    box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);

    .downInnerWrapper {
      margin: 10px 0px;

      .sequenceCont,
      .folderCont {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        cursor: pointer;
        padding: 10px 22px;

        &:hover {
          background-color: #fafafb;
        }
      }

      .dropDownOption {
        display: inline-block;
      }

      .dropdownIcon {
        display: inline-block;
        margin-right: 10px;
        width: 15px;
        height: 15px;

        &.sequenceIcon {
          width: 12px;
          margin-right: 13px;
        }

        svg {
          width: inherit;
          height: inherit;
          fill: #666666;
        }
      }
    }

    .dropdownMask {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;
      background: transparent;
      opacity: 0.75;
    }
  }
}

.sideWrapper {
  left: 80%;
  position: absolute;
  width: 100%;
  right: 0;
  font-family: inherit;
  background: #fff;
  z-index: 999999;
  top: 13px;
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  align-items: center;
  flex-direction: row;
  border-radius: 22.5px;
  box-shadow: 0 0 10px 1px rgba(59, 59, 59, 0.07);
  border: solid 1px #dfdfdf;
  border: solid 1px #dfdfdf;
  height: 35px;
  display: none;
}

.icon.optionEnabled {
  .sideWrapper,
  .moreOptionMask {
    display: inline-flex;
  }
}

.newFolderLoader {
  @include flex-center();
  padding: 18px 20px;
  height: 55px;
  border-radius: 6px;
  border-bottom: 1px solid #eeeef0;
  background-color: #fff;

  .linear-background {
    border-radius: 6px;
  }
  .folderIcon {
    width: 18px;
    margin-right: 7px;
  }
  .folderName {
    width: calc(100% - 25px);
  }
}

.nameInputcont {
  padding: 10px 25px;
  background-color: #fff8de;
  border-bottom: 1px solid #fdf3d0;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 55px;
  position: relative;
  width: 100%;
  font-weight: normal;
  z-index: 5;

  .nameInputBox:focus ~ label,
  &.filled label {
    top: -20px !important;
    transition: all 0.2s linear;
  }

  .radioBtnCont {
    width: fit-content;
    @include flex-center();
    justify-content: space-between;
    padding: 0px 15px;
    .type {
      font-size: 13px;
      padding-left: 5px;
    }
    label {
      font-size: 12px;
      color: $c-66;
      &.active {
        color: $blue-violet;
      }
    }
  }

  .nameInputInnercont {
    width: 100%;
    display: grid;
    align-items: center;
    z-index: 2;
    grid-template-columns: minmax(240px, calc(100% - 560px)) 420px 140px;
    .inputWrapper {
      display: inline-block;
      position: relative;
      .nameInputBox {
        width: 100%;
        padding-right: 5%;
        display: inline-block;
        border: 0;
        background: transparent;
        color: #787878;
        font-size: 15px;
        font-weight: normal;
      }
    }

    .sequenceTypeCont {
      @include flex-center();
    }

    .cancelWrapper {
      display: inline-block;
      text-align: right;
      position: relative;
      z-index: 9;

      .cancelNameInput {
        font-size: 13px;
        cursor: pointer;
      }

      .sequenceActionBtn {
        display: inline;
        color: $c-9e;
        text-align: right;
        cursor: pointer;
        font-size: 13px;
        padding: 5px 10px;
        z-index: 999;

        &.save,
        &.save-btn {
          color: $white;
          margin-right: 10px;
          z-index: 1;
        }
        &.cancelBtn {
          z-index: 1;
          border-radius: 2px;
          &:hover {
            box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.07);
          }
        }
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    z-index: 1;
    width: inherit;
    left: 0;
    height: 55px;
    box-shadow: 0px 0px 0px 0px;
    color: rgba(199, 152, 157, 0.6);
    //color: #fff8de;
    animation: anim-shadow 0.3s forwards;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 100px 50px;
    opacity: 0;
  }
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.customSelectCont {
  background-color: #fff;
  margin: 0px 15px 25px 15px;
  color: #000;
  display: inline-block;
  width: 35%;
  position: relative;
  padding-top: 25px;

  .selectValues {
    // padding-bottom: 10px;
    cursor: pointer;
    border-bottom: 1px solid $bg-default;

    input#select {
      cursor: pointer;
      width: 85%;
      border-bottom: none;
    }

    .downArrowBlue {
      padding: 0;
      text-align: right;
    }
  }

  .selectLabel {
    color: #666666;
    font-family: $font-regular;
    font-size: 13px;
  }

  .select {
    line-height: 36px;
    -webkit-appearance: none;
    border: 0;
    font-size: 15px;
    border: 1px solid #ebebeb;
    background: #fff;
    list-style: none;
    position: absolute;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
    max-height: 225.5px;
    top: 45px;
    width: 100%;
    padding: 0px;
    color: #484848;
    z-index: 100;
    margin-bottom: 20px;
    border-radius: 10px;

    .downInnerWrapper {
      margin: 10px 0px;

      .select-option {
        cursor: pointer;
        padding: 10px 22px;
        line-height: normal;
        &.wrapIconValue {
          @include flex-direction(row);
          justify-content: space-between;
        }
        &:hover {
          background-color: #f6f6f6;
        }
      }
    }
  }
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #5b82e6;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.d-none {
  display: none;
}

.err-msg-text {
  display: block;
  font-size: 11px;
  color: red;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.animated {
  transform-origin: top center;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  animation-duration: 500ms;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
}

.animatedFast {
  transform-origin: top center;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  animation-duration: 400ms;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
}

.animatedDropDown {
  animation-name: slideInY;
}

.animatedDropDownReverse {
  animation-name: slideInYReverse;
}

.customzoomIn {
  animation-name: scaleIn;
}

.animatedPopUp {
  animation-name: slideOutY;
}

.toogleSequence {
  z-index: 4;

  .toogleButton {
    position: relative;
    @include wt-ht(30px, 16px);
    overflow: hidden;
    border-radius: 100px;

    .toggleCheckbox {
      position: relative;
      @include wt-ht(100%, 100%);
      padding: 0;
      margin: 0;
      opacity: 0;
      cursor: pointer;
      z-index: 3;

      &:checked + .knobs:before {
        left: 16px;
        background-color: $white;
      }

      &:checked ~ .layer {
        background-color: #3ecb6f;
      }
    }

    &.loading {
      .knobs::before {
        animation: spin 1s linear infinite;
        border: 1.5px solid white;
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-left: 1px;
        background-color: transparent;
      }

      .toggleCheckbox:checked + .knobs:before {
        background-color: transparent;
      }
    }
  }
}

.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.knobs::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  @include wt-ht(12px, 12px);
  color: $white;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 4px;
  z-index: 2;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

.layer {
  width: 100%;
  transition: 0.3s ease all;
  z-index: 1;
  background-color: #ccc;
  border-radius: 100px;
}
.dayLaterPreviousText {
  color: $c-66;
}
.durationContainer {
  width: 30%;
  display: inline-block;
  background: #fff;
  margin: 0px 15px 15px 15px;
  color: #000;
  position: relative;
  padding-top: 25px;
  @include flex-center;
  .inputLabel {
    color: #666666;
    font-size: 13px;
    font-family: $font-regular;
    position: absolute;
    top: 3px;
    left: 0px;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .npReplyInputCont {
    width: 25%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    float: left;
    margin-right: 20px;
    border-bottom: 1px solid #ddd;

    @include stagePlusMinusBtnStyle();

    #daysLater {
      line-height: 36px;
      -webkit-appearance: none;
      border: 0;
      font-size: 15px;
      width: 100%;
      color: #3953fe;
      max-width: 55px;
      text-align: center;
    }
  }

  ul.select {
    margin: 0;
    top: 40px;
  }

  .durationTxt {
    padding-right: 5px;
  }
}

.react-datepicker-popper {
  z-index: 11 !important;
}

.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.w-70 {
  width: 65%;
  margin-right: 5%;
}
.w-30 {
  width: 25%;
  margin-right: 5%;
}
.m-b-5 {
  margin-bottom: 5px;
}
.h-20p {
  height: 20px;
}
.h-12p {
  height: 12px;
}

.h-14p {
  height: 12px;
}

.b-r-20 {
  border-radius: 20px;
}
.f-14p {
  font-size: 14px;
}
.d-block {
  display: inline-block;
}
.d-flex {
  display: flex;
}

.disabled {
  // pointer-events: none;
  cursor: not-allowed !important;
  opacity: 0.5;
}

.p-rel {
  position: relative;
}

.grey-color {
  color: $c-53 !important;
}

.dark-violet-color {
  color: $blue-violet !important;
}
.green-color {
  color: $color-green !important;
}
.black-color {
  color: $black !important;
}
.orange-color {
  color: $color-orange !important;
}

.blue-color {
  color: $blue !important;
}
.dropDown {
  .d-flex {
    i {
      color: $c-66;
    }
  }
}

.errMsgText {
  display: flex;
  font-size: 11px;
  color: red;
  position: absolute;
  bottom: -20px;
  right: 0px;
  i {
    margin-right: 5px;
  }
}

.errMsgTexts {
  color: red;
  font-size: 10px;
  position: absolute;
  right: 18px;
  bottom: 10px;
}

.unset {
  overflow: unset !important;
}

.connectAppSection {
  @include center-element();
}

sup.required {
  color: red;
}

.blurBg {
  filter: blur(3px);
  -webkit-filter: blur(2px);
  pointer-events: none;
  user-select: none;
}

.pointerUserSelectNone {
  cursor: unset;
  pointer-events: none;
  user-select: none;
}

.supportMailLink {
  text-decoration: none;
}

.confettiWrapper {
  canvas {
    z-index: 99 !important;
  }
}

.circleMousePointerAnimation {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 3px solid $onBoardingGreen;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: scale(0.5);
  animation: mouseClick 0.9s cubic-bezier(0, 0, 0.71, 0.46) forwards;
  animation-iteration-count: infinite;
  .ringInner {
    @include wt-ht(15px, 15px);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: $onBoardingGreen;
  }
  .ringOuter {
    @include wt-ht(30px, 30px);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid $onBoardingGreen;
  }
}

.bor-rad-rt {
  @include bor-rad-right(10px, 10px);
}

/* -------------- Custom Tooltip ------------- */
.posRel {
  position: relative;
}

.customTooltip {
  user-select: none;
  @extend %relBlock;
  .sharpPointer {
    position: absolute;
    border-top-right-radius: 5px;
    padding: 8px;
    border-right: 1px solid $c-f5;
    border-top: 1px solid $c-f5;
    z-index: 4;
    top: -2px;
    right: 2px;
    transform: rotate(-45deg);
    background: white;
    &.alignRight {
      top: 2px;
      left: 4px;
    }
  }
  .tooltipBody {
    position: absolute;
    right: -30px;
    top: 6px;
    z-index: 3;
    width: 270px;
    height: auto;
    line-height: initial;
    border-radius: 8px;
    box-shadow: 0 0 12.1px 0.9px rgba(203, 203, 203, 0.26);
    background-color: white;
    &.crmToolTipBody {
      width: max-content;
    }
    .tooltipText {
      position: relative;
      line-height: initial;
      display: inline-block;
      font-size: 13px;
      color: $c-66;
      line-height: 1.4;
      padding: 15px;
      a {
        color: $blue-violet;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &.alignRight {
      top: 10px;
      left: -15px;
      right: unset;
      width: unset;
      min-width: fit-content;
      max-width: 220px;
      .tooltipText {
        padding: 10px;
      }
    }
    .crmIconList {
      @include flex-center;
      padding: 10px;
      flex-wrap: wrap;
      :not(:last-child) {
        margin-right: 8px;
      }
    }
    .redirectLink {
      border: 0;
      background-color: transparent;
      color: $blue-violet;
      letter-spacing: 1;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@keyframes move-up {
  from {
    top: -5%;
  }
  to {
    top: 0;
  }
}

.deleteActionIcon {
  svg {
    @include wt-ht(15px, 15px);
  }
}

/* -------- Attachment -------- */
.ql-formats:nth-child(2) {
  position: relative;
  #attachmentUploadFileDom {
    position: relative;
    padding: 0;
    .attachmentUploadFile {
      position: relative;
      opacity: 0;
      z-index: 1;
      width: 18px;
      height: 16px;
      cursor: pointer !important;
      &:hover + .attachmentIcon {
        background: $c-ec;
        color: $c-22;
        border-radius: 2px;
        transition: all 0.3s;
        cursor: pointer;
      }
    }
    .attachmentIcon {
      position: absolute;
      z-index: 0;
      left: 1px;
      top: 2px;
      font-size: 14px;
      padding: 2px;
      cursor: pointer;
    }
  }
}

/* ------------- File Attachment Loader ---------- */
.fileUploadingLoader {
  position: absolute;
  display: block;
  bottom: 42px;
  z-index: 1;
  padding: 12px 10px;
  width: calc(100% - 2px);
  margin-left: 1px;
  margin-right: 1px;
  background: $c-fd;
  border-top: 1px solid $c-ee;
  .fileUploading {
    @include flex-center;
    justify-content: center;
    .uplaodText {
      color: $c-66;
      margin-right: 2px;
      font-size: 14px;
    }
    .uploadCount {
      color: $c-78;
      margin-right: 10px;
      font-size: 12px;
    }
    .progressBarSlide {
      @include wt-ht(160px, 12px);
      padding: 2px;
    }
  }
}

/* -------- Editor File Attachment --------- */
.attFileList {
  position: absolute;
  z-index: 1;
  width: calc(100% - 2px);
  margin-left: 1px;
  margin-right: 1px;
  bottom: 42px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
  background: white;
  row-gap: 5px;
  column-gap: 5px;
  max-height: 76px;
  background: $c-fc;
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.05);
  overflow: auto;
  border-top: 1px solid $c-ec;
  .attFileCard {
    position: relative;
    @include flex-center;
    flex: 1 0 230px;
    max-width: 230px;
    border: 1px solid $c-e7;
    border-radius: 8px;
    padding-left: 10px;
    height: 30px;
    background: white;

    .attName,
    .fileExtName {
      color: #455dfb;
      font-size: 13px;
    }
    .attName {
      @include ellipsis();
      min-width: auto;
      max-width: 106px;
      margin-right: 5px;
    }
    .fileExtName {
      margin-right: 3px;
    }
    .attSize {
      font-size: 12px;
      color: $c-5b;
      margin-top: 1px;
      margin-right: 5px;
    }
    .removeAttFile {
      position: absolute;
      right: 5px;
      top: 4px;
      padding: 3px;
      cursor: pointer;
      border-radius: 35px;
      font-size: 14px;
      color: $c-5b;
      margin-left: 10px;
      transition: all 0.3s;
      &:hover {
        color: $c-22;
        transition: all 0.3s;
        background: $c-f5;
      }
    }
  }
}

/* -------- Progress Bar with Line ------ */
.progressBarSlide {
  height: 20px;
  position: relative;
  background: #2196f3;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  padding: 10px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  span {
    width: 100%;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
      );
      z-index: 1;
      background-size: 50px 50px;
      animation: move 2s linear infinite;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      overflow: hidden;
    }
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

/* ---------- Sequence Attachmnet -------- */
#previewEditorSection {
  .attFileList {
    .attFileCard {
      flex: 1 0 235px;
      max-width: 235px;
      border-radius: 4px;
      padding-left: 5px;
      .attName {
        max-width: 114px;
      }
    }
  }
}

// Trial Ending Date //

.trialEndingDate {
  font-size: 13px;
  padding: 0 10px;
  line-height: 1.5;
  @include flex-center();
  height: 36px;
  white-space: nowrap;
  margin: 0 auto;
  justify-content: flex-end;
  transition: all 0.3s ease;
  white-space: nowrap;
  &.upgradeBtnOverlay {
    background: $c-f5;
    border-radius: 5px;
    cursor: pointer;
    padding: 0 10px;
    transition: all 0.3s ease;
  }
  .clockIcon {
    color: $hb-text;
    font-size: 13px;
    padding: 0px 5px;
  }

  .endingDate {
    color: $hb-text;
  }
}

.ql-editor img {
  user-select: none;
}

.manualEmailEditorPreviewWrap,
.automaticEmailEditorPreviewWrap {
  display: flex;
  background: white;
  .editorInputWrap {
    @include flex-width(60%);
    .title {
      padding-bottom: 0px !important;
    }
    .threadReplyToogle {
      padding: 5px 15px !important;
      margin-left: 15px !important;
    }
    .ql-formats {
      margin-right: 2px !important;
      &:last-child {
        right: 0;
        position: absolute;
      }
      button {
        width: 20px;
      }
      svg {
        @include wt-ht(14px, 14px);
      }
    }
    .ql-snow .ql-picker.ql-size {
      width: 78px !important;
    }
    .ql-snow .ql-picker.ql-font {
      width: 102px;
    }
    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
      top: 60%;
    }
    #sequenceAutomaticQuilEditor {
      .varibaleContainer {
        width: unset;
      }
      .ql-align.ql-picker.ql-icon-picker {
        display: none !important;
      }
    }
  }
  .previewContentWrap {
    @include flex-width(40%);
    padding-top: 25px;
    background: white;
    .preview-templete {
      width: calc(100% - 25px);
      h6.preview-title {
        padding-bottom: 10px;
      }
      .preview-body {
        height: calc(424px - 30px);
        text-align: initial;
      }
    }
  }
}

.htYellowPT {
  background: #fff5d9;
  border-radius: 2px;
  line-height: 18px;
  padding: 1px 2px;
  color: black;
}
.unresolvedTokenHighLight {
  background: #f7cbcd;
  border-radius: 2px;
  line-height: 18px;
  padding: 1px 2px;
  color: black;
}

#sendTestEmailAutoEmailBtn,
#sendTestEmailManualEmailBtn {
  margin: 0 10px auto 0;
  width: 150px;
  height: 36px;
  @extend %primaryOutlineBtn;
  &.loading {
    @include wt-ht(36px !important, 36px !important);
    border: none !important;
    outline: none !important;
    background: $blue-violet !important;
    border-radius: 35px !important;
    .load-text {
      @include wt-ht(26px !important, 26px !important);
    }
  }
  &.disableSentEmailBtn {
    background: $c-f5 !important;
    border: 1px solid $c-87 !important;
    color: $c-87 !important;
    user-select: none !important;
    pointer-events: none !important;
  }
}

.showDuration {
  flex-wrap: wrap;
  .inputCont {
    width: 100% !important;
    padding-right: 0 !important;
    margin: 0 0 5px 10px !important;
    padding-top: 20px !important;
  }
  .durationContainer {
    width: 50%;
    margin: 0 15px 0px 10px !important;
    @include flex-center;
  }
  .templateCont {
    width: calc(50% - 25px) !important;
    margin: 0 !important;
  }
}

.previewModeHtAdjust {
  height: calc(475px - 30px) !important;
}

.sortDescending,
.sortAscending {
  color: $c-ab;
  margin-left: 5px;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  border-radius: 35px;
  transition: all 0.3s;
  opacity: 0.7;
  &:hover {
    color: black;
    background: $c-f5;
    transition: all 0.3s;
  }
}

.activeSort {
  opacity: 1;
  color: $c-55;
}

.sortAscending {
  transform: rotate(180deg);
}
.tagSelectedSection {
  position: relative;
}
.wrapExportFilterIcon {
  @include flex-center();
  justify-content: flex-end;
}

.disableUserPointerEvents {
  user-select: none;
  pointer-events: none;
}

.contactOwnedFilterSection {
  position: relative;
  z-index: 3;
  margin-right: 15px;
  min-width: 185px;
  @include flex-center();
  height: 36px;

  .filterTagBtn {
    height: inherit;
    position: relative;
    background: $white;
    color: $c-66;
    border: 1px solid $tb-bor-btm;
    @include flex-direction(row);
    padding: 0 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      box-shadow: 2px 1px 3px 0px rgba(0, 0, 0, 0.08);
    }
    .contactIcon {
      position: relative;
      padding-right: 5px;
      svg {
        @include wt-ht(14px, 14px);
        fill: $c-66;
      }
    }
    .titleText {
      position: relative;
      padding-right: 5px;
      width: fit-content;
      font-family: Graphik-Regular, sans-serif;
      font-size: 14px;
      white-space: nowrap;
    }
    .tagSelected {
      position: relative;
      @include flex-direction(row);
      width: fit-content;
      max-width: 50px;
      font-family: Graphik-Regular, sans-serif;

      ul {
        @include list-style-zero();
        @include flex-direction(row);
        li {
          margin-left: 4px;
          font-size: 12px;
          margin-top: 3px;
          color: $blue-violet;
          text-transform: uppercase;
          &:not(:last-child):after {
            content: ",";
            color: $c-66;
          }
        }
      }
    }
    .countPlus {
      position: relative;
      border-radius: 50%;
      font-size: 11px;
      @include wt-ht(20px, 20px);
      color: $color-green;
      background: $white;
      font-weight: bold;
      cursor: pointer;
      @include flex-center();
      justify-content: center;
      border-radius: 50%;
      &:hover {
        background: $c-f5;
      }
    }
    .arrowIcon {
      position: relative;
      padding-left: 10px;
      @include flex-center();

      svg {
        width: 12px;
        height: 12px;
        fill: $c-66;
      }
    }
  }
  @extend %userListDropdown;
}

.overlayMask {
  @include bg-mask(4, transparent);
}

/* -------- Apex Chart ---------- */
.apexcharts-legend-marker {
  border-radius: 50% !important;
}

/* -------- End Apex Chart ---------- */

.disabledBtn {
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}

.datePickerArrow {
  position: absolute;
  right: 10px;
  top: 32px;
  svg {
    fill: $c-66;
    @include wt-ht(12px, 12px);
  }
}

.align-items-center {
  align-items: center;
}

.cursor-unset {
  cursor: unset;
}

// Event Promotion Banner
.eventRegistrationBanner {
  gap: 10px;
  @media (max-width: 996px) {
    display: none !important;
  }
  // > * {
  //   flex: 0 0 150px; // Set fixed width for all items
  //   max-width: 200px; // Optional: add a max width
  // }
  .decBanner {
    position: absolute;
    right: 0px;
    width: 100%;
    height: 100%;
    @media (max-width: 996px) {
      display: none !important;
    }
  }
  position: relative;
  // padding: 0 40px 0 15px;
  padding: 0 20px;
  height: auto;
  width: 100%;
  @include flex-align-center;
  z-index: 101;
  color: white;
  justify-content: center;
  object-fit: cover;
  .eventTextParent {
    padding-right: 0;
    max-width: 600px;
    .secondEventText {
      font-size: 14px !important;
    }
    .eventText,
    .secondEventText {
      position: relative;
      color: white;
      font-size: 20px;
      // margin-right: 20px;
      line-height: normal;
      letter-spacing: 0.3px;
      font-weight: 400;
      span {
        color: #ff6057;
        font-weight: 700;
      }
    }
  }
  .OfferEndingTime {
    @media (max-width: 996px) {
      display: none !important;
    }
    // width: 100px;
    .finalTimeWrapper {
      width: 100% !important;
      scale: 0.75;
      margin-left: -20px;
      margin-top: 15px;
      .countdown {
        width: 100%;
        display: flex;
        gap: 10px;
        scale: 0.9;
      }
      .card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #ff6057;
        padding: 20px 10px;
        border-radius: 10px;
        text-align: center;
        width: 45px;
        height: 40px;
        position: relative;
        .cardHeading {
          margin: 0;
          font-size: 18px;
          color: white;
        }
        .label {
          position: absolute;
          bottom: -25px;
          left: 50%;
          transform: translateX(-50%);
          margin: 0;
          font-size: 1em;
          color: white;
        }
      }
    }
  }

  .registerNow {
    // flex: 2;
    flex: 0 0 120px;
    // transform: translateX(-20px);
    position: relative;
    @include flex-align-center;
    color: #3953fb;
    background: #ffffff;
    letter-spacing: 0.3px;
    border-radius: 2px;
    height: 30px;
    border: 1px solid white;
    font-size: 14px;
    line-height: normal;
    min-width: 110px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    transition: box-shadow 0.3s;
    &:hover {
      box-shadow: 0 0 10px rgba(255, 102, 102, 0.7),
        0 0 10px rgba(255, 102, 102, 0.7), 0 0 10px rgba(255, 102, 102, 0.7);
    }
  }
  .closeBanner {
    position: absolute;
    top: 2px;
    right: 10px;
    font-size: 22px;
    cursor: pointer;
    padding: 2px;
    border-radius: 35px;
    transition: all 0.5s ease;
    &:hover {
      background: #b3aeae;
      // border: 1px solid #000000;
    }
  }
}

/* ---- BADGE ---- */
.betaBadge {
  position: relative;
  margin-left: 10px;
  font-size: 9px;
  font-weight: 600;
  @include flex-center;
  justify-content: center;
  @include wt-ht(auto, 16px);
  padding: 0 6px;
  background: #1a37f7;
  color: white;
  border-radius: 35px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
}

.missingTokenPromptList {
  list-style: none;
  padding: 0;
  text-align: initial;
  margin: 15px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  counter-reset: decimel-counter;
  flex-wrap: wrap;
  counter-reset: decimel-counter;
  li {
    font-weight: 500;
    counter-increment: decimel-counter;
    margin-right: 10px;
    &::before {
      content: counter(decimel-counter) ". ";
    }
  }
}
.bulkAddTagsPopup {
  .commonPopupContainer {
    width: 560px !important;
    .commonPopupHeader {
      margin-bottom: 6px;
    }
  }
  .flexRow {
    @include flex-center();
  }
  .contactsCount {
    font-size: 12px;
    color: $blue-violet;
    margin-left: 16px;
  }
  .previewTagWrapper {
    @include previewTags();
    border: 1px solid $c-dd;
    padding: 7px;
    border-radius: 6px;
    max-height: 90px;
    overflow: hidden;
    transition: all 0.3s;
    margin-top: 20px;
    .previewTagsCont {
      padding: 0px 10px 0px;
      max-height: 75px;
      margin: 0px;

      .selectedTag {
        color: #3b3b3b;
        padding: 2px 8px;
        .selecteTagValue {
          font-size: 13px;
        }
      }
    }
  }
  .newOption {
    position: relative;
    padding: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    font-size: 14px;

    .newOptionValue {
      padding-left: 5px;
      color: $blue-violet;
    }
  }

  .inputContainer {
    border-bottom: 1px solid $c-ee;
    cursor: text;
    position: relative;
    z-index: 100;
    .tagTitle {
      position: relative;
      color: #8d8d8d;
      font-size: 13px;
    }
    &:focus-within {
      border-bottom: 1px solid $blue-violet;
    }
  }
  .searchInputBoxWrapper {
    display: inline-flex;
    align-items: center;
  }
  .inputFilterSearch {
    border: 0;
    width: 100px;
    height: 28px;
  }
  .searchCloseIcon {
    font-size: 16px;
    cursor: pointer;
  }
  .contactTagList {
    height: 45px;
    display: inline;
  }
  .searchFilterWrapper {
    display: inline-block;
    position: relative;
    width: 80px;
    margin-bottom: 8px;
    .checkBoxSection {
      padding: 4px 0;
    }
    .checkBoxName {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 140px;
      white-space: nowrap;
    }
  }
  .tagList {
    position: absolute;
    border: 1px solid $c-ee;
    border-radius: 8px;
    width: max-content;
    z-index: 101;
    background-color: $white;
    top: 37px;
    padding: 12px;
    max-height: 250px;
    overflow-y: auto;
    box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.08);
  }
  .selectedTagWrapper {
    @include flex-center();
    align-items: start;
    flex-wrap: wrap;
    overflow: auto;
    margin: 20px auto;
    max-height: 66px;
    gap: 5px;
  }
  .updateButton {
    @include flex-center();
    justify-content: center;
    width: 200px;
    background-color: $blue-violet;
    color: $white;
    border-radius: 6px;
    margin: 0 auto;
    padding: 8px 0;
    cursor: pointer;
  }
  .descriptionMsg {
    color: $c-66;
    font-size: 13px;
    margin-bottom: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eceff1;
    color: #8d8d8d;
    .contactName {
      color: $blue-violet;
    }
  }
  .searchInputBoxWrapper,
  .searchFilterWrapper,
  .searchTextBox {
    width: 100%;
  }
  .searchInputBtn .searchTextBox input {
    background-color: transparent;
    width: 100%;
    font-size: 15px;
    padding-bottom: 0px;
    &::placeholder {
      font-weight: bold;
      text-align: center;
      color: $c-66;
    }
  }
}

.themeBlue {
  color: $blue-violet;
}
/* ----------- DRAG & DROP ----------- */
.dropHereText {
  font-size: 16px !important;
  color: $blue-violet;
  &.template {
    padding-right: 30px;
  }
}

.dragHolder {
  display: none !important;
  position: absolute;
  color: $c-cc;
  font-size: 18px !important;
  transition: all 0.3s ease;
  &.sequence {
    left: 2px;
  }
  &.template {
    left: 5px;
  }
}

// Email Content Loader
.cursorPointer {
  cursor: pointer;
}
.emailContentWrapper {
  @include flex-center();
  justify-content: center;
  padding: 20px;
  margin-top: 10px;
  overflow: auto;
  border: 1px solid $c-dd;
  border-radius: 5px;
  min-height: 240px;
  max-height: 320px;
  transition: all 0.3s ease;
  color: $c-3b;
  pointer-events: none;
  font-size: 14px;
  &.composeEditorLoader {
    min-height: 360px;
    max-height: 440px;
  }
  .emailContentSpinner {
    content: "";
    box-sizing: border-box;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #ccc;
    border-top-color: $blue-violet;
    z-index: 1;
    animation: spinner 0.6s linear infinite;
  }
}

// PROGRESS SPINNER
.progressingLoader {
  @include flex-align-center;
  padding: 20px;
  margin-top: 10px;
  overflow: auto;
  min-height: 240px;
  max-height: 320px;
  transition: all 0.3s ease;
  color: $c-3b;
  pointer-events: none;
  .loadingSpinner {
    content: "";
    box-sizing: border-box;
    position: absolute;
    @include wt-ht(50px, 50px);
    border-radius: 50%;
    border: 3px solid $c-cc;
    border-top-color: $blue-violet;
    z-index: 1;
    animation: spinner 0.6s linear infinite;
  }
}

.eventBanner {
  position: sticky;
  top: 0;
  width: 100%;
}
// Event Banner Height Handling Important
.mainContent {
  position: relative;
  display: block;
  width: 100%;
  height: 100vh;
  // Event Banner
  &.showEventBanner {
    height: calc(100vh - 75px);
    .sgHeader {
      top: 75px;
      z-index: 9;
    }
    .sideBar {
      top: 125px;
    }
  }
}

.loaderThreeDotWrapper {
  display: flex;
  .loaderText {
    animation: fadeIn 0.5s linear forwards;
    display: flex;
  }
  .loaderDot {
    animation: appear1 1s 1s steps(1, start) infinite;
    display: inline-block;
  }
  .loaderDot {
    &:nth-child(2) {
      animation: appear2 1s 1s steps(1, start) infinite;
    }
    &:nth-child(3) {
      animation: appear3 1s 1s steps(1, start) infinite;
    }
  }

  @keyframes fadeIn {
    from,
    67% {
      opacity: 0;
    }
    83.3%,
    to {
      opacity: 1;
    }
  }
  @keyframes appear1 {
    from {
      visibility: hidden;
    }
    33%,
    to {
      visibility: visible;
    }
  }
  @keyframes appear2 {
    from,
    33% {
      visibility: hidden;
    }
    67%,
    to {
      visibility: visible;
    }
  }
  @keyframes appear3 {
    from,
    67% {
      visibility: hidden;
    }
    to {
      visibility: visible;
    }
  }
}
.pauseResume {
  .sharpPointer {
    right: 5px !important;
  }
  .tooltipBody {
    right: -17px !important;
    width: 132px !important;
  }
}
.sequenceWrapper .tooltipBody {
  width: 132px !important;
}
.activateSeqToggle,
.activateSequenceWrapper,
.sequenceWrapper {
  .customTooltip {
    .sharpPointer {
      border-right: 1px solid $c-33;
      border-top: 1px solid $c-33;
      background-color: #393a3d;
      top: 8px;
      right: 5px;
    }
    .tooltipBody {
      background-color: #393a3d;
      border-radius: 5px;
      width: 132px;
      max-width: 140px;
      top: 17px;
      right: -17px;
    }
    .tooltipText {
      color: $white;
      padding: 8px;
    }
  }
}

.activateSequenceWrapper {
  @include flex-center;
  justify-content: space-between;
  padding: 10px 16px;
  border-radius: 6px;
  font-size: 13px;
  height: 48px;
  position: relative;

  &.noRightPadding {
    padding-right: 0;
    .rightStatus {
      padding: 16px;
      height: 36px;
      border-radius: 34px;
      margin-right: 8px;
    }
  }

  .toogleSequence .toogleButton .toggleCheckbox:checked ~ .layer {
    background-color: #3ecb6f !important;
  }
  &.activeStatus {
    border: 1px solid #28ad56;
    background-color: #28ad5629;
    color: $c-3b !important;
  }
  &.inactiveStatus {
    border: 1px solid #ff0000;
    background-color: #ff000042;
    color: $c-3b !important;
  }
  &.activated .customTooltip {
    .sharpPointer {
      border-right: 1px solid $c-33;
      border-top: 1px solid $c-33;
      background-color: #393a3d;
      top: 8px;
      right: 10px;
    }
    .tooltipBody {
      background-color: #393a3d;
      border-radius: 5px;
      width: 250px;
      top: 17px;
      right: 0px;
      max-width: unset;
    }
    .tooltipText {
      color: $white;
      padding: 8px;
    }
  }
  .leftToggleContent {
    @include flex-center;
    .freemiumIcon .thunderSvg {
      right: -25px;
    }
    .upgradeTooltipSection {
      .pointer {
        left: 36px;
      }
    }
  }
  .rightStatus {
    font-size: 12px;
    @include flex-center;
    justify-content: center;
    position: relative;
    @extend %toggleBtn;
    &.firstContactAdded {
      background: #f8b8bc;
      z-index: 15;
    }
    .statusText {
      margin-right: 8px;
    }
    .toogleButton {
      height: 20px !important;
      .knobs {
        &::before {
          background-color: $white !important;
          @include wt-ht(12px !important, 12px !important);
          top: 2px !important;
        }
      }
      &.loading .knobs::before {
        animation: spin 1s linear infinite !important;
        border: 1.5px solid white !important;
        border-bottom-color: transparent !important;
        border-left-color: transparent !important;
        border-left: 1px !important;
        background-color: transparent !important;
      }
    }
    .toggleCheckbox {
      cursor: pointer;
    }
    .toggleText {
      margin-left: 10px;
    }
    &.activeStatus {
      color: #28ad56;
      .layer {
        border: 1px solid #28ad56 !important;
        background-color: #28ad56 !important;
      }
    }
    &.inactiveStatus {
      color: #ff0000;
      .layer {
        border: 1px solid #ff0000 !important;
        background-color: #ff0000 !important;
      }
    }
    .customTooltip {
      .firstContactAddedTooltip.sharpPointer {
        border-right: 1px solid #ff00005c;
        border-top: 1px solid #ff00005c;
        background-color: $white;
        top: 24px;
        border-top-right-radius: 0px;
        padding: 6px;
      }
      .firstContactAddedTooltip {
        &.tooltipBody {
          background-color: $white;
          color: $c-3b;
          width: 190px;
          max-width: unset;
          border: 1px solid #ff00005c;
          top: 30px;
          right: -16px;
          text-align: center;
        }
        .tooltipText {
          color: $c-3b;
          font-size: 13px;
        }
      }
    }
  }
}

.activateSequenceLoader {
  background-color: $white;
  .toggleBtnSection {
    &.linear-background {
      @include wt-ht(34px, 16px);
    }
  }
  .flexWrapper {
    @include flex-center;
    width: 100%;
  }
  .subject {
    @include wt-ht(50%, 16px);
    margin-left: 8px;
  }
  .rightStatus {
    .statusText {
      @include wt-ht(70px, 16px);
    }
  }
}

%notesInput {
  @extend %relBlock;
  margin-bottom: 1rem;
  .notes-section {
    height: unset;
    label {
      @extend %label;
    }
    .txt-cont {
      position: relative;
      border-radius: 5px;
      box-shadow: inset 0 1px 2px 0 rgba(111, 111, 111, 0.16);
      border: solid 1px $c-dd;
      background-color: white;
      height: unset;
    }
  }
  .insertVariableBtn {
    position: relative;
    cursor: pointer;
    margin-top: 3px;
    padding: 3px 5px 1px;
    background: $c-f5;
    font-weight: 500;
    font-size: 12px;
    color: $c-66;
    border: 1px solid $c-dd;
    border-radius: 2px;
    width: fit-content;
    margin: 5px 0 0 auto;
    .varibaleContainer {
      bottom: 26px;
    }
  }
}

%failedOperationStyle {
  position: relative;
  .count {
    position: relative;
    cursor: pointer;
    display: block;
    padding: 2px;
    width: fit-content;
    margin: 0 auto;
    border-bottom: 1.5px dashed $c-66;
  }
  .cardTooltip {
    position: fixed;
    right: 0;
    min-width: 200px;
    max-width: 280px;
    background: white;
    border-radius: 6px;
    z-index: 2;
    box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
    border: solid 1px $c-e1;
    .cardTitle {
      position: relative;
      padding: 3px 8px 0;
      color: $c-55;
      display: flex;
      font-weight: 500;
      height: 36px;
      align-items: center;
      border-bottom: solid 1px $c-f5;
    }
    .cardBody {
      position: relative;
      padding: 0 8px;
      .detailedInfoCount {
        position: relative;
        display: flex;
        margin: 5px 0;
        .listIcon {
          font-size: 12px;
          margin-right: 6px;
          color: red;
        }
        .text {
          color: $c-78;
          font-size: 12px;
        }
      }
    }
  }
}

%notesInput {
  @extend %relBlock;
  margin-bottom: 1rem;
  .notes-section {
    height: unset;
    label {
      @extend %label;
    }
    .txt-cont {
      position: relative;
      border-radius: 5px;
      box-shadow: inset 0 1px 2px 0 rgba(111, 111, 111, 0.16);
      border: solid 1px $c-dd;
      background-color: white;
      height: unset;
    }
  }
  .insertVariableBtn {
    position: relative;
    cursor: pointer;
    margin-top: 3px;
    padding: 3px 5px 1px;
    background: $c-f5;
    font-weight: 500;
    font-size: 12px;
    color: $c-66;
    border: 1px solid $c-dd;
    border-radius: 2px;
    width: fit-content;
    margin: 5px 0 0 auto;
    .varibaleContainer {
      bottom: 26px;
    }
  }
}

%failedOperationStyle {
  position: relative;
  .count {
    position: relative;
    cursor: pointer;
    display: block;
    padding: 2px;
    width: fit-content;
    margin: 0 auto;
    border-bottom: 1.5px dashed $c-66;
  }
  .cardTooltip {
    position: fixed;
    right: 0;
    min-width: 200px;
    max-width: 280px;
    background: white;
    border-radius: 6px;
    z-index: 2;
    box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
    border: solid 1px $c-e1;
    .cardTitle {
      position: relative;
      padding: 3px 8px 0;
      color: $c-55;
      display: flex;
      font-weight: 500;
      height: 36px;
      align-items: center;
      border-bottom: solid 1px $c-f5;
    }
    .cardBody {
      position: relative;
      padding: 0 8px;
      .detailedInfoCount {
        position: relative;
        display: flex;
        margin: 5px 0;
        .listIcon {
          font-size: 12px;
          margin-right: 6px;
          color: red;
        }
        .text {
          color: $c-78;
          font-size: 12px;
        }
      }
    }
  }
}

.infoPromptContent {
  position: relative;
  margin-top: 20px;
  background: #3953fb1a;
  border-radius: 5px;
  color: $c-44;
  line-height: initial;
  padding: 0.75rem;
  line-height: 1.25;
  .linkBtn {
    cursor: pointer;
    color: $blue-violet;
    text-decoration: underline;
  }
}
.showRevealedContacts {
  text-decoration: underline;
  cursor: pointer;
  color: $white;
}
