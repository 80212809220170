.tasksOperation {
  @extend %relBlock;
  @include wt-ht(100%, 100%);
  border-radius: 10px;
  .titleHeader {
    @extend %relBlock;
    padding: 20px 15px 0px;
    h3 {
      font-size: 16px;
      color: $c-66;
      font-family: $font-regular;
      font-weight: 400;
      padding-bottom: 15px;
    }
  }
  .tableList {
    @extend %relBlock;
    height: calc(100% - 103px);
    width: 100%;
    border-top: 1px solid $b-c-1;
    .tableRecordHeightAlign {
      height: calc(100% - 48px);
      width: 100%;
      position: absolute;
      overflow: auto;
    }
    .tableHeader,
    .tableBody,
    .tableBodyLoader {
      display: grid;
      grid-template-columns: repeat(6, 15%) 10% 1fr;
      align-items: center;
      position: relative;
      font-size: 14px;
      @include wt-ht(100%, 48px);
      padding: 0 4px;
      color: $c-66;
      border-bottom: 1px solid $b-c-1;
      .titleRow {
        @extend %relBlock;
        width: 100%;
        @include ellipsis();
        padding: 0 10px;
      }
    }
    .tableHeader {
      height: 48px;
      position: sticky;
      background: $c-f9;
      top: 0;
      z-index: 1;
      width: calc(100% - 0.4em);
    }
    .colLoader {
      @extend %relBlock;
      width: 70%;
      margin: 0 10px;
    }
    .type {
      width: 80%;
      text-transform: capitalize;
    }
    .status {
      width: 80%;
    }
    .totalCount {
      text-align: center;
    }
    .successCount {
      text-align: center;
    }
    .failedCount {
      text-align: center;
      position: relative;
      @include flex-center;
    }

    .failedCount,
    .successCount,
    .totalCount {
      margin: 0 auto;
      width: 30%;
    }

    .ownerName,
    .failedCount,
    .successCount,
    .totalCount {
      .colLoader {
        margin: 0 auto;
      }
    }
    .ownerName {
      text-align: center;
      .contactOwner {
        @extend %contactOwner;
        margin: 0 auto;
        .customTooltip {
          .sharpPointer {
            left: unset;
            right: 22px;
            top: -7px;
            border-radius: unset;
            padding: 5px;
          }
          .tooltipBody {
            top: -18px;
            right: 27px;
            left: unset;
          }
        }
      }
      .colLoader {
        margin: 0 auto;
        @include wt-ht(30px, 30px);
        border-radius: 35px;
      }
    }
    .noDataFound {
      position: relative;
      @include flex-center;
      justify-content: center;
      text-align: center;
      padding: 40px 0 20px;
      .text {
        padding-left: 7px;
      }
    }
  }
  .pagination-section {
    position: absolute;
  }
}

.tasksFailedOperation {
  @extend %failedOperationStyle;
}
