.logoutPricingPage {
  font-family: $font-regular;
  @extend %relBlock;
  letter-spacing: 0.3px;
  line-height: 1.5;
  @include wt-ht(100%, 100%);
  overflow: auto;
  /* ------------- PRICING BODY ------------- */
  .currentPlanBtn {
    cursor: pointer;
  }
  @media (min-width: 1600px) {
    .container-fluid {
      max-width: 1366px;
    }
    .logoutPricing {
      max-width: 1366px;
      margin: 0 auto;
    }
  }
}

.website-header,
.website-footer {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin: 0;
    }
  }
  a,
  a .text {
    text-decoration: none;
    font-size: 14px;
    @media (min-width: 1200px) {
      font-size: 16px;
    }
    @media (min-width: 1600px) {
      font-size: 18px;
    }
  }
}
.active-nav {
  color: #0040a7 !important;
  font-weight: 600;
  user-select: none;
  pointer-events: none;
}

.btn-new {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: fit-content;
  font-weight: 600;
  cursor: pointer;
  &.btn-orange {
    color: white;
    background: #ff6057;
  }
  &.btn-arrow::after,
  &.btn-arrow-large::after {
    font-family: "Material Icons";
    content: "\e5e1";
    color: white;
    font-weight: 600;
    transition: all ease 0.3s;
  }

  &.btn-link-rounded {
    &.btn-arrow::after {
      color: #ff6057;
    }

    &.btn-arrow:hover::after {
      color: white;
    }
  }
  &.btn-rounded {
    border-radius: 26px;
    @media (min-width: 992px) {
      border-radius: 36px;
    }
  }

  &.btn-text {
    padding: 10px 1rem;
    font-size: 14px;
    @media (min-width: 768px) {
      padding: 10px 1.25rem;
      font-size: 15px;
    }
    @media (min-width: 992px) {
      padding: 12px 1.35rem;
      font-size: 16px;
    }
    @media (min-width: 1600px) {
      font-size: 18px;
      padding: 14px 1.75rem;
    }
  }

  &.btn-arrow::after {
    font-size: 14px;
    margin-left: 0.75rem;
    @media (min-width: 768px) {
      font-size: 15px;
      margin-left: 1rem;
    }
    @media (min-width: 1600px) {
      font-size: 18px;
      margin-left: 2rem;
    }
  }
}
.header-logo {
  flex: center;
  align-items: center;
  margin-right: 20px;
  @media (min-width: 992px) {
    margin-right: 60px;
  }
  a {
    position: relative;
    svg {
      width: 180px;
      path {
        fill: #3953fb;
        @media (max-width: 991px) {
          fill: white;
        }
      }
      @media (max-width: 991px) {
        width: 130px;
      }
    }
  }
}

/* ------------- HEADER ------------- */
header.website-header {
  position: sticky;
  display: block;
  line-height: 1;
  top: 0;
  z-index: 10;
  width: 100%;
  background: white;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  height: 64px;
  @media (min-width: 768px) {
    height: 74px;
  }
  .container {
    height: 100%;
    padding: 0;
  }
  .wrapHeaderLogo {
    @include flex-center;
    gap: 6px;
    max-width: 200px;
    svg {
      height: 34px;
      width: auto !important;
    }
  }
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .heaader-navbar {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      @media (max-width: 767px) {
        display: none;
      }
      &.flex-end {
        justify-content: flex-end;
      }
      ul.navbar-left,
      ul.navbar-right {
        position: inherit;
        display: flex;
        align-items: center;
        li {
          position: inherit;
          .loginText {
            font-size: 14px;
            color: #878787;
            letter-spacing: 0.3px;
            margin-right: 5px;
            @media (min-width: 991px) {
              font-size: 16px;
            }
          }
          line-height: 1.3 !important;
          a {
            line-height: 1.3 !important;
            &:not(.btn-new) {
              padding: 14px 22px;
              @media (max-width: 991px) {
                padding: 10px 18px;
              }
            }
            @media (max-width: 991px) {
              padding: 10px 18px;
            }
            &.btn-link {
              color: #333333;
              border: 1px solid transparent;
              border-radius: 26px;
              transition: all ease 0.3s;
              &:hover {
                background: #f5f5f5;
                border: 1px solid #00173c;
                color: #000;
                transition: all ease 0.3s;
              }
              &:active:hover {
                background: #f5f5f5;
                color: #ff6057;
              }
            }
          }
          &:not(:last-child) a {
            margin-right: 5px;
          }
        }
      }
    }
    .open-close-menu {
      position: relative;
      display: none;
      font-size: 32px;
      color: #0040a7;
      cursor: pointer;
      transition: all ease 0.3s;
      &::selection {
        background: none;
      }
      @media (max-width: 767px) {
        display: block;
      }
    }
  }
  #mobileDropdownMenu {
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
  }

  .navbar-dropdown-menu {
    position: relative;
    display: none;
    background: white;
    text-align: center;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    @media (max-width: 767px) {
      display: block;
    }
    .mobile-navbar-menu-items {
      position: relative;
      width: 100%;
      display: block;
      li {
        &:last-child {
          padding: 10px 0;
        }
        &:not(:first-child) {
          border-top: 1px solid #f2f2f2;
        }
        a {
          position: relative;
          color: #333;
          padding: 10px 0;
          width: 100%;
          display: block;
          &.btn-link-rounded {
            width: fit-content;
            border-radius: 35px;
            border: 1px solid #ff6057;
            color: #ff6057;
            padding: 8px 30px;
            cursor: pointer;
            letter-spacing: 0.3px;
            transition: all ease 0.3s;
            margin: 0 auto;
          }
        }
        &:hover a {
          background: #f5f5f5;
          color: #000;
          &.btn-link-rounded {
            background: unset;
            color: #ff6057;
            &:hover {
              background: #ff6057;
              color: white;
              transition: all ease 0.3s;
            }
          }
        }
        .btn-link-rounded {
          background: unset;
          color: #ff6057;
          &:hover {
            background: #ff6057;
            color: white;
            transition: all ease 0.3s;
          }
        }
      }
    }
  }
}
