.addSlackNotificationPopup {
  position: fixed;
  @include flex-align-center;
  @include wt-ht(100%, 100vh);
  inset: 0;
  background: transparent;
  z-index: 101;
  .card {
    position: relative;
    display: block;
    z-index: 101;
    border-radius: 10px;
    background: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.53);
    width: 600px;
    &.disableClick {
      @include disable(0.7, unset);
    }
    .cardHeader {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 14px 30px;
      border-bottom: 1px solid $b-c-1;
      .textWrapper {
        position: relative;
        display: block;
        .title {
          position: relative;
          display: block;
          font-size: 20px;
          text-align: left;
          color: $c-3b;
        }
        .desc {
          position: relative;
          display: block;
          margin-top: 6px;
          color: $c-8d;
          font-size: 12px;
        }
      }
      i.close {
        font-size: 20px;
        color: #000000;
        border-radius: 35px;
        padding: 3px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: $c-f5;
          color: $c-33;
        }
      }
    }
    .cardBody {
      position: relative;
      display: block;
      padding: 14px 30px;
      &.loading {
        @include disable(0.5, unset);
      }
    }

    .notesInput {
      @extend %relBlock;
      margin-bottom: 1rem;
      .notes-section {
        height: unset;
        label {
          @extend %label;
        }
        .txt-cont {
          position: relative;
          border-radius: 5px;
          box-shadow: inset 0 1px 2px 0 rgba(111, 111, 111, 0.16);
          border: solid 1px $c-dd;
          background-color: $white;
          height: unset;
        }
      }
      .insertVariableBtn {
        position: relative;
        cursor: pointer;
        margin-top: 3px;
        padding: 8px 5px 8px;
        background: $c-f5;
        font-weight: 500;
        font-size: 12px;
        color: $c-66;
        border: 1px solid $c-dd;
        border-radius: 2px;
        width: fit-content;
        margin: 5px 0 0 auto;
        .varibaleContainer {
          bottom: 26px;
        }
      }
    }
  }
  .overlay {
    @include bg-mask(100, rgba(0, 0, 0, 0.53));
  }

  .btnFooter {
    width: 100%;
    @include flex-center;
    justify-content: flex-end;
    border-top: 1px solid $c-dd;
    padding: 14px 30px;
    gap: 8px;
    button {
      font-size: 14px;
      padding: 10px 0;
      border-radius: 5px;
      letter-spacing: 0.3px;
      min-width: 120px;
      margin: unset;
      &.saveSlackNotificationBtn {
        border: solid 1px $blue-violet;
        background: $blue-violet;
        &.disabledBtn {
          @include disable(0.5, unset);
        }
      }
      &.slackNotificationCancelBtn {
        border: solid 1px $c-dd;
        color: $c-8d;
        background: $white;
      }
      &.loading {
        @include wt-ht(34px !important, 34px !important);
        transition: all 0.3s ease-in-out;
        min-width: auto;
        border-radius: 35px;
        .load-text {
          @include wt-ht(24px, 24px);
          top: 4px;
          left: 4px;
        }
      }
    }
  }
}
