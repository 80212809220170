.priceRangeSliderWrapper {
  @extend %popupCard;
  .card {
    width: 700px;
    max-height: 100%;
    overflow: auto;
    padding: 28px;
    border-radius: 6px;
  }
  .badgeText {
    margin-bottom: 8px;
  }
  .slideRangeHeader {
    @include flex-center;
    justify-content: space-between;
    margin-bottom: 12px;
    position: relative;

    .title {
      font-size: 20px;
      font-weight: 700;
    }
  }
  .rangeCont {
    border-radius: 10px;
    background-color: rgb(242, 242, 242);
    padding: 20px;
    padding-top: 6px;
    margin-top: 12px;
  }
  .slideRangeContent {
    width: 100%;

    datalist {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 12px;
    }

    option {
      padding: 0;
      position: relative;
      left: 14px;
      &:first-child,
      &:last-child {
        left: 0;
      }
    }

    #rangeSlider {
      width: 100%;
      cursor: pointer;
      overflow: hidden;
      position: relative;
    }
  }
  .btnContainer {
    @include flex-center;
    justify-content: center;
    margin-top: 24px;

    .sliderBtn {
      background-color: $blue-violet;
      color: $white;
      margin: 0;
    }
  }
}
