.foundMailPopupWrapper {
  @extend %dataStatusPopup;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  z-index: 1111;
  font-size: 17px;
  .foundMailContactContent {
    display: flex;
    flex-direction: column;
    width: 450px;
    border-radius: 16px;
    overflow: hidden;
    will-change: transform;
    transition: all 0.4s ease;
    background: #fff;
    z-index: 99999999;
    padding: 30px;
    .closeButtonWrapper {
      display: flex;
      justify-content: flex-end;
      .closeButton {
        cursor: pointer;
        width: fit-content;
        .closeIcon {
          position: absolute;
          top: 10px !important;
          right: 10px !important;
          cursor: pointer;
          padding: 4px;
          scale: 0.9;
          i {
            font-size: 10px;
          }
          &:hover {
            background-color: #ddd;
            border-radius: 50%;
          }
        }
      }
    }
    .headerData {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #333333;
      font-size: 18px;
      font-weight: 700;
    }
    .fetchData {
      font-size: 18px;
      font-weight: 700;
      color: #333333;
      padding: 10px 0;
    }
    .fetchProgress {
      display: flex;
      flex-direction: row;
      font-size: 16px;
      padding: 25px 0;
      gap: 30px;
      .contactDataContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .mailDataContainer {
          display: flex;
          gap: 20px;
          flex-direction: row;
          color: #1d2026;
          font-size: 15px;
          .mailIcon {
            padding: 0px 2px 0px 0px;
          }
          .mailId {
            max-width: 184px;
            min-width: 174px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
          }
          .copyIcon {
            cursor: pointer;
          }
        }
        .phoneDataContainer {
          display: flex !important;
          gap: 20px !important;
          flex-direction: row !important;
          .phoneIcon {
            padding: 0px 8px 0px 0px;
          }
          .phoneNumber {
            color: #333333;
            font-size: 15px;
            min-width: 100px;
            max-width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            &.found {
              min-width: 173px;
              max-width: 173px;
            }
          }
          .copyIcon {
            cursor: pointer;
          }
          .viewPhoneNumberContainer {
            cursor: pointer;
            .viewPhoneNumberButton {
              border: 1px solid #707070;
              border-radius: 12px;
              text-align: center;
            }
            .viewPhoneNumber {
              padding: 6px 10px;
              color: #3953fb;
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  .confirmation-popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
    background: rgba(0, 0, 0, 0.53);
    opacity: 0.75;
    &.bulkMask {
      z-index: 1;
    }
  }
  .gotItButtonContainer {
    display: flex;
    justify-content: center;
    .gotItButtonWrapper {
      border-radius: 5px;
      padding: 0 10px;
      background-color: #3953fb;
      color: #ffffff;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
      .gotItButton {
        padding: 8px 10px;
      }
    }
  }
  .confirmation-popup-mask {
    z-index: 115;
  }
}
