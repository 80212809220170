.creditsUsageSection {
  @extend %relBlock;
  height: 100%;
  .creditsUsageHeader {
    position: relative;
    @include flex-center;
    justify-content: space-between;
    height: 55px;
    padding: 0 30px;
    border-bottom: 1px solid $c-ec;
    h6 {
      position: relative;
      display: block;
      font-size: 16px;
      font-family: $font-regular;
      color: $c-66;
      font-weight: 400;
    }
    .flexEnd {
      @include flex-center;
      justify-content: flex-end;
      .refreshCreditsUsage {
        min-width: 100px;
        cursor: pointer;
        margin: auto 0;
        padding: 0 12px;
        color: $c-66;
        background: $c-f5;
        border: 1px solid $c-f5;
        font-size: 14px;
        height: 28px;
        border-radius: 35px;
        .iconRight i {
          font-size: 14px;
          padding-left: 5px;
        }
        &.loading {
          width: 30px !important;
          height: 30px !important;
          min-width: unset !important;
          .load-text {
            width: 18px;
            height: 18px;
            border-color: $blue-violet;
          }
        }
        &:hover {
          color: $c-22;
          border-color: $c-ee;
          transition: all 0.3s ease;
          .iconRight i {
            color: $c-22;
          }
        }
      }
    }
  }
  .creditsUsageBody {
    @extend %relBlock;
    padding: 20px 30px;
    overflow-y: auto;
    height: calc(100% - 55px);
    @media (max-width: 992px) {
    }
    .inputWrapper {
      @extend %inputWrapper;
      display: block;
      margin-bottom: 10px;
      .inputCont {
        display: block;
        .readOnlyInput .inputLabel {
          font-size: 12px;
          bottom: 32px;
        }
      }
      .dynamicText {
        display: block;
        position: relative;
        color: $c-97;
        font-size: 12px;
        max-width: 220px;
      }
    }
    .phoneNumberCredits,
    .emailValidationSection {
      margin-top: 30px;
    }
    .emailCredits,
    .phoneNumberCredits,
    .emailValidationSection {
      position: relative;
      .titleHeader {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid $c-e0;
        margin-bottom: 30px;
        padding: 10px 0;
        .subTitleHead {
          @extend %relBlock;
          width: calc(100% - 150px);
          h6 {
            @extend %relBlock;
            font-size: 16px;
            margin-bottom: 5px;
            color: $c-66;
            font-weight: 400;
          }
          p {
            @extend %relBlock;
            color: $c-78;
            font-size: 13px;
            a {
              color: $blue-violet;
              text-decoration: none;
            }
          }
        }
        .addEmailCreditsBtn,
        .addPhoneCreditsBtn,
        .addEmailValidationCreditsBtn {
          width: 120px;
          margin: auto 0;
          color: $blue-violet;
          background: white;
          border: 1px solid $blue-violet;
          font-size: 14px;
          height: 32px;
          &:hover {
            color: white;
            background: $blue-violet;
            transition: all 0.3s ease;
          }
        }
      }
      .formBody {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 20px;
        column-gap: 20px;
        @media (min-width: 1024px) and (max-width: 1200px) {
          grid-template-columns: repeat(3, 1fr);
        }
        @media (min-width: 768px) and (max-width: 1024px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: 0) and (max-width: 767px) {
          grid-template-columns: 100%;
          column-gap: unset;
        }
      }
    }
  }
}
