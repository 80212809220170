.emptySequence {
  background: white;
  border-radius: 6px;
  @extend %empty-section;
  .createBtn {
    position: relative;
    @include flex-center;
    border: 1px solid $blue-violet;
    border-radius: 5px;
    padding: 2px 20px;
    button {
      background: white;
      color: $blue-violet;
      padding-left: 0;
      padding-right: 0;
      .btn-name {
        font-weight: 600;
      }
      svg {
        fill: $blue-violet;
        @include wt-ht(16px, 16px);
      }
      &:hover {
        background: white;
        color: $blue-violet;
      }
    }
    .freemiumIconTooltip {
      margin-left: 10px;
      width: 26px;
      position: relative;
      .upgradeTooltipSection.btmRight {
        right: -46px;
      }
    }
    ​ &.noSharedSeqContainer {
      flex-direction: row;
      ​ .noSharedSeqIcon {
        margin-right: 10px;
      }
    }
  }
}
