.analyticsBody {
  position: relative;
  width: calc(100% - 30px);
}
.seqAnalyticsSection {
  margin-bottom: 20px;
  @media (max-width: 640px) {
    padding: 0px 15px 15px;
  }
  width: 100%;
  position: relative;
  .seqEmailsSection {
    margin-top: 30px;
    @media (max-width: 640px) {
      margin-top: 15px;
    }
    @media (min-width: 640px) and (max-width: 767px) {
      margin-top: 20px;
    }
  }
  .seqContactsSection {
    margin-top: 10px;
  }
  .seqEmailsSection,
  .seqContactsSection {
    position: relative;
    h6 {
      font-size: 18px;
      display: flex;
      align-items: center;
      @media (max-width: 640px) {
        font-size: 16px;
      }
      @media (min-width: 640px) and (max-width: 767px) {
        font-size: 18px;
      }
      font-weight: 500;
      line-height: 1.32;
      text-align: left;
      color: $c-53;
      margin-bottom: 10px;
    }
    .seqContacts,
    .seqEmails {
      list-style: none;
      padding: 0;
      margin: 0;
      display: grid;
      @media (max-width: 640px) {
        grid-template-columns: repeat(1, calc(100% - 15px));
        column-gap: 15px;
        row-gap: 10px;
      }
      @media (min-width: 640px) and (max-width: 767px) {
        grid-template-columns: repeat(3, calc(33.333% - 15px));
        column-gap: 15px;
        row-gap: 15px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: repeat(4, calc(25% - 20px));
        column-gap: 20px;
        row-gap: 15px;
      }
      @media (min-width: 992px) {
        grid-template-columns: repeat(5, calc(20% - 22px));
        column-gap: 2%;
        row-gap: 20px;
      }
      li {
        position: relative;
        z-index: 0;
        height: 80px;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0px 5px 12.4px 0.7px rgba(59, 59, 59, 0.07);
        border: solid 1px $c-eb;
        background-color: $white;
        transition: all 300ms ease;
        &.linear-background {
          box-shadow: none;
        }
        p {
          margin-bottom: 10px;
          font-size: 14px;
        }
        h4 {
          font-size: 20px;
          @media (max-width: 767px) {
            font-size: 16px;
          }
          color: $c-66;
          line-height: normal;
          font-weight: 600;
          &.comingSoon {
            font-family: $font-regular;
            font-style: italic;
            font-size: 12px;
            color: $c-ab;
            font-weight: 100;
          }
        }
        .badgeIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 10px;
          right: 0;
          z-index: 2;
          padding: 8px 15px;
          @media (max-width: 640px) {
            font-size: 4px 10px;
          }
          border-top-left-radius: 35px;
          border-bottom-left-radius: 35px;
          i,
          .errorOutlineIcon {
            color: $c-53;
            font-size: 16px;
            @media (max-width: 640px) {
              font-size: 14px;
            }
          }
          svg {
            color: $c-53;
            width: 16px;
            height: 16px;
            @media (max-width: 640px) {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }
}
