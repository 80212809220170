.tutorialMain {
  position: fixed;
  @include wt-ht(270px, 100%);
  left: 60px;
  top: 0;
  bottom: 0;
  background: $white;
  z-index: 100;
  overflow-x: hidden;
  transition: 0.3s;
  box-shadow: -2px 0px 21px 0 rgba(59, 59, 59, 0.12);

  .searchWrapper {
    width: 100%;
    border-radius: 5px;
    border-color: #ccc;
    border-width: 1px;
    padding: 10px 20px 10px 20px;
    background-color: #fff;
    z-index: 9999;
    opacity: 1;

    .searchInnerCont {
      position: relative;
      .textbox {
        padding: 5px;
        border-radius: 15px;
        width: 100%;
        padding-left: 30px;
        -webkit-appearance: none;
        font-family: $font-regular;
        color: $c-66;
        border: 2px solid $bg-default;
      }

      i {
        position: absolute;
        left: 10px;
        top: 7px;
        color: $c-87;
        font-size: 15px;
      }
    }
  }

  .headerWrapper {
    position: sticky;
    top: 0;
    z-index: 9999;
  }
  .header {
    position: relative;
    @include flex-center();
    padding: 20px 20px 10px 20px;
    border: 0;

    .title {
      font-size: 19px;
      font-weight: 600;
      color: $c-66;
      font-family: $font-regular;
    }

    .closeIcon {
      position: absolute;
      padding: 3px;
      right: -10px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 20px;
      .material-icons {
        font-size: 19px;
        color: $c-66;
      }

      &:hover {
        background-color: #ddd;
        border-radius: 50%;
      }
    }
  }

  .content {
    padding: 0px 20px;
    .listElement {
      padding: 10px 0px 25px 0px;
      border-bottom: 1px solid #eeeef0;

      .listTitle {
        text-align: left;
        font-size: 15px;
        padding: 7px 0px;
        font-family: $font-regular;
        font-weight: 600;
        color: $c-66;
      }

      .listDesc {
        text-align: left;
        font-size: 12px;
        color: $c-66;
        line-height: 16px;
      }

      .listVideo {
        border: 1px solid #ccc;
        height: 150px;
        border-radius: 5px;
        margin: 10px 0 0px 0;
        iframe {
          display: inline-block;
          position: relative;
          width: 100%;
          height: inherit;
          border-radius: 5px;
          border: none;
        }
      }
    }
  }

  .noResult {
    font-size: 14px;
    color: $c-66;
    padding: 20px 0px;
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}
