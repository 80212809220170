.filterByStep {
  @extend %relBlock;
  margin-left: 10px;

  &:hover {
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
  }

  .filterByStepBtn {
    position: relative;
    @include flex-center();
    border: 1px solid $c-eb;
    padding: 10px;
    border-radius: 5px;
    color: $c-59;
    background: white;
    cursor: pointer;
    @include wt-ht(36px, 36px);
    svg {
      @include wt-ht(16px, 16px);
      fill: $blue-violet;
    }
    .customTooltip {
      margin-top: 35px;
      background-color: #393a3d;
      .sharpPointer {
        border-right: 1px solid #393a3d;
        border-top: 1px solid #393a3d;
        background-color: #393a3d;
        right: -2px;
        top: -56px;
        border-top-right-radius: 35px;
      }
      .tooltipBody {
        width: fit-content;
        right: -10px;
        white-space: nowrap;
        background-color: #393a3d;
        right: -39px;
        top: -81px;
        border-radius: 5px;
        .tooltipText {
          color: $white;
          padding: 8px;
        }
      }
    }

    &.filterDisabled {
      opacity: 0.7;
      pointer-events: none;

      svg,
      svg path {
        fill: $c-9e;
      }
    }
  }
}

.stepsListWrapper {
  @include flex-align-path-justify(flex-start, row, flex-end);
  overflow: auto;
  height: 100%;
  margin-top: 10px;

  .stepsListCont {
    .stepData {
      @include wt-ht(125px, 45px);
      @include flex-align-path-justify(center, row, flex-start);
      padding: 15px 20px;
      background-color: $white;
      font-size: 12px;
      border: 1px solid $c-dd;
      cursor: pointer;
      .stepLabel {
        padding-right: 16px;
      }

      .stepValue {
        font-family: $font-semi-bold;
      }

      &.allStep {
        border: 1px solid $c-dd;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &.selected {
        background-color: $blue-violet;
        color: $white;
        border-color: $blue-violet;
      }
    }

    &.stepListloader {
      .linear-background {
        width: 100%;
      }
    }
  }
}
