.moreOptionSlide {
  position: relative;
  text-align: right;
  .threeDot {
    position: relative;
    border-radius: 35px;
    padding: 5px;
    color: $c-48;
    font-size: 16px;
    cursor: pointer;
    border-radius: 35px;
    transition: all 0.3s ease-in-out;
    &:hover {
      transition: all 0.3s ease-in-out;
      color: black;
      background: $c-f5;
    }
  }
  .dropdownOptions {
    position: absolute;
    z-index: 100;
    width: fit-content;
    top: -4px;
    right: 20px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    ul.dropdown {
      position: relative;
      border-radius: 35px;
      box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
      border: solid 1px $c-eb;
      @include list-style-zero();
      background: white;
      padding: 3px;
      @include flex-center;
      li {
        color: $c-66;
        position: relative;
        border-radius: 2px;
        cursor: pointer;
        @include flex-center;
        border-radius: 35px;
        &:not(:last-child) {
          margin-right: 5px;
        }
        i {
          font-size: 18px;
          padding: 5px;
          border-radius: 35px;
          transition: all 0.3s;
          &:hover {
            background: $c-f5;
            i {
              color: $black;
            }
          }
        }
        .customTooltip {
          display: none;
          font-family: $font-regular;
        }
        &:hover .customTooltip {
          display: block;
          font-family: $font-regular;
          .sharpPointer {
            border: unset;
            background: #393a3d;
            top: 14px;
            right: 10px;
            padding: 5px;
            border-bottom-left-radius: 5px;
            border-top-right-radius: unset;
          }
          .tooltipBody {
            background-color: #393a3d;
            border-radius: 5px;
            width: 86px;
            top: 18px;
            .tooltipText {
              font-size: 12px;
              color: $white;
              @include flex-center;
              justify-content: center;
              padding: 6px;
            }
          }
        }
      }
    }
  }
  .linear-background {
    display: block;
    @include wt-ht(24px, 24px);
    border-radius: 35px;
    margin: 0 0 0 auto;
  }
}
