@mixin selectTaskType {
  user-select: none;
  width: fit-content;
  @include flex-center;
  justify-content: center;
  @include wt-ht(100%, 100%);
  .popupCont {
    position: relative;
    width: 360px;
    height: fit-content;
    background: $white;
    padding: 20px;
    border-radius: 6px;
    z-index: 100;
    .taskTypeHeader {
      @include flex-center();
      justify-content: space-between;
      padding-bottom: 10px;

      .title {
        font-size: 18px;
        color: $c-66;
      }

      .popup-close {
        position: absolute;
        padding: 3px;
        top: 16.5px;
        right: 11px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .material-icons {
          font-size: 19px;
        }

        &:hover {
          background-color: #ddd;
          border-radius: 50%;
        }
      }
    }

    .taskOptions {
      @include flex-center();
      justify-content: space-between;
      flex-direction: row;
      padding: 15px 0px;

      .optionCont {
        @include flex-center();
        justify-content: space-between;
        width: 100%;

        .option {
          padding: 5px 15px;
          border: 1px solid #eeeef0;
          border-radius: 5px;
          position: relative;
          @include flex-center();
          cursor: pointer;
          height: 35px;

          &:hover {
            box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
          }
        }

        .manualEmail {
          .img {
            height: 18px;
            img {
              object-fit: contain;
              padding-right: 5px;
              @include wt-ht(21px, 14px);
            }
          }

          .text {
            font-size: 14px;
          }
        }

        .linkedInTask {
          &:hover {
            .linkedinSubMenu {
              display: block;
            }
          }

          .img {
            margin-right: 5px;
            svg {
              width: 16px;
              height: 13px;
              fill: #4b4f86;
            }
          }

          .subMenuArrow {
            transform: rotate(90deg);
          }

          .text {
            font-size: 14px;
          }

          .linkedinSubMenu {
            bottom: unset;
            opacity: 1;
            left: unset;
            width: 270px;
            padding: 10px 0px;
            font-family: inherit;
            background: #fff;
            z-index: 999999;
            top: 35px;
            border-radius: 10px;
            box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
            border: solid 1px #ebebeb;
            color: #666666;
            position: absolute;
            right: 0px;
            display: none;

            .stepCont {
              position: relative;
              display: flex;
              align-items: center;
              flex-direction: row;
              padding: 10px 15px;
              cursor: pointer;
              font-size: 14px;

              &:hover {
                background-color: $c-fa;
              }
            }
          }
        }
      }
    }
  }
}

.sequenceContactUploader {
  position: fixed;
  top: 0;
  left: 0;
  @include flex-center;
  @include wt-ht(100%, 100%);
  justify-content: center;
  z-index: 999;
  .contactUploader {
    @extend %relBlock;
    padding: 30px;
    width: 800px;
    margin: 0 auto;
    background: white;
    border-radius: 10px;
    z-index: 1000;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.08);
    h3 {
      color: $c-33;
      font-size: 24px;
      font-weight: 500;
    }
    .generatingPreview {
      @include flex-center;
      flex-direction: column;
      justify-content: center;
      height: calc(100% - 30px);
      padding: 80px 0;
      .generatingPreviewText {
        margin-top: 20px;
        text-align: center;
        font-size: 18px;
      }
    }
  }
}
.contactUploaderOverlay {
  @include bg-mask(999, rgba(0, 0, 0, 0.57));
}

.fieldValidatorMsgPopup {
  position: fixed;
  top: 0;
  left: 0;
  @include flex-center;
  @include wt-ht(100%, 100%);
  justify-content: center;
  z-index: 999;
  .fieldValidatorMsgCard {
    position: relative;
    z-index: 1000;
    border-radius: 10px;
    max-width: 1000px;
    background: #fff;
    padding: 30px;
    h3 {
      color: $c-33;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 50px;
    }
    .cardContent {
      position: relative;
      margin-bottom: 25px;
      @include flex-align-path-justify(center, column, center);
      text-align: center;
      img.contactErrorIcon {
        width: 75px;
        object-fit: contain;
      }
      .errorMessage {
        padding-top: 25px;
        .messageCont {
          .message {
            color: $c-66;
            font-family: $font-regular;
            padding-bottom: 25px;
            text-align: center;
            b {
              font-family: $font-medium;
            }
          }
          .missingFieldsCont {
            color: $c-66;
            font-size: 15px;
            line-height: 1.4;
            font-family: $font-medium;
            text-align: center;
            .missingField {
              .value {
                text-transform: capitalize;
              }
              .delimitter {
                padding: 0px 3px;
              }
            }
          }
        }
        .bottomCont {
          padding-top: 70px;
          color: $c-66;
          .orText {
            margin: 15px 0px;
            font-style: italic;
          }
          .cancelText {
            font-style: italic;
            .canceLink {
              color: $blue-violet;
              font-weight: 600;
              cursor: pointer;
            }
          }
          .btnCont {
            @include flex-align-path-justify(center, row, center);
            margin-top: 15px;
            .skipAndUploadContact,
            #reUploadContact {
              background: $blue-violet;
              color: $white;
              min-width: 120px;
              margin: 0 15px;
              &.loading {
                min-width: auto;
              }
            }
            #backToContactTable {
              background-color: $white;
              min-width: 120px;
              margin: 0 15px;
              border: 1px solid $blue-violet;
              color: $blue-violet;
              position: relative;
              i.material-icons-outlined {
                position: absolute;
                top: 10px;
                left: 20px;
                font-size: 20px;
              }
              &:hover {
                i.material-icons-outlined {
                  transform: translate(-20%);
                  will-change: transform;
                  transition: all 0.5s;
                }
              }
            }
          }
          .reuploadMsg {
            color: $red;
          }
        }
      }
      .bulkPopupMessage {
        height: 100%;

        .errorMessage {
          padding-top: 25px;

          &.limiReached {
            width: 70%;
            @include flex-center();
            .accordionText {
              font-size: 16px;
              line-height: 1.6;
              width: 480px;
            }
          }
        }
      }
    }
  }
  .fieldValidatorOverlay {
    @include bg-mask(999, rgba(0, 0, 0, 0.57));
  }
}

.contactPage {
  .rightPanel {
    padding: 0;
    height: 100%;
    .rightSideBarWrapper {
      height: 100%;
    }
  }
  .selectTaskTypeMask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: $c-33;
    opacity: 0.75;
  }
  .taskTypeCardPopup {
    position: fixed;
    z-index: 99;
    @include wt-ht(100%, 100%);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .selectTaskPopup {
      @include selectTaskType();
    }
  }

  .addContactWrapper {
    @include flex-center();
    flex-direction: row-reverse;
  }

  .headerSection {
    padding: 12px !important;
    .headerRightSection {
      padding: 0 !important;
      button {
        @extend %btn-blue;
        height: 36px;
        width: unset;
        min-width: 120px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.3px;
      }
    }
  }

  .bodySection {
    @extend %body-layout;
    display: flex;
    .layoutBody {
      width: 100%;
      background: transparent;
      .toggleContactFilterByOption {
        position: relative;
        @include flex-center();
        height: 36px;
        margin-left: 10px;
        width: 100px;
        &.disabled {
          @include disable(0.5, pointer, unset, unset);
        }
        &:hover {
          box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
        }
        background: $blue-violet;
        border: 1px solid $blue-violet;
        border-radius: 5px;
        color: white;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        svg {
          @include wt-ht(14px, 16px);
          fill: white;
          margin: 0 10px;
        }
        .text {
          font-size: 14px;
          padding-right: 6px;
          padding-top: 4px;
        }
        .count {
          @include flex-center;
          justify-content: center;
          background: white;
          color: $blue-violet;
          font-size: 14px;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
          @include wt-ht(24px, 32px);
          padding-top: 4px;
        }
        &.filterUsed {
          transition: all 0.3s ease-in-out;
          background: white;
          border: 1px solid $b-c-1;
          svg {
            fill: $blue-violet;
          }
          .text {
            color: $blue-violet;
          }
        }
      }
      .contactDataList {
        height: 100%;
      }
      &.showContactFilter {
        display: flex;
        justify-content: space-between;
        max-width: 100%;
        .contactDataList {
          flex: 0 0 calc(100% - 310px);
          max-width: calc(100% - 310px);
          transition: all 0.3s ease-in-out;
        }
        .contactFilterByOption {
          height: 100%;
          flex: 0 0 300px;
          max-width: 300px;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    .headerContents {
      position: relative;
      width: 100%;
      overflow: unset;
      padding: 10px 0px;
      display: flex;
      height: 58px;
      justify-content: space-between;
      .titleWrapper {
        .titleName {
          color: $c-53;
          background-color: $white;
          font-size: 22px;
          font-family: "Graphik-Regular ", Arial, sans-serif;
          @include ellipsis();
        }
      }

      .wrapExportFilterIcon {
        .drop-down-menu {
          position: absolute;
          top: 32px;
          z-index: 3;
          ul li .listItem .listItemWrap {
            width: 150px;
          }
        }
        @include addToSequenceButton();
      }

      @include searchInputByKeyword();
    }

    .filterContents {
      width: 100%;
      @include flex-center();
      justify-content: center;
      border-bottom: solid 1px $c-eb;
      &.seq-contact-nav-section {
        height: 83px !important;
      }
      .filterWrapper {
        position: relative;
        background: transparent;
        width: 100%;
        padding: 0px 0 8px;
        &.seq-contact-list {
          margin-bottom: 5px;
          overflow-x: auto;
          grid-auto-flow: column;
          .seq-contact-card {
            height: 69px !important;
          }
          .plusContactCard {
            height: 69px !important;
            border: 1px dashed $contact-stage-border;
            font-size: 44px;
            color: $contact-stage-border;
            cursor: pointer;
            &:hover {
              border-color: $contact-stage-border;
            }
          }
        }
        .filterCont {
          display: flex;
          width: inherit;
          align-items: center;
          border: 1px solid $c-eb;
          border-radius: 5px;
          width: 100%;
          justify-content: center;
          transition: background 200ms ease;
          box-shadow: 0px 4px 6px 0.7px rgba(59, 59, 59, 0.07);
          &.active {
            .filterValue {
              color: $c-ff9;
            }
          }
          .filterOption {
            @include flex-center();
            justify-content: space-between;
            flex-direction: row;
            position: relative;
            width: 100%;
            .filterValueCont {
              display: grid;
              text-align: left;
              grid-gap: 5px;
              overflow: hidden;
              font-size: 14px;
              .filterValue {
                font-size: 18px;
                font-weight: bold;
                &.contacted {
                  color: $blue-violet;
                }
                &.not_contacted {
                  color: $color-yellow;
                }
                &.replied {
                  color: $color-yellow;
                }
                &.opted_out {
                  color: $red;
                  font-size: 18px;
                }
                &.bounced {
                  font-size: 18px;
                }
                &.clickCount {
                  color: $pink;
                }
                &.openCount {
                  color: $purple;
                }
              }
              .filterLabel {
                font-size: 14px;
                color: $c-97;
                @include ellipsis();
              }
            }
            .material-icons-outlined {
              @include wt-ht(24px, 24px);
              color: $c-97;
              font-size: 20px;
            }
            .contactFilterIcon {
              position: relative;
              color: $c-97;
              svg {
                color: $c-97;
                fill: $c-97;
                * {
                  color: $c-97;
                  fill: $c-97;
                }
              }
              &.contacted,
              &.not_contacted,
              &.clickCount {
                svg {
                  @include wt-ht(16px, 16px);
                }
              }
              &.replied {
                svg {
                  @include wt-ht(19px, 16px);
                }
              }
              &.clickCount {
                svg {
                  @include wt-ht(18px, 18px);
                }
              }
            }
          }
          &:hover {
            border-color: #c4c4c4;
          }
        }

        #contacted .filterValue {
          color: $blue-violet;
        }

        #notcontacted .filterValue {
          color: $color-yellow;
        }

        #replied .filterValue {
          color: $color-green;
        }

        .coming-soon-card {
          .filterValue,
          .filterLabel,
          .material-icons-outlined {
            color: #ababab !important;
          }
          .filterValue {
            font-weight: 100 !important;
            font-size: 12px !important;
            font-style: italic;
          }
        }
      }
    }

    .contactDetailsCont {
      height: calc(100% - 138px);

      .contactDetailsInnerCont {
        height: 100%;
        .seq-contacts-table-section {
          height: 100%;
          background: transparent;
          .contactTable {
            height: 100%;
            overflow: hidden;
            border-radius: 10px;
            .pagination-section {
              border-top: 1px solid transparent;
            }
            .seq-contacts-table-header {
              height: 48px;
              border: 1px solid $b-c-1;
              @include bor-rad-top(10px, 10px);
              background: white;
              .headerLabel {
                padding: 0 10px;
              }
              .contactSelectionCbx {
                @include contactSelectCheckbox();
                .checkBoxSection {
                  padding: 0px;
                }
              }
              .contact-user-email {
                width: 240px;
              }
              .contact-user-company {
                width: 15%;
                padding: 10px;
                @include ellipsis();
                white-space: nowrap;
              }
              .contact-sequence,
              .actionColumn {
                width: 15%;
                padding: 10px;
              }

              .contact-stage-insight {
                width: 20%;
              }

              .contact-status {
                width: 12%;
              }

              .contact-tag {
                width: 12%;
              }

              .contact-date {
                width: 15%;
              }

              .contact-options {
                visibility: hidden;
                width: 5%;
              }
            }

            &.hidePagination {
              .contactBody {
                height: calc(100% - 70px);
              }
            }

            .contactBody {
              flex-direction: column;
              width: 100%;
              height: calc(100% - 52px);
              overflow-y: auto;
              transition: all 0.3s;
              background: white;
              border-left: 1px solid $b-c-1;
              border-right: 1px solid $b-c-1;
              border-bottom: 1px solid $b-c-1;
              border-radius: 10px;
              &.contactTableLoader {
                background: white;
                height: 100%;
                border-bottom: none;
              }
              .seq-contacts-table-body {
                position: relative;
                padding: 0px 5px;
                .columnCont {
                  padding: 0 10px;
                }
                .contactSelectionCbx {
                  @include contactSelectCheckbox();
                  width: 26px !important;
                  .checkBoxSection {
                    padding: 0px;
                  }
                }

                .contact-user-email {
                  width: 240px;
                  .linear-background {
                    width: 90%;
                  }
                }

                .contact-sequence,
                .actionColumn {
                  width: 15%;
                  padding: 10px;
                  @include flex-center();

                  span.trigger {
                    width: fit-content;
                    max-width: 135px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    cursor: default;

                    .sequence.column {
                      width: fit-content;
                      padding-right: 10px;
                      text-align: left;
                      max-width: inherit;
                    }
                  }

                  .seqStatus {
                    font-size: 12px;
                    text-transform: capitalize;
                  }

                  .arrowIcon {
                    @include wt-ht(12px, 12px);
                    margin-right: 5px;
                    svg {
                      fill: $c-66;
                      @include wt-ht(12px, 12px);
                    }
                  }
                }

                .actionColumn {
                  position: relative;
                  user-select: none;
                  :not(:last-child) {
                    margin-right: 15px;
                  }
                  .circleActionIcon {
                    position: relative;
                    @include flex-center();
                    justify-content: center;
                    @include wt-ht(30px, 30px);
                    cursor: pointer;
                    color: $blue-violet;
                    border: 1px solid $c-blue;
                    font-size: 16px;
                    border-radius: 50%;
                    padding: 7px;
                    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.09);
                    i {
                      font-size: 16px;
                      vertical-align: middle;
                    }
                    svg {
                      @include wt-ht(16px, 16px);
                      path:nth-child(2) {
                        color: $blue-violet;
                        fill: $blue-violet;
                      }
                    }
                    .customTooltip {
                      position: absolute;
                      top: 28px;
                      left: 2px;
                      .tooltipBody.alignRight {
                        left: -12px;
                        min-width: unset;
                        max-width: unset;
                      }
                    }
                    &.seqIcon {
                      svg {
                        @include wt-ht(12px, 12px);
                        color: $blue-violet;
                        fill: $blue-violet;
                      }
                      .customTooltip {
                        position: absolute;
                        top: 28px;
                        left: 2px;
                        .tooltipBody.alignRight {
                          left: -12px;
                          min-width: unset;
                          max-width: unset;
                        }
                      }
                    }
                    &:hover {
                      border: 1px solid $c-f5;
                      background: $c-f5;
                      box-shadow: 0px 0px 4px 1px rgba(59, 59, 59, 0.14);
                    }
                  }
                  .noPhone {
                    border: 1px solid #e3e4e4;
                    color: $c-ab;
                    cursor: pointer;
                    &:hover {
                      border: 1px solid $c-f5;
                      background: $c-f5;
                      color: $c-33;
                      box-shadow: 0px 0px 4px 1px rgba(59, 59, 59, 0.14);
                    }
                  }
                  .linear-background {
                    @include wt-ht(30px, 30px);
                    border-radius: 50%;
                  }
                }

                .contact-stage-insight {
                  width: 20%;
                  padding: 10px 0;
                }

                .contact-date {
                  width: 15%;
                }

                .contact-tag {
                  width: 12%;
                  @extend %relBlock;
                  .tagNameTable {
                    font-size: 14px;
                  }
                  .moreTags {
                    color: $blue-violet;
                    font-size: 13px;
                  }
                }

                .contact-status {
                  text-transform: capitalize;
                  width: 12%;
                }

                .contact-job {
                  width: 15%;
                  padding: 10px;

                  h6.linear-background {
                    margin-bottom: 5px;
                  }
                }

                .companyCont {
                  width: 15%;
                  display: flex;
                  flex-direction: column;
                  padding: 10px;

                  .job {
                    margin-bottom: 5px;
                    color: $c-53;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }

                  .company {
                    font-size: 13px;
                    word-break: break-word;
                  }
                }

                .moreOption {
                  width: 5%;
                  text-align: center;
                  cursor: pointer;
                  .linear-background {
                    @include wt-ht(30px, 30px);
                    border-radius: 50%;
                  }
                }

                .column {
                  width: 100%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;

                  &.nameCont {
                    @include flex-center;
                    margin-bottom: 5px;

                    .nameInnerCont {
                      @include flex-center();
                      @include ellipsis();
                      max-width: 80%;
                      width: fit-content;

                      .name {
                        font-size: 14px;
                        color: $c-53;
                        margin-bottom: 5px;
                      }

                      .lastName {
                        padding-left: 5px;
                      }
                    }

                    .ownerCont {
                      background-color: #e3e3e3;
                      color: #757575;
                      margin-left: 5px;
                      border-radius: 3px;

                      .ownerBadge {
                        text-transform: uppercase;
                        font-size: 9px;
                        padding: 5px;
                        cursor: pointer;
                      }
                    }
                  }

                  &.email {
                    font-size: 13px;
                    color: $c-88;
                  }
                }
              }
            }
          }
          &.adjustPaginationSpace {
            .contactBody {
              height: calc(100% - 54px);
              transition: all 0.3s;
              @include bor-rad-btm(0, 0);
              .ctTableBody:not(:last-child) {
                border-bottom: 1px solid $b-c-1;
              }
            }
          }
          &.adjustTableSpace {
            .contactBody {
              height: calc(100% - 2px);
              transition: all 0.3s;
            }
          }
        }
      }
    }
    .editContactPopup {
      .popup-wrap {
        background: $white;
        display: inline-block;
        padding: 30px;
        height: auto;
        min-width: 500px;
        .popContainer {
          padding: 20px 0px 10px 0px;
          .inputHolder {
            margin: 5px 0px;
            min-width: 150px;
            margin-right: 20px;
          }
        }
        .popup-action-wrap {
          padding: 0 40px;
          button {
            font-size: 13px;
            width: 60%;
          }
        }
      }
    }
  }
}

#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
}

.contactCountCard {
  position: relative;
  border-radius: 6px;
  height: 30px;
  font-size: 14px;
  color: $c-87;
  @include flex-center;
  justify-content: center;
  background: #e8ffce;
  transition: all 0.3s;
  .descText {
    position: relative;
    .linkBtnText {
      color: $blue-violet;
      font-weight: 600;
      margin-left: 2px;
      cursor: pointer;
    }
  }
}
.ctTableHeader {
  border-top: 1px solid $b-c-1;
  border-bottom: 1px solid $b-c-1;
  height: 48px;
  position: sticky;
  top: 0;
  z-index: 1;
}
.ctTableBodyWrapper {
  height: calc(100% - 48px);
  .add-contact-stage {
    height: 100% !important;
  }
}
.ctTableBody {
  position: relative;
  border-bottom: 1px solid $b-c-1;
}
.ctTableBodyLoader {
  position: relative;
  &:not(:last-child) {
    border-bottom: 1px solid $b-c-1;
  }
}
.ctTableHeader,
.ctTableBody,
.ctTableBodyLoader {
  width: 100%;
  background: white;
  display: grid;
  grid-template-columns:
    30px calc(15% - 10px) calc(13% - 10px) calc(13% - 30px) 12% calc(21% - 20px)
    11% 8% 7%;
  align-items: center;
  font-size: 14px;
  padding: 0 5px;
  color: $c-66;
  .ctSelectAll {
    @include flex-center;
    justify-content: center;
    .linear-background {
      @include wt-ht(15px, 15px);
      border-radius: 5px;
    }
  }
  .nameEmail,
  .companyJobTile,
  .tag,
  .status,
  .stageInsight,
  .lastContacted {
    position: relative;
    padding: 10px 5px;
  }
  .lastContacted {
    text-align: center;
  }
  .nameEmail {
    .name {
      color: $c-66;
      font-weight: 600;
      margin-bottom: 5px;
      @include ellipsis();
      &:hover {
        color: $blue-violet;
      }
      &.linear-background {
        @include wt-ht(50%, 14px);
      }
    }
    .email {
      color: $c-78;
      @include ellipsis();
      &:hover {
        color: $blue-violet;
      }
      &.linear-background {
        @include wt-ht(90%, 14px);
      }
    }
  }
  .companyJobTile {
    @extend %companyJobTile;
  }
  .tag {
    @extend %contactTag;
  }
  .ctOwner .circleOwner {
    @include flex-center;
    justify-content: center;
    @include wt-ht(36px, 36px);
    border-radius: 35px;
    padding-top: 2px;
    border: 1px solid $blue-violet;
    color: $blue-violet;
    text-transform: uppercase;
    font-size: 12px;
  }
  .lastContacted .linear-background {
    @include wt-ht(70%, 14px);
    display: block;
    margin: 0 auto;
  }
  .status {
    text-transform: capitalize;
    .icon.linear-background {
      display: inline-block;
      @include wt-ht(14px, 14px);
    }
    .text.linear-background {
      display: inline-block;
      width: calc(100% - 40px);
      height: 14px;
      margin-left: 6px;
    }
    .stageCont {
      @include flex-center();
      .icon {
        padding-top: 2px;
        svg {
          @include wt-ht(14px, 14px);
        }
      }
      color: $c-97;
      svg {
        color: $c-97;
        fill: $c-97;
        * {
          color: $c-97;
          fill: $c-97;
        }
      }
      .replied {
        svg {
          @include wt-ht(19px, 16px);
        }
      }

      .optedout {
        .material-icons-outlined {
          font-size: 16px;
        }
      }

      .bounced {
        .material-icons-outlined {
          font-size: 16px;
        }
      }
    }
    .plusIcon {
      @include flex-align-center();
    }
    .stage {
      line-height: 1;
      @include ellipsis();
    }
  }
  .insight-loader {
    margin: 0;
  }
  &.filterEnabled {
    grid-template-columns:
      30px calc(18% - 10px) calc(16% - 10px) auto calc(26% - 20px)
      20% 7%;
    .tag,
    .ctOwner {
      display: none;
    }
  }
}
.ctTableBody,
.ctTableHeader,
.ctTableBodyLoader {
  .ctOwner,
  .actionDp {
    position: relative;
    @include flex-center;
    justify-content: center;
    .linear-background {
      @include wt-ht(30px, 30px);
      border-radius: 35px;
      display: block;
      display: block;
      margin: 0 auto;
    }
  }
}
.ctOwner {
  .customTooltip {
    background: #393a3d;
    .sharpPointer {
      border-right: 1px solid $c-33;
      border-top: 1px solid $c-33;
      background: #393a3d;
      top: -40px;
      right: 10px;
    }
    .tooltipBody {
      width: fit-content;
      right: -10px;
      white-space: nowrap;
      background: #393a3d;
      right: -33px;
      top: -63px;
      .tooltipText {
        color: $white;
        padding: 8px;
      }
    }
  }
}
.contactSelectedCount {
  @include flex-center;
  position: relative;
  font-size: 15px;
  .allContactSelected {
    @include flex-center;
    position: relative;
    .clearBtn {
      color: $blue-violet;
      margin-left: 5px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .clearBtn {
    color: $blue-violet;
    margin-left: 5px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .selectedCount {
    color: $c-66;
    padding-right: 3px;
  }
  .totalCount {
    color: $blue-violet;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
