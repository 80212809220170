.editAccountSection {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  @include flex-center();
  justify-content: center;
  letter-spacing: 0.3px;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: auto;
  .card {
    position: relative;
    display: block;
    z-index: 101;
    border-radius: 5px;
    padding: 20px;
    background: white;
    min-width: 778px;
    max-width: 778px;
    .cardTitle {
      position: relative;
      @include flex-center;
      justify-content: space-between;
      padding-bottom: 12px;
      border-bottom: 1px solid $c-ec;
      h4 {
        position: relative;
        font-size: 20px;
        color: $c-3b;
        font-weight: 400;
      }
      i {
        position: relative;
        font-size: 24px;
        top: 2px;
        padding: 3px;
        border-radius: 50%;
        color: $c-53;
        cursor: pointer;
        &:hover {
          background: $c-dd;
          color: $black;
        }
      }
    }
    .cardBody {
      position: relative;
      padding: 24px 0 16px;
      overflow-y: auto;
      display: grid;
      column-gap: 16px;
      row-gap: 5px;
      grid-template-columns: repeat(2, 1fr);
      .dropdownOptions .selectOptionDropdown {
        max-height: 160px;
      }
      .inputWrapper {
        position: relative;
        @extend %inputWrapper;
        align-items: unset;
        width: 100%;
        margin-bottom: 14px;
      }
    }
    .cardFooter {
      position: relative;
      padding-top: 10px;
      @include flex-center;
      justify-content: flex-end;
      column-gap: 15px;
      button {
        font-size: 14px;
        padding: 10px 0;
        border-radius: 5px;
        letter-spacing: 0.3px;
        min-width: 120px;
        margin: unset;
        &.updateAccountBtn {
          border: solid 1px $blue-violet;
          background: $blue-violet;
          &.disabledBtn {
            pointer-events: none;
            user-select: none;
            cursor: unset;
          }
        }
        &.cancelEditAccountBtn {
          border: solid 1px $blue-violet;
          color: $blue-violet;
          background: white;
        }
        &.loading {
          @include wt-ht(34px !important, 34px !important);
          transition: all 0.3s ease-in-out;
          min-width: auto;
          border-radius: 35px;
          .load-text {
            @include wt-ht(24px, 24px);
            top: 4px;
            left: 4px;
          }
        }
      }
    }
  }
}

.overlayAccountMask {
  @include bg-mask(99, rgba(0, 0, 0, 0.57));
}
