%textDark {
  .textDark {
    font-weight: 500;
    color: $c-33;
  }
}

%textNormal {
  .textNormal {
    font-size: 14px;
    color: $c-33;
  }
}

%salesForce {
  .configFooter {
    padding: 24px 0;
    position: fixed;
    bottom: 0;
  }
  button.updateConfigure {
    background: $blue-violet;
    &.loading {
      min-width: auto;
    }
    &.disableBtn {
      @extend %disableBtn;
    }
  }
  %disableBtn {
    color: $c-cc;
    background: transparent;
    border: 1px solid $c-cc;
    cursor: not-allowed;
    pointer-events: none;
  }
  .activityLog {
    position: relative;
    .variantToggleWrapper {
      @include flex-center();
      align-items: center;
      line-height: 26px;
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      .toggleText {
        font-size: 13px;
        margin-left: 10px;
      }
    }
    h5.subHeading {
      font-size: 16px;
      margin-bottom: 6px;
      font-weight: 600;
    }
    p.descText {
      margin-bottom: 15px;
      font-size: 15px;
    }
    @extend %toggleSection;
  }
}

.disabledConfigure {
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
  button.updateConfigure {
    @extend %disableBtn;
  }
  button.cancelConfigure {
    pointer-events: all;
    cursor: pointer;
  }
}

%slack {
  .card {
    grid-template-columns: 77px auto 150px;
    img {
      max-width: 85%;
    }
  }
  .configFooter {
    padding: 16px 0;
    width: 60%;
    background: white;
    position: fixed;
    bottom: 0px;
    @include bor-rad-btm(10px, 10px);
  }

  .config {
    width: 55%;
    margin-bottom: 25px;

    .selectChannel {
      .textCont {
        @include flex-align-path-justify(center, row, space-between);
        @extend %textDark;
        @extend %textNormal;
      }
      .channelList {
        margin-top: 10px;
        border-radius: 8px;
        box-shadow: inset 0 1px 3px 0 $c-d6d6;
        border: solid 1px $c-9d9d;

        .autoCompleteDropdown {
          border-radius: inherit;
          .dropdownHeader {
            border-radius: inherit;
            border: 0;
            width: 100%;

            .title {
              margin-left: 10px;
              text-align: left;
              width: 95%;
              font-weight: 500;
            }
            .arrow {
              width: 5%;
              justify-content: center;
              i {
                color: $c-33;
              }
            }
          }

          .dropdownPanel {
            border-radius: 8px;
            .dropdownList {
              width: 100%;
              max-height: 200px;
              .dropdownOptions {
                .updatedName {
                  color: $c-3b;
                  &.loading {
                    @include flex-center;
                    color: $c-8d;
                  }
                }
              }
            }
          }
        }

        .autoCompleteDropdown,
        .dropdownPanel,
        .dropdownHeader {
          width: 100%;
        }
      }
    }
  }

  .memberconfig {
    margin-bottom: 30px;
    .textCont {
      @extend %textDark;
      margin-bottom: 15px;
    }

    .tableView {
      margin-bottom: 25px;
      border: solid 1px $c-bebe;
      border-radius: 8px;
      overflow: auto;
      &.disabled {
        opacity: 0.75;
        pointer-events: none;
      }

      table {
        border-collapse: collapse;
        font-size: 14px;
        thead {
          @extend %textDark;
          border-bottom: solid 1px $c-bebe;
          th {
            padding: 20px;
            background-color: $c-f5;
          }
        }
        .tableHeaderWrapper {
          tr {
            display: grid;
            grid-template-columns: 46% 18% 18% 18%;
          }
        }
        .tableBodyWrapper {
          display: block;
          overflow-y: auto;
          max-height: 460px;
          tr {
            display: grid;
            grid-template-columns: 46% 18% 18% 18%;
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid $c-f0;
            td {
              padding: 20px;

              &.open,
              &.click,
              &.reply {
                .checkboxWrapper {
                  justify-content: center;
                }
              }

              &.email {
                white-space: nowrap;
                max-width: 250px;
                overflow: hidden;
                color: $c-33;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }

  button#slackSettings {
    width: 130px;
    margin: 0px;
    background-color: $blue-violet;
  }
}

.crmConfigurePage {
  @include crmConfigurePageBase();

  .newTooltip {
    z-index: 13 !important;
    border-radius: 6px;
    position: absolute !important;
    right: 140px;
    top: 50px;
    &.crmNotConnected {
      right: 0px !important;
      top: 84px !important;
      .sharpPointer {
        top: 0px;
      }
    }
    .tooltipBody {
      padding: 12px;
    }
    .tooltipCloseIcon {
      z-index: 14 !important;
    }
  }

  &.salesForce {
    @extend %salesForce;
    height: 100%;
    width: 80%;
    .activityLogWrapper {
      margin-bottom: 20px;
      height: calc(100% - 140px);
    }
  }

  &.slack {
    @extend %slack;
    .activityLogWrapper {
      max-width: 55%;
    }
  }
  &.zoho {
    @extend %zoho;
    height: 100%;
    width: 80%;
    .activityLogWrapper {
      margin-bottom: 20px;
      height: calc(100% - 140px);
    }
    .tabNavigation .tabItem {
      min-width: unset !important;
      width: 100% !important;
    }

    .card {
      img {
        width: 45px;
        height: auto;
      }
    }
  }

  &.hubspot {
    height: 100%;
    width: 80%;
    @extend %hubspot;
    .activityLogWrapper {
      margin-bottom: 20px;
      height: calc(100% - 140px);
    }
    // .card {
    //   grid-gap: 16px;
    //   width: 100%;
    //   box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.11);
    // }
  }

  &.pipedrive {
    @extend %pipedrive;
    height: 100%;
    width: 80%;
    .activityLogWrapper {
      margin-bottom: 20px;
      height: calc(100% - 140px);
    }
  }

  .activityLogWrapper {
    position: relative;
    .upgradeTooltipSection {
      top: 30px;
      right: -16px;
      .pointer {
        right: 22px;
        top: -6px;
      }
    }
    .thunderOrange {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
.crmIntegration {
  max-height: 100%;
  .crmInnerCont {
    height: 100%;
  }
}

.highlightConnectBtn {
  background-color: $white !important;
  z-index: 14 !important;
  width: 200px;
  height: 55px;
  border-radius: 6px;
  @include flex-center;
  justify-content: center;
}
