.onboardParentContainer {
  .onboardMainContainer {
    .onBoardingCompletedPopupWrapper {
      z-index: 12;
    }
    .onboardCard {
      position: absolute;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      width: 470px;
      top: 5px;
      z-index: 11;
      right: 40px;
      height: calc(100vh - 50px);
      background-color: #ffffff;
      transition: box-shadow 0.5s, opacity 0.5s ease-in-out,
        transform 0.5s ease-in-out;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      }

      .onboardContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        margin-top: 70px;
        overflow-y: auto;
        border-radius: 0 0 8px 8px;

        .onboardItem {
          display: flex;
          gap: 20px;
          padding: 15px 16px;
          align-items: center;
          justify-content: center;
          border-bottom: 2px solid #d9d9d9;
          &.trueFlag {
            background-color: #f3f3f3;
            border-bottom: 2px solid #d9d9d9;
          }
        }
        .onboardInnerContentContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .cardHeadingContent {
            font-size: 18px;
            padding: 4px;
            color: #333333;
          }
          .cardContent {
            font-size: 14px;
            padding: 4px;
            color: #535353;
            line-height: 1.4em;
          }
        }
        .onboardInnerImageParentContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          transform: translateY(-50%);
          padding-left: 10px;
          .onboardInnerImageContainer {
            display: flex;
            align-items: center;
            justify-content: space-around;
            border: 1px solid #e0e3e6;
            width: 72px;
            height: 72px;
            padding: 10px;
            border-radius: 10px;
            &.trueFlag {
              background-color: #ffffff;
              border: 1px solid #e0e3e6;
            }
            .isCompleted {
              position: absolute;
              right: -5px;
              top: -5px;
            }
            .onboardInnerImageItem {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      .onBoardButtons {
        display: flex !important;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 12px 0;
        .watchVideo {
          cursor: pointer !important;
          display: flex;
          align-items: center;
        }
        .showMe {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #3953fb;
          border: 2px solid #3953fb;
          border-radius: 7px;
          cursor: pointer;
          transition: background-color 0.3s ease, color 0.3s ease !important;
          .seqNotFound {
            cursor: not-allowed;
          }
          &:hover {
            background-color: #3953fb;
            color: #ffffff !important;
          }
          button.subContent {
            color: #3953fb;
            border: none;
            background-color: transparent;
            cursor: pointer;
            font-size: 16px !important;
            font-weight: 500;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color 0.3s ease, color 0.3s ease;
            width: 80px;
            padding: 5px 10px;
            &:hover {
              background-color: #3953fb;
              color: #ffffff !important;
            }

            &:disabled {
              cursor: not-allowed;
              color: #3953fb !important;
              background-color: transparent;
              border: none;
            }
            &:disabled:hover {
              color: #ffffff !important;
            }
          }
        }
      }
      .cardHeading {
        padding: 25px 25px;
        color: #ffffff;
        background: #3953fb;
        border-bottom: 1px solid white;
        max-height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 8px 8px 0 0;
        .onboardContent {
          font-size: 16px;
          margin-top: 4px;
        }
        .closeBanner {
          position: absolute;
          margin-top: 4px;
          right: 10px;
          font-size: 22px;
          cursor: pointer;
          padding: 2px;
          border-radius: 50%;
          transition: all 0.5s ease;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background: #b3aeae;
          }
        }
      }
      .cardMessage {
        background: #3953fb;
        color: #ffffff;
        padding: 20px;
        border-bottom: 1px solid white;
        height: 120px;
        .onboardHeading {
          font-size: 22px;
          padding: 0 10px 0;
        }
        .onboardContent {
          font-size: 16px;
          padding: 5px 5px 25px 5px;
          line-height: 25px;
        }
        .watchCreditsContainer {
          display: flex;
          position: absolute;
          width: calc(100% - -2%);
          z-index: 10 !important;
          margin-top: -5px;
          .popupHeader {
            color: #333333;
            background-color: #ffffff;
            border-radius: 10px;
            padding: 15px 20px;
            width: calc(100% - 10%);
            box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.15),
              0 5px 10px rgba(0, 0, 0, 0.1), 0 5px 10px rgba(217, 217, 217, 0.1);
            .completedStatus {
              padding-top: 10px;
              .statusCompletedText {
                color: #333333;
                font-size: 14px;
                padding-top: 10px;
              }
              .statusBar {
                .progress {
                  -webkit-appearance: none;
                  border: none;
                  height: 8px;
                  overflow: hidden;
                  padding: 0;
                  position: relative;
                  width: 100%;
                  border-radius: 6px;
                  background-color: #d9d9d9;
                  .progress-value {
                    background-color: #3953fb;
                    width: 0%;
                    height: inherit;
                    display: block;
                    border-radius: 4px;
                    transition-delay: 1s;
                    transition: 0.5s linear;
                  }

                  &::-webkit-progress-bar {
                    background-color: #e9eef1;
                  }

                  &::-webkit-progress-value {
                    background-color: #2fdc47;
                  }
                }
              }
            }
          }

          .creditsGridWrapper {
            padding: 14px;
            border: 1px solid #ddd;
            background-color: #ffffff;
            color: #333333;
            border-radius: 8px;
            margin: 12px 0;
            width: calc(100% - 10%);
          }
        }
      }
    }
  }
}
