.accountTasks {
  position: relative;
  width: 100%;
  height: calc(100vh - 184px);
  .actionFilterCont {
    position: relative;
    @include flex-center;
    padding-bottom: 10px;
    justify-content: flex-end;
    gap: 10px;
    // Search Component STYLE
    @include searchInputByKeyword();
    .startAllAccountTasks {
      position: relative;
      margin: 0;
      margin-right: 10px;
      padding: 0 12px;
      height: 36px;
      font-size: 14px;
      background: $blue-violet;
      border-radius: $blue-violet;
      &:hover {
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
      }
      &.loading {
        min-width: unset;
      }
      .iconStart {
        margin-right: 2px;
        svg {
          @include wt-ht(22px, 22px);
        }
      }
    }
    .startAllTasksBtnLoader {
      @include wt-ht(120px, 36px);
      border-radius: 5px;
    }
    .filterByDateTab {
      position: relative;
      .filterTitle {
        position: relative;
        font-size: 14px;
        padding-right: 5px;
        color: $c-8d;
        text-transform: capitalize;
      }
      .dateFilter {
        gap: 6px;
        font-size: 14px;
        .tabItem {
          border-radius: 5px;
          border: 1px solid $blue-violet;
          .text {
            font-size: 14px;
          }
        }
      }
      @media (max-height: 640px) {
        .rdrCalendarWrapper {
          top: -82px;
        }
      }
    }
    .dueDateFilterLoader {
      gap: 6px;
    }
  }
}

.singleBanner .accountTasks {
  height: calc(100vh - 224px);
}
