@keyframes bottom-up {
  from {
    bottom: 0px;
    opacity: 0;
  }
  to {
    opacity: 1;
    bottom: 4px;
  }
}

.ScheduleLaterSendEmailBtn {
  position: relative;
  z-index: 10;
  @include flex-center;
  line-height: initial;
  .sendEmailBtn {
    border: solid 1px $blue-violet;
    background: $blue-violet;
    &.btnRoundedLeft {
      min-width: 90px !important;
      @include bor-rad-right(0 !important, 0 !important);
    }
    &.disabled {
      @include disable();
    }
  }
  .scheduleLaterBtn {
    position: relative;
    @include flex-center;
    .scheduleArrowBtn {
      position: relative;
      @include flex-align-center;
      background: #1633ea;
      @include wt-ht(40px, 40px);
      line-height: initial;
      border: solid 1px #1633ea;
      @include bor-rad-right(5px !important, 5px !important);
      cursor: pointer;
      color: white;
      &.disabled {
        @include disable();
      }
      i.rotateArrow {
        transform: rotate(180deg);
        transition: all 0.3s ease-in-out;
      }
    }

    .scheduleLaterDropdown {
      position: absolute;
      bottom: 40px;
      right: 0;
      letter-spacing: 0.1px;
      %btnBox {
        position: relative;
        @include flex-center;
        color: $blue-violet;
        padding: 0 12px;
        font-size: 12px;
        background: white;
        cursor: pointer;
        border: 1px solid $c-dd;
        border-radius: 6px;
        &:not(:last-child) {
          margin-bottom: 6px;
        }
        transition: all 0.3s ease-in-out;
        &:hover {
          box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
        }
      }
      .scheduleBtn {
        @extend %btnBox;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
        @include wt-ht(154px, 36px);
        animation: bottom-up 0.2s linear forwards;
        i {
          font-size: 12px;
          padding-right: 3px;
        }
      }
      .selectScheduleDateOption {
        position: relative;
        display: block;
        @include list-style-zero;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
        border: 1px solid $c-dd;
        background: white;
        border-radius: 6px;
        padding: 0.5rem;
        animation: bottom-up 0.2s linear forwards;
        .scheduleDateItem {
          @extend %btnBox;
          @include wt-ht(220px, 36px);
        }
      }
      .react-datepicker {
        width: 328px;
        right: 1px;
      }
    }
  }
}
