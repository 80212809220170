%relSelfCenter {
  position: relative;
  align-self: center;
}
.datePicker {
  position: relative;
  display: block;
  .dateFilterBtn {
    position: relative;
    @include flex-center();
    padding: 0 10px;
    height: 36px;
    background: $white;
    color: $blue-violet;
    border: solid 1px $tb-bor-btm;
    font-size: 14px;
    border-radius: 5px;
    min-width: 130px;
    cursor: pointer;
    .calendarIcon {
      @extend %relSelfCenter;
      margin-right: 5px;
      @include wt-ht(14px, 14px);
      svg {
        @include wt-ht(14px, 14px);
        fill: $blue-violet;
      }
    }
    .name {
      @extend %relSelfCenter;
      margin-right: 5px;
      height: 14px;
      color: $c-66;
    }
    .value {
      @extend %relSelfCenter;
      margin-right: 5px;
      height: 14px;
      padding-right: 5px;
    }
    i {
      @extend %relSelfCenter;
      font-size: 18px;
    }
  }
  .drop-down-menu{
    right: 0 !important;
  }
}
