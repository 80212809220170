%eventsListPopup {
  ul,
  .eventsList,
  .eventsListLoader {
    @extend %relBlock;
    @include list-style-zero;
    li,
    .eventOption {
      background: white;
      @extend %relBlock;
      padding: 12px;
      @include wt-ht(100%, 64px);
      border: 1px solid $c-dd;
      border-radius: 5px;
      transition: all 0.3s ease;
      &:hover:not(.active, .disabled) {
        cursor: pointer;
        border-color: $blue-violet;
      }
      &.active {
        cursor: unset;
        pointer-events: none;
        user-select: none;
        border: 1px solid $blue-violet;
        .name {
          color: $blue-violet;
        }
      }
      &.disabled {
        cursor: unset;
        pointer-events: none;
        user-select: none;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .name {
        position: relative;
        font-size: 16px;
        @include ellipsis;
        font-weight: 600;
        color: $c-1e;
        &.linear-background {
          @include wt-ht(80%, 18px);
        }
      }
      .duration {
        position: relative;
        display: flex;
        align-items: flex-start;
        margin-top: 6px;
        .scheduleIcon {
          position: relative;
          color: $blue-violet;
          font-size: 12px;
          margin-right: 3px;
          &.linear-background {
            @include wt-ht(14px, 14px);
            border-radius: 35px;
          }
        }
        .time {
          position: relative;
          color: #0b004e;
          font-weight: 300;
          font-size: 11px;
          &.linear-background {
            @include wt-ht(50px, 14px);
          }
        }
      }
      &.selectedEvent {
        cursor: unset;
        height: auto;
        border-color: $blue-violet;
        &:hover {
          border-color: $blue-violet;
          .name {
            color: #343a40;
          }
        }
        .meetingDuration,
        .timezoneInfo {
          position: relative;
          display: grid;
          grid-template-columns: 15px auto;
          align-items: flex-start;
          margin-top: 10px;
          i {
            position: relative;
            color: #343a40;
            font-size: 12px;
            margin-right: 3px;
          }
          .text {
            position: relative;
            color: #343a40;
            font-weight: 300;
            font-size: 11px;
          }
        }
      }
    }
  }
  .eventsListLoader {
    @extend %relBlock;
    li:hover {
      cursor: unset;
      pointer-events: none;
      user-select: none;
      border-color: $c-dd !important;
    }
  }
  .noEventFound {
    position: relative;
    @include flex-align-center;
    .text {
      font-size: 14px;
      position: relative;
      color: $c-66;
    }
  }
}
