.attachmentFileList {
  position: relative;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  .fileCard {
    position: relative;
    flex: 1 0 80px;
    max-width: 80px;
    border: 1px solid $c-e7;
    border-radius: 8px;
    box-shadow: 1.7px 2.5px 3px 0 rgba(124, 130, 165, 0.08);
    margin: 2px;
    .fileIcon {
      position: relative;
      height: 60px;
      @include flex-center();
      justify-content: center;
      img {
        width: 32px;
      }
      svg {
        @include wt-ht(24px, 24px);
      }
    }
    .fileDetails {
      @extend %relBlock;
      background: $c-f2;
      padding: 5px;

      @include bor-rad-btm(10px, 10px);
      border-top: 1px solid $c-e7;
      .fileName {
        color: $c-5b;
        font-size: 12px;
        margin-bottom: 2px;
        @include ellipsis();
      }
      .fileSize {
        font-size: 10px;
        color: $c-5b;
      }
    }
  }
}
