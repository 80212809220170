.successPopup {
  @extend %popupCard;
  .card {
    width: 400px;
    text-align: center;
    margin: 0 auto;
    img {
      margin: 30px 0 30px auto;
      max-width: 100px;
    }
    h6 {
      color: $c-33;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 25px;
    }
    p {
      color: $c-33;
      margin-bottom: 20px;
    }
    .failedCountText {
      color: $c-87;
      font-size: 14px;
      margin-bottom: 20px;
      line-height: 1.3;
    }
    button {
      background: $blue-violet;
      min-width: 130px;
      margin: 10px auto;
    }
  }
  .successPopupOverlay {
    @include bg-mask(99, rgba(0, 0, 0, 0.57));
  }
}
