.reportsSwitchVersionCont {
  position: relative;
  height: 60px;
  padding: 5px;
  margin-top: 6px;
  .switchVersionInnerCont {
    @include flex-center;
    justify-content: space-between;
    padding: 10px;
    height: 100%;
    border-radius: 5px;
    background: $white;
    .displayTextCont {
      @include flex-align-center;
      height: 100%;
      .displayText {
        font-size: 14px;
        color: #3b3b3b;
        margin-left: 10px;
      }
    }
    .btnCont {
      @include flex-center;
      .redirectionBtn {
        height: 26px;
        font-size: 12px;
        @extend %primaryBlueBtn;
        min-width: 130px;
        border-radius: 5px;
        color: $white;
        background: #28ad56 !important;
        border: 1px solid #28ad56;
        margin: unset;
        height: 28px;
        &.versionOneBtn {
          border: 1px solid $c-dd;
          color: #3b3b3b;
          background: $white !important;
        }
      }
      .closeSvgIcon {
        position: relative;
        padding: 3px 0 3px 10px;
        @include flex-center;
        svg {
          width: 14px;
          height: 14px;
          cursor: pointer;
          fill: #3b3b3b !important;
          path {
            fill: #3b3b3b !important;
          }
        }
      }
    }
  }
}
