.pageLimit {
  @include flex-center;
  position: relative;
  margin-right: 8px;
  width: fit-content;
  .text {
    font-size: 16px;
    color: $c-7e;
    margin-right: 5px;
  }
  .wrapIconValue {
    @include flex-center();
    position: relative;
    @include wt-ht(70px, 36px);
    background: white;
    border-radius: 5px;
    border: 1px solid $c-eb;
    cursor: pointer;
    &:hover {
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
    }
    .value {
      position: relative;
      top: 2px;
      width: 50px;
      text-align: center;
      color: #9ea6fd;
      font-weight: 600;
    }
    .arrow {
      position: relative;
      top: 2px;
      width: 20px;
      position: relative;
      transition: all 0.3s ease;
      svg {
        fill: $c-53;
        @include wt-ht(11px, 11px);
      }
      &.activeArrow {
        top: 0px;
        text-align: right;
        transform: rotate(-180deg);
        transition: all 0.3s ease;
      }
    }
  }
  ul,
  .limitDropdown {
    @include list-style-zero;
    position: absolute;
    right: 0;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
    padding: 5px 0;
    z-index: 12;
    width: 70px;
    top: 32px;
    opacity: 1;
    border: 1px solid $c-eb;
    transition: opacity 0.3s ease;
    .limitValue {
      position: relative;
      @include flex-center;
      justify-content: center;
      color: $c-66;
      padding: 0px 10px;
      height: 32px;
      font-size: 15px;
      cursor: pointer;
      &.activeLimit {
        color: $blue-violet;
        cursor: unset;
        background: #f3f9fa;
        font-weight: 500;
      }
      &:hover:not(.activeLimit) {
        background: $c-f5;
        color: $c-44;
      }
    }
  }
}

.pageLimitOverlay {
  @include bg-mask(11, transparent);
}
