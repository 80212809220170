.fileLimitExceedPopup {
  @extend %popupCard;
  z-index: 100003;
  .card {
    width: 500px;
    .cardBody {
      padding: 11px 0 10px;
      .fileUploadImg {
        position: relative;
        padding-bottom: 10px;
        text-align: center;
        img {
          margin: 0 auto;
          @include wt-ht(auto, 140px);
        }
      }
      h4 {
        text-align: center;
        margin-bottom: 15px;
        font-size: 18px;
        color: $c-33;
      }
      p {
        text-align: center;
        font-size: 14px;
        line-height: 1.5;
        color: $c-66;
        margin-bottom: 20px;
        b {
          color: $c-44;
        }
      }
      .attachmentTryAgainBtn {
        @extend %primaryBlueBtn;
        min-width: 130px;
      }
    }
  }
}
.overlayFileAttachemntExceedMask {
  @include bg-mask(100002, rgba(0, 0, 0, 0.16));
}
