.bulkAccountStageChangeComp {
  z-index: 19;
  position: fixed;
  @include wt-ht(100%, 100%);
  @include flex-align-center();
  user-select: none;
  inset: 0;
  &.hideListPopup {
    display: none;
  }
  .card {
    position: relative;
    z-index: 20;
    background: white;
    @include wt-ht(440px, auto);
    border-radius: 5px;
    padding: 20px;
    .cardHeader {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      border-bottom: 1px solid $b-c-1;
      .titleContainer {
        .title {
          position: relative;
          font-size: 20px;
          text-align: left;
          color: $c-3b;
        }
        .contactSelectedCont {
          color: $blue-violet;
          font-size: 12px;
          margin-top: 10px;
        }
      }
      i.close {
        font-size: 18px;
        color: $c-66;
        border-radius: 35px;
        padding: 3px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: $c-f5;
          color: $c-33;
        }
      }
    }
    .cardBody {
      position: relative;
      .accountListCont {
        border: 1px solid $b-c-1;
        border-radius: 6px;
        margin-top: 12px;
        .radioBtnCont {
          padding: 10px;
          border-bottom: 1px solid $b-c-1;
          &:last-child {
            border-bottom: none;
          }
          label {
            font-size: 14px;
            color: $c-3b;
          }
        }
      }
      .buttonActionList {
        @include flex-center;
        justify-content: space-between;
        margin-top: 10px;
        .cancelButton {
          @extend %primaryBlueBtn;
          min-width: 190px;
          margin-right: 10px;
          border-radius: 5px;
          color: $blue-violet;
          background: white;
          border: 1px solid $blue-violet;
          margin: 0 12px 0 0;
          &:hover {
            background: white;
          }
        }
        .updateButton {
          @extend %primaryBlueBtn;
          min-width: 190px;
          border-radius: 5px;
          background: $blue-violet;
          margin: unset;
          &.loading {
            transition: all 0.3s ease-in-out;
            min-width: auto;
            border-radius: 35px;
            margin-right: 60px;
            .load-text {
              @include wt-ht(30px, 30px);
              top: 4px;
              left: 4px;
            }
          }
        }
      }
    }
  }
  .bgOverlay {
    @include bg-mask(19, rgba(0, 0, 0, 0.57));
  }
}
