.progressContainer {
  @include flex-center;
  flex-direction: column;
  padding: 10px;
  background-color: $c-fa;
  border-bottom: 1px solid $b-c-1;
  height: fit-content;

  .progressMessage {
    text-align: center;
    margin-bottom: 8px;

    h2 {
      font-size: 13px;
      color: #343a40;
      font-weight: 400;
      margin: 0;
    }

    p {
      font-size: 12px;
      color: #8d8d8d;
      margin-top: 4px;
    }
  }

  .linearProgressBar {
    width: 32%;
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 15px;
    overflow: hidden;
    position: relative;

    .backgroundProgress {
      width: 100%;
      height: 100%;
      background-color: #ddd;
      background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
      );
      animation: move 8s linear infinite;
      background-size: 40px 40px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .linearProgress {
      height: 100%;
      background: linear-gradient(to right, #1f8dd5, #01b8d4, #3953fb);
      border-radius: 15px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 0; /* Start with 0 width and animate to progress */
      &.applyTransition {
        transition: width 2s ease-in-out;
      }
    }
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}
