.youtubePlayer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 31;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  .youtubeVideoCont {
    z-index: 5;
    @include flex-center();
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;

    &.loadingVideo {
      .videoParent {
        display: none;
      }
    }

    .videoParent {
      padding: 15px;
    }

    .loadingTxt {
      z-index: 5;
      color: $white;
    }
  }

  iframe {
    display: block;
    margin: 0 auto;
    border-radius: 10px;
  }

  .closeButton {
    margin: 15px 0px;
  }

  .videoLoaderPopup {
    .ball-container {
      top: 50%;
      height: auto;
    }
  }
}

.videoOverlayMask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.64);
}
