.addMyOwnNumberPopup {
  height: 100%;

  .ownNumberPopupWrapper {
    height: 300px;
    min-width: 400px !important;
    border-radius: 10px;
    background: #fff;
    padding: 25px;

    .newNumberCont {
      height: 100%;

      .popupHeader {
        @include flex-center();

        .popupTitle {
          color: $c-53;
          font-size: 16px;
          width: 80%;
          display: inline-block;
          font-weight: 600;
        }
      }

      .popupBody {
        padding-top: 25px;
        height: 100%;

        .enterNumberText {
          font-size: 14px;
          color: $c-66;
        }

        .numberInputCont {
          margin-top: 10px;
        }

        .telephonicInputCont {
          @include flex-center();

          .flag-dropdown {
            .selected-flag {
              background: transparent;
              border: 0;
              padding: 0;
              cursor: pointer;
            }

            .country-list {
              width: 300px !important;
              height: 140px !important;
              border-radius: 5px;

              .country-name,
              .dial-code {
                font-family: $font-regular, sans-serif;
                font-size: 13px;
                color: $c-66;
              }
            }
          }

          .form-control {
            border: 0;
            box-shadow: none;
            padding-left: 30px;
            width: 95%;
            color: $blue-violet;
            border-radius: 0;
            border-bottom: 1px solid $c-ec;
          }

          .extensionWrapper {
            width: 25%;
            padding-left: 5px;

            .inputBox {
              line-height: 25px;
              font-size: 15px;
              will-change: font-size;
              width: 100%;
              -webkit-appearance: none;
              color: #3953fb;
              border: none;
              border-bottom: 1px solid #f3f4f8;
            }
          }
        }

        .infoCont {
          .infoText {
            font-size: 11px;
            color: $c-66;
            font-style: italic;
            padding-top: 10px;
            @include flex-direction(row);

            .infoIconTp {
              padding-right: 5px;

              i {
                font-size: 15px;
                color: $c-66;
              }
            }
          }
        }

        .actionBtn {
          width: calc(100% - 50px);
          @include flex-direction(row);
          padding-top: 20px;
          position: absolute;
          bottom: 25px;
          left: 25px;
          right: 25px;
          justify-content: space-evenly;

          .backCont {
            background-color: $white;
            border: solid 1px $tb-bor-btm;
            color: $blue-violet;
          }

          .btn {
            &.disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
