@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.sgHeader {
  position: fixed;
  top: 0;
  background-color: #3953fb;
  height: 50px;
  width: 100%;
  z-index: 12;
  @include flex-center;
  justify-content: space-between;
  padding-left: 10px;
  .rightSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .flexWrapper {
      @include flex-center;
      align-items: center;
      margin: 0 6px;
      width: fit-content !important;
      border-radius: 5px;
      padding: 0 6px;
      cursor: pointer;
      color: $white;
      &.creditsUsage {
        z-index: 14;
      }
      &.hovered {
        background-color: #203adc;
      }
    }
    .itemIcon {
      width: 10%;
      margin: 0 6px;
      cursor: pointer;
      @include wt-ht(26px, 26px);
      @include flex-center;
      justify-content: center;
      border-radius: 5px;
      &:hover {
        background-color: #203adc;
      }
      svg {
        @include wt-ht(14px, 14px);
      }
    }
    .itemIcon.phoneCall {
      svg {
        @include wt-ht(12px, 12px);
      }
    }
    .itemIcon.creditsUsage {
      margin: 0;
      svg {
        @include wt-ht(20px, 20px);
      }
    }
    .globalRequestDemoBtn {
      background-color: transparent;
      border: 1px solid $white;
      color: $white;
      height: 28px;
      cursor: pointer;
      margin-right: 12px;
    }
  }
  .leftSection {
    position: relative;
    @include flex-center;
    gap: 5px;
    .sideBarlogo {
      position: relative;
      height: 32px;
      svg {
        @include wt-ht(32px, 32px);
        fill: white;
        path {
          fill: white;
        }
      }
    }
  }
}

/* ---chmln--- */
.chmln-helpbar-footer-left {
  display: none !important;
}
.chmlnSearch {
  position: relative;
}
#chmln .chmln-helpbar-widget-trigger {
  z-index: 12 !important;
}
/* ---end --- */

.userMenuIconAction {
  position: relative;
  @include flex-center;
  margin: 0 14px;
  cursor: pointer;
  height: 40px;
  .userIcon {
    position: relative;
    @include flex-align-center;
    text-transform: uppercase;
    cursor: pointer;
    @include wt-ht(36px, 36px);
    border-radius: 35px;
    background: #959cf2;
    color: $white;
    font-size: 14px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    line-height: 1;
  }

  .drop-down-menu {
    top: 22px;
    border-top-left-radius: 130px;
    padding: 16px 0 0;
    background: transparent;
    border: none;
    ul {
      margin: 0 !important;
      background: white;
      padding: 5px 0;
      border-radius: 6px;
      top: -5px;
      border: 1px solid $c-ea;
      li {
        height: 30px;
        padding: 0 10px;
        &:nth-child(2) {
          .itemName {
            top: 2px !important;
          }
        }

        .listItem .listItemWrap {
          width: 140px;
          .svg-wrapper svg {
            @include wt-ht(12px, 12px);
          }
          .icon-holder {
            margin-right: 10px !important;
          }
          .listItem i {
            font-size: 18px;
            color: $c-3b;
          }
        }
        &:hover {
          background-color: #eff2ff !important;
          color: $blue-violet;
          svg,
          svg path {
            fill: $blue-violet !important;
          }
          .itemName {
            color: $blue-violet;
          }
        }
      }
    }
  }
  .dropdownOverlay {
    @include bg-mask(1, transparent);
  }
}
.addStepDropdown {
  #linkedinAuto,
  #linkedin {
    .itemName {
      top: 2px;
      position: relative;
    }
  }
}
