.outboxPage {
  width: 100%;
  height: 100%;
  background: $c-f5f8;
  border-color: $bg-default;
  * {
    box-sizing: border-box;
  }
  .breadCrumbWrapper {
    @include flex-center();
    position: relative;
    .infoIcon {
      font-size: 16px;
      cursor: pointer;
      margin-left: 8px;
    }
    .customTooltip {
      .tooltipBody {
        background-color: $c-4a;
        box-shadow: unset;
        right: -409px;
        top: -27px;
        width: 400px;
      }
      .tooltipText {
        color: $white;
        a,
        #linkToSchedules {
          color: $white;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .sharpPointer {
        background-color: $c-4a;
        border-right: 1px solid $c-4a;
        border-top: 1px solid $c-4a;
        z-index: 0;
        top: -10px;
        right: -20px;
      }
    }
  }
  .outboxWrapper {
    position: relative;
    display: grid;
    padding: 12px;
    @include wt-ht(100%, calc(100% - 24px));
    grid-template-columns: 100%;
    &.rightSideBar {
      display: flex;
      .outboxContentWrapper {
        flex: 1;
      }
    }
    .rightPanel .rightSideBarWrapper {
      height: 100%;
    }
    &.showOutboxFilter {
      transition: all 3s ease-in-out;
      justify-content: space-between;
      column-gap: 12px;
      grid-template-columns: calc(100% - 312px) 300px;
    }
  }
  .outboxContentWrapper {
    position: relative;
    display: block;
    overflow: hidden;
    height: 100%;
    font-size: 14px;
    .headerContents {
      position: relative;
      width: 100%;
      padding: 10px 0px;
      display: flex;
      height: 56px;
      justify-content: space-between;
      .leftHeaderContents {
        @include flex-center;
      }
      .rightHeaderContents {
        @include flex-center;
        .outboxFilterIconToggle {
          position: relative;
          @include flex-center();
          height: 36px;
          width: 100px;
          &.disabled {
            @include disable(0.5, unset);
            svg {
              fill: $c-66;
            }
            .text {
              color: $c-66;
            }
          }
          &:hover {
            box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
          }
          background: white;
          border: 1px solid $b-c-1;
          border-radius: 5px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          svg {
            @include wt-ht(14px, 16px);
            fill: $blue-violet;
            margin: 0 10px;
          }
          .text {
            font-size: 14px;
            padding-right: 6px;
            padding-top: 4px;
            color: $blue-violet;
          }
          .count {
            @include flex-center;
            justify-content: center;
            background: white;
            color: $blue-violet;
            font-size: 14px;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            @include wt-ht(24px, 32px);
            padding-top: 4px;
          }
          &.filterUsed {
            transition: all 0.3s ease-in-out;
            background: $blue-violet;
            border: 1px solid $blue-violet;
            svg {
              fill: white;
            }
            .text {
              color: white;
            }
          }
        }
        .exportBtnWrapper {
          margin-left: unset;
          margin-right: 10px;
        }
      }
    }
    .filterContentsWrapper {
      position: relative;
      background: transparent;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(13, calc(100% / 9 - 10px));
      grid-gap: 10px;
      overflow-x: auto;
      padding: 0px 0 8px;
      border-bottom: 1px solid $c-ee;
      grid-auto-flow: column;
      .linear-background {
        transition: all 200ms ease;
        height: 67px;
        border-radius: 6px;
        @include flex-center();
      }
      .filterContainer {
        width: inherit;
        @include flex-center();
        justify-content: center;
        background-color: $white;
        border: 1px solid $c-eb;
        border-radius: 5px;
        width: 100%;
        padding: 10px 8px;
        transition: background-color 200ms ease;
        box-shadow: 0px 4px 6px 0.7px rgba(59, 59, 59, 0.07);
        cursor: pointer;
        &.active {
          border-top: 4px solid $c-ff9 !important;
          pointer-events: none;

          .filterValue {
            color: $c-ff9;
            font-weight: bold;
          }
          &:hover {
            border: 0;
            border-top: 3px solid #ff9066;
          }
        }
        &:hover {
          border: 1px solid #c4c4c4;
        }
        .filterOption {
          @include flex-center();
          justify-content: space-between;
          flex-direction: row;
          position: relative;
          width: 100%;
          .filterValueCont {
            display: grid;
            text-align: left;
            grid-gap: 5px;
            overflow: hidden;
            font-size: 14px;
            width: 100%;
            .flexValue {
              @include flex-center;
              justify-content: space-between;
              .liIcon svg {
                @include wt-ht(14px, 14px);
                fill: #0a66c2;
                position: relative;
              }
            }
            .filterValue {
              font-size: 18px;
              font-weight: bold;
            }
            .filterLabel {
              font-size: 14px;
              color: $c-97;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  .outBoxEmailList {
    border-radius: 10px;
    height: calc(100% - 170px);
    background-color: $white;
  }
  .outboxCardList {
    background-color: $white;
    height: calc(100% - 50px);
    overflow: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .outboxHeader {
      position: relative;
      display: grid;
      grid-template-columns: 2% 20% 38% 10% 10% 10% 4%;
      column-gap: 1%;
      padding: 12px;
      font-size: 14px;
      align-content: center;
      border: 1px solid #eaeaea;
      background: #fafafa;
      height: 44px;
      &.reduceMsgWidth {
        grid-template-columns: 2% 20% 20% 10% 10% 10% 4%;
      }
      &.adjustCheckboxWidth {
        grid-template-columns: 20% 38% 12% 10% 10% 5%;
      }
      .checkBoxSection {
        position: relative;
        margin-bottom: 5px;
      }
      .toAddress,
      .email,
      .sequenceName {
        @include flex-center;
      }
      .sequenceName {
        @include flex-center;
        margin-left: 8px;
      }

      .date,
      .time,
      .owner {
        @include flex-align-center;
      }
    }
    .outboxNoData {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 16px;
      text-transform: capitalize;
    }
    &.adjustPaginationSpace {
      height: calc(100% - 52px);
      transition: all 0.3s;
      @include bor-rad-btm(0, 0);
    }
    &.adjustTableSpace {
      height: 100%;
      transition: all 0.3s;
    }
    .pagination-section {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .outboxCardWrapper {
      grid-template-columns: 20% 38% 12% 10% 10% 5%;
      column-gap: 1%;
      border-bottom: 1px solid $c-ea;
      cursor: pointer;
      padding: 12px;
      display: grid;
      align-items: center;
      &.inboxMailUnread {
        font-weight: bold;
        font-family: Graphik-Medium;
      }
      &.adjustWidth {
        grid-template-columns: 2% 20% 38% 10% 10% 10% 4%;
      }
      &.isActiveCard {
        background-color: $c-f5;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .checkboxBtn {
      position: relative;
      display: block;
      width: 32px;
      text-align: center;
      .linear-background {
        display: block;
        width: 18px;
        border-radius: 6px;
      }
    }
    &.loading {
      grid-template-columns: 25% 30% 15% 10% 10% 5%;
    }
    .recipientInfoSection {
      position: relative;
      font-size: 14px;
      @include ellipsis();
    }
    .content {
      @include ellipsis();
    }

    .recipientInfo,
    .subject {
      font-size: 14px;
      @include flex-center;
      white-space: nowrap;
      svg {
        margin-right: 8px;
        &:hover {
          transition: all 0.9s;
        }
      }
    }
    .recipientInfo {
      .flexCenter {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
        top: 2px;
        width: unset;
        max-width: 290px;
      }
      svg {
        @include wt-ht(12px, 12px);
        object-fit: contain;
      }
      .recipientEmail {
        @include ellipsis();
        &:hover {
          color: $blue-violet;
          text-decoration: underline;
        }
      }
      .recipientCount {
        position: relative;
        color: $c-66;
        font-size: 14px;
        padding-left: 2px;
        top: 2px;
      }
    }
    .emailInfoWrapper {
      position: relative;
      display: grid;
      align-items: center;
      grid-template-columns: 28px calc(100% - 28px);
      .subjectIcon {
        position: relative;
        @include flex-center;
        svg {
          @include wt-ht(24px, 25px);
          flex: 0 0 18px;
          position: relative;
        }
        .liIcon svg {
          @include wt-ht(14px, 14px);
          position: relative;
          top: 3px;
        }
        .editScheduleDate {
          position: absolute;
          padding: 2px;
          right: 0;
          top: 10px;
          z-index: 1;
          .orangeDot {
            @extend %relBlock;
            padding: 3px;
            @include wt-ht(6px, 6px);
            background: #f59064;
            border-radius: 50%;
          }
          .customTooltip {
            top: 27px;
            left: 9px;
            .sharpPointer {
              background: $black;
              padding: 6px;
              top: unset;
              bottom: 36px;
              border-top-right-radius: 50px;
              right: 5px;
              border-color: $black;
              &.firstCard {
                bottom: 12px;
                border-radius: 0px;
              }
            }
            .tooltipBody {
              background: $black;
              width: 160px;
              top: unset;
              bottom: 40px;
              border-radius: 5px;
              left: unset;
              right: -66px;
              &.firstCard {
                bottom: -6px;
              }
              .tooltipText {
                color: $white;
                font-size: 12px;
                padding: 4px;
              }
            }
          }
        }
      }
      .emailInfoCont {
        @include flex-center;
        overflow: hidden;
        text-overflow: ellipsis;
        top: 2px;
        width: 100%;
        .seperatorHyphen {
          margin: 0 8px;
        }
        .autoReplyBadge {
          min-width: 120px;
          margin-left: 12px;
        }
        &.autoReplyTypeCont {
          .subject {
            @include ellipsis;
            max-width: 400px;
          }
        }
      }
      .content {
        @include ellipsis;
        max-width: 440px;
      }
    }
    .sequenceSection {
      position: relative;
      width: 100%;
      .sequenceNamecont {
        border-radius: 15px;
        border: 1px solid $c-ea;
        padding: 5px 10px;
        text-decoration: none;
        color: $c-66;
        max-width: 100%;
        font-size: 14px;
        cursor: pointer;
        width: fit-content;
        @include ellipsis();
        transition: all 0.3s ease-in-out;
        &:hover {
          color: $blue-violet;
          background: $c-f5;
        }
      }
    }
    .recipientEmail {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 90%;
      white-space: nowrap;
    }
    .contactOwner {
      @include flex-center();
      justify-content: center;
      .circleOwner {
        @include flex-center;
        justify-content: center;
        @include wt-ht(36px, 36px);
        border-radius: 50%;
        padding-top: 2px;
        border: 1px solid $blue-violet;
        color: $blue-violet;
        text-transform: uppercase;
        font-size: 12px;
      }
    }
    .editIcon {
      @include flex-align-center;
      .svg {
        font-size: 14px;
      }
    }
  }
  .showEmptyTable .outBoxEmailList {
    height: calc(100% - 114px);
    .outboxCardList {
      overflow: unset !important;
    }
  }
}
.outboxCardWrapper {
  &.outboxCardLoading {
    .recipientInfo .flexCenter.linear-background {
      width: 80px;
      margin-right: 8px;
      top: 0;
    }
    .emailScheuledInfoLoader {
      display: grid;
      align-items: center;
      grid-template-columns: 30% 65%;
      column-gap: 5%;
      margin-left: -30px;
    }
    .icon.linear-background {
      @include wt-ht(20px, 20px);
      border-radius: 50%;
      margin-right: 8px;
    }
    .recipientEmail {
      width: 150px;
    }
    .subject .flexCenter.linear-background {
      width: 80%;
      align-items: center;
      margin-right: 8px;
    }
    .contactOwner .linear-background {
      @include wt-ht(36px, 36px);
      border-radius: 50%;
    }
    .sequenceName {
      width: 100%;
      height: 14px !important;
    }
    .buttonWrapper {
      width: 150px;
      margin-right: 30px;
    }
    .scheduledDate,
    .scheduledTime {
      @include flex-align-center;
      .flexCenter {
        width: 55px;
      }
    }
  }
}

// email schedule date and time styling

.scheduledDate,
.scheduledTime {
  @include flex-center();
  justify-content: center;
  @include wt-ht(100%, 100%);
  svg {
    margin-right: 8px;
    @include wt-ht(14px, 14px);
    object-fit: contain;
    &:hover {
      transition: all 0.9s;
    }
  }
  .scheduledDate,
  .scheduledTime {
    &.scheduledEmail {
      svg {
        @include wt-ht(18px, 18px);
      }
    }
  }
  .scheduledDate {
    margin-right: 10px;
    .flexCenter {
      position: relative;
      bottom: -1px;
    }
  }
  .scheduledTime {
    .flexCenter {
      position: relative;
      bottom: -1px;
    }
  }
}

.outboxCardPopupWrapper {
  z-index: 9999999;
  position: fixed;
  @include wt-ht(100%, 100%);
  @include flex-center();
  justify-content: center;
  user-select: none;
  .outboxCardPopupContainer {
    position: relative;
    z-index: 20;
    background: white;
    @include wt-ht(778px, auto);
    border-radius: 5px;
    padding: 10px 15px 15px;
    .outboxPopupHeader {
      position: relative;
      display: grid;
      grid-template-columns: calc(65% - 30px) calc(35% - 30px) auto;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $c-dd;
      height: 55px;
      &.replyHeader {
        grid-template-columns: 30px calc(60% - 60px) calc(40% - 30px) auto;
      }
      .backBtn,
      .closeLg {
        cursor: pointer;
        font-size: 24px;
        border-radius: 35px;
        padding: 5px;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: $c-f5;
        }
      }
      .backBtn {
        padding: 0px;
        margin-right: 8px;
      }
      .subjectBlock {
        position: relative;
        @include flex-center;
        .labelName {
          font-size: 16px;
          color: $c-3b;
          padding-right: 5px;
        }
        .subjectText {
          font-size: 16px;
          color: $blue-violet;
          font-weight: 600;
          @include ellipsis();
          width: calc(100% - 63px);
        }
      }
      .emailSequenceInsight {
        justify-self: flex-end;
        ul li:last-child {
          .icon-tag {
            margin-right: 0px !important;
          }
          .customTooltip .sharpPointer {
            right: -50px !important;
          }
        }
      }
    }
    .outboxPopupContent {
      position: relative;
      padding: 10px 0;
      a[href*="/unsubscribe/"],
      a[href="{{unsubscribeLink}}"] {
        pointer-events: none;
      }
      .fromEmailDetails {
        position: relative;
        @include flex-center;
        justify-content: space-between;
        margin: 5px 0 8px;
        .fromAddress {
          position: relative;
          @include flex-center;
          font-size: 14px;
          .labelName {
            color: $c-3b;
            font-size: 14px;
            padding-right: 5px;
          }
          a.fromEmailText {
            font-size: 14px;
            color: $blue-violet;
            text-decoration: none;
            cursor: pointer;
          }
        }
        .dateDetails {
          position: relative;
          @include flex-center;
          color: $c-8d;
          .eventDate {
            align-self: flex-end;
            font-size: 12px;
            padding-right: 2px;
          }
        }
      }
      .toAddress,
      .bccAddress,
      .ccAddress {
        position: relative;
        @include flex-center;
        justify-content: flex-start;
        .labelName {
          color: $c-3b;
          font-size: 14px;
          padding-right: 5px;
        }
        .toEmailText {
          position: relative;
          display: flex;
          align-items: flex-end;
          flex-flow: wrap;
          column-gap: 6px;
          row-gap: 5px;
          a.chipEmail {
            position: relative;
            font-size: 12px;
            @include flex-center;
            line-height: inherit;
            border: 1px solid $c-ea;
            border-radius: 12px;
            color: $c-3b;
            padding: 5px 10px;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }

      .toEmailDetails {
        position: relative;
        display: grid;
        grid-template-columns: 70% 30%;
        justify-content: space-between;
        margin: 5px 0;
        .wrapAddressList div:not(:last-child) {
          margin-bottom: 8px;
        }
        .emailStatusData {
          position: relative;
          @include flex-center;
          justify-content: flex-end;
          align-self: flex-start;
          .viewReply {
            cursor: pointer;
            color: $blue-violet;
            font-size: 12px;
            position: relative;
            margin-right: 10px;
            border-radius: 12px;
            padding: 2px 8px;
            transition: all 0.3s ease-in-out;
            &:hover {
              background: $c-f5;
            }
          }
          .outboxEmailStatusWrapper {
            position: relative;
            @include flex-center();
            .customTooltip {
              z-index: 1;
              .tooltipBody {
                background-color: $c-4a;
                box-shadow: unset;
                right: -132px;
                top: 25px;
                width: 400px;
              }
              .tooltipText {
                color: $white;
                a,
                #linkToSchedules {
                  color: $white;
                  text-decoration: underline;
                  cursor: pointer;
                }
              }
              .sharpPointer {
                background-color: $c-4a;
                border-right: 1px solid $c-4a;
                border-top: 1px solid $c-4a;
                z-index: 0;
                top: 18px;
                right: 37px;
              }
            }
            svg {
              @include wt-ht(14px, 14px);
              margin-right: 5px;
            }
            .scheduledText {
              font-size: 13px;
              text-transform: capitalize;
            }
          }
        }
      }
      .emailContent {
        position: relative;
        padding: 20px;
        margin-top: 10px;
        overflow: auto;
        border: 1px solid $c-dd;
        border-radius: 5px;
        min-height: 240px;
        max-height: 320px;
        color: $c-3b;
        font-size: 13px;
        white-space: pre-wrap;
        .ql-editor {
          padding: 0;
          white-space: unset !important;
        }
      }
    }
    .btnCont {
      display: flex;
      justify-content: flex-end;
      .editEmailBtn {
        font-size: 14px;
        padding: 10px 0;
        border-radius: 5px;
        letter-spacing: 0.3px;
        min-width: 120px;
        margin: unset;
        border: solid 1px $blue-violet;
        color: $blue-violet;
        background: white;
      }
    }
    .outboxPopupFooter {
      position: relative;
      @include flex-center;
      justify-content: flex-end;
      font-size: 12px;
      .stageNumber {
        position: relative;
        color: $c-3b;
        @include flex-center;
        justify-content: center;
        border: 1px solid $c-ea;
        border-radius: 12px;
        padding: 5px 10px;
      }
      .sequenceName {
        position: relative;
        cursor: pointer;
        color: $blue-violet;
        border: 1px solid $c-ea;
        border-radius: 12px;
        padding: 5px 10px;
        margin-right: 5px;
        @include ellipsis();
        width: fit-content;
        max-width: 240px;
      }
    }
  }
}
