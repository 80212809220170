.safetySettingsWrapper {
  .contactAdminPopup {
    @include adminPopup();
  }
  .adminPopupMask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 199;
    background: #333;
    opacity: 0.75;
  }

  .safetySettingsContainer {
    max-width: 80%;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    .safetySettingsTitle {
      font-size: 16px;
      color: $c-66;
      padding-bottom: 20px;
      font-family: $font-regular;
      font-weight: 400;
    }
    .checkboxWrapper {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .numberInputBox,
    .trackingWrapper {
      margin-bottom: 30px;
      input {
        max-width: 500px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .errMsgText {
        bottom: -20px;
      }
    }
    .saveSafetySettings {
      padding: 10px 20px;
      background: $blue-violet;
      margin: 10px 0 auto;
      cursor: pointer;
      font-weight: 500;
      min-width: 130px;
      height: 40px;
      letter-spacing: 0.3px;
      &.disableBtn {
        color: $c-cc;
        background: transparent;
        border: 1px solid $c-cc;
        cursor: not-allowed;
        pointer-events: none;
        font-weight: 500;
      }
      &.loading {
        @include wt-ht(40px !important, 40px !important);
        transition: all 0.3s ease;
        border-radius: 50%;
        min-width: 40px;
        @include flex-center();
        position: relative;
      }
    }
  }
}
