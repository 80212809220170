.wrapAddTolistBtnPopup {
  @extend %relBlock;
  .addContactsToList {
    position: relative;
    @include wt-ht(36px, 36px);
    @include flex-align-center;
    border-radius: 5px;
    border: 1px solid $b-c-1;
    background: white;
    padding: 0 10px;
    margin-left: 10px;
    cursor: not-allowed;
    user-select: none;
    i {
      font-size: 24px;
      fill: $c-9e;
      opacity: 0.7;
    }
    .customTooltip {
      .sharpPointer {
        border-right: 1px solid $c-33;
        border-top: 1px solid $c-33;
        background-color: #393a3d;
        top: -38px;
        right: 2px;
      }
      .tooltipBody {
        background-color: #393a3d;
        border-radius: 5px;
        width: 100px;
        top: -62px;
        right: -40px;
        &.bulkAddToList {
          width: 275px;
          right: -120px;
        }
      }
      .tooltipText {
        color: $white;
        padding: 8px;
        display: block;
        text-align: center;
      }
    }
    &.enabled {
      cursor: pointer;
      @extend %enabledBtn;
      &:hover {
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
      }
    }
  }
  .addListPopup {
    z-index: 19;
    position: fixed;
    @include wt-ht(100%, 100%);
    @include flex-align-center();
    user-select: none;
    inset: 0;
    &.hideListPopup {
      display: none;
    }
    .card {
      position: relative;
      z-index: 20;
      background: white;
      @include wt-ht(440px, auto);
      border-radius: 5px;
      padding: 20px;
      .cardHeader {
        position: relative;
        @include flex-center;
        justify-content: space-between;
        .title {
          position: relative;
          font-size: 20px;
          text-align: left;
          color: $c-3b;
        }
        i.close {
          font-size: 18px;
          color: $c-66;
          border-radius: 35px;
          padding: 3px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            background: $c-f5;
            color: $c-33;
          }
        }
      }
      .cardBody {
        position: relative;
        &.loading {
          pointer-events: none;
          user-select: none;
          cursor: unset;
        }
        .createNewListButton {
          position: relative;
          padding: 20px 0;
          margin-bottom: 16px;
          .createListBtn {
            @include flex-center;
            cursor: pointer;
            position: relative;
            @include wt-ht(100%, 50px);
            padding: 1rem;
            border-radius: 10px;
            border: solid 1px $b-c-1;
            background-color: $c-f9;
            i.add {
              font-size: 18px;
              text-align: left;
              color: $blue-violet;
              margin-right: 5px;
            }
            .text {
              font-size: 16px;
              text-align: left;
              color: $c-3b;
            }
          }
          .wrapInputAction {
            position: relative;
            display: grid;
            grid-template-columns: calc(100% - 46px) 36px;
            justify-content: space-between;
            margin-top: 16px;
            padding: 0 20px;
            .inputWrapper {
              @extend %inputWrapper;
              margin-bottom: 0;
            }
            .addNewListBtn {
              position: relative;
              @include flex-align-center;
              @include wt-ht(36px, 36px);
              border-radius: 5px;
              font-size: 16px;
              transition: all 0.3s ease;
              color: white;
              background: $blue-violet;
              cursor: pointer;
              &.loading {
                @include wt-ht(36px !important, 36px !important);
                border-radius: 35px;
                cursor: unset;
                pointer-events: none;
                user-select: none;
                background: unset;
                border: none;
                transition: all 0.3s ease;
                .load-text {
                  border-color: $blue-violet;
                  @include wt-ht(24px !important, 24px !important);
                }
              }
              &.disabled {
                cursor: unset;
                background: $c-9e;
                pointer-events: none;
                user-select: none;
              }
              &:hover {
                box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
              }
            }
          }
          border-bottom: solid 1px $b-c-1;
        }
        .selectListDropdown {
          position: relative;
          border-radius: 10px;
          border: solid 1px $b-c-1;
          background: white;
          margin: 1rem 0;
          .listHeading {
            position: relative;
            padding: 1rem;
            border-bottom: 1px solid $b-c-1;
            font-size: 16px;
            color: $c-66;
            font-weight: 600;
          }
          .selectOptions {
            position: relative;
            display: block;
            .searchInputBox {
              position: relative;
              display: grid;
              grid-template-columns: 26px calc(100% - 30px);
              justify-content: space-between;
              align-items: center;
              border: 1px solid $b-c-1;
              padding: 3px;
              margin: 1rem 1rem 10px;
              .inputWrapper {
                @extend %inputWrapper;
                margin-bottom: 0px;
                .inputCont {
                  border-radius: 5px;
                  .inputBox,
                  .inputBox:focus {
                    border: none !important;
                    font-size: 14px;
                    &::placeholder {
                      color: $c-78;
                      font-size: 14px;
                    }
                    & ~ .focusBorder {
                      display: none;
                    }
                  }
                }
              }
              i {
                font-size: 18px;
                color: $c-78;
                align-self: center;
                justify-self: flex-end;
              }
            }
            .optionList {
              position: relative;
              height: 200px;
              overflow: auto;
              .noListFound {
                position: relative;
                text-align: center;
                top: 30px;
                font-size: 16px;
                color: $c-8d;
              }
              .options {
                position: relative;
                display: grid;
                grid-template-columns: 26px calc(100% - 26px);
                padding: 10px 1rem;
                border-bottom: 1px solid $b-c-1;
                align-items: center;
                justify-content: space-between;
                transition: all 0.3s ease;
                cursor: pointer;
                &:hover:not(.disableSelect) {
                  background: $c-f5;
                }
                &.disableSelect {
                  pointer-events: none;
                  user-select: none;
                  cursor: unset;
                }
                .radioBtn {
                  opacity: 0;
                  position: absolute;
                  z-index: 2;
                  @include wt-ht(24px, 24px);
                  display: block;
                  margin: 0;
                  cursor: pointer;

                  &:checked ~ label {
                    color: $blue-violet !important;
                  }
                  &:checked ~ .contentClass::before {
                    background-color: #ffffff;
                    border: 2px solid #3953f6;
                  }

                  &:checked ~ .contentClass::after {
                    background-color: #3953f6;
                    border: 0px solid transparent;
                    transform: scale(0.6);
                  }
                  .contentClass::before,
                  .contentClass::after {
                    @include wt-ht(18px, 18px);
                  }
                }
                .listDetails {
                  position: relative;
                  display: grid;
                  grid-template-columns: 75% calc(25% - 10px);
                  justify-content: space-between;
                  .listInfo {
                    @extend %relBlock;
                    width: 100%;
                    &.alignSelfCenter {
                      @include flex-center;
                      align-items: center;
                    }
                    .name {
                      @extend %relBlock;
                      font-size: 16px;
                      color: $c-3b;
                      @include ellipsis();
                      margin-bottom: 2px;
                    }
                    .createdInfo {
                      @extend %relBlock;
                      color: $c-78;
                      opacity: 0.8;
                      font-size: 13px;
                    }
                  }
                  .listContactsCount {
                    @extend %relBlock;
                    justify-self: flex-end;

                    .count {
                      @extend %relBlock;
                      font-size: 16px;
                      text-align: right;
                      color: $blue-violet;
                      margin-bottom: 2px;
                    }
                    .text {
                      @extend %relBlock;
                      text-align: right;
                      color: $c-78;
                      opacity: 0.8;
                      font-size: 13px;
                    }
                  }
                }
              }
            }
          }
        }
        .errMsg {
          position: absolute;
          display: block;
          bottom: -18px;
          font-size: 14px;
          text-align: center;
          color: red;
          left: 50%;
          transform: translateX(-50%);
          will-change: auto;
        }
        .addContactToSelectedListBtn {
          min-width: 200px;
          font-size: 14px;
          border-radius: 10px;
          background: $blue-violet;
          letter-spacing: 0.3px;
          cursor: pointer;
          &.loading {
            @include wt-ht(40px, 40px);
            border-radius: 35px;
            min-width: unset;
            .load-text {
              @include wt-ht(30px, 30px);
              border-color: white;
            }
          }
        }
      }
    }
    .bgOverlay {
      @include bg-mask(19, rgba(0, 0, 0, 0.57));
    }
  }
}
