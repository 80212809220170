.filterByContactWrapper {
  @extend %relBlock;
  margin-left: 10px;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }
  &:hover {
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
  }
  .filterContactTagBtn {
    position: relative;
    @include flex-center();
    border: 1px solid $c-eb;
    padding: 10px;
    border-radius: 5px;
    color: $c-59;
    background: white;
    cursor: pointer;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      user-select: none;
    }
    svg {
      @include wt-ht(14px, 16px);
      fill: $blue-violet;
      margin-right: 8px;
    }
    .filterTextWrapper {
      @include flex-center;
      color: $blue-violet;
      font-size: 14px;
      span {
        padding-top: 2px;
      }
      .dropdownFilterIcon {
        font-size: 20px;
        color: $c-9e;
      }
    }
    .customTooltip {
      margin-top: 35px;
      .tooltipBody {
        width: fit-content;
        right: -10px;
        white-space: nowrap;
        .tooltipText {
          padding: 10px;
        }
      }
    }
  }
  .filterTagDropdown {
    background: white;
    border-radius: 10px;
    border: 1px solid $c-ee;
    position: absolute;
    width: 170px;
    right: 0;
    top: 42px;
    z-index: 99;
    box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.08);
    .dropdownItem {
      border-bottom: 1px solid $c-ee;
      padding: 8px 16px;
      cursor: pointer;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  .filterTagOverlay {
    transition: all 300ms ease;
    @include bg-mask(3, transparent);
  }
}
.filterSelectedSection {
  flex: 1;
  display: flex;
  .filterTagOverlay {
    transition: all 300ms ease;
    @include bg-mask(3, transparent);
  }
  .filterSelectedTagSection,
  .filterSelectedOwnerSection {
    margin-left: 10px;
  }
  .filteredValueWrapper {
    width: 200px;
    background-color: $white;
    border-radius: 6px;
    border: 1px solid $c-eb;
    padding: 6px 12px;
    line-height: 1.5;
    @include flex-center();
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    .filteredValueContainer {
      flex: 1;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
    .filterSelectionValue {
      text-transform: capitalize;
      margin-right: 8px;
      font-size: 14px;
    }
    .filteredValues {
      color: $blue-violet;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100px;
      flex: 1;
      font-size: 14px;
    }
    .closeIcon {
      font-size: 16px;
      cursor: pointer;
    }
  }
  .filterWrapper,
  .outboxFilterByOwner {
    padding: 16px 20px;
    background-color: $white;
    border-radius: 8px;
    width: 400px;
    border: 1px solid $c-ee;
    position: absolute;
    z-index: 99;
    display: flex;
    flex-direction: column;
  }
  .headerContainer {
    @include flex-center();
    justify-content: space-between;
    padding-bottom: 20px;
    .headerFilterValue {
      color: $black;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 500;
    }
    .headerClearValue {
      color: $blue-violet;
      cursor: pointer;
      z-index: 2;
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
        user-select: none;
      }
    }
  }
  .bodyContainer {
    flex: 1;
    margin-bottom: 30px;
    .inputContainer {
      border-bottom: 1px solid $c-ee;
      cursor: text;
      position: relative;
      z-index: 3;
    }
    .searchInputBoxWrapper {
      display: inline-flex;
      align-items: center;
    }
    .inputFilterSearch {
      border: 0;
      width: 100px;
      height: 28px;
    }
    .searchCloseIcon {
      font-size: 16px;
      cursor: pointer;
    }
    .contactTagList {
      height: 45px;
      display: inline;
    }
    .searchFilterWrapper {
      display: inline-block;
      position: relative;
      width: 80px;
      margin-bottom: 8px;
      .checkBoxSection {
        padding: 4px 0;
      }
      .checkBoxName {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 140px;
        white-space: nowrap;
      }
    }

    .tagList {
      position: absolute;
      border: 1px solid $c-ee;
      border-radius: 8px;
      width: max-content;
      z-index: 101;
      background-color: $white;
      top: 37px;
      padding: 12px;
      max-height: 250px;
      overflow-y: auto;
      box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.08);
    }
  }
  .footerContainer {
    .doneButton {
      background-color: $blue-violet;
      width: 100px;
      z-index: 2;
    }
  }
}
.selectedTagWrapper {
  @include flex-center;
  gap: 5px;
  .tagItem {
    position: relative;
    line-height: normal;
    border: 1px solid $c-c7;
    color: $blue-violet;
    background-color: $white;
    margin-bottom: 8px;
    padding: 4px 10px;
    @include flex-center;
    justify-content: center;
    border-radius: 15px;
    .selectedTag {
      display: block;
      @include ellipsis;
      color: $c-3b;
      font-size: 12px;
      width: unset;
      min-width: 20px;
      max-width: 110px;
    }
    .selectedTagCloseIcon {
      position: relative;
      margin-left: 2px;
      font-size: 14px;
      padding: 1px;
      cursor: pointer;
      color: $c-9e;
      &:hover {
        border-radius: 35px;
        background: $c-eb;
      }
    }
  }
}
