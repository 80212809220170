.updateContactStageCont {
  position: relative;
  .selectedStage {
    @include flex-center();
    cursor: pointer;
    color: $c-3b;
    position: relative;

    .stageName {
      width: unset;
      min-width: 20px;
      max-width: 160px;
      @include ellipsis;
      white-space: nowrap;
    }

    .arrow {
      transition: all 0.3s ease;
      @include flex-center();
      justify-content: inherit;
      position: relative;
      left: 8px;
      svg {
        fill: $c-53;
        @include wt-ht(9px, 9px);
      }

      &.activeArrow {
        transform: rotate(180deg);
      }
    }
  }
  .drop-down-menu {
    position: absolute;
    left: -7px;
    &.above {
      bottom: 28px;
    }
    &.below {
      top: 20px;
    }
    .dropdownList {
      max-height: 160px;
    }
    ul {
      li {
        .listItem {
          font-size: 13px;
          .listItemWrap {
            width: 178px;
          }
        }
      }
    }
  }
  .dropdownMask,
  .overlayMask {
    z-index: 100;
  }
}
