%accountNotesBorder {
  border: 1px solid $c-border;
  border-radius: 5px;
}

@mixin buttonStyles($border, $background, $color) {
  font-size: 14px;
  padding: 10px 0;
  border-radius: 5px;
  letter-spacing: 0.3px;
  min-width: 120px;
  margin: unset;

  &.disabledBtn {
    pointer-events: none;
    user-select: none;
    cursor: unset;
  }

  border: solid 1px $border;
  background: $background;
  color: $color;
}

@mixin commonInputWrapperStyles {
  position: relative;
  @extend %inputWrapper;
  align-items: unset;
  width: 100%;
  margin-bottom: 14px;
}

/*Loader*/
.accountNotesListHeaderLoader {
  padding: 18px;
  border-bottom: 1px solid $c-border;

  .accountNotesTitleLoader {
    &.linear-background {
      height: 12px;
      width: 70%;
    }
  }
  .accountNotesCreatedByLoader {
    @include flex-center;
    justify-content: space-between;
    padding-top: 6px;
    .notesCreatedByLoader {
      &.linear-background {
        height: 8px;
        width: 60%;
      }
    }
    .notesCreatedDateLoader {
      &.linear-background {
        height: 8px;
        width: 30%;
      }
    }
  }
}
.notesViewBody {
  .accountNotesViewHeaderLoader {
    padding: 16px;
    border-bottom: 1px solid $c-border;

    .accountNotesViewHeaderActionLoader {
      @include flex-center;
      justify-content: space-between;
      padding-top: 6px;
      .notesViewTitleLoader {
        &.linear-background {
          height: 12px;
          width: 50%;
        }
      }
      .notesViewActionsLoader {
        @include flex-center;
        gap: 6px;
        .notesIconLoader {
          &.linear-background {
            border-radius: 50px;
            @include wt-ht(25px, 25px);
          }
        }
      }
    }
    .notesViewCreatedDateLoader {
      &.linear-background {
        height: 8px;
        width: 30%;
      }
    }
  }
  .notesViewText {
    margin-top: 8px;
    &.linear-background {
      width: 50%;
    }
  }
  .notesViewSubText {
    margin: 8px 0px;
    &.linear-background {
      width: 30%;
    }
  }
}

/*Loader*/

.accountNotes {
  position: relative;
  display: flex;
  gap: 12px;
  padding-top: 12px;
  height: calc(100vh - 178px);

  @include searchInputByKeyword();
  .searchWrapper .searchInput {
    width: 300px;
  }

  .accountAllNotesList {
    position: relative;
    background-color: $white;
    height: calc(100% - 50px);
    width: 300px;
    padding: 16px;
    overflow: auto;
    margin-top: 12px;
    @extend %accountNotesBorder;

    .accountNotesListHeader {
      color: $c-3b;
      font-size: 18px;
      font-weight: medium;
      text-align: left;
      padding-bottom: 9px;
      border-bottom: 1px solid $c-border;
    }
    .entireList {
      cursor: pointer;

      .accountNotesTitle {
        padding-top: 16px;
        color: #343a40;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        &:hover,
        &.active {
          color: $blue-violet;
          font-weight: medium;
        }
      }
    }
    .accountNotesCreatedBy {
      @include flex-center;
      justify-content: space-between;
      padding: 6px 0px;
      border-bottom: 1px solid $c-border;
      .notesCreatedBy {
        opacity: 0.8;
        color: $c-3b;
        text-align: left;
        font-size: 11px;
        line-height: 14px;
        font-weight: normal;
      }
      .notesCreatedDate {
        opacity: 0.8;
        color: $c-3b;
        text-align: left;
        font-size: 11px;
        line-height: 14px;
        font-weight: normal;
      }
    }
  }

  .accountNotesListBody {
    position: relative;
    background-color: $white;
    width: calc(100% - 300px);
    padding: 16px;
    @extend %accountNotesBorder;

    .accountNotesList {
      padding: 16px;
      width: 100%;
      height: 100%;

      @extend %accountNotesBorder;

      .accountViewHeader {
        @include flex-center;
        justify-content: space-between;
        padding-bottom: 16px;
        border-bottom: 1px solid $c-border;
        .notesTitle {
          text-align: left;
          font-size: 16px;
          line-height: 21px;
          font-weight: medium;
          color: #343a40;
        }
        .notesCreatedOn {
          padding-top: 4px;
          color: $c-8d;
          text-align: left;
          font-size: 11px;
          line-height: 14px;
          font-weight: normal;
        }
      }
      .NotesHeaderAction {
        @include flex-center;
        gap: 4px;

        .editAccountActionIcon {
          cursor: pointer;
          @include wt-ht(26px, 26px);
          border: 1px solid #dddddd;
          border-radius: 3px;
          padding: 4px;
          color: $c-8d;
          &:hover {
            border: 1px solid $blue-violet;
            color: $blue-violet;
          }
        }
      }

      .notesAnserView {
        padding-top: 16px;
        text-align: left;
        color: $c-3b;
        font-size: 14px;
        line-height: 24px;
        font-weight: normal;
        opacity: 1;
        pointer-events: none;
      }
    }
  }

  .editNotesAction {
    position: absolute;
    width: 100%;
    z-index: 99;
    @include flex-center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid $c-border;
    gap: 8px;
    padding-bottom: 4px;

    .inputWrapper {
      @include commonInputWrapperStyles;
    }

    .editNotesActionBtn {
      @include flex-center;
      gap: 8px;
      button {
        font-size: 14px;
        padding: 10px 0;
        border-radius: 5px;
        letter-spacing: 0.3px;
        min-width: 120px;
        margin: unset;
        &.saveAccountNotesBtn {
          border: solid 1px $blue-violet;
          background: $blue-violet;
          &.disabledBtn {
            pointer-events: none;
            user-select: none;
            cursor: unset;
          }
        }
        &.accountNotesCancelBtn {
          border: solid 1px #dddddd;
          color: $blue-violet;
          background: white;
        }
        &.loading {
          @include wt-ht(34px !important, 34px !important);
          transition: all 0.3s ease-in-out;
          min-width: auto;
          border-radius: 35px;
          .load-text {
            @include wt-ht(24px, 24px);
            top: 4px;
            left: 4px;
          }
        }
      }
    }
  }
}

.initialUserNotes {
  position: relative;
  padding-top: 12px;
  height: calc(100vh - 178px);
  .initialBody {
    background-color: $white;
    height: 100%;
    width: 100%;
    @extend %accountNotesBorder;

    .initialAddNotes {
      @include flex-center;
      justify-content: center;
      flex-direction: column;
      height: 100%;

      .accountNotesImg {
        @include wt-ht(90px, 90px);
        object-fit: contain;
      }

      button {
        @include buttonStyles($blue-violet, $blue-violet, $white);
      }
    }
  }
  .editNotesAction {
    position: absolute;
    width: 100%;
    z-index: 99;
    @include flex-align-center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid $c-border;
    gap: 8px;
    padding-bottom: 4px;

    .inputWrapper {
      @include commonInputWrapperStyles;
    }

    .editNotesActionBtn {
      @include flex-center;
      gap: 8px;
      button {
        font-size: 14px;
        padding: 10px 0;
        border-radius: 5px;
        letter-spacing: 0.3px;
        min-width: 120px;
        margin: unset;
        &.saveAccountNotesBtn {
          border: solid 1px $blue-violet;
          background: $blue-violet;
          &.disabledBtn {
            pointer-events: none;
            user-select: none;
            cursor: unset;
          }
        }
        &.accountNotesCancelBtn {
          border: solid 1px $c-dd;
          color: $blue-violet;
          background: white;
        }
        &.loading {
          @include wt-ht(34px !important, 34px !important);
          transition: all 0.3s ease-in-out;
          min-width: auto;
          border-radius: 35px;
          .load-text {
            @include wt-ht(24px, 24px);
            top: 4px;
            left: 4px;
          }
        }
      }
    }
  }
}

.singleBanner .accountNotes,
.singleBanner .initialUserNotes {
  height: calc(100vh - 218px);
}
