@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 75%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.bookNowBanner {
  position: fixed;
  top: 50%;
  bottom: 50%;
  transform: translate(0%, -35%);
  right: -280px;
  z-index: 8;
  transition: all 0.9s ease-in-out;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
  @include wt-ht(280px, 306px);
  border-bottom-left-radius: 6px;
  &.bookNowBg {
    right: 0;
    transition: all 0.9s ease-in-out;
  }
  &:hover {
    .wrapImgTextBookNowBtn {
      right: 0;
    }
  }
  .closeIcon {
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    &:hover {
    }
  }
  .rotateRequestDemoBtn {
    position: relative;
    right: 280px;
    transform: rotate(-90deg);
    transform-origin: right bottom;
    position: absolute;
    top: -36px;
  }
  .initialReqDemoBtn {
    right: 280px;
  }
  .requestDemoBtn {
    font-size: 1rem;
    background-color: $white;
    color: $blue-violet;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
    width: 200px;
    padding: 8px 12px;
    padding-right: 8px;
    height: 36px;
    .iconRight {
      margin-left: 12px;
      margin-right: 6px;
    }
  }
  .wrapImgTextBookNowBtn {
    position: relative;
    width: 100%;
    background: $white;

    .bookNowSmartImg {
      position: absolute;
      top: -40px;
      right: 65px;
    }
    .wrapDescTitle {
      @extend %relBlockWidth;
      font-size: 14px;
      padding-top: 100px;
      text-align: center;

      p {
        color: $c-50;
        font-size: 13px;
        margin-bottom: 20px;
        padding: 0 28px;
      }
      h6 {
        font-size: 16px;
        color: #2c2d31;
        padding: 0 23px;
        margin-bottom: 32px;
      }
    }
    .bookNowBtn {
      background: url("../../../assets/png/dottedBgForBtn.png") no-repeat;
      padding-top: 8px;
      padding-bottom: 2px;
      #BookNowOneAndOneBtn {
        min-width: 180px !important;
        background: #2fd378;
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 12px;
        border-radius: 25px;
        i {
          position: absolute;
          right: 0;
          transform: rotate(180deg);
          font-size: 18px;
          position: relative;
          margin-left: 32px;
          transition: all 0.3s;
        }
        &:hover {
          transition: all 0.3s;
          background: #4caf50;
          font-weight: 500;
          i {
            transition: all 0.3s;
            right: -5px;
          }
        }
      }

      .bookDemoBtnJiggle {
        @include btn-jiggle(10s, infinite);
      }
    }
  }
}
