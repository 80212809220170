.ctTlSection {
  position: relative;
  @include wt-ht(100%, calc(100% - 60px));
  display: grid;
  grid-template-columns: calc(100% - 372px) 360px;
  justify-content: space-between;
  background: $c-f5f8;
  padding: 12px;
  .ctTlBody {
    position: relative;
    height: calc(100vh - 24px);
    .tabNavigation {
      border-bottom: 1px solid $c-ec;
      .tabItem {
        height: 36px;
        cursor: unset;
        pointer-events: none;
        user-select: none;
        &.activeTab {
          border-bottom: 2px solid $blue-violet;
          font-weight: 600;
          color: $blue-violet;
        }
      }
    }
    .ctTlContentBody {
      position: relative;
      margin-top: 12px;
      border-radius: 10px;
      background: white;
      padding: 24px 14px 24px 24px;
      overflow: hidden;
      @include wt-ht(100%, calc(100% - 155px));
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

      .ctTlFilter {
        position: absolute;
        height: 40px;
        right: 28px;
        z-index: 1;
        .wrapCtTlFilter {
          position: relative;
          @include flex-center;
          justify-content: center;
          height: 30px;
          .filterByContactWrapper {
            .filterContactTagBtn {
              padding: 6px 10px;
              svg {
                @include wt-ht(11px, 16px);
              }
            }
            .filterTagDropdown {
              top: 32px;
              .dropdownItem {
                padding: 8px 10px;
                font-size: 14px;
                &:not(.active):hover {
                  background: $c-f5;
                  color: $c-44;
                }
                &.active {
                  color: $blue-violet;
                  background: $c-f5;
                }
              }
            }
          }
        }
      }
      .ctTlHistoryList {
        position: relative;
        overflow-y: auto;
        padding-right: 10px;
        @include wt-ht(100%, calc(100% - 1px));
        &.noDataFound {
          @include wt-ht(100%, 100%);
        }
        .monthlyActivityFeed {
          position: relative;
          &:not(:last-child) {
            margin-bottom: 40px;
          }
          .ctTlMonth {
            position: relative;
            display: inline-block;
            text-align: left;
            color: $c-53;
            padding: 10px 0 24px;
            .dot {
              position: relative;
              display: inline-block;
              border-radius: 35px;
              border: 4px solid $blue-violet;
              margin-right: 10px;
              padding: 2px;
              &.linear-background {
                padding: 2px;
                height: unset;
                border-color: $c-dd;
              }
            }
            .month {
              position: relative;
              display: inline-block;
              color: $c-3b;
              font-size: 16px;
              font-weight: 500;
              &.linear-background {
                @include wt-ht(140px, 14px);
              }
            }
          }
          .ctTlCard,
          .ctTlCardLoader {
            position: relative;
            display: grid;
            grid-template-columns: 26px calc(100% - 26px);
            align-items: flex-start;
            justify-content: space-between;
            padding: 10px 0;
            background-color: white;
            border-bottom: 1px solid $c-dd;
            pointer-events: none;
            cursor: unset;
            &.showPreview {
              cursor: pointer;
              pointer-events: unset;
            }
            &:not(:last-child) {
              margin-bottom: 10px;
            }

            .svgIconType {
              position: relative;
              @include flex-center;
              .autoReplyIcon {
                font-size: 16px;
              }
              svg {
                position: relative;
                @include wt-ht(14px, 14px);
                path {
                  @include wt-ht(14px, 14px);
                }
                &.opened,
                &.resumed {
                  fill: $blue-violet;
                }
                &.sent,
                &.email,
                &.manualEmail {
                  fill: #00a358 !important;
                  path {
                    fill: #00a358 !important;
                  }
                }
                &.clicked,
                &.ooto_paused {
                  fill: $c-8d !important;
                }
                &.ooto_resumed {
                  fill: #00a358 !important;
                }
                &.phone {
                  fill: #1eb9d5 !important;
                }
                &.linkedinAuto {
                  fill: $blue-violet !important;
                  path {
                    fill: $blue-violet !important;
                  }
                }
                &.linkedin {
                  fill: #0a66c2 !important;
                  path {
                    fill: #0a66c2 !important;
                  }
                }
                &.contact_update {
                  @include wt-ht(13px, 13px);
                }
                &.task,
                &.task_completed,
                &.task_skipped,
                &.customTask,
                &.marked_as_complete {
                  fill: #ff9e10 !important;
                }
                &.account_created,
                &.received_email,
                &.add_to_sequence,
                &.skip_to_next_step,
                &.contact_update {
                  fill: $blue-violet !important;
                  path {
                    fill: $blue-violet !important;
                  }
                }
                &.account_field_update,
                &.contact_created {
                  fill: #00a358 !important;
                  path {
                    fill: #00a358 !important;
                  }
                }
                &.bounced,
                &.ooto_resume_failed {
                  fill: #ff0000 !important;
                }
                &.meeting_booked {
                  fill: #272056;
                }
                &.optedOut,
                &.remove_contact,
                &.email_validation_service_opted_out,
                &.email_validation_service_bounced {
                  fill: #ff0000 !important;
                  path {
                    fill: #ff0000 !important;
                  }
                }
                &.optedOut {
                  @include wt-ht(12px, 12px);
                }
              }
              &.linear-background {
                border-radius: 35px;
                @include wt-ht(14px, 14px);
              }
            }
            .activityFeedCard {
              position: relative;
              display: grid;
              grid-template-columns: calc(100% - 180px) 180px;
              align-items: flex-start;
              justify-content: space-between;
              background-color: white;
              .contentCard {
                position: relative;
                width: 100%;
                font-size: 13px;
                text-align: left;
                b {
                  color: $c-44 !important;
                }
                p {
                  position: relative;
                  color: $c-3b;
                  .sequenceName {
                    color: $blue-violet;
                    cursor: pointer;
                  }
                  &.linear-background {
                    display: block;
                    @include wt-ht(450px, 12px);
                  }
                }
                .visitedLinkList {
                  position: relative;
                  pointer-events: all;
                  .showMoreButton {
                    cursor: pointer;
                  }
                }
                .emailSubjectContent {
                  position: relative;
                  width: 100%;
                  @include ellipsis();
                  font-size: 13px;
                  margin-top: 5px;
                  .subject {
                    color: $c-3b;
                  }
                  .content {
                    color: $c-8d;
                  }
                  &.linear-background {
                    @include wt-ht(400px, 12px);
                  }
                  .taskType {
                    font-weight: bold;
                    color: $c-3b;
                  }
                }
                .dFlex {
                  @include flex-center;
                }
                .contactTaskNotes,
                .contactTaskMessage {
                  margin-top: 5px;
                  font-size: 13px;
                  .taskType {
                    color: $c-3b;
                  }
                }
                .contactTaskMessage {
                  .taskType {
                    font-weight: 500;
                  }
                }
                .fieldUpdateList {
                  @extend %relBlock;
                  margin-top: 5px;
                  .fieldNameList {
                    margin-top: 2px;
                    color: $c-3b;
                    .fieldName {
                      font-weight: 600;
                      color: $c-44;
                    }
                    .strikeThrough {
                      color: $c-78;
                      text-decoration: line-through;
                    }
                  }
                }
                .sequenceDetails {
                  @include flex-center;
                  margin-top: 6px;
                  color: $c-8d;
                  &.linear-background {
                    height: 10px;
                    width: 200px;
                  }
                  .contactStepNumber {
                    position: relative;
                    padding-right: 5px;
                  }
                  .sequenceName {
                    position: relative;
                    @include flex-center;
                    &.hasStep {
                      padding-left: 5px;
                    }
                    max-width: 300px;
                    cursor: pointer;
                    @include ellipsis;
                    cursor: pointer;
                    &:hover {
                      color: $blue-violet;
                      transition: all 0.3s ease;
                    }
                  }
                }
                .clickLink {
                  @extend %relBlock;
                  margin-top: 5px;
                  &.linear-background {
                    height: 10px;
                    width: 180px;
                    margin-right: 10px;
                  }
                }
                a,
                .linkText {
                  position: relative;
                  font-size: 13px;
                  font-weight: 500;
                  text-align: left;
                  color: $blue-violet;
                  text-decoration: none;
                  &:hover {
                    text-decoration: underline;
                    transition: all 0.3s ease;
                  }
                }
                .callDetails {
                  position: relative;
                  margin-top: 5px;
                  @include ellipsis;
                  color: $c-3b;
                  font-size: 13px;
                  text-transform: capitalize;
                  span {
                    color: $c-8d;
                  }
                }
              }
              .calenderDate {
                position: relative;
                font-weight: 500;
                text-align: right;
                font-size: 12px;
                color: $c-8d;
                &.linear-background {
                  margin: 0 0 0 auto;
                  @include wt-ht(60%, 10px);
                }
              }
            }
          }
        }
        .emptyActivity {
          @include flex-center();
          @include wt-ht(100%, 100%);
          justify-content: center;
          flex-direction: column;
          img {
            margin: 0 auto;
            padding-bottom: 15px;
          }
          .infoText {
            color: $c-78;
            font-size: 16px;
          }
        }
        .paginateActivity {
          position: relative;
          text-align: center;
          cursor: pointer;
          border: 1px solid $blue-violet;
          background: $c-f5;
          color: $blue-violet;
          font-size: 12px;
          border-radius: 4px;
          padding: 5px 10px 4px;
          width: 140px;
          margin: 10px auto 0;
        }
      }
    }
  }
  .autoReplyBadge {
    margin-top: 8px;
  }
  .ctTlSidePanelCard {
    position: relative;
    width: 100%;
    height: calc(100vh - 132px);
    @extend %contactSliderCard;
    .contactCard {
      height: 100%;
      border-radius: 10px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      .contactInfoCard .contactActivityInfo {
        .wrapContactActivity {
          height: calc(100% - 244px) !important;
        }
      }
    }
  }
}
// Styles reflect in performtask also
.singleBanner {
  .ctTlBody {
    height: calc(100vh - 67px);
  }
  .ctTlSidePanelCard {
    height: calc(100vh - 172px);
    .contactCard {
      .contactInfoCard .contactActivityInfo {
        .wrapContactActivity {
          height: calc(100% - 284px) !important;
        }
      }
    }
  }
}

.combinedBanners {
  .ctTlBody {
    height: calc(100vh - 105px);
  }
  .ctTlSidePanelCard {
    height: calc(100vh - 162px);
  }
}

// Event registration banner handle
.showEventBanner .ctTlSection .ctTlSidePanelCard {
  height: calc(100vh - 124px);
  .wrapContactActivity {
    height: calc(100% - 258px) !important;
  }
  .contactCardLoader .wrapContactActivity {
    height: calc(100% - 248px) !important;
  }
}

.showEventBanner .ctTlSection .ctTlBody {
  position: relative;
  height: calc(100vh - 67px);
}

.showEventBanner .singleBanner {
  .ctTlBody {
    height: calc(100vh - 107px);
  }
  .ctTlSidePanelCard {
    height: calc(100vh - 164px);
  }
}

.showEventBanner .singleBanner .ctTlSection .ctTlSidePanelCard {
  .contactCard:not(.contactCardLoader) .wrapContactActivity {
    width: 360px;
    height: calc(100% - 298px) !important;
  }
  .contactCardLoader .wrapContactActivity {
    @include wt-ht(360px, calc(100% - 286px) !important);
  }
}

.showEventBanner .combinedBanners .ctTlSection {
  .ctTlBody {
    height: calc(100vh - 149px);
  }
  .ctTlSidePanelCard {
    height: calc(100vh - 206px);
  }
}

.showEventBanner .combinedBanners .ctTlSection .ctTlSidePanelCard {
  .contactCard:not(.contactCardLoader) .wrapContactActivity {
    height: calc(100% - 340px) !important;
  }
  .contactCardLoader .wrapContactActivity {
    @include wt-ht(360px, calc(100% - 328px) !important);
  }
}
