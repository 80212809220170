.subjectLineGenerator {
  position: absolute;
  display: block;
  z-index: 100;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 8px 10px;
  background: white;
  border-left: 1px solid $c-ed;
  border-bottom: 1px solid $c-ed;
  border-right: 1px solid $c-ed;
  white-space: nowrap;
  .generatorBtn {
    @extend %relBlock;
    display: grid;
    grid-template-columns: 22px calc(100% - 32px);
    align-items: center;
    svg {
      @include wt-ht(22px, 22px);
    }
    .disableBtn {
      pointer-events: none;
      user-select: none;
      cursor: unset;
    }
    .generateSubjectLineBtn {
      position: relative;
      @include flex-center;
      border-radius: 21px;
      border: solid 1px #f7f7f7;
      margin-left: 10px;
      padding: 6px;
      cursor: pointer;
      transition: all 0.3s ease;
      min-width: 250px;
      svg {
        margin-right: 10px;
        @include wt-ht(13px, 13px);
      }
      .text {
        font-size: 13px;
        color: $c-66;
      }
      &:hover {
        background: $c-f5;
        .text {
          color: $c-52;
        }
      }
      .betaBadge {
        padding-top: 4px;
      }
      .subjectLineLoader {
        color: $blue-violet;
        margin-left: 10px;
      }
      .freemiumIcon {
        padding-left: 10px;
        justify-content: unset;
      }
    }
  }
  .recommendedSubjectLines {
    @extend %relBlock;
    width: 440px;
    .wrapTitleReload {
      @include flex-center;
      justify-content: space-between;
      margin-bottom: 5px;
      .title {
        position: relative;
        @include flex-center;
        color: $c-87;
        font-size: 12px;
        padding: 8px 5px;
        user-select: none;
        pointer-events: none;
        cursor: unset;
        font-weight: 600;
      }
      .reloadSubjectLineBtn {
        position: relative;
        display: grid;
        grid-template-columns: 32px auto;
        border: solid 1px $c-dd;
        width: 100px;
        text-align: center;
        align-items: center;
        height: 28px;
        cursor: pointer;
        background: white;
        border-radius: 35px;
        padding: 5px;
        transition: all 0.3s ease;
        .reloadText {
          color: $c-66;
          font-weight: 500;
          font-size: 14px;
          text-align: left;
        }
        .spinnerReload {
          color: $blue-violet;
          font-size: 14px;
        }
        &:hover {
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
          background: $c-f5;
        }
      }
    }
    .dropdownList {
      @include list-style-zero;
      @extend %relBlock;
      box-shadow: inset 0 1px 6px 0 $c-c4;
      padding: 8px 5px;
      border-radius: 5px;
      max-height: 200px;
      overflow-y: auto;
      &.emptySubjectLine {
        @extend %relBlock;
        padding: 20px;
        white-space: normal;
        font-size: 13px;
        color: $c-78;
        white-space: normal;
        .reloadBtnLin {
          color: $blue-violet;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .dropdownOption {
        @extend %relBlock;
        cursor: pointer;
        padding: 5px;
        border-radius: 5px;
        border: 0.8px solid transparent;
        transition: all 0.3s ease;
        white-space: normal;
        font-size: 13px;
        color: $c-52;
        white-space: normal;
        &:hover {
          border: solid 0.8px #d9deff;
          background-color: rgba(235, 234, 244, 0.39);
        }
      }
    }
  }
}

.subjectLineOverlay {
  @include bg-mask(99, transparent);
}
