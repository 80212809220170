@mixin titleStyle {
  position: relative;
  display: block;
  color: $c-66;
  padding-bottom: 15px;
  line-height: 1.4;
  font-size: 13px;
}

@mixin rechargeCard {
  .rechargeAmountCont {
    width: 100%;
    position: relative;

    .rechargeTitle {
      @include titleStyle();
    }

    .amountData {
      padding-top: 10px;
      width: 90%;

      @include flex-center();
      flex-direction: row;
      justify-content: space-between;

      .rechargeCard {
        font-size: 15px;
        color: $c-66;

        .radioBtnCont {
          .contentClass {
            margin: 0px;
          }
        }
      }
    }
  }
}

@mixin action {
  @include flex-center();
  justify-content: center;
  padding-top: 24px;
  .footerWrapper {
    @include flex-direction(column);

    .selectedChargeCont {
      .chargeText {
        color: rgba(102, 102, 102, 0.75);
        font-size: 12px;
        padding-top: 10px;
      }
    }

    .btn.purchaseCredits {
      width: 200px;
    }
  }
}

.buycreditsPopup {
  height: 100%;

  .buyCreditsWrapper {
    max-height: 300px;
    border-radius: 10px;
    background: #fff;
    min-width: 415px !important;
    padding: 25px;

    .creditsCont {
      width: 100%;
      height: 100%;

      .popupHeader {
        @include flex-center();

        .popupTitle {
          color: $c-53;
          font-size: 16px;
          width: 80%;
          display: inline-block;
          font-weight: 600;
        }
      }

      .popupBody {
        padding-top: 10px;
        height: 100%;

        .rechargeAmountWrapper {
          @include rechargeCard();
        }

        .cardDetailsWrapper {
          padding-top: 25px;
        }

        .actionWrapper {
          @include action();
        }
      }
    }
  }

  &.ccPopup {
    .ccPopupWrapper {
      height: 260px;

      .cardDetailsWrapper {
        padding-top: 0px !important;
      }

      .actionWrapper {
        flex-direction: column;

        .btncont {
          justify-content: space-evenly !important;
          @include flex-center();
          width: 100%;
        }

        .purchaseNumberBtn {
          width: 150px;
        }

        .backCont {
          @extend %secondaryButton;
          border-color: $tb-bor-btm;
        }
      }

      .chargeText {
        color: $c-66;
        font-size: 11px;
        padding-top: 10px;
      }
    }
  }
}
