.editBulkTasksPopup {
  z-index: 19;
  position: fixed;
  @include wt-ht(100%, 100%);
  @include flex-align-center();
  user-select: none;
  inset: 0;
  .card {
    position: relative;
    z-index: 20;
    background: white;
    @include wt-ht(500px, auto);
    border-radius: 5px;
    .cardHeader {
      position: relative;
      @include flex-center;
      justify-content: space-between;
      padding: 20px 30px 10px;
      border-bottom: 1px solid $c-f5;
      .title {
        position: relative;
        font-size: 20px;
        text-align: left;
        color: $c-3b;
      }
    }
    .cardBody {
      position: relative;
      padding: 20px 30px;
      &.loading {
        pointer-events: none;
        user-select: none;
        cursor: unset;
      }
      .taskDatePicker {
        @extend %taskDatePicker;
        max-width: 300px;
        label {
          font-size: 12px !important;
          display: block;
          text-align: left;
        }
      }
      .notesInput {
        @extend %notesInput;
      }
    }
    .cardFooter {
      @extend %contentSpace;
      @include flex-center;
      justify-content: flex-end;
      border-top: 1px solid $c-ea;
      column-gap: 15px;
      button {
        font-size: 14px;
        padding: 10px 0;
        border-radius: 8px;
        letter-spacing: 0.3px;
        min-width: 120px;
        margin: unset;
        &.editBulkTasksButton {
          border: solid 1px $blue-violet;
          background: $blue-violet;
        }
        &.closeEditBulkTasksBtn {
          border: solid 1px $c-71;
          color: $c-52;
          background: white;
        }
        &.loading {
          @include wt-ht(34px !important, 34px !important);
          transition: all 0.3s ease-in-out;
          min-width: auto;
          border-radius: 35px;
          .load-text {
            @include wt-ht(24px, 24px);
            top: 4px;
            left: 4px;
          }
        }
      }
    }
  }
  .bgOverlay {
    @include bg-mask(19, rgba(0, 0, 0, 0.57));
  }
}
