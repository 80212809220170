.valueColumn {
  .tagsWrapper {
    position: relative;
    display: grid;
    grid-template-rows: 22px;
    row-gap: 5px;
    .addContactTagBtn {
      position: relative;
      @include flex-align-center;
      cursor: pointer;
      transition: transform 0.3s ease-in-out;
      border: 1px solid $c-dd;
      border-radius: 5px;
      @include wt-ht(20px, 20px);
      i {
        position: relative;
        display: block;
        line-height: normal;
        color: $c-8d;
        font-size: 18px;
      }
      .customTooltip {
        top: 18px;
        .sharpPointer {
          background: $black;
          padding: 6px;
          top: unset;
          bottom: 36px;
          border-top-right-radius: 50px;
          right: 2px;
          border-color: $black;
        }
        .tooltipBody {
          background: $black;
          width: 62px;
          top: unset;
          bottom: 40px;
          border-radius: 5px;
          left: unset;
          right: -20px;
          .tooltipText {
            color: $white;
            font-size: 12px;
            padding: 4px;
          }
        }
      }
    }
    .tagsList {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, auto);
      max-height: 93px;
      overflow-y: auto;
      padding: 6px;
      border: 1px solid $c-dd;
      border-radius: 10px;
      width: fit-content;
      &.multiple {
        grid-gap: 5px;
      }
      &.single {
        grid-gap: 0px;
      }
      .tagItem {
        position: relative;
        border: 1px solid #959cf2;
        color: $c-66;
        background-color: $white;
        @include flex-center;
        justify-content: space-between;
        border-radius: 30px;
        padding: 4px 8px;
        width: 85px;
        .name {
          font-size: 12px;
          @include ellipsis;
          max-width: calc(77px - 12px);
        }
        .close {
          color: $c-66;
          font-size: 10px;
          padding: 1px;
          border-radius: 35px;
          background: transparent;
          cursor: pointer;
          &:hover {
            background: $c-f5;
            color: $c-33;
          }
        }
      }
      .highlightedButton {
        cursor: pointer;
        color: $blue-violet;
        padding: 4px;
      }
    }
  }
}
