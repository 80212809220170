@mixin cnameText {
  color: $c-66;
  font-size: 13px;
  font-weight: 600;
  padding-bottom: 15px;
}

.addCustomUrlPopup {
  @extend %popupCard;
  user-select: none;
  .card {
    min-width: 500px;
    .cardTitle h4 {
      color: $c-53;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .cardBody {
      @extend %relBlock;
      .descText {
        @extend %relBlock;
        color: $c-78;
        line-height: 1.4;
        font-size: 13px;
        margin-bottom: 10px;
      }
      .cnameCont {
        position: relative;
        @include wt-ht(95%, auto);
        display: grid;
        padding-bottom: 20px;
        grid-template-columns: 20px calc(100% - 20px);
        .textBoxWrapper {
          @extend %relBlock;
          padding-left: 15px;
          .hostDetails {
            margin: 15px 0px;
            .cnameText {
              color: $c-66;
              font-size: 13px;
              font-weight: 600;
              padding-bottom: 10px;
            }
            .inputCont {
              #domain {
                font-size: 14px;
              }
              input {
                color: $c-78;
                border-radius: 4px;
                border: solid 1px $c-dd;
                background-color: $white;
                width: 100%;
                padding: 12px;
                
              }
            }
            &.target {
              position: relative;
              margin-bottom: 30px;
              .copy {
                position: absolute;
                top: 38px;
                right: 10px;
                color: $blue-violet;
                font-size: 13px;
                cursor: pointer;
              }
              .copiedText {
                position: absolute;
                top: 12px;
                right: 0;
                font-size: 12px;
                color: $green;
                font-family: "Graphik-Semibold";
                transition: all 0.5s;
              }
            }
            .errMsgDomainText {
              display: flex;
              font-size: 11px;
              color: red;
              position: absolute;
              bottom: -2px;
              left: 15px;
            }
          }
        }
        .statusBar {
          text-align: center;
          position: relative;
          .statusIcon {
            margin-top: 8px;
            i {
              font-size: 20px;
              &.greenTick {
                color: #4fcc60;
              }
              &.pendingIcon {
                position: absolute;
                top: 104px;
                left: 0px;
              }
            }
            .progressBar {
              position: absolute;
              background-color: #4fcc60;
              width: 2px;
              display: block;
              border-radius: 4px;
              -webkit-transition-delay: 1s;
              transition-delay: 1s;
              -webkit-transition: 0.5s linear;
              transition: 0.5s linear;
              left: 9px;
            }
          }
        }
      }
      @extend %actionBtnWrapper;
    }
  }
  .overlayAddEditCustomUrl {
    @include bg-mask(99, rgba(0, 0, 0, 0.65));
  }
}
