%configurationCont {
  @extend %relBlock;
  @include wt-ht(100%, calc(100% - 115px));
  overflow: auto;
  .accountConfigurationContLoader,
  .accountConfigurationCont {
    @extend %relBlock;
    @include wt-ht(100%, 100%);
    @include flex-align-center;
    flex-direction: column;
    .accountConfigText {
      margin-bottom: 20px;
      font-size: 14px;
      color: $c-3b;
      &.linear-background {
        @include wt-ht(32px, 14px);
      }
    }
    .connectEmailCont {
      @include flex-center;
      margin: 0 auto 25px;
      .connectedAccountEmail {
        position: relative;
        @include flex-align-center;
        height: 38px;
        padding: 0 20px;
        border: 1px solid $blue-violet;
        border-radius: 5px;
        width: auto;
        &.connectAccount {
          cursor: pointer;
        }
        &.boxLoader {
          min-width: 400px;
          border: 1px solid $c-ec;
        }
        .calendarImg {
          position: relative;
          padding-right: 15px;
          @include flex-center;
          svg {
            @include wt-ht(16px, 16px);
          }
          &.linear-background {
            margin-right: 15px;
            @include wt-ht(24px, 24px);
          }
        }
        .text {
          position: relative;
          font-size: 14px;
          top: 1px;
          padding-right: 2px;
          &.linear-background {
            @include wt-ht(160px, 10px);
            margin-right: 2px;
          }
        }
        .emailText {
          position: relative;
          top: 1px;
          font-size: 14px;
          &.linear-background {
            @include wt-ht(120px, 10px);
          }
        }
      }
      .disconnectBtnCont {
        position: relative;
        @include wt-ht(130px !important, 40px !important);
        @include flex-align-center;
        .disconnectCalender {
          background: $blue-violet;
          margin-left: 10px;
          min-width: 130px;
          height: 38px;
          .iconLeftCont {
            @include flex-align-center;
            .leftIcon {
              font-size: 16px;
              margin-right: 4px;
            }
          }
          &.loading {
            transition: all 0.3s ease-in-out;
            min-width: auto;
            border-radius: 35px;
            .load-text {
              @include wt-ht(30px, 30px);
              top: 4px;
              left: 4px;
            }
          }
        }
      }
    }
    .brandLogoToggle {
      position: relative;
      @include flex-align-center;
      width: 90%;
      .toggleBox {
        color: $c-3b;
        &.linear-background {
          @include wt-ht(32px, 14px);
          margin-right: 8px;
        }
      }
      .toogleSequence {
        @extend %toggleBtn;
      }
      .toggleText {
        position: relative;
        top: 1px;
        color: $c-3b;
        font-size: 14px;
        &.activeToogle {
          font-weight: 400 !important;
        }
        &.linear-background {
          @include wt-ht(550px, 12px);
        }
      }
    }
  }
  .emptyInbox {
    background: white;
    @extend %emptyInbox;
  }
  .accountConfigCont {
    @extend %relBlock;
    background: white;
    @include wt-ht(100%, 100%);
  }
}
