$contactStageBg-color: #959cf2;
.contactStageAndRules {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  .tabNavigation .tabItem {
    width: unset;
    min-width: 140px;
  }
}
.contactStagesWrapper {
  @extend %relBlock;
  height: calc(100% - 56px);
  user-select: none;
  overflow-y: auto;
  .contactStagesCont {
    @extend %relBlock;
    padding: 25px;
    height: 100%;
    .headerCont {
      display: block;
      padding-bottom: 20px;
      font-size: 16px;
      color: $c-66;
      font-weight: 400;
    }
    .infoText {
      text-align: left;
      font-size: 11px;
      letter-spacing: 0px;
      color: #8d8d8d;
      opacity: 1;
      padding-bottom: 21px;
      padding-top: 6px;
      height: 11px;
      border-bottom: 1px solid #e0e0e0;
    }
    .topPostion {
      padding-top: 15px;
    }
    .contactStageContentBody {
      position: relative;
      display: block;
      @include wt-ht(100%, calc(100% - 40px));
    }
    .contactStageCardList {
      position: relative;
      display: grid;
      grid-template-columns: repeat(1, 100%);
      grid-gap: 15px;
      width: 100%;
      overflow-y: auto;
      justify-content: space-between;
      align-items: stretch;
      padding-bottom: 40px;
      @media (min-width: 992px) {
        grid-template-columns: repeat(2, calc(50% - 10px));
      }
      @media (min-width: 1400px) {
        grid-template-columns: repeat(3, calc(33.33% - 10px));
      }
    }
    .contactStageCard {
      border: 1px solid $contactStageBg-color;
      border-radius: 5px;
      @include flex-center;
      justify-content: space-between;
      height: 36px;
      padding: 0 12px;
      font-size: 14px;
      color: $c-3b;
      line-height: normal;
      &.nonActive {
        border: 1px solid $c-dd;
      }
      &.nonEditable {
        background-color: #f1f9ff;
        color: $contactStageBg-color;
        border: 1px solid #f1f9ff;
      }
      .contactStageName {
        @include text-ellipsis();
        max-width: 200px;
      }
      .inputWithCloseIcon {
        @include flex-center;
        position: relative;
        width: 100%;
        justify-content: space-between;
        .editStageName {
          height: 30px;
          border: 0;
          width: 100%;
          padding: 0;
          font-size: 14px;
          color: $c-3b;
        }
        .closeIcon {
          font-size: 14px;
          color: $c-8d;
          cursor: pointer;
          transform: translateX(0, 100%);
          transition: all 0.3s;
        }
      }
      .actionIcons {
        @include flex-center;
      }

      .visibilityIcon {
        &.visible {
          color: $blue-violet;
        }
      }
      .iconBackground {
        @include wt-ht(18px, 18px);
        border-radius: 3px;
        background-color: #f4f4f6;
        margin-right: 5px;
        @include flex-center;
        justify-content: center;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &.cursorNotAllowed {
          cursor: not-allowed;
          opacity: 0.5;
        }
        .customTooltip {
          .sharpPointer {
            border-top-right-radius: 10px;
            border-bottom-left-radius: 35px;
            padding: 6px;
            top: 8px;
            right: 0;
            background: #393a3d;
            border: 1px solid #393a3d;
          }
          .tooltipBody {
            background: #393a3d;
            border-radius: 3px;
            top: 14px;
            left: unset;
            right: -15px;
            width: max-content;
            text-align: center;
          }
          .tooltipText {
            color: $white;
            padding: 5px 8px;
            font-size: 13px;
          }
        }
      }

      .deleteIcon {
        cursor: pointer;
        width: 14px;
        position: relative;
      }

      .renameIcon,
      .visibilityIcon {
        color: $c-8d;
        font-size: 14px;
        position: relative;
      }
    }
    .createNewStageCard {
      border: 1px dashed $contactStageBg-color;
      color: $c-8d;
      cursor: pointer;
      .iconBg i {
        font-size: 20px;
      }
    }
    .contactStageCardLoader {
      border-color: $c-dd;
      background-color: transparent;
      .contactStageName {
        width: 150px;
      }
    }
  }
}

// Used for call and contact stage
.createStagePopupWrapper,
.createCallStagePopup {
  @extend %popupCard;
  .popupHeader {
    padding-bottom: 15px;
    @include flex-align-path-justify(center, row, space-between);

    .title {
      text-align: left;
      color: $c-3b;
      font-size: 20px;
    }

    .commonPopupCloseIcon {
      font-size: 20px;
      padding: 3px;
      border-radius: 50%;
      color: $c-53;
      cursor: pointer;

      &:hover {
        color: #000;
        background-color: $c-dd;
      }
    }
  }
  hr {
    border: 0;
    border-bottom: 1px solid $bg-default;
    margin: 0;
    padding: 0;
    margin-bottom: 36px;
  }
  label {
    font-size: 12px;
  }
  .card {
    width: 400px;
    max-height: 700px;
    overflow: auto;
    text-align: center;
    padding: 20px;
    .inputWrapper {
      @extend %inputWrapper;
      &.phoneNumber {
        margin-bottom: 20px;
      }
      .activeBorder {
        border-color: $blue-violet !important;
      }
    }
    .createStageButton {
      @extend %primaryBlueBtn;
      width: 50%;
      border-radius: 10px;
    }
  }
}

.addDomainInputBtnCont {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin: 10px 0 30px;
  column-gap: 20px;
  .inputWrapper {
    @extend %inputWrapper;
    margin-bottom: 0px;
    max-width: 300px;
    &.phoneNumber {
      margin-bottom: 20px;
    }
    .activeBorder {
      border-color: $blue-violet !important;
    }
  }
  .addDomainBtn {
    @extend %primaryBlueBtn;
    min-width: 100px;
    margin: unset;
    border-radius: 5px;
    &.loading {
      transition: all 0.3s ease-in-out;
      min-width: auto;
      border-radius: 35px;
      min-width: unset;
    }
  }
}

.bulkUploadCsvBtn {
  color: $c-8d !important;
  background: $c-f9 !important;
  border: 1px solid #eceff1 !important;
  width: 340px;
  border-radius: 5px;
  margin: unset !important;
}

.domainSeperator {
  position: relative;
  display: block;
  padding-bottom: 16px;
  border: none;
  border-bottom: 1px solid rgb(224, 224, 224);
}
