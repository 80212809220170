.findMailContactPopupWrapper {
  @extend %dataStatusPopup;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  z-index: 1111;
  font-size: 17px;
  .findMailContactContent {
    display: flex;
    flex-direction: column;
    width: 450px;
    border-radius: 16px;
    overflow: hidden;
    will-change: transform;
    transition: all 0.4s ease;
    background: #fff;
    z-index: 99999999;
    padding: 30px;
    .fetchContactData {
      font-size: 18px;
      font-weight: 700;
      color: #333333;
      padding: 10px 0;
    }
    .fetchProgress {
      font-size: 16px;
      padding: 10px 0;
      color: #333333;
      .contactNotFound {
        scale: 0.8 !important;
      }
    }
  }
  .confirmation-popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
    background: rgba(0, 0, 0, 0.53);
    opacity: 0.75;
    &.bulkMask {
      z-index: 1;
    }
  }
  .container {
    position: relative;
    padding: 10px 0px;
    border-radius: 4px;
    box-sizing: border-box;
    background: #fff;
  }
  .loading {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 10px;
    background: #f1f1f1;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow: hidden;
  }
  .loading:after {
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    height: 100%;
    border-radius: 4px;
    background: #3953fb;
  }
  .loading.active:after {
    animation: loadingAnimation 3s forwards linear;
  }
  .loading.stopping:after {
    animation: stoppingAnimation 1s forwards;
  }
  @keyframes loadingAnimation {
    0% {
      width: 0;
    }
    50% {
      width: 75%;
    }
    100% {
      width: 90%;
    }
  }
  @keyframes stoppingAnimation {
    from {
      width: 90%;
    }
    to {
      width: 100%;
    }
  }
  .confirmation-popup-mask {
    z-index: 115;
  }
}
