.multiSelectOption {
  position: relative;
  border-bottom: 1px solid $c-ec;
  .insetDropdown {
    position: relative;
    display: block;
    width: calc(100% - 8px);
    margin: 0 0 10px 8px;
    z-index: 1;
    .horizontalBorderLine {
      position: absolute;
      width: 4px;
      height: 100%;
      background: #dee3ff;
      border-radius: 5px;
      left: -8px;
      top: 0px;
      z-index: 1;
    }
    .searchValueInputBox {
      position: relative;
      border-bottom: 1px solid $c-ec;
      display: grid;
      grid-template-columns: calc(100% - 20px) 18px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 5px 0px;
      height: 30px;
      letter-spacing: 0.3px;
      z-index: 1001;
      background: white;
      &.textEntered {
        grid-template-columns: calc(100% - 40px) 18px 18px;
      }
      .searchInput {
        position: relative;
        color: $c-66;
        border: none;
        padding-right: 5px;
        background: transparent;
        font-size: 14px;
        &:focus-within {
          color: $blue-violet;
        }
      }
      .clearIpIcon {
        position: relative;
        font-size: 14px;
        cursor: pointer;
        z-index: 2;
        color: $c-66;
        padding: 2px;
        border-radius: 35px;
        background: $white;
        &:hover {
          padding: 2px;
          background: $c-f5;
          color: $c-3b;
        }
      }
      .arrowIcon {
        position: relative;
        color: $c-66;
        font-size: 18px;
        z-index: 1;
        transition: all 0.3s ease-in;
        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
    .ddBox {
      position: relative;
      display: block;
      z-index: 1000;
      ul,
      .ddList {
        @extend %relBlock;
        z-index: 2;
        border-radius: 5px;
        @include list-style-zero;
        max-width: 100%;
        max-height: 180px;
        overflow-y: auto;
        padding: 0;
        background: white;
        li,
        .ddItem {
          position: relative;
          display: block;
          line-height: normal;
          padding: 6px 10px;
          background: white;
          font-size: 14px;
          cursor: pointer;
          color: $c-66;
          &:hover:not(.noResultsFound, .ddLoader) {
            background: $c-f5;
            color: $c-33;
          }
          &.noResultsFound {
            color: $c-66;
            text-align: center;
            background: $c-f9;
            padding: 10px;
          }
          .checkBoxSection {
            padding: 0 !important;
            .commonFilledCheckBox .checkBoxCard .emptyCheckBox {
              @include wt-ht(11px, 11px);
            }
            .checkBoxName,
            label {
              position: relative;
              line-height: normal;
              @include flex-center;
              max-width: 224px;
              padding-left: 6px !important;
              .nameText {
                position: relative;
                @include ellipsis();
                max-width: 170px;
              }
              .sharedBadgeWrapper {
                position: relative;
                @extend %sharedBadge;
                background-color: $c-e3;
                @include flex-center();
                margin-left: 5px;
                padding: 4px;
                border-radius: 3px;
                .ownerBadgeWrap {
                  position: relative;
                  top: 2px;
                  line-height: normal;
                  @include ellipsis();
                  max-width: 30px;
                }
                .sharedFolder {
                  font-size: 12px;
                  margin-right: 2px;
                }
              }
              .folderNameBadgeWrapper {
                position: relative;
                margin-left: 5px;
                @include flex-center();
                background-color: #f1f9ff;
                border-radius: 3px;
                padding: 4px;
                .folderNameBadge {
                  position: relative;
                  line-height: normal;
                  top: 2px;
                  letter-spacing: 0.3px;
                  font-size: 9px;
                  color: #959cf2;
                  text-transform: uppercase;
                  max-width: 50px;
                  @include ellipsis();
                }
                .sharedFolder {
                  color: #959cf2;
                  font-size: 12px;
                  margin-right: 2px;
                }
              }
            }
          }
          &.ddLoader {
            padding: 0;
            .spinnerLoader {
              @extend %spinnerIcon;
              opacity: 0.8;
              background: $c-f7;
              padding: 10px 0;
              i {
                font-size: 14px;
              }
              .text {
                font-size: 14px;
              }
            }
          }
        }
      }
      .applySelectionBtn {
        @extend %primaryBlueBtn;
        cursor: pointer;
        width: 100%;
        height: 32px;
        z-index: 2;
        border-radius: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      .ddOverlay {
        @include bg-mask(1, transparent);
      }
    }
    @extend %selectedItemsCont;
  }
  .noDataAvailable {
    position: relative;
    padding: 12px 0;
    text-align: center;
    font-size: 14px;
    background-color: $c-f5;
  }
}
