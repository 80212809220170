@mixin closeIcon {
  .popup-close {
    position: absolute;
    padding: 3px;
    top: 16.5px;
    right: 11px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
      font-size: 19px;
    }

    &:hover {
      background-color: #ddd;
      border-radius: 50%;
    }
  }
}

@mixin onBoardingStepsPopup {
  .onboardingStepsPopup {
    min-height: 270px;
    overflow: unset;
    width: 390px;
    height: fit-content;
    border-radius: 10px;
    box-shadow: 0 0 43px 0 rgba(68, 68, 68, 0.24);
    background-color: #ffffff;
    position: absolute;
    bottom: -24px;
    left: 12px;
    z-index: 9;
    &.headeWhiteBg {
      .popupHeader {
        background-color: $white;
      }
    }
    @include closeIcon();
    .popup-close {
      right: 0;
      top: -27px;
      background: #fff;
      border-radius: 50%;
      padding: 0;
      width: 25px;
      height: 25px;
      &:hover {
        background-color: #fff !important;
      }
      i {
        color: $c-66;
        font-size: 16px;
      }
    }
    .popupHeader {
      background-color: #f7f8ff;
      padding: 15px;
      display: grid;
      grid-template-columns: calc(100% - 60px) 60px;
      border-bottom: 1px solid $c-f5;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .leftSection {
        width: 100%;
        margin-right: 10px;
        
        .headerText {
          color: $c-73;
          padding: 5px 0px 0px 0px;
          @include ellipsis();
          font-size: 13px;
          line-height: 1.5;
          svg {
            @include wt-ht(14px, 14px);
          }
        }

        .completedStatus {
          padding-top: 10px;

          .statusCompletedText {
            color: $c-66;
            font-size: 12px;
            padding-top: 5px;
          }

          .statusBar {
            @include progressBar();
          }
        }
      }

      .rightSection {
        width: 100%;
        @include flex-center();
        justify-content: center;

        .headerIcon {
          width: 80px;
          height: auto;
          object-fit: contain;
        }
      }
    }

    .popupContent {
      height: 100%;

      .onboardingCompleted {
        color: $c-66;
        @include flex-center();
        justify-content: center;
        font-size: 13px;
        font-style: italic;
        padding: 15px 0px 20px 0px;

        .awesome {
          @include flex-center();

          .smileyIcon {
            font-size: 16px;
            font-style: normal;
            padding-left: 5px;
          }
        }
      }

      .contentInnerCont {
        padding: 5px 10px;
        cursor: default;

        .steps {
          color: $c-66;
          letter-spacing: 0.09px;
          padding: 15px 10px;
          font-size: 14px;
          @include flex-center();
          font-weight: normal;
          flex-direction: row;
          border-bottom: 1px solid $c-f5;
          cursor: pointer;
          position: relative;

          .circleMousePointerAnimation {
            right: 24px;
            ~ {
              .stepsText {
                font-weight: 600;
              }

              .circleIcon {
                .emptyCircle {
                  opacity: 1;
                }
              }
            }
          }
          .forwardArrow {
            font-size: 20px;
            display: none;
          }
          &:hover .forwardArrow {
            color: $blue-violet;
            display: unset;
            font-size: 20px;
            margin: 0 0 0 auto;
            animation-name: opacityTransform;
            animation-duration: 0.8s;
            animation-iteration-count: 1;
          }

          &.disabled {
            opacity: 0.7;
            cursor: default;

            svg {
              opacity: 0.7;
            }
          }

          &.filled {
            cursor: default;
            text-decoration: line-through;
          }

          .circleIcon {
            margin-right: 15px;
            @include wt-ht(20px, 20px);

            .emptyCircle {
              svg {
                @include wt-ht(20px, 20px);
                fill: $step-circle;
              }
            }

            .rotatingCircle {
              @include circleLoader();
            }

            svg {
              @include wt-ht(20px, 20px);
              fill: $progress-bar-color;
            }
          }

          .stepsText {
            line-height: 22px;
          }
        }
      }
    }

    &::before,
    &::after {
      content: "";
      display: inline-block;
      border: 8px solid transparent;
      border-right-color: $white;
      position: absolute;
      bottom: 19px;
      left: -8px;
      transform: translateX(-50%);
    }
  }
}

@mixin progressBar {
  .progress {
    -webkit-appearance: none;
    border: none;
    height: 12px;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
    border-radius: 6px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
      0 1px rgba(255, 255, 255, 0.08);

    .progress-value {
      background-color: #4fcc60;
      width: 0%;
      height: inherit;
      display: block;
      // background-image: linear-gradient(
      //   45deg,
      //   #2fdc47 25%,
      //   transparent 25%,
      //   transparent 50%,
      //   #2fdc47 50%,
      //   #2fdc47 75%,
      //   transparent 75%,
      //   transparent
      // );
      border-radius: 4px;
      transition-delay: 1s;
      transition: 0.5s linear;
      // transition-property: width, background-color;
    }

    &::-webkit-progress-bar {
      background-color: $progress-bar-bg;
    }

    &::-webkit-progress-value {
      background-color: $progress-bar-color;
    }
  }
}

@mixin onBoardingCompletedPopup {
  z-index: 10;
  height: 100%;

  .onboardingCompletedPopup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 700px;
    padding: 25px;
    padding-top: 0px;
    z-index: 1111;
    will-change: transform;
    font-size: 17px;
    font-family: $font-regular;

    .onBoardingcompletedInnerCont {
      @include flex-center();
      justify-content: center;
      flex-direction: column;
      min-width: 650px;
      height: 400px;
      border-radius: 10px;
      border-top: 0px;
      overflow: hidden;
      will-change: transform;
      transition: all 0.4s ease;
      background-color: $white;
      padding: 70px 70px 35px 70px;

      @include closeIcon();

      .title {
        @include flex-center();
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .titleName {
          color: $c-3b;
          font-size: 38px;
          font-weight: 600;
          font-family: "Graphik-Semibold";
          letter-spacing: -0.52px;
        }

        .subTitle {
          font-size: 18px;
          color: $c-66;
          padding-top: 5px;
          letter-spacing: 0.2px;
        }

        img {
          width: 35px;
          height: auto;
          margin-left: 1px;
        }
      }

      .ratingCont {
        @include ratingCont();

        .ratingSmileyCont {
          transition: all 2s ease-in-out;

          .smileyIcon {
            display: inline-grid;
          }
        }
      }

      .feedbackCont {
        @include feedBackCont();
      }
    }
  }
}

@mixin ratingCont {
  padding-top: 40px;
  @include flex-center();
  flex-direction: column;

  .ratingText {
    font-size: 13px;
    color: $c-66;
    font-weight: 600;
  }

  .thankyouFeedbackCont {
    padding-bottom: 20px;
    @include flex-center();
    align-items: center;
    justify-content: center;
    z-index: 9;

    .thumbsUpIcon {
      height: 16px;
      padding-right: 5px;
      span {
        font-size: 16px;
        color: $c-66;
      }
    }
    .thankyouText {
      font-size: 13px;
      color: #666;
    }
  }
}

@mixin feedBackCont {
  padding-top: 30px;
  @include flex-center();
  justify-content: center;
  flex-direction: row;

  .questionCont {
    font-size: 13px;
    color: $c-66;
    padding-right: 10px;
  }

  .talkToUsBtn {
    line-height: 13px;
    height: 30px;
    width: fit-content;
    padding: 15px 20px;
    font-size: 13px;
    text-transform: none;
  }
}

@mixin strikeThroughAnimation {
  .strikeThrough {
    position: relative;
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      // box-shadow: 0 1px rgba(255, 255, 255, 0.6);
      top: 50%;
      background: $c-66;
      transform-origin: center left;
      animation: strikethrough 0.5s 0.2s cubic-bezier(0.55, 0, 0.1, 1) 1;
      transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    }
  }
}

@mixin circleLoader {
  margin-bottom: 3.5em;
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

@keyframes opacityTransform {
  0% {
    opacity: 0;
    transform: translateX(-19px);
  }

  10% {
    opacity: 0.1;
    transform: translateX(-17px);
  }

  20% {
    opacity: 0.2;
    transform: translateX(-15px);
  }

  30% {
    opacity: 0.3;
    transform: translateX(-13px);
  }

  40% {
    opacity: 0.4;
    transform: translateX(-11px);
  }

  50% {
    opacity: 0.5;
    transform: translateX(-9);
  }

  60% {
    opacity: 0.6;
    transform: translateX(-7px);
  }

  70% {
    opacity: 0.7;
    transform: translateX(-5px);
  }

  80% {
    opacity: 0.8;
    transform: translateX(-3px);
  }

  90% {
    opacity: 0.9;
    transform: translateX(-1px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
