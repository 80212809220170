.upgradePopupSection {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  will-change: transform;
  user-select: none;
  width: fit-content;
  z-index: 100;
  .cloudyImg {
    position: absolute;
    top: -30px;
    right: -80px;
    width: 100%;
    z-index: 0;
  }
  .upgradeSettingsCard {
    width: 440px;
    position: relative;
    padding-top: 30px;
    padding-left: 30px;
    border-radius: 10px;
    box-shadow: 0 0 22.3px 1.7px rgba(59, 59, 59, 0.14);
    border: 1px solid #ebebeb;
    background-color: #fff;
    display: grid;
    grid-template-columns: 240px 170px;
    align-items: flex-end;
    .upgradeBody {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .upgradeTitle {
        font-size: 16px;
        font-weight: 500;
        font-family: Graphik-Medium;
        line-height: 1.3;
        letter-spacing: 0.3px;
        text-align: left;
        color: #333;
      }
      .upgradeContentBody {
        padding-bottom: 30px;
        margin-top: 40px;
        position: relative;
        button {
          background: #ff9001;
          height: 36px;
          border-radius: 6px;
          font-weight: 600;
          letter-spacing: 0.3px;
          padding: 10px 15px;
          margin: unset;
        }
      }
    }
    .upgradeImg {
      @extend %relWidth;
      img {
        max-width: 100%;
        display: block;
        margin: 0 0 0 auto;
      }
    }
  }
  .upgradeCard {
    position: relative;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 22.3px 1.7px rgba(59, 59, 59, 0.14);
    border: solid 1px $c-eb;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .upgradeBody {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      img {
        width: 180px;
      }
      .upgradeTitle {
        margin-bottom: 20px;
        font-size: 20px;
        letter-spacing: 0.3px;
        font-family: $font-regular;
        color: $c-53;
        font-weight: bold;
      }
      p {
        font-size: 16px;
        font-family: $font-regular;
        color: $c-53;
        letter-spacing: unset;
      }
      .upgradeContentBody {
        margin-top: 30px;
        position: relative;
        button {
          background: $blue-violet;
          min-width: 130px;
          border-radius: 6px;
          font-weight: 600;
          letter-spacing: 0.3px;
          padding: 12px 25px;
          color: $white;
          font-size: 16px;
          &:hover {
            background: $blue-violet;
          }
        }
        .startPlanText {
          font-family: $font-regular;
          font-size: 13px;
          line-height: 1.3;
          letter-spacing: 0.3px;
          color: $c-66;
          margin-top: 6px;
        }
      }
    }
    .upgradeImg {
      @extend %relWidth;
      margin-bottom: 30px;
      img {
        width: 130px;
        display: block;
        margin: 0 auto;
      }
    }
  }
}
.overlayUpgradeMask {
  transition: all 300ms ease;
  @include bg-mask(99, rgba(0, 0, 0, 0.08));
  &.darkBgMask {
    background-color: $c-freePlan;
  }
}

.transparentOverlay {
  transition: all 300ms ease;
  @include bg-mask(99, transparent);
}
