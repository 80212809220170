$size: 15px;
%superCharge {
  .gradIconText {
    position: relative;
    margin: 0 8px;
    .gradText {
      position: relative;
      background: linear-gradient(180deg, $c-22, $grad-yellow, $c-22);
      background: -webkit-linear-gradient(180deg, $c-22, $grad-yellow, $c-22);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    i {
      font-size: 20px;
      position: absolute;
      top: -18px;
      left: 50%;
      transform: translateX(-50%, -50%);
      will-change: transform;
      color: $grad-yellow;
    }
  }
}

%textPopular {
  @extend %relBlock;
  font-size: 11px;
  font-weight: 500;
  color: rgba(40, 40, 40, 0.5);
}

@mixin ChooseSeats {
  .chooseSeats {
    position: relative;
    @include flex-center();
    justify-content: space-between;
    min-width: 240px;
    max-width: 300px;
    height: 48px;
    padding: 0 20px;
    border-radius: 5px;
    border: solid 1px $blue-violet;
    color: $c-53;
    background: #f7f8ff;
    margin-bottom: 40px;
    .labelName {
      font-size: 16px;
    }
    .seatAddMinus {
      display: flex;
      i {
        color: $c-65;
        font-size: 20px;
        cursor: pointer;
        user-select: none;
        &.disableMinus {
          pointer-events: none;
          cursor: none;
          color: $c-dd;
        }
      }
      .seatCount {
        color: $c-22;
        font-size: 20px;
        width: 30px;
        text-align: center;
      }
    }
  }
}

@mixin PricingTrialDetails {
  .trialDetailsWrapper {
    .trialDetailsInnerCont {
      @include ChooseSeats();
      .planName {
        font-family: $font-medium;
        color: $c-42;
        font-size: 20px;
        margin-bottom: 15px;
      }

      .totalChargeText {
        font-size: 15px;
        font-family: $font-regular;
        width: 90%;
        line-height: 1.74;

        .highlight {
          font-family: $font-medium;
          border-bottom: 1px solid $bd-green;
        }
      }

      .advantageWrapper {
        margin-top: 70px;

        .advantageText {
          font-size: 13px;
          margin-top: 20px;
        }

        .advantagesList {
          @include flex-center();
          justify-content: space-between;
          flex-direction: row;
          box-shadow: 2px 6.7px 27px 0 rgba(59, 59, 59, 0.07);
          background-color: #ffffff;
          width: 100%;
          max-width: 400px;
          padding: 30px;
          margin-top: 10px;
          border-radius: 15px;

          .advantage {
            &.crmSync {
              .text {
                width: 50px;
              }
            }

            flex-direction: column;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 100px;
            width: 75px;

            img {
              width: 50px;
              height: auto;
              object-fit: contain;
            }

            .text {
              color: $c-42;
              font-family: $font-regular;
              font-size: 13px;
              width: 75px;
              text-align: center;
              word-break: break-word;
              white-space: normal;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  .paymentForm {
    .chargeNote {
      font-size: 11px;
      color: $c-74;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: center;
      font-family: $font-regular;
      padding-top: 10px;
    }
  }
}

@mixin TrialSuccess {
  .trialSuccess {
    padding-top: 15px;
    .descCont {
      .successMsgText {
        font-size: 24px;
        font-family: $font-medium;
        padding-bottom: 8px;
      }

      .desc {
        font-family: $font-medium;
        font-size: 18px;
      }
    }
    .demo {
      @include flex-center();
      justify-content: center;
      flex-direction: column;
      padding-top: 50px;

      .demoText {
        font-size: 15px;
      }

      .btnCont {
        @include flex-center();
        padding-top: 15px;
        flex-direction: row;
        justify-content: space-between;
        width: 350px;

        .btn {
          &:hover {
            box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
          }
        }

        .notNow {
          border: solid 1px $b-c-1 !important;
          background: #fff !important;
          color: $c-66 !important;
          width: 120px;
          padding: 10px;
        }

        .bookMeeting {
          padding: 10px;
          background-color: $hb-text !important;
          width: 200px !important;
        }
        svg {
          width: 25px;
          padding-left: 10px;
          position: absolute;
          right: 80px;

          path {
            fill: $white;
          }
        }
      }
    }
  }
}

/* ------------------ */
.pricingSection {
  @extend %relBlockWidth;
  height: 100%;
  overflow-y: auto;
  letter-spacing: 0.3px;
  background-color: #f5f8fd;
  @media (max-width: 1080px) {
    padding: 0 0px 25px;
  }
  color: $c-66;
  .pricingHeaderBg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 220px;
    background: $lg-blue;
  }

  .pricingBody {
    @extend %relBlockWidth;
    // padding: 30px;
    overflow-y: auto;
    .pricingCard {
      border-radius: 10px;
      background: $white;
      box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.05);
      display: grid;
      grid-template-columns: 280px calc(100% - 280px);
      @media (max-width: 1080px) {
        grid-template-columns: 100%;
      }

      .pricingLeftContent {
        @media (max-width: 1080px) {
          display: none;
        }
        @include bor-rad-left(10px, 10px);
        @extend %relBlockWidth;
        background: $lg-blue;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        @media (max-width: 1080px) {
          padding-left: 15px;
          padding-right: 15px;
        }
        z-index: 1;
        .layerBg {
          position: absolute;
          display: block;
          z-index: -1;
          left: 0;
          top: 0;
        }
        img {
          width: 100%;
        }
        h2 {
          color: $c-3b;
          position: relative;
          font-family: $font-medium;
          font-size: 32px;
          padding-left: 35px;
          padding-right: 35px;
          margin-top: 80px;
          &.title {
            padding-top: 30px;
            margin-bottom: 15px;
            @extend %superCharge;
          }
        }
        p.descPolicy {
          padding-left: 35px;
          padding-right: 35px;
          position: relative;
          font-size: 14px;
          color: $c-53;
          line-height: 1.5;
        }
        .userLayer {
          padding-left: 10px;
          padding-right: 10px;
          position: relative;
          margin-top: 30px;
          display: flex;
          img {
            height: auto;
          }
        }
      }
      .pricingContentBody {
        padding: 25px;
        overflow: hidden;
        @extend %relBlockWidth;
        .wrapPlanNameType {
          @include flex-center;
          margin-bottom: 20px;
          .backBtn {
            @include flex-center;
            font-size: 14px;
            cursor: pointer;
            i {
              font-size: 16px;
              line-height: 17px;
            }
            span {
              padding: 0 5px;
            }
          }
          .planNameType {
            position: relative;
            margin-left: 20px;
            font-size: 16px;
            color: $blue-violet;
          }
        }
        .pricngFeatureSection {
          @extend %relBlockWidth;
          overflow-x: auto;
          .pricingFeatureTable {
            min-width: 600px;
            width: 100%;
            position: relative;
            display: grid;
            grid-template-columns: 34% 22% 22% 22%;
            .featureList {
              border-top: 2px solid transparent;
              border-bottom: 2px solid transparent;
              &.popularPlan {
                border: 2px solid #ffe22a;
                background: #fffef4;
                border-radius: 15px;
              }
              &.activePaidPlan {
                border: 2px solid #b2cad6;
                background: #f7f8ff;
                border-radius: 15px;
              }
              @extend %relBlockWidth;
              &.customPlan {
                @include flex-direction("column");
                align-items: center;
                padding: 0;
                @include wt-ht(100%, 100%);
                .planNameText {
                  position: absolute;
                  height: 48px;
                  margin: 0 auto;
                  width: 100%;
                  @include flex-center();
                  top: 0;
                  justify-content: center;
                  color: $c-53;
                  font-size: 18px;
                  font-weight: 500;
                  font-family: $font-medium;
                }

                .customFeature {
                  @include flex-direction(column);
                  text-align: center;
                  width: 100%;
                  img {
                    margin-bottom: 20px;
                  }
                  p.text {
                    font-size: 14px;
                    margin-bottom: 20px;
                    line-height: 1.5;
                  }
                  .chatBtn {
                    outline: none;
                    line-height: 14px;
                    font-size: 14px;
                    letter-spacing: 0.3px;
                    background: $white;
                    border: 1px solid $blue-violet;
                    color: $blue-violet;
                    transition: all 300ms ease;
                    padding: 8px 20px;
                    border-radius: 3px;
                    cursor: pointer;
                    text-decoration: none;
                    &:hover {
                      background: $blue-violet;
                      color: $white;
                      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
                      transition: all 300ms ease;
                    }
                  }
                }
              }
              .planName {
                padding: 0 10px;
                height: 48px;
                @include flex-center();
                color: $c-53;
                font-size: 18px;
                font-weight: 500;
                font-family: $font-medium;
                &.planSelected {
                  color: $blue-violet;
                  i {
                    padding-right: 5px;
                    font-size: 20px;
                  }
                }
              }
              .featureName,
              .currentPlan,
              .planCost {
                text-align: center;
                padding: 0 5px;
                white-space: nowrap;
                height: 42px;
                @include flex-center();
                color: $c-66;
                font-size: 14px;
                border-bottom: 1px solid $c-ee;
                i {
                  font-size: 20px;
                  &.checked {
                    color: $color-green;
                  }
                }
                b {
                  color: $c-22;
                  margin-right: 4px;
                }
                &.activePlanDetail {
                  height: auto;
                  padding: 10px 5px;
                  display: block;
                  white-space: unset;
                  span {
                    position: relative;
                    display: block;
                    white-space: nowrap;
                    &:not(:last-child) {
                      margin-bottom: 2px;
                    }
                    &.showCurrentPlan {
                    }
                    &.seatPrice {
                      color: $c-66;
                      font-size: 13px;
                      font-weight: 600;
                    }
                    &.billType {
                      color: $c-78;
                      font-size: 12px;
                    }
                  }
                }
              }
              .planCost,
              .currentPlan {
                border-bottom: 1px solid transparent;
              }
              .justifyCenter {
                justify-content: center;
              }
            }
          }
        }
        .redirectPaymentBtn {
          @include btnWithArrow(30px, flex-end, 20px, 14px, 10px);
        }
        .pricingPaymentSection {
          position: relative;
          display: grid;
          grid-template-columns: repeat(2, 50%);
          .paymentForm {
            @extend %relBlockWidth;
            box-shadow: 0 0 32.3px 5.7px rgba(59, 59, 59, 0.07);
            background-color: $white;
            padding: 30px;
            border-radius: 6px;
            .formInputGroup {
              display: grid;
              grid-template-columns: repeat(2, calc(50% - 10px));
              justify-content: space-between;
              row-gap: 10px;
              position: relative;
            }
            .cardInputWrapper {
              @extend %relBlockWidth;

              .cardInput {
                border: 1px solid $c-ee;
                padding: 15px 10px;
                border-radius: 3px;
                margin: 20px 0;
                .cardLabel {
                  position: relative;
                  font-size: 14px;
                  display: block;
                  margin-bottom: 10px;
                }
              }
              .errMsgText {
                @extend %errMsgText;
                bottom: unset;
                top: 51px;
              }
            }
            .formDivider {
              margin: 20px 0 35px;
              position: relative;
              display: flex;
              .halfCircle {
                background: #f6f6f6;
                position: absolute;
                height: $size * 2;
                width: $size;
                &.left {
                  right: -30px;
                  border-bottom-left-radius: $size * 2;
                  border-top-left-radius: $size * 2;
                }
                &.right {
                  left: -30px;
                  border-bottom-right-radius: $size * 2;
                  border-top-right-radius: $size * 2;
                }
              }
              .dashedLine {
                position: relative;
                display: block;
                padding: 15px 15px 0 15px;
                width: 90%;
                margin: 0 auto;
                border-bottom: 1px dashed $c-cc;
              }
            }
            .inputWrapper {
              @extend %inputWrapper;
            }
            .submitSection {
              text-align: center;
              margin: 35px 0 35px;
              button {
                user-select: none;
                background-color: $blue-violet;
                border-color: $blue-violet;
                color: $white;
                outline: none;
                font-weight: bold;
                border: none;
                letter-spacing: 0.3px;
                border-radius: 4px;
                padding: 0px 20px;
                height: 40px;
                font-size: 14px;
                margin: 0 auto;
                width: 90%;
                text-align: center;
                cursor: pointer;
                &.loading {
                  border-radius: 50%;
                  width: auto;
                }
                &:hover {
                  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
                }
              }
            }
          }
          .paymentForm::after {
            background: linear-gradient(-45deg, #f8f8f8 16px, transparent 0),
              linear-gradient(45deg, #f8f8f8 16px, transparent 0);
            background-position: bottom;
            background-repeat: repeat-x;
            background-size: 22px 22px;
            content: " ";
            display: block;
            position: absolute;
            bottom: -5px;
            left: 0px;
            width: 100%;
            height: 24px;
          }
          .seatBillingSection {
            padding-right: 30px;
            width: 90%;
            @extend %relBlockWidth;
            .title {
              font-size: 26px;
              font-weight: 600;
              line-height: 1.35;
              letter-spacing: 0.3px;
              color: $c-3b;
              margin-bottom: 15px;
            }
            @include ChooseSeats();
            .calenderFilterSection {
              @extend %relBlockWidth;
              padding: 20px 10px;
              border-top: 2px dotted $c-e1;
            }
            .seatPriceTable {
              position: relative;
              padding: 20px 10px;
              border-top: 2px dotted $c-e1;
              display: flex;
              grid-template-columns: 30% 5% 30% 5% 30%;
              align-items: center;
              justify-content: space-between;
              flex-direction: row;
              .detailCard {
                @include flex-direction(column);
                align-items: flex-start;
                .text {
                  font-size: 14px;
                }
                .value {
                  margin-top: 10px;
                  font-size: 16px;
                  font-weight: bold;
                }
              }
              .multiplySymbol {
                position: relative;
                align-self: flex-end;
                i {
                  color: $c-65;
                  font-size: 10px;
                }
              }
            }
            .totalAmount {
              border-top: 2px dotted $c-e1;
              border-bottom: 2px dotted $c-e1;
              padding: 20px 10px;
              @include flex-center();
              justify-content: space-between;
              .text {
                color: $blue-violet;
                font-size: 14px;
              }
              .priceAmount {
                font-size: 16px;
                color: $blue-violet;
                font-weight: bold;
              }
            }
          }

          @include PricingTrialDetails();
        }
      }
    }
  }
}

.pricingBody {
  padding: 40px;
  .paymentSuccess {
    position: relative;
    padding: 60px 0;
    @include wt-ht(470px, 100%);
    margin: 0 auto;
    color: $c-66;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @extend %successGreenTick;
    .thankYouMsgText {
      margin: 25px 30px 20px;
      font-size: 32px;
      font-family: $font-medium;
      font-weight: 600;
      line-height: 1.4;
      letter-spacing: 1px;
      text-align: center;
      color: $c-22;
    }
    hr {
      width: 240px;
      border: none;
      margin-bottom: 30px;
      border-bottom: 2px dotted $c-e1;
    }
    .spinnerLoader {
      @extend %spinnerIcon;
    }
    .infoText {
      font-size: 14px;
      color: $c-78;
    }

    @include TrialSuccess();
  }
}

.pricingPageSection {
  &.logoutPricing {
    .pricingCard,
    .pricingCardLoader {
      min-height: 800px !important;
    }
  }

  %sectionTitle {
    @extend %relBlock;
    font-size: 24px;
    margin-bottom: 30px;
    color: $c-66;
    text-align: center;
    font-weight: 500;
  }

  .pricingTitle {
    @extend %relBlockWidth;
    padding: 30px;
    h1.title {
      position: relative;
      margin-bottom: 10px;
      font-family: $font-medium;
      font-weight: 700;
      font-size: 30px;
      color: $c-3b;
      letter-spacing: 1.5px;
      line-height: 1.3;
      @extend %superCharge;
    }
    p.text {
      font-size: 16px;
      margin: 0;
      color: $c-53;
      line-height: 1.5;
    }
  }
  .pricingTextSection {
    width: 100%;
    text-align: center;
  }
  .pricingTableSection {
    @extend %relBlock;
    .pricingBlueBg {
      position: absolute;
      height: 300px;
      background: #f5f9ff;
      width: 100%;
      .pricingBgImg {
        width: 100%;
      }
    }
    .pricingToogle {
      @extend %relBlock;
      padding: 30px 0;
      text-align: center;
      .toggleBtnSection {
        justify-content: center;
        .toogleButton {
          @include wt-ht(60px, 30px);
        }
        .rightPad {
          font-size: 18px;
        }
        .leftPad {
          font-size: 18px;
        }
        :checked + .knobs:before {
          left: 28px;
        }
        .knobs::before {
          border-radius: 35px;
          top: 4px;
          left: 4px;
          @include wt-ht(28px, 22px);
        }
        .offerBadge {
          font-size: 12px;
          height: 24px;
        }
      }
    }
    .planInfo {
      @extend %relBlockWidth;
      border-bottom: 1px dashed $c-e0;
      min-height: 220px;
      .pricingPlan {
        width: 100%;
        @include flex-center;
        justify-content: center;
        text-align: center;
        margin-bottom: 24px;
        font-size: 14px;
        .planTagSvg {
          width: fit-content;
          border-radius: 16px;
        }
        .upgradeBadge {
          margin-right: unset !important;
          .text {
            line-height: 1.3 !important;
          }
        }
      }
      .planName {
        @extend %relBlock;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.3px;
        text-align: center;
        color: $c-53;
        &.linear-background {
          border-radius: 35px;
          margin: 0 auto 24px;
          @include wt-ht(90px, 32px);
        }
      }
      .planPrice {
        @extend %relBlock;
        text-align: center;
        color: $c-66;
        letter-spacing: 0.3px;
        min-height: 64px;
        &.borderNone {
          border: none;
        }
        &.planPriceLoader {
          @include flex-center;
          justify-content: center;
          margin-top: 10px;
          .dollar {
            position: relative;
            opacity: 1;
            &.linear-background {
              border-radius: 35px;
              @include wt-ht(16px, 16px);
            }
          }
          .seatText {
            position: relative;
            opacity: 1;
            &.linear-background {
              border-radius: 35px;
              @include wt-ht(60px, 16px);
            }
          }
        }
        .discountPrice {
          position: relative;
          font-weight: bolder;
          font-family: $font-semi-bold;
          color: rgba(102, 102, 102, 0.5);
          margin-right: 10px;
          font-size: 18px;
          text-decoration: line-through;
          text-decoration-color: rgba(102, 102, 102, 0.5);
        }
        .dollar,
        .seatText {
          font-size: 16px;
          font-weight: 500;
        }
        .price {
          font-family: $font-semi-bold;
          font-size: 36px;
          font-weight: 500;
          &.linear-background {
            border-radius: 6px;
            @include wt-ht(50px, 42px);
            margin-right: 5px;
            margin-left: 2px;
          }
        }
      }
      .upgradeBtn {
        @include flex-center;
        justify-content: center;
        @include wt-ht(140px, 42px);
        border-radius: 8px;
        border: solid 1px $blue-violet;
        color: $blue-violet;
        margin: 10px auto 0px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.3px;
        padding: 10px 0;
        cursor: pointer;
        &:hover {
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.06);
        }
      }
      .trialMessageWrapper {
        position: relative;
        min-height: 42px;
        @include flex-center;
        justify-content: center;
        .currentlyInTrialMessage {
          color: $blue-violet;
          font-size: 16px;
          position: relative;
          text-align: center;
          margin: 0 auto;
          font-style: italic;
        }
      }

      .currentPlanBtn {
        position: relative;
        @include flex-center;
        justify-content: center;
        @include wt-ht(140px, 42px);
        border-radius: 8px;
        border: solid 1px $blue-violet;
        background: $white;
        color: $blue-violet;
        margin: 10px auto 0px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.3px;
        padding: 10px 0;
        cursor: pointer;
        &.linear-background {
          border: solid 1px $c-ee;
          color: transparent;
        }
      }

      .isTrialText {
        text-align: center;
        font-size: 12px;
        font-style: italic;
        color: $blue-violet;
        padding: 5px 0px;
      }
      .requestFreeTrial {
        @include flex-center;
        justify-content: center;
        @include wt-ht(180px, 42px);
        border-radius: 8px;
        border: solid 1px #3953fe;
        background: transparent;
        color: #3953fe;
        margin: 10px auto 0px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.3px;
        padding: 10px 0;
        cursor: pointer;
        &:hover {
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.06);
        }
      }
      .noCardReq,
      .noCardReqLoader {
        @extend %relBlock;
        text-align: center;
        color: $c-66;
        font-weight: 400;
        font-size: 14px;
        padding: 15px 0;
        opacity: 0.2;
        .linear-background {
          opacity: 1 !important;
          @extend %relBlock;
          margin: 0 auto;
          @include wt-ht(130px, 12px);
        }
      }
      .noCardReqLoader {
        min-height: 52px;
        opacity: 1;
      }
    }
    .pricingList {
      position: relative;
      display: grid;
      grid-template-columns: repeat(4, 310px);
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      padding: 0 15px;
      column-gap: 15px;
      .creditsInfo {
        @extend %relBlock;
        text-align: left;
        font-size: 16px;
        color: $c-66;
        padding: 15px 0;
        .dataHeading {
          padding-bottom: 15px;
        }
        .unlimitedCredits {
          position: relative;
          .text {
            position: relative;
            display: flex;
          }
          i {
            cursor: pointer;
            font-size: 14px;
            color: $c-66;
            opacity: 0.3;
            padding-left: 4px;
            padding-top: 2px;
            width: 20px;
          }
        }
        .linear-background {
          @extend %relBlock;
          margin: 5px auto;
          @include wt-ht(160px, 14px);
          &.icon {
            @include wt-ht(160px, 14px);
          }
        }
      }
      .pricingCardLoader {
        .dataHeading {
          margin-bottom: 16px;
          width: 30%;
        }
        .creditsInfoLoader {
          margin-top: 20px;
        }
        .planFeatureList {
          height: 445px !important;
        }
      }
      .pricingCard,
      .pricingCardLoader {
        @extend %relBlock;
        border-radius: 10px;
        box-shadow: -1px -1px 20px 12px #f7f8ff;
        background-color: white;
        align-self: stretch;
        padding: 30px 20px 30px;
        min-height: 940px;
        flex: 0 0 22%;
        &:last-child {
          margin-right: 0;
        }
        .fiftypercentPopular {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 11px;
          font-weight: 600;
          padding: 8px;
          border-radius: 5px;
          background: #fff5e5;
          .wrapDiscountIng {
            position: relative;
            @include flex-center;
            margin-bottom: 3px;
            .fiftyPercentImg {
              @include wt-ht(14px, 14px);
              margin-right: 5px;
            }
            .offPercent {
              @extend %textPopular;
              font-weight: bolder;
            }
          }
          .launchText {
            @extend %textPopular;
          }
        }

        .planFeatureCard {
          @extend %relBlock;
          margin-top: 24px;
          .planCombineText {
            @extend %relBlock;
            opacity: 0.5;
            font-size: 14px;
            font-style: italic;
            line-height: 2.54;
            text-align: left;
            color: rgba(102, 102, 102, 0.5);
            &.linear-background {
              @include wt-ht(140px, 14px);
              border-radius: 35px;
              margin-bottom: 15px;
            }
          }
          .planFeatureList {
            @extend %relBlock;
            height: 580px;
            border-bottom: 1px dashed $c-e0;
            .freePlanTooltip {
              .customTooltip .tooltipBody {
                right: 0;
                left: -32px;
              }
            }
            .planText {
              margin-bottom: 12px;
              font-style: italic;
              .highlightPlanText {
                color: $c-freePlan;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
    /* On screens that are 1100px or less, reduce the grid template size */
    @media (max-width: 1300px) {
      .pricingList {
        grid-template-columns: repeat(4, 250px);
      }
    }

    .planItem {
      display: grid;
      grid-template-columns: 30px calc(100% - 30px);
      align-items: flex-start;
      list-style-type: none;
      margin-top: 5px;
      font-size: 14px;
      &.extraHeight {
        height: 20px;
      }
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      svg {
        @include wt-ht(18px, 18px);
      }
      .icon {
        @include wt-ht(18px, 18px);
        border-radius: 35px;
        margin-bottom: 12px;
      }
      .infoIconTp {
        position: relative;
        cursor: pointer;
        bottom: 2px;
        i {
          font-size: 14px;
          cursor: pointer;
          margin-left: 2px;
          opacity: 0.3;
          color: #3c5afa;
          vertical-align: middle;
        }
      }
      .itemName,
      p {
        position: relative;
        @include flex-center();
        color: $c-66;
        font-size: 14px;

        &.linear-background {
          @include wt-ht(calc(100% - 30px), 14px);
          border-radius: 35px;
          margin-bottom: 12px;
        }
      }
    }
    .freePlanFeatureListCard {
      position: relative;
      border-radius: 10px;
      box-shadow: -1px -1px 20px 12px #f7f8ff;
      background-color: white;
      padding: 30px;
      display: grid;
      grid-template-columns: 24% 6% 70%;
      max-width: 88%;
      margin: 50px auto;
      .planInfo {
        min-height: unset;
      }
      .verticalLine {
        border-right: 1px dashed $c-e0;
        width: 1px;
      }
      .freePlanFeatureList {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        height: fit-content;
        align-items: center;
        li {
          display: grid;
          grid-template-columns: 30px calc(100% - 30px);
          align-items: center;
          list-style-type: none;
          position: relative;
          margin-top: 5px;
          svg {
            @include wt-ht(18px, 18px);
          }
          .itemName {
            position: relative;
            display: flex;
            align-items: center;
            color: $c-66;
            font-size: 14px;
          }
          margin-bottom: 15px;
        }
      }
    }
  }

  .enterprisePlanSection {
    @extend %relBlockWidth;
    margin: 80px 0px 100px;
    text-align: center;
    .enterprisePlan {
      position: relative;
      .enterpriseImg {
        max-width: 100%;
      }
      .aeFeatureSection {
        @extend %relBlock;
        .title {
          @extend %sectionTitle;
          margin-bottom: 5px;
        }
        .desc {
          @extend %relBlock;
          text-align: center;
          font-size: 16px;
          color: $c-66;
          margin-bottom: 40px;
          font-weight: 600;
        }
        .aeFeatureList {
          position: relative;
          display: grid;
          align-items: center;
          grid-template-columns: 240px;
          justify-content: center;
          .card {
            @extend %relBlock;
            border: 1px solid $c-ee;
            border-radius: 10px;
            padding: 30px;
            transition: all 0.3s;
            cursor: pointer;
            &:not(:last-child) {
              margin-right: 30px;
            }
            img {
              @include wt-ht(auto, 26px);
              margin-bottom: 10px;
            }
            .name {
              @extend %relBlock;
              font-size: 14px;
              font-weight: 300;
              color: rgba(102, 102, 102, 0.5);
              margin-bottom: 2px;
            }
            .value {
              @extend %relBlock;
              font-size: 16px;
              font-weight: 400;
              color: $c-66;
            }
            .hoverArrowForward {
              position: absolute;
              display: none;
              right: 20px;
              font-size: 24px;
              bottom: 10px;
            }
            &:hover {
              box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.06);
              border-color: #3c5afa;
              transition: all 0.3s;
              .hoverArrowForward {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .crmSection {
    @extend %relBlock;
    margin: 0 0 100px;
    .title {
      @extend %sectionTitle;
    }
    .crmIconList {
      position: relative;
      z-index: 1;
      display: grid;
      grid-template-columns: 150px 150px 150px 150px 150px;
      align-items: center;
      padding-top: 20px;
      justify-content: space-around;
      width: 890px;
      margin: 0 auto;
      overflow-x: auto;
      .crmIcon {
        position: relative;
        @include flex-center;
        justify-content: center;
        height: 60px;
        object-fit: contain;
        border-radius: 8px;
        box-shadow: 0 0 54.9px 4.1px rgba(203, 203, 203, 0.16);
        background-color: white;
      }
    }
    .crmOverlayLeft {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 0;
    }
    .crmOverlayRight {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 0;
    }
  }
  .freqAskQuesSection {
    @extend %relBlock;
    margin: 0 0 40px;
    padding: 20px 40px 0;
    .title {
      @extend %sectionTitle;
      margin-bottom: 20px;
    }
    .freqAskQuesRow {
      position: relative;
      display: grid;
      grid-template-columns: 50% 50%;
      .imgCol {
        padding: 0 60px 0 20px;
        img {
          max-width: 100%;
        }
      }
      .contentCol {
        @extend %relBlock;
        padding-top: 20px;
        p {
          padding: 0 20px 30px;
        }
      }
    }
  }
}

.loginPricing {
  .pricingContainer {
    position: relative;
    background: #fff;
    height: 100%;
    border-radius: 10px;
    border: 1px solid $b-c-1;
    box-shadow: 0 0 12px 2.7px rgba(59, 59, 59, 0.04);
    transition: 0.5s;
    flex: 1;
  }
  @media (min-width: 1600px) {
    .pricingContainer > div {
      margin-left: auto;
      margin-right: auto;
      max-width: 1366px;
    }
  }
}
.pricingWrapper {
  display: flex;
  margin: 12px;
}
