.taskAttachmentList {
  position: relative;
  @include flex-center;
  padding: 10px;
  line-height: normal;
  flex-wrap: wrap;
  gap: 6px;
  .taskAttachmentItem {
    position: relative;
    @include flex-center;
    border-radius: 15px;
    padding: 2px 5px 2px 8px;
    border: 1px solid $c-ee;
    line-height: normal;
    .taskAttName {
      position: relative;
      font-size: 12px;
      color: $blue-violet;
      @include ellipsis;
      max-width: 140px;
      line-height: normal;
    }
    .taskAttSize {
      position: relative;
      font-size: 12px;
      padding: 0 5px;
      color: $c-78;
      line-height: normal;
    }
    .taskAttCloseIcon {
      position: relative;
      font-size: 12px;
      color: $c-78;
      padding: 2px;
      border-radius: 35px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        color: $c-33;
        background: $c-f5;
      }
    }
    &:hover .taskAttSize {
      color: $c-66;
    }
  }
}

.performTaskManualEmailEditor.taskAttachments#reactEditor .quill .ql-container {
  height: unset !important;
  min-height: 160px;
  max-height: calc(100% - 42px);
}
