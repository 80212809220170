.filterByContactTags {
  @extend %relBlock;
  margin-left: 10px;

  &:hover {
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
  }

  .filterContactTagBtn {
    position: relative;
    @include flex-center();
    border: 1px solid $c-eb;
    padding: 10px;
    border-radius: 5px;
    color: $c-59;
    background: white;
    cursor: pointer;
    @include wt-ht(36px, 36px);
    svg {
      @include wt-ht(16px, 16px);
      fill: $blue-violet;
    }
    .customTooltip {
      margin-top: 35px;
      background-color: #393a3d;
      .sharpPointer {
        border-right: 1px solid #393a3d;
        border-top: 1px solid #393a3d;
        background-color: #393a3d;
        right: -2px;
        top: -56px;
        border-top-right-radius: 35px;
      }
      .tooltipBody {
        width: fit-content;
        border-radius: 5px;
        right: -10px;
        white-space: nowrap;
        background-color: #393a3d;
        right: -39px;
        top: -81px;
        .tooltipText {
          color: $white;
          padding: 8px;
        }
      }
    }
  }

  .filterTagDropdown {
    background: white;
    border-radius: 10px;
    border: 1px solid $c-ee;
    position: absolute;
    width: 240px;
    right: 0;
    z-index: 99;
    box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.08);

    .sharpPointer {
      position: absolute;
      border-top-right-radius: 5px;
      padding: 8px;
      border-right: 1px solid $c-f5;
      border-top: 1px solid $c-f5;
      z-index: 4;
      top: -8px;
      right: 10px;
      transform: rotate(-45deg);
      background: white;
    }

    .dropdownTitle {
      font-size: 14px;
      padding: 10px 20px 8px;
      color: $c-66;
      font-weight: 500;
      letter-spacing: 0.3px;
    }

    .tagsNotFound {
      color: $c-44;
      background: $c-f5;
      font-size: 14px;
      text-align: center;
      padding: 10px;
    }

    .searchInputBox {
      width: 238px;
      border-left: none;
      border-right: none;
      background: $c-f5f8;
      border-radius: 0 !important;
      .searchInputBtn .searchTextBox input {
        width: 212px;
        padding: 5px 20px 5px 10px;
        font-size: 14px;
        background: $c-f5f8;
      }
    }

    .resetSelectAllWrap {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 50%);
      align-items: center;
      justify-content: center;
      text-align: center;
      border-bottom: 1px solid $b-c-1;

      .selectAll {
        border-right: 1px solid $b-c-1;
      }
      .selectAll,
      .resetAll {
        font-size: 14px;
        padding: 8px 5px;
        cursor: pointer;
        line-height: 1.3;
        transition: all 0.3s;
        &:hover {
          background: $c-f5;
          color: $blue-violet;
          font-weight: 500;
        }
      }
    }

    .tagList {
      @extend %relBlock;
      max-height: 180px;
      overflow-y: auto;
      width: calc(100% - 20px);
      margin: 5px 10px;

      .tagItem {
        @include flex-center();
        padding: 2px 0;
        border-radius: 3px;
        transition: all 0.3s;
        color: $c-66;

        cursor: pointer;
        .checkBoxSection {
          width: 100%;
          .commonFilledCheckBox.checked {
            background: $blue-violet;
            border-color: $blue-violet;
          }
          .commonFilledCheckBox.checked ~ label {
            color: $blue-violet;
            width: 100%;
            @include ellipsis();
          }
          .checkSvgIcon {
            background: $blue-violet;
            box-shadow: 0 0 0 1px $blue-violet;
          }
        }
        i {
          margin-right: 10px;
          font-size: 14px;
        }
        .tagName {
          font-size: 14px;
        }
        &:hover {
          background: $c-f5;
          transition: all 0.3s;
        }
      }
    }

    .fade {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .filterTagOverlay {
    transition: all 300ms ease;
    @include bg-mask(98, transparent);
  }
}
