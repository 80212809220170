.sequenceTemplatePopupSection,
.predefinedSequenceSection,
.generateAISequenceSection {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  @include flex-center();
  justify-content: center;
  letter-spacing: 0.3px;
  width: 100%;
  z-index: 100;
  overflow: auto;
  .card {
    position: relative;
    z-index: 102;
    border-radius: 10px;
    background: white;
    height: fit-content;
    padding: 20px;
    width: 50%;
    .linear-background {
      border-radius: 10px;
    }
    .createSequenceTitle {
      color: $c-3b;
      font-weight: 500;
      text-align: center;
      font-size: 24px;
      margin-bottom: 20px;
    }
    #closeSequenceTemplatePopup {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 102;
      font-size: 22px;
      padding: 2px;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.2s ease-in;
      &:hover {
        background: $c-dd;
        color: $black;
        transition: all 0.2s ease-in;
      }
    }
    .TitleBadge {
      @extend %relBlock;
      margin-bottom: 24px;
      .title {
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 8px;
        color: $c-53;
        text-align: center;
      }
      .descText {
        font-size: 14px;
        color: $c-8d;
        text-align: center;
      }
      .seqStepsDaysWrapper {
        font-size: 14px;
        color: $c-8d;
        .totalStages {
          margin-right: 6px;
        }
      }
    }
    .createSequenceSection {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #f9f9ff;
      padding: 10px 14px;
      @include wt-ht(100%, fit-content);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      transition: all 0.4s;
      bottom: -128px;
      &.showPanel {
        display: flex;
        bottom: 0;
      }
      .createSequenceBox {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .descText {
          font-size: 14px;
          line-height: 1.5;
          text-align: center;
          color: $c-53;
          padding-bottom: 15px;
        }
        .inputTextBox {
          @extend %relBlock;
          label {
            font-size: 12px;
            text-align: left;
            display: flex;
          }
          input {
            height: 36px;
            padding: 0 10px;
            object-fit: contain;
            border-radius: 5px;
            box-shadow: 0px 5px 12.4px 0.7px rgba(59, 59, 59, 0.07);
            border: solid 1px $c-eb;
            background-color: white;
            width: 100%;
          }
          .errMsgText {
            position: absolute;
            left: 0;
            right: unset;
            bottom: -15px;
          }
        }
      }
      .inputError {
        position: relative;
      }
      button.animatedButton {
        background: $blue-violet;
        margin: 15px auto 0;
        text-align: center;
        padding: 10px;
        width: 100%;
      }
    }
    .predefinedTemplatesSection {
      @extend %relBlock;
      .TemplateList {
        @extend %relBlock;
        @include wt-ht(100%, calc(100% - 92px));
        overflow: auto;
        padding: 0 4px 0 0;
        .templateItem {
          position: relative;
          display: grid;
          padding: 10px;
          cursor: pointer;
          grid-template-columns: 24px calc(100% - 24px);
          border: 1px solid $c-dd;
          border-radius: 10px;
          margin-bottom: 16px;
          &:hover {
            transition: all 0.3s ease;
            background: #f5f5f5;
            color: #333;
          }
          &.templateCardLoader {
            height: 84px;
          }
          .iconSection {
            &.linear-background {
              width: 15px;
            }
            svg {
              margin-top: 2px;
              @include wt-ht(14px, 14px);
            }
          }
          .templateDetailInfo {
            @extend %relBlock;
            h6.title {
              font-size: 16px;
              margin-bottom: 8px;
              font-weight: 800;
              font-stretch: normal;
              font-style: normal;
              text-align: left;
              color: $c-53;
              &.linear-background {
                width: 65%;
                margin-bottom: 15px;
              }
            }
            p.descText {
              font-size: 14px;
              line-height: 1.4;
              text-align: left;
              color: $c-8d;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              overflow: hidden;
            }
            .descTextLoader.linear-background {
              width: 90%;
              margin-top: 7px;
              height: 12px;
            }
          }
          &.activeTemplate {
            background: #eceefe;
            border-radius: 3px;
            .iconSection svg {
              fill: $blue-violet;
            }
            .templateDetailInfo h6 {
              color: $blue-violet;
            }
          }
          .clickIcon {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
          }
        }
      }
    }
    .sequenceStagesSection {
      @extend %relBlock;
      z-index: 101;
      width: 100%;
      height: 521px;
      transition: all 0.3s ease;
      .seqTitleDurationWrapper {
        display: grid;
        grid-template-columns: 55% auto;
        margin-bottom: 10px;
        align-items: center;
        justify-content: space-between;
        .seqName {
          color: $blue-violet;
          font-size: 14px;
          letter-spacing: 0.3px;
          font-weight: 600;
          @include ellipsis();
          font-weight: blue-violet;
        }
      }
      .stageStepList {
        @extend %relBlock;
        @include wt-ht(100%, calc(100% - 188px));
        overflow: auto;
        padding: 0 6px 0 0;
        .stageCard {
          width: 100%;
          @extend %relBlock;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 5px;
          }
          border-radius: 10px;
          border: solid 1px $c-eb;
          background-color: $white;
          .stageInfo {
            padding: 15px;
            position: relative;
            @include flex-center();
            justify-content: space-between;
            .stageTypeInfoWrapper {
              position: relative;
              @include flex-center();
              .iconType {
                @include wt-ht(15px, 15px);
                margin-right: 5px;
                align-self: baseline;
                svg {
                  margin-right: 5px;
                  fill: $c-33;
                  @include wt-ht(15px, 15px);
                }
              }
              .stageTitle {
                font-size: 15px;
                text-align: left;
                color: $c-33;
                &.linear-background {
                  width: 90px;
                }
              }
            }
            .stageDurtion {
              position: relative;
              @include flex-center();
              font-size: 13px;
              svg {
                margin-left: 5px;
                fill: $c-87;
                @include wt-ht(12px, 12px);
                transform: rotate(-90deg);
              }
              &.linear-background {
                width: 60px;
              }
            }
            &.loaderSection {
              height: 48px;
            }
          }
          .accordianLoader {
            position: relative;
            padding: 15px;
            display: grid;
            height: 49px;
            border-top: 1px solid $c-eb;
            grid-template-columns: 40% 60%;
            .descBtnWrap {
              position: relative;
              display: block;
              display: flex;
              justify-content: flex-end;
              .desc {
                width: calc(100% - 44px);
                &.linear-background {
                  width: calc(100% - 54px);
                  margin-right: 5px;
                }
              }
              .collapseBtn.linear-background {
                width: 40px;
              }
            }
          }
        }
      }
      .btnSection {
        @extend %relBlock;
        text-align: center;
        margin-top: 2px;
        @include flex-center();
        justify-content: flex-end;
        button.animatedButton.backBtn {
          background: $white;
          border: 1px solid $blue-violet;
          border-radius: 6px;
          margin-right: 8px;
          color: $blue-violet;
          min-width: 80px;
        }
        button.animatedButton {
          background: $blue-violet;
          margin: 14px 0;
          text-align: center;
          padding: 10px;
          min-width: 150px;
          &.loading {
            min-width: unset;
          }
        }
      }
    }
  }
  .overlayMaskPopup {
    @include bg-mask(1, rgba(0, 0, 0, 0.57));
  }
  .sequenceTemplatePopupHeader {
    width: 100%;
  }
  .sequenceTemplatePopupContent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;

    .createNewSeq,
    .selectTemplate,
    .generateAISequence {
      background: $white;
      border: 1px solid $c-dd;
      border-radius: 10px;
      @include flex-center();
      flex-direction: column;
      justify-content: center;
      text-align: center;
      cursor: pointer;
      padding-top: 14px;
      min-height: 270px;
      overflow: hidden;
      &:hover {
        border: 1px solid $blue-violet;
      }
      .createNewSeqItem {
        transition: all 0.4s;
        transform: translateY(60px);
        will-change: inherit;
        position: relative;
        &.showPanel {
          transform: translateY(-5%);
        }
      }
      .seqTemplateLabel {
        color: $blue-violet;
        font-size: 16px;
        margin: 8px 0;
      }
      .seqTemplateSubLabel {
        color: $c-8d;
        font-size: 10px;
        margin-bottom: 10px;
      }
    }
    .selectTemplate,
    .generateAISequence {
      &:hover {
        background-color: #f9f9ff;
      }
    }
  }
}

@keyframes SectionSlideInFromRight {
  0% {
    margin-left: -530px;
    opacity: 0;
  }
  25% {
    margin-left: -385px;
  }
  50% {
    margin-left: -260px;
    opacity: 0.25;
  }
  75% {
    margin-left: -135px;
  }
  100% {
    margin-left: 0;
    opacity: 1;
  }
}

.sectionSlideIn {
  animation: SectionSlideInFromRight 0.3s linear;
}

.predefinedSequenceSection,
.generateAISequenceSection {
  .generateAIItemWrapper {
    margin-top: 18px;
  }
  .generateAISecondScreen {
    .errMsgText {
      bottom: -20px !important;
    }
  }
  .generateAISeqItem {
    margin-bottom: 24px;
    position: relative;
    .dropdownHeaderLabel {
      font-size: 16px;
      color: $c-3b;
      margin-bottom: 8px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .nextBtn {
      background-color: $blue-violet;
      min-width: 120px;
      &.loading {
        min-width: unset;
      }
    }
    .errMsgText {
      position: absolute;
      left: 0;
      right: unset;
      bottom: -18px;
    }
    .drop-down-menu {
      box-shadow: unset;
      top: 40px;
      border: 1px solid $c-dd;
      border-radius: 5px;
      border-collapse: collapse;
    }
    .dropdownList {
      margin: 0;
      .option {
        padding: 0 8px;
        margin: 0;
        &:last-child .listItem {
          border-bottom: 0;
        }
      }
      .listItem {
        border-bottom: 1px solid $c-dd;
        padding: 8px 0;
        .listItemWrap {
          flex-direction: column;
          align-items: flex-start;
        }
        .itemName {
          font-size: 14px;
          color: $c-66;
        }
        .itemSubLabel {
          margin-top: 6px;
          font-size: 11px;
          color: $c-8d;
          @include ellipsis();
          width: 95%;
        }
      }
    }
  }
  .card {
    max-width: 500px;
    hr {
      border: 0;
      border-bottom: 1px solid $c-dd;
      margin-bottom: 20px;
    }
  }
  .overlayMaskPopup {
    @include bg-mask(1, rgba(0, 0, 0, 0.57));
  }
  .filterDropdown {
    .filterHeader {
      height: 40px;
    }
    .activeDropdownValue {
      height: 40px;
      border: 1px solid $c-dd;
      border-radius: 5px;
      padding: 0 16px;
      .activeValue {
        font-size: 14px;
        color: $c-8d;
      }
      &.activeBorder {
        border: 1px solid $blue-violet;
        .activeValue {
          color: $c-3b;
        }
      }
      i {
        color: $c-8d;
        right: 16px;
      }
    }
    label {
      font-size: 11px;
      display: block;
      margin-bottom: unset;
      top: 0;
      font-size: 11px;
      transition: all 0.3s;
      &.adjustLabel {
        top: 22px;
      }
    }
  }
  #description {
    border: 1px solid $c-dd;
    height: 88px;
    font-size: 14px;
    color: $c-3b;
    &:focus {
      border: 0;
      border: 1px solid $blue-violet;
    }
    &.activeBorder {
      border: 1px solid $blue-violet;
    }
  }
  .notes-section {
    height: auto;
  }
  label[for="description"] {
    bottom: 71px !important;
  }
  .inputWrapper {
    @extend %inputWrapper;
    &.phoneNumber {
      margin-bottom: 20px;
    }
    #domain,
    #name {
      color: $c-3b;
      font-size: 14px;
    }
    input {
      border: 1px solid $c-dd !important;
      border-radius: 5px;
      padding: 0 16px;
      height: 40px;
      &:focus {
        border: 0;
        border: 1px solid $blue-violet !important;
      }
      .focusBorder {
        display: none;
      }
    }
    .activeBorder {
      border: 1px solid $blue-violet !important;
    }
  }
}
.progressBarWrapper {
  @include flex-center();
  justify-content: center;
  margin-bottom: 24px;
  .progressCircle {
    border-radius: 50%;
    @include wt-ht(24px, 24px);
    background-color: $white;
    @include flex-center();
    justify-content: center;
    border: 1px solid $blue-violet;
    font-size: 10px;
    cursor: pointer;
    &.active {
      background-color: $blue-violet;
      color: $white;
    }
  }
  .progressLine {
    width: 50px;
    color: $blue-violet;
    border: 0.5px solid $blue-violet;
    background-color: $blue-violet;
  }
}
