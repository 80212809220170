.drop-down-menu {
  position: absolute;
  right: 0;
  height: auto;
  min-width: max-content;
  max-width: 250px;
  font-family: inherit;
  box-sizing: content-box;
  background: $white;
  z-index: 999;
  border-radius: 10px;
  box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
  border: solid 1px $c-eb;
  padding: 0px;
  transition: opacity 0.4s ease-in-out;
  &.subDropDownMenu {
    max-height: 260px;
    overflow: auto;
  }
  ul {
    @include list-style-zero();
    min-width: 130px;
    max-height: 240px;
    overflow: auto;
    margin: 7px 0;
    .noSequencesMsg {
      @include flex-center();
      position: relative;
      padding: 8px 10px;
      width: 100%;
      justify-content: center;
      font-size: 12px;
    }
    li {
      @include flex-center();
      position: relative;
      width: 100%;
      transition: all 0.3s ease;
      padding: 6px 10px;
      cursor: pointer;
      text-align: left;
      @include flex-center;
      &#cancelInvite {
        .icon-holder {
          svg {
            @include wt-ht(14px, 16px);
          }
        }
      }
      &.disabled {
        pointer-events: none;
      }
      .listItem {
        line-height: initial;
        font-size: 14px;
        color: $c-66;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .listItemWrap {
          line-height: initial;
          width: 184px;
          @include ellipsis();
          @include flex-center();
          .itemName {
            @include ellipsis();
            line-height: initial;
          }
          .itemIcon {
            line-height: initial;
            margin-right: 12px;
            svg {
              @include wt-ht(12px, fit-content);
            }
          }
          .icon-holder {
            position: relative;
            line-height: initial;
            @include flex-center();
            margin-right: 7px;
            font-size: 14px;
            svg {
              @include wt-ht(inherit, inherit);
              fill: $c-66;
            }
            i {
              vertical-align: baseline;
              font-size: 14px;
              margin-right: 5px;
            }
          }
          .customText {
            font-size: 10px;
            background: #f2eeee;
            margin-left: 12px;
            display: flex;
            align-items: center;
            padding: 2px 6px 1px;
            border-radius: 10px;
            border: 1px solid $c-ee;
          }
          .svg-wrapper {
            display: inline-flex;
            svg {
              @include wt-ht(14px, 14px);
            }
          }
          .freemiumIcon {
            position: absolute;
            right: 10px;
            margin-top: 5px;
            svg {
              right: 10px;
              top: -13px;
            }
          }
          .removeTagIconWrap {
            position: relative;
            @include flex-center();
            .closeIcon {
              font-size: 8px;
              position: absolute;
              bottom: 4px;
              left: 6px;
              transform: rotate(45deg);
            }
            .removeTagIcon {
              font-size: 18px;
            }
          }
          .addTagIcon {
            font-size: 18px;
          }
        }
        .iconArrow {
          text-align: center;
          &.starIcon {
            .material-icons {
              color: gold;
              font-size: 16px;
              vertical-align: middle;
            }
            .material-icons-outlined {
              color: $c-73;
              font-size: 16px;
              vertical-align: middle;
            }
          }
        }
        i {
          font-size: 14px;
          color: $c-78;
          vertical-align: middle;
        }
      }

      &:hover {
        transition: all 0.3s ease;
        background: $c-f5;
        color: $c-33;
      }
      &:hover .icon-holder i {
        color: $c-33;
      }

      &#createNewSequenceFolder,
      &#createNewTemplateFolder {
        .icon-holder {
          i {
            margin: 0;
          }
        }
      }
    }
    .optionLoader {
      pointer-events: none;
    }
  }
}
