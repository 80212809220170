.taskPage {
  position: relative;
  .headerSection {
    .headerBtnContainer {
      @extend %AddStepButton;
      .addNewButton.btn {
        font-size: 14px;
        font-family: $font-regular;
        font-weight: 600;
        min-width: 120px;
      }
    }
  }
  .bodySection {
    @extend %body-layout;
    height: calc(100% - 58px);
    .layoutBody {
      background: transparent;
      .filterTopHeader {
        @include flex-center();
        justify-content: flex-end;
        margin-bottom: 16px;
        position: sticky;
        top: 0;
        z-index: 1;
        width: 100%;
        border-bottom: 1px solid $b-c-1;
        .dateRange {
          font-size: 18px;
          margin-right: 6px;
        }
        .taskTopHeader {
          @include flex-center();
          margin-bottom: 10px;
          .filterByDateTab {
            margin-left: 40px;
          }
        }
      }
      .startAllTaskloader {
        border-radius: 6px;
        @include wt-ht(130px, 36px);
        margin-right: 10px;
      }
      .dueDateFilterLoader {
        width: 420px;
        @include flex-center;
        gap: 6px;
        .filerName {
          display: block;
          @include wt-ht(30px, 13px);
          margin-right: 7px;
        }
        .wrapper {
          position: relative;
          @include flex-center;
          .filterBox {
            display: block;
            @include wt-ht(100px, 36px);
            border-radius: 0px;
            &:first-child {
              border-bottom-left-radius: 6px;
              border-top-left-radius: 6px;
            }
            &:last-child {
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
            }
          }
        }
      }
    }
    .headerContents {
      overflow: unset;
      @include flex-center;
      justify-content: flex-end;

      .startAllTask {
        font-family: $font-regular;
        background: $blue-violet;
        min-width: 130px;
        height: 36px;
        padding: 5px 15px;
        font-size: 15px;
        margin-right: 0px;
        &.loading {
          min-width: unset;
        }
        .iconStart {
          margin-right: 2px;
          svg {
            @include wt-ht(22px, 22px);
          }
        }
      }
      .searchWrapper {
        width: 66.66%;
        display: flex;
        align-items: center;
        flex-direction: row;

        .searchInput {
          display: flex;
          align-items: center;
          width: 50%;

          .searchImage {
            width: 20px;
            height: 20px;
            margin-right: 5px;

            svg {
              width: 20px;
              height: 20px;
              fill: $c-66;
            }
          }

          input {
            border: 0;
            color: $c-78;
            width: 100%;
            line-height: 36px;
            font-size: 17px;
            padding-right: 5px;
          }
        }

        .searchedKeyCont {
          width: 50%;
          display: flex;
          align-items: center;
          margin: 0px 5px;
          justify-content: center;

          .searchedKey {
            width: fit-content;
            overflow: hidden;
            white-space: nowrap;
            display: block;
            text-overflow: ellipsis;
            padding: 0px 5px;
            color: $blue-violet;
          }

          .closeIcon {
            display: inline-block;
            margin: 0px 5px;

            i {
              position: relative;
              font-size: 20px;
              cursor: pointer;
              padding: 5px;
              margin-left: 5px;
              border-radius: 50%;
              transition: all 0.2s ease-in;

              &:hover {
                color: $black;
                background: $blue-violet;
                transition: all 0.2s ease-out;
              }
            }
          }
        }
      }
    }

    .filterContents {
      width: 100%;
      @include flex-center;
      .seq-contact-card {
        &.active {
          z-index: 1 !important;
          pointer-events: none;
          border-top: 4px solid $blue-violet !important;

          &:hover {
            border-color: #c4c4c4;
          }

          .sharp-pointer {
            position: absolute;
            @include wt-ht(0, 0);
            border-bottom: 1px solid $c-ed;
            border-right: 1px solid $c-ed;
            background: $white;
            font-size: 0;
            line-height: 0;
            padding: 5px;
            bottom: -7px;
            left: 50%;
            transform: rotate(45deg);
            transition: all 200ms ease;
          }
        }
        .seq-contact-body {
          position: relative;
          .seq-contact-content {
            position: relative;
            h6 {
              font-size: 16px;
              font-family: $font-semi-bold;
              margin-bottom: 5px;
              line-height: 1.3;
              height: 20px;
              &.activeNo {
                color: $blue-violet;
              }
            }
            p {
              font-size: 14px;
              @include ellipsis;
            }
          }
          i {
            font-size: 20px;
          }
          .cardSvgIcon {
            svg {
              @include wt-ht(18px, 18px);
            }
          }
        }
      }
      .filterWrapper {
        width: 100%;
        grid-template-columns: repeat(5, calc(180px - 10px));
        padding: 0 0 10px;
        padding-bottom: 5px;
        margin: 0;
        &.seq-contact-list {
          overflow-x: auto;
        }
        .filterCont {
          width: inherit;
          display: flex;
          align-items: center;
          border: 1px solid $c-eb;
          border-radius: 5px;
          justify-content: center;
          transition: all 200ms ease;
          box-shadow: 0px 4px 6px 0.7px rgba(59, 59, 59, 0.07);
          &.active {
            .filterValue {
              color: $c-ff9;
            }
          }
          .filterOption {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;

            .material-icons-outlined {
              width: 24px;
              font-size: 20px;
              color: $c-7b;
            }

            .svgIcon {
              width: 24px;
              display: block;
              &.phone svg {
                @include wt-ht(20px, 20px);
              }
              svg {
                @include wt-ht(16px, 16px);
                fill: $c-7b;
              }
            }

            .filterValueCont {
              display: grid;
              text-align: left;
              grid-gap: 5px;
              overflow: hidden;
              width: calc(100% - 24px);
              font-size: 14px;
              .filterValue {
                font-size: 16px;
                font-family: "Graphik-Semibold";
              }
              .filterLabel {
                @include ellipsis();
              }
            }
          }
        }

        #contacted .filterValue {
          color: $blue-violet;
        }

        #notcontacted .filterValue {
          color: #ffbf43;
        }

        #replied .filterValue {
          color: #6ac259;
        }

        .coming-soon-card {
          .filterValue,
          .filterLabel,
          .material-icons-outlined {
            color: #ababab !important;
          }
        }
      }
    }
  }
}

.startAllTaskloader {
  border-radius: 6px !important;
  @include wt-ht(130px, 36px);
  margin-right: 10px;
}
.dueDateFilterLoader {
  width: 240px;
  @include flex-center;
  .filerName {
    display: block;
    @include wt-ht(30px, 13px);
    margin-right: 7px;
  }
  .filterBox {
    display: block;
    @include wt-ht(106px, 36px);
    border-radius: 6px;
  }
}

.tasksTableCont {
  height: calc(100% - 82px);
  position: relative;
  border-radius: 6px;
  display: block;
  overflow: hidden;
  margin-top: 12px;
  .tasksTableContBody {
    flex-direction: column;
    width: 100%;
    height: calc(100% - 55px);
    overflow: auto;
    border-radius: 6px;
    background-color: $white;
    .no-task {
      height: calc(100% - 66px) !important;
    }
    .emptyTasksSection {
      position: relative;
      @include flex-align-center;
      height: calc(100% - 48px);
      width: 100%;
      color: $c-66;
      padding-top: 40px;
      .taskIcon {
        @include wt-ht(15px, 15px);
        svg {
          @include wt-ht(inherit, inherit);
          fill: $c-66;
        }
      }
      .text {
        position: relative;
        font-size: 16px;
        padding-left: 6px;
        text-align: center;
        color: $c-66;
      }
    }
    &.noPagination {
      border-radius: unset;
      height: calc(100% - 12px);
    }
  }
  &.accountTasksTable {
    margin-top: 0;
    height: calc(100% - 136px);
    background: white;
    border-radius: 6px;
    &.adjustFilterHeight {
      height: calc(100% - 102px);
    }
    .tasksTableContBody {
      &.noPagination {
        border-radius: unset;
      }
    }
  }
}

.taskHeader {
  border-right: none;
  height: 48px;
  position: sticky;
  top: 0;
  z-index: 1;
  @include bor-rad-top(6px, 6px);
  border-bottom: 1px solid $b-c-1;
  > * {
    line-height: initial;
  }
}

.taskTableBody {
  position: relative;
  border-bottom: 1px solid $b-c-1;
  &:hover {
    background-color: $c-f8;
  }
  cursor: pointer;
}

.taskTableBodyLoader {
  position: relative;
  &:not(:last-child) {
    border-bottom: 1px solid $b-c-1;
  }
}
.taskHeader,
.taskTableBody,
.taskTableBodyLoader {
  width: 100%;
  background: white;
  display: grid;
  grid-template-columns: 18% 15% auto 125px 45px;
  &.selectCheckbox {
    grid-template-columns: 28px 18% 15% auto 125px 45px;
  }
  &.hideMoreOptions {
    grid-template-columns: 18% 15% auto 125px;
  }
  align-items: center;
  font-size: 14px;
  padding: 10px 5px;
  text-decoration: none;
  line-height: normal;
  color: $c-66;
  .contactInfo,
  .taskNotes,
  .taskType,
  .taskStage,
  .taskSequence,
  .taskDueDate,
  .taskMoreOptions {
    position: relative;
    padding: 0px 5px;
  }
  .checkboxBtn {
    justify-content: center;
    margin: 0 auto;
    .checkBoxSection {
      &.linear-background {
        @include wt-ht(15px, 15px);
        border-radius: 3px;
      }
    }
  }
  .contactInfo {
    .fullName {
      @extend %relBlock;
      color: $blue-violet;
      @include ellipsis;
      margin-bottom: 2px;
      width: 200px;
      &.linear-background {
        @include wt-ht(60%, 14px);
        margin-bottom: 4px;
      }
    }
    .jobTitleCompany {
      @extend %relBlock;
      color: $c-78;
      font-size: 12px;
      @include ellipsis;
      &.linear-background {
        @include wt-ht(80%, 12px);
      }
    }
  }
  .taskNotesWrapper {
    @include flex-center();
    justify-content: space-between;
    flex: 0 0 100%;
  }
  .taskNotes {
    word-break: break-all;
    color: $c-78;
    flex: 1;
    .notes {
      @include lineClamp(1);
    }
    .lineOne {
      display: block;
      margin-bottom: 5px;
      &.linear-background {
        @include wt-ht(90%, 10px);
      }
    }
    .lineTwo {
      display: block;
      &.linear-background {
        @include wt-ht(50%, 10px);
      }
    }
  }
  .taskStageSequence {
    flex: 0 0 30%;
    justify-content: flex-start;
    margin-right: 20px;
    &.flexItem {
      @include flex-center();
    }
    .taskStage {
      margin: 0;
      padding: 0 5px;
    }
  }
  .taskType {
    @include flex-center;
    gap: 8px;
    &.flexItem {
      @include flex-center();
    }
    .circle {
      &.linear-background {
        @include wt-ht(24px, 24px);
        border-radius: 35px;
      }
    }
    .line {
      margin-left: 10px;
      display: block;
      &.linear-background {
        @include wt-ht(50%, 15px);
      }
    }
    .linkedin {
      fill: #4b5082;
      svg {
        fill: #4b5082;
        @include wt-ht(14px, 14px);
      }
    }
    .customTask {
      svg {
        fill: #4cc8a1;
        path {
          fill: #4cc8a1;
          @include wt-ht(14px, 14px);
        }
      }
    }
    .phone {
      svg {
        fill: $c-7b;
      }
    }
    .taskTypeWrapper {
      @include flex-center();
      &.manualEmail .taskTypeIcon,
      &.email .taskTypeIcon {
        display: flex;
        i.manualEmail {
          font-size: 17px;
          color: $c-7b;
        }
      }
      &.linkedin,
      &.customTask {
        svg {
          fill: $c-7b;
          path {
            fill: $c-7b;
          }
        }
      }
      &.phone {
        svg {
          fill: $c-7b;
        }
      }
    }
    .taskTypeIcon {
      margin-right: 8px;
    }
    span {
      display: block;
    }
    svg {
      @include wt-ht(16px, 16px);
    }
    img {
      @include wt-ht(16px, auto);
    }
  }
  .taskStageSequenceLoader {
    margin-top: 6px;
    margin-right: 20px;
    .taskStage,
    .taskSequence {
      width: 100px;
      height: 30px;
      margin: 0;
    }
    &.flexItem {
      @include flex-center();
    }
  }
  .taskStage {
    text-align: center;
    color: $c-78;
    margin: 0 auto;
    font-size: 12px;
    line-height: normal;
    .stageNumber {
      border-radius: 5px;
      border: 1px solid #dad9d9;
      padding: 4px 8px;
      max-width: 100px;
      width: fit-content;
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      color: $c-66;
      line-height: normal;
      justify-content: center;
      white-space: nowrap;
    }
    .linear-background {
      @include wt-ht(100%, 24px);
      border-radius: 35px;
      display: block;
      margin-right: 12px;
    }
  }
  .taskSequence {
    color: $c-78;
    .sequenceBadge {
      border-radius: 5px;
      line-height: normal;
      border: 1px solid #dad9d9;
      padding: 4px 8px;
      max-width: 175px;
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      color: #666;
      font-size: 12px;
      white-space: nowrap;
      .name {
        width: auto;
        @include ellipsis;
      }
    }
    .linear-background {
      display: block;
      @include wt-ht(100%, 24px);
    }
  }
  .taskMoreOptions {
    text-align: center;
    i {
      color: $c-78;
      font-size: 24px;
    }
    .icon-hover-btn {
      @include flex-align-center;
      padding: 0 8px;
      @include wt-ht(24px, 24px);
    }
    .drop-down-menu {
      min-width: 120px;
      max-width: 130px;
      ul {
        min-width: 100px;
      }
    }
    .linear-background {
      display: block;
      border-radius: 35px;
      @include wt-ht(30px, 30px);
    }
  }
  .taskDueDate {
    @include flex-center;
    i {
      padding-right: 3px;
      font-size: 16px;
    }
    .linear-background {
      display: block;
      @include wt-ht(80%, 14px);
    }
  }
}

.taskHeader {
  background: #fafafa !important;
}

.filterByTaskWrapper {
  position: relative;
  @include flex-center;
  justify-content: flex-start;
  .filterTitle {
    font-size: 15px;
    padding-right: 5px;
    color: $c-8d;
    text-transform: capitalize;
  }
  .filterByTask {
    @extend %dateFilter;
  }
}

.filterByDateTab {
  position: relative;
  @include flex-center;
  line-height: normal;
  justify-content: flex-end;
  &.disable {
    @include disable(0.5, unset);
  }
  .filterTitle {
    font-size: 15px;
    padding-right: 5px;
    color: $c-78;
    text-transform: capitalize;
  }
  .dateFilter {
    @extend %dateFilter;
    gap: 4px;
    .tabItem {
      border-radius: 5px !important;
      border: 1px solid $blue-violet !important;
      border-right: 1px solid $blue-violet !important;
    }
  }
}

.filterIconWraper {
  @extend %filterIconWrapper;
  svg {
    fill: $blue-violet;
  }
}

.rdrCalendarWrapper {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 36px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);

  .rdrMonthAndYearWrapper {
    padding-top: 0;
    height: 46px;
  }

  .rdrMonth {
    padding-bottom: 10px;
  }

  .rdrWeekDay {
    line-height: 1.8;
  }

  &.dateRangePicker .dateBtnSection {
    position: relative;
    padding: 0 0.833em 1.666em 0.833em;
    text-align: right;
    @include flex-center;
    justify-content: flex-end;

    input[type^="button"] {
      height: 30px;
      border-radius: 35px;
      outline: none;
      letter-spacing: 0.3px;
      font-size: 14px;
      width: 80px;
      border: none;
      font-weight: 400;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 10px;
      }

      &.cancelCustomDateFilter {
        background: white;
        color: $c-66;
        transition: all 0.3s ease;

        &:hover {
          transition: all 0.3s ease;
          background: $c-f5;
          color: black;
        }
      }

      &.applyCustomDateFilter {
        color: white;
        background: #3d91ff;
        transition: all 0.3s ease;

        &:not(:disabled):hover {
          transition: all 0.3s ease;
          box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.12);
        }

        &:disabled {
          background: $c-78;
          cursor: default;
          opacity: 0.5;
        }
      }
    }

    .wrapUpgradeIconTooltip {
      padding-left: 30px;

      .freemiumIcon {
        right: 10px;
      }
    }

    #customDateFilterErrMsg {
      display: block;
      position: absolute;
      font-size: 12px;
      bottom: 3px;
      color: $c-78;
      transition: all 0.3s ease;

      &.highlightErr {
        color: red;
      }
    }
  }
}

.overlayCustomDateFilter {
  @include bg-mask(4, transparent);
}

.taskSectionWrapperCont {
  transition: 0.5s;
  grid-gap: 16px;
  height: calc(100% - 64px);
  &.displayContents {
    display: block;
    transition: 0.5s;
  }
  &.taskSectionWrapperGrid {
    display: grid;
    grid-template-columns: calc(100% - 318px) 300px;
  }
  .taskSectionContainer {
    height: 100%;
    overflow: hidden;
    .tasksTableCont {
      z-index: 0;
    }
  }
  .filterComponentWrapper {
    background-color: $white;
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .filterCompContainer {
      &:last-child .filterComponentBodyHeader {
        border-bottom: 1px solid $c-ec;
      }
    }
    .clearAllBtn {
      position: relative;
      top: 3px;
      @extend %relBlock;
      font-size: 12px;
      cursor: pointer;
      color: $blue-violet;
      &.disabled {
        @include disable(0.8, unset);
        color: rgba(57, 83, 251, 0.5);
      }
    }
    .autoCompleteDropdownListWrapper {
      .listItemWrap {
        width: 250px !important;
      }
    }
    .filterComponentBody {
      height: calc(100% - 102px);
    }
    .customAccordion .customAccordionItem .drop-down-menu {
      top: unset;
    }
    .conditionField {
      padding: 10px 0;
    }
    .dropdownSearchBox {
      background-color: unset !important;
      border: 0;
      padding: 0;
      .fieldOperatorValue .inputWrapper input,
      .fieldOperatorValue .selectLabel,
      .singleDropdown .selectLabel {
        height: 26px !important;
      }
      .conditionalFieldWrap {
        position: relative;
        margin-bottom: 18px;
        max-height: 150px;
        padding: 0;
        .conditionalField {
          padding: 0 10px;
          padding-bottom: 0;
        }
      }
    }
    .filterAction {
      position: relative;
      border-top: 1px solid $c-ec;
      display: grid;
      padding: 0 10px;
      align-items: center;
      height: 50px;
      grid-template-columns: repeat(2, calc(50% - 5px));
      background-color: $white;
      justify-content: space-between;
      &.disabled {
        @include disable(0.6, unset);
      }
      .cancelFilter,
      .applyFilter {
        position: relative;
        @include flex-center;
        justify-content: center;
        height: 30px;
        font-size: 14px;
        padding: 0 10px;
        transition: all 0.3s ease;
        width: 130px;
        font-weight: 500;
        letter-spacing: 0.3px;
        cursor: pointer;
      }
      .cancelFilter {
        background: white;
        color: $blue-violet;
      }
      .applyFilter {
        background: $blue-violet;
        color: white;
      }
    }
    .flexCenterWithJustifyContent {
      @include flex-center();
      justify-content: space-between;
      width: 100%;
      .clearAll {
        line-height: 47px;
      }
      .clearAllIcon {
        font-size: 16px;
        cursor: pointer;
        &:hover {
          color: $c-55;
        }
      }
    }
    .itemName {
      .conditionSymbol {
        margin-right: 4px;
      }
      .sharedBadgeWrapper {
        @extend %sharedBadge;
        background-color: #e3e3e3;
        @include flex-center();
        margin-left: 10px;
        padding: 4px;
        border-radius: 3px;
      }
      .folderNameBadgeWrapper {
        margin-left: 10px;
        @include flex-center();
        background-color: #f1f9ff;
        border-radius: 3px;
        padding: 4px;
        .folderNameBadge {
          letter-spacing: 0.4px;
          line-height: 9px;
          font-size: 9px;
          color: #959cf2;
          text-transform: uppercase;
        }
        .sharedFolder {
          color: #959cf2;
          font-size: 14px;
          margin-right: 5px;
        }
      }
      display: flex !important;
      align-items: center;
      .nameText {
        @include ellipsis();
      }
    }
    .addRemoveValueWrapper {
      max-height: 90px;
    }
  }
}

// Bulk task action button
.bulkActionCont {
  position: relative;
  @include flex-center;
  .formGroupButton {
    position: relative;
    @include flex-align-center;
    gap: 5px;
    padding-left: 1rem;
    button {
      position: relative;
      @include flex-align-center;
      height: 36px;
      border: 1px solid #3953fb;
      border-radius: 5px;
      background: white;
      color: #3953fb;
      text-align: center;
      font-size: 14px;
      padding-top: unset;
      padding-bottom: unset;
      transition: all 0.3s ease-in-out;
      .iconLeftCont {
        position: relative;
        @include flex-center;
        .leftIcon {
          font-size: 14px;
          padding-right: 5px;
        }
      }

      &:hover {
        background: #3953fb;
        color: white;
      }
    }
  }
}

// Account Tasks Type Dropdown
.accountTaskTypeDropdown {
  position: relative;
  @include flex-center;
  justify-content: space-between;
  padding: 4px;
  width: 126px;
  border-radius: 3px;
  background: #f4f4f6;
  cursor: pointer;
  .selectedTaskTypeValue {
    position: relative;
    font-size: 12px;
    font-weight: 500;
    color: $c-3b;
  }
  .selctedCount {
    @include flex-center();
    gap: 6px;
  }
  i {
    font-size: 16px;
    color: $c-3b;
    padding: 0 2px;
    transition: all 0.3s ease-in-out;
    &.tranformArrow {
      transform: rotate(180deg);
    }
  }

  .drop-down-menu {
    top: 20px;
    right: 0;
    li {
      &:hover {
        background-color: #eff2ff !important;
        .listItemWrap {
          color: $blue-violet;
        }
      }
      .listItem .listItemWrap {
        width: 120px;
      }
    }
  }
}

.taskCont {
  .dropdownList li {
    height: 38px;
    padding: 10px;
  }
}
