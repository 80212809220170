.newTooltip {
  user-select: none;
  @extend %relBlock;
  .sharpPointer {
    position: absolute;
    border-top-right-radius: 5px;
    padding: 8px;
    border-right: 1px solid $c-f5;
    border-top: 1px solid $c-f5;
    z-index: 4;
    top: 101px;
    right: 94px;
    transform: rotate(-45deg);
    background: white;
    &.alignRight {
      top: 2px;
      left: 4px;
    }
  }
  .tooltipCloseIcon {
    position: absolute;
    top: 10px;
    right: -18px;
    padding: 4px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 16px;
    &:hover {
      background-color: $c-dd;
    }
  }
  .tooltipHeading {
    color: red;
    font-size: 14px;
    margin-bottom: 12px;
  }
  .tooltipBody {
    position: absolute;
    right: -30px;
    top: 6px;
    z-index: 3;
    width: 270px;
    height: auto;
    line-height: initial;
    border-radius: 4px;
    box-shadow: 0 0 12.1px 0.9px rgba(203, 203, 203, 0.26);
    background-color: white;
    &.crmToolTipBody {
      width: max-content;
    }
    .tooltipText {
      position: relative;
      line-height: initial;
      display: inline-block;
      font-size: 13px;
      color: $c-66;
      line-height: 1.4;
      a {
        color: $blue-violet;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &.alignRight {
      top: 10px;
      left: -15px;
      right: unset;
      width: unset;
      min-width: fit-content;
      max-width: 220px;
      .tooltipText {
        padding: 10px;
      }
    }
    .crmIconList {
      @include flex-center;
      padding: 10px;
      flex-wrap: wrap;
      :not(:last-child) {
        margin-right: 8px;
      }
    }
    .redirectLink {
      border: 0;
      background-color: transparent;
      color: $blue-violet;
      letter-spacing: 1;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
