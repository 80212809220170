.updateContactOwnerPopup {
  position: fixed;
  @include flex-align-center;
  @include wt-ht(100%, 100vh);
  inset: 0;
  background: transparent;
  z-index: 11;
  .card {
    position: relative;
    z-index: 2;
    background: white;
    @include wt-ht(440px, auto);
    border-radius: 5px;
    padding: 20px;
    color: $c-3b;
    &.disableClick {
      user-select: none;
      opacity: 0.7;
      pointer-events: none;
    }
    .cardHeader {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid $b-c-1;
      .textWrapper {
        position: relative;
        display: block;
        .title {
          position: relative;
          display: block;
          font-size: 20px;
          text-align: left;
          color: $c-3b;
        }
        .desc {
          position: relative;
          display: block;
          margin-top: 6px;
          color: $c-8d;
          font-size: 12px;
        }
      }
      i.close {
        font-size: 20px;
        color: $c-66;
        border-radius: 35px;
        padding: 3px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: $c-f5;
          color: $c-33;
        }
      }
    }
    .cardBody {
      position: relative;
      display: block;
      padding: 15px 0 0 2px;
      &.loading {
        pointer-events: none;
        user-select: none;
        cursor: unset;
      }
      @include searchInputByKeyword;
      .searchWrapper {
        margin-bottom: 15px;
        .searchInput {
          width: 100%;
          padding: 3px;
          .searchTextBox {
            width: calc(100% - 30px);
            input {
              width: 100%;
              padding: 6px 20px 6px 2px;
            }
          }
        }
      }
      .contactOwnerOptList {
        position: relative;
        height: 248px;
        overflow: auto;
        .noUserFound {
          position: relative;
          text-align: center;
          top: 30px;
          font-size: 16px;
          color: $c-8d;
        }
        .options {
          position: relative;
          display: grid;
          grid-template-columns: 26px calc(100% - 26px);
          align-items: center;
          padding: 12px;
          transition: all 0.3s ease;
          cursor: pointer;
          border-bottom: 1px solid $b-c-1;
          &.disableOpt {
            @include disable(0.6);
          }
          &:hover:not(.activeMember) {
            background: $c-f5;
          }
          &.activeMember {
            pointer-events: none;
            user-select: none;
            cursor: unset;
          }
          .radioBtn {
            position: absolute;
            opacity: 0;
            z-index: 2;
            @include wt-ht(24px, 24px);
            display: block;
            margin: 0;
            cursor: pointer;
            &:checked ~ label {
              color: $blue-violet !important;
            }
            &:checked ~ .contentClass::before {
              background-color: #ffffff;
              border: 2px solid #3953f6;
            }

            &:checked ~ .contentClass::after {
              background-color: #3953f6;
              border: 0px solid transparent;
              transform: scale(0.6);
            }
            .contentClass::before,
            .contentClass::after {
              @include wt-ht(18px, 18px);
            }
            &.linear-background {
              position: relative;
              opacity: 1;
              @include wt-ht(18px, 18px);
            }
          }
          .teamMemberName {
            position: relative;
            display: inline-block;
            line-height: 24px;
            font-size: 16px;
            color: $c-66;
            &.linear-background {
              position: relative;
              @include wt-ht(calc(100% - 20px), 16px);
            }
            .currentOwner {
              position: relative;
              background: #d3d9fd;
              margin-left: 6px;
              color: $blue-violet;
              padding: 4px;
              border-radius: 3px;
              font-size: 12px;
              height: 16px;
              top: -1px;
            }
          }
          &.activeMember .teamMemberName {
            color: $c-3b;
          }
        }
      }

      .updateContactOwnerBtn {
        position: relative;
        display: block;
        margin-top: 15px;
        min-width: 200px;
        font-size: 14px;
        border-radius: 10px;
        background: $blue-violet;
        letter-spacing: 0.3px;
        min-height: 40px;
        cursor: pointer;
        &.loading {
          @include wt-ht(40px, 40px);
          border-radius: 35px;
          min-width: unset;
          .load-text {
            @include wt-ht(30px, 30px);
            border-color: white;
          }
        }
      }
    }
  }
  .overlay {
    @include bg-mask(1, rgba(0, 0, 0, 0.65));
  }
}
