.customFields {
  padding: 10px 10px 0 10px;
  height: 100% !important;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;

  .cusotmFieldsInnerCont {
    height: 100% !important;
    overflow: hidden !important;
    width: 100% !important;
    padding-bottom: 0px !important;

    .titleHeader {
      padding-bottom: 20px;
      display: block;
      font-size: 16px;
      font-family: $font-regular;
      color: $c-66;
      font-weight: 400;
    }

    .fieldList {
      height: calc(100% - 160px);
      overflow-y: auto;
      border-bottom: 1px solid $c-ec;
      .highlightBlue {
        color: blue;
      }
      .addCustomFields {
        width: 220px;
        border: 1px dashed #959cf2;
        color: #8d8d8d;
        cursor: pointer;
        display: flex;
        padding: 6px;
        justify-content: space-between;
        border-radius: 10px;
        font-size: 14px;
        align-items: center;
        margin-bottom: 10px;
      }
      .customFieldsInputBox {
        .innerCont {
          @include flex-center();
          padding: 10px 0px;

          .labelCont {
            width: 150px;

            .inputLabel {
              font-size: 14px;
            }
          }

          .valueCont {
            display: flex;
            width: 270px;
            position: relative;
            .showtoolTipAndIcon {
              .thunderSvg {
                position: absolute;
                cursor: pointer !important;
                g {
                  filter: unset;
                }
              }
            }

            .inputBox {
              border: 1px solid $c-ec;
              padding: 10px;
              border-radius: 6px;
              box-shadow: none;
              outline: 0;
              margin-left: 20px;
              width: calc(100% - 20px);
              color: $blue-violet;
              transition: all 0.5s;

              &:focus {
                border-color: $blue-violet;
              }
            }
          }
        }

        .customFieldInputSpinLoader {
          @include InputSpinLoader(8.5px, 10px, $c-ab);
        }
      }
    }

    .actionCont {
      display: block;
      position: relative;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: left;

      button {
        background: $blue-violet;
        border-color: $blue-violet;
        color: $white;
        border: none;
        letter-spacing: 0.3px;
        width: fit-content;
        margin: unset;
      }

      &.btnDisabled {
        button {
          background: transparent;
          border: 1px solid #ccc;
          color: #ccc;
          cursor: not-allowed;
          pointer-events: none;
          margin: unset;
        }
      }
    }
  }
}
.contactAdminPopup {
  @include adminPopup();
}

.adminPopupMask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 199;
  background: #333;
  opacity: 0.75;
}
