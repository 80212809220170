.autoCompleteDropdown {
  @include flex-center;
  position: relative;
  color: $c-66;
  width: 300px;
  &.positionTop .dropdownPanel {
    top: unset !important;
    bottom: 36px !important;
  }
  .dropdownHeader {
    @include flex-center();
    justify-content: space-between;
    @include wt-ht(300px, 36px);
    position: relative;
    background: white;
    border-bottom: 1px solid $c-eb;
    cursor: pointer;
    .titleWrapper {
      @include flex-center();
    }
    .title {
      text-align: center;
      color: $blue-violet;
    }
    .arrow {
      position: relative;
      transition: all 0.3s ease;
      @include flex-center();
      i {
        color: $blue-violet;
        font-size: 18px;
      }
      &.activeArrow {
        text-align: right;
        transform: rotate(-180deg);
        transition: all 0.3s ease;
      }
    }
  }
  .dropdownPanel {
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
    position: absolute;
    z-index: 12;
    opacity: 1;
    top: 38px;
    border: 1px solid $c-eb;
    overflow: auto;
    .searchInputBtn {
      width: 100%;
      border-bottom: 1px solid $c-eb;
      .searchIcon {
        flex: 0 0 24px;
        font-size: 20px;
        margin-left: 4px;
      }
      .searchTextBox {
        flex: 1;
        margin: 4px;
        &.spacingSearch {
          padding-top: 7px;
          padding-bottom: 7px;
        }
      }
      .searchInputBoxWrapper {
        width: 100%;
        @include flex-center();
        input {
          width: 100%;
          border-radius: 4px;
          padding: 4px 8px;
          line-height: unset;
        }
      }
    }
  }

  .dropdownList {
    @include list-style-zero;
    padding: 5px 0;
    transition: opacity 0.3s ease;
    overflow: auto;
    width: 300px;
    max-height: calc(300px - 24px);
    .noResult {
      font-size: 14px;
      color: $c-75;
      padding: 10px;
      &.displayCenter {
        display: flex;
        justify-content: center;
      }
    }
    .dropdownOptions {
      position: relative;
      @include flex-center;
      padding: 0px 10px;
      height: 36px;
      font-size: 14px;
      cursor: pointer;
      .updatedName {
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 210px;
        &.linear-background {
          width: 100%;
        }
      }
      .defaultValue {
        letter-spacing: 0.4px;
        padding: 5px;
        font-size: 10px;
        border-radius: 3px;
        margin-left: 10px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        background-color: #e3e3e3;
        color: #757575;
      }
      &.active {
        color: $blue-violet;
        background: #f3f9fa;
        font-weight: 500;
      }
      &:hover:not(.activeLimit) {
        background: $c-f5;
        color: $c-44;
      }
    }
  }
}
.autoCompleteDropdownOverlay {
  @include bg-mask(11, transparent);
}
