.inputOptionsHolder {
  color: $c-78;
  position: relative;
  .inputOptions {
    position: relative;
    width: 50%;
    margin-right: 10px;
  }

  label {
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
    text-align: left;
  }
  .showLimitText {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 10px;
    color: #bcb4b4;
  }
  .value {
    font-size: 14px;
    color: $blue-violet;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    .react-datepicker-wrapper {
      width: 100%;
      transform: translate3d(-10%, 49px, 0) !important;
    }
  }
  i {
    font-size: 20px;
  }
}

.notes-section {
  height: 180px;
  .txt-cont {
    overflow-y: auto;
    border: 1px solid #cecece;
    height: calc(100% - 25px);
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    display: block;
    resize: none;
    font-size: 14px;
    font-family: $font-regular;
    line-height: 1.5;
  }
  .notes-section {
    height: 180px;
    .txt-cont {
      overflow-y: auto;
      border: 1px solid #cecece;
      height: 90%;
      padding: 10px;
      border-radius: 10px;
      display: block;
    }
    .title {
      color: #787878;
      font-size: 16px;
    }
  }
}
