.profileAndEmailPreference {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  .tabNavigation {
    position: relative;
    z-index: 10;
    .tabItem {
      width: unset;
      min-width: 140px;
    }
  }
}

.emailPreferenceWrapper {
  height: calc(100% - 56px);
  user-select: none;
  overflow-y: auto;
  padding: 20px;

  .epCardBody {
    min-height: 174px;
    width: 100%;
    border: 1px solid $c-dd;
    padding: 20px;
    border-radius: 5px;

    .disabledValue {
      padding: 12px;
      color: $c-8d;
      border: 1px solid $c-dd;
      font-size: 14px;
      line-height: 17px;
      border-radius: 4px;
    }
  }
  .epCardTemplate {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: 13px;
  }
  .cBody {
    display: flex;
    flex-direction: column;
    row-gap: 13px;

    .toggleBtnSection .toggleText.activeToogle,
    .leftPad {
      position: relative;
      color: $c-3b;
      font-size: 14px;
      font-family: "Graphik-Medium";
      line-height: normal;
      top: 1px;
    }

    .toogleSequence .toogleButton {
      &.loading {
        .knobs::before {
          animation: spin 1s linear infinite;
          border: 1.5px solid #3953fb;
          border-bottom-color: #3953fb;
          border-left-color: #3953fb;
          border-left: 1px;
          background-color: transparent;
        }

        .toggleCheckbox:checked + .knobs:before {
          background-color: transparent;
        }
      }
      @include wt-ht(28px, 14px);
      .knobs::before {
        @include wt-ht(10px, 10px);
        top: 2px;
        padding: unset;
      }
      .toggleCheckbox:checked {
        & ~ .layer {
          background: #fff;
          border: 1px solid #3953fb;
        }
        & + .knobs:before {
          left: 15px;
          background: #3953fb;
        }
      }
      .layer {
        background: #fff;
        border: 1px solid $c-d3;
      }
      .knobs:before {
        background: $c-a7;
      }
    }
  }
  .cardCBody {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    padding-bottom: 16px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    color: $c-8d;
    font-family: "Graphik-Regular";
    @include lineClamp(2);
    min-height: 54px;
  }

  .avatarFlex {
    display: flex;
  }
  .singleAvatar {
    border-radius: 50%;
    overflow: hidden;
    width: 36px;
    height: 36px;
    border: 1px solid $blue-violet;
    background-color: $white;
    span {
      width: 100%;
      height: 100%;
      display: block;
      @include flex-align-center();
      justify-content: center;
      font-size: 14px;
      color: $blue-violet;
    }
  }
  .avatar {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    width: 36px;
    height: 36px;
    border: 1px solid $blue-violet;
    background-color: $white;

    &:not(:first-child) {
      margin-left: -19px;
      -webkit-mask: radial-gradient(
        circle 18px at 2px 50%,
        transparent 99%,
        $blue-violet 100%
      );
      mask: radial-gradient(circle 18px at 2px 50%, transparent 99%, #fff 100%);
    }

    &:first-child {
      background-color: $blue-violet;
      border: 1px solid $blue-violet;
      z-index: 10;
      cursor: pointer;
    }
    &:disabled {
      @include disable(0.3, unset);
    }

    span {
      width: 100%;
      height: 100%;
      display: block;
      @include flex-align-center();
      justify-content: center;
      font-size: 14px;
      color: $blue-violet;
    }
  }

  .loaderContainer {
    height: 174px;
    width: 100%;
    border: 1px solid $c-dd;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .toggleFlex {
      @include flex-center;
      gap: 4px;
      .cardToggleLoader {
        &.linear-background {
          @include wt-ht(24px, 24px);
        }
      }

      .toggleContent {
        &.linear-background {
          width: 100%;
        }
      }
    }
  }
  .contentLoader {
    &.linear-background {
      width: 80%;
    }
  }
  .subContentLoader {
    &.linear-background {
      width: 50%;
    }
  }
  .dropdownLoader {
    &.linear-background {
      width: 100%;
      height: 20%;
    }
  }
}
