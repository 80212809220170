.metricsTableSection {
  position: relative;
  @include wt-ht(100%, 100%);
  border-radius: 5px;
  background-color: $white;
  z-index: 0;
  .metricsHeaderCont {
    @include flex-align-center(center, space-between);
    border: 1px solid $c-dd;
    border-bottom: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .singleTabName {
      @include flex-center;
      font-size: 16px;
      width: 220px;
      height: 52px;
      padding-left: 8px;
      color: $c-343a40;
      border-bottom: 1px solid $c-dd;
    }
    .tabNavigation {
      .tabItem {
        top: 0;
        color: $blue-violet;
        height: 52px;
        border-right: 1px solid $c-dd;
        border-bottom: 1px solid $c-dd;
        &.activeTab {
          background: #fafafa;
          border-bottom: none;
        }
      }
    }
    .selectedCountCont {
      width: 100%;
      height: 52px;
      border-bottom: 1px solid $c-dd;
      @include flex-align-center(center, flex-end);
    }
    .rightSideCont {
      @include flex-center;
      height: 52px;
      justify-content: flex-end;
      padding-right: 10px;
      border-bottom: 1px solid $c-dd;
      .inputSectionCont {
        position: relative;
        @include searchInputByKeyword();
        .drop-down-menu {
          z-index: 1000;
          right: 25px;
        }
      }
      .customDragAndDropWrapper {
        position: relative;
        background: $white;
        color: $blue-violet;
        font-size: 14px;
        border-radius: 5px;
        @include wt-ht(36px, 36px);
        @include flex-align-center();
        cursor: pointer;
        margin-left: 10px;
        border: 1px solid $c-dd;
        .dragAndDropIcon {
          @include wt-ht(16px, 16px);
          fill: $c-9e;
          path {
            fill: $c-9e;
          }
        }
        .bridgeCont {
          position: absolute;
          top: 20px;
          @include wt-ht(40px, 40px);
        }
        .dragAndDropCont {
          position: absolute;
          top: 40px;
          right: 0px;
          width: 300px;
          height: 440px;
          z-index: 1000;
          border: 1px solid $c-dd;
          padding: 10px;
          padding-bottom: 0px;
          color: $c-3b;
          border-radius: 5px;
          background: $white;
          .dragAndDropDropDown {
            height: 376px;
            .allCheckboxCont {
              position: relative;
              @include flex-center;
              line-height: 1;
              margin-left: 16px;
              margin-bottom: 10px;
              .itemName {
                padding: 5px;
                border-radius: 5px;
              }
            }
            .dragAndDropBody {
              height: calc(100% - 30px);
              overflow: scroll;
              .itemWrapper {
                position: relative;
                @include flex-center;
                line-height: 1;
                margin-left: 10px;
                margin-bottom: 10px;
                &.isDraggedIn {
                  border-bottom: 2px solid $blue-violet;
                }
                .dragHolder {
                  position: absolute;
                  display: block !important;
                  &.reports {
                    left: -12px;
                  }
                }
                .checkBoxSection {
                  margin-left: 6px;
                }
                &:hover {
                  background-color: #fafafb;
                  border-top-right-radius: 0;
                  border-top-left-radius: 0;
                }
                .displayNameWithIcon {
                  @include flex-center;
                  svg {
                    @include wt-ht(14px, 14px);
                    fill: #0a66c2;
                    position: relative;
                    top: -2px;
                    margin-right: 4px;
                  }
                }
                .itemName {
                  padding: 5px;
                  border-radius: 5px;
                }
              }
            }
          }
          .btnCont {
            @include flex-center;
            margin-top: 10px;
            width: 100%;
            background: $white;
            .cancelButton {
              @extend %primaryBlueBtn;
              min-width: 130px;
              border-radius: 5px;
              color: $blue-violet;
              background: white;
              border: 1px solid $blue-violet;
              margin: unset;
              height: 28px;
              &:hover {
                background: white;
              }
            }
            .saveButton {
              @extend %primaryBlueBtn;
              min-width: 130px;
              border-radius: 5px;
              background: $blue-violet;
              margin: unset;
              height: 30px;
              margin-left: 10px;
              &.loading {
                transition: all 0.3s ease-in-out;
                min-width: auto;
                border-radius: 35px;
                @include wt-ht(32px !important, 32px !important);
                .load-text {
                  @include wt-ht(20px, 20px);
                  top: 5px;
                  left: 6px;
                }
              }
              &.disableBtn {
                opacity: 0.5;
                cursor: none;
              }
            }
          }
        }
        .bgOverlay {
          @include bg-mask(19, rgba(0, 0, 0, 0.57));
          background: none;
        }
      }
      .exportBtnWrapper {
        border: 1px solid $c-dd;
        .customTooltip {
          .tooltipBody {
            right: -22px;
            &.bulkAddtoExport {
              width: 240px;
              right: -24px;
              z-index: 4;
            }
          }
        }
      }
    }
  }
  .metricsTableBody {
    width: 100%;
    height: calc(100% - 106px);
    overflow: auto;
    border: 1px solid $c-dd;
    border-top: none;
    @include tableFixedCont();
    &.noPagination {
      height: calc(100% - 52px);
    }
    .tableContainer {
      height: 100%;
      color: $c-66;
      &::-webkit-scrollbar {
        height: 0.3em;
      }
      thead th[scope="row"] {
        padding: 0;
      }
      tbody th[scope="row"] {
        padding: 0;
      }
      thead {
        border-top: none;
        tr {
          top: -1px;
          border-bottom: 1px solid $c-dd;
          th {
            min-width: 236px;
            color: $c-8d;
            padding: 0;
            .tabType {
              @include flex-center;
              padding: 14px;
              min-width: 236px;
              height: 46px;
              &.userTab {
                padding-left: 12px;
              }
              &.teamTab {
                padding-left: 14px;
              }
              .checkBoxSection {
                margin-right: 8px;
              }
            }
            .nameAndEmailCont {
              height: 46px;
              @include flex-align-center;
              height: 100% !important;
              .displayNameWithIcon {
                @include flex-center;
                svg {
                  @include wt-ht(14px, 14px);
                  fill: #0a66c2;
                  position: relative;
                  top: -3px;
                  margin-right: 4px;
                }
              }
            }
          }
        }
      }
      tbody {
        tr {
          border: 1px solid $c-dd;
          th {
            .userNameAndEmail {
              @include flex-center;
              padding: 14px;
              max-width: 270px;
              min-width: none;
              .checkBoxSection {
                margin-right: 6px;
              }
              &.isEmail {
                padding: 10px;
              }
              .nameAndEmailCont {
                margin-left: 2px;
                max-width: 230px;
                &.inlineDisplay {
                  @include flex-center;
                  width: 100%;
                }
                .userName {
                  color: $c-3b;
                  @include ellipsis();
                  &.sequenceName {
                    @include flex-center;
                    font-size: 15px;
                    width: 100%;
                    @include ellipsis();
                    &.isHighlightSeqName {
                      color: $blue-violet;
                      cursor: pointer;
                      &:hover {
                        text-decoration: underline;
                      }
                    }
                  }
                }
                .emailCont {
                  @include ellipsis();
                  color: $c-8d;
                  font-size: 12px;
                  margin-top: 2px;
                }
              }
            }
            .nameAndEmailLoader {
              padding-left: 20px;
            }
          }

          td {
            text-align: center;
            color: $c-3b;
          }
        }
      }
      .sortIcon {
        font-size: 18px;
        padding: 2px;
        width: 24px;
        margin-left: 5px;
        @extend %relBlock;
        border-radius: 50%;
        transition: all 0.3s ease;
        cursor: pointer;
        color: $c-78;
        transform: rotate(180deg);

        &:hover {
          background: $c-eb;
          transition: all 0.3s ease;
        }

        &.activeSort {
          color: black;
        }

        &.ascending {
          transform: rotate(0deg);
        }
      }

      .emptyTableSection {
        position: relative;
        height: calc(100% - 48px);
        width: 100%;
        color: $c-66;
        flex-direction: column;
        @include flex-align-center;
        .text {
          position: relative;
          font-size: 16px;
          text-align: center;
        }
      }
      .reportsLoader .linear-background {
        display: block;
        @include wt-ht(70%, 12px);
        border-radius: 10px;
        margin: unset;
      }
    }
  }
  .metricsTableFooter {
    @include flex-align-center;
    padding: 8px;
    border: 1px solid $c-dd;
    border-top: 0;
    .pagination-section {
      padding: 0;
      border-top: 0;
    }
    .pageLimit {
      margin-right: 4px;
    }
    .limitDropdown {
      top: -154px;
      padding: 10px;
    }
  }
}
