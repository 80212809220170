.transactionProgressPopup {
  height: 100%;

  .transactionProgressWrapper {
    height: fit-content;
    border-radius: 10px;
    background: #fff;
    min-width: 300px !important;
    padding: 25px;

    .animationCont {
      width: 100%;
      height: 100%;
    }

    .processingText,
    .waitText {
      font-size: 14px;
      width: 100%;
      font-weight: normal;
      padding-top: 15px;
      color: $c-53;
      display: inline-block;
      text-align: center;
    }
  }
}
