%checkboxBtn {
  .checkboxBtn {
    position: relative;
    display: block;
    width: 32px;
    text-align: center;
    .linear-background {
      display: block;
      width: 18px;
      border-radius: 6px;
    }
  }
}
.callsSection {
  position: relative;
  @include wt-ht(100%, 100%);
  background: $c-f5f8;

  .wrapFlex {
    @include flex-center;

    .reportInfoIconTp {
      position: relative;
      @include flex-center;
      margin-left: 5px;

      i {
        font-size: 18px;
        color: $blue-violet;
        padding: 2px;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          background: $c-f5;
        }
      }

      .wrapInfoText {
        position: absolute;
        left: 24px;
        width: 420px;
        font-size: 12px;
        padding: 7px;
        background: black;
        border-radius: 10px;
        color: white;

        .sharpPointer {
          position: absolute;
          padding: 5px;
          z-index: 4;
          top: 18px;
          left: -5px;
          transform: rotate(45deg);
          background: black;
        }
      }
    }
  }

  .callsContainer {
    position: relative;
    @include wt-ht(100%, 100%);
    display: flex;
    padding: 12px;
    .callsBody {
      @extend %body-layout;
      padding: 0;

      .callsCard {
        position: relative;
        @include wt-ht(100%, 100%);
        border-radius: 15px;

        .filterNaviagtionCard {
          position: sticky;
          top: 0;
          z-index: 9;
          width: 100%;
          @include flex-center();
          justify-content: space-between;
          border-bottom: 1px solid $b-c-1;

          .callsFilter {
            position: relative;
            width: 100%;
            @include flex-center();
            justify-content: flex-end;
            padding-bottom: 10px;

            .lockIcon {
              background: orange;
              color: white;
              font-size: 12px;
              width: 20px;
              height: 20px;
              border-radius: 35px;
              justify-content: center;
              display: flex;
              align-items: center;
              position: relative;
              margin-left: 10px;
            }

            .userFilterDropDown {
              position: relative;
              margin-right: 10px;

              .userFilterBtn {
                position: relative;
                @include flex-center();
                justify-content: center;
                outline: none;
                background: white;
                border-radius: 3px;
                border: 1px solid $b-c-1;
                padding: 0 10px;
                @include wt-ht(200px, 36px);
                font-size: 15px;
                transition: all 0.3s ease;
                cursor: pointer;

                &:hover {
                  background: $c-fb;
                  transition: all 0.3s ease;
                }

                .icon {
                  margin-right: 10px;
                  @include wt-ht(14px, 14px);

                  svg {
                    fill: $blue-violet;
                    @include wt-ht(14px, 14px);
                  }
                }

                .UpgradeTooltip {
                  .sharpPointer {
                    right: 29px;
                  }
                }

                .name {
                  position: relative;
                  color: $blue-violet;
                  min-width: 100px;
                  max-width: 110px;
                  text-align: left;
                  font-size: 15px;
                  top: 2px;
                  line-height: initial;

                  .shortName {
                    text-transform: uppercase;

                    &:not(:last-child)::after {
                      content: ", ";
                    }

                    &.teamNameSelected {
                      text-transform: unset;
                      @include ellipsis;
                    }
                  }
                }

                .hiddenCount {
                  font-size: 13px;
                  color: $c-78;
                  min-width: 30px;
                  line-height: initial;
                  position: relative;
                  top: 1px;
                }

                .arrow {
                  margin-left: 5px;
                  margin-top: 3px;
                  @include flex-center();
                  transition: all 0.3s ease;

                  svg {
                    fill: $c-53;
                    @include wt-ht(11px, 11px);
                  }

                  &.activeArrow {
                    transform: rotate(-180deg);
                    transition: all 0.3s ease;
                  }
                }
              }

              @extend %userListDropdown;

              .overlayUserFilterMask {
                @include bg-mask(4, transparent);
              }
            }

            .dateFilter {
              @extend %dateFilter;
            }

            .rdrCalendarWrapper {
              position: absolute;
              z-index: 5;
              right: 0;
              top: 36px;
              box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);

              .rdrMonthAndYearWrapper {
                padding-top: 0;
                height: 46px;
              }

              .rdrMonth {
                padding-bottom: 10px;
              }

              .rdrWeekDay {
                line-height: 1.8;
              }

              &.dateRangePicker .dateBtnSection {
                position: relative;
                padding: 0 0.833em 1.666em 0.833em;
                text-align: right;
                @include flex-center;
                justify-content: flex-end;

                input[type^="button"] {
                  height: 30px;
                  border-radius: 35px;
                  outline: none;
                  letter-spacing: 0.3px;
                  font-size: 14px;
                  width: 80px;
                  border: none;
                  font-weight: 400;
                  cursor: pointer;

                  &:not(:first-child) {
                    margin-left: 10px;
                  }

                  &.cancelCustomDateFilter {
                    background: white;
                    color: $c-66;
                    transition: all 0.3s ease;

                    &:hover {
                      transition: all 0.3s ease;
                      background: $c-f5;
                      color: black;
                    }
                  }

                  &.applyCustomDateFilter {
                    color: white;
                    background: #3d91ff;
                    transition: all 0.3s ease;

                    &:not(:disabled):hover {
                      transition: all 0.3s ease;
                      box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.12);
                    }

                    &:disabled {
                      background: $c-78;
                      cursor: default;
                      opacity: 0.5;
                    }
                  }
                }

                .wrapUpgradeIconTooltip {
                  padding-left: 30px;

                  .freemiumIcon {
                    right: 10px;
                  }
                }

                #customDateFilterErrMsg {
                  display: block;
                  position: absolute;
                  font-size: 12px;
                  bottom: 3px;
                  color: $c-78;
                  transition: all 0.3s ease;

                  &.highlightErr {
                    color: red;
                  }
                }
              }
            }

            .overlayCustomDateFilter {
              @include bg-mask(4, transparent);
            }
          }
        }

        .callsOverview {
          position: relative;
          @include wt-ht(100%, 100%);
          .callsTableCard {
            @extend %relBlock;
            @include wt-ht(100%, calc(100% - 178px));
            .callsOverviewSection {
              position: relative;
              background: $white;
              @include wt-ht(100%, 100%);
              border-radius: 10px;
              border: 1px solid $b-c-1;
              box-shadow: 0 0 12px 2.7px rgba(59, 59, 59, 0.04);

              .tableHeader {
                @extend %tableHeader;
                padding: 5px 15px;
                @include flex-direction(row);
                height: 49px;
                border-top: none;
                @include bor-rad-top(10px, 10px);
                overflow-y: scroll;
                @extend %checkboxBtn;
                .recording {
                  text-align: center;
                }
                .checkboxBtn {
                  .checkBoxSection {
                    padding-top: 2px;
                  }
                }
              }

              .tableBody {
                position: relative;
                @include wt-ht(100%, calc(100% - 59px));
                @include flex-direction(column);
                overflow: auto;
                background: white;

                &.showPagination {
                  @include wt-ht(100%, calc(100% - 108px));
                }

                .tableRow,
                .tableRowLoader {
                  position: relative;
                  width: 100%;
                  border-bottom: 1px solid $tb-bor-btm;
                  padding: 10px 15px;
                  @extend %checkboxBtn;
                  .name {
                    .callerName {
                      position: relative;
                      line-height: normal;
                      top: 2px;
                      font-size: 15px;
                      color: $blue-violet;
                      cursor: pointer;
                      &:hover {
                        color: $blue-violet !important;
                        text-decoration: underline;
                      }
                    }
                    .phoneNumber {
                      position: relative;
                      margin-top: 5px;
                      font-size: 14px;
                    }
                  }

                  .calledBy {
                    .calledName {
                      font-size: 15px;
                      margin-bottom: 5px;
                    }
                  }

                  .callNotes {
                    word-break: break-word;

                    .descText {
                      width: calc(100% - 16px);
                      text-align: left;
                      // @include text-ellipsis(2);
                    }
                  }

                  .playRecordedAudio {
                    position: relative;
                    @include flex-center;
                    padding-top: 10px;
                    width: 70%;
                    margin: 0 auto;

                    audio {
                      width: 100%;
                      margin: 0 auto;
                      background: transparent;
                      border-radius: 0;
                      height: 38px;

                      &::-webkit-media-controls-enclosure {
                        border-radius: 35px;
                        background-color: $c-f5;
                      }
                    }

                    audio::-webkit-media-controls-timeline-container,
                    video::-webkit-media-controls-timeline-container {
                      -webkit-appearance: media-controls-background;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: flex-end;
                      flex: 1 1;
                      -webkit-user-select: none;
                      height: 16px;
                      min-width: 0;
                    }

                    audio::-webkit-media-controls-current-time-display,
                    audio::-webkit-media-controls-time-remaining-display {
                      -webkit-appearance: media-current-time-display;
                      -webkit-user-select: none;
                      color: $c-66;
                    }

                    .hideAudio {
                      font-size: 14px;
                      cursor: pointer;
                      margin-left: 10px;
                      color: $blue-violet;
                    }
                  }

                  .actions {
                    .drop-down-menu {
                      top: -4px;
                      right: 24px;
                      ul li .listItem .listItemWrap {
                        width: 100px;
                        .itemName {
                          position: relative;
                          top: 2px;
                        }
                      }
                    }
                  }
                }

                .dataTableList,
                .tableRowLoader {
                  position: relative;
                  width: 100%;
                  font-size: 14px;
                  @include flex-direction(row);

                  .linear-background {
                    @include wt-ht(60%, 14px);
                    display: block;
                    border-radius: 10px;
                    margin: 2px 0;
                  }

                  .recording {
                    text-align: center;

                    .play,
                    .pause {
                      cursor: pointer;
                      padding: 5px;
                      border-radius: 35px;
                      border: 1px solid $c-ee;
                    }

                    .pause {
                      color: $blue-violet;
                    }

                    .play {
                      color: $bd-green;
                    }
                  }
                }

                .tableRowLoader {
                  min-height: 54px;

                  .recording,
                  .actions {
                    .linear-background {
                      @include wt-ht(24px, 24px);
                      border-radius: 35px;
                      margin: 0 auto;
                    }
                  }

                  .callDuration {
                    .linear-background {
                      @include wt-ht(35%, 14px);
                      margin: 0 auto;
                    }
                  }
                }
              }
            }
          }
        }

        .callsAnalytics {
          @extend %relBlock;
          height: calc(100% - 48px);

          .wrapCallsAnalyticsRow {
            padding: 15px 1% 0px;
            height: 100%;
            overflow: auto;

            .chartCard {
              @extend %relBlock;
              background: white;
              border-radius: 15px;
              box-shadow: 0 0 12px 2.7px rgba(32, 31, 31, 0.04);
              padding: 20px;
              margin-bottom: 25px;

              .chartContent {
                @extend %relBlock;
                height: 350px;
              }

              .chartLoader {
                @include flex-center;
                justify-content: center;
                @include wt-ht(100%, 100%);
              }

              .apexcharts-legend-marker {
                top: 1px !important;
                @include wt-ht(10px !important, 10px !important);
              }
            }

            h4.cardTitle {
              font-weight: 500;
              font-size: 18px;
              margin-bottom: 10px;
              color: $c-55;
            }

            .callsTeamSummary {
              position: relative;
              background: $white;
              @include wt-ht(100%, 100%);
              border-radius: 10px;
              border: 1px solid $b-c-1;
              box-shadow: 0 0 12px 2.7px rgba(59, 59, 59, 0.04);
              z-index: 0;
              h4.cardTitle {
                padding: 20px 20px 5px;
              }

              .tableContainer {
                overflow: auto;
                height: calc(100% - 70px);
              }
              .alignCenter {
                text-align: center;
              }
              table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
                border-top: 1px solid #eceff1;
                border-bottom: 1px solid #eceff1;
              }
              thead tr td:first-child,
              th {
                background: #fafafa;
                color: #666;
                font-weight: 500;
              }
              td,
              th {
                border-top: 1px solid #eceff1;
                text-align: left;
                font-size: 14px;
                min-width: 160px;
                letter-spacing: 0.3px;
                padding: 10px;
                height: 46px;
                vertical-align: middle;
              }

              tr td:first-child,
              tr th:first-child {
                padding-left: 20px;
              }
              tr th:last-child,
              tr td:last-child {
                padding-right: 20px;
              }
              th[scope="row"],
              thead tr {
                position: sticky;
                top: 0;
                left: 0;
                z-index: 999;
              }
              tbody th[scope="row"] {
                background: white;
                z-index: 998;
              }
              .teamSummaryLoader .linear-background {
                display: block;
                @include wt-ht(70%, 12px);
                border-radius: 10px;
                margin: unset;
              }
              .teamMember {
                position: relative;
                padding: 0 10px;
                font-size: 15px;

                h6.emailAddress {
                  font-size: 14px;
                  color: $c-78;
                  font-weight: 400;
                  margin-top: 2px;
                  margin-bottom: 5px;
                  @include ellipsis();

                  &.linear-background {
                    @include wt-ht(60%, 12px);
                    display: block;
                    border-radius: 10px;
                    margin-top: 7px;
                    margin-bottom: 7px;
                  }
                }

                p.fullName {
                  font-size: 16px;
                  color: $c-33;
                  margin-top: 5px;
                  font-weight: 500;
                  margin-bottom: 2px;
                  @include ellipsis();

                  &.linear-background {
                    @include wt-ht(80%, 16px);
                    display: block;
                    border-radius: 10px;
                    margin-top: 7px;
                    margin-bottom: 7px;
                  }
                }
              }

              .callsTeamSummaryReport {
                position: relative;
                background: $white;
                @include wt-ht(100%, calc(100% - 96px));
              }

              .tableHeader {
                @extend %tableHeader;
                padding: 5px 15px;
                @include flex-direction(row);
                height: 49px;
                border-top: none;
                border-top: 1px solid $tb-bor-btm;
              }

              .tableBody {
                position: relative;
                @include wt-ht(100%, calc(100% - 62px));
                @include flex-direction(column);
                overflow: auto;
                background: white;
                .tableRow,
                .callsTeamSummaryLoader {
                  font-size: 16px;
                  min-height: 54px;
                  @include flex-direction(row);
                  @extend %relPadTableWidth;
                  padding: 5px 15px;
                  border-bottom: 1px solid $tb-bor-btm;
                  .name {
                    word-break: break-all;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.noReportsFound {
  position: relative;
  letter-spacing: 0.3px;
  padding-top: 40px;
  @include flex-direction(column);
  justify-content: center;

  .textDesc {
    color: #abaeb5;
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: 0.3px;
    font-style: italic;
  }
}

.reportTableInitialScreen {
  position: relative;
  @include wt-ht(100%, 100%);
  padding: 25px;
  display: grid;
  align-items: center;
  grid-template-columns: 60% 35%;

  img {
    max-width: 100%;
    margin: 0 0 0 auto;
  }

  .textDesc {
    color: #abaeb5;
    font-size: 18px;
    max-width: 340px;
    line-height: 1.3;
    letter-spacing: 0.3px;
    text-align: left;
    padding-left: 20px;
    font-style: italic;
  }

  &.emptyEmailInsight {
    img {
      height: unset;
    }
  }

  &.emptyCallActivity {
    height: 300px;

    img {
      height: unset;
    }

    .textDesc {
      max-width: 380px;
    }
  }
}

.bulkFilterOptWrapper {
  position: relative;
  @include flex-center;
  justify-content: space-between;
  padding: 5px 10px 5px 0;
  .selectCallLogsWrapper {
    position: relative;
    .contactSelectedCount {
      line-height: initial;
    }
  }
  .filterOptList {
    position: relative;
    @include flex-center;
    justify-self: flex-end;
    .bulkLogCalls {
      position: relative;
      background: white;
      border: 1px solid $c-dd;
      cursor: not-allowed;
      user-select: none;
      @include flex-align-center;
      @include wt-ht(32px, 32px);
      border-radius: 5px;
      &.loading {
        @include disable(0.5);
      }
      svg {
        position: relative;
        display: block;
        color: $c-66;
        @include wt-ht(16px, 18px);
      }
      &.active {
        user-select: unset;
        cursor: pointer;
        svg path:first-child {
          fill: $blue-violet;
        }
      }
      .customTooltip {
        position: relative;
        top: 12px;
        .sharpPointer {
          padding: 6px;
          border-top-right-radius: 3px;
          border-bottom-left-radius: 50%;
          border-color: #393a3d;
          background: #393a3d;
        }
        .tooltipBody {
          right: -6px;
          width: unset;
          padding: 3px;
          &.minContent {
            min-width: 80px;
          }
          &.maxContent {
            min-width: 212px;
          }
          top: 4px;
          border-radius: 4px;
          background: #393a3d;
          max-width: 200px;
          color: white;
          .tooltipText {
            color: white;
            padding: 5px 8px;
          }
        }
      }
    }
    .exportBtnWrapper {
      @include wt-ht(32px, 34px);
      .customTooltip {
        .sharpPointer {
          border-bottom-left-radius: 35px;
          top: 12px;
          right: 2px;
        }
        .tooltipBody {
          right: -10px;
          top: 18px;
        }
      }
      .wrapLockIcon {
        .upgradeTooltipSection {
          right: -10px;
          .pointer {
            right: 20px;
          }
        }
      }
    }
  }
}
