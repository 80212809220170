.featureDemoContentCard {
  position: absolute;
  bottom: 15px;
  @include flex-center;
  background: white;
  border-radius: 35px;
  font-size: 14px;
  border: 1px solid $c-ee;
  width: fit-content;
  padding: 8px 20px;
  cursor: pointer;
  transition: all 0.2s;
  svg {
    @include wt-ht(24px, 24px);
    margin-right: 6px;
  }
  .featureName {
    margin-right: 5px;
    padding-top: 2px;
    color: black;
    &::after {
      content: ":";
    }
  }
  .featureVideoLink {
    color: #03a9f4;
    cursor: pointer;
    padding-top: 2px;
    &::after {
      content: "?";
    }
  }
  &:hover {
    background: $c-f5;
  }
}
