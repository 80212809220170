.linkedInReplyPopupWrapper {
  position: fixed;
  @include flex-center;
  justify-content: center;
  inset: 0;
  @include wt-ht(100%, 100%);
  z-index: 20;
  .closeIcon {
    cursor: pointer;
    border-radius: 50%;
    @include wt-ht(20px, 20px);
    padding: 6px;
    @include flex-center;
    justify-content: center;
    i {
      font-size: 18px;
    }
    &:hover {
      background-color: #f5f0f0;
    }
  }
  .card {
    position: relative;
    z-index: 20;
    background: white;
    @include wt-ht(840px, auto);
    border-radius: 5px;
    padding: 10px 15px 15px;
    .cardHeader {
      @include flex-center;
      justify-content: space-between;
      border-bottom: 1px solid $c-dd;
      padding: 12px 0;
    }
    .cardTitle {
      position: relative;
      @include flex-center;
      svg {
        @include wt-ht(24px, 24px);
        margin-left: 4px;
      }
      .linkedInTitle {
        font-size: 16px;
        margin-left: 8px;
      }
    }

    .toLinkedInDetails {
      position: absolute;
      display: flex;
      right: 45px;
      margin: 5px 0;

      .linkedInActionListBtn {
        @include flex-center;
        ul {
          @include list-style-zero();
          @include flex-center;
          li {
            &.reply {
              position: relative;
              cursor: pointer;
              @include flex-center;
              transition: all 0.3s ease-in-out;
              border-radius: 12px;
              padding: 2px 8px;
              color: $blue-violet;
              font-size: 12px;
              .name {
                position: inherit;
                top: 1px;
                &.icon {
                  padding-left: 5px;
                }
              }
              svg {
                @include wt-ht(13px, 13px);
                color: $blue-violet;
              }
              &:hover {
                background: $c-f5;
              }
            }
          }
        }
      }
    }
    .cardBody {
      overflow: auto;
      max-height: 400px;
      .linkedInContentWrapper {
        @include flex-center();
        justify-content: center;
        padding: 20px;
        margin-top: 10px;
        transition: all 0.3s ease;
        pointer-events: none;
        font-size: 14px;
        height: 350px;
        .emailContentSpinner {
          content: "";
          box-sizing: border-box;
          position: absolute;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 3px solid #ccc;
          border-top-color: $blue-violet;
          z-index: 1;
          animation: spinner 0.6s linear infinite;
        }
      }
    }

    .actionBtnList {
      position: relative;
      @include flex-center;
      justify-content: flex-end;
      column-gap: 15px;
      .actionBtn {
        font-size: 14px;
        border-radius: 5px;
        letter-spacing: 0.3px;
        min-width: 120px;
        margin: unset;
        height: 40px;
        padding: 0 12px;
        &.cancelBtn {
          border: solid 1px $blue-violet;
          color: $blue-violet;
          background: white;
        }
        &.loading {
          width: 120px !important;
          height: 40px !important;
          .knobs:before {
            left: 12px;
            top: 12px;
          }
          .knobs::before {
            animation: spin 1s linear infinite;
            border: 1.5px solid $white;
            border-bottom-color: transparent;
            border-left-color: transparent;
            border-left: 1.5px;
            background-color: transparent;
          }

          .toggleCheckbox:checked + .knobs:before {
            background-color: transparent;
          }
        }
        &.sendEmailBtn {
          border: solid 1px $blue-violet;
          background: $blue-violet;
          &.btnRoundedLeft {
            min-width: 90px !important;
            @include bor-rad-right(0 !important, 0 !important);
          }
          &.disabled {
            @include disable();
          }
        }
      }
    }
  }
  .linkedInReplyContentWrapBody {
    border-bottom: 1px solid $c-dd;
    padding: 8px 0;
    &:not(:first-child) {
      opacity: 0.5;
    }
    &:last-child {
      border-bottom: 0 !important;
    }
    &:first-child {
      .linkedInDate {
        color: #28ad56;
      }
    }
    .linkedInIntroDetails {
      @include flex-center;
      .imagePic {
        @include wt-ht(16px, 16px);
      }
      .imgPlaceholder {
        @include flex-center;
        img {
          @include wt-ht(30px, 30px);
          border-radius: 50%;
        }
        span {
          font-size: 30px;
          color: #c0b9b9;
        }
      }
      .linkedInDate {
        margin-left: 8px;
        font-size: 12px;
      }
      .linkedInSenderName {
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 500;
        margin-left: 6px;
      }
    }
    .linkedInBodyDetails {
      margin-left: 36px;
      .linkedInMessage {
        padding: 8px 0;
        color: $c-3b;
        font-size: 13px;
        white-space: pre-line;
      }
    }
  }
  .linkedInReplyPopupOverlay {
    @include bg-mask(19, rgba(0, 0, 0, 0.5));
  }
}
