.initialWelcomePopupWrapper {
  @extend %popupCard;
  .card {
    width: 600px;
    max-width: 700px;
    max-height: 700px;
    overflow: auto;
    text-align: center;
    position: relative;
    .popupClose {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
      padding: 2px;
      @include wt-ht(18px, 18px);
      @include flex-align-center;
      i {
        font-size: 16px;
      }
      &:hover {
        background-color: $c-dd;
        border-radius: 50%;
      }
    }
    .welcomePopupHeader {
      @include flex-center;
      flex-direction: column;
      svg {
        width: 300px;
        height: 200px;
        margin-bottom: 20px;
      }
      .userName {
        font-size: 18px;
        color: #1d2026;
        font-weight: bold;
        margin-bottom: 16px;
      }
      .headerPopupText {
        font-size: 20px;
        color: #1d2026;
        margin-bottom: 16px;
        font-weight: bold;
        width: 75%;
      }
      .orangeText {
        color: #f1473e;
      }
      .headerAdditionalText {
        font-size: 15px;
        color: #1d2026;
        width: 82%;
      }
    }
    .welcomePopupHeader,
    .welcomePopupFooter {
      .popupText {
        font-size: 12px;
        color: $c-66;
        margin-bottom: 20px;
        border: 1px solid $c-dd;
        border-radius: 5px;
        background-color: #f7f7f7;
        text-align: left;
        padding: 10px;
      }
      .letsGoBtn {
        background-color: $blue-violet;
        @include flex-center();
        justify-content: center;
        height: 32px;
        margin: 24px auto;
        border-radius: 6px;
      }
    }
  }
}
