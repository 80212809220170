%btn-blue {
  user-select: none;
  background-color: $blue-violet;
  border-color: $blue-violet;
  color: $white;
  outline: none;
  border: none;
  padding: 8px;
  border-radius: 4px;
  @include flex-center();
  justify-content: center;
  min-width: 120px;
  font-size: 16px;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &:hover {
    background: #2743f4;
    transition: all 200ms ease-in-out;
  }
}

%errMsgText {
  position: absolute;
  bottom: -15px;
  font-size: 11px;
  left: 0;
  color: red;
}

%relBlockWidth {
  position: relative;
  display: block;
  width: 100%;
}

%relWidth {
  position: relative;
  width: 100%;
}

%relBlock {
  position: relative;
  display: block;
}

%relPadTableWidth {
  width: 100%;
  position: relative;
  padding: 5px 25px;
}

%tableHeader {
  background: $c-fa;
  color: $c-66;
  font-size: 14px;
  font-weight: 500;
  @extend %relPadTableWidth;
  border-top: 1px solid $tb-bor-btm;
  border-bottom: 1px solid $tb-bor-btm;
}

%empty-section {
  position: relative;
  @include wt-ht(100%, 100%);
  @include flex-center();
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  overflow: auto;

  img {
    width: 120px;
    height: 100px;
    object-fit: contain;
  }

  h6 {
    font-weight: 500;
    font-size: 16px;
    margin: 15px 0;
  }

  .noSharedSeqIcon {
    width: 20px;
    height: auto;
  }

  button {
    @extend %btn-blue;
    padding: 10px 15px;
    border-radius: 5px;

    svg {
      width: 18px;
      height: 18px;
      fill: $white;
      vertical-align: middle;
    }

    .btn-name {
      padding-left: 8px;
    }
  }
}

.headerSection {
  position: relative;
  padding: 12px 12px;
  background-color: $white;
  height: 60px;
  @include flex-center();
  letter-spacing: 0.3px;
  justify-content: space-between;

  .breadCrumbLeftSection {
    width: 70%;
    @include flex-center;
    justify-content: flex-start;

    .sharedLabel {
      @include flex-center;
    }
  }

  .add-new-dropdown {
    position: relative;
    @include flex-center;

    &.reportPageHeader,
    &.settingsPageHeader,
    &.pricingPageHeader {
      @include flex-center();

      .helpIcon {
        @include flex-center();
        cursor: pointer;

        svg {
          @include wt-ht(24px, 24px);
        }
      }
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    button {
      position: relative;
      min-width: 120px;
      @extend %btn-blue;
      height: 36px;
      font-size: 14px;
      border-radius: 5px;
      font-family: $font-regular;
      padding: 8px;
      letter-spacing: 0.3px;
      font-weight: 600;
    }

    .drop-down-menu {
      top: 36px;
    }

    .dropdown-overlay {
      @include bg-mask(1, transparent);
    }
  }

  .sharedBadge {
    letter-spacing: 0.4px;
    background-color: #ffdfd1;
    padding: 5px;
    line-height: 9px;
    font-size: 9px;
    color: #e46833;
    border-radius: 3px;
    margin-left: 10px;
    text-transform: uppercase;
  }
}

.icon-hover-btn,
%closeIcon {
  font-size: 18px !important;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in;
  color: #787878;

  &:hover {
    background: #e8e8e8;
    color: $c-22;
    transition: all 0.2s ease-in;
  }
}

.strikeThrough {
  text-decoration: line-through;
}

%body-layout {
  @include wt-ht(100%, calc(100% - 60px));
  padding: 12px;
  display: block;
  overflow: hidden;
  position: relative;

  &.productDiscount {
    @include wt-ht(100%, calc(100% - 114px));
  }

  .layoutBody {
    height: 100%;
    border-radius: 10px;
    background: $white;
    position: relative;
    flex: 1;
    transition: 0.5s;
  }
}

.pageLayout {
  position: relative;
  @include wt-ht(100%, calc(100% - 60px));
  display: grid;
  grid-template-columns: 260px calc(100% - 260px);
  padding: 12px;

  .navLayout,
  .contentLayout {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 10px;
    background-color: $white;
  }

  .navLayout {
    @include wt-ht(calc(100% - 10px), 100%);
  }

  .contentLayout {
    @include wt-ht(100%, 100%);
  }
}

%layout {
  position: relative;
  display: block;
  @include wt-ht(100%, 100%);
}

%hoverScroll {
  overflow: hidden;

  &:hover {
    overflow: auto;
  }
}

%email-insight-loader {
  display: flex;
  flex-direction: row;

  .linear-background {
    width: 30px;
    height: 30px;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}

.contactUploadLoader {
  background: rgba(249, 249, 249, 0.58);
  border-bottom: 1px solid #f8f8f8;
  padding: 10px;
}

.contactUploadMsg {
  margin-left: 20px;
  padding: 10px;
}

.contactUploadLoader,
.contactUploadMsg {
  transition: all 300ms ease;
  color: $green;
  font-size: 14px;
  @include flex-center();
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation: cardSlideUp 0.3s linear;
}

.circleLoader {
  position: relative;
  margin-right: 5px;

  &.animatedButton {
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px;
    font-weight: 500;
    color: $white;
    background: $light-green;
    font-size: 14px;
    border-radius: 5px;
    transition: all 0.3s ease;

    &.loading {
      position: relative;
      transition: all 0.3s ease;
      border-radius: 50%;
      @include wt-ht(16px, 16px);

      .load-text {
        position: absolute;
        color: $white;
        font-size: 10px;
        font-weight: bold;
        text-align: center;
        line-height: 1;
        padding: 5px;
        z-index: 2;
        border-radius: 50%;
        transition: 0.3s ease all,
          left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
        animation: spin 1s linear infinite;
        border: 1.5px solid $white;
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-left: 1px;
        background-color: transparent;
        @include wt-ht(16px, 16px);
        top: 2px;
        left: 2px;
      }
    }

    &.fill-transistion {
      transition: transform 0.3s 0.1s ease-out, opacity 0.1s 0.3s,
        background-color 0.1s 0.3s;
      transform: scale(100);
      opacity: 1;

      .load-text {
        border: none;
      }
    }
  }
}

%inputWrapper {
  @include flex-center();
  margin-bottom: 30px;

  &.jobTitle {
    margin-bottom: 20px;
  }

  font-family: $font-regular;
  position: relative;
  @extend %relWidth;

  .errMsgText {
    @extend %errMsgText;
  }

  .inputCont {
    @extend %relWidth;
    display: inline-block;
    background-color: $white;
    color: $black;

    .inputBox {
      line-height: 36px;
      font-size: 15px;
      will-change: font-size;
      width: 100%;
      -webkit-appearance: none;
      border: none;
      color: $blue-violet;
      border-bottom: 1px solid $bg-default;
    }

    .inputLabel {
      color: $c-66;
      font-size: 13px;
      will-change: font-size;
      position: absolute;
      bottom: 12px;
      letter-spacing: 0.3px;
      left: 0px;
      transition: all 0.2s linear;

      .required {
        color: red;
        padding-left: 5px;
      }

      .infoIcon {
        position: relative;
        top: 2px;
        left: 4px;

        i {
          font-size: 13px;
          cursor: pointer;
        }

        .customTooltip {
          position: relative;
          z-index: 100;

          .sharpPointer {
            border-top-right-radius: 2px;
            padding: 5px;
            border-right: 1px solid black;
            border-top: 1px solid black;
            right: 1px;
            background: black;
          }

          .tooltipBody {
            background: black;
            right: -15px;
            top: 3px;
            width: 200px;
            border-radius: 4px;

            .tooltipText {
              color: white;
              white-space: initial;
              padding: 6px;
              font-size: 11px;
              text-align: center;
            }
          }
        }
      }
    }

    &.readOnlyInput {
      pointer-events: none;
      user-select: none;
      cursor: initial;

      .inputLabel {
        bottom: 36px;
        will-change: font-size;
        font-size: 11px;
        transition: all 0.2s linear;
      }

      .inputBox {
        border: 0px;
      }
    }
  }

  .inputBox ~ .focusBorder {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    will-change: height;
    z-index: 99;
  }

  .inputBox ~ .focusBorder:before,
  .inputBox ~ .focusBorder:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: $blue-violet;
    transition: 0.4s;
    will-change: background-color;
  }

  .inputBox ~ .focusBorder:after {
    left: auto;
    right: 0;
  }

  .inputBox:focus ~ .focusBorder:before,
  .inputBox:focus ~ .focusBorder:after {
    width: 50%;
    transition: 0.4s;
  }

  .inputBox:focus ~ label,
  .select-box:focus ~ label,
  .inputBox.filled ~ label {
    bottom: 36px;
    font-size: 11px;
    transition: all 0.2s linear;
  }
}

%secondaryButton {
  border: solid 1px $blue-violet;
  background-color: $white;
  color: $blue-violet;
  font-size: 13px;
}

@mixin btnWithArrow($mt, $btnAlign, $iconSize, $fs, $mlIcon) {
  margin-top: $mt;
  @extend %relWidth;
  display: flex;
  justify-content: $btnAlign;

  button {
    user-select: none;
    background-color: $blue-violet;
    border-color: $blue-violet;
    color: $white;
    outline: none;
    border: none;
    letter-spacing: 0.3px;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: $fs;
    margin: unset;
    cursor: pointer;

    &.loading {
      border-radius: 50%;
    }

    &:hover {
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    }

    @include flex-direction(row);
    transition: all 200ms ease-in-out;

    i {
      font-size: $iconSize;
      margin-left: $mlIcon;
      transform: rotate(180deg);
      will-change: transform;
    }

    &:hover i {
      transition: all 300ms ease;
    }
  }
}

@mixin AddContactDropDown() {
  position: relative;

  .dropdown-overlay {
    @include bg-mask(11, transparent);
  }

  .addColor {
    background-color: $blue-violet !important;
  }

  .addContactCont {
    font-size: 13px;
    font-weight: 600;
    background-color: $blue-violet !important;
    border-radius: 5px;
    @include flex-center();
    padding: 0 10px;
    cursor: pointer;
    height: 36px;
    font-family: $font-regular;

    .btnText {
      margin-left: 10px !important;
    }

    &.loading {
      width: 140px !important;
      height: 36px !important;

      .knobs:before {
        left: 4px;
        top: 12px;
      }

      .knobs::before {
        animation: spin 1s linear infinite;
        border: 1.5px solid $white;
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-left: 1px;
        background-color: transparent;
      }

      .toggleCheckbox:checked + .knobs:before {
        background-color: transparent;
      }
    }

    .addContactIcon {
      width: 14px;
      height: 14px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      line-height: 16px;

      svg {
        width: inherit;
        height: inherit;
        fill: $blue-violet;
      }
    }

    .addContactBtn {
      color: $blue-violet;
      display: table-cell;
      vertical-align: middle;
      font-size: 13px;
    }
  }

  .drop-down-menu {
    right: 0px;

    .icon-holder i {
      margin-right: 0px !important;
    }

    #singleContact {
      .listItemWrap {
        .dropdownImg {
          @include wt-ht(16px, auto);
          object-fit: contain;
          margin-right: 15px;
        }
      }
    }

    #bulkUpload {
      .listItemWrap {
        .dropdownImg {
          @include wt-ht(20px, auto);
          object-fit: contain;
          margin-right: 11px;
        }
      }
    }
  }
}

.dropdownList {
  #importLinkedIn {
    svg {
      width: 22px;
      height: 16px;
    }
  }

  #singleContact {
    .icon-holder {
      .material-icons-outlined {
        font-size: 21px;
        color: $c-10c;
      }
    }

    .listItemWrap {
      width: 110px;
    }
  }

  #bulkUpload {
    .icon-holder {
      .material-icons-outlined {
        font-size: 22px;
        color: $c-0635;
      }
    }

    .listItemWrap {
      width: 110px;
    }
  }
}

.helpIcon {
  @include flex-center();
  cursor: pointer;

  svg {
    @include wt-ht(24px, 24px);
    margin-right: 30px;
  }
}

.headerBtnContainer {
  @include flex-center();
  justify-content: space-between;

  #requestDemo {
    background-color: #00ce76;
    width: 130px;
    transition: all 0.3s;
    margin-right: 15px;
  }

  #startTrial {
    background-color: #ff7835;
    width: 130px;
    transition: all 0.3s;
    margin-right: 15px;
  }

  .add-new-dropdown.addContactDropDown {
    @include AddContactDropDown();
  }
}

.alignFlexStart {
  align-items: flex-start;
}

.anchorHighLight {
  position: relative;
  cursor: pointer;

  &:hover {
    color: $blue-violet !important;
    text-decoration: underline;
  }
}

.underlineHighlight {
  position: relative;
  display: block;
  text-align: left;
  cursor: pointer;

  &:hover,
  &:hover .name {
    color: $blue-violet !important;
    text-decoration: underline;
  }
}

%loaderHeight {
  height: 12px;
  margin-bottom: 5px;
}

.heading {
  position: relative;
  color: $c-66;
  @include ellipsis();
  @include flex-center();
  margin-bottom: 5px;
  font-size: 14px;

  .linear-background {
    display: block;
    width: 60%;
    border-radius: 6px;
    @extend %loaderHeight;
  }

  span.icon {
    display: block;
    @include wt-ht(14px, 14px);
    margin-right: 10px;
  }

  i {
    font-size: 14px;
    vertical-align: middle;
    margin-right: 10px;
  }

  svg {
    @include wt-ht(12px, 12px);
    fill: $c-66;
    margin-right: 10px;
  }

  .blueHighLight {
    color: $blue-violet;

    &.phoneSize {
      font-size: 13px;
    }
  }

  .textNormal {
    color: $c-66;
  }
}

.taskResultsection {
  width: 100%;
  display: grid;
  position: relative;
  margin-top: 5px;
  grid-template-columns: repeat(2, 50%);

  .taskBody {
    padding: 0 5px;
    position: relative;

    h6 {
      text-align: center;
      font-weight: 400;
      width: 100%;
      font-size: 14px;

      &.total {
        color: $blue-violet;
      }

      &.completed {
        color: $green;
      }

      .count {
        font-weight: 600;
      }

      &.linear-background {
        width: 60%;
        margin: 0 auto;
        border-radius: 6px;
      }
    }

    .tableBody {
      width: 100%;
      position: relative;
      @include list-style-zero();
      margin-top: 10px;
      margin-bottom: 10px;

      &.total {
        border-right: 1px solid $c-ee;
      }

      .tableRow {
        position: relative;
        display: grid;
        font-size: 14px;
        color: $c-66;

        .linear-background {
          border-radius: 6px;
        }

        &:not(:last-child) {
          margin-bottom: 5px;
        }

        grid-template-columns: 20px auto;

        i {
          font-size: 12px;
        }

        svg {
          fill: $c-66;
          @include wt-ht(12px, 12px);
        }

        img {
          width: 10px;
          height: 10px;
        }

        .taskTextCount {
          text-align: left;
          margin-bottom: 5px;

          h6 {
            text-align: left;
            margin-bottom: 5px;
            font-size: 12px;
            text-transform: capitalize;

            &.linear-background {
              margin: 0 0 5px;
              border-radius: 6px;
              @extend %loaderHeight;
            }
          }

          p {
            font-weight: 600;

            &.linear-background {
              width: 30%;
              border-radius: 6px;
            }
          }
        }
      }
    }
  }
}

.remainCount {
  margin-top: 10px;
  padding: 5px 10px;
  font-size: 12px;
  background: $white;
  width: fit-content;
  border-radius: 35px;
  border: 1px solid $c-ee;
  color: $c-78;
}

.emptyTaskSequence {
  margin-left: 24px;
}

.contactSequence {
  position: relative;
  padding-left: 24px;
  list-style: none;

  li {
    position: relative;

    a {
      font-size: 14px;
      text-decoration: none;
      color: $blue-violet;
      @include ellipsis();

      &:hover {
        cursor: pointer;
      }

      &:before {
        content: "- ";
      }
    }

    &.linear-background {
      @extend %loaderHeight;
      width: 70%;
    }
  }

  .remainCount {
    margin-top: 10px;
    padding: 5px 10px;
    font-size: 12px;
    background: $white;
    width: fit-content;
    border-radius: 35px;
    border: 1px solid $c-ee;
    color: $c-78;
  }
}

.email-insight-stage {
  margin-top: 10px;
  padding-left: 24px;
}

.insight-loader {
  margin-top: 10px;
  @extend %email-insight-loader;
  margin-left: 24px;

  .linear-background {
    border-radius: 50% !important;
  }
}

.padleft24 .email-insight-stage {
  padding-left: 24px;
}

.unavailableItalic {
  font-size: 12px;
  font-style: italic;
  color: $c-78;
}

.task-notes .linear-background {
  border-radius: 6px !important;
}

%popupCard {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  @include flex-center();
  justify-content: center;
  letter-spacing: 0.3px;
  width: 100%;
  z-index: 100;
  overflow: auto;

  .card {
    position: relative;
    display: block;
    z-index: 101;
    border-radius: 10px;
    padding: 30px;
    background: white;
    width: 360px;

    .cardTitle {
      position: relative;
      display: flex;
      justify-content: space-between;

      h4 {
        margin-bottom: 20px;
        font-size: 22px;
      }

      i {
        position: absolute;
        top: -15px;
        right: -20px;
        font-size: 19px;
        padding: 3px;
        border-radius: 50%;
        color: $c-53;
        cursor: pointer;

        &:hover {
          background: $c-dd;
          color: $black;
        }
      }
    }

    .cardBody {
      @extend %relBlockWidth;
    }
  }
}

%titleTextHeading {
  @extend %relBlock;
  padding-bottom: 20px;
  box-sizing: border-box;
  font-size: 15px;
  font-family: $font-regular;
  color: $c-66;
}

%editorConfig {
  @extend %relBlock;
  height: 100%;

  .ql-toolbar {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    background-color: $c-fa;
    border: 0;
    border-top: 1px solid $c-cc;
    @include bor-rad-btm(5px, 5px);

    .ql-formats {
      .ql-variables {
        display: none;
      }

      .ql-picker-options {
        transform: translateY(-100%);
        margin-top: -25px;

        .ql-picker-item[data-value="9.75px"]:before {
          content: "small";
          font-size: 10px !important;
        }

        .ql-picker-item[data-value="13px"]:before {
          content: "Normal";
          font-size: 13px !important;
        }

        .ql-picker-item[data-value="19.5px"]:before {
          content: "Large";
          font-size: 18px !important;
        }

        .ql-picker-item[data-value="32.5px"]:before {
          content: "Huge";
          font-size: 25px !important;
        }
      }
    }
  }

  .ql-container.ql-snow {
    border: 0px;

    .ql-tooltip {
      left: 0px !important;
      transform: translateY(-10px);
    }
  }

  .quill {
    height: calc(100% - 40px);
    position: relative;
    border: 1px solid $c-e1;
    border-radius: 5px;
  }
}

%primaryBlueBtn {
  background: $blue-violet;
  border-color: $blue-violet;
  color: $white;
  border: none;
  letter-spacing: 0.3px;
  border-radius: 4px;

  &.loading {
    border-radius: 50%;
    transition: all 200ms ease-in-out;
  }

  &:hover {
    background: $blue-btn-hover;
    transition: all 200ms ease-in-out;
  }
}

%outBlueBorBtn {
  background: white;
  border: 1px solid $c-e1;
  color: $c-66;
  letter-spacing: 0.3px;
  border-radius: 4px;

  &:hover {
    background: $c-f5;
    color: $c-33;
    border-color: $c-f5;
    transition: all 200ms ease-in-out;
  }
}

%primaryOutlineBtn {
  background: white;
  border: 1px solid $blue-violet;
  color: $blue-violet;
  letter-spacing: 0.3px;
  border-radius: 4px;

  &:hover {
    background: $blue-violet;
    color: white;
    border-color: $blue-violet;
    transition: all 200ms ease-in-out;
  }
}

%cardSlideHeader {
  background: $blue-violet;
  position: relative;
  height: 42px;
  padding: 8px 10px 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .titleCont .backArrow {
    font-size: 16px;
    padding: 5px;
    margin-right: 5px;
    color: white;
    transition: all 0.3s;
    border-radius: 50%;

    &:hover {
      background: #0078d7;
      transition: all 0.3s;
    }
  }

  .title {
    font-size: 16px;
    color: $white;
    font-weight: 400;
  }

  .closeIcon {
    color: $white;
    display: block;
    cursor: pointer;

    i {
      font-weight: 400;
      font-size: 18px;
      padding: 3px;
      border-radius: 50%;

      &:hover {
        background: #2c45e6;
      }
    }
  }
}

%contactInfoCard {
  .contactInfoList {
    padding: 10px 20px;
    background: white;
    border-bottom: 1px solid $bg-default;

    .contactFullName {
      font-size: 16px;
      font-weight: 500;
      color: $blue-violet;
      margin-bottom: 5px;
      word-break: break-word;

      &.linear-background {
        width: 180px;
      }
    }

    .titleCompanyWrap {
      @extend %relBlock;
      font-size: 14px;
      word-break: break-all;

      .title {
        color: $c-5c;
      }

      .at {
        font-size: 16px;
        color: $c-5c;
        margin: 0 2px;
      }

      .company {
        color: $c-66;
      }
    }

    .titleCompanyWrapLoader {
      position: relative;
      font-size: 14px;
      display: grid;
      grid-template-columns: 120px 24px 120px;
      height: 32px;
      align-items: center;

      span.linear-background {
        height: 14px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    .iconInfoWrap {
      display: flex;
      position: relative;
      user-select: none;
      margin-top: 5px;

      .linear-background {
        @include wt-ht(30px, 30px);
      }

      .phoneTooltip {
        white-space: nowrap;
      }

      span:not(:last-child) {
        margin-right: 8px;
      }

      .circleTooltipIcon {
        @include flex-center();
        justify-content: center;
        @include wt-ht(24px, 24px);
        cursor: pointer;
        color: $c-66;
        border: 1px solid #d5dadf;
        font-size: 12px;
        border-radius: 50%;
        padding: 6px;

        svg {
          @include wt-ht(12px, 12px);
          color: $c-66;
          fill: $c-66;
        }
      }
    }

    .featureActionIconsWrap {
      position: relative;
      @include flex-center;
      margin-top: 5px;

      .iconBtnRounded {
        position: relative;
        @include flex-center;
        justify-content: center;
        border-radius: 35px;
        border: 1px solid $c-c7;
        cursor: pointer;
        @include wt-ht(32px, 32px);

        svg {
          @include wt-ht(16px, 16px);
        }

        &.iconBtnUpcoming {
          svg {
            fill: $c-aa;
            @include wt-ht(14px, 14px);
          }
        }

        &.iconBtnEnabled {
          svg {
            fill: $blue-violet;
          }
        }

        &:hover {
          box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.08);
        }

        &:not(:last-child) {
          margin-right: 10px;
        }

        .customTooltip {
          .sharpPointer.alignRight {
            top: 14px;
            left: -14px;
          }

          .tooltipBody.alignRight {
            top: 21px;
            left: -30px;
            min-width: unset;
          }
        }

        &.linear-background {
          border-color: transparent;
        }
      }
    }
  }
}

%contactDetailInfoList {
  .contactDetailInfoList {
    position: relative;
    width: 100%;
    padding-top: 10px;

    .infoIconCard {
      position: relative;
      display: grid;
      grid-template-columns: 22px calc(100% - 22px);
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      .icon {
        color: $c-66;

        svg {
          @include wt-ht(12px, 12px);
          fill: $c-66;
          margin-right: 10px;
        }
      }

      .text {
        font-size: 14px;
        color: $c-66;
        word-break: break-word;

        &.blueColor {
          color: $blue-violet;
        }
      }

      .linkedinInfo {
        font-size: 14px;
        color: $c-66;
        color: $blue-violet;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          text-decoration-color: $blue-violet;
        }

        &.ellipsisWrap {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        width: fit-content;
      }
    }
  }
}

%sharedBadge {
  .sharedBadge,
  .ownerBadge,
  .ownerBadgeWrap {
    letter-spacing: 0.4px;
    background-color: #ffdfd1;
    padding: 5px;
    line-height: 9px;
    font-size: 9px;
    color: #e46833;
    border-radius: 3px;
    margin-left: 10px;
    text-transform: uppercase;
  }

  .sharedFolder {
    font-size: 14px;
    color: #757575;
    margin-right: 5px;
  }

  .ownerBadgeWrap {
    background-color: #e3e3e3;
    color: #757575;
    margin-left: 0;
    padding: 0;
  }

  .ownerCont {
    @include flex-center;
    background-color: #e3e3e3;
    color: #757575;
    margin-left: 5px;
    border-radius: 3px;

    .lockIcon,
    .sequenceOwnerReadOnlyIcon {
      font-size: 11px;
      line-height: 11px;
      padding: 5px 5px 5px 0px;
    }

    .ownerBadge {
      background-color: #e3e3e3;
      color: #757575;
      margin: 0px;
      padding: 5px;
    }
  }
}

%EditFolder {
  .nameInputInnercont {
    @include flex-center();

    .inputWrapper {
      width: 80%;
    }

    .cancelWrapper {
      width: 15%;
    }
  }
}

%AutoEmail {
  svg {
    fill: $white;
    @include wt-ht(24px, 24px);
    background-color: $c-38;
    padding: 3px;
    border-radius: 50%;
  }
}

%ManualEmail {
  .emailIcon {
    background: $c-ff9;
    padding: 3px;
    border-radius: 50%;
    @include flex-center();
    justify-content: center;

    img {
      object-fit: contain;
      width: 17px;
    }
  }
}

/* -- Edit and Delete Icon -- */
.editActionIcon,
.deleteActionIcon {
  @include wt-ht(35px, 30px);
  @include flex-center();
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  transition: all 0.3s;

  svg {
    @include wt-ht(15px, 15px);
    fill: $c-66;
  }

  &:hover {
    transition: all 0.3s;
    background: $c-f5;
    @include wt-ht(35px, 35px);

    svg {
      fill: $c-33;
    }
  }
}

%AddStepButton {
  .addStepsButton {
    float: none;
    position: relative;
    height: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;

    .addNewButton {
      margin: 0;
      font-size: 0.9vw;
      width: fit-content;
      padding: 10px;
    }

    .stepWrapper {
      .downCont {
        min-width: 220px;
        height: fit-content;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        border-radius: 10px;
        box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
        border: solid 1px #ebebeb;
        padding: 0px;
        opacity: 0;
        bottom: 50px;
        transition: opacity 0.4s ease-in-out;
        top: unset;
      }

      .stepInnerWrapper {
        margin: 10px 0px;

        .stepCont {
          position: relative;
          display: flex;
          align-items: center;
          flex-direction: row;
          padding: 10px 15px;
          cursor: pointer;
          font-size: 14px;

          .subMenuArrow {
            font-size: 16px;
          }

          &#email {
            .stepIcon {
              svg {
                fill: $c-38;
              }
            }
          }

          &#linkedin {
            position: relative;

            .stepIcon {
              height: 16px;

              svg {
                width: 16px;
                height: 13px;
                fill: $c-4b;
              }
            }

            &:hover .linkedInSubMenu {
              display: block;
            }
          }

          &#manualEmail {
            .stepIcon {
              img {
                width: inherit;
                height: inherit;
                object-fit: contain;
              }
            }
          }

          &#phone {
            .stepIcon {
              svg {
                fill: $c-1eb9d5;
                @include wt-ht(18px, 18px);
              }
            }
          }

          &#customTask {
            .stepIcon {
              svg {
                fill: $fade-green;
                @include wt-ht(16px, 16px);
              }
            }
          }

          &:hover {
            background-color: #fafafb;
          }

          .freemiumIcon {
            svg {
              right: 10px;
              top: -13px;
            }
          }

          .stepIcon {
            @include wt-ht(16px, 16px);
            display: inline-block;
            background-repeat: no-repeat;
            margin-right: 13px;

            svg {
              @include wt-ht(16px, 16px);
              fill: $c-66;
            }
          }

          .addstepBtn {
            color: $c-66;
            width: 85%;
            font-size: 14px;
            line-height: 16px;
          }

          &.inActive {
            svg {
              fill: #cacaca;
            }

            pointer-events: none;
            user-select: none;

            .addstepBtn {
              color: #cacaca;
            }
          }

          .linkedInSubMenu {
            @include linkedInSubMenu();
          }
        }
      }
    }

    &.down {
      .downCont {
        top: 5px;
        bottom: unset;
        opacity: 1;
        right: 0;
      }
    }
  }
}

%NotesSection {
  .notesCont {
    height: 0px;
    margin-top: 15px;
    transition: all 0.4s ease;

    .notes-section {
      height: inherit;

      textarea {
        height: 90px;
        display: none;
        font-family: $font-regular;
        font-size: 13px;
        line-height: 1.4;

        &::placeholder {
          font-size: 12px;
        }
      }
    }

    &.notesDropDownShown {
      height: 90px !important;
      margin-bottom: 5px;
      transition: all 0.4s ease;

      textarea {
        display: block;
        transition: all 0.4s ease;
      }
    }
  }

  .notesOptionShown {
    .quill {
      .ql-container {
        max-height: 127px !important;

        .ql-editor {
          min-height: 127px !important;
        }
      }
    }
  }
}

%actionBtnWrapper {
  .actionWrapper {
    position: relative;
    @include flex-center();
    margin: 0 auto;
    width: fit-content;

    button {
      width: 130px;

      &.cancel {
        @extend %secondaryButton;
        border-color: $c-dd;
        color: $c-66;
        margin-right: 15px;
        cursor: pointer;
      }

      &.verify,
      &.confirm {
        @extend %primaryBlueBtn;
        cursor: pointer;
      }
    }

    .verifyBtn,
    .confirmBtn {
      width: 130px;
    }
  }
}

@mixin adminPopup {
  bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-user-select: none;
  user-select: none;
  width: -moz-fit-content;
  right: 0;
  margin: auto;
  height: fit-content;
  z-index: 200;
  width: fit-content;

  .adminPopupWrapper {
    width: 400px;
    position: relative;
    padding: 30px 24px;
    border-radius: 10px;
    box-shadow: 0 0 22.3px 1.7px rgba(59, 59, 59, 0.14);
    border: 1px solid #ebebeb;
    background-color: #fff;
    display: grid;
    grid-template-columns: 200px 150px;
    z-index: 9;

    .popupContent {
      padding-right: 15px;

      .popupTitle {
        color: $c-33;
        font-size: 14px;
        font-family: "Graphik-Semibold";
        line-height: 1.3;
        letter-spacing: 0.18px;
      }

      .contentBody {
        padding-top: 50px;

        .btn {
          background-color: $orange-btn;
          color: $white;
          @include wt-ht(100px, 30px);
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
}

@mixin previewTags {
  .previewTagsCont {
    flex-wrap: wrap;
    padding: 0px 37px 0px;
    margin-left: -3px;
    @include flex-center();
    max-height: 60px;
    overflow: auto;
    margin: 20px 0px;
    z-index: 0;
    min-height: 50px;

    .selectedTag {
      padding: 5px 13px;
      border-radius: 14.5px;
      border: solid 1px #c7ceff;
      font-size: 14px;
      color: #3953fb;
      margin: 3px;
      @include flex-center();

      i {
        cursor: pointer;
        font-size: 16px;
        color: $c-66;
        padding-left: 3px;
        padding: 3px;
        font-size: 12px;

        &:hover {
          border-radius: 35px;
          background: $c-eb;
        }
      }

      .selecteTagValue {
        @include ellipsis();
        max-width: 100px;
        margin-right: 3px;
      }
    }
  }
}

%sharpPointer {
  position: absolute;
  border-top-right-radius: 5px;
  padding: 8px;
  border-right: 1px solid $c-f5;
  border-top: 1px solid $c-f5;
  z-index: 4;
  top: -8px;
  right: 10px;
  transform: rotate(-45deg);
  background: white;
}

@mixin stagePlusMinusBtnStyle {
  .noReplyBtn {
    outline: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    height: 10px;
    width: 10px;
    position: relative;

    &:before,
    &:after {
      display: inline-block;
      position: absolute;
      content: "";
      width: 10px;
      height: 2px;
      background-color: #666666;
      transform: translate(-50%, -50%);
    }

    &.plus:after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}

%userListDropdown {
  .dropdownSection {
    position: absolute;
    z-index: 1000;
    right: 0px;
    height: auto;
    top: 36px;
    min-width: 200px;
    max-width: 200px;
    font-family: inherit;
    box-sizing: content-box;
    background: $white;
    border-radius: 10px;
    box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
    border: solid 1px #ebebeb;
    padding: 12px;
    transition: opacity 0.4s ease-in-out;

    .singleSelectSection {
      position: relative;
      padding: 0 5px;

      .radioBtnCont {
        @include flex-direction(row);
        margin-bottom: 5px;
        font-size: 14px;

        label {
          &.active {
            color: $blue-violet;
          }
        }
      }
    }

    .multiSelectSection {
      transition: all 0.3s ease-in-out;
      position: relative;
      border-top: 1px solid $tb-bor-btm;
      padding-top: 5px;

      .checkBoxDropdown {
        overflow-y: auto;
        max-height: 110px;
      }

      .teamdropDown {
        height: auto;
        padding-bottom: 30px;
      }

      .errorTeamdropDown {
        overflow-y: auto;
        text-align: center;
        height: 60px;
      }
    }

    .overrideColor {
      background-color: transparent;

      &.loading .load-text {
        border-color: $blue-violet;
      }
    }
  }
}

%staticInfoTp {
  @extend %relBlock;
  z-index: 0;

  .wrapIconText {
    position: relative;
    display: grid;
    width: 260px;
    grid-template-columns: 21px auto;
    align-items: flex-start;
    color: $c-22;
    background: #e1f5e1;
    border-radius: 10px;
    padding: 8px;

    i {
      font-size: 16px;
      margin-top: 2px;
    }

    .text {
      @extend %relBlock;
      letter-spacing: 0.3px;
      font-size: 12px;
      line-height: 16px;

      b {
        font-weight: bold;
      }
    }
  }

  .sharpPointer {
    position: absolute;
    padding: 5px;
    z-index: 4;
    top: 21px;
    left: -5px;
    transform: rotate(45deg);
    background: #e1f5e1;
  }
}

%enabledBtn {
  cursor: pointer;

  svg {
    fill: $blue-violet;

    path {
      fill: $blue-violet;
    }
  }

  i {
    opacity: 1;
    color: $blue-violet;
  }
}

@mixin searchInputByKeyword {
  .searchWrapper {
    @include flex-center();
    flex-direction: row;

    .searchInput {
      background: white;
      position: relative;
      @include flex-center();
      width: 286px;
      border-radius: 6px;
      border: 1px solid $c-eb;

      .errMsg {
        position: absolute;
        bottom: -12px;
        color: red;
        font-size: 11px;
        left: 0px;
      }

      .searchImage {
        @include wt-ht(30px, 30px);
        cursor: pointer;
        @include flex-center;
        justify-content: center;

        svg {
          @include wt-ht(20px, 20px);
          fill: $c-66;
        }
      }

      .searchTextBox {
        @include flex-center();
        position: relative;

        input {
          border: 0;
          color: $c-3b;
          width: 254px;
          font-size: 16px;
          padding: 6px 20px 6px 2px;
          border-radius: 35px;
          line-height: 1.5;
        }

        i {
          position: absolute;
          right: 5px;
          color: $c-66;
          font-size: 14px;
          cursor: pointer;
          padding: 2px;
          border-radius: 35px;
          @include flex-center();

          &:hover {
            color: $black;
          }
        }
      }

      &:focus-within {
        .searchImage svg {
          fill: $blue-violet;
        }

        input {
          color: $blue-violet;
          border-radius: 35px;
          transition: all 300ms ease-in-out;
        }
      }
    }
  }
}

.tabStatusItem {
  position: relative;
  @include flex-center();
  justify-content: center;
  margin: 0px 10px;
  letter-spacing: 0.3px;
  cursor: pointer;
  height: 46px;
  font-size: 15px;
  transition: all 0.3s ease;
  color: #535353;
  padding: 0 6px;
  width: 110px;

  &.active {
    font-weight: 600;
    color: $blue-violet;
    border-bottom: 2px solid $blue-violet;
    transition: all 0.3s ease;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

%dateFilter {
  position: relative;
  @include flex-center;
  justify-content: center;

  .tabItem {
    position: relative;
    background: $white;
    @include flex-center;
    border: 1px solid $b-c-1;
    border-collapse: collapse;
    color: $blue-violet;
    border-right: 1px solid transparent;
    padding: 0px 10px;
    letter-spacing: 0.3px;
    cursor: pointer;
    height: 36px;
    font-size: 15px;
    transition: all 0.3s ease;

    &:first-child {
      @include bor-rad-left(5px, 5px);
    }

    &:last-child {
      @include bor-rad-right(5px, 5px);
      border-right: 1px solid $b-c-1;
    }

    &:not(.active):hover {
      background: $c-fb;
      transition: all 0.3s ease;
    }

    &.active {
      transition: all 0.3s ease;
      background: $blue-violet;
      color: white;
      z-index: 1;
      border-radius: 3px;
      border: 1px solid $blue-violet;
    }

    .filterText {
      position: relative;
      line-height: normal;
    }
  }

  .dateRange {
    font-size: 18px;
    margin-right: 4px;
  }
}

%contactOwner {
  position: relative;
  @include flex-center;
  margin: 0 auto;
  justify-content: center;
  @include wt-ht(30px, 30px);
  border-radius: 35px;
  padding-top: 2px;
  border: 1px solid $blue-violet;
  color: $blue-violet;
  font-size: 12px;
  cursor: pointer;

  .circleOwner {
    text-transform: uppercase;
  }

  .customTooltip {
    background: #393a3d;

    .sharpPointer {
      border-right: 1px solid $c-33;
      border-top: 1px solid $c-33;
      background: #393a3d;
      top: -35px;
      right: -2px;
    }

    .tooltipBody {
      width: fit-content;
      right: -10px;
      white-space: nowrap;
      background: #393a3d;
      right: -33px;
      top: -58px;

      .tooltipText {
        color: $white;
        padding: 8px;
      }
    }
  }
}

.pricingPageTrialBannerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pricingPageTrialBanner {
  border: 1px solid $c-proPlan;
  border-radius: 13px;
  text-align: center;
  padding: 10px 16px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.16);

  .trialMessaging {
    color: $c-proPlan;
    font-family: $font-medium;
    font-weight: bold;
    font-size: 20px;
  }

  .upgradeMessaging {
    font-size: 14px;
    margin-top: 12px;
    color: $c-freePlan;
  }
}

.showIntercomButton {
  cursor: pointer;
  background-color: #474343;
  color: #fff;
  border: none;
  transition: background-color 0.15s linear;
  border-radius: 100px;
  position: fixed;
  bottom: 0;
  right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 12px;
  z-index: 102;
}

%deleteIcon {
  color: $c-66;
  background: white;
  border-radius: 35px;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 18px;
  padding: 6px;
  width: fit-content;

  &:hover {
    background: $c-f5;
    color: $c-33;
  }
}

.knowMoreBtnWrapper {
  @include flex-center();
  justify-content: center;
  width: 100px;
  margin: 0 10px;
  cursor: pointer;
  line-height: 36px;

  .knowMoreText {
    position: relative;
    display: flex;
    font-size: 12px;
    color: $blue-violet;

    &:hover {
      text-decoration: underline;
      color: $blue-violet;
    }
  }

  .launchIcon {
    color: $blue-violet;
    font-size: 14px;
    margin-left: 4px;
  }
}

.invalidVariablesText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ol {
    margin-bottom: 0;
    overflow-y: auto;
    max-height: 60px;
    padding-right: 26px;
    font-weight: 500;

    li {
      text-align: left;
    }
  }

  .LearnMoreBtnWrapper {
    display: inline-flex;
    align-items: center;
    color: $blue-violet;
    font-size: 14px;

    .launchIcon {
      color: $blue-violet;
      font-size: 14px;
      margin-left: 4px;
    }

    a {
      color: $blue-violet;
      text-decoration: none;
    }
  }
}

%toggleSection {
  .toggleBtnSection {
    .toogleSequence .toogleButton {
      &.loading {
        .knobs::before {
          animation: spin 1s linear infinite;
          border: 1.5px solid #3953fb;
          border-bottom-color: #3953fb;
          border-left-color: #3953fb;
          border-left: 1px;
          background-color: transparent;
        }

        .toggleCheckbox:checked + .knobs:before {
          background-color: transparent;
        }
      }

      @include wt-ht(28px, 14px);

      .knobs::before {
        @include wt-ht(10px, 10px);
        top: 2px;
        padding: unset;
      }

      .toggleCheckbox:checked {
        & ~ .layer {
          background: #fff;
          border: 1px solid #3953fb;
        }

        & + .knobs:before {
          left: 15px;
          background: #3953fb;
        }
      }

      .layer {
        background: #fff;
        border: 1px solid $c-d3;
      }

      .knobs:before {
        background: $c-a7;
      }
    }

    &.linear-background {
      @include wt-ht(34px, 16px);
    }
  }
}

.comingSoonBadge {
  display: none;
  opacity: 0.6;
  border-radius: 10px;
  background-color: black;
  font-size: 12px;
  padding: 3px 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
  will-change: tranform;
  font-weight: 600;
}

%linear-background {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, $c-ee),
    color-stop(18%, $c-dd),
    color-stop(33%, $c-ee)
  );
  background: linear-gradient(90deg, $c-ee 8%, $c-dd 18%, $c-ee 33%);
  background-size: 1000px 104px;
  height: 12px;
  border: none !important;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}

.flexCenter {
  @include flex-center();
}

%emptyInbox {
  @extend %empty-section;
  @include flex-center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 20px);

  .connectInboxButton {
    position: relative;

    img,
    #smtpAuthBtn {
      position: relative;
      cursor: pointer;
      display: block;
      width: 200px;

      &.googleAuthBtn {
        height: unset;
      }

      &.outlookAuthBtn {
        height: 40px;
        width: 194px;
        margin: 15px auto;
      }

      &.smtpAuthBtn {
        @include flex-center;
        height: 36px;
        width: 194px;
        border: 1px solid $c-66;
        padding: 0 10px;
        margin: 0 auto;
        vertical-align: middle;

        .smtpImapIcon {
          font-size: 18px;
          color: $c-66;
          margin-right: 8px;
        }

        .btnCta {
          position: relative;
          color: $c-66;
          font-weight: 600;
          font-size: 13px;
          line-height: 1px;
        }
      }
    }
  }
}

%searchInput {
  position: relative;
  @include flex-center();

  .errMsg {
    position: absolute;
    bottom: -12px;
    color: red;
    font-size: 11px;
    left: 35px;
  }

  .searchIcon {
    @extend %relBlock;
    text-align: right;
    @include wt-ht(24px, 18px);
    cursor: pointer;

    svg {
      @include wt-ht(18px, 18px);
      fill: $c-94;
    }
  }

  .searchTextBox {
    @include flex-center();
    position: relative;

    input {
      border: 0;
      color: $c-3b;
      width: 260px;
      font-size: 16px;
      padding: 5px 28px 5px 10px;
      border-radius: 35px;
      background: $white;
      line-height: 1.5;
    }

    i {
      position: absolute;
      right: 5px;
      color: $c-55;
      font-size: 14px;
      cursor: pointer;
      padding: 2px;
      border-radius: 35px;
      @include flex-center();

      &:hover {
        color: $black;
        background: $c-f5;
      }
    }
  }

  &:focus-within {
    .searchIcon svg {
      fill: $blue-violet;
    }

    input {
      color: $c-3b;
    }
  }
}

%sendingWindowWrapper {
  color: $c-66;
  font-size: 14px;

  .sendingWindowContainer {
    margin-top: 8px;
  }

  .labelText {
    margin-bottom: 30px;
  }

  .tickIcon,
  .closeIcon {
    font-size: 18px;
    padding: 4px;
    cursor: pointer;

    &:hover {
      background: #ebebeb;
      @include flex-center();
      justify-content: center;
      border-radius: 35px;
    }
  }

  .tickIcon {
    color: $blue-violet;
  }

  .verticalSeperator {
    position: relative;
    @include flex-center();
    height: 16px;
    margin: 0 4px;

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: $c-eb;
    }
  }

  .closeIcon {
    margin-left: 4px;
  }

  .daysWithTimeWrapper {
    display: grid;
    grid-template-columns: 20% 80%;
    align-items: flex-start;
    margin-bottom: 8px;

    .timeRangeWrap {
      display: flex;
    }

    .timeRangeContainer {
      display: flex;
      flex-direction: column;
    }

    .flexCenterTop {
      display: flex;
      align-items: flex-start;
      position: relative;
      width: fit-content;
    }

    .flexCenter {
      @include flex-center();
      position: relative;
      width: fit-content;
    }

    .flexCenterPanel {
      @include flex-center();
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .timeRangeBlockCont {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .timeRangeBlockWrap {
      @include flex-center();

      .closeIcon {
        color: #d9d6d6;
        margin-left: 12px;
      }
    }

    .timeRangeBlock {
      border: 1px solid $c-ea;
      height: 34px;
      color: $blue-violet;
      border-radius: 6px;
      font-size: 14px;
      @include flex-center();
      justify-content: center;
      line-height: 1;
      min-width: 160px;
      cursor: pointer;

      .closeIcon {
        font-size: 12px;
        color: $c-66;

        &:hover {
          background: #ebebeb;
          border-radius: 35px;
          @include flex-center();
          justify-content: center;
        }
      }
    }

    .applyAll {
      position: relative;

      &.clearAll {
        .swapIcon {
          color: $c-66;
          font-size: 18px;
        }
      }

      .swapIcon {
        cursor: pointer;
        margin-left: 8px;
        padding: 4px;
        color: $blue-violet;
        font-size: 16px;

        &:hover {
          background: #e8e8e8;
          border-radius: 35px;
          @include flex-center();
          justify-content: center;
        }
      }

      .customTooltip {
        .sharpPointer {
          top: -48px;
          right: 7px;
          border: none;
          background: #393a3d;
        }

        .tooltipBody {
          background: #393a3d;
          border-radius: 5px;
          top: -70px;
          left: -38px;
          width: 120px;
          text-align: center;
        }

        .tooltipText {
          color: $white;
          padding: 8px;
        }
      }
    }

    .addCustomTimeIcon {
      color: #d9d6d6;
      margin-left: 16px;
      font-size: 22px;
      cursor: pointer;
      position: relative;
      @include flex-center();
      justify-content: center;
      @include wt-ht(24px, 24px);

      &:hover {
        background: #ebebeb;
        border-radius: 35px;
      }
    }
  }

  .WeekDays {
    font-family: $font-regular;
    flex: 0 0 10%;
    color: #3b3b3b;
    font-size: 14px;
    position: relative;
    top: 5px;
  }

  .timeBlock {
    color: $blue-violet;
    font-size: 14px;
    @include flex-center();
    border: solid 1px $c-ea;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    height: 34px;
    width: fit-content;

    .timeIcon {
      font-size: 18px;
      margin-right: 8px;
    }

    .addTimeText {
      font-size: 13px;
    }
  }

  .timeRangeWrapper {
    @include flex-center();
    font-size: 14px;
    position: relative;
    height: 34px;
    width: 100%;

    .timeContWrapper {
      position: relative;
    }

    .timeRangeCont {
      color: $blue-violet;
      @include flex-center();
      position: absolute;
      background-color: $white;
    }

    .actionButtons {
      @include flex-center();
      color: $c-66;
      border: 1px solid $c-ea;
      border-radius: 6px;
      height: 32px;
    }

    .textSeperator {
      margin: 0 16px;
      color: $c-66;
    }

    .timeRange {
      border: 0;
      @include flex-center();
      color: $c-66;
      width: 29px;
      padding: 0;
      text-align: center;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .fromPanel,
    .toPanel {
      @include flex-center();
      position: relative;

      .dropdownFilterIcon {
        font-size: 16px;
        cursor: pointer;
        color: $c-66;
      }

      .drop-down-menu {
        .dropdownList {
          width: 75px;
          min-width: 75px;
        }
      }
    }

    .fromPanel {
      .drop-down-menu {
        top: 25px;
        border-radius: 0;
      }
    }

    .toPanel {
      margin-right: 8px;

      .drop-down-menu {
        left: 0px;
        top: 25px;
        border-radius: 0;
      }
    }
  }

  .timeWrapper {
    @include flex-center();
    border: 1px solid $c-ea;
    border-radius: 3px;
    height: 32px;

    .timeIconCont {
      border-right: 1px solid $c-ea;
      @include flex-center();

      .timeIcon {
        font-size: 18px;
        padding: 0 6px;
      }
    }

    .timeCont {
      @include flex-center();
      position: relative;
      border-collapse: collapse;
    }

    .quantity {
      @include flex-center();
      flex-direction: column;

      .lessButton,
      .moreButton {
        border: 0;
        background: transparent;
        margin: 0;
        padding: 0;
        @include wt-ht(20px, 100%);
        @include flex-center();
        justify-content: center;
        cursor: pointer;

        &:hover {
          background-color: $c-f5;
        }
      }

      .lessButton {
        border-left: 1px solid $c-eb;
        border-bottom: 1px solid $c-eb;
      }

      .moreButton {
        border-left: 1px solid $c-eb;
      }
    }

    .expandLessIcon,
    .expandMoreIcon {
      font-size: 12px;
      color: $c-66;
    }

    .quantity input {
      border-left: none;
      border-right: none;
    }
  }

  .amPmPanel {
    @include flex-center;
    position: relative;

    .toggleHeader {
      @include flex-center();
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      border: 1px solid $c-ea;
      padding: 4px;
      border-radius: 3px;
      margin-left: 4px;
      height: 32px;
      color: $c-66;

      .title {
        text-align: center;
      }
    }
  }
}

.crmExportNotConnectedWrapper,
.crmExportNotMapped {
  .noteText {
    background-color: #ebedff;
    border-radius: 4px;
    font-size: 14px;
    padding: 8px;
    margin-top: 4px;
    text-align: left;
  }
}

.bufferCont {
  display: grid;
  margin: 10px 0;
  grid-template-columns: calc(60% - 15px) 40%;
  column-gap: 15px;

  @media (max-width: 1200px) {
    grid-template-columns: 100%;
    column-gap: unset;
    row-gap: 40px;
  }

  .addTimeBeforeOrAfterWrapper,
  .startBeforeWrapper {
    margin-top: 30px;

    .addTimeBeforeOrAfterCont {
      display: grid;
      grid-template-columns: repeat(2, 30%);
    }

    .autoCompleteDropdown {
      .dropdownHeader {
        height: 30px;
      }
    }

    .checkBoxSection {
      .labelText {
        margin-bottom: 0px;
      }

      .checkBoxName {
        padding-left: 10px;
      }

      .singleSelectDropdown.disabled {
        pointer-events: none;
      }
    }
  }
}

// Filter Option Card
%filterOprionCard {
  @extend %relBlock;
  height: 100%;
  background: white;
  border: 1px solid $c-ec;
  border-radius: 6px;

  .filterHeader {
    position: relative;
    @include flex-center;
    justify-content: space-between;
    height: 50px;
    @include bor-rad-top(6px, 6px);
    padding: 0 10px;
    border-bottom: 1px solid $c-ec;

    .name {
      @extend %relBlock;
      color: $c-53;
      font-size: 16px;
    }

    .clearBtn {
      @extend %relBlock;
      font-size: 12px;
      cursor: pointer;
      color: $blue-violet;

      &.disabled {
        @include disable(0.8, unset);
        color: rgba(57, 83, 251, 0.5);
      }
    }
  }

  .filterBody {
    @extend %relBlock;
    padding: 5px 10px;
    height: calc(100% - 100px);
    overflow-y: auto;

    .dropdownButton {
      position: relative;
      @include flex-center;
      justify-content: space-between;
      height: 44px;
      cursor: pointer;

      .label {
        @extend %relBlock;
        font-size: 14px;
        text-align: left;
        color: $c-66;
      }

      .wrapCountArrow {
        position: relative;
        @include flex-center;

        .selectedCount {
          margin-right: 10px;
          @extend %relBlock;
          border-radius: 4px;
          padding: 3px 7px 1px;
          border: solid 1px rgba(57, 83, 251, 0.3);
          background-color: rgba(57, 83, 251, 0.1);
          font-size: 12px;
          text-align: center;
          color: $blue-violet;
        }

        .arrowIcon {
          @extend %relBlock;
          color: $c-66;
          font-size: 18px;
          transition: all 0.3s ease-in;

          &.rotate {
            transform: rotate(180deg);
          }
        }
      }
    }

    .dropdownOverlay {
      @include bg-mask(1, transparent);
    }
  }

  .filterAction {
    position: relative;
    border-top: 1px solid $c-ec;
    display: grid;
    padding: 0 10px;
    align-items: center;
    height: 50px;
    grid-template-columns: repeat(2, calc(50% - 5px));
    justify-content: space-between;

    &.disabled {
      @include disable(0.6, unset);
    }

    .cancelFilter,
    .applyFilter {
      position: relative;
      @include flex-center;
      justify-content: center;
      height: 30px;
      font-size: 14px;
      padding: 0 10px;
      border-radius: 5px;
      transition: all 0.3s ease;
      width: 130px;
      font-weight: 500;
      letter-spacing: 0.3px;
      cursor: pointer;
    }

    .cancelFilter {
      background: white;
      color: $blue-violet;
    }

    .applyFilter {
      background: $blue-violet;
      color: white;
    }
  }
}

%filterIconWrapper {
  background-color: $white;
  @include wt-ht(36px, 36px);
  padding: 4px;
  margin-left: 10px;
  @include flex-center();
  justify-content: center;
  border: 1px solid #eceff1;
  border-radius: 6px;
  cursor: pointer;

  &.showFilter {
    background-color: $blue-violet;

    svg {
      fill: $white;
    }
  }

  &.disabledFilter {
    @include disable(0.7, unset);
  }

  svg {
    @include wt-ht(16px, 16px);
    fill: $c-66;
  }
}

// Fa Spinner
%spinnerIcon {
  padding: 15px 0 25px;
  display: block;
  position: relative;
  width: 100%;
  text-align: center;
  margin: 0 auto;

  i {
    font-size: 30px;
    color: $blue-violet;
  }

  .text {
    position: relative;
    display: inline-block;
    padding-left: 6px;
    font-size: 24px;
    color: $c-78;
  }
}

// Selected Chip Items
%selectedItemsCont {
  .selectedItemsCont {
    position: relative;
    display: grid;
    grid-template-columns: calc(100% - 30px) 20px;

    .selectedList {
      position: relative;
      @include flex-center;
      @include list-style-zero;
      flex-wrap: wrap;
      padding-top: 10px;
      gap: 6px;
      max-height: 100px;
      overflow-y: auto;

      .chip {
        position: relative;
        @include flex-center;
        justify-content: space-between;
        border-radius: 35px;
        border: solid 1px $blue-violet;
        background-color: #fff;
        padding: 2px 3px 2px 5px;

        .name {
          position: relative;
          font-size: 12px;
          color: $c-66;
          flex-flow: wrap;
          max-width: 64px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
          align-items: center;
          top: 1px;
        }

        .removeIcon {
          cursor: pointer;
          font-size: 12px;
          color: $c-66;
          border-radius: 35px;
          padding: 2px;

          &:hover {
            color: $c-66;
            background: $c-ec;
          }
        }
      }
    }

    .expandCollapse {
      position: relative;
      @include flex-center;
      padding: 2px 6px;
      border-radius: 35px;
      background: $blue-violet;
      color: $white;
      width: fit-content;
      transition: all 0.3 ease;
      cursor: pointer;

      .count {
        font-size: 11px;
        padding-top: 2px;
        font-weight: 600;
      }

      .arrowIcon {
        font-size: 14px;
        height: 12px;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }

    .clearAll {
      position: relative;
      font-size: 18px;
      color: $c-8d;
      cursor: pointer;
      padding: 1px;
      border-radius: 35px;
      margin-top: 12px;
      height: fit-content;
      background: $white;

      &:hover {
        background: $c-f5;
        color: $c-66;
      }
    }
  }
}

%companyJobTile {
  .job {
    margin-bottom: 5px;
    @include ellipsis();

    &.linear-background {
      @include wt-ht(50%, 14px);
    }
  }

  .company {
    @include ellipsis();

    &.linear-background {
      @include wt-ht(90%, 14px);
    }
  }
}

%contactTag {
  .tagNameTable {
    @include ellipsis();
    margin-bottom: 5px;

    &.linear-background {
      display: block;
      @include wt-ht(90%, 14px);
    }
  }

  .moreTags {
    color: $blue-violet;
    font-size: 12px;

    &.linear-background {
      display: block;
      @include wt-ht(30%, 11px);
    }
  }
}

%upgradePopupCard {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  @include flex-center();
  justify-content: center;
  letter-spacing: 0.3px;
  width: 100%;
  z-index: 100;
  overflow: auto;

  .card {
    position: relative;
    display: block;
    z-index: 101;
    border-radius: 10px;
    padding: 30px;
    background: white;
    width: 360px;

    .cardTitle {
      position: relative;
      display: flex;
      justify-content: space-between;

      h4 {
        margin-bottom: 20px;
        font-size: 22px;
      }

      i {
        position: absolute;
        top: -15px;
        right: -20px;
        font-size: 19px;
        padding: 3px;
        border-radius: 50%;
        color: $c-53;
        cursor: pointer;

        &:hover {
          background: $c-dd;
          color: $black;
        }
      }
    }

    .cardBody {
      @extend %relBlockWidth;
    }
  }
}

%dataStatusPopup {
  position: fixed;
  top: 10%;
  left: 0;
  @include flex-center();
  justify-content: center;
  letter-spacing: 0.3px;
  width: 100%;
  z-index: 100;
  overflow: auto;

  .card {
    position: relative;
    display: block;
    z-index: 101;
    border-radius: 10px;
    padding: 30px;
    background: white;
    width: 360px;

    .cardTitle {
      position: relative;
      display: flex;
      justify-content: space-between;

      h4 {
        margin-bottom: 20px;
        font-size: 22px;
      }

      i {
        position: absolute;
        top: -15px;
        right: -20px;
        font-size: 19px;
        padding: 3px;
        border-radius: 50%;
        color: $c-53;
        cursor: pointer;

        &:hover {
          background: $c-dd;
          color: $black;
        }
      }
    }

    .cardBody {
      @extend %relBlockWidth;
    }
  }
}

.autoReplyBadge {
  border: 1px solid $c-dd;
  border-radius: 12px;
  background-color: #efeded;
  padding: 4px 8px;
  font-size: 12px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
