.sequenceContactFilter {
  @extend %filterOprionCard;
  display: grid;
  grid-template-rows: 50px auto 50px;
  overflow: hidden;
  .filterBody,
  .filterHeader,
  .filterAction {
    height: 100% !important;
  }
  .contactDateFilter,
  .conditionField {
    position: relative;
    border-bottom: 1px solid $c-ec;
    .dropdownSearchBox {
      @extend %relBlock;
      width: calc(100% - 8px);
      margin: 0 0 10px 8px;
      .horizontalBorderLine {
        position: absolute;
        width: 4px;
        height: 100%;
        background: #dee3ff;
        border-radius: 5px;
        left: -8px;
        top: 0px;
        z-index: 1;
      }
    }
  }
}

.conditionalFieldCard,
.dateRangeConditionCard {
  .conditionalField,
  .sequenceFilter,
  .dateRangeCondition {
    padding-bottom: 8px;
    &:not(:last-child) {
      border-bottom: 1px dashed rgba(176, 185, 245, 0.5);
      margin-bottom: 8px;
    }
  }
}

.filterWrapper {
  margin-left: 10px;
  .filterContactBtn {
    position: relative;
    @include flex-center();
    border: 1px solid $c-eb;
    padding: 10px;
    border-radius: 5px;
    color: $c-59;
    background: white;
    cursor: pointer;
    @include wt-ht(36px, 36px);
    svg {
      @include wt-ht(16px, 16px);
      fill: $blue-violet;
    }
    .customTooltip {
      margin-top: 35px;
      background-color: #393a3d;
      .sharpPointer {
        border-right: 1px solid #393a3d;
        border-top: 1px solid #393a3d;
        background-color: #393a3d;
        right: -2px;
        top: -56px;
        border-top-right-radius: 35px;
      }
      .tooltipBody {
        width: fit-content;
        border-radius: 5px;
        right: -10px;
        white-space: nowrap;
        background-color: #393a3d;
        right: -39px;
        top: -81px;
        .tooltipText {
          color: $white;
          padding: 8px;
        }
      }
    }
  }
}

.dropdownSearchBox {
  position: relative;
  .actionButton {
    position: relative;
    @include flex-center;
    flex-wrap: wrap;
    justify-content: flex-end;
    .resetButton {
      position: relative;
      @include flex-center;
      height: 20px;
      padding: 0 6px;
      border-radius: 35px;
      transition: all 0.3s ease;
      width: fit-content;
      font-weight: 500;
      letter-spacing: 0.3px;
      cursor: pointer;
      &:hover {
        background: white;
        color: $c-22;
      }
      .refreshIcon {
        font-size: 12px;
        color: $c-66;
        margin-right: 4px;
      }
      .text {
        padding-top: 2px;
        font-size: 12px;
        color: $c-66;
      }
    }
    .addNewButton {
      position: relative;
      @include flex-center;
      height: 20px;
      padding: 0 10px;
      border-radius: 35px;
      transition: all 0.3s ease;
      width: fit-content;
      font-weight: 500;
      letter-spacing: 0.3px;
      cursor: pointer;
      &.disabled {
        &:hover {
          background: transparent;
        }
      }
      &:hover {
        background: #e4eaff;
      }
      .addIcon {
        font-size: 12px;
        color: $blue-violet;
        margin-right: 4px;
      }
      .text {
        font-size: 12px;
        padding-top: 2px;
        color: $blue-violet;
      }
    }
  }
}
.fieldnameDropdown {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: calc(100% - 30px) 24px;
  justify-content: space-between;
  .deleteIcon {
    border-radius: 35px;
    padding: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: $c-22;
      background: white;
    }
  }
}
.singleDropdown {
  position: relative;
  .selectLabel {
    position: relative;
    cursor: pointer;
    display: grid;
    align-items: center;
    grid-template-columns: calc(100% - 24px) 20px;
    justify-content: space-between;
    height: 28px;
    border-bottom: 1px solid $c-ec;
    .title {
      position: relative;
      width: 100%;
      @include ellipsis();
      font-size: 14px;
      letter-spacing: 0.3px;
      color: $c-66;
      .conditionSymbol {
        position: relative;
        font-size: 24px;
        color: $blue-violet;
        line-height: 1.15;
        &.customSymbol {
          font-size: 18px;
        }
      }
    }
    .arrowIcon {
      font-size: 20px;
      color: $c-66;
    }
  }
  .drop-down-menu {
    width: 100%;
    ul {
      max-height: 206px;
    }
  }
  &.positionTop .drop-down-menu {
    bottom: 30px;
  }
}
.fieldNameSequenceDropdown {
  .selectSequenceLabel {
    position: relative;
    cursor: pointer;
    display: grid;
    align-items: center;
    grid-template-columns: calc(100% - 24px) 20px;
    justify-content: space-between;
    height: 28px;
    border: none;
    background: transparent;
    border-bottom: 1px solid $c-ec;
    .title {
      position: relative;
      width: 100%;
      @include ellipsis();
      font-size: 14px;
      letter-spacing: 0.3px;
      color: $c-66;
      .conditionSymbol {
        position: relative;
        font-size: 24px;
        color: $blue-violet;
        line-height: 1.15;
        &.customSymbol {
          font-size: 18px;
        }
      }
    }
    .arrowIcon {
      font-size: 20px;
      color: $c-66;
    }
  }
  .drop-down-menu {
    right: 0 !important;
    left: unset !important;
    ul .sequenceSelected {
      background: $c-f5;
      opacity: 0.8;
      cursor: unset;
      pointer-events: none;
      user-select: none;
      .itemName {
        color: $blue-violet;
      }
    }
  }
  .listItemWrap {
    width: 224px;
    .itemName {
      @extend %sharedBadge;
      display: flex !important;
      align-items: center;
      .nameText {
        @include ellipsis();
      }
      .trigger {
        width: fit-content;
        .ownerCont {
          width: fit-content;
        }
      }
    }
  }
}
.fieldOperatorValue {
  position: relative;
  display: grid;
  grid-template-columns: 30px calc(100% - 40px);
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  &.disableInput {
    @include disable(0.5, unset);
  }
  .selectLabel {
    position: relative;
    border: 1px solid rgba(57, 83, 251, 0.6);
    grid-template-columns: 100%;
    justify-content: center;
    border-radius: 4px;
    background: white;
    height: 28px;
    .title {
      position: relative;
      text-align: center;
    }
    .arrowIcon {
      display: none;
    }
  }
  .drop-down-menu {
    right: unset;
    left: 0;
  }
  .inputWrapper input {
    @include wt-ht(100%, 30px);
    background: transparent;
    border: none;
    border-bottom: 1px solid $c-ec;
    color: $c-66;
  }
}
.sequenceFilter {
  position: relative;
}
.selectDateRangePicker {
  position: relative;
  .selectDateLabel {
    position: relative;
    cursor: pointer;
    display: grid;
    align-items: center;
    grid-template-columns: calc(100% - 24px) 20px;
    justify-content: space-between;
    height: 28px;
    border-bottom: 1px solid $c-ec;
    .title {
      font-size: 13px;
      letter-spacing: 0.3px;
      color: $c-66;
    }
    .arrowIcon {
      font-size: 20px;
      color: $c-66;
    }
  }
  .datePickerDropdown {
    position: fixed;
    z-index: 1001;
    right: 1%;
    .rdrCalendarWrapper {
      margin-right: 10px;
      z-index: 2;
      box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.17);
      .rdrMonthAndYearWrapper {
        padding-top: 0;
        height: 32px;
      }
      .rdrMonth {
        padding-bottom: 5px;
        font-size: 11px;
      }
      .rdrWeekDay {
        line-height: 1.8;
      }
      .rdrDateDisplayItem input {
        height: 1.8em;
        line-height: 1.8em;
      }
      .dateBtnSection {
        position: relative;
        padding: 0 10px 5px;
        text-align: right;
        @include flex-center;
        justify-content: flex-end;
        input[type^="button"] {
          height: 20px;
          border-radius: 35px;
          outline: none;
          letter-spacing: 0.3px;
          font-size: 12px;
          width: 60px;
          border: none;
          font-weight: 400;
          cursor: pointer;
          &:not(:first-child) {
            margin-left: 10px;
          }
          &.cancelCustomDateFilter {
            background: white;
            color: $c-66;
            transition: all 0.3s ease;
            &:hover {
              transition: all 0.3s ease;
              background: $c-f5;
              color: black;
            }
          }
          &.applyCustomDateFilter {
            color: white;
            background: #3d91ff;
            transition: all 0.3s ease;
            &:not(:disabled):hover {
              transition: all 0.3s ease;
              box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.12);
            }
            &:disabled {
              background: $c-78;
              cursor: default;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
  &.disabled {
    @include disable(0.6, unset);
  }
}
.errMessage {
  position: absolute;
  font-size: 11px;
  text-align: right;
  color: red;
  bottom: 2px;
}
