.customListWrapper {
  margin-left: 12px;
  .customListContainer {
    @include flex-center();
    .editIcon,
    .deleteIcon {
      font-size: 16px;
      cursor: pointer;
      margin: 0 4px;
    }
  }
  .customListIcon {
    font-size: 18px;
    margin-right: 10px;
    color: $blue-violet;
  }
  .title {
    font-size: 14px;
    color: $c-66 !important;
  }
  .dropdownHeader {
    border: 1px solid $c-eb;
    border-radius: 5px;
    padding: 0 6px;
  }
  .arrow i {
    color: $c-66 !important;
  }
  .customListLeftSection,
  .customListRightSection {
    display: flex;
    flex-direction: column;
  }
  .customListLeftSection {
    align-items: flex-start;
    .nonUpdatedName {
      color: #3b3b3b;
    }
  }
  .customListRightSection {
    align-items: flex-end;
  }
  .customListHorizontalDisplay {
    justify-content: space-between;
    width: 15%;
    display: flex;
    position: relative;
    bottom: 12px;
  }
  .dropdownList .dropdownOptions {
    padding: 8px 10px !important;
    border-bottom: 1px solid $c-eb;
    height: auto;
    justify-content: space-between;
  }
  .updatedName,
  .totalContactInList {
    color: $blue-violet;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .createdDate,
  .contactsField {
    color: #999696;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .createNewListBtn {
    background-color: $blue-violet;
    color: $white;
    font-size: 14px;
    padding: 8px 10px;
    height: 36px;
    @include flex-center();
    cursor: pointer;
    i {
      font-size: 18px;
      margin-right: 10px;
    }
  }
}

.createEditListPopupWrapper {
  @extend %popupCard;
  .popupHeader {
    padding-bottom: 29px;
    @include flex-align-path-justify(center, row, space-between);

    .title {
      text-align: left;
      color: $c-66;
      font-size: 20px;
    }

    .commonPopupCloseIcon {
      font-size: 20px;
      padding: 3px;
      border-radius: 50%;
      color: $c-53;
      cursor: pointer;

      &:hover {
        color: #000;
        background-color: $c-dd;
      }
    }
  }
  .card {
    width: 400px;
    max-height: 700px;
    overflow: auto;
    text-align: center;
    padding: 20px;
    .inputWrapper {
      @extend %inputWrapper;
      &.phoneNumber {
        margin-bottom: 20px;
      }
    }
    .createListButton {
      @extend %primaryBlueBtn;
      width: 50%;
      border-radius: 10px;
    }
  }
}
.addtoListPopupWrapper {
  @extend %popupCard;
  .card {
    width: 400px;
    overflow: auto;
    text-align: center;
    padding: 30px 16px;
  }
  .addContactToListTitle {
    font-size: 14px;
    width: 100%;
    text-align: center;
    margin-bottom: 12px;
    color: $c-66;
  }
}
