@mixin editAccountSvg {
  svg {
    margin: 0;
    fill: #0a66c2 !important;
    path {
      fill: #0a66c2 !important;
    }
    @include wt-ht(13px !important, 13px !important);
  }
}

.accountInfo {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 10px;
  height: calc(100vh - 132px);
  overflow: auto;
  .accountInfoHeader {
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;
    @include flex-center;
    justify-content: space-between;
    padding: 20px 12px;
    border-bottom: 1px solid $c-ee;
    .domainName {
      color: $c-1a;
      font-size: 16px;
      @include ellipsis;
    }
    .accountSocialLinks {
      @include flex-center();
      gap: 4px;
    }
  }
  .accountInfoBodyLoader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: 58px;
    &:not(:last-child) {
      border-bottom: 1px solid $c-dd;
    }
    &:last-child {
      border: none;
    }
    .accountInsightsLoader {
      &.linear-background {
        width: 30%;
      }
    }
  }
  .accountInfoHeaderLoader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: 58px;
    &:not(:last-child) {
      border-bottom: 1px solid $c-dd;
    }
    &:last-child {
      border: none;
    }
  }

  .accountInsights {
    background: $white;
    border-radius: 10px;
    .editWrapper {
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      cursor: pointer;
      padding: 16px;
      text-align: center;
      opacity: 0.4;
      border-top: 1px solid $c-dd;
      transition: all 0.3s ease-in-out;
      &:hover {
        opacity: 0.8;
        color: #3953fb;
      }
    }
    .accountInfoLogo {
      width: 120px;
      height: 20px;
      object-fit: contain;
      img {
        width: 120px;
        height: 20px;
        object-fit: contain;
      }
      &.linear-background {
        width: 20%;
      }
    }
    .accountInfoListContainer {
      display: grid;
      width: 100%;
      grid-template-columns: 120px 1fr;
      align-items: center;
      padding: 18px 12px;
      border-bottom: 1px solid $c-ee;
    }
    .accountListTitle {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.25;
      letter-spacing: 0px;
      color: #8d8d8d;
      text-align: left;
      white-space: nowrap;
      &.linear-background {
        width: 50%;
      }
    }
    .emailInsightLoader {
      @include flex-center;
      justify-content: space-between;
      gap: 20px;
      padding: 18px 12px;
      & .emailInsightTitleLoader {
        &.linear-background {
          width: 30%;
        }
      }
      & .emailInsightCountLoader {
        &.linear-background {
          width: 30%;
        }
      }
    }
    .accountListItem {
      width: 150px;
      font-size: 14px;
      line-height: 1.25;
      font-weight: normal;
      letter-spacing: 0px;
      color: $c-3b;
      word-break: break-word;
      &.linkedinUrl {
        @include linkHover;
      }
      &.linear-background {
        width: 50%;
      }
    }
    .accountEmailInsightsList {
      gap: 14px;
      padding: 18px 12px;
      display: grid;
      grid-template-columns: 100px 1fr;
      align-items: center;

      .email-insight-stage {
        li :not(:last-child) {
          margin-right: 26px;
        }
        li:last-child {
          .customTooltip .tooltipBody {
            right: -5px;
          }
        }
      }

      .accountEmailInsigtsTitle {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        color: #8d8d8d;
        text-align: left;
        white-space: nowrap;
      }
    }
    .emailInsightLoader {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 18px 12px;
      border-bottom: 1px solid $c-dd;
    }
    .accountDropdown {
      @include flex-center;
      @include updateAccountStageDropdown();
    }
    .accountInsightsLoader {
      padding: 10px;
      border-radius: 6px;
      &.linear-background {
        width: 30%;
      }
    }
    .accountEditLoader_ {
      @include flex-align-center();
      padding: 20px;
      .accountEditLoader {
        width: 100%;
        padding: 10px;
        border-radius: 6px;
        margin: auto;
        &.linear-background {
          width: 50%;
        }
      }
    }
  }

  .accountEmailInsights {
    position: relative;
    background-color: $white;
    border-radius: 10px;
    margin-top: 15px;
    height: fit-content;
    border-radius: 10px;
    .accountEmailInsightsHeader {
      @include flex-center;
      justify-content: space-between;
      padding: 14px 10px;
      &:not(:last-child) {
        border-bottom: 1px solid $c-ee;
      }
      &:last-child {
        border: none;
      }
    }

    .accountEmailInsightsTitle {
      position: relative;
      font-size: 14px;
      font-weight: normal;
      line-height: initial;
      letter-spacing: 0px;
      text-align: left;
      color: #343a40;
      &.linear-background {
        width: 30%;
      }
    }
    .accountEmailInsightsBadge {
      padding: 6px 12px;
      background: #3953fb0f;
      line-height: normal;
      border-radius: 6px;
      color: #3953fb;
      font-size: 14px;
      font-weight: normal;
      text-align: right;
    }
    .accountEmailInsightsLoader {
      @include flex-center;
      justify-content: space-between;
      padding: 18px 12px;
      border-bottom: 1px solid $c-ee;
      &:last-child {
        border-bottom: none;
      }
      .accountEmailInsightsTitleLoader {
        padding: 10px;
        border-radius: 6px;
        &.linear-background {
          width: 30%;
        }
      }
      .accountEmailInsightsBadgeLoader {
        padding: 10px;
        border-radius: 6px;
        box-shadow: none;
        &.linear-background {
          width: 30%;
        }
      }
    }
  }
}

.singleBanner .accountInfo {
  height: calc(100vh - 172px);
}
