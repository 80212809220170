.scheduledDateToast {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 40px;
  margin: auto;
  .wrapScheduleTimeBanner {
    position: relative;
    display: block;
    text-align: center;
    background: #c9f3c1;
    padding: 5px;
    border-radius: 6px;
    width: 98%;
    .text {
      color: #444;
      font-size: 14px;
    }
    i {
      position: absolute;
      font-size: 18px;
      color: #444;
      right: 10px;
      top: 4px;
      cursor: pointer;
      border-radius: 35px;
      padding: 2px;
      &:hover {
        background: #91ce9c;
        transition: all ease 0.3s;
      }
    }
  }
}
