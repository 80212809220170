.dialerServiceCardSection {
  position: fixed;
  @include wt-ht(340px, auto);
  right: 0;
  bottom: 0;
  background: $white;
  z-index: 101;
  overflow-x: hidden;
  transition: height 0.3s ease-in-out;
  user-select: none;
  box-shadow: -2px 0px 21px 0 rgba(59, 59, 59, 0.12);
}
.phoneExample {
  font-size: 12px;
}

.linear-background {
  border-radius: 20px;
}
.infoFeed {
  position: absolute;
  z-index: 1000;
  background: #fff9e6;
  color: #ff9800;
  border-left: 1px solid #f5d575;
  border-right: 1px solid #f5d575;
  border-bottom: 1px solid #f5d575;
  font-size: 14px;
  letter-spacing: 0.3px;
  padding: 7px 20px;
  font-weight: 500;
  left: 50%;
  transform: translateX(-50%);
  top: 42px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all 0.3s ease-in;
}
.dialerPage {
  position: relative;
  overflow-y: auto;
  @include wt-ht(340px, auto);
}
.dialerServiceCardLoader {
  background: white;
}
.dialerServiceCard,
.dialerServiceCardLoader {
  .dsHeader {
    @extend %cardSlideHeader;
    position: sticky;
    top: 0;
    z-index: 10;
    .iconHolder {
      color: white;
      position: relative;
      top: 4px;
      .svgIcon {
        fill: white;
        position: relative;
        right: 16px;
      }
      .arrowIcon {
        font-size: 40px;
        position: relative;
        top: 7px;
        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
  }
  .unavailableItalic {
    font-size: 12px;
    font-style: italic;
    color: $c-78;
  }
  @extend %contactInfoCard;
  .inputRemoveBtnWrapLoader {
    margin-top: 10px;
  }
  .contactInfoList {
    background-color: rgba(242, 243, 255, 0.47);
    text-align: center;
  }
  .contactFullName {
    color: #000000 !important;
  }
  .roundedLetter {
    @include flex-center();
    justify-content: center;
    @include wt-ht(50px, 50px);
    border-radius: 35px;
    padding-top: 2px;
    border: 2px solid #ffffff;
    color: #8d8d8d;
    text-transform: uppercase;
    font-size: 28px;
    background-color: #eff3f4;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .titleCompanyWrap {
    font-size: 12px;
  }
  .DailpadSection {
    .inputRemoveBtnWrap,
    .inputRemoveBtnWrapLoader {
      display: grid;
      grid-template-columns: 250px 30px;
      justify-content: center;
      align-items: center;
      .dialInputBox {
        position: relative;
        display: block;
        height: 20px;
        border-radius: 5px !important;
      }
      input {
        border: none;
        font-size: 18px;
        text-align: center;
        letter-spacing: 1px;
        user-select: auto;
        &::placeholder {
          color: $b-c-1;
          text-align: center;
          font-weight: 400;
          letter-spacing: 0px;
        }
      }
      .removeBtn {
        position: relative;
        @include flex-center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        @include wt-ht(30px, 30px);
        padding: 6px;
        &:hover {
          background: $bg-default;
          svg {
            color: $c-66;
            fill: $c-66;
          }
        }
        svg {
          @include wt-ht(18px, 18px);
          color: $b-c-1;
          fill: $b-c-1;
        }
        &.linear-background {
          @include wt-ht(20px, 20px);
          margin-left: 10px;
          border-radius: 50%;
        }
      }
    }
    .contactNameInputWrap {
      position: relative;
      display: block;
      text-align: center;
      border-bottom: 1px solid $bg-default;
      padding: 10px;
      padding-top: 0;
      h6.contactName {
        font-size: 16px;
        font-weight: 500;
        color: $c-5c;
        margin-bottom: 5px;
        &.linear-background {
          width: 60%;
          margin: 0 auto;
        }
      }
    }
    .keypadSection {
      position: relative;
      padding: 10px 60px;
      display: grid;
      grid-template-columns: repeat(3, 50px);
      justify-content: space-between;
      .phoneKey {
        position: relative;
        @include flex-center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        border-radius: 50%;
        @include wt-ht(50px, 50px);
        &.linear-background {
          border-radius: 50% !important;
          margin-bottom: 10px;
          @include wt-ht(42px, 42px);
        }
        .number {
          position: relative;
          @include flex-center;
          justify-content: center;
          padding: 3px;
          font-size: 20px;
          letter-spacing: 0.3px;
          color: $c-66;
        }
        .letter {
          font-size: 10px;
          letter-spacing: 0.3px;
          color: $c-ab;
        }
        &:hover,
        &:active {
          background: $c-f5;
          .number {
            color: $c-22;
          }
          .letter {
            color: $c-66;
          }
        }
      }
    }
    .daillingNumberCard {
      position: relative;
      @include wt-ht(100%, auto);
      .contactFirstLetterBgWrap {
        @extend %relBlock;
        padding: 10px 20px;
        .overLetterCenter {
          display: flex;
          align-items: center;
          justify-content: center;
          .contactImgBg {
            @include wt-ht(50px, 50px);
            opacity: 0.5;
            background: $c-blue;
            border-radius: 50%;
          }
          svg {
            @include wt-ht(89px, 89px);
            color: $c-blue;
            fill: $c-blue;
          }
          .firstLetter {
            position: absolute;
            font-size: 24px;
            color: $blue-violet;
          }
        }
      }
      .contactNameInputWrap {
        position: relative;
        display: block;
        text-align: center;
        border-bottom: 1px solid transparent;
        padding: 10px 20px;
        letter-spacing: 0.3px;
        h6.contactName {
          font-size: 18px;
          font-weight: 600;
          color: $c-5c;
          margin-bottom: 5px;
        }
        .callStatus {
          position: relative;
          margin-top: 10px;
          margin-bottom: 30px;
          font-size: 18px;
          color: $c-66;
          text-transform: capitalize;
          &.calling::after {
            content: "...";
            color: $c-66;
          }
          &.connected {
            color: $green;
          }
          &.ended,
          &.disconnected {
            color: red;
          }
        }
        .contactNumber {
          position: relative;
          margin-top: 10px;
          font-size: 16px;
          color: $c-66;
          &.ended,
          &.disconnected {
            animation: blinkingText 1.2s 3;
          }
        }
        .timerSection {
          position: relative;
          @include flex-center;
          justify-content: center;
          margin-top: 10px;
          transition: all 0.3s ease;
          color: $c-66;
          height: 20px;
          .calling,
          .connected,
          .disconnected,
          .ended {
            position: relative;
            display: flex;
            margin-right: 10px;
            svg {
              @include wt-ht(16px, 16px);
              color: $c-66;
              fill: $c-66;
            }
          }
          .connected svg {
            color: $green;
            fill: $green;
          }
          .disconnected svg,
          .ended svg {
            color: red;
            fill: red;
            transform: rotate(135deg);
          }
          .minutes,
          .hours,
          .seconds {
            position: relative;
            display: block;
            text-align: center;
            letter-spacing: 1px;
            width: 28px;
            transition: all 0.3s ease;
          }
          &.ended,
          &.disconnected {
            color: red;
            transition: all 0.3s ease;
            animation: blinkingText 1.2s 3;
          }
        }
        &.ivrKeypadEnabled {
          .callStatus {
            margin-top: unset;
            margin-bottom: 10px;
          }
        }
        .recordingState {
          position: relative;
          @include flex-center;
          margin: 15px 0 10px;
          justify-content: center;
          .redDot {
            position: relative;
            padding: 5px;
            border-radius: 35px;
            background: red;
            margin-right: 5px;
          }
          .recordingText {
            font-size: 14px;
            color: $c-66;
          }
        }
      }
      .dialerKeyPadOnCalling {
        position: relative;
        .inputRemoveBtnWrap {
          padding-bottom: 10px;
        }
        .keypadSection {
          border-top: 1px solid $bg-default;
        }
      }
    }
  }
  .dialerBtnSection,
  .dialerButtonLoader {
    display: grid;
    grid-template-columns: 34px 34px 50px 34px 34px;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px 10px;
    .linear-background {
      @include wt-ht(34px, 34px);
      border-radius: 50% !important;
      &.callLoader {
        @include wt-ht(50px, 50px);
      }
    }
    %iconRound {
      position: relative;
      @include flex-center;
      justify-content: center;
      @include wt-ht(34px, 34px);
      border-radius: 50%;
      padding: 5px;
      user-select: none;
      transition: all 0.3s;
    }
    %setIconColor {
      color: $c-ab;
      svg {
        color: $c-ab;
        fill: $c-ab;
        path {
          color: $c-ab;
          fill: $c-ab;
        }
      }
    }
    %enabled {
      border: 1px solid $c-e0;
      background: white;
      cursor: pointer;
      transition: all 0.3s;
      color: $c-73;
      svg {
        color: $c-73;
        fill: $c-73;
        path {
          color: $c-73;
          fill: $c-73;
        }
      }
    }
    %active {
      border: 1px solid $blue-violet;
      background: $blue-violet;
      cursor: pointer;
      transition: all 0.3s;
      color: white;
      svg {
        color: white;
        fill: white;
        path {
          color: white;
          fill: white;
        }
      }
    }
    .recordIcon {
      &.recordingDisabled {
        @extend %iconRound;
        border: 1px solid $c-f1;
        background: $c-f1;
        opacity: 0.7;
        svg {
          opacity: 0.5;
          path {
            opacity: 0.5;
          }
        }
      }
      &.pauseRecording {
        @extend %iconRound;
        cursor: pointer;
        background: #ff6a6a;
        user-select: auto;
        svg {
          @include wt-ht(18px, 18px);
          fill: white;
        }
      }
      &.startRecording,
      &.resumeRecording {
        position: relative;
        @include flex-center;
        justify-content: center;
        @include wt-ht(34px, 34px);
        border-radius: 50%;
        padding: 5px;
        opacity: 1;
        cursor: pointer;
        border: 1px solid $c-e0;
        background: white;
        svg {
          @include wt-ht(13px, 13px);
          fill: white;
        }
      }
    }
    .dialMenu {
      cursor: pointer;
    }
    .mike {
      span {
        font-size: 18px;
      }
    }
    .dialPadDisabled {
      opacity: 0.7;
      pointer-events: none;
    }
    .mike,
    .dialMenu,
    .notes {
      @extend %iconRound;
      background: white;
      border: 1px solid $c-f1;
      svg {
        @include wt-ht(16px, 16px);
      }
      @extend %setIconColor;
      &.enabled {
        @extend %enabled;
      }
      &.active {
        @extend %active;
        color: white;
      }
      &.keypadEnabled {
        @extend %active;
      }
    }
    .callUser,
    .endCall {
      @extend %iconRound;
      border: 1px solid $c-ab;
      @include wt-ht(50px, 50px);
      padding: 10px;
      cursor: pointer;
      svg {
        @include wt-ht(24px, 24px);
        color: white;
        fill: white;
      }
    }
    .endCall {
      border: 1px solid red;
      background: red;
      svg {
        transform: rotate(135deg);
      }
    }
    .callUser {
      border: 1px solid $c-green;
      background: $c-green;
    }
  }
  .dsDefaultNoSection {
    position: relative;
    border-top: 1px solid $bg-default;
    padding: 5px 20px;
    .dsWrapDefautContactLoader {
      height: 36px;
      position: relative;
      display: grid;
      grid-template-columns: 40px 200px;
      align-items: center;
      justify-content: center;
      &.linear-background {
        height: 16px;
      }
      .number {
        margin-left: 10px;
      }
    }
    .dsWrapDefautContact {
      position: relative;
      @include flex-center;
      justify-content: center;
      color: $c-73;
      font-size: 14px;
      .text {
        position: relative;
        color: $c-73;
        font-size: 12px;
        margin-right: 5px;
      }
      .number {
        @extend %relBlock;
        width: 160px;
        padding: 5px 10px;
        @include flex-center;
        justify-content: space-between;
        border-radius: 5px;
        cursor: pointer;
        span {
          @extend %relBlock;
          margin-top: 2px;
        }
        i {
          margin-left: 10px;
          font-size: 20px;
          vertical-align: middle;
        }
        &:hover {
          background: $c-f5;
          color: $c-33;
        }
      }
      .purchaseNumber {
        position: relative;
        margin: 2px 0;
        padding: 5px 15px;
        border: 1px solid $blue-violet;
        border-radius: 5px;
        margin-left: 10px;
        font-size: 14px;
        cursor: pointer;
        color: $blue-violet;
      }
      .defaultNoLoader {
        position: relative;
        display: block;
        @include wt-ht(160px, 30px);
        border-radius: 5px;
      }
      .drop-down-menu {
        bottom: 0;
      }
      .overlayDefaultNo {
        @include bg-mask(99, transparent);
        left: unset;
        width: 340px;
      }
    }
  }
  .dsPhoneCreditsSection,
  .dsPhoneCreditsSectionLoader {
    position: relative;
    padding: 5px 20px;
    text-align: center;
    border-top: 1px solid $bg-default;
    .creditsWrap {
      position: relative;
      @include flex-center;
      font-size: 12px;
      justify-content: center;
      .text {
        position: relative;
        color: $c-73;
      }
      .creditsRemaining {
        position: relative;
        display: block;
        margin-left: 5px;
      }
      .creditsRemainingLoader {
        height: 4px;
        border-radius: 35px;
        width: 16px;
      }
    }
    .addCreditsBtn {
      @extend %relBlock;
      font-size: 12px;
      color: $blue-violet;
      cursor: pointer;
      text-align: right;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
      font-weight: 500;
      &:hover {
        background: $c-f5;
      }
    }
  }
  .dsPhoneCreditsSectionLoader {
    height: 36px;
    position: relative;
    align-items: center;
    .creditsWrap {
      display: grid;
      grid-template-columns: 40px 120px;
      align-items: center;
      justify-content: center;
    }
  }
  .dsUpgradeCreditsPopup,
  .dsCancelLogPopup {
    position: fixed;
    z-index: 102;
    @include flex-center;
    justify-content: center;
    right: 0px;
    top: 0;
    bottom: 0;
    padding: 0 40px;
    .upgradeCreditsCard,
    .cancelLogCard {
      @extend %relBlock;
      background: white;
      padding: 30px;
      z-index: 103;
      width: 260px;
      border-radius: 10px;
      text-align: center;
      img {
        @extend %relBlock;
        width: 50px;
        margin: 0 auto 20px;
      }
      h6 {
        color: $c-53;
        font-size: 16px;
        letter-spacing: 0.3px;
        margin-bottom: 10px;
      }
      p {
        color: $c-66;
        margin-bottom: 10px;
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 1.5;
      }
      .creditsBtn,
      .cancelLogBtn {
        @extend %relBlock;
        padding-top: 10px;
        .addPhoneCreditsBtn,
        .yesCloseBtn {
          @extend %primaryBlueBtn;
          padding: 10px 0;
          min-width: 130px;
        }
        .cancelUpgradeBtn,
        .noDontCancelBtn {
          margin-top: 10px;
          @extend %outBlueBorBtn;
          padding: 10px 0;
          min-width: 130px;
        }
      }
    }
    .overlayUCP,
    .overlayCLP {
      @include bg-mask(101, rgba(0, 0, 0, 0.27));
      left: unset;
      width: 340px;
    }
  }
  .dsUpgradeCreditsPopup {
    position: absolute;
    .overlayUCP {
      position: absolute;
    }
  }
}
.dialerInformationArea {
  padding: 10px;
}
.DialerFirstLetter {
  @extend %relBlock;
  @include wt-ht(100%, 100px);
  &.callingHt {
    @include wt-ht(100%, 140px);
  }
  &.emptyContactHt {
    @include wt-ht(100%, 190px);
    .blueWaveBg {
      @include wt-ht(100%, 190px);
    }
  }
  .blueWaveBg {
    @include wt-ht(100%, 140px);
    position: absolute;
    display: block;
    z-index: 0;
    background: #eff1f9;
    opacity: 0.5;
    object-fit: cover;
  }
  .circleFirstLetter {
    position: relative;
    @include flex-center;
    justify-content: center;
    height: 100%;
    .whiteCircleBg {
      @include wt-ht(60px, 60px);
      position: absolute;
      display: block;
      background: white;
      border-radius: 50%;
      z-index: 1;
      &.linear-background {
        border-radius: 50% !important;
      }
    }
    .circleBlueWaveBg {
      @include wt-ht(60px, 60px);
      position: absolute;
      display: block;
      border-radius: 50%;
      z-index: 1;
      background: #eff1f9;
      opacity: 0.5;
      &.linear-background {
        border-radius: 50% !important;
      }
    }
    .letter {
      font-size: 24px;
      font-family: $font-semi-bold;
      color: $blue-violet;
      z-index: 2;
    }
  }
  &.contactCardLetterWrapper {
    @include wt-ht(100%, 100px);
    .blueWaveBg {
      @include wt-ht(100%, 100px);
      object-fit: cover;
    }
    .circleFirstLetter {
      .whiteCircleBg {
        @include wt-ht(42px, 42px);
      }
      .letter {
        font-size: 22px;
        font-family: $font-regular;
      }
    }
  }
}
.overlayDssBg {
  @include bg-mask(99, transparent);
}
.dsLogAfterEndSection {
  @extend %relBlock;
  padding: 0 20px 20px;
  %label {
    @extend %relBlock;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: $c-66;
  }
  .wrapInputLabel {
    @extend %relBlockWidth;
    margin-top: 20px;
    .label {
      @extend %label;
    }
    .callOutcomeBtn {
      position: relative;
      border: 1px solid $c-ee;
      @include flex-center;
      justify-content: space-between;
      padding: 5px 10px;
      border-radius: 5px;
      width: 100%;
      cursor: pointer;
      .text {
        font-size: 14px;
        color: $c-66;
      }
      i {
        font-size: 20px;
        vertical-align: middle;
      }
    }
    .drop-down-menu ul {
      max-height: 160px !important;
    }
    .overlayCallOutcomeBg {
      @include bg-mask(100, transparent);
      left: unset;
      width: 340px;
    }
  }
  .callNotesWrap {
    @extend %relBlockWidth;
    margin-top: 20px;
    .notes-section {
      height: 100%;
      .name {
        @extend %label;
      }
      .txt-cont {
        color: $c-66;
        border-radius: 5px;
        border: 1px solid $c-ee;
        height: 100px;
        transition: all 0.3s;
        font-size: 14px;
      }
    }
  }
  .saveContactLogNo {
    @extend %relBlockWidth;
    margin-top: 20px;
    p {
      @extend %relBlockWidth;
      font-size: 14px;
      color: $c-66;
      line-height: 1.5;
      letter-spacing: 0.3px;
      .contactName {
        color: $blue-violet;
      }
    }
    .toggleBtnSection {
      margin-top: 5px;
      .rightPad {
        padding-right: 0;
      }
      .leftPad {
        font-size: 14px;
      }
    }
  }
  .logCallBtnWrapper {
    position: relative;
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    .logCallBtn {
      @extend %primaryBlueBtn;
      width: 130px;
      padding: 10px 0;
      cursor: pointer;
      margin: 0;
    }
  }
}
.callNotesSection {
  position: fixed;
  display: block;
  width: 340px;
  padding: 20px;
  bottom: 0;
  background: white;
  right: 340px;
  z-index: 100;
  border-top-left-radius: 10px;
  border-left: 1px solid $c-f5;
  border-top: 1px solid $c-f5;
  box-shadow: -1px 0px 8px 2px rgba(0, 0, 0, 0.08);
  animation: cardSlideInRight 0.3s linear;
  &.pluginCallNotes {
    width: 300px;
    background: white;
    right: 20px;
    border-top-right-radius: 10px;
    animation: cardSlideInBtmRight 0.3s linear;
  }
  i {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 20px;
    @extend %closeIcon;
    padding: 4px;
    z-index: 2;
  }
  .notes-section {
    height: 240px;
    .name {
      font-size: 14px;
      color: $c-66;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}
.dsCallLogsOverlay {
  @include bg-mask(100, rgba(0, 0, 0, 0.65));
}
.dsCallLogsPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  will-change: transform;
  user-select: none;
  width: fit-content;
  .upgradeSettingsCard {
    width: 440px;
    position: relative;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 22.3px 1.7px rgba(59, 59, 59, 0.14);
    border: 1px solid #ebebeb;
    background-color: #fff;
    align-items: flex-end;
    .dsLogAfterEndSection,
    .contactInfoList {
      padding: 0;
      border: 0;
      padding-top: 12px;
    }
    @extend %contactInfoCard;

    .headingWrapper {
      @include flex-center();
      justify-content: space-between;
      color: $c-66;
      font-weight: 600;
      .closeIcon {
        cursor: pointer;
        font-size: 20px;
        padding: 3px;
        &:hover {
          border-radius: 50%;
          background: #ebebeb;
        }
      }
    }
    .selectedContactsText {
      position: relative;
      display: block;
      padding-top: 12px;
      color: $blue-violet;
    }
    .sequenceInfo {
      border: 1px solid #c7ceff;
      color: $c-66;
      line-height: 1.4;
      display: inline-flex;
      align-items: center;
      font-size: 12px;
      padding: 2px 8px;
      margin-top: 12px;
      border-radius: 14px;
      .phoneCall,
      .of {
        text-transform: none;
      }
      .phoneCallStage,
      .phoneCallSequence {
        color: $blue-violet;
        font-weight: 500;
        white-space: nowrap;
      }
      .phoneCallSequence {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 200px;
      }
    }
  }
}

@keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
