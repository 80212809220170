.multiSelectDropdown {
  position: relative;
  border-bottom: 1px solid $c-ec;

  .dropdownSearchBox {
    position: relative;
    padding: 0 5px 10px 10px;
    background-color: $white;
    .horizontalBorderLine {
      position: absolute;
      width: 4px;
      height: calc(100% - 10px);
      background: #dee3ff;
      border-radius: 5px;
      left: 0;
      top: 0px;
    }
    .searchBox {
      @extend %relBlock;
      .searchInputList {
        position: relative;
        display: grid;
        z-index: 3;
        grid-template-columns: 100%;
        &.twoColLayout {
          grid-template-columns: calc(100% - 30px) 20px;
          justify-content: space-between;
        }
        align-items: center;
        .inputBox {
          color: $c-44;
          border: none;
          border-bottom: 1px solid $c-dd;
          padding: 5px 5px 5px 0;
          height: 30px;
          background: transparent;
          font-size: 14px;
          &:focus {
            color: $blue-violet;
          }
          &::placeholder {
            color: $c-85;
          }
        }
        .closeIcon {
          font-size: 16px;
          color: $c-44;
          cursor: pointer;
          border-radius: 35px;
          transition: all 0.3s ease;
          margin: 0 auto;
          &:hover {
            background: $c-f5;
            color: $c-22;
          }
        }
      }
      .dropdownList {
        position: absolute;
        width: 100%;
        top: 26px;
        z-index: 4;
        background: white;
        padding: 6px;
        border-radius: 6px;
        border: 1px solid $c-ec;
        max-height: 200px;
        overflow: auto;

        .noSequencesMsg {
          @include flex-align-center;
          position: relative;
          padding: 8px 10px;
          width: 100%;
          font-size: 12px;
        }
      }
    }
    .selectedItems {
      position: relative;
      @include flex-center;
      flex-wrap: wrap;
      padding-top: 10px;
      gap: 6px;
      .chip {
        position: relative;
        @include flex-center;
        justify-content: space-between;
        border-radius: 10px;
        border: solid 1px $c-eb;
        background-color: #fff;
        .name {
          position: relative;
          padding: 4px 0px 3px 6px;
          font-size: 12px;
          color: $c-66;
          flex-flow: wrap;
          max-width: 80px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
          align-items: center;
        }
        .closeIcon {
          cursor: pointer;
          font-size: 12px;
          color: $c-66;
          border-radius: 35px;
          margin: 0 2px 0 5px;
          padding: 2px;
          &:hover {
            color: $c-66;
            background: $c-ec;
          }
        }
      }
      .expandCollapse {
        position: relative;
        @include flex-center;
        padding: 0 6px;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.08);
        color: $c-3b;
        height: 18px;
        width: fit-content;
        transition: all 0.3 ease;
        cursor: pointer;
        .count {
          font-size: 11px;
          padding-top: 2px;
          font-weight: 600;
        }
        .arrowIcon {
          font-size: 14px;
          height: 12px;
          &.rotate {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .dropdownBgOverlay {
    @include bg-mask(1, transparent);
  }
}
