@mixin FileUploadBox {
  .fileUploadWrapper {
    @include flex-center;
    justify-content: center;
    flex-direction: column;
    .fileUploadCont {
      width: 50%;
      text-align: center;
      color: #858585;
      display: inline-block;
      margin: 0;
      padding: 40px 0;
      border-radius: 10px;
      font-style: italic;

      .fileuploadInput {
        position: absolute;
        width: 100%;
        outline: none;
        opacity: 0;
        margin: auto;
        left: 0;
        display: inline-block;
        top: 0;
        height: 100%;
        cursor: pointer;
      }
    }
  }
  .sampleFile {
    padding: 20px 0px;
    color: $c-85;
    font-size: 13px;

    .clickHereLink {
      color: $blue;
      cursor: pointer;
      margin-left: 5px;
    }
  }
}

@mixin CsvUpload {
  .uploadContainer {
    height: 100%;
    .uploadWrapper {
      @include flex-align-path-justify(center, column, center);
      height: 350px;
      .fileUploadContainer {
        border: 2px dotted #dddddd;
        @include wt-ht(100%, 100%);
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .imageCont {
        padding: 25px 0px;
        img {
          width: 100px;
          height: auto;
          object-fit: contain;
        }
      }
      .fileUploadWrapper {
        width: 100%;
      }

      @include FileUploadBox();
    }
  }
}

@mixin GeneratingPreview {
  .generatingPreview {
    transition: all 0.5s;
    height: 350px;
    overflow-y: auto;
    @include flex-align-path-justify(center, column, center);
    .generatingPreviewImg {
      object-fit: contain;
    }
    .generatingPreviewText {
      padding: 10px 0;
      font-family: $font-medium;
      font-size: 16px;
      color: $c-66;
    }
  }
}

@mixin BulkContactTable {
  .bulkContactTable {
    position: relative;
    display: grid;
    grid-template-columns: 25% 25% 50%;
    overflow-y: auto;
    max-height: 435px;
    min-height: 200px;
    .csvFieldsColumn {
      position: relative;
    }
    .columnTitle {
      color: $blue-violet;
      font-family: $font-medium;
      font-size: 15px;
      padding-bottom: 15px;
      text-align: left;
      position: sticky;
      top: 0;
      z-index: 2;
      background: white;
      border-bottom: 1px solid $b-c-1;
    }

    .csvFieldColumn {
      font-family: $font-medium;
      color: $c-4e;
      font-size: 13px;
      text-transform: capitalize;
      .required {
        @include fieldRequired(4px);
      }
    }

    .columnData {
      font-size: 14px;
      height: 45px;
      color: $c-66;
      text-align: left;
      padding: 13px 0px;
      @include ellipsis();
      border-bottom: 1px solid $c-f3;
    }
  }
}

@mixin CSVmappingDropDown {
  .listCont {
    .dropDownParent {
      position: relative;
      overflow: unset;
      @include flex-center();

      .selectedField {
        color: $c-66;
        font-family: $font-regular;
        border-radius: 6px;
        border: solid 1px $c-f3;
        padding: 5px 7px;
        min-width: 150px;
        max-width: 160px;
        cursor: pointer;
        width: fit-content;
        @include flex-center();
        justify-content: space-between;
        text-transform: capitalize;

        span {
          @include ellipsis();
        }

        .downArrowBlack {
          padding: 0px 5px;
        }
      }

      .invalidMap {
        @include flex-center();
        i {
          color: $c-fb656;
          font-size: 18px;
          padding-left: 5px;
          cursor: pointer;
        }

        .customTooltip {
          .sharpPointer {
            border-color: $c-4543 !important;
            background-color: $c-4543;
            padding: 7px;
            top: 10px;
          }

          .tooltipBody {
            background-color: $c-4543;
            top: 17px;
            border-radius: 5px;
            left: -30px;

            .tooltipText {
              padding: 7px;
              color: $white;
              font-size: 11px;
            }
          }
        }
      }

      .invalid {
        border-color: $c-fb656 !important;
      }

      .drop-down-menu {
        left: 0px;
        max-width: fit-content;
        top: 40px;
        max-height: 200px;
        overflow: auto;
        font-family: $font-regular;

        .csv_dropdown {
          height: 36px;
          text-transform: capitalize;
        }
      }

      &.dropDownUpPosition {
        .drop-down-menu {
          bottom: 40px;
          top: unset;
        }
      }
    }

    .dropdown-overlay {
      @include bg-mask(1, transparent);
    }
  }
}

@mixin UploadContactButton {
  .actionCont {
    height: calc(100% - 525px);
    @include flex-center();
    justify-content: flex-end;

    button#uploadBulkContact {
      background-color: $blue-violet;
      color: $white;
      margin: 0px;
      padding: 12px 25px;

      &.disabled {
        pointer-events: none;
      }
    }
  }
}

@mixin BulkPopupMessage {
  .bulkPopupMessage {
    height: 100%;
    margin-bottom: 25px;
    @include flex-align-path-justify(center, column, center);
    .imageCont {
      img {
        width: 75px;
        object-fit: contain;
      }
    }

    .errorMessage {
      padding-top: 25px;

      .messageCont {
        .message {
          color: $c-66;
          font-family: $font-regular;
          padding-bottom: 25px;
          text-align: center;

          b {
            font-family: $font-medium;
          }
        }

        .missingFieldsCont {
          color: $c-66;
          font-family: $font-medium;
          text-align: center;

          .missingField {
            .value {
              text-transform: capitalize;
            }
            .delimitter {
              padding: 0px 3px;
            }
          }
        }
      }

      &.limiReached {
        width: 70%;
        @include flex-center();
        .accordionText {
          font-size: 16px;
          line-height: 1.6;
          width: 480px;
          margin: 0 auto;
        }
      }
    }

    .bottomCont {
      padding-top: 70px;
      color: $c-66;

      .orText {
        margin: 15px 0px;
        font-style: italic;
      }

      .cancelText {
        font-style: italic;

        .canceLink {
          color: $blue-violet;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .btnCont {
        @include flex-align-path-justify(center, row, center);
        margin-top: 15px;

        .skipAndUploadContact,
        #reUploadContact {
          background-color: $blue-violet;
          color: $white;
          min-width: 120px;
          margin: 0 15px;
        }

        #backToContactTable {
          background-color: $white;
          min-width: 120px;
          margin: 0 15px;
          border: 1px solid $blue-violet;
          color: $blue-violet;
          position: relative;

          i.material-icons-outlined {
            position: absolute;
            top: 10px;
            left: 20px;
            font-size: 20px;
          }

          &:hover {
            i.material-icons-outlined {
              transform: translate(-20%);
              will-change: transform;
              transition: all 0.5s;
            }
          }
        }
      }

      .reuploadMsg {
        color: $red;
      }
    }
  }
}

.addBulkContactWrapper {
  position: fixed;
  z-index: 12;
  height: 100%;
  inset: 0;
  .common-popup {
    @extend %relBlock;
    padding: 0;
    .popup-wrap {
      @extend %relBlock;
      height: calc(100% - 10%);
      border-radius: 10px;
      min-width: 1000px;
      background: #fff;
      .popupHeader {
        .title {
          padding: 25px;
        }
      }
      .popup-content-wrap {
        @extend %relBlock;
        height: calc(100% - 67px);
        @include CsvUpload();
        @include GeneratingPreview();
        @include BulkContactTable();
        @include CSVmappingDropDown();
        @include UploadContactButton();
        @include BulkPopupMessage();
        padding: 10px 50px;
        .actionCont {
          @extend %relBlock;
          height: 50px;
          margin-bottom: 10px;
          margin-top: 10px;
        }
      }
    }
  }
}
