.numberInputBox {
  @extend %relBlock;
  margin-bottom: 16px;
  &.numberInputBoxLoader {
    width: 100%;
  }
  label,
  .labelLoader {
    @extend %relBlock;
    color: $c-66;
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 500;
    &.linear-background {
      border-radius: 10px;
      @include wt-ht(40%, 16px);
    }
  }
  .numberInputBoxWrapper {
    position: relative;
    max-width: 500px;
    .thunderSvg {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    .upgradeTooltipSection {
      right: -146px;
      .pointer {
        right: 152px;
        top: -8px;
      }
    }
  }
  .numberInputBoxSubLabel {
    font-size: 12px;
    color: #8c8c8c;
    margin-left: 6px;
  }
  .subTextForLabel {
    font-size: 12px;
    margin-bottom: 16px;
    color: #8c8c8c;
  }
  input {
    @extend %relBlock;
    padding: 10px;
    border-radius: 3px;
    width: 100%;
    outline: none;
    border: 1px solid $c-dd;
    font-size: 14px;
    background: white;
    &:valid {
      color: $blue-violet;
    }
    &:invalid {
      color: $blue-violet;
    }
    &:disabled {
      color: $c-cc;
      background-color: $c-f0;
      opacity: 0.75;
    }
    &:focus {
      color: $c-66;
    }
  }
  .inputBoxLoader {
    @include wt-ht(100%, 38px);
    border-radius: 3px;
  }
  &:focus-within {
    input {
      border: 1px solid $blue-violet;
      color: $c-66;
    }
    label,
    .numberInputBoxSubLabel {
      color: $blue-violet;
    }
  }
  .errMsgText {
    @extend %errMsgText;
  }
}
