.tabNavigation {
  position: relative;
  @include flex-center;
  background: transparent;
  @include list-style-zero;
  .tabItem {
    position: relative;
    font-size: 14px;
    top: 2px;
    color: $c-53;
    letter-spacing: 0.3px;
    cursor: pointer;
    height: 46px;
    @include flex-align-center;
    padding: 0 1.5rem;
    font-weight: 400;
    text-decoration: none;
    &.active {
      font-weight: 500;
      color: $blue-violet;
      border-bottom: 2px solid $blue-violet;
      transition: all 0.3s ease;
    }
  }
}
