%calendarFormInputCont {
  .calendarFormInputCont {
    @extend %relBlock;
    @include wt-ht(100%, 100%);
    padding: 15px;
    .labelText {
      @extend %relBlock;
      letter-spacing: 0px;
      color: #343a40;
      font-size: 16px;
      margin: 0;
      font-weight: 600;
    }
    .timezoneInputForm {
      position: relative;
      padding: 10px 0 20px;
      .activeValue {
        overflow: unset;
        white-space: unset;
        text-overflow: unset;
      }
      .singleSelectDropdown {
        border: 0;
        border: 1px solid $c-eb;
        padding-left: 10px;
      }
    }
    .rdrCalendarWrapper {
      position: relative;
      width: 100%;
      margin: 0 auto;
      border: 1px solid $c-e8;
      padding: 5px;
      top: 15px;
      box-shadow: unset;
      .rdrSelected + span + .rdrDayNumber,
      .rdrSelected + .rdrDayNumber {
        span {
          color: $white !important;
        }
      }
      .rdrWeekDays {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
      }
      .rdrWeekDay {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        color: $c-1e;
        width: 40px;
      }
      .rdrWeekDay:first-child,
      .rdrWeekDay:last-child {
        color: #3852a1;
      }
      .rdrDays {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        justify-content: space-between;
        align-items: center;
        grid-gap: 6px;
      }
      .rdrDay {
        border-radius: 50%;
        width: 40px;
        height: 38px;
        border: 1px solid #eff3f9;
      }
      .rdrDayDisabled {
        background-color: #eff3f9;
        color: #929292;
      }
      .rdrNextPrevButton {
        margin: 0;
      }
      .rdrMonthAndYearWrapper {
        padding: 0;
        height: 40px;
      }
      .rdrMonth {
        width: 100%;
        padding: 10px;
      }
      .rdrMonthName {
        display: none;
      }
      .rdrSelected {
        background-color: $blue-violet !important;
        color: $white !important;
        border-radius: 50%;
        inset: 0 0 5px 0;
        @include wt-ht(100%, 100%);
      }
      span.rdrSelected + .rdrDayNumber {
        border: 0 !important;
      }
      .rdrCalendarWrapper:not(.rdrDateRangeWrapper)
        .rdrDayHovered
        span.rdrSelected
        + .rdrDayNumber::after {
        border: 0 !important;
      }
      .rdrDayHovered {
        color: $blue-violet !important;
      }
      &:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
        border-radius: 50% !important;
        height: 36px;
        top: 0;
        bottom: 0;
      }
      .rdrDayToday.rdrDayHovered {
        .rdrDayNumber {
          border: 0 !important;
        }
      }
      .rdrMonths {
        width: 100%;
      }
      .rdrCalendarWrapper:not(.rdrDateRangeWrapper)
        .rdrDayHovered
        .rdrDayNumber:after {
        border: 0;
        border: 1px solid $blue-violet !important;
        border-radius: 50%;
      }
      .rdrDayEndPreview,
      .rdrDayStartPreview {
        border: 0 !important;
      }
      .rdrDayToday .rdrDayNumber {
        border: 1px solid $blue-violet !important;
        border-radius: 50%;
      }
      .rdrCalendarWrapper:not(.rdrDateRangeWrapper)
        .rdrDayToday.rdrDayHovered
        .rdrDayNumber:after {
        border: 0 !important;
      }
      .rdrDayToday .rdrDayNumber span:after {
        display: none !important;
      }
      .rdrDayNumber {
        top: 0px;
        bottom: 0;
      }
      @media (min-width: 0px) and (max-width: 1200px) {
        .rdrDay {
          width: 30px;
          height: 30px;
        }
        .rdrDayNumber,
        .rdrSelected {
          height: 29px;
        }

        .rdrWeekDay {
          width: 30px;
        }
        &:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
          height: auto !important;
        }
      }
      .rdrNextPrevButton {
        margin: 0;
      }
      .rdrMonthAndYearWrapper {
        padding: 0;
        height: 40px;
      }
      .rdrMonth {
        width: 100%;
        padding: 10px;
        .rdrMonthName {
          display: none;
        }
      }
    }
  }
}
