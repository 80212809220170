%navigation {
  position: relative;
  border: 1px solid $blue-violet;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  @include flex-center;
  justify-content: center;
  width: 54px;
  .prevTask,
  .nextTask {
    color: $blue-violet;
  }
  &.disableNavigation {
    opacity: 0.5;
    border: 1px solid $c-dd;
  }
}

.performTask {
  .taskSection {
    font-family: $font-regular, Arial, sans-serif;
    display: flex;

    .navLayout {
      .taskNavigation {
        .navInnerCont {
          display: flex;
          align-items: center;
          padding: 15px;
          height: 60px;
          color: $c-66;
          font-weight: 500;
          border-bottom: 1px solid $c-ee;
          justify-content: space-between;

          .numberOfTaskCont {
            display: flex;
            align-items: center;
          }

          .backToTaskCont {
            color: $blue-violet;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 13px;
            cursor: pointer;

            .leftArrow {
              margin-right: 10px;
              width: 14px;
              height: 14px;

              svg {
                width: inherit;
                height: inherit;
                transform: rotate(180deg);

                path {
                  fill: $blue-violet;
                }
              }
            }
          }
        }
      }

      .taskListInnerCont {
        height: calc(100% - 70px);
        overflow-y: auto;

        .noTaskfound {
          @include flex-center();
          justify-content: center;
          height: 100%;
        }

        .taskCard {
          height: fit-content;
          width: 100%;
          display: block;
          border-bottom: 1px solid $c-ee;

          &:hover {
            background-color: #f8f8f8;
          }

          .cardInnerCont {
            display: flex;
            width: 100%;
            align-items: flex-start;
            flex-direction: column;
            padding: 15px;
            justify-content: left;
            cursor: pointer;

            .headerCont {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              .completedBtn {
                @include flex-center();
                color: $green !important;
                text-transform: capitalize;

                .tickIcon {
                  color: $green;
                  font-size: 20px;
                  margin-right: 5px;
                }
              }

              .taskDue {
                font-size: 12px;
              }
            }

            .contactName {
              color: $c-53;
              text-transform: capitalize;
              font-size: 14px;
              width: 70%;
              word-break: break-word;
            }

            .detailCont {
              padding-bottom: 7px;
              color: $c-66;
              font-size: 13px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .jobTitle,
              .company {
                word-break: break-all;
              }
              .dot {
                padding: inherit;
                margin: 0 5px;
              }
            }

            .type {
              background-color: $c-ec;
              width: fit-content;
              border-radius: 15px;
              padding: 3px 10px;
              font-size: 12px;
              display: flex;
              align-items: center;

              span {
                line-height: 14px;
                @include flex-center();
              }

              .icon {
                @include wt-ht(12px, 12px);
                margin-right: 5px;
                &.linkedin {
                  @include wt-ht(10px, 10px);
                }
                img {
                  width: 12px;
                }
                svg {
                  width: inherit;
                  fill: $c-66;
                  height: inherit;
                }
              }
              .email .manualEmail {
                font-size: 12px;
              }
            }
          }

          &.selectedContact {
            background-color: #f7f7f7;
          }
        }
      }
    }

    .contentLayout {
      position: relative;
      @include wt-ht(100%, 100%);
      justify-content: space-between;
      overflow: hidden;
      background: transparent;

      .performTaskPanel {
        position: relative;
        @include wt-ht(100%, 100%);
        @extend %contactSliderCard;

        .taskHeaderSection {
          position: relative;
          height: 60px;
          display: grid;
          grid-template-columns: 60px calc(100% - 75px);
          column-gap: 15px;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .backButton {
            position: relative;
            background: $white;
            border-radius: 10px;
            @include wt-ht(60px, 60px);
            @include flex-align-center;
            cursor: pointer;
            .backIconToolTipWrapper {
              position: relative;
              top: 3px;
              i {
                position: relative;
                font-size: 22px;
                color: $c-9e;
                &:hover {
                  color: $blue-violet;
                }
              }
            }
          }
          .taskDetailNavHeader {
            position: relative;
            display: grid;
            grid-template-columns: repeat(2, auto);
            column-gap: 15px;
            align-items: center;
            justify-content: space-between;
            @include wt-ht(100%, 60px);
            border-radius: 10px;
            background: white;
            padding: 0 20px;

            .navigationAndTaskCount {
              display: grid;
              grid-template-columns: 130px calc(100% - 140px);
              column-gap: 10px;
              align-items: center;
              .numberOfTaskCont {
                @include flex-align-center;
                font-size: 16px;
                &.linear-background {
                  width: 100px;
                }
              }
              .navTaskBtnCont {
                display: grid;
                grid-template-columns: 54px calc(100% - 64px);
                column-gap: 10px;
                height: 34px;
                align-items: center;
                .navToPrev,
                .navToNext {
                  @extend %navigation;
                }
              }
            }
            .detailedTaskInfo {
              @include flex-center;
              justify-content: space-between;
              .detailedTaskInfoLoader {
                width: 190px;
                display: flex;
                justify-content: space-between;
                .iconLoader {
                  width: 30px;
                }
                .taskTypeLoader,
                .taskDueLoader {
                  width: 70px;
                }
              }
              .completedBtn {
                position: relative;
                @include flex-center();
                color: $green !important;
                padding-left: 15px;
                .tickIcon {
                  color: $green;
                  font-size: 10px;
                  margin-right: 5px;
                  line-height: normal;
                  border: 2px solid $green;
                  border-radius: 50px;
                  padding: 1px;
                }
                .statusCont {
                  @include flex-center;
                  font-size: 12px;
                  padding-top: 2px;
                  text-transform: capitalize;
                }
              }
              .taskDue {
                @include flex-center;
                font-size: 12px;
                position: relative;
                padding-left: 20px;
                color: $green;
              }
              .taskTypeWithIcon {
                @include flex-center;
                font-weight: bold;
                color: $black;
                text-transform: capitalize;
                .svgIconType {
                  &.customTask {
                    fill: $orange;
                  }
                  &.email,
                  &.manualEmail {
                    fill: $c-00A358 !important;
                    path {
                      fill: $c-00A358 !important;
                    }
                  }
                  &.linkedin {
                    width: 15px;
                    fill: $c-0a66c2 !important;
                    path {
                      fill: $c-0a66c2 !important;
                    }
                  }
                }
                .taskName {
                  position: relative;
                  padding-left: 10px;
                }
              }
            }
          }
        }
        .taskBody {
          display: grid;
          grid-template-columns: 360px 310px calc(100% - 690px);
          column-gap: 10px;

          .performTaskSection {
            position: relative;
            overflow: auto;
            border-radius: 10px;
            width: 100%;
            height: calc(100vh - 204px);
            background: white;
            border: 1px solid $blue-violet;
            .innerCont {
              height: 100%;

              .scheduleTask {
                height: 100%;
                position: relative;

                .emailTask {
                  height: 100%;
                  &.adjustScheduledDateToase {
                    height: calc(100% - 43px);
                  }
                  .addressCont {
                    height: 52px;
                    display: grid;
                    padding: 15px;
                    align-items: center;
                    grid-template-columns: 70px calc(100% - 80px);
                    .labelName {
                      width: 70px;
                      border-radius: 10px;
                      height: 14px;
                    }
                    .contactName {
                      margin-left: 10px;
                      border-radius: 10px;
                      height: 14px;
                      width: calc(100% - 10px);
                    }
                  }
                  .userInputCont {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid $c-ee;
                    padding: 10px 15px;
                    width: 100%;

                    &.notesCont {
                      position: relative;
                      display: grid;
                      align-items: center;
                      grid-template-columns: 70px calc(100% - 70px);
                      .editableSection {
                        display: inherit;
                        width: inherit;
                      }
                    }

                    label {
                      width: 70px;
                      padding-right: 10px;
                      font-size: 13px;
                      line-height: 16px;
                    }

                    .notesWrap {
                      display: block;
                      position: relative;
                      display: flex;
                      align-items: center;
                      flex-direction: row;
                      font-size: 14px;
                      line-height: 16px;
                      .notesText {
                        font-size: 14px;
                        visibility: hidden;
                        opacity: 0;
                        transition: height 3s;
                        .readLess {
                          display: none;
                          cursor: pointer;
                          background: $white;
                          color: $blue-violet;
                          border: 1px solid transparent;
                          font-size: 12px;
                          font-weight: 500;
                          width: 80px;
                          border-radius: 35px;
                          letter-spacing: 0.3px;
                          transition: display 0.3s ease-in-out;
                          text-overflow: none;
                          overflow: none;
                          white-space: none;
                        }
                      }
                      .readMore {
                        display: none;
                        cursor: pointer;
                        background: $white;
                        color: $blue-violet;
                        line-height: initial;
                        border: 1px solid transparent;
                        font-size: 10px;
                        width: 70px;
                        border-radius: 35px;
                        margin-top: 2px;
                        font-weight: 500;
                        letter-spacing: 0.3px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                      }
                    }

                    .inputFieldCont {
                      width: calc(100% - 100px);
                      border: 0;
                      color: $blue-violet;
                      font-size: 14px;

                      &.editableAreaWrapper {
                        color: $c-66;
                        width: 70%;
                        background-color: #fff;
                        display: flex;
                        position: relative;
                        z-index: 30;
                      }
                    }

                    &.expand {
                      .editableAreaWrapper {
                        width: 100%;
                        top: 100%;
                        left: 0px;
                        display: block;
                        height: fit-content;
                        border-bottom: 1px solid #f1f4f7;
                        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                        position: absolute;
                        background-color: #fff;
                      }

                      .expandText {
                        float: right;
                        padding: 5px 0px;
                      }
                    }

                    .expandText {
                      font-size: 13px;
                      color: $blue-violet;
                      position: relative;
                      cursor: pointer;
                      text-align: right;
                    }

                    .optOutIcon {
                      font-size: 11px;
                      color: $red;
                      margin-right: 10px;
                    }
                  }
                  .fromAddressCont {
                    .fromEmailText {
                      &.highlightBlue {
                        font-size: 14px;
                        color: $blue-violet;
                        text-decoration: none;
                        cursor: pointer;
                      }
                    }
                    .selectInboxDropdownSection {
                      position: relative;
                      top: 1px;
                      font-size: 14px;
                      .dropdownArrow {
                        position: relative;
                        cursor: pointer;
                        font-size: 16px;
                        background: $c-f5;
                        color: $c-66;
                        margin-left: 10px;
                        border: 1px solid $c-ec;
                        border-radius: 35px;
                        transition: all 0.3s ease-in-out;
                        &:hover {
                          color: $c-33;
                        }
                      }
                      .drop-down-menu {
                        left: 0;
                        .dropdownList,
                        #selectInboxDropdown {
                          margin: 4px 0;
                          li {
                            color: $c-33;
                            &.alreadySelected {
                              pointer-events: none;
                              user-select: none;
                              opacity: 0.6;
                              cursor: unset;
                            }
                          }
                        }
                      }
                      .connectedInboxOverlay {
                        @include bg-mask(19, transparent);
                      }
                    }
                  }
                  .reactunsubscribeEditor {
                    .ql-toolbar.ql-snow {
                      .ql-formats:nth-child(4),
                      .ql-formats:nth-child(5) {
                        display: none;
                      }
                    }

                    .ql-snow .ql-tooltip::before {
                      content: "{{unsubscribeLink}}";
                      color: $blue-violet;
                    }
                    .ql-snow .ql-tooltip.ql-editing::before {
                      content: "Enter link";
                    }

                    .ql-snow .ql-tooltip a.ql-preview,
                    .ql-snow .ql-tooltip a.ql-action {
                      display: none !important;
                    }
                    .ql-snow .ql-editing[data-mode="link"] a.ql-action {
                      display: inline-block !important;
                      &:after {
                        content: "Save" !important;
                        color: $blue-violet;
                      }
                    }
                  }

                  #reactEditor {
                    height: calc(100% - 213px);
                    &.emailEditorAdHt {
                      height: calc(100% - 213px);
                    }
                    .quill {
                      border: 0;
                      padding: 0;
                      border-radius: 0 !important;
                      overflow: unset;
                      .ql-toolbar {
                        border-bottom: 1px solid;
                        border-color: $c-ee;
                        bottom: 0px;

                        button {
                          width: 22px;
                          height: 22px;
                        }

                        .ql-variables {
                          display: none;
                        }
                      }

                      .ql-container {
                        border: 0;
                        height: calc(100% - 42px);
                        overflow: auto;
                        margin-bottom: 0;
                        .ql-editor {
                          min-height: unset;
                        }
                      }
                    }
                  }

                  .editorLoader {
                    min-height: 260px;
                    position: relative;
                    padding: 20px;
                    .linear-background {
                      position: relative;
                      min-height: 180px;
                      max-height: auto;
                      display: block;
                      border-radius: 10px;
                      width: 100%;
                    }
                  }

                  .actionCont {
                    border: 0;
                  }
                }

                .linkedInTask,
                .phoneCallTask,
                .customTask {
                  height: calc(
                    100% - 60px
                  ); // 76px - 75px button cont height + 1px border
                  padding: 20px;
                  overflow: hidden;

                  .notesCont {
                    color: $c-66;
                    padding-top: 20px;
                    .notesLabel {
                      font-size: 16px;
                      color: $c-33;
                      font-weight: 500;
                      display: inline-block;
                      padding-bottom: 10px;
                    }

                    .editableAreaWrapper {
                      font-size: 14px;
                      max-height: 250px;
                      overflow-y: auto;
                      color: $c-66;
                      border: 1px solid $c-dd;
                      border-radius: 10px;
                      padding: 10px;
                    }
                  }

                  .taskTypeCont {
                    .taskLabel {
                      padding-bottom: 10px;
                      display: inline-block;
                      color: $c-33;
                      font-weight: 500;
                      .taskType {
                        font-size: 15px;
                      }
                    }
                  }

                  .taskButtonWrapper {
                    padding: 20px 0px;
                    .taskButton {
                      width: fit-content;
                      padding: 0px 10px;
                      border: 1px solid $blue-violet;

                      .svgIcon {
                        margin-right: 10px;

                        &.phoneIcon {
                          svg {
                            fill: $c-1eb9d5;
                            width: 16px;
                            height: 16px;
                          }
                        }
                        svg {
                          fill: $blue-violet;
                          width: 14px;
                          height: 12px;
                        }
                      }
                      .taskButtonText {
                        text-transform: none;
                        color: $c-66;
                      }
                    }
                  }
                }

                .actionContLoader {
                  position: absolute;
                  bottom: 0px;
                  padding: 20px;
                  width: 100%;
                  display: grid;
                  grid-template-columns: 100px 115px auto;
                  align-items: center;

                  .actionBtn {
                    position: relative;
                    display: block;
                    height: 30px;
                    border-radius: 6px;
                    width: 100px;
                    &:nth-child(2) {
                      margin-left: 15px;
                    }
                    &:last-child {
                      margin: 0 0 0 auto;
                    }
                  }
                }

                .actionCont {
                  font-size: 15px;
                  margin: 0px;
                  position: relative;
                  width: 100%;
                  padding: 10px 20px;
                  border-top: 1px solid $c-ee;
                  display: grid;
                  grid-template-columns: repeat(2, auto);
                  justify-content: space-between;

                  .btnWrapper,
                  .navTaskBtnCont {
                    display: grid;
                    grid-template-columns: auto calc(100% - 64px);
                    column-gap: 10px;
                    height: 34px;
                    align-items: center;
                    .navToPrev,
                    .navToNext {
                      @extend %navigation;
                    }
                  }

                  .sendbtnCont {
                    margin-right: 15px;
                    height: 36px;
                    position: relative;
                    cursor: pointer;
                    @include flex-center();

                    #markComplete {
                      background: $blue-violet;
                      height: 36px;
                      width: 170px;
                      &.hasSiblingBtn {
                        @include bor-rad-right(0 !important, 0 !important);
                      }
                    }
                    .siblingBtn {
                      @include siblingBtn();
                      .drop-down-menu {
                        bottom: 40px;
                        right: 0px;
                      }
                      .downArrow {
                        width: 10px;
                        height: 10px;
                        line-height: 18px;

                        svg {
                          width: inherit;
                          height: inherit;
                          fill: $white;
                        }
                      }
                      .drop-down-menu {
                        min-width: 120px;
                        max-width: 120px;
                        ul {
                          min-width: 120px;
                          li {
                            .listItemWrap {
                              width: 100%;
                              text-align: center;
                            }
                          }
                        }
                      }
                    }

                    .layer {
                      background-color: transparent;
                    }

                    &.loading {
                      .knobs {
                        left: calc(50% - 12px);
                        &.scheduledDateLoader {
                          left: calc(50% - 36px);
                        }
                        &::before {
                          left: 10px;
                          top: calc(50% - 6px);
                          animation: spin 1s linear infinite;
                          border: 1.5px solid #ffffff;
                          border-bottom-color: transparent;
                          border-left-color: transparent;
                          border-left: 1px;
                          background-color: transparent;
                        }
                      }
                    }

                    .completedBtn {
                      display: flex;
                      align-items: center;
                      height: 35px;
                      height: 36px;
                      width: 130px;
                      // border: 1px solid #eee;
                      border-radius: 5px;
                      justify-content: center;
                      cursor: default;
                      color: $green;

                      .tickIcon {
                        color: #6ac259;
                        font-size: 10px;
                        margin-right: 5px;
                        border: 2px solid $green;
                        border-radius: 50px;
                        padding: 1px;
                      }

                      .completedButton {
                        line-height: 20px;
                      }
                    }

                    .taskPerformActionBtn {
                      width: 160px;
                      font-size: 14px;
                      padding: 10px;
                      min-height: 35px;
                      height: 36px;
                      color: $blue-violet;
                      background-color: $blue-violet;
                      color: #fff;
                      &.hasSiblingBtn {
                        @include bor-rad-right(0 !important, 0 !important);
                      }
                    }
                    .wrapScheduleDateIconSymbol {
                      position: relative;
                      margin-left: 15px;
                      cursor: pointer;
                      border-radius: 35px;
                      .scheduleDatePicekerIcon {
                        position: absolute;
                        top: 0;
                        left: 0;
                        cursor: pointer;
                        z-index: 0;
                      }
                      #scheduleDatePicker {
                        width: 24px;
                        height: 24px;
                        color: transparent;
                        background: transparent;
                        border: none;
                        cursor: pointer;
                      }
                      .react-datepicker-popper {
                        transform: translate3d(0, 1, 0px) !important;
                        .react-datepicker {
                          width: 330px;
                          .react-datepicker__triangle {
                            left: 165px;
                          }
                        }
                        .react-datepicker__time-list {
                          width: 99% !important;
                        }
                      }
                    }
                  }

                  .snoozeCont {
                    background-color: $white;
                    color: $blue-violet;
                    border: 1px solid $c-ee;
                    font-size: 14px;

                    .snoozeTxt {
                      font-size: 14px;
                      line-height: 14px;
                      padding-left: 5px;
                    }

                    i {
                      background-color: $white;
                      color: $blue-violet;
                      font-size: 14px;
                    }
                  }
                }

                .whiteButton {
                  background-color: $white;
                  color: $blue-violet;
                  display: flex;
                  font-size: 14px;
                  height: 35px;
                  padding: 0 10px;
                  cursor: pointer;
                  font-weight: 500;
                  &.btn {
                    border: 1px solid #ddd;
                  }
                  .leftArrow {
                    @extend %navigation;
                    left: -11px;
                  }
                  .rightArrow {
                    @extend %navigation;
                  }
                }
              }
            }

            .noTaskfound {
              @include flex-center();
              justify-content: center;
              height: 100%;
            }
          }

          .ctTlSection {
            position: relative;
            width: 100%;
            height: 100%;
            background: #f5f8fd;
            padding: 0px;
            display: block;
            .ctTlBody {
              .ctTlContentBody {
                margin-top: 0px;
                padding: 12px 16px;
                height: calc(100% - 180px);
                border: 1px solid $c-dd;
                .title {
                  font-size: 18px;
                  margin-bottom: 30px;
                  position: relative;
                  color: #343a40;
                  bottom: -9px;
                  left: 4px;
                }
                .ctTlFilter {
                  top: 20px;
                  right: 18px;
                }
                .ctTlHistoryList {
                  height: calc(100% - 50px);
                  .monthlyActivityFeed {
                    margin-bottom: 20px;
                    .ctTlMonth {
                      padding: 10px 0 15px;
                    }
                    .activityFeedCard {
                      display: flex;
                      flex-direction: column;
                      .calenderDate {
                        margin-top: 5px;
                      }
                      .contentCard {
                        position: relative;
                        word-wrap: break-word;
                        .linear-background {
                          width: 230px;
                        }
                        .emailSubjectContent &.linear-background {
                          width: 230px;
                        }
                        .dFlex {
                          width: 100px;
                        }

                        .sequenceDetails {
                          position: relative;
                          display: block;
                          .sequenceName {
                            &.hasStep {
                              padding-left: 0;
                            }
                          }
                          .contactStepNumber {
                            position: relative;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .contactCard {
            background: none;
            padding: 0px;
            position: relative;
            .contactInfoCard {
              background: white;
              padding: 10px;
              border: 1px solid $c-dd;
              height: calc(100vh - 206px) !important;
              border-radius: 10px;
              .wrapContactActivity {
                height: calc(100% - 224px) !important;
              }
            }

            &.contactCardLoader .wrapContactActivity {
              height: calc(100% - 218px) !important;
            }
          }
        }
      }
    }
  }
}

.combinedBanners
  .performTask
  .taskSection
  .contentLayout
  .performTaskPanel
  .contactCard
  .contactInfoCard {
  height: calc(100vh - 235px) !important;
}

.singleBanner
  .performTask
  .taskSection
  .contentLayout
  .performTaskPanel
  .contactCard
  .contactInfoCard {
  height: calc(100vh - 246px) !important;
}

.singleBanner .contactCardLoader .performTask {
  height: calc(100% - 150px) !important;
}

.combinedBanners .contactCardLoader .performTask {
  height: calc(100% - 190px) !important;
}

.showEventBanner
  .performTask
  .taskSection
  .contentLayout
  .performTaskPanel
  .taskBody
  .contactCard
  .contactInfoCard {
  height: calc(100vh - 197px) !important;
}

.showEventBanner
  .combinedBanners
  .performTask
  .taskSection
  .contentLayout
  .performTaskPanel
  .contactCard
  .contactInfoCard {
  height: calc(100vh - 277px) !important;
}

.showEventBanner
  .singleBanner
  .performTask
  .taskSection
  .contentLayout
  .performTaskPanel
  .contactCard
  .contactInfoCard {
  height: calc(100vh - 237px) !important;
}

.singleBanner .performTaskSection {
  height: calc(100vh - 248px) !important;
}

.combinedBanners .performTaskSection {
  height: calc(100vh - 234px) !important;
}
.showEventBanner .performTaskSection {
  height: calc(100vh - 196px) !important;
}
.showEventBanner .singleBanner .performTaskSection {
  height: calc(100vh - 276px) !important;
}
.showEventBanner .combinedBanners .performTaskSection {
  height: calc(100vh - 276px) !important;
}
