.sequencePage {
  .sequenceSectionWrapper {
    display: flex;
    @include wt-ht(100%, 100%);
    padding: 12px;
  }
  .sequenceCont {
    .sequenceDetailSection {
      position: relative;
      @include wt-ht(100%, calc(100% - 60px));
      display: grid;
      grid-template-columns: 100%;
      .hideLeftSection {
        display: none;
      }
    }
    .sequenceSection {
      position: relative;
      @include wt-ht(100%, calc(100% - 60px));
      display: grid;
      grid-template-columns: 280px calc(100% - 280px);
      &.productDiscount {
        @include wt-ht(100%, calc(100% - 114px));
      }
    }

    .leftSection {
      height: 100%;
      padding-right: 10px;
      border-radius: unset;
      background: unset;
      position: relative;
      margin: unset;
      max-width: unset;
      width: 280px;
      overflow: hidden;
      .selected {
        position: relative;
        color: #3953fb !important;
        i {
          color: $c-66;
        }
        &:hover {
          background: $white !important;
          &:after {
            transform: translate(20%, 0);
            will-change: transform;
          }
        }
        &:after {
          content: url("./../../assets/svg/rightArrowBlue.svg");
          width: 15px;
          position: absolute;
          right: 10px;
          height: 18px;
          padding-top: 2px;
          transition: all 200ms ease;
        }
      }

      .sectionAllCont {
        .leftSectionSequenceCont {
          cursor: pointer;
          width: calc(100% - 10px);
          padding: 0px 5px;
          text-decoration: none;
          color: #787878;

          .allListCont {
            width: 100%;
            display: flex;
            align-items: center;

            .mi-list-icon {
              width: 20%;
            }

            .allTemplateBtn,
            .allSequenceBtn {
              display: inline;
              width: 60%;
            }

            .mi-right-arrow {
              width: 20%;
              text-align: right;
            }
          }
        }
      }

      .folderCont {
        color: #787878;
        height: 100%;
        background: $white;
        border-radius: 10px;
        .nofolders {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: inherit;

          svg {
            width: 60px;
            height: 60px;
            fill: #7a82a5;
          }

          .emptyFolder {
            font-size: 14px;
            color: rgba(122, 130, 165, 0.75);
            padding-top: 10px;
          }
        }

        .folderListCont {
          // box-shadow: rgb(226, 226, 226) 0px 11px 8px -10px inset,
          //   rgb(247, 247, 247) 0px 0px 5folderContpx 0px inset;
          height: 100%;
          overflow: hidden;
          padding-bottom: 10px;
          a:first-child {
            @include bor-rad-top(10px, 10px);
          }
          .dynamicFoldersWrapper {
            overflow-y: auto;
            height: calc(100% - 110px);
            overflow-x: hidden;

            @extend %EditFolder;
          }

          .folder {
            padding: 18px 10px;
            border-bottom: 1px solid #eeeef0;
            word-break: break-word;
            font-family: $font-regular;
            cursor: pointer;
            display: flex;
            align-items: center;
            color: #666666;
            text-decoration: none;
            &.dropOver {
              opacity: 0.6;
              color: $blue-violet;
              border-bottom: 1px solid $blue-violet;
            }
            &:hover {
              background-color: #fafafb;
            }
            //padding-left: 4px;
            i {
              font-size: 18px;
              vertical-align: middle;
              margin-right: 10px;
            }
            .folderIcon {
              width: 10%;
              height: 20px;
              text-align: center;
              padding-right: 10px;
              box-sizing: content-box;

              svg {
                width: 18px;
                height: 18px;
                fill: #666666;
              }
            }

            .rightArrowIcon {
              height: 15px;
              padding-left: 10px;
              svg {
                width: 18px;
                height: 15px;

                path {
                  fill: #3953fb;
                }
              }
            }

            span.folderName {
              width: calc(100% - 72px);
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 14px;
            }
            .sharedSeqFolder {
              background-image: url("../../assets/png/Shared.png");
              margin-right: 10px;
              width: 18px;
              height: 18px;
              background-size: 16px;
              background-repeat: no-repeat;
            }
          }
          .dynamicFoldersWrapper .folder {
            height: 55px;
            &:hover {
              background-color: #fafafb;
              border-top-right-radius: 0;
              border-top-left-radius: 0;
              .folderName {
                width: calc(100% - 120px);
              }
            }
          }
        }
      }
    }

    .rightSection {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      margin: unset;
      background: unset;
      .metricsTableFooter {
        margin-top: -50px;
        @include flex-align-center;
        padding: 8px;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        width: 100%;

        margin-top: -48.5px;
        border-top: 1px solid $c-f7;

        .pagination-section {
          padding: 0;
          border-top: 0;
          grid-column: 1;
        }

        .pageLimit {
          margin-right: 4px;
          grid-column: 2;
          justify-self: end;
        }

        .limitDropdown {
          top: -154px;
          padding: 10px;
        }
      }

      .sectionInnerCont {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: white;
        .tableCont {
          padding-bottom: 10px;
          display: inline-block;
          width: 100%;
          &.normalHeight {
            height: 95%;
          }
          &.fullHeight {
            height: 100%;
          }
          .table {
            width: 100%;
            border-collapse: collapse;
            height: 100%;

            %column-section {
              &.name {
                width: 24%;
                @include flex-center();

                .nameText {
                  max-width: 80%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              &.contacts {
                display: block;
                width: 16%;
                text-align: center;
              }

              &.openRate,
              &.clickRate,
              &.replyRate,
              &.updateTs {
                display: block;
                width: 12%;
                text-align: center;
              }

              &.updateTs {
                @include flex-center();
                justify-content: center;
              }

              &.iconCont {
                width: 12%;
                display: flex;
                justify-content: flex-end;
                .icon {
                  display: block;
                  // width: 5%;
                  text-align: center;
                }
              }
            }
            .tableHader {
              background: #fafafa;
              display: flex;
              align-items: center;
              border-bottom: 1px solid $b-c-1;
              padding: 9px 0px;
              font-size: 14px;
              height: 55px;

              &.seqTableLoader {
                height: 55px;
              }

              .toggleBtnHeader {
                min-width: 60px;
                display: block;
                @include flex-center();
                justify-content: center;
                visibility: hidden;
              }

              .sequenceHeaderWrapper {
                width: 100%;
                @include flex-center();
                flex-direction: row;
                .column {
                  @include flex-center();
                  padding: 10px;
                  color: $c-66;
                  @extend %column-section;
                  &.contacts {
                    width: 12%;
                  }
                  &.updateTs {
                    width: 16%;
                  }
                }
              }
            }

            .tableBody {
              max-height: 100%;
              overflow: auto;
              height: calc(100% - 60px);

              .sequenceWrapper {
                position: relative;
                width: 100%;
                @include flex-center();
                flex-direction: row;
                border-bottom: 1px solid $bg-default;
                .toogleSequence.loading {
                  .customTooltip {
                    .tooltipBody {
                      width: 250px !important;
                      max-width: unset !important;
                    }
                  }
                }
                &.seqTableLoader {
                  height: 55px;

                  .iconCont {
                    display: flex;
                    justify-content: center;
                    width: 10%;
                    .iconLoader {
                      width: 30px;
                      height: 30px;
                      margin: 0px 10px;
                      border-radius: 50%;
                    }
                  }
                }

                &:hover .dragHolder {
                  display: block !important;
                }
                .toogleSequence {
                  position: relative;
                  @include flex-center();
                  justify-content: center;
                  width: 60px;
                  z-index: unset !important;
                  padding-left: 10px;
                  .wrapThunderIcon {
                    position: relative;
                    left: 2px;
                    top: -6px;
                    z-index: 1;
                  }
                  .customTooltip {
                    .sharpPointer {
                      background: #393a3d !important;
                      border-right: 1px solid #393a3d !important;
                      border-top: 1px solid #393a3d !important;
                      top: 10px;
                      right: 8px;
                      border-bottom-left-radius: 35px;
                    }
                    .tooltipBody {
                      background: #393a3d !important;
                      right: unset;
                      top: 18px;
                      left: -42px;
                      .tooltipText {
                        color: white;
                      }
                    }
                  }
                }

                .sequenceDataRow {
                  font-family: $font-regular;
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                  width: calc(100% - 70px);
                  cursor: pointer;
                  color: #787878;
                  text-decoration: none;

                  .sequenceDataColumn {
                    padding: 10px;
                    word-break: break-word;
                    position: relative;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: block;
                    overflow: hidden;
                    @extend %column-section;

                    &.contacts {
                      width: 12%;
                    }

                    &.updateTs {
                      width: 16%;
                    }
                    @extend %sharedBadge;

                    &.percentage {
                      @include flex-center();
                      justify-content: center;
                    }

                    .dot {
                      font-size: 6px;
                      font-weight: 600;
                      line-height: 16px;
                      padding-right: 5px;
                    }

                    &.openRate,
                    &.openRate .dot {
                      color: #658bed;
                    }

                    &.clickRate,
                    &.clickRate .dot {
                      color: #ff8d55;
                    }

                    &.replyRate,
                    &.replyRate .dot {
                      color: #3ecb6f;
                    }

                    &.updateTs {
                      @include flex-center();
                      justify-content: center;
                    }
                  }

                  .iconCont {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 12%;
                    .icon {
                      text-align: center;
                      padding: 10px 0;
                      // border-bottom: 1px solid #eeeef0;
                      position: relative;

                      .overlayBtn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 20px;
                        padding: 0px 10px;
                        width: fit-content;
                        width: -webkit-fit-content;
                        width: -moz-fit-content;

                        .overlayBtnTxt {
                          line-height: 20px;
                          font-size: 14px;
                          display: contents;
                        }
                      }

                      .status {
                        height: 24px;
                        &.resume {
                          height: 30px;
                        }
                      }

                      .pause {
                        svg {
                          fill: #658bed;
                          width: 20px;
                          height: 20px;
                        }

                        .overlayBtnTxt {
                          color: #658bed;
                        }
                      }

                      .stop {
                        svg {
                          fill: #ff8d55;
                        }

                        .overlayBtnTxt {
                          color: #ff8d55;
                        }
                      }

                      .resume {
                        svg {
                          fill: #3ecb6f;
                          width: 30px;
                          height: 30px;
                        }

                        .overlayBtnTxt {
                          color: #3ecb6f;
                        }
                      }
                    }
                    .addContactDropDown {
                      @include AddContactDropDown();
                      .drop-down-menu {
                        top: calc(100% - 50px);
                      }
                    }
                  }

                  .addContacts {
                    // margin: auto;
                    position: relative;

                    &.first {
                      .drop-down-menu {
                        top: 36px !important;
                      }
                    }
                  }

                  .moreOption {
                    cursor: pointer;
                    -webkit-user-select: none;
                    user-select: none;
                    overflow: unset;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .moreOptionCont {
                      padding: 10px 0;
                    }
                    .moreOptionBtn {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .sequenceProperties {
        height: 100%;
        .propertiesHeader {
          display: flex;
          align-items: center;
          background: transparent;
          .headerStyle {
            background: transparent;
            .toogleSequence {
              position: relative;
              display: flex;
              margin-right: 15px;
              border-right: 1px solid $b-c-1;
              width: fit-content;
              z-index: unset !important;
              .wrapThunderIcon {
                position: relative;
                left: 10px;
              }
              .customTooltip {
                .sharpPointer {
                  background: #393a3d !important;
                  border-right: 1px solid #393a3d !important;
                  border-top: 1px solid #393a3d !important;
                  top: 22px;
                  right: 6px;
                  border-bottom-left-radius: 35px;
                }
                .tooltipBody {
                  background: #393a3d !important;
                  right: unset;
                  top: 30px;
                  left: -34px;
                  .tooltipText {
                    color: white;
                  }
                }
              }
            }
          }
          .rightSectionHeader {
            @include flex-center();
            width: 100%;
            justify-content: flex-end;
            background: transparent;
            .addStepsButton {
              padding-left: 15px;
              .addNewButton {
                font-size: 13px;
                font-weight: bold;
                min-width: 120px;
              }
              .drop-down-menu {
                @include stageDropdownMenu(45px, 0);
                left: unset;
              }
            }
            .exportToSalesforceSection {
              margin-right: 10px;
            }

            .addContactDropDown {
              @include AddContactDropDown();
              #addContactBtn {
                width: 140px;
              }
            }

            .exportBtnWrapper {
              padding: 10px;
              font-size: 0.9vw;
              @include wt-ht(36px, 36px);
            }
          }

          .tabSelected {
            .propertyTxt {
              color: $blue-violet;
              font-weight: 500;
            }
            //border-bottom: 1.5px solid #3953fb;
          }

          .headerInnerCont.headerStyle {
            border: 0;
            width: fit-content;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            position: relative;
            padding: 0;
          }

          .headerCont {
            padding: 22px 25px;
            height: 58px;
            color: $c-53;
            cursor: pointer;
            letter-spacing: 0.2px;

            &:hover {
              color: #635e75;
            }
          }
        }

        .propertiesBody {
          position: relative;
          max-height: 100%;
          height: calc(100% - 108px);
          overflow: auto;
          border-top: 1px solid $c-ec;
          .overviewBody {
            position: relative;
            padding: 15px 5px;
            .newEmailSendingWindowBanner {
              position: relative;
              @include flex-center;
              justify-content: center;
              margin-bottom: 15px;

              .redirectLinkText {
                @include flex-center;
                margin-right: 2px;
                cursor: pointer;
                color: $blue-violet;
                i {
                  margin-right: 5px;
                  font-size: 18px;
                }
                .text {
                  font-size: 15px;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
            &.noStage {
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .stagesList {
                @include flex-center;
                justify-content: center;
              }
              .addStepHeader {
                height: unset;
              }
            }
            .addStepHeader {
              @include wt-ht(100%, 100%);
              @include flex-align-center;
              position: relative;
              .stepHeaderCont {
                position: relative;
                display: grid;
                grid-template-columns: 10% 82% 8%;
                width: 250px;
                padding: 10px;
                border: 1px dashed $c-dd;
                background: $white;
                cursor: pointer;
                .addStepName {
                  color: $c-3b;
                  font-size: 14px;
                }
                .arrowIcon {
                  @extend %relBlock;
                  color: $c-66;
                  font-size: 20px;
                }
              }
            }
            .drop-down-menu {
              @include stageDropdownMenu(45px, 0);
            }
          }
          &.prospectsContainer {
            overflow: initial;
            border-radius: 0px;
          }
          .noStagesCont {
            padding: 10px;
            @include flex-center();
            color: $c-53;
            align-items: center;
            flex-direction: column;
          }
          &.analyticsSection,
          &.throttlingSection {
            background: $white;
            padding: 15px;
            border-top: 1px solid $c-ec;
            @include bor-rad-btm(6px, 6px);
            .saveSeqSettingsBtn {
              padding: 10px 20px;
              background: $blue-violet;
              margin: 10px 0 auto;
              cursor: pointer;
              font-weight: 500;
              min-width: 130px;
              height: 40px;
              letter-spacing: 0.3px;
              &.disableBtn {
                color: $c-cc;
                background: transparent;
                border: 1px solid $c-cc;
                cursor: not-allowed;
                pointer-events: none;
                font-weight: 500;
              }
              &.loading {
                min-width: auto;
              }
            }
          }
        }
        @extend %AddStepButton;
      }
    }

    .noContentContainer {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;

      .noContentInnerCont {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        .textCont {
          width: 70%;
          text-align: center;
          font-family: $font-regular;
          display: flex;
          align-items: center;
          flex-direction: column;
          .title {
            padding: 10px 0px;
            font-size: 20px;
          }
          .content {
            padding: 15px 0px;
          }
        }
      }
    }
  }
}

.downArrowBlack.calendar-select-arrow {
  margin-left: -20px;
  display: inline-block;
  z-index: 0;
  pointer-events: none;
  padding: 0;
  width: fit-content;
}

.calendarWrapper.calendarEnabled {
  .react-datepicker-popper {
    top: 0px !important;
    left: 50% !important;
    transform: translate3d(0%, 12%, 0px) !important;
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    left: 65% !important;
  }

  .react-datepicker__time-container {
    width: fit-content;
  }
}
.sequenceMultiInboxSelection,
.sequenceScheduleSettings {
  padding-bottom: 16px;
  font-size: 14px;
}
.sequenceScheduleSettings {
  @extend %relBlock;
  .scheduleForm {
    .flexCenter {
      @include flex-center();
      align-items: flex-start;
      .scheduleWindowPanel {
        flex: 0 0 35%;
      }
    }
    .selectedTimeZone {
      width: 300px;
    }
    .sendingWindow {
      flex: 0 0 40%;
    }
    .scheduleHeader {
      font-size: 14px;
      font-weight: 500;
      color: $c-66;
      margin-bottom: 16px;
    }
    .daysWithTimeWrapper {
      @include flex-center();
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
      .weekDays {
        font-family: $font-regular;
        flex: 0 0 45%;
        &.linear-background {
          @include wt-ht(60px, 16px);
          border-radius: 10px;
          margin-right: 80px;
          flex: 0 0 75px;
        }
      }
      .TimeRange {
        &.linear-background {
          @include wt-ht(90px, 16px);
          border-radius: 10px;
          flex: 0 0 90px;
        }
      }
    }
  }
}
.sequenceThrottlingSettings {
  padding-top: 20px;
}
.sequenceMultiInboxSelection {
  margin-bottom: 20px;
  .addInboxBtn {
    position: relative;
    width: fit-content;
    @include flex-center;
    border-radius: 3px;
    color: $c-78;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    padding: 0 8px 0 5px;
    height: 36px;
    transition: all 0.3s ease-in-out;
    i {
      font-size: 16px;
    }
    .btnText {
      padding-left: 5px;
    }
    &:hover {
      background: $c-f5;
      color: $c-44;
    }
  }
  .dropdownInboxList {
    position: relative;
    @include flex-center;
    .selectLabel {
      position: relative;
      @include flex-center;
      width: 300px;
      justify-content: space-between;
      height: 36px;
      background: #fff;
      border-bottom: 1px solid #ebebeb;
      cursor: pointer;
      .selectText {
        text-align: center;
        color: $blue-violet;
      }
      .arrowDownIcon {
        color: $blue-violet;
        font-size: 18px;
      }
    }
    .dropdownOptions {
      position: absolute;
      width: 300px;
      padding: 10px 0;
      min-width: 300px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 0 16.1px 0.9px rgba(59, 59, 59, 0.07);
      z-index: 12;
      opacity: 1;
      top: 38px;
      border: 1px solid $c-eb;
      overflow: auto;
      .inboxList {
        overflow-y: auto;
        max-height: 200px;
        .addInboxButtonWrapper {
          padding: 8px 10px;
          &:last-child {
            border: 0;
          }
        }
      }
      .connectInboxBtn {
        @include flex-center();
        font-weight: bold;
        cursor: pointer;
        height: 36px;
        padding: 8px 10px;
        font-size: 13px;
        color: $c-78;
        &:hover {
          color: $blue-violet;
        }
        i {
          font-size: 16px;
          margin-right: 4px;
        }
      }
    }
    .inboxLoader {
      padding: 8px 10px;
      .linear-background {
        width: 100%;
        height: 15px;
        margin-bottom: 8px;
      }
    }
  }
  .multiInboxDropdownOverlay {
    @include bg-mask(11, transparent);
  }
  .selectedInboxEmailList {
    position: relative;
    width: 100%;
    @include flex-center;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    @include list-style-zero;
    margin-top: 10px;
    overflow: auto;
    max-height: 80px;
    margin-bottom: 10px;
    .linear-background {
      width: 180px;
      height: 34px;
    }
    li {
      position: relative;
      @include flex-center;
      padding: 8px 1.25rem 8px 10px;
      border: 1px solid $c-eb;
      transition: all 0.3s ease;
      border-radius: 2px;
      .email {
        font-size: 13px;
      }
      .removeIcon {
        position: absolute;
        display: none;
        right: 2px;
        border: 1px solid transparent;
        padding: 1px;
        font-size: 12px;
        cursor: pointer;
        border-radius: 35px;
        margin-left: 2px;
        &.disabled {
          user-select: none;
        }
      }
      &:hover {
        background: #f5fbfe;
        .removeIcon {
          display: block;
          background: white;
          display: block;
          border: 1px solid $c-eb;
        }
      }
    }
  }
}
.sequenceThrottlingSettings,
.sequenceScheduleSettings,
.sequenceMultiInboxSelection,
.sequenceExcludeContactSafetySetting {
  @extend %relBlock;
  h6 {
    color: $c-53;
    margin-bottom: 10px;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 1.32;
    font-family: $font-regular;
    font-weight: 600;
  }
  .subTextForSettings {
    color: #8c8c8c;
    margin-bottom: 18px;
    font-size: 14px;
    width: 70%;
    line-height: 1.3;
    .hereText {
      text-decoration: none;
      color: $blue-violet;
      cursor: pointer;
      border: 0;
      font-size: 14px;
      background-color: transparent;
      padding: 0;
    }
    &.noMargin {
      margin-bottom: 0;
    }
  }
  .autoCompleteDropdownLoading,
  .sendingWindowLoading {
    margin-bottom: 20px;
    .inputBoxLoader {
      @include wt-ht(300px, 38px);
      border-radius: 3px;
    }
    .labelLoader {
      @extend %relBlock;
      color: $c-66;
      font-size: 15px;
      margin-bottom: 8px;
      font-weight: 500;
      &.linear-background {
        border-radius: 10px;
        @include wt-ht(160px, 16px);
      }
    }
  }
  .autoCompleteDropdown,
  .selectedTimeZone {
    margin-bottom: 20px;
  }
  .throttlingForm {
    @extend %relBlock;
    .inputForm {
      display: flex;
      column-gap: 1.67%;
      width: 75%;
    }
  }
}
.sequenceExcludeContactSafetySetting {
  position: relative;
  padding: 20px 0;
  border-bottom: 1px solid $c-eb;
  .checkboxWrapper {
    margin-top: 10px;
    .checkBoxName {
      padding-top: 4px;
    }
  }
}
.autoCompleteDropdown {
  .createNewScheduleBtn {
    @include flex-center();
    font-weight: bold;
    cursor: pointer;
    height: 36px;
    padding: 0 10px;
    font-size: 13px;
    &:hover {
      color: $blue-violet;
    }
    i {
      font-size: 16px;
      margin-right: 4px;
    }
  }
  .dropdownList {
    padding: 0 !important;
    max-height: 200px !important;
    overflow-y: auto;
  }
}

.disabledAddStepBtn {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
  cursor: unset;
}

.firstContactAddedOverlay {
  @include bg-mask(14, $c-33);
  opacity: 0.5;
}
