@import "./onBoardingMixins.scss";

.onboardingSection {
  @include wt-ht(100%, 100%);

  .getStartedButtonCont {
    z-index: 9;
    border: none;
    position: fixed;
    bottom: 14px;
    left: 4px;
    color: $white;
    width: fit-content;
    @include flex-center();

    .getStartedWrapper {
      position: relative;
      cursor: pointer;
      font-size: 14px;
      letter-spacing: 0.3px;
      font-family: $font-regular;
      padding: 8px 12px;
      z-index: 9;
      border-radius: 5px;
      @include flex-center();
      @include wt-ht(40px, 40px);
      .rocketIcon {
        @include wt-ht(18px, 18px);
        svg {
          @include wt-ht(inherit, inherit);
          fill: $c-8d;
        }
        &:hover svg {
          fill: $blue-violet;
        }
        &.showSteps {
          svg {
            fill: $blue-violet;
          }
        }
      }
      .remainingCount {
        position: absolute;
        background: #e40303;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        color: white;
        text-align: center;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -10px;
        right: -10px;
        box-shadow: 1px -1px 2px 0 rgba(243, 133, 133, 0.05);
        font-weight: bold;
      }
    }

    .stepsPopup {
      position: relative;

      @include onBoardingStepsPopup();
    }
  }

  .onboarding-popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: #333;
    opacity: 0.5;
  }

  .onBoardingCompletedPopupWrapper {
    @include onBoardingCompletedPopup();
  }
}
